import ApiService from '@/services/api/Api.service';
import { ProductPreference } from '../../interfaces/productPreference.interface';
class ProductPreferenceService extends ApiService {
  async update(params: ProductPreference): Promise<any> {
    return this.axios.post('/uiapi/product-preference/' + params.id, params);
  }

  async create(params: ProductPreference): Promise<any> {
    return this.axios.post('/uiapi/product-preference/', params);
  }

  async delete(id: number): Promise<any> {
    return this.axios.delete('/uiapi/product-preference/' + id);
  }
}

export default new ProductPreferenceService();
