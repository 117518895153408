






















































































































































































































































import DataGrid from '../components/DataGrid.vue';
import { Vue } from 'vue-property-decorator';
import PageLayout from '../components/PageLayout.vue';

import ProviderFormService from '../services/api/provider-form.service';
import S3FileService from '../services/api/S3File.service';
import { SubFilterSet } from '../interfaces';
import axios from 'axios';
import { ProviderForm } from '../interfaces';
import SearchInputField from '../components/SearchInputField.vue';
import { Product } from '../interfaces';
import UserService from '../services/api/User.service';
import CustomDialog from '../components/CustomDialog.vue';
import FormatDisplayService from '../services/FormatDisplay.service';

/**
 *  Metadata service URL
 */
const metadataEndpointUrl = '/uiapi/grids/provider-form';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/provider-form/search';

const emptyProviderFormDataRecord: ProviderForm = {
  active: false,
  providerId: null,
  name: '',
  formIdentifier: '',
  formDate: new Date().toISOString().substr(0, 10),
  filename: '',
  id: null,
  provider: {
    id: undefined,
  },
};

export default Vue.extend({
  name: 'ProviderForm',
  components: {
    PageLayout,
    DataGrid,
    SearchInputField,
    CustomDialog,
  },
  data: () => ({
    userService: UserService,
    dialog: false,
    previewDialog: false,
    valid: true,
    editedRecordState: [],
    overlay: false,
    errors: [] as string[],
    messages: [] as string[],
    errorsPDF: [] as Array<any>,
    editActionType: 'INSERT',
    editedRecord: Object.assign({}, emptyProviderFormDataRecord),
    providerListOptions: [] as SubFilterSet[],
    selectedProductId: 0 as number | undefined,
    selectedProductName: '' as string | undefined,
    selectedProductProviderId: '' as string | number | undefined,
    currentProviderFormId: null as number | null,
    menu: false,
    alertType: 'success',
    selectedFile: null as null | File,
    reRenderGrid: 0,
    selectedFileRules: [(v: string) => !!v || 'Please select a file'],
    nameInputRules: [(v: string) => !!v || 'Name is required'],
    hideFilters: false,
    endPoint: {
      metadataEndpointUrl: metadataEndpointUrl,
      dataEndpointUrl: dataEndpointUrl,
    },
  }),
  watch: {},
  methods: {
    getAdditionalConditionsProductSearch() {
      if (this.selectedProductProviderId && this.selectedProductProviderId !== '') {
        return [
          {
            conditionComparatorOption: 'equal',
            fieldName: 'provider.id',
            fieldType: 'String',
            value: this.selectedProductProviderId,
          },
        ];
      } else {
        return [];
      }
    },
    error() {
      return {
        set: (errors: string[]) => {
          this.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      return {
        set: (errors: string[]) => {
          this.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.messages.push(error);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
    openDialog(editedRecord?: ProviderForm): void {
      this.errors.length = 0;
      this.resetValidation();
      if (editedRecord === undefined) {
        this.editActionType = 'INSERT';
        Object.assign(this.editedRecord, emptyProviderFormDataRecord);
      } else {
        this.editActionType = 'UPDATE';
        Object.assign(this.editedRecord, editedRecord);
      }
      this.dialog = !this.dialog;
    },
    async openPreviewDialog(form: ProviderForm) {
      this.currentProviderFormId = form.id;
      this.getRemoteComponent('PreviewDialog')
        .external()
        .open();
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    selectProduct(product: Product) {
      this.errorsPDF = [];
      this.selectedProductId = product.id || undefined;
      this.selectedProductName = product.name || product.externalId;
      this.selectedProductName =
        product.productType +
        ' - ' +
        product.accountNumber +
        ' - ' +
        FormatDisplayService.getRender('toDateTime')((product.dateTimeCreated || '').toString());
    },
    async downloadFormTemplate(form: ProviderForm) {
      S3FileService.getPresignedProviderFormDownloadUrl(form.id as number).then(url => {
        window.open(url, '_blank');
      });
    },
    async generateProviderFormPreview(selectedProductId: number, providerFormId: number) {
      try {
        this.errorsPDF = [];
        const res = await ProviderFormService.previewForm(providerFormId, selectedProductId);
        const file = new Blob([res.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } catch (error) {
        this.errorsPDF.push(error.response?.data?.message || 'Unknown error');
      }
    },
    validate() {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).providerForm.validate();
      }, 250);
    },
    resetValidation() {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).providerForm.resetValidation();
      }, 100);
    },
    formValueChange() {
      this.message().clear();
    },
    async submitDataRequest() {
      try {
        if ((this.$refs.providerForm as Vue & { validate: () => boolean }).validate()) {
          this.overlay = true;
          this.editedRecord.filename = '';
          if (this.selectedFile) {
            const s3 = await S3FileService.getPresignedProviderFormUploadUrl(
              this.selectedFile.name.replace(/\.[^/.]+$/, ''),
            );
            const axiosCl = axios.create();
            await axiosCl.put(s3.url, this.selectedFile, {
              headers: {
                'Content-Type': this.selectedFile.type,
              },
            });
            this.editedRecord.filename = s3.filename;
          }
          if (this.editActionType === 'INSERT') {
            await ProviderFormService.createProviderForm(this.editedRecord);
          }
          if (this.editActionType === 'UPDATE') {
            await ProviderFormService.updateProviderForm(this.editedRecord);
          }
          this.selectedFile = null;

          // (this.$refs.providerForm as Vue & { reset: () => boolean }).reset();
          this.reRenderGrid++;
          this.alertType = 'success';
          this.message().add('Provider Form saved');
          setTimeout(() => {
            this.dialog = false;
            this.message().clear();
            this.overlay = false;
          }, 1500);
        }
      } catch (err) {
        this.error().clear();
        this.error().add('There were some errors during provider form saving');
        this.overlay = false;
      }
    },
    onMetaDataLoaded(data: any[]) {
      if (data.length) {
        this.providerListOptions =
          data.filter(item => item.subFilterName === 'Provider')[0]?.conditions[0]?.fieldOptions || [];
      }
    },
  },
  computed: {
    buttonActions() {
      return [
        {
          name: 'New Provider Form',
          icon: 'mdi-plus',
          disabled: !this.userService.checkUserActionAccess('PROVIDER_FORM_EDIT'),
          callback: () => {
            this.openDialog();
          },
        },
      ];
    },
    actions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit Provider Form',
          disabled: !this.userService.checkUserActionAccess('PROVIDER_FORM_EDIT'),
          callback: (rowData: ProviderForm): void => {
            this.openDialog(rowData);
          },
        },
        {
          icon: 'mdi-file',
          tooltip: 'Preview',
          callback: (rowData: ProviderForm): void => {
            this.selectedProductProviderId = rowData.provider?.id;
            this.openPreviewDialog(rowData);
          },
        },
        {
          icon: 'mdi-download',
          tooltip: 'Download Provider Form Template',
          callback: (rowData: ProviderForm): void => {
            this.downloadFormTemplate(rowData);
          },
        },
      ];
    },
  },
});
