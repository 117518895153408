




















































































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import { PathI } from '@/interfaces';
import SfBooleanSelectField from '@/components/SFComponents/SfBooleanSelectField.vue';
import SfEnumField from '@/components/SFComponents/SfEnumField.vue';
import SfLookupField from '@/components/SFComponents/SfLookupField.vue';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import ValidationService from '@/services/Validations.service';
import axios from '@/services/api/Api.service';
import UserService from '@/services/api/User.service';
import { AxiosResponse } from 'axios';
const axiosInstance = new axios().getClient();

const saveUrl = '/uiapi/config-path';

export default Vue.extend({
  data() {
    return {
      ValidationService: ValidationService,
      formValid: true,
      metadataEnumsMap: new Map(),
      service: MetadataService,
      content: this.value as PathI,
      errors: [] as string[],
      messages: [] as string[],
      progress: false as boolean,
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      partsReadOnly: {
        autoFinalizeAfterCreateProduct: true,
        cancellationSendOnly: true,
        autoSendCancellation: true,
        finalizeAfterSending: true,
        cancellationSendAndGetRefundQuote: true,
        autoSendCancellationRefundQuote: true,
        refundCustomer: false,
        lenderReceivables: false,
        debitDealerReserve: true,
        perDealer: true,
      },
    };
  },
  components: { SfEnumField, SfBooleanSelectField, SfLookupField },
  name: 'LenderServiceConfigurationPathsEditForm',
  filters: {
    extendCancelType(v: 'O' | 'P' | 'R') {
      const translateCancelType = [
        { code: 'O', name: 'OTR' },
        { code: 'P', name: 'PO' },
        { code: 'R', name: 'REPO' },
      ] as { code: string; name: string }[];
      const output = translateCancelType.find(t => t.code === v);
      return output ? output.name : v;
    },
  },
  watch: {
    '$props.value'(v) {
      this.content = v;
    },
    'content.autoFinalizeAfterCreateProduct': {
      handler(n, o) {
        if (n === true && o === false) {
          this.resetLeftOptionsVisibility();
          Object.assign(this.content, {
            cancellationSendOnly: false,
            autoSendCancellation: null,
            finalizeAfterSending: null,
            cancellationSendAndGetRefundQuote: false,
            autoSendCancellationRefundQuote: null,
          });
          Object.assign(this.partsReadOnly, {
            autoFinalizeAfterCreateProduct: false,
            cancellationSendAndGetRefundQuote: true,
            cancellationSendOnly: true,
          });
          this.$emit('input', this.content);
        }
        if (n === false && o === true) {
          Object.assign(this.partsReadOnly, {
            autoFinalizeAfterCreateProduct: true,
          });
        }
      },
      deep: true,
    },
    'content.cancellationSendOnly': {
      handler(n, o) {
        if (n === true && o === false) {
          this.resetLeftOptionsVisibility();
          Object.assign(this.content, {
            autoFinalizeAfterCreateProduct: false,
            autoSendCancellation: null,
            finalizeAfterSending: null,
            cancellationSendAndGetRefundQuote: false,
            autoSendCancellationRefundQuote: null,
          });
          Object.assign(this.partsReadOnly, {
            autoFinalizeAfterCreateProduct: false,
            autoSendCancellation: false,
            finalizeAfterSending: false,
            cancellationSendAndGetRefundQuote: false,
          });
          Object.assign(this.partsReadOnly, {
            autoFinalizeAfterCreateProduct: true,
            cancellationSendAndGetRefundQuote: true,
            cancellationSendOnly: false,
          });
          this.$emit('input', this.content);
        }
        if (n === false && o === true) {
          Object.assign(this.partsReadOnly, {
            autoSendCancellation: true,
            finalizeAfterSending: true,
            cancellationSendOnly: true,
          });
          Object.assign(this.content, {
            autoSendCancellation: null,
            finalizeAfterSending: null,
          });
        }
      },
      deep: true,
    },
    'content.cancellationSendAndGetRefundQuote': {
      handler(n, o) {
        if (n === true && o === false) {
          this.resetLeftOptionsVisibility();
          Object.assign(this.content, {
            autoFinalizeAfterCreateProduct: false,
            cancellationSendOnly: false,
            autoSendCancellation: null,
            finalizeAfterSending: null,
          });
          Object.assign(this.partsReadOnly, {
            autoFinalizeAfterCreateProduct: false,
            autoSendCancellation: true,
            finalizeAfterSending: true,
            cancellationSendAndGetRefundQuote: false,
            autoSendCancellationRefundQuote: false,
          });
          Object.assign(this.partsReadOnly, {
            autoFinalizeAfterCreateProduct: true,
            cancellationSendAndGetRefundQuote: false,
            cancellationSendOnly: true,
          });
          this.$emit('input', this.content);
        }
        if (n === false && o === true) {
          Object.assign(this.partsReadOnly, {
            autoSendCancellationRefundQuote: true,
            cancellationSendAndGetRefundQuote: true,
          });
          Object.assign(this.content, {
            autoSendCancellationRefundQuote: null,
          });
        }
      },
      deep: true,
    },
    'content.refundCustomer': {
      handler(n, o) {
        if (n === true && o === false) {
          this.$emit('input', this.content);
          Object.assign(this.partsReadOnly, {
            refundCustomer: false,
          });
        }
        if (n === false && o === true) {
          this.$emit('input', this.content);
          Object.assign(this.partsReadOnly, {
            refundCustomer: true,
          });
        }
      },
      deep: true,
    },
    'content.lenderReceivables': {
      handler(n, o) {
        if (n === true && o === false) {
          Object.assign(this.partsReadOnly, {
            lenderReceivables: false,
            debitDealerReserve: false,
          });
          this.$emit('input', this.content);
        }
        if (n === false && o === true) {
          Object.assign(this.partsReadOnly, {
            lenderReceivables: true,
            debitDealerReserve: true,
            perDealer: true,
          });
          Object.assign(this.content, {
            debitDealerReserve: null,
            perDealer: null,
          });
        }
      },
      deep: true,
    },
    'content.debitDealerReserve': {
      handler(n, o) {
        if (n === true) {
          Object.assign(this.partsReadOnly, {
            perDealer: false,
          });
          this.$emit('input', this.content);
        }
        if (n === false) {
          Object.assign(this.partsReadOnly, {
            perDealer: true,
          });
          Object.assign(this.content, {
            perDealer: null,
          });
          this.$emit('input', this.content);
        }
      },
      deep: true,
    },
  },
  async created() {
    this.error().clear();
    this.message().clear();
    this.metadataEnumsMap = await this.service.loadData(MetadataType.CONFIG_PATH);
    this.updateFormFieldVisibility();
    this.validateForm();
  },
  methods: {
    updateFormFieldVisibility() {
      Object.assign(this.partsReadOnly, {
        autoFinalizeAfterCreateProduct: !this.content.autoFinalizeAfterCreateProduct,
        cancellationSendOnly: !this.content.cancellationSendOnly,
        autoSendCancellation: this.content.autoSendCancellation !== null ? false : true,
        finalizeAfterSending: this.content.finalizeAfterSending !== null ? false : true,
        cancellationSendAndGetRefundQuote: !this.content.cancellationSendAndGetRefundQuote,
        autoSendCancellationRefundQuote: this.content.autoSendCancellationRefundQuote !== null ? false : true,
        refundCustomer: !this.content.refundCustomer,
        lenderReceivables: !this.content.lenderReceivables,
        debitDealerReserve: this.content.debitDealerReserve !== null ? false : true,
        perDealer: this.content.perDealer !== null ? false : true,
      });
    },
    selectPathOptions(): [object] | [] {
      if (
        !this.content.autoFinalizeAfterCreateProduct &&
        !this.content.cancellationSendOnly &&
        !this.content.cancellationSendAndGetRefundQuote
      ) {
        return [
          () => {
            return 'One of Options need to be selected';
          },
        ];
      }
      return [];
    },
    selectRule(disabled: boolean): [object] | [] {
      if (!disabled) {
        return [
          (v: null | true | false) => {
            if (v === null) {
              return 'Required Yes or No';
            }
            return true;
          },
        ];
      }
      return [];
    },
    resetLeftOptionsVisibility() {
      Object.assign(this.partsReadOnly, {
        autoFinalizeAfterCreateProduct: true,
        cancellationSendOnly: true,
        autoSendCancellation: true,
        finalizeAfterSending: true,
        cancellationSendAndGetRefundQuote: true,
        autoSendCancellationRefundQuote: true,
      });
    },
    resetRightOptionsVisibility() {
      Object.assign(this.partsReadOnly, {
        refundCustomer: false,
        lenderReceivables: false,
        debitDealerReserve: true,
        perDealer: true,
      });
    },
    clearAllOptionsSelection() {
      Object.assign(this.content, {
        autoFinalizeAfterCreateProduct: false,
        cancellationSendOnly: false,
        autoSendCancellation: null,
        finalizeAfterSending: null,
        cancellationSendAndGetRefundQuote: false,
        autoSendCancellationRefundQuote: null,
        refundCustomer: false,
        lenderReceivables: false,
        debitDealerReserve: null,
        perDealer: null,
      });
    },
    effectiveDateTypeInput() {
      this.content.effectiveDate = this.content.effectiveDateType === 'AllTime' ? null : this.content.effectiveDate;
    },
    validateForm() {
      setTimeout(() => {
        this.$getRemoteComponent('PathFormComponent', this).validate();
      }, 250);
    },
    setProgress(val: boolean): boolean {
      this.progress = val;
      this.$forceUpdate();
      return this.progress;
    },
    message() {
      const scope = this;
      return {
        set: (messages: string[]) => {
          scope.messages.push(...messages);
          return this;
        },
        add: (messages: string) => {
          scope.messages.push(messages);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    async submitForm() {
      await this.saveData();
      if (this.errors.length > 0) {
        return Promise.reject(false);
      } else {
        setTimeout(() => {
          return Promise.resolve(true);
        }, 1500);
      }
    },
    saveData() {
      if (!this.formValid) {
        this.error().clear();
        this.message().clear();
        this.error().add('Form data is not valid');
        return Promise.reject(false);
      }
      let url = saveUrl;
      let insert = true;

      const requestData = JSON.parse(JSON.stringify(this.content));

      if (requestData && requestData.id && requestData.id > 0) {
        url += '/' + requestData.id;
        insert = false;
      }

      if (requestData && requestData.effectiveDateType !== 'AllTime') {
        requestData.startDate = requestData.effectiveDate;
      }

      if (insert) {
        delete requestData.externalId;
        delete requestData.createdDate;
        delete requestData.createdById;
        delete requestData.updatedById;
        delete requestData.dateTimeUpdated;
      } else {
        delete requestData.externalId;
        delete requestData.createdDate;
        delete requestData.createdById;
        delete requestData.updatedById;
      }

      this.setProgress(true);
      this.error().clear();
      this.message().clear();
      return axiosInstance
        .post<PathI, AxiosResponse<{ success: boolean; messages: string[]; errors: string[]; data: PathI }>>(
          url,
          requestData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(result => {
          this.setProgress(false);
          if (result.data.success) {
            this.content = result.data.data;
            this.$emit('input', this.content);
            this.$emit(insert ? 'row-added' : 'row-updated');
            if (result.data.messages && result.data.messages.length > 0) this.message().set(result.data.messages);
          } else {
            // Setting errors will show them on form
            this.error().set(result.data.errors);
          }
        })
        .catch((error: string) => {
          this.setProgress(false);
          this.error().set([error]);
        });
    },
  },
  props: ['value', 'id', 'orgId'],
});
