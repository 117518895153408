































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import SearchInputField from '@/components/SearchInputField.vue';
import FormatDisplayService from '@/services/FormatDisplay.service';
import ValidationService from '@/services/Validations.service';
import eventBus from '@/events/EventBus';

@Component({
  components: { SearchInputField },
})
export default class IDPProductForm extends Vue {
  @Prop([Object, Boolean]) readonly productData: any | undefined;
  @Prop([String, Boolean]) readonly selectedProduct: string | undefined;
  data() {
    return {
      productEnums: null as null | any[],
      ready: false,
      nonTextFields: ['providerName', 'cancelType'],
      numericFields: ['contractPrice', 'contractMiles', 'contractTerm', 'purchaseOdometer'],
      translateFieldLabel: {
        providerContract: 'Provider Contract Number',
        providerFormId: 'Provider Form ID',
      },
      productForm: null as null | any,
      validationService: ValidationService,
      decisionedFields: [
        'productType',
        'contractDate',
        'contractTerm',
        'providerName',
        'contractMiles',
        'contractPrice',
        'providerContract',
        'purchaseOdometer',
      ],
      decisionedFieldsFilterOut: [
        'orgId',
        'informedDocumentId',
        'informedFilePdfPath',
        'allowAutoChangeRefundProcessingStatus',
        'allowAutoChangeProductProcessingStatus',
        'account',
        'cancelType',
        'lenderCancelSendData',
        'productType',
      ],
      readonly: false,
      disabled: false,
    };
  }
  async mounted() {
    this.$data.productEnums = await MetadataService.loadData(MetadataType.PRODUCT);
    this.$data.ready = true;
    this.$data.disabled =
      !!this.productData.dateTimeSendToRefundControl && (this.productData.notIncluded || this.productData.verified);
    this.$data.readonly =
      !!this.productData.dateTimeSendToRefundControl && (this.productData.notIncluded || this.productData.verified);

    setTimeout(() => {
      this.$data.productForm = this.$getRemoteComponent('RefIDPProductForm', this);
      this.$data.productForm.validate();
    }, 500);
  }
  formatReadOnlyData(value: any, fieldName: string) {
    let valueConverted = value;

    if (fieldName.indexOf('Date') > -1) {
      valueConverted = FormatDisplayService.getRender('toDate')(value);
    }

    return valueConverted;
  }
  callParentUpdate() {
    this.$emit('formUpdate');
    this.$forceUpdate();
  }
  getFields() {
    let fields = [] as string[];
    if (this.productData) {
      const extractedKeys = Object.keys(this.productData.extracted || {});
      const submittedKeys = Object.keys(this.productData.submitted || {});
      fields = Array.from(new Set([...submittedKeys, ...extractedKeys]));
      fields = fields.filter((f: any) => !this.$data.decisionedFieldsFilterOut.includes(f));

      //remove entry from fields array when equals lenderCancelDate
      if (fields && fields.length > 0 && fields.includes('lenderCancelDate')) {
        fields.splice(fields.indexOf('lenderCancelDate'), 1);
      }
    }
    return fields;
  }
  camelCaseToTitleCase(str: string): string {
    // Insert space before uppercase letters and split the string
    let spacedStr = str.replace(/(?<=.)([A-Z])/g, ' $1');

    if (this.$data.translateFieldLabel[str]) {
      return this.$data.translateFieldLabel[str];
    }

    // Transform to title case
    return spacedStr
      .toLowerCase()
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  copyToDecisioned(productData: any, from: string, field: string) {
    this.productData.decisioned[field] = this.productData[from][field];
    this.callParentUpdate();
  }
  getColorIfSelected(productData: any, from: string, field: string) {
    if (this.productData.decisioned[field] == this.productData[from][field]) {
      return 'green';
    }
    return '';
  }
  selectProvider(provider: any) {
    this.productData.decisioned.providerName = provider.name;
    this.callParentUpdate();
  }
  switchProductToVerified() {
    const errorMessage = 'Missing data in required fields, Cannot switch to Verified';
    if (!this.productData.verified && !this.$data.productForm.validate()) {
      eventBus.$emit('ADD_TEMP_ERROR', {
        content: errorMessage,
      });
      this.callParentUpdate();
      return false;
    }
    this.productData.verified = !this.productData.verified ? true : false;
    this.productData.notIncluded = false;
    this.callParentUpdate();
  }
}
