var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        flex: "1",
        height: "100%",
        "flex-direction": "row"
      }
    },
    [
      _c("sf-data-grid", {
        ref: "DaysToRefundGrid",
        attrs: {
          apiMetadataUrl: _vm.gridApiUrls.metadata,
          apiDataUrl: _vm.gridApiUrls.data,
          "footer-height": _vm.footerHeight,
          actions: _vm.actions(),
          rowClick: _vm.editRecord,
          "fixed-conditions": [
            {
              fieldName: "orgId",
              value: _vm.orgId,
              fieldType: "string",
              conditionComparatorOption: "equal"
            },
            {
              fieldName: "type",
              value: "Refund",
              fieldType: "string",
              conditionComparatorOption: "equal"
            }
          ]
        }
      }),
      _c(
        "sf-custom-dialog",
        {
          ref: "DaysToRefundCustomDialogForm",
          attrs: { "max-width": "500", fullscreen: "" },
          on: {
            close: function($event) {
              return _vm.refreshGrid()
            }
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(
              _vm._s(_vm.dialogTitleText) +
                " " +
                _vm._s(_vm.readonly ? " (Read-Only)" : "")
            )
          ]),
          _c("div", { attrs: { slot: "buttons" }, slot: "buttons" }, [
            _c(
              "button",
              {
                staticClass: "slds-button slds-button_neutral",
                on: {
                  click: function($event) {
                    _vm
                      .getRemoteComponent("DaysToRefundCustomDialogForm")
                      .external()
                      .close()
                  }
                }
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "button",
              {
                staticClass: "slds-button slds-button_brand",
                attrs: { disabled: !_vm.saveButtonActive || _vm.readonly },
                on: {
                  click: function($event) {
                    return _vm.saveFormData()
                  }
                }
              },
              [_vm._v(" Save ")]
            )
          ]),
          _c(
            "div",
            {
              staticStyle: { display: "flex", flex: "1" },
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _c("days-to-refund-edit-form", {
                ref: "DaysToRefundEditForm",
                attrs: { orgId: _vm.orgId },
                on: {
                  "row-updated": function($event) {
                    return _vm.refreshGrid()
                  },
                  "row-added": function($event) {
                    return _vm.refreshGrid()
                  }
                },
                model: {
                  value: _vm.editedRecord,
                  callback: function($$v) {
                    _vm.editedRecord = $$v
                  },
                  expression: "editedRecord"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }