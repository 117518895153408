import { render, staticRenderFns } from "./MessagesCard.vue?vue&type=template&id=434160ac&scoped=true&"
import script from "./MessagesCard.vue?vue&type=script&lang=ts&"
export * from "./MessagesCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "434160ac",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VAlert,VCard})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('434160ac')) {
      api.createRecord('434160ac', component.options)
    } else {
      api.reload('434160ac', component.options)
    }
    module.hot.accept("./MessagesCard.vue?vue&type=template&id=434160ac&scoped=true&", function () {
      api.rerender('434160ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MessagesCard.vue"
export default component.exports