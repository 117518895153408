var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-layout",
        {
          attrs: {
            pageName: _vm.pageTitle,
            pageIcon: "mdi-text-box-search-outline",
            contentScroll: false,
            showToolbarSlots: false,
            to: false
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [_c("IDPAccountDetails", { attrs: { id: this.id, eager: "" } })],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }