var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": _vm.maxWidth,
        "min-height": "95%",
        scrollable: "",
        fullscreen: _vm.fullscreen
      },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "section",
        {
          staticClass: "slds-modal slds-fade-in-open slds-modal_large",
          attrs: {
            role: "dialog",
            tabindex: "-1",
            "aria-labelledby": "modal-heading-01",
            "aria-modal": "true",
            "aria-describedby": "modal-content-id-1"
          }
        },
        [
          _c("div", { staticClass: "slds-modal__container" }, [
            _vm.$slots.title
              ? _c("header", { staticClass: "slds-modal__header" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "slds-button slds-button_icon slds-modal__close slds-button_icon-inverse",
                      attrs: { title: "Close" },
                      on: {
                        click: function($event) {
                          _vm.open = false
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass:
                            "slds-button__icon slds-button__icon_large",
                          attrs: { "aria-hidden": "true" }
                        },
                        [
                          _c("use", {
                            attrs: {
                              "xlink:href":
                                require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                                "#close"
                            }
                          })
                        ]
                      ),
                      _c("span", { staticClass: "slds-assistive-text" }, [
                        _vm._v("Close")
                      ])
                    ]
                  ),
                  _c(
                    "h2",
                    {
                      staticClass: "slds-modal__title slds-hyphenate",
                      attrs: { id: "modal-heading-01" }
                    },
                    [_vm._t("title", [_vm._v("Modeal Header Text")])],
                    2
                  )
                ])
              : _vm._e(),
            _vm.open
              ? _c(
                  "div",
                  {
                    staticClass: "slds-modal__content slds-p-around_medium",
                    class: _vm.$props.fullscreen ? "sf-full-screen" : "",
                    attrs: { id: "modal-content-id-1" }
                  },
                  [_vm._t("content")],
                  2
                )
              : _vm._e(),
            _c(
              "footer",
              { staticClass: "slds-modal__footer" },
              [_vm._t("buttons")],
              2
            )
          ])
        ]
      ),
      _c("div", { staticClass: "slds-backdrop slds-backdrop_open" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }