






































import { Vue } from 'vue-property-decorator';
import FormatDisplayService from '../services/FormatDisplay.service';

export default Vue.extend({
  name: 'CustomDatePicker',
  components: {},
  data() {
    return {
      sendDelayDateMenu: false as boolean,
      formatDisplayService: FormatDisplayService,
      content: this.value,
      valueData: null as null | string,
    };
  },
  computed: {
    controlDatePickerClearButton() {
      if (typeof this.disableclear === 'string' || this.disableclear) {
        return false;
      }
      return true;
    },
    controlDatePickerMenu() {
      if (typeof this.readonly === 'string' || this.readonly) {
        return false;
      }
      if (typeof this.disabled === 'string' || this.disabled) {
        return false;
      }
      return true;
    },
  },
  watch: {
    '$props.value'(v) {
      this.content = v;
    },
  },
  methods: {
    makeid(length: number) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    handleInput() {
      this.$emit('input', this.content);
    },
  },
  props: [
    'hide-details',
    'outlined',
    'value',
    'readonly',
    'disabled',
    'label',
    'rules',
    'placeholder',
    'disableclear',
    'prependIcon',
    'hint',
  ],
});
