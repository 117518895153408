























































import { Vue } from 'vue-property-decorator';
import { Transmission } from '../interfaces';
import FormatDisplayService from '../services/FormatDisplay.service';

export default Vue.extend({
  name: 'TransmissionDetails',
  data: () => ({
    editedRecord: {} as Transmission,
    productName: '',
    FormatDisplayService: FormatDisplayService as Object,
  }),
  methods: {},
  created() {
    this.editedRecord = this.passEditedRecord;
    this.productName = this.passProductName;
  },
  watch: {
    passEditedRecord: function(newVal) {
      this.editedRecord = newVal;
    },
    passProductName: function(newVal) {
      this.productName = newVal;
    },
  },
  props: {
    passEditedRecord: {
      type: Object,
    },
    passProductName: {
      type: String,
    },
  },
});
