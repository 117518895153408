import axios, { AxiosInstance } from 'axios';
import EventBus from '@/events/EventBus';

export default class ApiService {
  protected axios: AxiosInstance;
  protected orgId = '';
  protected sfUser = false;

  constructor() {
    this.axios = axios.create({
      baseURL: '/',
      headers: {
        'Content-type': 'application/json',
        orgId: this.orgId,
      },
    });
    this.axios.defaults.withCredentials = true;
    this.axios.interceptors.response.use(
      response => {
        EventBus.$emit('NetworkConnected');
        return Promise.resolve(response);
      },
      error => {
        if (error.message === 'Network Error') {
          EventBus.$emit('NetworkDisconnected');
          return Promise.reject(error);
        }
        if (error?.response?.status === 403) {
          if (this.sfUser) {
            EventBus.$emit('ForceRefreshPageInfo');
          } else {
            window.localStorage.clear();
            window.location.href = `/uiapi/aws-cognito/login`;
          }
        }
        if (error?.response?.status === 401) {
          if (this.sfUser) {
            EventBus.$emit('ForceRefreshPageInfo');
          } else {
            window.localStorage.clear();
            window.location.href = `/uiapi/aws-cognito/login`;
          }
        } else {
          return Promise.reject(error);
        }
      },
    );
    // Set Request Header OrgId by Event SetLenderOrgId
    EventBus.$on('SetLenderOrgId', (orgId: string) => {
      Object.assign(this.axios.defaults, { headers: { orgId: orgId } });
    });
    EventBus.$on('SfUserLogged', (sfUser: boolean) => {
      this.sfUser = sfUser;
    });
  }
  getClient() {
    return this.axios;
  }
}
