var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "d-flex flex-column flex-grow-1 fill-parent-height",
      attrs: { fluid: "", "pa-0": "" }
    },
    [
      _vm.pageType === "STANDARD_CONTENT_WITH_HEADER" && _vm.pageName
        ? _c(
            "div",
            [
              _c(
                "v-row",
                {
                  staticClass: "page-header top-row flex-grow-0 flex-shrink-0",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "page-header-column",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "page-title v-card__title page-layout-title-class"
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "page-title-icon",
                              attrs: {
                                "aria-hidden": "false",
                                color: "primary"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.pageIcon) + " ")]
                          ),
                          _vm._v(" " + _vm._s(_vm.pageName) + " ")
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "bottom-row flex-grow-1 flex-shrink-1 page-layout-content-row",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "ma-0" },
                    [
                      _c("v-overlay", {
                        attrs: { absolute: "", value: _vm.contentOverlay }
                      }),
                      _vm._t("content")
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.pageType === "STANDARD_GRID_WITH_CONTROLS"
        ? _c(
            "div",
            [
              _c(
                "v-row",
                {
                  staticClass: "page-header top-row flex-grow-0 flex-shrink-0",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "page-header-column",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "page-title v-card__title page-layout-title-class"
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "page-title-icon",
                              attrs: {
                                "aria-hidden": "false",
                                color: "primary"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.pageIcon) + " ")]
                          ),
                          _vm._v(" " + _vm._s(_vm.pageName) + " ")
                        ],
                        1
                      ),
                      _c("div", [_vm._t("header_line")], 2),
                      _c(
                        "div",
                        { staticClass: "page-header-toolbar" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "ma-0", attrs: { cols: "4" } },
                                [_vm._t("toolbar")],
                                2
                              ),
                              _c(
                                "v-col",
                                { staticClass: "ma-0", attrs: { cols: "2" } },
                                [_vm._t("options")],
                                2
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "ma-0 page-layout-button-col",
                                  attrs: { cols: "6" }
                                },
                                [_vm._t("buttons")],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "bottom-row flex-grow-1 flex-shrink-1 page-layout-content-row",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "ma-0" },
                    [
                      _c("v-overlay", {
                        attrs: { absolute: "", value: _vm.contentOverlay }
                      }),
                      _vm._t("content")
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }