































































































































































import { Vue } from 'vue-property-decorator';
import SearchInputField from '../../components/SearchInputField.vue';
import ValidationService from '@/services/Validations.service';
import FormatDisplayService from '@/services/FormatDisplay.service';
import ConsumerRefundService from '../../services/api/ConsumerRefund.service';
import { Product, ConsumerRefund } from '../../interfaces';

export default Vue.extend({
  name: 'ConsumerRefundCreate',
  components: { SearchInputField },
  data: () => ({
    overlay: false,
    valid: true,
    errors: [] as string[],
    ValidationService: ValidationService,
    FormatDisplayService: FormatDisplayService,
    consumerRefundDateMenu: false,
    selectedProductName: '' as string | undefined,
    selectedProductOrgId: '',
    snackBarSuccess: false,
    snackBarError: false,
    editedRecord: {
      consumerRefundType: '',
      consumerRefundAmount: '',
      consumerRefundDate: null,
      consumerRefundProcessing: '',
      consumerRefundReference: '',
      productExternalId: '' as string,
      accountNumber: '',
      orgId: '',
    } as ConsumerRefund,
  }),
  methods: {
    formValueChange() {
      if (
        this.editedRecord.consumerRefundType == 'Stop Payment' &&
        this.editedRecord.consumerRefundAmount &&
        parseFloat(this.editedRecord.consumerRefundAmount + '') > 0
      ) {
        this.editedRecord.consumerRefundAmount = `-${this.editedRecord.consumerRefundAmount}`;
      }
    },
    selectProduct(product: Product) {
      this.selectedProductName = (product.name || product.id) as string;
      this.editedRecord.productExternalId = product.externalId as string;
      this.editedRecord.orgId = product.orgId as string;
      this.editedRecord.accountNumber = product.accountNumber as string;
    },
    async createConsumerRefund() {
      this.error().clear();
      try {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          this.overlay = true;
          let res = await ConsumerRefundService.create(this.editedRecord);
          if (res.success) {
            this.snackBarSuccess = true;
            this.overlay = false;
            this.$emit('consumerRefundCreated');
          } else {
            if (res.errors) {
              this.error().set(res.errors);
            }
            this.snackBarError = true;
            this.overlay = false;
          }
        }
      } catch (err) {
        this.error().set([err.response.data.message]);
        this.snackBarError = true;
        this.overlay = false;
      }
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    setError(errors: string[]) {
      this.errors.length = 0;
      Object.assign(this.errors, errors);
    },
  },
  created() {
    this.selectedProductName = (this.productByDefault.name || this.productByDefault.id) as string;
    this.editedRecord.productExternalId = this.productByDefault.externalId;
    this.editedRecord.orgId = this.productByDefault.orgId;
    this.editedRecord.accountNumber = this.productByDefault.accountNumber;
    this.editedRecord.consumerRefundProcessing = 'Pending Consumer Refund Data';
  },
  props: {
    productByDefault: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
});
