var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        "return-value": _vm.time,
        transition: "scale-transition",
        "offset-y": "",
        "max-width": "290px",
        "min-width": "290px"
      },
      on: {
        "update:returnValue": function($event) {
          _vm.time = $event
        },
        "update:return-value": function($event) {
          _vm.time = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        value: _vm.timeFormat12(_vm.content),
                        label: _vm.label,
                        "prepend-icon": "mdi-clock-time-four-outline",
                        readonly: _vm.readonly,
                        dense: ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.handleInput()
                        }
                      }
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _vm.menu
        ? _c("v-time-picker", {
            attrs: { format: "ampm", "full-width": "" },
            on: {
              "click:minute": function($event) {
                _vm.$refs.menu.save(_vm.time)
                _vm.handleInput()
              }
            },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = $$v
              },
              expression: "content"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }