var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    (_vm.fieldOperatorSync === "in" || _vm.fieldOperatorSync === "not in") &&
    _vm.fieldDefinitionSync.fieldOptionsConverted
      ? _c(
          "div",
          [
            _c("sf-enum-field", {
              staticClass: "sfl-fix-label",
              attrs: {
                dense: "",
                "hide-details": _vm.hideDetails,
                "field-data-type": "ARRAY",
                "key-field": "value",
                "search-field": "text",
                items: _vm.listItems(
                  _vm.fieldDefinitionSync.fieldOptionsConverted
                ),
                itemsObjects: _vm.fieldDefinitionSync.fieldOptionsConverted,
                rules: [
                  _vm.services.validationService.getValidator(
                    "required",
                    _vm.fieldDefinitionSync.fieldLabel
                  )
                ]
              },
              model: {
                value: _vm.fieldValueSync.value,
                callback: function($$v) {
                  _vm.$set(_vm.fieldValueSync, "value", $$v)
                },
                expression: "fieldValueSync.value"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.fieldOperatorSync !== "in" && _vm.fieldOperatorSync !== "not in"
      ? _c(
          "div",
          [
            _c("v-autocomplete", {
              attrs: {
                outlined: "",
                dense: "",
                items: _vm.fieldDefinitionSync.fieldOptionsConverted,
                "hide-no-data": "",
                "hide-details": _vm.hideDetails,
                rules: [
                  _vm.services.validationService.getValidator(
                    "required",
                    _vm.fieldDefinitionSync.fieldLabel
                  )
                ]
              },
              model: {
                value: _vm.fieldValueSync.value,
                callback: function($$v) {
                  _vm.$set(_vm.fieldValueSync, "value", $$v)
                },
                expression: "fieldValueSync.value"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }