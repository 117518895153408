var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "DaysToRefundFormComponent",
      staticStyle: { display: "flex", flex: "1", "flex-direction": "column" },
      attrs: { readonly: _vm.readonly },
      model: {
        value: _vm.formValid,
        callback: function($$v) {
          _vm.formValid = $$v
        },
        expression: "formValid"
      }
    },
    [
      _c("SfCustomDialogLayout", { staticClass: "input-shrink" }, [
        _c(
          "div",
          { attrs: { slot: "base-info" }, slot: "base-info" },
          [
            _c("v-progress-linear", {
              directives: [
                {
                  name: "visible",
                  rawName: "v-visible",
                  value: _vm.progress,
                  expression: "progress"
                }
              ],
              attrs: { indeterminate: "", color: "#0176d3" }
            }),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.messages.length > 0,
                    expression: "messages.length > 0"
                  }
                ],
                staticClass: "confirmation-message"
              },
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-alert",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "line-height": "15px"
                            },
                            attrs: {
                              type: "info",
                              border: "left",
                              text: "",
                              outlined: "",
                              dense: "",
                              autocomplete: "none"
                            }
                          },
                          [
                            _c("div", { staticClass: "mb-1" }, [
                              _c("b", [_vm._v("Messages:")])
                            ]),
                            _vm._l(_vm.messages, function(
                              message,
                              messageIndex
                            ) {
                              return _c("div", { key: messageIndex }, [
                                _vm._v("* " + _vm._s(message))
                              ])
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.length > 0,
                    expression: "errors.length > 0"
                  }
                ],
                staticClass: "error-message"
              },
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-alert",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "14px",
                              "line-height": "15px"
                            },
                            attrs: {
                              type: "error",
                              border: "left",
                              text: "",
                              outlined: "",
                              dense: ""
                            }
                          },
                          [
                            _c("div", { staticClass: "mb-1" }, [
                              _c("b", [_vm._v("Errors:")])
                            ]),
                            _vm._l(_vm.errors, function(error, errorIndex) {
                              return _c("div", {
                                key: errorIndex,
                                domProps: { innerHTML: _vm._s("* " + error) }
                              })
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { attrs: { slot: "main-right" }, slot: "main-right" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label" },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.metadataEnumsMapLetter.get(
                          "effectiveTypeDate"
                        ),
                        label: "Effective Date Type",
                        outlined: "",
                        dense: ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.effectiveDateTypeInput()
                        }
                      },
                      model: {
                        value: _vm.content.effectiveDateType,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "effectiveDateType", $$v)
                        },
                        expression: "content.effectiveDateType"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label" },
                  [
                    _c("custom-date-picker", {
                      attrs: {
                        readonly: _vm.readonly,
                        outlined: "",
                        dense: "",
                        label: "Effective Date",
                        disabled: _vm.content.effectiveDateType === "AllTime",
                        rules:
                          _vm.content.effectiveDateType !== "AllTime"
                            ? [
                                _vm.ValidationService.getValidator(
                                  "required",
                                  "Effective Date"
                                )
                              ]
                            : []
                      },
                      model: {
                        value: _vm.content.effectiveDate,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "effectiveDate", $$v)
                        },
                        expression: "content.effectiveDate"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label" },
                  [
                    _vm.content.effectiveDateType !== "AllTime"
                      ? _c("custom-date-picker", {
                          attrs: {
                            readonly: _vm.readonly,
                            outlined: "",
                            dense: "",
                            disabled: "",
                            label: "Start Date"
                          },
                          model: {
                            value: _vm.content.effectiveDate,
                            callback: function($$v) {
                              _vm.$set(_vm.content, "effectiveDate", $$v)
                            },
                            expression: "content.effectiveDate"
                          }
                        })
                      : _vm._e(),
                    _vm.content.effectiveDateType === "AllTime"
                      ? _c("custom-date-picker", {
                          attrs: {
                            readonly: _vm.readonly,
                            outlined: "",
                            dense: "",
                            label: "Start Date",
                            rules: [
                              _vm.ValidationService.getValidator(
                                "required",
                                "Start Date"
                              )
                            ]
                          },
                          model: {
                            value: _vm.content.startDate,
                            callback: function($$v) {
                              _vm.$set(_vm.content, "startDate", $$v)
                            },
                            expression: "content.startDate"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label" },
                  [
                    _c("custom-date-picker", {
                      attrs: {
                        readonly: _vm.readonly,
                        outlined: "",
                        dense: "",
                        label: "End Date"
                      },
                      on: {
                        input: function($event) {
                          return _vm.input()
                        }
                      },
                      model: {
                        value: _vm.content.endDate,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "endDate", $$v)
                        },
                        expression: "content.endDate"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label", attrs: { cols: "6" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "Days To Refund",
                        outlined: "",
                        dense: "",
                        rules: [
                          _vm.ValidationService.getValidator(
                            "required",
                            "Days To Refund"
                          )
                        ],
                        "persistent-hint": ""
                      },
                      on: { keypress: _vm.$keyPressIntegerValidation },
                      model: {
                        value: _vm.content.daysToRefund,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "daysToRefund", $$v)
                        },
                        expression: "content.daysToRefund"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { attrs: { slot: "param-up" }, slot: "param-up" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "2" } },
                  [
                    _c("v-switch", {
                      staticClass: "mt-1",
                      attrs: { dense: "", label: "Active" },
                      on: {
                        change: function($event) {
                          return _vm.input()
                        }
                      },
                      model: {
                        value: _vm.content.active,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "active", $$v)
                        },
                        expression: "content.active"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label" },
                  [
                    _c("v-text-field", {
                      attrs: { label: "Rule Name", outlined: "", dense: "" },
                      model: {
                        value: _vm.content.name,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "name", $$v)
                        },
                        expression: "content.name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "pr-5",
            attrs: { slot: "param-down" },
            slot: "param-down"
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  right: "20px",
                  bottom: "0px",
                  overflow: "hidden",
                  display: "flex"
                }
              },
              [
                _vm.refresh
                  ? _c("SfCustomParameters", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectedTab === "RULE",
                          expression: "selectedTab === 'RULE'"
                        }
                      ],
                      ref: "REFCustomParameters",
                      attrs: {
                        "custom-params": _vm.content.managementRuleParameters,
                        "static-parameters": true,
                        orgId: _vm.orgId
                      },
                      on: {
                        "update:customParams": function($event) {
                          return _vm.$set(
                            _vm.content,
                            "managementRuleParameters",
                            $event
                          )
                        },
                        "update:custom-params": function($event) {
                          return _vm.$set(
                            _vm.content,
                            "managementRuleParameters",
                            $event
                          )
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }