var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wiko",
      staticStyle: {
        "max-height": "350px",
        "font-size": "14px",
        "line-height": "16px",
        background: "#FFF",
        "min-width": "150px",
        overflow: "hidden",
        position: "relative"
      }
    },
    [
      _c(
        "div",
        { staticClass: "pa-0 ma-0", staticStyle: { background: "#FFF" } },
        [
          _c(
            "v-row",
            { staticClass: "mb-0" },
            [
              _c(
                "v-col",
                { staticClass: "mb-0 pb-0", on: { click: _vm.stopTheEvent } },
                [
                  _c(
                    "legend",
                    {
                      staticClass: "mb-0 pa-2 pr-8",
                      staticStyle: {
                        "border-bottom": "1px solid #ddd",
                        width: "100%",
                        position: "relative"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.header.text) +
                          " " +
                          _vm._s(_vm.getSelectedCount()) +
                          " "
                      ),
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            cursor: "pointer",
                            position: "absolute",
                            top: "8px",
                            right: "10px"
                          },
                          attrs: { small: "", color: "#1b96ff" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.resetFilter()
                            }
                          }
                        },
                        [_vm._v(" mdi-eraser ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "input-shrink" },
                    [
                      ["Enum"].indexOf(_vm.getSubFilterType()) > -1 &&
                      _vm.getEnumFieldOptions().length > 10 &&
                      !_vm.header.lookups
                        ? _c("v-text-field", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              "prepend-icon": "mdi-magnify",
                              "append-icon": "mdi-close",
                              outlined: ""
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.search = ""
                              }
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        : _vm._e(),
                      ["String"].indexOf(_vm.getSubFilterType()) > -1 &&
                      _vm.header.lookups
                        ? _c("v-text-field", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              "prepend-icon": "mdi-magnify",
                              "append-icon": "mdi-close",
                              outlined: ""
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.search = ""
                                _vm.inputLookupFieldCallSearch()
                              },
                              input: _vm.inputLookupField
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        : _vm._e(),
                      ["String", "Integer", "Float"].indexOf(
                        _vm.getSubFilterType()
                      ) > -1 && !_vm.header.lookups
                        ? _c(
                            "v-container",
                            {
                              staticClass: "slds-form-element",
                              staticStyle: {
                                width: "450px",
                                "margin-left": "0px",
                                "margin-right": "-130px",
                                "margin-top": "-13px",
                                "margin-bottom": "-13px"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "sfl-text-filter-row" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-1  pt-3",
                                      attrs: { cols: "7" }
                                    },
                                    [
                                      _c("v-select", {
                                        staticStyle: { "font-size": "20px" },
                                        attrs: {
                                          outlined: "",
                                          autocomplete: "none",
                                          value: " = ",
                                          dense: "",
                                          "hide-details": "",
                                          items: _vm
                                            .getSubFilterComparatorOptions()
                                            .filter(function(o) {
                                              if (
                                                o === "in" ||
                                                o === "not in"
                                              ) {
                                                return false
                                              }
                                              return true
                                            })
                                            .map(function(o) {
                                              if (typeof o === "object") {
                                                o.text = "equals"
                                                return o
                                              } else {
                                                return {
                                                  text: o.replace(
                                                    "contain",
                                                    "contains"
                                                  ),
                                                  value: o
                                                }
                                              }
                                            })
                                        },
                                        model: {
                                          value: _vm.header.comparator1,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.header,
                                              "comparator1",
                                              $$v
                                            )
                                          },
                                          expression: "header.comparator1"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-text-field",
                                        {
                                          staticStyle: { "font-size": "20px" },
                                          attrs: {
                                            outlined: "",
                                            autocomplete: "none",
                                            clearable: "",
                                            "hide-details": "",
                                            append: "",
                                            dense: ""
                                          },
                                          on: {
                                            keypress: _vm.keyPressValidation
                                          },
                                          model: {
                                            value: _vm.header.value1,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.header,
                                                "value1",
                                                $$v
                                              )
                                            },
                                            expression: "header.value1"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              attrs: {
                                                slot: "append",
                                                color: "#00834F",
                                                disabled:
                                                  !_vm.header.value1 ||
                                                  _vm.header.value1 === "" ||
                                                  !_vm.header.comparator1
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.inputFieldSubFilter()
                                                }
                                              },
                                              slot: "append"
                                            },
                                            [_vm._v(" mdi-filter-plus ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  ["String", "Integer", "Float"].indexOf(
                    _vm.getSubFilterType()
                  ) > -1 && !_vm.header.lookups
                    ? _c(
                        "div",
                        [
                          _vm.header.subFilterSelection &&
                          _vm.header.subFilterSelection.length > 0
                            ? _c(
                                "v-container",
                                {
                                  staticClass: "slds-form-element pb-0",
                                  staticStyle: {
                                    "max-height": "250px",
                                    overflow: "hidden",
                                    "overflow-y": "auto",
                                    "border-top": "1px solid #ddd"
                                  }
                                },
                                _vm._l(
                                  _vm.header.subFilterSelection || [],
                                  function(subFilter, subFilterIndex) {
                                    return _c(
                                      "v-row",
                                      {
                                        key: subFilterIndex,
                                        staticClass: "input-shrink",
                                        staticStyle: {
                                          "max-height": "80px",
                                          overflow: "hidden",
                                          "overflow-y": "auto",
                                          "max-width": "450px",
                                          "margin-left": "-75px",
                                          "margin-right": "-80px",
                                          "margin-top": "-25px",
                                          "margin-bottom": "-10px"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1  pt-3",
                                            attrs: { cols: "7" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                outlined: "",
                                                autocomplete: "none",
                                                value:
                                                  subFilter.comparatorOption,
                                                dense: "",
                                                "hide-details": "",
                                                readonly: "",
                                                disabled: ""
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-text-field",
                                              {
                                                attrs: {
                                                  outlined: "",
                                                  autocomplete: "none",
                                                  value: subFilter.value,
                                                  "hide-details": "",
                                                  append: "",
                                                  dense: "",
                                                  readonly: "",
                                                  disabled: ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      slot: "append",
                                                      color: "#DD5144"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.header.subFilterSelection.splice(
                                                          subFilterIndex,
                                                          1
                                                        )
                                                        _vm.inputFieldSubFilter()
                                                      }
                                                    },
                                                    slot: "append"
                                                  },
                                                  [_vm._v(" mdi-filter-minus ")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.getSubFilterType() === "Date"
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-container",
                            {
                              staticClass:
                                "slds-form-element pa-5 input-shrink",
                              staticStyle: {
                                width: "550px",
                                "margin-left": "-85px",
                                "margin-right": "-90px",
                                "margin-top": "-20px",
                                "margin-bottom": "-20px"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-1  pt-3",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          autocomplete: "none",
                                          value: " = ",
                                          dense: "",
                                          "hide-details": "",
                                          outlined: "",
                                          items: _vm.getSubFilterComparatorOptions()
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.inputDateSubFilter()
                                          }
                                        },
                                        model: {
                                          value: _vm.header.comparator1,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.header,
                                              "comparator1",
                                              $$v
                                            )
                                          },
                                          expression: "header.comparator1"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "close-on-content-click": false
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              outlined: "",
                                                              autocomplete:
                                                                "none",
                                                              value: _vm.parseDate(
                                                                _vm.header
                                                                  .value1
                                                              ),
                                                              clearable: "",
                                                              readonly: "",
                                                              "prepend-icon":
                                                                "mdi-calendar",
                                                              "hide-details":
                                                                "",
                                                              dense: ""
                                                            },
                                                            on: {
                                                              "click:clear": function(
                                                                $event
                                                              ) {
                                                                delete _vm
                                                                  .header.value1
                                                                delete _vm
                                                                  .header.value2
                                                                delete _vm
                                                                  .header
                                                                  .comparator2
                                                                _vm.inputDateSubFilter()
                                                                _vm.$forceUpdate()
                                                              }
                                                            }
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            248798346
                                          ),
                                          model: {
                                            value: _vm.header.dateMenu1,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.header,
                                                "dateMenu1",
                                                $$v
                                              )
                                            },
                                            expression: "header.dateMenu1"
                                          }
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: { "no-title": "" },
                                            on: {
                                              input: function($event) {
                                                _vm.inputDateSubFilter()
                                                _vm.header.dateMenu1 = false
                                              }
                                            },
                                            model: {
                                              value: _vm.header.value1,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.header,
                                                  "value1",
                                                  $$v
                                                )
                                              },
                                              expression: "header.value1"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.header.value1 &&
                              _vm.header.comparator1 !== "equal"
                                ? _c(
                                    "v-row",
                                    { staticClass: "mt-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-1  pt-3",
                                          attrs: { cols: "6" }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              autocomplete: "none",
                                              disabled: !_vm.header.value1,
                                              dense: "",
                                              "hide-details": "",
                                              items: _vm
                                                .getSubFilterComparatorOptions()
                                                .filter(function(c) {
                                                  if (c === "equal") {
                                                    return false
                                                  }
                                                  if (
                                                    [
                                                      "greater than",
                                                      "greater than or equal"
                                                    ].indexOf(
                                                      _vm.header.comparator1
                                                    ) > -1 &&
                                                    [
                                                      "greater than",
                                                      "greater than or equal"
                                                    ].indexOf(c) > -1
                                                  ) {
                                                    return false
                                                  }
                                                  if (
                                                    [
                                                      "less than",
                                                      "less than or equal to"
                                                    ].indexOf(
                                                      _vm.header.comparator1
                                                    ) > -1 &&
                                                    [
                                                      "less than",
                                                      "less than or equal to"
                                                    ].indexOf(c) > -1
                                                  ) {
                                                    return false
                                                  }
                                                  return true
                                                })
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.inputDateSubFilter()
                                              }
                                            },
                                            model: {
                                              value: _vm.header.comparator2,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.header,
                                                  "comparator2",
                                                  $$v
                                                )
                                              },
                                              expression: "header.comparator2"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "close-on-content-click": false
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  outlined: "",
                                                                  autocomplete:
                                                                    "none",
                                                                  clearable: "",
                                                                  value: _vm.parseDate(
                                                                    _vm.header
                                                                      .value2
                                                                  ),
                                                                  disabled: !_vm
                                                                    .header
                                                                    .value1,
                                                                  readonly: "",
                                                                  "prepend-icon":
                                                                    "mdi-calendar",
                                                                  "hide-details":
                                                                    "",
                                                                  dense: ""
                                                                },
                                                                on: {
                                                                  "click:clear": function(
                                                                    $event
                                                                  ) {
                                                                    delete _vm
                                                                      .header
                                                                      .value2
                                                                    _vm.inputDateSubFilter()
                                                                  }
                                                                }
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3839756639
                                              ),
                                              model: {
                                                value: _vm.header.dateMenu2,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.header,
                                                    "dateMenu2",
                                                    $$v
                                                  )
                                                },
                                                expression: "header.dateMenu2"
                                              }
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  disabled: !_vm.header.value1,
                                                  "no-title": ""
                                                },
                                                on: {
                                                  input: function($event) {
                                                    _vm.inputDateSubFilter()
                                                    _vm.header.dateMenu2 = false
                                                  }
                                                },
                                                model: {
                                                  value: _vm.header.value2,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.header,
                                                      "value2",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "header.value2"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.getSubFilterType() === "Checkbox" ||
                  _vm.getSubFilterType() === "Boolean"
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "slds-form-element__control pa-2 pb-3"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "slds-checkbox pt-1",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.addBoolFilter(
                                      !_vm.checkbox.booleanYes,
                                      "YES"
                                    )
                                  }
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkbox.booleanYes,
                                      expression: "checkbox.booleanYes"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    name: "options",
                                    id: "YES",
                                    readonly: ""
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.checkbox.booleanYes
                                    )
                                      ? _vm._i(_vm.checkbox.booleanYes, null) >
                                        -1
                                      : _vm.checkbox.booleanYes
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.checkbox.booleanYes,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.checkbox,
                                              "booleanYes",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.checkbox,
                                              "booleanYes",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.checkbox,
                                          "booleanYes",
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "slds-checkbox__label",
                                    attrs: { for: "YES" }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "slds-checkbox_faux"
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "slds-form-element__label"
                                      },
                                      [_vm._v(" YES")]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "slds-checkbox pt-1",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.addBoolFilter(
                                      !_vm.checkbox.booleanNo,
                                      "NO"
                                    )
                                  }
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkbox.booleanNo,
                                      expression: "checkbox.booleanNo"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    name: "options",
                                    id: "NO",
                                    readonly: ""
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.checkbox.booleanNo
                                    )
                                      ? _vm._i(_vm.checkbox.booleanNo, null) >
                                        -1
                                      : _vm.checkbox.booleanNo
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.checkbox.booleanNo,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.checkbox,
                                              "booleanNo",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.checkbox,
                                              "booleanNo",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.checkbox, "booleanNo", $$c)
                                      }
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "slds-checkbox__label",
                                    attrs: { for: "NO" }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "slds-checkbox_faux"
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "slds-form-element__label"
                                      },
                                      [_vm._v(" NO")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.getSubFilterType() === "String" && _vm.header.lookups
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "250px",
                            overflow: "hidden",
                            "overflow-y": "auto",
                            "border-top": "1px solid #ddd"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "slds-form-element__control pa-2 pb-3 noselect"
                            },
                            _vm._l(_vm.getLookUpSelection, function(
                              fieldOption
                            ) {
                              return _c(
                                "div",
                                {
                                  key: fieldOption.id,
                                  staticClass: "slds-checkbox pt-1"
                                },
                                [
                                  _c("input", {
                                    staticClass: "cursor-pointer",
                                    attrs: {
                                      readonly: "",
                                      type: "checkbox",
                                      id: fieldOption.id
                                    },
                                    domProps: {
                                      checked:
                                        _vm.selection.findIndex(function(i) {
                                          return i.name === fieldOption.name
                                        }) > -1
                                          ? true
                                          : false
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.addLookupFilter(fieldOption)
                                      }
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "slds-checkbox__label cursor-pointer",
                                      attrs: { for: fieldOption.id }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "slds-checkbox_faux"
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "slds-form-element__label"
                                        },
                                        [_vm._v(" " + _vm._s(fieldOption.name))]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.getSubFilterType() === "Enum"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "250px",
                            overflow: "hidden",
                            "overflow-y": "auto",
                            "border-top": "1px solid #ddd"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "slds-form-element__control pa-2 pb-3 noselect"
                            },
                            _vm._l(_vm.getEnumFieldOptionsFiltered, function(
                              fieldOption
                            ) {
                              return _c(
                                "div",
                                {
                                  key: fieldOption.value,
                                  staticClass: "slds-checkbox pt-1"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: fieldOption.selected,
                                        expression: "fieldOption.selected"
                                      }
                                    ],
                                    staticClass: "cursor-pointer",
                                    attrs: {
                                      type: "checkbox",
                                      id: fieldOption.value,
                                      "true-value": true,
                                      "false-value": false
                                    },
                                    domProps: {
                                      checked: false,
                                      checked: Array.isArray(
                                        fieldOption.selected
                                      )
                                        ? _vm._i(fieldOption.selected, null) >
                                          -1
                                        : fieldOption.selected
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.addEnumFilter()
                                      },
                                      change: function($event) {
                                        var $$a = fieldOption.selected,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                fieldOption,
                                                "selected",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                fieldOption,
                                                "selected",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(fieldOption, "selected", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "slds-checkbox__label cursor-pointer",
                                      attrs: { for: fieldOption.value }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "slds-checkbox_faux"
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "slds-form-element__label"
                                        },
                                        [_vm._v(" " + _vm._s(fieldOption.text))]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-0 sfl-filter-button",
                      staticStyle: { width: "100%", color: "white" },
                      attrs: { small: "", color: "#1b96ff", tile: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("apply-action", _vm.header)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.autoRefresh ? "Close" : "Apply"))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }