import _Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    $getRemoteComponent: (refComponentName: string, scope: any) => HTMLFormElement;
    $keyPressIntegerValidation: (evt: { which: boolean; keyCode: number; preventDefault: () => {} }) => boolean;
    $keyPressFloatValidation: (evt: { which: boolean; keyCode: number; preventDefault: () => {} }) => boolean;
    $getClone: (data: any) => any;
    $getRandomId: (length?: number) => string;
    $findParentByName: (name: string, scope: any) => any;
    $getAllNestedInputs: (component: any, errors?: boolean) => any[];
  }
}

export function LctPlugin(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$getClone = (data: any): any => {
    return JSON.parse(JSON.stringify(data));
  };
  Vue.prototype.$getRemoteComponent = (refComponentName: string, scope: any): HTMLFormElement => {
    return scope.$refs[refComponentName] as HTMLFormElement;
  };
  Vue.prototype.$getAllNestedInputs = (c: any, errors?: boolean): any[] => {
    let inputs: Vue[] = [];
    errors = errors || false;
    if (c.$_modelEvent === 'input') {
      if (errors && c.errorBucket.length > 0) {
        inputs.push(c);
      }
      if (!errors) {
        inputs.push(c);
      }
    }
    c.$children.forEach((child: any) => {
      inputs = inputs.concat(Vue.prototype.$getAllNestedInputs(child, errors));
    });

    return inputs;
  };
  Vue.prototype.$findParentByName = (name: string, scope: any): any => {
    let parent = scope.$parent;
    while (parent) {
      if (parent.$options.name === name) {
        return parent;
      }
      parent = parent.$parent;
    }
    return null;
  };
  Vue.prototype.$getRandomId = (length?: number): string => {
    if (!length) {
      length = 32;
    }
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  Vue.prototype.$keyPressIntegerValidation = (evt: {
    which: boolean;
    keyCode: number;
    target: {
      value: string | null;
    };
    preventDefault: () => {};
  }): boolean => {
    // @ts-ignore
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    // @ts-ignore: Unreachable code error
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    } else {
      return true;
    }
    return true;
  };
  Vue.prototype.$keyPressFloatValidation = (evt: {
    which: boolean;
    keyCode: number;
    target: {
      value: string | null;
    };
    preventDefault: () => {};
  }): boolean => {
    // @ts-ignore
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (evt.target.value && evt.target.value.indexOf('.') > -1 && charCode === 46) {
      evt.preventDefault();
    }
    // @ts-ignore: Unreachable code error
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
    return true;
  };
}
