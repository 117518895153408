var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pushed.editedRecord
    ? _c(
        "div",
        { ref: "floatingMessagRef", staticClass: "layout-floating-message" },
        [
          _vm.pushed.editedRecord.messages &&
          _vm.pushed.editedRecord.messages.refund_details_messages
            ? _c("MessagesCard", {
                attrs: {
                  messages:
                    _vm.pushed.editedRecord.messages.refund_details_messages,
                  title: "Refund Details"
                }
              })
            : _vm._e(),
          _vm.pushed.messages.length > 0
            ? _c("MessagesCard", {
                attrs: {
                  messages: _vm.pushed.editedRecord.messages,
                  title: "Messages"
                }
              })
            : _vm._e(),
          _vm.pushed.errors.length > 0
            ? _c(
                "v-card",
                [
                  _c(
                    "v-alert",
                    {
                      staticStyle: {
                        "font-weight": "strong",
                        "font-size": "14px",
                        "line-height": "15px"
                      },
                      attrs: {
                        type: "error",
                        border: "left",
                        text: "",
                        outlined: "",
                        dense: ""
                      }
                    },
                    [
                      _c("div", [_c("b", [_vm._v("Errors:")])]),
                      _vm._l(_vm.pushed.errors, function(error, errorIndex) {
                        return _c("div", { key: errorIndex }, [
                          _vm._v("* " + _vm._s(error))
                        ])
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }