import { render, staticRenderFns } from "./productPreferencesTab.vue?vue&type=template&id=671178fe&scoped=true&"
import script from "./productPreferencesTab.vue?vue&type=script&lang=ts&"
export * from "./productPreferencesTab.vue?vue&type=script&lang=ts&"
import style0 from "./productPreferencesTab.vue?vue&type=style&index=0&id=671178fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "671178fe",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardSubtitle,VCardText,VCardTitle,VCol,VContainer,VDialog,VDivider,VForm,VIcon,VProgressLinear,VRow,VSelect,VSnackbar,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('671178fe')) {
      api.createRecord('671178fe', component.options)
    } else {
      api.reload('671178fe', component.options)
    }
    module.hot.accept("./productPreferencesTab.vue?vue&type=template&id=671178fe&scoped=true&", function () {
      api.rerender('671178fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/lenderDetailsTabs/productPreferencesTab.vue"
export default component.exports