



































































import { Vue } from 'vue-property-decorator';
import SfCustomDialog from '@/components/SFComponents/SfCustomDialog.vue';
import SfDataGrid from '@/components/SFComponents/SfDataGrid.vue';
import LenderServiceConfigurationFeeEditForm from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfigurationFeeEditForm.vue';
import { FeeI } from '@/interfaces';
import UserService from '@/services/api/User.service';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import moment from 'moment';

const emptyFeeRecord = {
  id: null,
  name: null,
  orgId: '',
  sfid: null,
  active: false,
  externalId: null,
  startDate: null,
  endDate: null,
  productTypes: [],
  states: [],
  effectiveDate: null,
  effectiveTypeDate: 'AllTime',
  allowableFee: 0,
  allowableFeeType: 'Not to Exceed',
  freeLookPeriod: 0,
  createdDate: null,
  createdById: null,
  dateTimeUpdated: null,
  updatedById: null,
  providers: [],
  applyToPastRecords: false,
} as FeeI;

export default Vue.extend({
  name: 'LenderServiceConfigurationFee',
  data() {
    return {
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      saveButtonActive: true,
      editedRecord: null as null | FeeI,
      gridApiUrls: {
        metadata: '/uiapi/grids/config-cancellation-fee',
        data: '/uiapi/config-cancellation-fee/search',
        lookups: {
          providers: {
            metadata: '/uiapi/grids/provider',
            data: '/uiapi/provider/search',
          },
        },
      },
    };
  },
  components: {
    SfDataGrid,
    SfCustomDialog,
    LenderServiceConfigurationFeeEditForm,
    ConfirmationDialog,
  },
  computed: {
    applyToPastRecordConfirmationButtons() {
      return [
        {
          name: 'Save',
          callback: () => {
            this.applyToPastRecords();
            setTimeout(() => {
              this.$getRemoteComponent('FeeApplyToPastRecordDialog', this)
                .external()
                .close();
            }, 250);
          },
        },
      ];
    },
  },
  methods: {
    openConfirmationDialogApplyToPastRecords() {
      this.$getRemoteComponent('FeeApplyToPastRecordDialog', this)
        .external()
        .open();
    },
    checkApplyToPastRecordsSaveButton() {
      if (this.editedRecord && this.editedRecord.id) {
        return this.editedRecord.applyToPastRecords;
      } else {
        return false;
      }
    },
    checkApplyToPastRecords() {
      if (this.editedRecord) {
        if (!this.editedRecord.active) {
          return true;
        }
        if (this.editedRecord.endDate) {
          if (moment(this.editedRecord.endDate) < moment().add(-1, 'days')) {
            return true;
          }
        }
        return this.editedRecord.applyToPastRecords;
      } else {
        return true;
      }
    },
    refreshGrid() {
      this.getRemoteComponent('LenderServiceConfigurationFeeGrid').loadData();
    },
    async saveFormData() {
      this.saveButtonActive = false;
      this.getRemoteComponent('LenderServiceConfigurationFeeEditForm')
        .submitForm()
        .then(() => {
          this.saveButtonActive = true;
          this.getRemoteComponent('FeeCustomDialogForm')
            .external()
            .close();
        })
        .catch(() => {
          this.saveButtonActive = true;
        });
    },
    async applyToPastRecords() {
      this.saveButtonActive = false;
      this.getRemoteComponent('LenderServiceConfigurationFeeEditForm')
        .applyToPastRecords()
        .then(() => {
          this.saveButtonActive = true;
          this.getRemoteComponent('FeeCustomDialogForm')
            .external()
            .close();
        })
        .catch(() => {
          this.saveButtonActive = true;
        });
    },
    addNewFeeRule() {
      this.editedRecord = JSON.parse(JSON.stringify(emptyFeeRecord));
      if (this.editedRecord) {
        this.editedRecord.orgId = this.orgId;
      }
    },
    editRecord(record: FeeI) {
      this.editedRecord = JSON.parse(JSON.stringify(record));
      this.openEditDialog();
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    openEditDialog() {
      this.getRemoteComponent('FeeCustomDialogForm')
        .external()
        .open();
    },
  },
  props: ['footerHeight', 'orgId'],
});
