var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": _vm.maxWidth, scrollable: "" },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "dialog-card-header" }, [
            _c("span", { staticClass: "headline" }, [_vm._v(_vm._s(_vm.title))])
          ]),
          _c(
            "v-icon",
            {
              staticClass: "confirmation-dialog-close-button",
              attrs: { center: "", large: "", color: "white" },
              on: {
                click: function($event) {
                  return _vm.cancel()
                }
              }
            },
            [_vm._v(" mdi-close ")]
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticStyle: { height: "95%" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-progress-linear", {
                            directives: [
                              {
                                name: "visible",
                                rawName: "v-visible",
                                value: _vm.processing,
                                expression: "processing"
                              }
                            ],
                            attrs: {
                              indeterminate: "",
                              query: true,
                              color: "blue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.messages.length > 0,
                          expression: "messages.length > 0"
                        }
                      ]
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "line-height": "15px"
                                  },
                                  attrs: {
                                    type: "info",
                                    border: "left",
                                    text: "",
                                    outlined: "",
                                    dense: "",
                                    autocomplete: "none"
                                  }
                                },
                                [
                                  _c("div", [_c("b", [_vm._v("Mesagess:")])]),
                                  _vm._l(_vm.messages, function(
                                    message,
                                    messageIndex
                                  ) {
                                    return _c("div", { key: messageIndex }, [
                                      _vm._v("* " + _vm._s(message))
                                    ])
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.length > 0,
                          expression: "errors.length > 0"
                        }
                      ]
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "confirmation-dialog-errors",
                                  attrs: {
                                    type: "error",
                                    border: "left",
                                    text: "",
                                    outlined: "",
                                    dense: "",
                                    autocomplete: "none"
                                  }
                                },
                                [
                                  _c("div", [_c("b", [_vm._v("Errors:")])]),
                                  _vm._l(_vm.errors, function(
                                    error,
                                    errorIndex
                                  ) {
                                    return _c("div", { key: errorIndex }, [
                                      _vm._v("* " + _vm._s(error))
                                    ])
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-form",
                    { attrs: { disabled: _vm.processing } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-0 ma-0" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "ma-0 pa-0" },
                                [
                                  _c("v-card-subtitle", {
                                    domProps: { innerHTML: _vm._s(_vm.message) }
                                  }),
                                  _c("v-card-subtitle", [_vm._t("message")], 2)
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "visible",
                      rawName: "v-visible",
                      value: _vm.cancelVisible,
                      expression: "cancelVisible"
                    }
                  ],
                  attrs: {
                    disabled: _vm.processing || _vm.cancelDisabled,
                    text: "",
                    elevation: "2"
                  },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _vm._l(_vm.buttons, function(button, buttonIndex) {
                return _c(
                  "v-btn",
                  {
                    key: buttonIndex,
                    attrs: {
                      text: "",
                      elevation: "2",
                      color: button.color || "primary",
                      disabled: _vm.processing || button.disabled
                    },
                    on: { click: button.callback }
                  },
                  [_vm._v(" " + _vm._s(button.name) + " ")]
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }