import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import DataUpload from '@/views/DataUpload.vue';
import DealerPage from '@/views/DealerPage.vue';
import AccountsPage from '@/views/AccountsPage.vue';
import ProviderPage from '@/views/ProviderPage.vue';
import ProviderForm from '@/views/ProviderForm.vue';
import ProductTypeAlias from '@/views/ProductTypeAlias.vue';
import ProviderAlias from '@/views/ProviderAlias.vue';
import Lender from '@/views/Lender.vue';
import Product from '@/views/Product.vue';
import IDPPage from '@/views/IDPPage.vue';
import IDPPageDetails from '@/views/IDPPageDetails.vue';
import LogBrowser from '@/views/LogBrowserPage.vue';
import InformedLogBrowser from '@/views/InformedLogBrowserPage.vue';
import ApiPartnerLogBrowser from '@/views/ApiPartnerLogBrowser.vue';
import ExportDocument from '@/views/ExportDocument.vue';
import SfLenderServiceConfig from '@/views/SfLenderServiceConfig.vue';
import SfProductAuditHistory from '@/views/SfProductAuditHistory.vue';
import SfAccountAuditHistory from '@/views/SfAccountAuditHistory.vue';
import UserService from '../services/api/User.service';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: AccountsPage,
  },
  {
    path: '/dealers',
    name: 'Dealers',
    component: DealerPage,
  },
  {
    path: '/providers',
    name: 'Providers',
    component: ProviderPage,
  },
  {
    path: '/provider-form',
    name: 'Provider Form',
    component: ProviderForm,
  },
  {
    path: '/product-type-alias',
    name: 'Product Type Alias',
    component: ProductTypeAlias,
  },
  {
    path: '/provider-alias',
    name: 'Provider Alias',
    component: ProviderAlias,
  },
  {
    path: '/lenders',
    name: 'Lenders',
    component: Lender,
  },
  {
    path: '/log-browser',
    name: 'Log Browser',
    component: LogBrowser,
  },
  {
    path: '/informed-log-browser',
    name: 'Informed Log Browser',
    component: InformedLogBrowser,
  },
  {
    path: '/api-partner-log-browser',
    name: 'API Partner Log Browser',
    component: ApiPartnerLogBrowser,
  },
  {
    path: '/export-document',
    name: 'Export Document',
    component: ExportDocument,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/data-upload',
    name: 'Data Upload',
    component: DataUpload,
  },
  {
    path: '/products',
    name: 'Products',
    component: Product,
  },
  {
    path: '/idp-details/:id?',
    name: 'IDP',
    component: IDPPageDetails,
  },
  {
    path: '/idp',
    name: 'IDP Details',
    component: IDPPage,
  },
  {
    path: '/sf-lender-service-config',
    name: 'SF Lender Service Config',
    component: SfLenderServiceConfig,
  },
  {
    path: '/sf-audit-history/product/:sfid',
    name: 'SF Product Audit History',
    component: SfProductAuditHistory,
  },
  {
    path: '/sf-audit-history/account/:sfid',
    name: 'SF Account Audit History',
    component: SfAccountAuditHistory,
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter() {
      location.href = '/uiapi/aws-cognito/logout';
    },
  },
  {
    path: '/login',
    beforeEnter() {
      location.href = '/uiapi/aws-cognito/login';
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (['Salesforce Admin', 'Salesforce Standard', 'Salesforce Employee'].includes(UserService.getUserRole())) {
    const routeName = to?.name || 'none';
    if (
      ['SF Product Audit History', 'SF Account Audit History', 'SF Lender Service Config', 'Logout'].includes(routeName)
    ) {
      next();
    } else {
      next('/logout');
    }
  } else {
    document.title = 'LCT ' + (to?.name || '');
    next();
  }
});

export default router;
