export interface EnumItemI {
  text: string;
  value: string | boolean;
}

class DealerPageService {
  protected DealerStatesUS: EnumItemI[];
  protected DealerStatesCanada: EnumItemI[];
  protected DealerStatus: EnumItemI[];
  protected CommunicationPreference: EnumItemI[];
  protected DealerReserve: EnumItemI[];
  protected DealerCountry: EnumItemI[];
  protected DealerTimeZones: EnumItemI[];

  constructor() {
    (this.DealerCountry = [
      {
        text: 'United States',
        value: 'US',
      },
      {
        text: 'Canada',
        value: 'CA',
      },
    ]),
      (this.DealerReserve = [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ]),
      (this.CommunicationPreference = [
        {
          text: 'Email',
          value: 'Email',
        },
        {
          text: 'Fax',
          value: 'Fax',
        },
      ]),
      (this.DealerStatus = [
        {
          text: 'Active',
          value: 'Active',
        },
        {
          text: 'Inactive',
          value: 'Inactive',
        },
        {
          text: 'Suspended',
          value: 'Suspended',
        },
        {
          text: 'Out of Business',
          value: 'Out of Business',
        },
      ]),
      (this.DealerStatesUS = [
        {
          text: 'Alabama',
          value: 'AL',
        },
        {
          text: 'Alaska',
          value: 'AK',
        },
        {
          text: 'American Forces Americas',
          value: 'AA',
        },
        {
          text: 'American Forces Europe',
          value: 'AE',
        },
        {
          text: 'American Forces Pacific',
          value: 'AP',
        },
        {
          text: 'American Samoa',
          value: 'AS',
        },
        {
          text: 'Arizona',
          value: 'AZ',
        },
        {
          text: 'Arkansas',
          value: 'AR',
        },
        {
          text: 'California',
          value: 'CA',
        },
        {
          text: 'Colorado',
          value: 'CO',
        },
        {
          text: 'Connecticut',
          value: 'CT',
        },
        {
          text: 'Delaware',
          value: 'DE',
        },
        {
          text: 'District Of Columbia',
          value: 'DC',
        },
        {
          text: 'Federated States Of Micronesia',
          value: 'FM',
        },
        {
          text: 'Florida',
          value: 'FL',
        },
        {
          text: 'Georgia',
          value: 'GA',
        },
        {
          text: 'Guam',
          value: 'GU',
        },
        {
          text: 'Hawaii',
          value: 'HI',
        },
        {
          text: 'Idaho',
          value: 'ID',
        },
        {
          text: 'Illinois',
          value: 'IL',
        },
        {
          text: 'Indiana',
          value: 'IN',
        },
        {
          text: 'Iowa',
          value: 'IA',
        },
        {
          text: 'Kansas',
          value: 'KS',
        },
        {
          text: 'Kentucky',
          value: 'KY',
        },
        {
          text: 'Louisiana',
          value: 'LA',
        },
        {
          text: 'Maine',
          value: 'ME',
        },
        {
          text: 'Marshall Islands',
          value: 'MH',
        },
        {
          text: 'Maryland',
          value: 'MD',
        },
        {
          text: 'Massachusetts',
          value: 'MA',
        },
        {
          text: 'Michigan',
          value: 'MI',
        },
        {
          text: 'Minnesota',
          value: 'MN',
        },
        {
          text: 'Mississippi',
          value: 'MS',
        },
        {
          text: 'Missouri',
          value: 'MO',
        },
        {
          text: 'Montana',
          value: 'MT',
        },
        {
          text: 'Nebraska',
          value: 'NE',
        },
        {
          text: 'Nevada',
          value: 'NV',
        },
        {
          text: 'New Hampshire',
          value: 'NH',
        },
        {
          text: 'New Jersey',
          value: 'NJ',
        },
        {
          text: 'New Mexico',
          value: 'NM',
        },
        {
          text: 'New York',
          value: 'NY',
        },
        {
          text: 'North Carolina',
          value: 'NC',
        },
        {
          text: 'North Dakota',
          value: 'ND',
        },
        {
          text: 'Northern Mariana Islands',
          value: 'MP',
        },
        {
          text: 'Ohio',
          value: 'OH',
        },
        {
          text: 'Oklahoma',
          value: 'OK',
        },
        {
          text: 'Oregon',
          value: 'OR',
        },
        {
          text: 'Palau',
          value: 'PW',
        },
        {
          text: 'Pennsylvania',
          value: 'PA',
        },
        {
          text: 'Puerto Rico',
          value: 'PR',
        },
        {
          text: 'Rhode Island',
          value: 'RI',
        },
        {
          text: 'South Carolina',
          value: 'SC',
        },
        {
          text: 'South Dakota',
          value: 'SD',
        },
        {
          text: 'Tennessee',
          value: 'TN',
        },
        {
          text: 'Texas',
          value: 'TX',
        },
        {
          text: 'Utah',
          value: 'UT',
        },
        {
          text: 'Vermont',
          value: 'VT',
        },
        {
          text: 'Virgin Islands',
          value: 'VI',
        },
        {
          text: 'Virginia',
          value: 'VA',
        },
        {
          text: 'Washington',
          value: 'WA',
        },
        {
          text: 'West Virginia',
          value: 'WV',
        },
        {
          text: 'Wisconsin',
          value: 'WI',
        },
        {
          text: 'Wyoming',
          value: 'WY',
        },
      ]),
      (this.DealerStatesCanada = [
        {
          text: 'Alberta',
          value: 'AB',
        },
        {
          text: 'British Columbia',
          value: 'BC',
        },
        {
          text: 'Manitoba',
          value: 'MB',
        },
        {
          text: 'New Brunswick',
          value: 'NB',
        },
        {
          text: 'Newfoundland and Labrador',
          value: 'NL',
        },
        {
          text: 'Northwest Territories',
          value: 'NT',
        },
        {
          text: 'Nova Scotia',
          value: 'NS',
        },
        {
          text: 'Nunavut',
          value: 'NU',
        },
        {
          text: 'Ontario',
          value: 'ON',
        },
        {
          text: 'Prince Edward Island',
          value: 'PE',
        },
        {
          text: 'Quebec',
          value: 'QC',
        },
        {
          text: 'Saskatchewan',
          value: 'SK',
        },
        {
          text: 'Yukon',
          value: 'YT',
        },
      ]);
    this.DealerTimeZones = [
      {
        text: 'US/Alaska',
        value: 'US/Alaska',
      },
      {
        text: 'US/Pacific',
        value: 'US/Pacific',
      },
      {
        text: 'US/Michigan',
        value: 'US/Michigan',
      },
      {
        text: 'US/Arizona',
        value: 'US/Arizona',
      },
      {
        text: 'US/Indiana-Starke',
        value: 'US/Indiana-Starke',
      },
      {
        text: 'US/Aleutian',
        value: 'US/Aleutian',
      },
      {
        text: 'US/Hawaii',
        value: 'US/Hawaii',
      },
      {
        text: 'US/East-Indiana',
        value: 'US/East-Indiana',
      },
      {
        text: 'US/Central',
        value: 'US/Central',
      },
      {
        text: 'US/Mountain',
        value: 'US/Mountain',
      },
      {
        text: 'US/Samo',
        value: 'US/Samo',
      },
    ];
  }

  async getItems(
    EnumID:
      | 'DealerStatesUS'
      | 'DealerStatesCanada'
      | 'DealerStatus'
      | 'CommunicationPreference'
      | 'DealerReserve'
      | 'DealerCountry'
      | 'DealerTimeZones',
    Concate?: boolean | undefined,
    forceReject?: boolean | undefined,
  ): Promise<EnumItemI[]> {
    return new Promise((resolve, reject) => {
      if (forceReject) {
        reject('Dealer Page Service Error');
      } else {
        setTimeout(() => {
          if (Concate) {
            resolve(
              this[EnumID].map((item: EnumItemI) => {
                return {
                  text: '(' + item.value + ') ' + item.text,
                  value: item.value,
                };
              }) as EnumItemI[],
            );
          } else {
            resolve(this[EnumID] as EnumItemI[]);
          }
        }, 10);
      }
    });
  }
}

export default new DealerPageService();
