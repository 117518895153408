import ApiService from '@/services/api/Api.service';
import { Product, Account, Response } from '../../interfaces';
import { SearchRequestBodyI } from '../../interfaces';
import { AxiosResponse } from 'axios';

export interface ApiRefundResponse {
  affectedRows?: number;
}

class ProductService extends ApiService {
  async getAllProducts(): Promise<Product> {
    return (await this.axios.get('/uiapi/product/get-all')).data;
  }

  async getById(id: number): Promise<Product> {
    return (await this.axios.get<Product>('/uiapi/product/' + id)).data;
  }

  async update(data: Product): Promise<Response<Product>> {
    return (await this.axios.post<Product, AxiosResponse<Response<Product>>>('/uiapi/product/' + data.id, data)).data;
  }

  async create(data: Product): Promise<Response<Product>> {
    return (await this.axios.post<Product, AxiosResponse<Response<Product>>>('/uiapi/product/', data)).data;
  }

  GetLetterIconLink(letter: string) {
    switch (letter) {
      case 'Dealer':
        return require('@/assets/icons/dl_blank.png');
      case 'Dealer 2nd Notice':
        return require('@/assets/icons/dl_2nd.png');
      case 'Dealer Final Notice':
        return require('@/assets/icons/dl_3rd.png');
      case 'Provider':
        return require('@/assets/icons/pl_blank.png');
      case 'Provider 2nd Notice':
        return require('@/assets/icons/pl_2nd.png');
      case 'Provider Final Notice':
        return require('@/assets/icons/pl_3rd.png');
      default:
        return '';
    }
    return '';
  }

  async updateProductAndAccount(data: {
    product: Product;
    account: Account;
  }): Promise<Response<{ product: Product; account: Account }>> {
    return (
      await this.axios.post<
        {
          product: Product;
          account: Account;
        },
        AxiosResponse<
          Response<{
            product: Product;
            account: Account;
          }>
        >
      >('/uiapi/product-account/' + data.product.id, data)
    ).data;
  }

  async validateProductData(data: { product: Product }): Promise<Response<{ product: Product }>> {
    return (
      await this.axios.post<
        {
          product: Product;
        },
        AxiosResponse<
          Response<{
            product: Product;
          }>
        >
      >('/uiapi/product/validate', data)
    ).data;
  }

  async restoreProcessingStatus(data: { productId: number }): Promise<Response<Product>> {
    return (
      await this.axios.post<{ productId: number }, AxiosResponse<Response<Product>>>(
        '/uiapi/product-restore-processing-status',
        data,
      )
    ).data;
  }

  async finalizeProcessingStatus(data: { productId: number }): Promise<Response<Product>> {
    return (
      await this.axios.post<{ productId: number }, AxiosResponse<Response<Product>>>(
        '/uiapi/product-finalize-processing-status',
        data,
      )
    ).data;
  }

  async sendRequestApiRefund(request: SearchRequestBodyI): Promise<Response<ApiRefundResponse>> {
    request.conditions = request.conditions.filter(c => c.fieldName === 'id');
    return (
      await this.axios.post<SearchRequestBodyI, AxiosResponse<Response<ApiRefundResponse>>>(
        '/uiapi/product/request-api-refund',
        request,
      )
    ).data;
  }

  async createNoCancellableProduct(data: {
    accountId: number;
  }): Promise<Response<{ product: Product; account: Account }>> {
    return (
      await this.axios.post<{ accountId: number }, AxiosResponse<Response<{ product: Product; account: Account }>>>(
        '/uiapi/product-create-no-cancellable/',
        data,
      )
    ).data;
  }
}

export default new ProductService();
