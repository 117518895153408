


































































































































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import axios from '../../services/api/Api.service';
import FormatDisplayService from '../../services/FormatDisplay.service';
import ValidationService from '../../services/Validations.service';
import SearchInputField from '@/components/SearchInputField.vue';
import MetadataService, { MetadataType } from '../../services/api/Metadata.service';
import CustomTooltip from '@/components/CustomTooltip.vue';
import { AxiosResponse } from 'axios';
import { SerchResponse } from '../../../../src/domain/grid/interfaces';
import { Product } from '@/interfaces/product.interface';
import moment from 'moment';
import { left } from 'fp-ts/Either';
/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

/**
 *  Update record endpoint URL
 */
const recordUpdateEndpointUrl = '/uiapi/account/{id}';
/**
 *  Insert record endpoint URL
 */
const recordInsertEndpointUrl = '/uiapi/account';

export interface AccountDetailsRecordI {
  VIN: string | null;
  accountNumber: string | null;
  accountProcessingStatus: string | null;
  cancelOdometer: number | null;
  coCustomerFirstName: string | null;
  coCustomerLastName: string | null;
  custom: string | null;
  customerBusinessName: string | null;
  customerCity: string | null;
  customerCountry: string | null;
  customerFirstName: string | null;
  customerLastName: string | null;
  customerState: string | null;
  customerStreet: string | null;
  customerZIP: string | null;
  dealerNumber: string | null;
  id: string | null;
  make: string | null;
  model: string | null;
  name: string | null;
  orgId: string | null;
  servicingSystemUpdate: string | null;
  sfid: string | null;
  year: string | null;
  dealer: {
    name: string | null;
    dealerNumber: string | null;
    dealerState: string | null;
  } | null;
  updatedByName: string | null;
  createdByName: string | null;
  dateTimeCreated: string | null;
  dateTimeUpdated: string | null;
  businessChannel: string | null;
  vehicleInServiceDate: string | null;
  products: Product[];
}

export const emptyAccountDetailsRecord = {
  VIN: null,
  accountNumber: null,
  accountProcessingStatus: 'Open',
  cancelOdometer: null,
  coCustomerFirstName: null,
  coCustomerLastName: null,
  custom: null,
  customerBusinessName: null,
  customerCity: null,
  customerCountry: null,
  customerFirstName: null,
  customerLastName: null,
  customerState: null,
  customerStreet: null,
  customerZIP: null,
  dealerNumber: null,
  products: [],
  id: null,
  make: null,
  model: null,
  name: null,
  orgId: null,
  servicingSystemUpdate: null,
  sfid: null,
  year: null,
  dealer: {
    name: null,
    dealerNumber: null,
    dealerState: null,
  },
  updatedByName: null,
  createdByName: null,
  dateTimeCreated: null,
  dateTimeUpdated: null,
  businessChannel: null,
  vehicleInServiceDate: null,
} as AccountDetailsRecordI;

export default Vue.extend({
  name: 'AccountDetailsForm',
  components: { SearchInputField, CustomTooltip },
  data: () => ({
    moment: moment,
    messages: [],
    errors: [] as string[],
    ValidationService: ValidationService,
    service: MetadataService,
    metadataEnumsMap: new Map(),
    valid: true,
    processing: false,
    editedRecord: JSON.parse(JSON.stringify(emptyAccountDetailsRecord)) as AccountDetailsRecordI,
    editedRecordLists: {
      state: [],
      country: [],
    },
    snackBarSuccess: false,
    snackBarError: false,
  }),
  async created() {
    this.metadataEnumsMap = await this.service.loadData(MetadataType.ACCOUNT);
    this.editedRecord = this.accountData;
  },
  watch: {
    'editedRecord.customerState': {
      handler(newValue) {
        if (newValue) {
          this.fixCountryBasedOnSelectedState();
        }
      },
      deep: true,
    },
    $props: {
      handler() {
        this.editedRecord = this.accountData;
      },
      deep: true,
    },
  },
  filters: {
    toDate(v: string | null) {
      if (v) {
        return FormatDisplayService.getRender('toDateTime')(v);
      } else {
        return ' ';
      }
    },
    nullToEmpty(v: string | null) {
      return v ? v : ' ';
    },
  },
  methods: {
    validationFunctions: function(v: string | null) {
      const scope = this;
      return {
        checkIfVehicleInServiceDateIsEqualToOrSmallerThanProductsContractDate() {
          let success = true as boolean | string;

          if (
            scope.editedRecord.vehicleInServiceDate &&
            scope.editedRecord.products &&
            scope.editedRecord.products.length
          ) {
            let invalidVehicleInServiceDate = false;

            scope.editedRecord.products.forEach(product => {
              if (
                product.contractDate &&
                moment(scope.editedRecord.vehicleInServiceDate) > moment(product.contractDate)
              ) {
                invalidVehicleInServiceDate = true;
              }
            });

            if (invalidVehicleInServiceDate) {
              return 'Please check Vehicle In-Service Date - Vehicle In-Service Date may not be after Contract Date';
            }
          }

          return success;
        },
        checkIfCancelOdometerIsLessThanPurchaseOdometer() {
          let success = true as boolean | string;
          let cancelOdometer = 0 as number;

          if (scope.editedRecord.cancelOdometer) {
            cancelOdometer = scope.editedRecord.cancelOdometer;
          } else {
            cancelOdometer = 0;
          }

          if (scope.editedRecord.products && scope.editedRecord.products.length > 0) {
            const cancelOdometerLessThanPurchaseOdometer = !scope.editedRecord.products.every(product => {
              if (product.purchaseOdometer && product.purchaseOdometer > cancelOdometer) {
                return false;
              }

              return true;
            });

            if (cancelOdometerLessThanPurchaseOdometer) {
              return 'Cancel Odometer not saved - cannot be less than Purchase Odometer.';
            }
          }

          return success;
        },
      };
    },
    fixCountryBasedOnSelectedState() {
      const stateItems = this.metadataEnumsMap.get('customerState');
      const selectedState = stateItems.find(
        (state: { text: string; value: string }) => state.value == this.editedRecord.customerState,
      );
      if (selectedState) {
        this.editedRecord.customerCountry = selectedState.text.split('(')[1].split(')')[0];
      }
    },
    async formDataRefresh() {
      if (this.editedRecord.id !== null) {
        this.editedRecord = (await MetadataService.getRecord(
          { id: this.editedRecord.id },
          'All',
          '/uiapi/account/search',
        )) as AccountDetailsRecordI;
        this.$emit('account-data-refresh', this.editedRecord);
      }
    },
    editType(): 'INSERT' | 'UPDATE' {
      if (this.editedRecord.id === null) {
        return 'INSERT';
      } else {
        return 'UPDATE';
      }
    },
    initForm(): void {
      this.errors.length = 0;
      this.messages.length = 0;
    },
    submitDataRequest() {
      // reset error box on send request
      this.errors.length = 0;
      this.messages.length = 0;
      this.processing = false;
      this.fixCountryBasedOnSelectedState();

      const requestObject = Object.assign({}, this.editedRecord as AccountDetailsRecordI);
      let url = '';

      if (requestObject.dealer) {
        if (!requestObject.dealer.dealerNumber) {
          requestObject.dealer = null;
        }
      }

      if (!requestObject.id) {
        url = recordInsertEndpointUrl;
      } else {
        url = recordUpdateEndpointUrl.replace('{id}', requestObject.id.toString());
      }
      //validation part, preparing data
      if (this.valid) {
        this.processing = true;
        axiosInstance
          .post<AccountDetailsRecordI, AxiosResponse<SerchResponse<AccountDetailsRecordI>>>(url, requestObject, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(result => {
            this.processing = false;
            if (result.data.success) {
              this.snackBarSuccess = true;
              this.messages = []; //result.data.messages; @todo: Przemek to check if it us used. search doesn't return "messages" property
              result.data.data.dealer = this.editedRecord.dealer;
              this.editedRecord = result.data.data;
              this.$emit('accountUpdated', this.editedRecord);
            } else {
              // Setting errors will show them on form
              this.snackBarError = true;
              this.error().set(result.data.errors);
            }
          })
          .catch(error => {
            this.snackBarError = true;
            this.processing = false;
            this.error().add(error);
          });
      } else {
        this.resetValidation();
        this.validate();
      }
    },
    selectDealer(rowData: { name: string; dealerNumber: string; dealerState: string }) {
      this.editedRecord.dealer = rowData;
      this.editedRecord.dealerNumber = rowData.dealerNumber;
    },
    // Return Access to ProviderAliasEditGrid Component ( all properties, all methods )
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    /**
     * Reste validation
     */
    resetValidation(): number {
      return setTimeout(() => {
        this.getRemoteComponent('accountForm').resetValidation();
      }, 250);
    },
    /**
     * Do validation on form
     */
    validate(): number {
      return setTimeout(() => {
        this.getRemoteComponent('accountForm').validate();
      }, 500);
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
  },
  props: {
    accountData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
