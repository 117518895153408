var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-layout",
        {
          attrs: {
            pageName: "Accounts",
            pageIcon: "mdi-format-list-text",
            contentScroll: false,
            contentList: _vm.screenList,
            contentSelected: _vm.screen
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "grid" }, slot: "grid" },
            [
              _c(
                "data-grid",
                _vm._b(
                  {
                    ref: "AccountListGrid",
                    attrs: {
                      itemsPerPage: [50, 100, 200, 300, 400, 500],
                      itemsPerPageInitial: 100,
                      actions: _vm.actions,
                      "button-actions": _vm.buttonActions,
                      "fixed-conditions":
                        typeof _vm.lender === "string" && _vm.lender !== ""
                          ? [
                              {
                                fieldName: "orgId",
                                value: _vm.lender,
                                fieldType: "string",
                                conditionComparatorOption: "equal"
                              }
                            ]
                          : [],
                      "fixed-conditions-prevent-call": false,
                      headersAutoParserMapping: ""
                    },
                    on: {
                      onFilterRecords: _vm.setFilteredRecords,
                      "row-selection": _vm.rowSelection
                    }
                  },
                  "data-grid",
                  _vm.dataGrid,
                  false
                )
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "details" }, slot: "details" },
            [
              _vm.screen === "details"
                ? _c("account-details", {
                    attrs: {
                      "account-data": this.accountDetails.accountData,
                      filteredAccounts: _vm.filteredAccounts,
                      selectedAccountIndex: _vm.selectedAccountIndex,
                      disabled:
                        !_vm.isEditedRecordLenderActive &&
                        _vm.selectedAccountIndex !== null,
                      eager: ""
                    },
                    on: {
                      switchScreen: function($event) {
                        _vm.screen = "grid"
                        _vm.refreshGrid
                      },
                      "update:accountData": function($event) {
                        return _vm.$set(
                          this.accountDetails,
                          "accountData",
                          $event
                        )
                      },
                      "update:account-data": function($event) {
                        return _vm.$set(
                          this.accountDetails,
                          "accountData",
                          $event
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [_c("send-cancellation-action", { ref: "SendCancellationAction" })],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }