var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-layout",
    { attrs: { pageName: "Product Type Alias", pageIcon: "mdi-link-variant" } },
    [
      _c("div", { attrs: { slot: "toolbar" }, slot: "toolbar" }),
      _c(
        "div",
        { attrs: { slot: "buttons" }, slot: "buttons" },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "900px", scrollable: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            staticClass: "dealer-page-button",
                            attrs: {
                              color: "primary",
                              elevation: "2",
                              disabled: false,
                              medium: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.openDialog()
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" NEW PRODUCT TYPE ALIAS ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "dialog-card-header" }, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Product Type Alias Data")
                    ])
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer"
                      },
                      attrs: { center: "", large: "", color: "white" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" mdi-close ")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticStyle: { height: "95%" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    directives: [
                                      {
                                        name: "visible",
                                        rawName: "v-visible",
                                        value: _vm.overlay,
                                        expression: "overlay"
                                      }
                                    ],
                                    attrs: {
                                      indeterminate: "",
                                      query: true,
                                      color: "blue"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.length > 0,
                                  expression: "errors.length > 0"
                                }
                              ]
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            "line-height": "15px"
                                          },
                                          attrs: {
                                            type: "error",
                                            border: "left",
                                            text: "",
                                            outlined: "",
                                            dense: ""
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c("b", [_vm._v("Errors:")])
                                          ]),
                                          _vm._l(_vm.errors, function(
                                            error,
                                            errorIndex
                                          ) {
                                            return _c(
                                              "div",
                                              { key: errorIndex },
                                              [_vm._v("* " + _vm._s(error))]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-form",
                            {
                              ref: "providerForm",
                              attrs: {
                                "lazy-validation": "",
                                disabled: _vm.overlay
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-container",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-checkbox", {
                                                            attrs: {
                                                              label: "Verified"
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .verified,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "verified",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.verified"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              label: "Alias",
                                                              rules:
                                                                _vm.aliasInputRules,
                                                              required: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .alias,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "alias",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.alias"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dense: "",
                                                              items:
                                                                _vm.productTypeOptions,
                                                              label:
                                                                "Product Type",
                                                              rules:
                                                                _vm.productTypeInputRules,
                                                              required: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .productType,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "productType",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.productType"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              label:
                                                                "Created By",
                                                              readonly: "",
                                                              disabled: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .createdByName,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "createdByName",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.createdByName"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              label:
                                                                "Updated By",
                                                              readonly: "",
                                                              disabled: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .updatedByName,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "updatedByName",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.updatedByName"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              label:
                                                                "Date/time Created",
                                                              readonly: "",
                                                              disabled: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .createdDate,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "createdDate",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.createdDate"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              label:
                                                                "Date/time Updated",
                                                              readonly: "",
                                                              disabled: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .updatedDate,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "updatedDate",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.updatedDate"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _vm.message
                                                            ? _c(
                                                                "v-alert",
                                                                {
                                                                  attrs: {
                                                                    border:
                                                                      "left",
                                                                    color:
                                                                      _vm.alertType,
                                                                    dark: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.message
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-3",
                                      attrs: { text: "", elevation: "2" },
                                      on: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary",
                                      attrs: {
                                        elevation: "2",
                                        disabled: _vm.overlay
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.submitDataRequest()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.editActionType === "UPDATE"
                                              ? "Update Product Type Alias"
                                              : "Add New Product Type Alias"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "500px", scrollable: "" },
              model: {
                value: _vm.deleteDialogOpen,
                callback: function($$v) {
                  _vm.deleteDialogOpen = $$v
                },
                expression: "deleteDialogOpen"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "dialog-card-header" }, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Delete Product Type Alias")
                    ])
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer"
                      },
                      attrs: { center: "", large: "", color: "white" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialogOpen = false
                        }
                      }
                    },
                    [_vm._v(" mdi-close ")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticStyle: { height: "95%" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    directives: [
                                      {
                                        name: "visible",
                                        rawName: "v-visible",
                                        value: _vm.processing,
                                        expression: "processing"
                                      }
                                    ],
                                    attrs: {
                                      indeterminate: "",
                                      query: true,
                                      color: "blue"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.length > 0,
                                  expression: "errors.length > 0"
                                }
                              ]
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            "line-height": "15px"
                                          },
                                          attrs: {
                                            type: "error",
                                            border: "left",
                                            text: "",
                                            outlined: "",
                                            dense: "",
                                            autocomplete: "none"
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c("b", [_vm._v("Errors:")])
                                          ]),
                                          _vm._l(_vm.errors, function(
                                            error,
                                            errorIndex
                                          ) {
                                            return _c(
                                              "div",
                                              { key: errorIndex },
                                              [_vm._v("* " + _vm._s(error))]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-form",
                            {
                              ref: "aliasDeleteForm",
                              attrs: {
                                "lazy-validation": "",
                                disabled: _vm.processing
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0 ma-0" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "ma-0 pa-0" },
                                        [
                                          _c("v-card-subtitle", [
                                            _vm._v(
                                              "Are you sure you want to delete "
                                            ),
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.editedRecord.alias || ""
                                                ) + " product type alias?"
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { elevation: "2" },
                          on: {
                            click: function($event) {
                              _vm.deleteDialogOpen = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "error",
                          attrs: { elevation: "2", disabled: _vm.processing },
                          on: {
                            click: function($event) {
                              return _vm.submitDeleteDataRequest()
                            }
                          }
                        },
                        [_vm._v(" Delete ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("data-grid", {
            key: _vm.reRenderGrid,
            attrs: {
              headersAutoParserMapping: "",
              "api-metadata-url": _vm.endPoint.metadataEndpointUrl,
              "api-data-url": _vm.endPoint.dataEndpointUrl,
              "footer-height": 70,
              itemsPerPage: [50, 100, 200, 300, 400, 500],
              itemsPerPageInitial: 100,
              actions: _vm.actions,
              "fixed-conditions": [],
              "fixed-conditions-prevent-call": false,
              buttonActions: _vm.buttonActions
            },
            on: { onMetaDataLoaded: _vm.onMetaDataLoaded }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }