var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.openDialog
        ? _c(
            "confirmation-dialog",
            _vm._b(
              { attrs: { buttons: _vm.buttons, eager: "" } },
              "confirmation-dialog",
              _vm.confirmationDialog,
              false
            ),
            [
              _c(
                "div",
                { attrs: { slot: "message" }, slot: "message" },
                [
                  _c("search-input-field", {
                    attrs: {
                      fieldProps: {
                        value: _vm.selectedUser
                          ? _vm.selectedUser.firstName +
                            " " +
                            _vm.selectedUser.lastName +
                            " (" +
                            _vm.selectedUser.username +
                            ")"
                          : "",
                        readonly: true,
                        label: "User",
                        dense: true
                      },
                      maxWidth: "650px",
                      searchField: "username",
                      apiMetadataUrl: "/uiapi/grids/user-internal",
                      apiDataUrl: "/uiapi/user/search-internal",
                      title: "Search User",
                      searchInputLabel: "Seach by username",
                      columns: [
                        { text: "Actions", value: "Actions" },
                        { text: "First Name", value: "firstName" },
                        { text: "Last Name", value: "lastName" },
                        { text: "Username", value: "username" },
                        { text: "Role", value: "role" }
                      ],
                      selectEvent: _vm.selectUser
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }