


























import { Vue } from 'vue-property-decorator';
import SfDataGrid from '@/components/SFComponents/SfDataGrid.vue';
import UserService from '@/services/api/User.service';
import EventBus from '@/events/EventBus';
import SfLetterUpload from '../SfLetterUploadEdit.vue';
import axios from '@/services/api/Api.service';
const axiosInstance = new axios().getClient();

export default Vue.extend({
  name: 'LenderServiceConfigurationLettersLibrary',
  data() {
    return {
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      saveButtonActive: true,
      gridApiUrls: {
        metadata: '/uiapi/grids/cancellation-letter',
        data: '/uiapi/cancellation-letter/search',
        lookups: {},
      },
    };
  },
  components: {
    SfDataGrid,
    SfLetterUpload,
  },
  created() {
    EventBus.$on('sf-letter-upload-success', (success: boolean) => {
      if (success) {
        this.refreshGrid();
      }
    });
  },
  methods: {
    actions() {
      return [
        {
          icon: 'mdi-file-find',
          tooltip: 'Preview Letter',
          disabled: false,
          callback: async (rowData: any): Promise<void> => {
            EventBus.$emit('SF_OPEN_LETTER_FORM_PREVIEW', rowData);
          },
        },
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit Letter',
          hide: (rowData: any) => {
            return rowData.fileURL != null &&
              (rowData.fileURL.indexOf('default-letter.pdf') == 0 || rowData.fileURL.indexOf('!update!') == 0)
              ? false
              : true && !this.readonly;
          },
          callback: async (rowData: any): Promise<void> => {
            this.getRemoteComponent('LetterLibraryUploadEditComponent').$data.open = true;
            setTimeout(() => {
              this.getRemoteComponent('LetterLibraryUploadEditComponent').$data.uploadRecord = {
                type: rowData.type,
                party: rowData.party,
                name: rowData.name,
                filename: rowData.fileURL,
                externalId: rowData.externalId,
                id: rowData.id,
              };
            }),
              250;
          },
        },
        {
          icon: 'mdi-download',
          tooltip: 'Download Letter',
          disabled: false,
          callback: async (rowData: any): Promise<void> => {
            let url = `/uiapi/cancellation-letter/get-letter-form-flat?orgId=${this.orgId}&Id=${rowData.id}`;
            EventBus.$emit('SF_PROCESSING', true);
            axiosInstance
              .get<Blob>(url, {
                responseType: 'blob',
                headers: {
                  orgId: this.orgId,
                },
              })
              .then(response => {
                EventBus.$emit('SF_PROCESSING', false);
                if (response.data.size === 0) {
                  EventBus.$emit('SF_PROCESSING_ERROR', 'Download Error...');
                } else {
                  var a = document.createElement('a');
                  document.body.appendChild(a);
                  a.style.display = 'none';
                  const file = new Blob([response.data], { type: 'application/pdf' });
                  const fileURL = URL.createObjectURL(file);

                  a.href = fileURL;
                  a.download = rowData.name;
                  a.click();
                  window.URL.revokeObjectURL(fileURL);
                  a.remove();

                  /**
                   * If Download with preview below code should be uncommented
                   */

                  // const newWindow = window.open(fileURL, '_BLANK');
                  // if (newWindow) {
                  //   setTimeout(() => {
                  //     newWindow.document.title = rowData.name || 'LetterFormFilePreview.pdf';
                  //   }, 300);
                  // }
                }
              })
              .catch(async (e: any) => {
                EventBus.$emit('SF_PROCESSING', false);
                EventBus.$emit('SF_PROCESSING_ERROR', 'Download Error...');
              });
          },
        },
      ];
    },
    getRemoteComponent(ref: string) {
      return this.$getRemoteComponent(ref, this);
    },
    refreshGrid() {
      this.$getRemoteComponent('LenderServiceConfigurationLetterLibraryGrid', this)?.loadData();
    },
  },
  props: ['footerHeight', 'orgId'],
});
