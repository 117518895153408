var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { eagar: "" },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "section",
        {
          staticClass: "slds-modal slds-fade-in-open",
          attrs: {
            role: "dialog",
            tabindex: "-1",
            "aria-labelledby": "modal-heading-01",
            "aria-modal": "true",
            "aria-describedby": "modal-content-id-1"
          }
        },
        [
          _c("div", { staticClass: "slds-modal__container" }, [
            _c("header", { staticClass: "slds-modal__header" }, [
              _c(
                "button",
                {
                  staticClass:
                    "slds-button slds-button_icon slds-modal__close slds-button_icon-inverse",
                  attrs: { title: "Close" },
                  on: {
                    click: function($event) {
                      _vm.open = false
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "slds-button__icon slds-button__icon_large",
                      attrs: { "aria-hidden": "true" }
                    },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                            "#close"
                        }
                      })
                    ]
                  ),
                  _c("span", { staticClass: "slds-assistive-text" }, [
                    _vm._v("Close")
                  ])
                ]
              ),
              _c(
                "h2",
                {
                  staticClass: "slds-modal__title slds-hyphenate",
                  attrs: { id: "modal-heading-01" }
                },
                [_vm._t("title", [_vm._v("Select Product for Preview")])],
                2
              )
            ]),
            _vm.open
              ? _c(
                  "div",
                  {
                    staticClass: "slds-modal__content slds-p-around_medium",
                    staticStyle: { overflow: "hidden" },
                    attrs: { id: "modal-content-id-1" }
                  },
                  [
                    _vm._t("content", [
                      _c(
                        "v-form",
                        {
                          ref: "LetterUploadForm",
                          model: {
                            value: _vm.formValid,
                            callback: function($$v) {
                              _vm.formValid = $$v
                            },
                            expression: "formValid"
                          }
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "input-shrink-upload pa-0 ma-0" },
                            [
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.messages.length > 0,
                                      expression: "messages.length > 0"
                                    }
                                  ],
                                  staticClass: "confirmation-message"
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                                "line-height": "15px"
                                              },
                                              attrs: {
                                                type: "info",
                                                border: "left",
                                                text: "",
                                                outlined: "",
                                                dense: "",
                                                autocomplete: "none"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mb-1" },
                                                [_c("b", [_vm._v("Mesagess:")])]
                                              ),
                                              _vm._l(_vm.messages, function(
                                                message,
                                                messageIndex
                                              ) {
                                                return _c(
                                                  "div",
                                                  { key: messageIndex },
                                                  [
                                                    _vm._v(
                                                      "* " + _vm._s(message)
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.length > 0,
                                      expression: "errors.length > 0"
                                    }
                                  ],
                                  staticClass: "error-message"
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              staticStyle: {
                                                "font-weight": "strong",
                                                "font-size": "14px",
                                                "line-height": "15px"
                                              },
                                              attrs: {
                                                type: "error",
                                                border: "left",
                                                text: "",
                                                outlined: "",
                                                dense: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mb-1" },
                                                [_c("b", [_vm._v("Errors:")])]
                                              ),
                                              _vm._l(_vm.errors, function(
                                                error,
                                                errorIndex
                                              ) {
                                                return _c("div", {
                                                  key: errorIndex,
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      "* " + error
                                                    )
                                                  }
                                                })
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pt-0" },
                                    [
                                      _c(
                                        "v-text-field",
                                        {
                                          ref: "SfPreviewSearchField",
                                          attrs: {
                                            "prepend-icon": "mdi-magnify",
                                            dense: "",
                                            "hide-details": "",
                                            clearable: "",
                                            label:
                                              "Search Product by Account Number"
                                          },
                                          on: { input: _vm.searchInput },
                                          model: {
                                            value: _vm.search,
                                            callback: function($$v) {
                                              _vm.search = $$v
                                            },
                                            expression: "search"
                                          }
                                        },
                                        [
                                          _vm.progress
                                            ? _c(
                                                "div",
                                                {
                                                  attrs: { slot: "append" },
                                                  slot: "append"
                                                },
                                                [
                                                  _c("v-progress-circular", {
                                                    attrs: {
                                                      width: 2,
                                                      size: "20",
                                                      color: "primary",
                                                      indeterminate: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { staticClass: "ma-0 pa-0" }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "250px",
                                          display: "flex",
                                          "border-radius": "5px"
                                        }
                                      },
                                      [
                                        _vm.open
                                          ? _c("sf-data-grid", {
                                              ref: "SfLetterPreviewSearchGrid",
                                              attrs: {
                                                apiMetadataUrl:
                                                  "/uiapi/grids/product",
                                                apiDataUrl:
                                                  "/uiapi/product/search",
                                                customTableSize: 200,
                                                selection: true,
                                                returnSampleRecord: true,
                                                showColumns: [
                                                  {
                                                    value: "accountNumber",
                                                    text: "Account Number",
                                                    hideFilter: true
                                                  },
                                                  {
                                                    text: "Product Type",
                                                    value: "productType",
                                                    hideFilter: true
                                                  },
                                                  {
                                                    text: "Created At",
                                                    value: "dateTimeCreated",
                                                    parse: "toDateTime",
                                                    hideFilter: true
                                                  }
                                                ],
                                                fixedConditions:
                                                  _vm.fixedConditions,
                                                itemSelected: function(
                                                  selectedItem
                                                ) {
                                                  if (!selectedItem.value) {
                                                    _vm.selected = null
                                                  } else {
                                                    _vm.selected =
                                                      selectedItem.item
                                                  }
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  2
                )
              : _vm._e(),
            _c("footer", { staticClass: "slds-modal__footer " }, [
              _c(
                "div",
                {
                  staticClass: "slds-button slds-button_neutral",
                  attrs: { title: "Upload Letter" },
                  on: {
                    click: function($event) {
                      _vm.open = false
                    }
                  }
                },
                [
                  _vm._v(" Cancel "),
                  _c("span", { staticClass: "slds-assistive-text" }, [
                    _vm._v("Cancel")
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "slds-button slds-button_neutral",
                  attrs: {
                    title: "Select Product for Preview",
                    disabled: _vm.progress || !_vm.selected
                  },
                  on: {
                    click: function($event) {
                      return _vm.getPreviewFile(_vm.selected)
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "slds-button__icon slds-button__icon_left",
                      attrs: { "aria-hidden": "true" }
                    },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                            "#file"
                        }
                      })
                    ]
                  ),
                  _vm._v(" Preview Letter "),
                  _c("span", { staticClass: "slds-assistive-text" }, [
                    _vm._v("Select Product")
                  ])
                ]
              )
            ])
          ])
        ]
      ),
      _c("div", { staticClass: "slds-backdrop slds-backdrop_open" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }