




































import { Vue } from 'vue-property-decorator';
import MessagesCard from './MessagesCard.vue';
import eventBus from '@/events/EventBus';
import { Product } from '@/interfaces/product.interface';

export default Vue.extend({
  name: 'LayoutFloatingMessages',
  components: { MessagesCard },
  data: () => ({
    collapsed: false,
    pushed: {
      errors: [],
      messages: [],
      editedRecord: {} as Product,
    },
  }),
  created() {
    eventBus.$on('FLOATING_MESSAGES_CHANGED', (data: any) => {
      if (undefined === data) {
        this.pushed.errors.length = 0;
        this.pushed.messages.length = 0;
        this.pushed.editedRecord = {} as Product;
      }
      this.pushed = { ...this.pushed, ...data };
    });
  },
  beforeDestroy() {
    eventBus.$off('FLOATING_MESSAGES_CHANGED');
  },
  methods: {},
});
