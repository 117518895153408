var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-dialog",
    _vm._b(
      {
        attrs: {
          maxWidth: "50%",
          errors: _vm.errors,
          messages: _vm.messages,
          processing: _vm.processing
        }
      },
      "custom-dialog",
      _vm.CustomDialog,
      false
    ),
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("Request Re-Quote")
      ]),
      !_vm.reQuoteProcessed
        ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _vm._v(
              " All current refund data will be removed and the Product will be submitted for a new quote. "
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { attrs: { slot: "buttons" }, slot: "buttons" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-1",
              attrs: { disabled: _vm.processing, elevation: "2" },
              on: {
                click: function($event) {
                  _vm
                    .getRemoteComponent("CustomDialog")
                    .external()
                    .close()
                }
              }
            },
            [_vm._v(" Close ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-1 primary",
              attrs: {
                elevation: "2",
                disabled: _vm.processing || _vm.reQuoteProcessed
              },
              on: {
                click: function($event) {
                  return _vm.sendReQuoteRequest()
                }
              }
            },
            [_vm._v(" Continue ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }