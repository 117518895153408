var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "visible",
                        rawName: "v-visible",
                        value: _vm.overlay,
                        expression: "overlay"
                      }
                    ],
                    attrs: { indeterminate: "", query: true, color: "blue" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-form",
            {
              ref: "apiConfigurationForm",
              attrs: { disabled: _vm.overlay, "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _vm.apiPartner.enabled &&
                              _vm.apiPartner.verified &&
                              _vm.apiPartner.locked
                                ? [
                                    _vm.apiPartner.dateTimeCreated != null
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "primary",
                                              elevation: "2"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.unlock()
                                              }
                                            }
                                          },
                                          [_vm._v("Unlock")]
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e(),
                              !_vm.apiPartner.locked
                                ? [
                                    _vm.apiPartner.dateTimeCreated != null
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "primary",
                                              elevation: "2"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.save(false)
                                              }
                                            }
                                          },
                                          [_vm._v("Save")]
                                        )
                                      : _vm._e(),
                                    _vm.apiPartner.enabled
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "primary",
                                              elevation: "2"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.save(true)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Save and Send New Generated Credentials"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-2",
                                        attrs: {
                                          color: "primary",
                                          elevation: "2",
                                          medium: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.fetchApiPartnerData()
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v(" mdi-refresh")])],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        {},
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "mt-0" },
                            [
                              _c("span", [_vm._v("API Partner Configuration")]),
                              _c("v-badge", {
                                staticClass: "mb-6",
                                attrs: {
                                  inline: "",
                                  color: _vm.apiPartner.enabled
                                    ? "success"
                                    : "blue-grey"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "badge",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          _vm._s(
                                            _vm.apiPartner.enabled
                                              ? "enabled"
                                              : "disabled"
                                          )
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("v-badge", {
                                staticClass: "mb-6",
                                attrs: {
                                  inline: "",
                                  color: _vm.apiPartner.verified
                                    ? "success"
                                    : "info"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "badge",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          _vm._s(
                                            _vm.apiPartner.verified
                                              ? "verified"
                                              : "not verified"
                                          )
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _vm.apiPartner.locked && _vm.apiPartner.verified
                                ? _c("v-badge", {
                                    staticClass: "mb-6",
                                    attrs: { inline: "", color: "error" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "badge",
                                          fn: function() {
                                            return [_vm._v("locked")]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      47820498
                                    )
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _vm.apiPartner.enabled &&
                                              !_vm.apiPartner.verified
                                                ? [
                                                    _vm.apiPartner
                                                      .dateTimeCreated != null
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass: "ml-0",
                                                            attrs: {
                                                              color: "info",
                                                              elevation: "2"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.verify()
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Verify Partner"
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-checkbox", {
                                                attrs: { label: "Enabled" },
                                                model: {
                                                  value: _vm.apiPartner.enabled,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.apiPartner,
                                                      "enabled",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "apiPartner.enabled"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.apiPartner.enabled
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      dense: "",
                                                      label:
                                                        "API Partner Email",
                                                      required: "",
                                                      rules: [
                                                        _vm.ValidationService.getValidator(
                                                          "required",
                                                          "API Partner Email"
                                                        ),
                                                        _vm.ValidationService.getValidator(
                                                          "maxLength",
                                                          "API Partner Email",
                                                          {
                                                            length: 255
                                                          }
                                                        ),
                                                        _vm.ValidationService.getValidator(
                                                          "email",
                                                          "API Partner Email"
                                                        )
                                                      ]
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.apiPartner.email,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.apiPartner,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "apiPartner.email"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.apiPartner.enabled
                        ? _c(
                            "v-card",
                            { staticClass: "mt-5" },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "dialog-card-header" },
                                [
                                  _c(
                                    "v-row",
                                    [_c("v-col", [_vm._v("Limits")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      dense: "",
                                                      required: "",
                                                      type: "number",
                                                      label:
                                                        "Limit number of products in one request",
                                                      rules: [
                                                        _vm.ValidationService.getValidator(
                                                          "required",
                                                          "Limit number of products in one request"
                                                        )
                                                      ]
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.apiPartner
                                                          .maxProductsInRequest,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.apiPartner,
                                                          "maxProductsInRequest",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "apiPartner.maxProductsInRequest"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              true
                                                ? _c(
                                                    "v-col",
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          dense: "",
                                                          required: "",
                                                          type: "number",
                                                          label:
                                                            "Limit number of dealers in one request",
                                                          rules: [
                                                            _vm.ValidationService.getValidator(
                                                              "required",
                                                              "Limit number of dealers in one request"
                                                            )
                                                          ]
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.apiPartner
                                                              .maxDealersInRequest,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.apiPartner,
                                                              "maxDealersInRequest",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "apiPartner.maxDealersInRequest"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              false
                                                ? _c(
                                                    "v-col",
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          dense: "",
                                                          required: "",
                                                          type: "number",
                                                          label:
                                                            "Limit number of requests per minute",
                                                          rules: [
                                                            _vm.ValidationService.getValidator(
                                                              "required",
                                                              "Limit number of requests per minute"
                                                            )
                                                          ]
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.apiPartner
                                                              .maxRequestsPerMinute,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.apiPartner,
                                                              "maxRequestsPerMinute",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "apiPartner.maxRequestsPerMinute"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      dense: "",
                                                      required: "",
                                                      type: "number",
                                                      label:
                                                        "Limit number of accounts in one request",
                                                      rules: [
                                                        _vm.ValidationService.getValidator(
                                                          "required",
                                                          "Limit number of accounts in one request"
                                                        )
                                                      ]
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.apiPartner
                                                          .maxAccountsInRequest,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.apiPartner,
                                                          "maxAccountsInRequest",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "apiPartner.maxAccountsInRequest"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-card",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "dialog-card-header" },
                            [
                              _c(
                                "v-row",
                                [_c("v-col", [_vm._v("System Information")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  label: "Created By",
                                                  disabled: "",
                                                  value: _vm.formatUserDateTime(
                                                    _vm.apiPartner
                                                      .createdByName,
                                                    _vm.apiPartner
                                                      .dateTimeCreated
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  label:
                                                    "Last Generated Token At",
                                                  disabled: "",
                                                  value: _vm.returnDateTimeRenderd(
                                                    _vm.apiPartner
                                                      .tokenCreatedDateTime
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  label: "Last Modified By",
                                                  disabled: "",
                                                  value: _vm.formatUserDateTime(
                                                    _vm.apiPartner
                                                      .updatedByName,
                                                    _vm.apiPartner
                                                      .dateTimeUpdated
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  false
                    ? _c(
                        "v-col",
                        [
                          _c(
                            "v-card-text",
                            {
                              staticStyle: {
                                "padding-left": "15px",
                                "padding-top": "10px",
                                "padding-bottom": "0px",
                                background: "#eee"
                              }
                            },
                            [_vm._v(" Api Partner Logs ")]
                          ),
                          _c("data-grid", {
                            ref: "ApiPartnerLogsGrid",
                            attrs: {
                              showFilterSets: false,
                              apiMetadataUrl: "/uiapi/grids/api-partner-logs",
                              apiDataUrl: "/uiapi/api-partner-logs/search",
                              fixedConditions: [
                                {
                                  fieldName: "orgId",
                                  value: _vm.apiPartner.orgId,
                                  fieldType: "string",
                                  conditionComparatorOption: "equal"
                                }
                              ]
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-snackbar",
                {
                  attrs: {
                    centered: "",
                    color: "success",
                    text: "",
                    timeout: 3000
                  },
                  model: {
                    value: _vm.snackBarSuccess,
                    callback: function($$v) {
                      _vm.snackBarSuccess = $$v
                    },
                    expression: "snackBarSuccess"
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "success" } }, [
                    _vm._v(" mdi-check-bold ")
                  ]),
                  _vm._v(" " + _vm._s(_vm.successMessage) + " ")
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    centered: "",
                    color: "error",
                    text: "",
                    timeout: 4000
                  },
                  model: {
                    value: _vm.snackBarError,
                    callback: function($$v) {
                      _vm.snackBarError = $$v
                    },
                    expression: "snackBarError"
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "error" } }, [
                    _vm._v(" mdi-alert-circle-outline ")
                  ]),
                  _vm._l(_vm.errors, function(error, errorIndex) {
                    return _c("div", { key: errorIndex }, [
                      _vm._v("* " + _vm._s(error))
                    ])
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }