var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      ref: "notesComponentFrame",
      staticClass: "sfl-base-container sfl-background-color",
      staticStyle: { border: "0px solid red" }
    },
    [
      _vm._t("NetworkDisconnected"),
      _vm._t("SessionDisconnected"),
      _vm._t("default", [
        _c(
          "div",
          {
            staticStyle: {
              flex: "1",
              height: "100%",
              display: "flex",
              "flex-direction": "column"
            }
          },
          [
            _vm.showButtons
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      background: "#fff",
                      height: "38px",
                      position: "relative"
                    }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "slds-button slds-button_neutral",
                        staticStyle: {
                          float: "right",
                          "margin-right": "10px",
                          "margin-top": "8px"
                        },
                        attrs: { small: "", disabled: _vm.newNoteEditMode },
                        on: {
                          click: function($event) {
                            return _vm.addNote(true)
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { left: "", dark: "" } }, [
                          _vm._v(" mdi-comment-plus ")
                        ]),
                        _vm._v(" New ")
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: {
                  background: "#fff",
                  flex: "1",
                  "overflow-y": "auto",
                  "overflow-x": "hidden",
                  padding: "5px",
                  position: "relative"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      right: "0px",
                      border: "0px solid red",
                      "z-index": "1",
                      background: "#fff"
                    }
                  },
                  [_c("error-message-info", { ref: "NotesErrorMessageInfo" })],
                  1
                ),
                _c(
                  "v-data-table",
                  _vm._b(
                    {
                      staticClass: "notes",
                      staticStyle: { border: "0px solid gold" },
                      attrs: {
                        loading: _vm.loading,
                        options: _vm.dataTable.options,
                        headers: _vm.headers,
                        height: _vm.tableHeight,
                        "hide-default-header": "",
                        "hide-default-footer": _vm.hideFooter,
                        "calculate-widths": ""
                      },
                      on: {
                        "update:options": function($event) {
                          return _vm.$set(_vm.dataTable, "options", $event)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm._l(
                            _vm.headers.filter(function(header) {
                              return header.hasOwnProperty("parse")
                            }),
                            function(header) {
                              return {
                                key: "item." + header.value,
                                fn: function(ref) {
                                  var item = ref.item
                                  var value = ref.value
                                  return [
                                    _c(
                                      "div",
                                      {
                                        key: header.value,
                                        class: item.edit ? "edit-note" : "",
                                        staticStyle: { position: "relative" }
                                      },
                                      [
                                        _vm.processing && item.edit
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  position: "absolute",
                                                  top: "0px",
                                                  bottom: "-15px",
                                                  left: "-15px",
                                                  right: "-15px",
                                                  border: "1px solid white",
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "justify-content": "center"
                                                }
                                              },
                                              [
                                                _c("div", {
                                                  staticStyle: {
                                                    position: "absolute",
                                                    top: "0px",
                                                    left: "0px",
                                                    right: "0px",
                                                    bottom: "0px",
                                                    "z-index": "1",
                                                    background: "#000",
                                                    opacity: ".1"
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      padding: "10px",
                                                      border: "1px solid white",
                                                      "border-radius": "5px",
                                                      background: "#fff",
                                                      "z-index": "5"
                                                    }
                                                  },
                                                  [
                                                    _c("v-progress-circular", {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        size: "20",
                                                        width: "2",
                                                        indeterminate: "",
                                                        color: "primary"
                                                      }
                                                    }),
                                                    _vm._v("Saving Note... ")
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "border-bottom": "1px solid #eee",
                                              padding: "5px",
                                              "padding-left": "0px"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "font-weight": "bold",
                                                  "font-size": "13px",
                                                  "text-decoration":
                                                    "underline",
                                                  display: "inline-block"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.updatedByName) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c("div", {
                                              staticStyle: {
                                                "font-weight": "bold",
                                                "font-size": "13px",
                                                display: "inline-block",
                                                float: "right"
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.getNoteDate(item)
                                                )
                                              }
                                            })
                                          ]
                                        ),
                                        !item.edit
                                          ? _c(
                                              "pre",
                                              {
                                                class:
                                                  _vm.expanded.includes(item) ||
                                                  _vm.showFullNotes
                                                    ? "content-pre-expanded"
                                                    : "content-pre-collapsed",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.cellClickEvent(
                                                      header,
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(header.parse(value))
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        item.edit
                                          ? _c(
                                              "div",
                                              [
                                                _c("v-textarea", {
                                                  model: {
                                                    value: item.content,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "content",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.content"
                                                  }
                                                }),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticStyle: {
                                                      float: "right",
                                                      "margin-bottom": "10px",
                                                      "margin-left": "5px",
                                                      "margin-top": "-10px"
                                                    },
                                                    attrs: {
                                                      "x-small": "",
                                                      title: "Cancel note edit"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                        return _vm.cancelEditNote(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" Cancel ")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticStyle: {
                                                      float: "right",
                                                      "margin-bottom": "10px",
                                                      "margin-right": "0px",
                                                      "margin-top": "-10px"
                                                    },
                                                    attrs: {
                                                      "x-small": "",
                                                      color:
                                                        item.content.replace(
                                                          /\s+/g,
                                                          ""
                                                        ).length === 0
                                                          ? "error"
                                                          : "primary",
                                                      title:
                                                        item.content.replace(
                                                          /\s+/g,
                                                          ""
                                                        ).length === 0
                                                          ? "Note cannot be empty"
                                                          : "Save Note"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                        item.content.replace(
                                                          /\s+/g,
                                                          ""
                                                        ).length > 0
                                                          ? _vm.saveNote(item)
                                                          : false
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Save")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !item.edit
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "right"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: {
                                                      title:
                                                        item.createdById !==
                                                        _vm.userData.userId
                                                          ? "Only the creator of this message can edit it"
                                                          : "Edit Note"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mb-3",
                                                        staticStyle: {
                                                          float: "left"
                                                        },
                                                        attrs: {
                                                          "x-small": "",
                                                          icon: "",
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.dataTable.items.find(
                                                              function(item) {
                                                                return item.edit
                                                              }
                                                            )
                                                              ? null
                                                              : item.createdById !==
                                                                _vm.userData
                                                                  .userId
                                                              ? null
                                                              : _vm.editNote(
                                                                  item
                                                                )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            class: _vm.dataTable.items.find(
                                                              function(item) {
                                                                return item.edit
                                                              }
                                                            )
                                                              ? "btn-disabled"
                                                              : item.createdById !==
                                                                _vm.userData
                                                                  .userId
                                                              ? "btn-disabled"
                                                              : ""
                                                          },
                                                          [_vm._v("mdi-pencil")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm.showFullNotes === false
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          "x-small": "",
                                                          icon: "",
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.cellClickEvent(
                                                              header,
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.expanded.includes(
                                                                  item
                                                                )
                                                                  ? "mdi-chevron-up"
                                                                  : "mdi-chevron-down"
                                                              )
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                }
                              }
                            }
                          )
                        ],
                        null,
                        true
                      )
                    },
                    "v-data-table",
                    _vm.dataTable,
                    false
                  )
                )
              ],
              1
            ),
            _c(
              "custom-dialog",
              {
                ref: "NotesViewAllDialog",
                staticStyle: { height: "90%" },
                attrs: { egear: "" },
                on: { updated: _vm.viewAllOpenUpdate, close: _vm.closeViewAll }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "700px",
                      position: "relative",
                      display: "flex",
                      padding: "0px",
                      border: "0px",
                      margin: "-5px",
                      overflow: "hidden"
                    },
                    attrs: { slot: "content" },
                    slot: "content"
                  },
                  [
                    _vm.viewAllOpen
                      ? _c("notes-component", {
                          attrs: {
                            showViewAll: false,
                            showFullNotes: true,
                            hideFooter: false,
                            fixedConditions: _vm.fixedConditions,
                            apiMetadataUrl: _vm.apiMetadataUrl,
                            apiDataUrl: _vm.apiDataUrl,
                            noteClickPreview: false
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("Notes")
                ]),
                _c(
                  "div",
                  {
                    attrs: { slot: "buttons" },
                    on: {
                      click: function($event) {
                        return _vm.closeViewAll()
                      }
                    },
                    slot: "buttons"
                  },
                  [_c("v-btn", [_vm._v("Back")])],
                  1
                )
              ]
            ),
            _vm.showViewAll
              ? _c(
                  "button",
                  {
                    staticClass: "slds-button slds-button_neutral",
                    staticStyle: {
                      position: "absolute",
                      bottom: "0px",
                      width: "100%"
                    },
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.openViewAll()
                      }
                    }
                  },
                  [_vm._v(" View All ")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }