






























































































































































































































































import { Vue } from 'vue-property-decorator';
import PageLayout from '../components/PageLayout.vue';
import ProductTypeAliasService from '../services/api/ProductTypeAlias.service';
import { ProductTypeAlias, Response } from '../interfaces';
import FormatDisplayService from '../services/FormatDisplay.service';
import DataGrid from '@/components/DataGrid.vue';

/**
 *  Metadata service URL
 */
const metadataEndpointUrl = '/uiapi/grids/product-type-alias';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/product-type-alias/search';

const emptyProductTypeAlias: ProductTypeAlias = {
  id: 0,
  alias: '',
  productType: '',
  verified: false,
  createdByName: null,
  updatedByName: null,
  createdDate: null,
  updatedDate: null,
};

export default Vue.extend({
  name: 'productTypeAlias',
  components: {
    PageLayout,
    DataGrid,
  },
  data: () => ({
    endPoint: {
      metadataEndpointUrl: metadataEndpointUrl,
      dataEndpointUrl: dataEndpointUrl,
    },
    errors: [],
    editActionType: 'INSERT',
    dialog: false,
    editedRecord: Object.assign({}, emptyProductTypeAlias) as ProductTypeAlias,
    reRenderGrid: 0,
    overlay: false,
    valid: true,
    message: '',
    processing: false,
    deleteDialogOpen: false,
    selectedProductIdToDelete: 0 as number,
    alertType: 'success',
    productTypeOptions: [],
    aliasInputRules: [(v: string) => !!v || 'Alias is required'],
    productTypeInputRules: [(v: string) => !!v || 'Product Type is required'],
  }),
  methods: {
    openDialog(record?: ProductTypeAlias): void {
      this.errors.length = 0;
      this.resetValidation();
      if (record === undefined) {
        this.editActionType = 'INSERT';
        Object.assign(this.editedRecord, emptyProductTypeAlias);
      } else {
        this.editActionType = 'UPDATE';
        this.editedRecord = JSON.parse(JSON.stringify(record)) as ProductTypeAlias;
        if (this.editedRecord.createdDate) {
          this.editedRecord.createdDate = FormatDisplayService.getRender('toDateTime')(this.editedRecord.createdDate);
        }
        // change format of date to MM/DD/YYYY
        if (this.editedRecord.updatedDate) {
          this.editedRecord.updatedDate = FormatDisplayService.getRender('toDateTime')(this.editedRecord.updatedDate);
        }
      }
      this.dialog = !this.dialog;
    },
    async submitDataRequest() {
      try {
        if ((this.$refs.providerForm as Vue & { validate: () => boolean }).validate()) {
          this.overlay = true;
          let res: Response<ProductTypeAlias>;
          this.editActionType === 'INSERT'
            ? (res = await ProductTypeAliasService.create(this.editedRecord))
            : (res = await ProductTypeAliasService.update(this.editedRecord, this.editedRecord.id as number));

          if (res?.success) {
            (this.$refs.providerForm as Vue & { reset: () => boolean }).reset();
            this.reRenderGrid++;
            setTimeout(() => {
              this.dialog = false;
              this.message = '';
              this.overlay = false;
            }, 1500);
          } else {
            this.setError(res?.errors || ['Unknown Error']);
            this.overlay = false;
          }
        }
      } catch (err) {
        this.setError([err]);
        this.alertType = 'error';
        this.overlay = false;
      }
    },
    submitDeleteDataRequest() {
      ProductTypeAliasService.delete(this.selectedProductIdToDelete)
        .then(res => {
          this.processing = false;
          if (res?.success) {
            this.reRenderGrid++;
            this.deleteDialogOpen = !this.deleteDialogOpen;
          } else {
            this.setError(res.errors || ['Unknown Error']);
          }
        })
        .catch(err => {
          this.processing = false;
          this.setError([err]);
        });
    },
    setError(errors: string[]) {
      this.errors.length = 0;
      Object.assign(this.errors, errors);
    },
    formValueChange() {
      this.message = '';
    },
    resetValidation() {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).providerForm.resetValidation();
      }, 100);
    },
    onMetaDataLoaded(data: any[]) {
      if (data.length) {
        this.productTypeOptions =
          data.filter(item => item.subFilterName === 'Product Type')[0]?.conditions[0]?.fieldOptions || [];
      }
    },
  },
  computed: {
    buttonActions() {
      return [
        {
          name: 'New Product Type Alias',
          icon: 'mdi-plus',
          callback: () => {
            this.openDialog();
          },
        },
      ];
    },
    actions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit',
          callback: (rowData: ProductTypeAlias): void => {
            this.openDialog(rowData);
          },
        },
        {
          icon: 'mdi-trash-can',
          tooltip: 'Delete',
          callback: (rowData: ProductTypeAlias): void => {
            this.selectedProductIdToDelete = rowData.id as number;
            this.errors.length = 0;
            this.deleteDialogOpen = !this.deleteDialogOpen;
          },
        },
      ];
    },
  },
});
