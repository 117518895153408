

















































import { Vue } from 'vue-property-decorator';
import DataGrid from '@/components/DataGrid.vue';
import PageLayout from '@/components/PageScrollLayout.vue';
import AccountDetails from '@/components/AccountDetails/AccountDetails.vue';
import * as AccountDetailsForm from '@/components/AccountDetails/AccountDetailsForm.vue';
import SendCancellationAction from '@/components/SendCancellationAction.vue';
import { Account } from '@/interfaces';

/**
 *  Metadata service URL
 */
const metadataEndpointUrl = '/uiapi/grids/account';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/account/search';

export interface AccountsPageDataI {
  screen: string;
  screenList: string[];
  lender: string;
  selectedRows: {}[];
  dataGrid: {
    apiMetadataUrl: string;
    apiDataUrl: string;
    ref: string;
    showSelection: boolean;
  };
  accountDetails: {
    accountData: Account;
  };
  filteredAccounts: Account[];
  selectedAccountIndex: number | null;
}

export default Vue.extend({
  name: 'AccountsPage',
  components: {
    DataGrid,
    PageLayout,
    AccountDetails,
    SendCancellationAction,
  },
  /**
   * Page Component data object
   */
  data: (): AccountsPageDataI => ({
    screen: 'grid',
    screenList: ['grid', 'details'],
    lender: '',
    selectedRows: [],
    dataGrid: {
      apiMetadataUrl: metadataEndpointUrl,
      apiDataUrl: dataEndpointUrl,
      ref: 'dataGrid',
      showSelection: true,
    },
    accountDetails: {
      accountData: {},
    },
    filteredAccounts: [],
    selectedAccountIndex: null,
  }),
  watch: {
    screen: {
      handler(newScreen, oldScreen) {
        if (oldScreen === 'details' && newScreen === 'grid') {
          this.getRemoteComponent('AccountListGrid').submitDataRequest();
        }
      },
    },
    '$store.getters.lender': {
      handler() {
        this.lender = this.$store.getters.lender as string;
      },
    },
  },
  methods: {
    // Keep local selectedRows updated with grid sellection
    rowSelection(selectedRows: {}[]) {
      this.selectedRows = selectedRows;
    },
    setFilteredRecords(accounts: Account[]) {
      this.filteredAccounts = accounts;
    },
    refreshGrid() {
      this.getRemoteComponent('AccountListGrid').loadData();
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
  },
  created() {
    this.lender = this.$store.getters.lender as string;
  },
  computed: {
    isEditedRecordLenderActive() {
      return this.accountDetails?.accountData?.orgPreference && this.accountDetails?.accountData?.orgPreference.active;
    },
    /**
     * Defined action for GridComponent
     */
    actions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit Account',
          /**
           *  Callback function for action click
           *  params:
           *  - rowData - grid data for selected row (Object)
           *  - rowIndex - grid store index for selected row (in case of delete or other manipulation on data) (number)
           *  - gridData - grid store data (Array of Objects)
           */
          callback: (rowData: Account, index: number): void => {
            this.accountDetails.accountData = rowData;
            this.selectedAccountIndex = index;
            this.screen = 'details';
            // Put data from grid row to editor
          },
        },
      ];
    },
    buttonActions() {
      return [
        {
          name: 'New Account ' + (this.lender === '' || this.lender === null ? ' (Select Lender)' : ''),
          icon: 'mdi-format-annotation-plus',
          disabled: this.lender === '' || this.lender === null ? true : false,
          callback: () => {
            let emptyRecord = JSON.parse(JSON.stringify(AccountDetailsForm.emptyAccountDetailsRecord));
            emptyRecord.orgId = this.lender;
            this.accountDetails.accountData = emptyRecord;
            this.selectedAccountIndex = null;
            this.screen = 'details';
          },
        },
      ];
    },
  },
});
