

















import { Vue } from 'vue-property-decorator';
import PageLayout from '../../components/PageLayout.vue';
import { LenderCustomFieldsI } from '../../interfaces/lender.interface';

export default Vue.extend({
  name: 'LenderCustomFields',
  components: { PageLayout },
  data: () => ({
    errors: [] as string[],
    messages: [] as string[],
    tableConfiguration: {
      footerProps: {
        itemsPerPageOptions: [5, 50, 100],
      },
      footerOptions: {
        page: 1,
        itemsPerPage: 100,
      },
      itemsPerPage: 100,
      height: 400,
      dense: true,
      items: [] as LenderCustomFieldsI[],
      headers: [
        {
          text: 'Object',
          value: 'apiObjectName',
          type: 'text',
        },
        {
          text: 'Field',
          value: 'apiName',
          type: 'text',
        },
        {
          text: 'Label',
          value: 'label',
          type: 'text',
        },
        {
          text: 'Type',
          value: 'type',
          type: 'text',
        },
        {
          text: 'Required',
          value: 'required',
          type: 'boolean',
        },
      ],
    },
  }),
  created() {
    this.tableConfiguration.items = this.passEditedRecord.customFields || [];
    this.onResize();
  },
  mounted() {
    setTimeout(() => this.onResize(), 100);
    this.onResize();
  },
  methods: {
    onResize() {
      this.tableConfiguration.height =
        window.innerHeight - this.getRemoteComponent('resizableDiv')?.getBoundingClientRect().y - 110;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      const scope = this;
      return {
        set: (messages: string[]) => {
          scope.messages.push(...messages);
          return this;
        },
        add: (message: string) => {
          scope.messages.push(message);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
  },
  props: {
    passEditedRecord: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
