



















































































































































































































































































































































































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import DataGrid from '@/components/DataGrid.vue';
import ProviderDetails from '@/components/ProviderDetails.vue';
import PageScrollLayout from '@/components/PageScrollLayout.vue';
import ValidationService from '../services/Validations.service';
import ProviderPageService, { EnumItemI } from '../services/ProviderPage.service';
import axios from '../services/api/Api.service';
import FormatDisplayService from '../services/FormatDisplay.service';
import UserService from '../services/api/User.service';
import { AxiosResponse } from 'axios';

Vue.directive('visible', function(el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

/**
 *  Metadata service URL
 */
const metadataEndpointUrl = '/uiapi/grids/provider';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/provider/search';
/**
 *  Update record endpoint URL
 */
const recordUpdateEndpointUrl = '/uiapi/provider/{id}';
/**
 *  Insert record endpoint URL
 */
const recordInsertEndpointUrl = '/uiapi/provider';

/**
 * Empty vprovider data record
 */
export const emptyProviderRecordData = {
  id: null,
  providerCancellationContactPhone: null,
  providerCity: null,
  providerParentName: null,
  providerRefundRequestMethod: null,
  providerRefundWebsite: null,
  providerState: null,
  providerStreetAddress: null,
  providerWebAddress: null,
  providerZip: null,
  providerCancellationFax: null,
  name: null,
  providerCountry: null,
  APIConfigCode: null,
  customCancellationFormId: null,
  cancellationContact: null,
  cancellationEmail: null,
  refundAPI: null,
  refundContact: null,
  refundContactEmail: null,
  refundContactPhone: null,
  refundEmail: null,
  refundPhone: null,
  refundReturnSLA: null,
  createdDate: null,
  dateTimeUpdated: null,
  emailFormat: null,
} as ProviderDataRecordI;

/**
 * Provider Data Record Interface
 */
export interface ProviderDataRecordI {
  id: null | number;
  providerCancellationContactPhone: null | string;
  providerCity: null | string;
  providerParentName: null | string;
  providerRefundRequestMethod: null | string;
  providerRefundWebsite: null | string;
  providerState: null | string;
  providerStreetAddress: null | string;
  providerWebAddress: null | string;
  providerZip: null | string;
  providerCancellationFax: null | string;
  name: null | string;
  providerCountry: null | string;
  APIConfigCode: null | string;
  customCancellationFormId: null | string;
  cancellationContact: null | string;
  cancellationEmail: null | string;
  refundAPI: null | string;
  refundContact: null | string;
  refundContactEmail: null | string;
  refundContactPhone: null | string;
  refundEmail: null | string;
  refundPhone: null | string;
  refundReturnSLA: null | string;
  createdDate: null | string;
  dateTimeUpdated: null | string;
  createBy: null | string;
  changedBy: null | string;
  notes: null | string;
  noteLastModifiedBy: null | string;
  noteLastModifiedDateTime: null | string;
  communicationPreference: null | 'Email' | 'Fax';
  emailFormat: null | string;
}

/**
 * ProviderPage Data Interface
 */
interface ProviderPageDataI {
  userService: {};
  contentList: string[];
  contentSelected: string;
  providerDetailsEnabled: boolean;
  snackMessage: boolean;
  phoneNumberRulesList: string[];
  ValidationService: {};
  ProviderPageService: {};
  editActionType: 'INSERT' | 'UPDATE' | null;
  editedRecordProviderDetails: ProviderDataRecordI | null;
  editedRecord: ProviderDataRecordI | null;
  editedRecordLists: {
    providerState: EnumItemI[];
    providerCountry: EnumItemI[];
    providerRefundRequestMethod: EnumItemI[];
    CustomCancellationForm: EnumItemI[];
    refundAPIproviders: EnumItemI[];
    emailFormat: EnumItemI[];
  };
  valid: boolean;
  overlay: boolean;
  errors: string[];
  dialog: boolean;
  gridColumns: CustomDataGridHeaderI[];
  endPoint: {
    metadataEndpointUrl: string;
    dataEndpointUrl: string;
  };
  formatDisplayService: any;
}

export interface CustomDataGridHeaderI {
  text: string;
  value: string;
  parse?: (v: string | null) => string | null;
  sortable?: boolean;
}

export default Vue.extend({
  name: 'ProviderPage',
  components: {
    DataGrid,
    PageScrollLayout,
    ProviderDetails,
  },
  /**
   * Page Component data object
   */
  data: (): ProviderPageDataI => ({
    userService: UserService,
    contentList: ['grid', 'details'],
    contentSelected: 'grid',
    providerDetailsEnabled: false,
    snackMessage: false,
    phoneNumberRulesList: [
      'valid format (xxx) xxx-xxxx',
      'max length less than 16 characters',
      'cannot start with 1',
      'cannot contain sequential numbers (234) 567-890)',
      'cannot use the same digits (222) 222-2222)',
    ],
    ValidationService: ValidationService,
    ProviderPageService: ProviderPageService,
    editActionType: null,
    editedRecordProviderDetails: Object.assign({}, emptyProviderRecordData) as ProviderDataRecordI,
    editedRecord: Object.assign({}, emptyProviderRecordData) as ProviderDataRecordI,
    editedRecordLists: {
      providerState: [],
      providerCountry: [],
      providerRefundRequestMethod: [],
      CustomCancellationForm: [],
      refundAPIproviders: [],
      emailFormat: [],
    },
    valid: true,
    overlay: false,
    errors: ['errrr'],
    dialog: false,
    endPoint: {
      metadataEndpointUrl: metadataEndpointUrl,
      dataEndpointUrl: dataEndpointUrl,
    },
    gridColumns: [
      {
        text: 'Provider Name',
        value: 'name',
      },
      {
        text: 'Provider Parent Company',
        value: 'providerParentName',
      },
      {
        text: 'Refund Request Method',
        value: 'providerRefundRequestMethod',
      },
      {
        text: 'Contact Phone',
        value: 'refundContactPhone',
        parse: FormatDisplayService.getRender('toPhone'),
      },
      {
        text: 'Contact Email',
        value: 'refundEmail',
      },
      {
        text: 'Actions',
        value: 'Actions',
      },
    ],
    formatDisplayService: FormatDisplayService,
  }),
  methods: {
    refreshValidation() {
      this.resetValidation();
      this.validate();
    },
    validateEmailFax(type: 'Email' | 'Fax') {
      return (v: string | number | null) => {
        if (this.editedRecord && this.editedRecord.communicationPreference === type && (!v || v == '')) {
          return type + ' is required if Communication Preference is set to ' + type;
        }
        return true;
      };
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    returnDateTimeRenderd(TimeDateString: string) {
      return FormatDisplayService.getRender('toDateTime')(TimeDateString);
    },
    /**
     * Method open dialog and do setup for edited reccord as new or edit
     */
    openEditDialog(editedRecord?: ProviderDataRecordI | undefined): void {
      // Clear error box on enter edit
      this.errors.length = 0;
      // Reset validation
      this.resetValidation();
      if (editedRecord === undefined) {
        // reset data in edited record if option "new provider"
        this.editActionType = 'INSERT';
        this.editedRecord = JSON.parse(JSON.stringify(emptyProviderRecordData)) as ProviderDataRecordI;
      } else {
        // assign record from other source and edit
        this.editActionType = 'UPDATE';
        this.editedRecord = JSON.parse(JSON.stringify(editedRecord)) as ProviderDataRecordI;
        // Reload Enums for specific provider
        this.getEnums();
      }
      // Run validation
      this.validate();
      this.dialog = !this.dialog;
    },
    /**
     * Reste validation
     */
    resetValidation(): number {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).providerForm.resetValidation();
      }, 250);
    },
    /**
     * Do validation on form
     */
    validate(): number {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).providerForm.validate();
      }, 500);
    },
    /**
     * Remote Submit in ProviderDetails component
     */
    submitDataRequestProviderDetails() {
      (this.$refs.providerDetails as HTMLFormElement).submitDataRequest();
    },

    refreshProviderGrid() {
      (this.$refs.dataGrid as HTMLFormElement).submitDataRequest();
    },
    /**
     * Method for submitting Data
     */
    submitDataRequest(): void {
      // reset error box on send request
      this.errors.length = 0;
      this.overlay = false;
      const requestObject = Object.assign({}, this.editedRecord as ProviderDataRecordI);
      let url = '';
      if (!requestObject.id) {
        url = recordInsertEndpointUrl;
      } else {
        url = recordUpdateEndpointUrl.replace('{id}', requestObject.id.toString());
      }
      //validation part, preparing data
      if (this.valid) {
        this.overlay = true;
        axiosInstance
          .post<ProviderDataRecordI, AxiosResponse<{ success: boolean; errors: string[] }>>(url, requestObject, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(result => {
            this.overlay = false;
            if (result.data.success) {
              // Reload CustomDataGrid component
              (this.$refs.dataGrid as HTMLFormElement).submitDataRequest();
              this.snackMessage = true;
              this.dialog = false;
            } else {
              // Setting errors will show them on form
              this.setError(result.data.errors);
            }
          })
          .catch(error => {
            this.overlay = false;
            this.setError([error]);
          });
      } else {
        this.resetValidation();
        this.validate();
      }
    },
    goBackToProvider() {
      this.providerDetailsEnabled = false;
      this.contentSelected = 'grid';
      setTimeout(() => {
        (this.$refs.dataGrid as HTMLFormElement).onResize();
      }, 250);
    },
    /**
     * Set Errors in Edit Details Box
     */
    setError(errors: string[]) {
      this.errors.length = 0;
      Object.assign(this.errors, errors);
    },
    /**
     * Method feed lists with data from service ( async - need to be replaced with Enum global service )
     */
    async getEnums(): Promise<void> {
      this.editedRecordLists.providerState = (await ProviderPageService.getItems(
        'ProviderStates',
        true,
      )) as EnumItemI[];
      this.editedRecordLists.providerCountry = (await ProviderPageService.getItems('ProviderCountry')) as EnumItemI[];
      this.editedRecordLists.refundAPIproviders = (await ProviderPageService.getItems('RefundAPI')) as EnumItemI[];
      this.editedRecordLists.providerRefundRequestMethod = (await ProviderPageService.getItems(
        'ProviderRefundRequestMethod',
      )) as EnumItemI[];
      this.editedRecordLists.emailFormat = (await ProviderPageService.getItems('EmailFormat')) as EnumItemI[];
      if (this.editedRecord && this.editedRecord.id) {
        this.editedRecordLists.CustomCancellationForm = (await ProviderPageService.getItems(
          'CustomCancellationForm',
          false,
          false,
          {
            providerId: (this.editedRecord || { id: null }).id + '',
          },
        )) as EnumItemI[];
      }
    },
    /**
     * Validation Rules Object contain all validatioin methods
     */
    validationRules(FieldName?: string) {
      if (!FieldName) {
        FieldName = 'Field ';
      }
      return {
        /**
         * Data length validation
         */
        notEmpty(v: string): boolean | string {
          return !!v || FieldName + ' is required';
        },
      };
    },
  },
  /**
   * Method will run when comoponent is created
   */
  created() {
    this.getEnums();
  },
  computed: {
    getRefundEmailsRules() {
      return [
        ValidationService.getValidator('emailSemiconList', 'Refund Email'),
        ValidationService.getValidator('isValidEmailCount', 'Refund Email'),
      ];
    },
    buttonActions() {
      return [
        {
          name: 'New Provider',
          icon: 'mdi-plus',
          disabled: !UserService.checkUserActionAccess('PROVIDER_EDIT'),
          callback: () => {
            this.openEditDialog();
          },
        },
      ];
    },
    /**
     * Defined action for GridComponent
     */
    actions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit Provider',
          /**
           *  Callback function for action click
           *  params:
           *  - rowData - grid data for selected row (Object)
           *  - rowIndex - grid store index for selected row (in case of delete or other manipulation on data) (number)
           *  - gridData - grid store data (Array of Objects)
           */
          callback: (rowData: ProviderDataRecordI): void => {
            // Put data from grid row to editor
            this.editedRecordProviderDetails = Object.assign({}, JSON.parse(JSON.stringify(rowData)));
            this.contentSelected = 'details';
            this.providerDetailsEnabled = true;
          },
        },
      ];
    },
  },
});
