import { render, staticRenderFns } from "./SfDataGrid.vue?vue&type=template&id=3c4f7f49&scoped=true&"
import script from "./SfDataGrid.vue?vue&type=script&lang=ts&"
export * from "./SfDataGrid.vue?vue&type=script&lang=ts&"
import style0 from "./SfDataGrid.vue?vue&type=style&index=0&id=3c4f7f49&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c4f7f49",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VDataTable,VIcon,VMenu,VTooltip})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c4f7f49')) {
      api.createRecord('3c4f7f49', component.options)
    } else {
      api.reload('3c4f7f49', component.options)
    }
    module.hot.accept("./SfDataGrid.vue?vue&type=template&id=3c4f7f49&scoped=true&", function () {
      api.rerender('3c4f7f49', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SFComponents/SfDataGrid.vue"
export default component.exports