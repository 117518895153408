var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sfl-base-container sfl-background-color" },
    [
      _vm._t("NetworkDisconnected"),
      _vm._t("SessionDisconnected"),
      _vm._t("default", [
        _c("xlsx-export", { ref: "ExportComponent" }),
        _c("div", [
          _c(
            "div",
            {
              staticClass:
                "slds-page-header slds-page-header_joined slds-page-header_bleed slds-shrink-none test-headerRegion slds-is-relative slds-page-header--object-home"
            },
            [
              _c("div", { staticClass: "slds-grid" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "slds-col slds-has-flexi-truncate firstHeaderRow"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "slds-media slds-no-space slds-grow" },
                      [
                        _c("div", { attrs: { "aria-hidden": "true" } }, [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "record-avatar-container slds-avatar slds-avatar_small icon slds-m-right_small",
                                staticStyle: { "background-color": "#747E96" }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "slds-icon_container slds-icon-standard-account"
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "slds-icon",
                                        attrs: { "aria-hidden": "true" }
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href":
                                              require("slds/assets/icons/standard-sprite/svg/symbols.svg") +
                                              "#account"
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", {
                                      staticClass: "slds-assistive-text"
                                    })
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "slds-media__body slds-align-middle" },
                          [
                            _c("div"),
                            _c(
                              "h2",
                              { staticClass: "slds-card__header-title" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "slds-card__header-link baseCard__header-title-container"
                                  },
                                  [
                                    !_vm.isSalesforceUser
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "slds-truncate slds-m-right--xx-small",
                                            attrs: { title: "Attachment" },
                                            on: {
                                              click: function($event) {
                                                return _vm.setAuditHistoryDialogOpen(
                                                  true
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Audit History")]
                                        )
                                      : _vm._e(),
                                    _vm.isSalesforceUser
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "slds-truncate slds-m-right--xx-small",
                                            staticStyle: { color: "black" },
                                            attrs: {
                                              href: _vm.salesforceViewAllUrl,
                                              target: "_parent"
                                            }
                                          },
                                          [_vm._v(" Audit History ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "lds-shrink-none slds-m-right--xx-small",
                                        attrs: {
                                          title: "(" + _vm.gridItems + ")"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "(" + _vm._s(_vm.gridItems) + ")"
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "slds-col slds-no-flex slds-grid slds-align-bottom"
                    },
                    [
                      _vm._m(0),
                      _c("div", { staticClass: "slds-m-left_xx-small" }),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "slds-m-left_xx-small simpleEmptyState"
                          },
                          [
                            _c("div", { staticClass: "slds-button-group" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSearch,
                                      expression: "showSearch"
                                    }
                                  ],
                                  staticClass:
                                    "slds-form-element__control slds-input-has-icon slds-input-has-icon_left",
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-right": "5px"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "slds-icon slds-input__icon slds-input__icon_left slds-icon-text-default",
                                      attrs: { "aria-hidden": "true" }
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                                            "#search"
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        top: "5px",
                                        right: "2px",
                                        "font-size": "22px",
                                        color: "#b0adab"
                                      },
                                      on: { click: _vm.clearSearch }
                                    },
                                    [_vm._v(" mdi-close")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.search,
                                        expression: "search"
                                      }
                                    ],
                                    staticClass: "slds-input",
                                    staticStyle: {
                                      "padding-right": "25px",
                                      width: "180px"
                                    },
                                    attrs: {
                                      type: "text",
                                      placeholder: "Search Keyword",
                                      clearable: ""
                                    },
                                    domProps: { value: _vm.search },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.search = $event.target.value
                                        },
                                        _vm.serchKeywordEvent
                                      ]
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "forceRefreshButton" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        "min-width": "96px"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.showExport,
                                                          expression:
                                                            "showExport"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "slds-button slds-button_neutral",
                                                      on: {
                                                        submit: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    "div",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "slds-button__icon slds-button__icon_left",
                                                      attrs: {
                                                        "aria-hidden": "true"
                                                      }
                                                    },
                                                    [
                                                      _c("use", {
                                                        attrs: {
                                                          "xlink:href":
                                                            require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                                                            "#upload"
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v("Export ")
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            height: "114px"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "slds-dropdown slds-dropdown_left"
                                            },
                                            [
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "slds-dropdown__list",
                                                  attrs: {
                                                    role: "menu",
                                                    "aria-label": "Show More"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "slds-dropdown__item",
                                                      attrs: {
                                                        role: "presentation"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.exportFile(
                                                            "XLSX"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "#",
                                                            role: "menuitem",
                                                            tabindex: "0"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "slds-truncate",
                                                              attrs: {
                                                                title:
                                                                  "Export to XLSX file"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "XLSX File"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "slds-dropdown__item",
                                                      attrs: {
                                                        role: "presentation"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.exportFile(
                                                            "CSV"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "#",
                                                            role: "menuitem",
                                                            tabindex: "-1"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "slds-truncate",
                                                              attrs: {
                                                                title:
                                                                  "Export to CSV file"
                                                              }
                                                            },
                                                            [_vm._v("CSV File")]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "slds-dropdown__item",
                                                      attrs: {
                                                        role: "presentation"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.exportFile(
                                                            "TXT"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "#",
                                                            role: "menuitem",
                                                            tabindex: "-1"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "slds-truncate",
                                                              attrs: {
                                                                title:
                                                                  "Export to TXT file"
                                                              }
                                                            },
                                                            [_vm._v("TXT File")]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "slds-button slds-button_icon slds-button_icon-border-filled",
                                      attrs: { title: "Refresh" },
                                      on: { click: _vm.refreshGrid }
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "slds-button__icon",
                                          attrs: { "aria-hidden": "true" }
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                                                "#refresh"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "slds-assistive-text" },
                                        [_vm._v("Refresh")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  staticClass: "slds-grid"
                },
                [
                  _vm._m(1),
                  _c("div", {
                    staticClass:
                      "slds-col slds-no-flex slds-grid slds-align-bottom slds-shrink"
                  })
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTypes,
                      expression: "showTypes"
                    }
                  ],
                  staticClass: "shrink-types"
                },
                [
                  _c("fieldset", { staticClass: "slds-form-element" }, [
                    _c("div", { staticClass: "slds-form-element__control" }, [
                      _c(
                        "div",
                        { staticClass: "slds-checkbox_button-group" },
                        _vm._l(_vm.productTypes, function(
                          productType,
                          productTypeIndex
                        ) {
                          return _c(
                            "span",
                            {
                              key: productTypeIndex,
                              staticClass: "slds-button slds-checkbox_button",
                              on: {
                                click: function($event) {
                                  return _vm.produtTypeCheckboxClick(
                                    productType
                                  )
                                }
                              }
                            },
                            [
                              _c("input", {
                                attrs: { type: "checkbox", name: "checkbox" },
                                domProps: {
                                  value: productType,
                                  checked: _vm
                                    .productTypeSelection()
                                    .check(productType)
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "slds-checkbox_button__label",
                                  attrs: {
                                    title: _vm.productTypeCodeFromName(
                                      productType
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "slds-checkbox_faux" },
                                    [_vm._v(_vm._s(productType))]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ]
              )
            ]
          )
        ]),
        _c("sf-data-grid", {
          ref: "AuditGrid",
          attrs: {
            localSort: true,
            apiMetadataUrl: _vm.gridApiUrls[_vm.auditType].metadata,
            apiDataUrl: _vm.gridApiUrls[_vm.auditType].data,
            rowClick: _vm.viewRecord,
            disableFilters: true,
            hideFooter: true,
            footerHeight:
              (_vm.footerHeight ? _vm.footerHeight : 0) +
              (_vm.viewAll ? 30 : 0),
            previews: _vm.transmissionPreviews,
            "fixed-conditions": [
              {
                fieldName: "orgId",
                value: _vm.orgId,
                fieldType: "string",
                conditionComparatorOption: "equal"
              },
              {
                fieldName:
                  _vm.recordId != null || _vm.sfid != null
                    ? "sfid"
                    : _vm.auditType == "product"
                    ? "externalId"
                    : "accountNumber",
                value:
                  _vm.recordId != null
                    ? _vm.recordId
                    : _vm.sfid != null
                    ? _vm.sfid
                    : _vm.externalId,
                fieldType: "string",
                conditionComparatorOption: "equal"
              }
            ]
          },
          on: {
            "local-sort-trigger": _vm.performLocalSort,
            "sf-grid-data-loaded": _vm.getGridItems
          }
        }),
        _vm.viewAll
          ? _c(
              "button",
              {
                staticClass: "slds-button slds-button_neutral",
                staticStyle: {
                  position: "absolute",
                  bottom: "0px",
                  width: "100%"
                },
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.setAuditHistoryDialogOpen(true)
                  }
                }
              },
              [_vm._v(" View All ")]
            )
          : _vm._e()
      ]),
      _c(
        "v-dialog",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.viewAll,
              expression: "viewAll"
            }
          ],
          staticStyle: {
            position: "relative",
            width: "100vw",
            overflow: "hidden"
          },
          attrs: {
            persistent: "",
            scrollable: false,
            "min-height": "95%",
            height: "95%"
          },
          model: {
            value: _vm.viewAllDialog,
            callback: function($$v) {
              _vm.viewAllDialog = $$v
            },
            expression: "viewAllDialog"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                width: "100%",
                overflow: "hidden",
                display: "flex",
                "flex-direction": "column"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "headline dialog-card-header",
                  staticStyle: { height: "50px" }
                },
                [
                  _c("div", { staticClass: "pt-2 pl-2 " }, [
                    _vm._v("Audit History")
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        cursor: "pointer"
                      },
                      attrs: { center: "", large: "", color: "white" },
                      on: {
                        click: function($event) {
                          return _vm.setAuditHistoryDialogOpen(false)
                        }
                      }
                    },
                    [_vm._v(" mdi-close ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    height: "calc(100vh - 250px)"
                  }
                },
                [
                  _c("audit-history-component", {
                    attrs: {
                      orgId: _vm.orgId,
                      auditType: _vm.auditType,
                      externalId: _vm.externalId,
                      sfid: _vm.recordId != null ? _vm.recordId : _vm.sfid,
                      viewAll: false,
                      footerHeight: 340
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slds-m-left_xx-small" }, [
      _c("div", {
        staticClass:
          "test-listViewSettingsMenu slds-m-left_xx-small simpleEmptyState"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slds-col slds-align-bottom" }, [
      _c("div", { staticClass: "slds-text-body--small" }, [
        _c("div", [
          _c("div", { attrs: { "aria-live": "polite", role: "status" } }, [
            _c("span", { staticClass: "countSortedByFilteredBy" }, [
              _vm._v("2 items • ")
            ]),
            _c("span", [_vm._v("Updated 5 minutes ago")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }