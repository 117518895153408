/**
 * Local Storage Service
 */
class LocalStorageService {
  constructor() {}

  /**
   * Set object in local storage/Session
   * @param componentId
   * @param dataObject
   * @param session
   * @returns object | null
   */
  public set(componentId: string, dataObject: any, session?: boolean) {
    if (session) {
      window.sessionStorage.setItem(componentId, JSON.stringify(dataObject));
    } else {
      window.localStorage.setItem(componentId, JSON.stringify(dataObject));
    }
    return dataObject;
  }

  /**
   * Get object from local storage/Session
   * @param componentId
   * @param session
   * @returns object | null
   */
  public get(componentId: string, session?: boolean) {
    let item = null;
    if (session) {
      item = window.sessionStorage.getItem(componentId);
    } else {
      item = window.localStorage.getItem(componentId);
    }
    if (item) {
      return JSON.parse(item);
    } else {
      null;
    }
  }
}

export default new LocalStorageService();
