var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.accountData !== null
    ? _c(
        "div",
        { staticClass: "idp-details-main-container" },
        [
          _c(
            "div",
            { staticClass: "idp-details-header" },
            [
              _c(
                "v-container",
                { staticClass: "ml-0 mt-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-1 pb-2",
                          staticStyle: {
                            "white-space": "nowrap",
                            display: "flex",
                            "align-items": "center"
                          },
                          attrs: { cols: "2" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                elevation: "2",
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.goBack()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v(" mdi-arrow-left")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                color: "primary",
                                elevation: "2",
                                small: "",
                                disabled: _vm.shouldDisableSaveButton(),
                                title: _vm.dataChange()
                                  ? "No Data was Changed"
                                  : "Data was Changed - Please Save"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.requestSaveData()
                                }
                              }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(" mdi-content-save")
                              ]),
                              _vm._v(" SAVE ")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-1 pb-2",
                          staticStyle: { "font-size": "1.1em" }
                        },
                        [
                          _c("span", { staticClass: "ml-0 mr-5" }, [
                            _vm._v(" Account #: "),
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(_vm._s(this.accountData.accountNumber))]
                            )
                          ]),
                          _c("span", { staticClass: "ml-2 mr-5" }, [
                            _vm._v(" Client: "),
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(this.accountData.orgPreference.name)
                                )
                              ]
                            )
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "ml-2 mr-0",
                              staticStyle: { float: "right !important" }
                            },
                            [
                              _vm._v(" Status: "),
                              _c(
                                "span",
                                { staticStyle: { "font-weight": "bold" } },
                                [_vm._v(_vm._s(this.accountData.status))]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "products-menu" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "span",
                            {
                              staticClass: "tab tab-account",
                              class:
                                "ACCOUNT" === _vm.control.selectedForm
                                  ? "tab-selected"
                                  : "",
                              attrs: { elevatioin: "2" },
                              on: {
                                click: function($event) {
                                  return _vm.selectAccount()
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: { "line-height": "3px" },
                                  attrs: {
                                    color:
                                      _vm.getAccountProgressIcon() ===
                                      "mdi-circle-slice-8"
                                        ? "green"
                                        : "#aaa"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.getAccountProgressIcon())
                                  )
                                ]
                              ),
                              _vm._v(" ACCOUNT "),
                              _vm.accountData.errors &&
                              _vm.accountData.errors.length > 0
                                ? _c("div", {
                                    staticClass: "indicator error-indicator"
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticStyle: { padding: "7px", margin: "3px" } },
                            [_c("v-icon", [_vm._v(" mdi-pan-right ")])],
                            1
                          ),
                          _vm._l(this.accountData.informedProducts, function(
                            informedProduct,
                            informedProductIndex
                          ) {
                            return _c(
                              "span",
                              {
                                key: informedProductIndex,
                                staticClass: "tab tab-product",
                                class:
                                  informedProductIndex ===
                                  _vm.control.selectedForm
                                    ? _vm.getTabClass(informedProduct) +
                                      "tab-selected"
                                    : _vm.getTabClass(informedProduct),
                                attrs: {
                                  title: _vm.GetTabTitle(informedProduct)
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.selectProduct(
                                      _vm.accountData.informedProducts[
                                        informedProductIndex
                                      ],
                                      informedProductIndex
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(informedProduct.productType) + " "
                                ),
                                _c("div", {
                                  staticClass: "indicator",
                                  class: _vm.getTabSuccessIndicator(
                                    informedProduct
                                  )
                                }),
                                _c("div", {
                                  staticClass: "indicator",
                                  class: _vm.getTabErrorIndicator(
                                    informedProduct
                                  )
                                })
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("v-col")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "idp-details-content" }, [
            _c("div", { staticClass: "idp-details-form" }, [
              _c(
                "div",
                { staticClass: "form-data" },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "1",
                        overflow: "hidden !important",
                        position: "relative"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "fit-to-parent",
                          staticStyle: { overflow: "hidden !important" }
                        },
                        [
                          _vm.control.selectedForm !== "ACCOUNT"
                            ? _c("IDPProductForm", {
                                attrs: {
                                  productData:
                                    _vm.accountData.informedProducts[
                                      _vm.control.selectedForm
                                    ]
                                },
                                on: {
                                  formUpdate: function($event) {
                                    return _vm.$forceUpdate()
                                  },
                                  "update:productData": function($event) {
                                    return _vm.$set(
                                      _vm.accountData.informedProducts,
                                      _vm.control.selectedForm,
                                      $event
                                    )
                                  },
                                  "update:product-data": function($event) {
                                    return _vm.$set(
                                      _vm.accountData.informedProducts,
                                      _vm.control.selectedForm,
                                      $event
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.control.selectedForm === "ACCOUNT"
                            ? _c("IDPAccountForm", {
                                attrs: {
                                  readonly: _vm.ifAccountReadOnly(false),
                                  disabled: _vm.ifAccountReadOnly(false),
                                  accountData: _vm.accountData
                                },
                                on: {
                                  formUpdate: function($event) {
                                    return _vm.$forceUpdate()
                                  },
                                  "update:accountData": function($event) {
                                    _vm.accountData = $event
                                  },
                                  "update:account-data": function($event) {
                                    _vm.accountData = $event
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  !_vm.dataChange()
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-alert",
                            {
                              staticStyle: { "margin-bottom": "0px" },
                              attrs: {
                                color: "info",
                                text: "",
                                rounded: "0",
                                icon: "mdi-information",
                                border: "left"
                              }
                            },
                            [
                              _vm._v(
                                " Account/Products data was edited consider save action "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.tempErrors, function(tempError, tempErrorIndex) {
                    return _c(
                      "div",
                      { key: tempErrorIndex },
                      [
                        _c(
                          "v-alert",
                          {
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: {
                              color: "#C51162",
                              text: "",
                              rounded: "0",
                              icon: "mdi-alert-circle",
                              border: "left"
                            }
                          },
                          [_vm._v(" " + _vm._s(tempError.content) + " ")]
                        )
                      ],
                      1
                    )
                  }),
                  _vm.getProductErrors()
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-alert",
                            {
                              staticStyle: { "margin-bottom": "0px" },
                              attrs: {
                                color: "#C51162",
                                text: "",
                                rounded: "0",
                                icon: "mdi-alert-circle",
                                border: "left"
                              }
                            },
                            [
                              _c("div", [_vm._v("Product:")]),
                              _vm._l(_vm.getProductErrors(), function(
                                error,
                                errorIndex
                              ) {
                                return _c("div", { key: errorIndex }, [
                                  _vm._v("- " + _vm._s(error))
                                ])
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.getAccountErrors()
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-alert",
                            {
                              staticStyle: { "margin-bottom": "0px" },
                              attrs: {
                                color: "#C51162",
                                text: "",
                                rounded: "0",
                                icon: "mdi-alert-circle",
                                border: "left"
                              }
                            },
                            [
                              _c("div", [_vm._v("Account:")]),
                              _vm._l(_vm.getAccountErrors(), function(
                                error,
                                errorIndex
                              ) {
                                return _c("div", { key: errorIndex }, [
                                  _vm._v("- " + _vm._s(error))
                                ])
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-overlay",
                    {
                      attrs: {
                        absolute: true,
                        value: _vm.processing,
                        opacity: 0.2
                      }
                    },
                    [
                      _c(
                        "v-snackbar",
                        {
                          attrs: {
                            centered: "",
                            color: "default",
                            text: "",
                            absolute: ""
                          },
                          model: {
                            value: _vm.processing,
                            callback: function($$v) {
                              _vm.processing = $$v
                            },
                            expression: "processing"
                          }
                        },
                        [
                          _c("v-progress-circular", {
                            staticClass: "mr-5",
                            attrs: { indeterminate: "", color: "primary" }
                          }),
                          _vm._v(" Processing... ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ]),
            _c("div", { staticClass: "idp-details-preview" }, [
              _c(
                "div",
                { staticStyle: { "z-index": "10", position: "relative" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-3",
                          staticStyle: {
                            "text-align": "right",
                            "white-space": "nowrap"
                          },
                          attrs: { cols: "6" }
                        },
                        [
                          _vm.control.selectedForm === "ACCOUNT"
                            ? _c("v-select", {
                                staticClass: "mr-2 ml-1 idp-account-pdf-select",
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  "solo-inverted": "",
                                  items: _vm.getListOfAccountPDfs(),
                                  value: _vm.previewDocumentPathSelected
                                },
                                on: { change: _vm.accountPDFSelect }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-3",
                          staticStyle: {
                            "text-align": "right",
                            "white-space": "nowrap"
                          },
                          attrs: { cols: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1 mt-1",
                              attrs: { small: "" },
                              on: {
                                click: function($event) {
                                  return _vm.openPDFInNewWindow()
                                }
                              }
                            },
                            [
                              _vm._v(" Image "),
                              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                                _vm._v(" mdi-open-in-new ")
                              ])
                            ],
                            1
                          ),
                          _vm.control.selectedForm !== "ACCOUNT"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ml-1 mr-1",
                                  attrs: { small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openExtractionLog(
                                        _vm.accountData.informedProducts[
                                          _vm.control.selectedForm
                                        ].informedApiLog
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(" Raw Extraction "),
                                  _c(
                                    "v-icon",
                                    { attrs: { right: "", dark: "" } },
                                    [_vm._v(" mdi-open-in-new ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.control.selectedForm === "ACCOUNT"
                            ? _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ml-1 mr-1",
                                                    attrs: { small: "" }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(" Raw Extraction "),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      dark: ""
                                                    }
                                                  },
                                                  [_vm._v(" mdi-open-in-new ")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3928325731
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(
                                      _vm.accountData.informedApiLogs,
                                      function(log, logIndex) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: logIndex,
                                            staticClass: "cursor-pointer",
                                            on: {
                                              click: function($event) {
                                                return _vm.openExtractionLog(
                                                  log
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                _vm._s(
                                                  "(" +
                                                    (logIndex + 1) +
                                                    ") " +
                                                    log.endpointName
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "document",
                  staticStyle: { position: "relative" }
                },
                [
                  _vm.documentLoading
                    ? _c(
                        "div",
                        { staticClass: "fit-to-parent idp-document-loading" },
                        [
                          _c(
                            "div",
                            [
                              _c("v-progress-circular", {
                                staticClass: "mr-2",
                                attrs: { indeterminate: "", color: "primary" }
                              }),
                              _vm._v(" LOADING")
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("iframe", {
                    staticClass: "idp-preview-iframe",
                    attrs: { src: _vm.previewDocumentPathSelected },
                    on: {
                      load: function($event) {
                        return _vm.iframeLoaded()
                      }
                    }
                  })
                ]
              )
            ])
          ]),
          _c(
            "custom-dialog",
            _vm._b(
              { attrs: { slot: "options", eager: "" }, slot: "options" },
              "custom-dialog",
              _vm.customDialogDetails,
              false
            ),
            [
              (_vm.detailRecord
              ? true
              : false)
                ? _c("informed-api-log-details", {
                    ref: "informedApiLogDetails",
                    attrs: {
                      slot: "content",
                      data: _vm.detailRecord,
                      eager: ""
                    },
                    slot: "content"
                  })
                : _vm._e(),
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.getLogTitle()))
              ]),
              _c(
                "div",
                { attrs: { slot: "buttons" }, slot: "buttons" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3 primary",
                      attrs: { elevation: "2" },
                      on: {
                        click: function($event) {
                          _vm.downloadFile(
                            _vm.detailRecord.id,
                            "informed-api-logs_" +
                              _vm.detailRecord.endpointName.replaceAll(
                                " ",
                                "-"
                              ) +
                              "_" +
                              _vm.detailRecord.dateTimeCreated
                          )
                        }
                      }
                    },
                    [_vm._v(" Download ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", elevation: "2" },
                      on: {
                        click: function($event) {
                          _vm
                            .getRemoteComponent("customDialogDetails")
                            .external()
                            .close()
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "confirmation-dialog",
            _vm._b(
              { attrs: { buttons: _vm.buttons(), eager: "" } },
              "confirmation-dialog",
              _vm.confirmationDialog,
              false
            ),
            [
              _c(
                "div",
                { attrs: { slot: "message" }, slot: "message" },
                [
                  _c("v-checkbox", {
                    attrs: { label: "Send to Refund Control" },
                    model: {
                      value: _vm.sendToRefundControl,
                      callback: function($$v) {
                        _vm.sendToRefundControl = $$v
                      },
                      expression: "sendToRefundControl"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "confirmation-dialog",
            _vm._b(
              { attrs: { buttons: _vm.buttonsExit(), eager: "" } },
              "confirmation-dialog",
              _vm.confirmationDialogExit,
              false
            ),
            [
              _c("div", { attrs: { slot: "message" }, slot: "message" }, [
                _vm._v(" Save Data Before Exit? ")
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }