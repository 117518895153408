var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-text-field",
        _vm._b(
          {
            staticStyle: { cursor: "pointer" },
            attrs: { "append-icon": _vm.readonly ? null : "mdi-magnify" },
            on: {
              click: function($event) {
                return _vm.openSearchDialog()
              }
            }
          },
          "v-text-field",
          _vm.$props.fieldProps,
          false
        )
      ),
      _c(
        "v-dialog",
        {
          staticStyle: { background: "#EEE" },
          attrs: { "max-width": _vm.maxWidth, "min-width": "550px" },
          model: {
            value: _vm.searchDialogOpen,
            callback: function($$v) {
              _vm.searchDialogOpen = $$v
            },
            expression: "searchDialogOpen"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "ma-0 pa-0",
              staticStyle: { background: "#eeeeee" }
            },
            [
              _c("v-card-title", { staticClass: "dialog-card-header" }, [
                _c("span", { staticClass: "headline" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ]),
              _c(
                "v-icon",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer"
                  },
                  attrs: { center: "", large: "", color: "white" },
                  on: {
                    click: function($event) {
                      _vm.searchDialogOpen = false
                    }
                  }
                },
                [_vm._v(" mdi-close ")]
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: " pa-3" },
                [
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                ref: "searchField",
                                staticClass: "mr-3 ml-3",
                                attrs: {
                                  dense: "",
                                  autocomplete: "searchField",
                                  label: _vm.searchInputLabel,
                                  clearable: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.input()
                                  },
                                  "click:clear": function($event) {
                                    return _vm.input()
                                  }
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "ma-0 pa-0" },
                            [
                              _c("data-grid", {
                                ref: "SearchResultsGrid",
                                attrs: {
                                  apiDataUrl: _vm.apiDataUrl,
                                  apiMetadataUrl: _vm.apiMetadataUrl,
                                  forceTableHeight: 300,
                                  hideFilterSetup: "",
                                  itemsPerPageInitial: _vm.itemsPerPage,
                                  headerColumnsShow: _vm.columns,
                                  actions: _vm.actions,
                                  fixedConditions: _vm.conditions,
                                  fixedConditionsPreventCall: true,
                                  rowClickEvent: _vm.rowClickEvent,
                                  initialSortOrder: _vm.initialSortOrder,
                                  headersAutoParserMapping: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }