import ApiService from '@/services/api/Api.service';
import axios from 'axios';
import { ProviderForm } from '../../interfaces';

const instance = axios.create();

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') != -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result as string);
          resolve(Promise.reject(error));
        };
        reader.onerror = () => {
          reject(error);
        };
        reader.readAsText(error.response.data);
      });
    }

    return Promise.reject(error);
  },
);

class ProviderFormService extends ApiService {
  async createProviderForm(data: ProviderForm): Promise<ProviderForm> {
    return this.axios.post('/uiapi/provider-form/create', data);
  }
  async updateProviderForm(data: ProviderForm): Promise<ProviderForm> {
    return this.axios.post('/uiapi/provider-form/update', data);
  }
  async previewForm(providerFormId: number, productId: number) {
    return instance.get('/uiapi/provider-form/preview', {
      responseType: 'blob',
      params: {
        providerFormId,
        productId,
      },
    });
  }
}

export default new ProviderFormService();
