var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      outlined: "",
      dense: "",
      items: [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
      ],
      "hide-no-data": "",
      "hide-details": _vm.hideDetails
    },
    model: {
      value: _vm.fieldValueSync.value,
      callback: function($$v) {
        _vm.$set(_vm.fieldValueSync, "value", $$v)
      },
      expression: "fieldValueSync.value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }