var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-dialog",
    _vm._b(
      {
        attrs: {
          maxWidth: "70%",
          errors: _vm.errors,
          messages: _vm.messages,
          processing: _vm.processing
        }
      },
      "custom-dialog",
      _vm.CustomDialog,
      false
    ),
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("Finalize Products Process")
      ]),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("div", { staticClass: "mb-1" }, [
            _vm._v("Selected products (" + _vm._s(_vm.items.length) + ")")
          ]),
          _c(
            "v-data-table",
            _vm._b(
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.items.length > 0,
                    expression: "items.length > 0"
                  }
                ],
                staticClass: "elevation-1",
                attrs: {
                  dense: "",
                  options: _vm.dataTable.options,
                  height: "40vh",
                  items: _vm.items,
                  headers: _vm.accountHeaders
                },
                on: {
                  "update:options": function($event) {
                    return _vm.$set(_vm.dataTable, "options", $event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(
                      _vm.accountHeaders.filter(function(header) {
                        return header.hasOwnProperty("parse")
                      }),
                      function(header) {
                        return {
                          key: "item." + header.value,
                          fn: function(ref) {
                            var value = ref.value
                            return [
                              _c("span", {
                                key: header.value,
                                domProps: {
                                  innerHTML: _vm._s(header.parse(value))
                                }
                              })
                            ]
                          }
                        }
                      }
                    ),
                    {
                      key: "item.finalizationStatus",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "white-space": "nowrap",
                                width: "100%"
                              }
                            },
                            [
                              item.finalizationStatus
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "ma-2",
                                      attrs: {
                                        label: "",
                                        small: "",
                                        "text-color": "white",
                                        color: _vm.getStatusColor(
                                          item.finalizationStatus
                                        )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.finalizationStatus) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              "v-data-table",
              _vm.dataTable,
              false
            )
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "buttons" }, slot: "buttons" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-1",
              attrs: { disabled: _vm.processing, elevation: "2" },
              on: {
                click: function($event) {
                  _vm
                    .getRemoteComponent("CustomDialog")
                    .external()
                    .close()
                }
              }
            },
            [_vm._v(" Close ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-1 primary",
              attrs: {
                disabled: _vm.processing || _vm.sendProcessed,
                elevation: "2"
              },
              on: {
                click: function($event) {
                  return _vm.submitDataRequest(_vm.cancellationType)
                }
              }
            },
            [_vm._v(" Finalize ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }