var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customFieldsDefinition.length > 0
    ? _c(
        "v-card",
        {
          staticClass: "mt-8 ma-0",
          class: _vm.noFrame ? "ma-0 pa-0 mt-0" : "mt-8",
          staticStyle: { width: "100%" },
          attrs: { elevation: _vm.noFrame ? 0 : 1 }
        },
        [
          !_vm.noTitle
            ? _c(
                "v-card-title",
                [_c("v-row", [_c("v-col", [_vm._v(" Custom Fields ")])], 1)],
                1
              )
            : _vm._e(),
          _vm.render
            ? _c(
                "v-card-text",
                { class: _vm.noFrame ? "ma-0 pa-0" : "" },
                _vm._l(_vm.getFieldsRenderList, function(fieldType) {
                  return _c("div", { key: fieldType.name }, [
                    fieldType.name === "TWO_LINE"
                      ? _c(
                          "div",
                          { staticClass: "mt-2" },
                          _vm._l(fieldType.items, function(row, index) {
                            return _c(
                              "div",
                              { key: index },
                              _vm._l(
                                row.filter(function(item) {
                                  return item !== null
                                }),
                                function(field, fieldIndex) {
                                  return _c(
                                    "v-row",
                                    { key: fieldIndex },
                                    [
                                      field
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("custom-field", {
                                                        attrs: {
                                                          dense: "",
                                                          label: field.label,
                                                          rules: field.required
                                                            ? [
                                                                _vm.ValidationService.getValidator(
                                                                  "required",
                                                                  field.label
                                                                )
                                                              ]
                                                            : [],
                                                          definition: field
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.content[
                                                              field.apiName
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.content,
                                                              field.apiName,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "content[field.apiName]"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    fieldType.name === "ONE_LINE"
                      ? _c(
                          "div",
                          _vm._l(fieldType.items, function(row, index) {
                            return _c(
                              "v-row",
                              { key: index },
                              _vm._l(
                                row.filter(function(item) {
                                  return item !== null
                                }),
                                function(field, fieldIndex) {
                                  return _c(
                                    "v-col",
                                    { key: fieldIndex, attrs: { cols: 6 } },
                                    [
                                      field
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  field.type !== "Checkbox"
                                                    ? _c("custom-field", {
                                                        attrs: {
                                                          dense: "",
                                                          label: field.label,
                                                          definition: field
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.content[
                                                              field.apiName
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.content,
                                                              field.apiName,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "content[field.apiName]"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  field.type === "Checkbox"
                                                    ? _c("v-checkbox", {
                                                        attrs: {
                                                          dense: "",
                                                          label: field.label,
                                                          definition: field
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.content[
                                                              field.apiName
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.content,
                                                              field.apiName,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "content[field.apiName]"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          }),
                          1
                        )
                      : _vm._e()
                  ])
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }