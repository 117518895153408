



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import ValidationService from './../services/Validations.service';
import ProductService from './../services/api/Product.service';
import { Product, Account, Provider, ProductPayment, Transmission } from '../interfaces';
import { EnumItemI } from '../services/DealerPage.service';
import DealerService from '../services/DealerPage.service';
import SearchInputField from '../components/SearchInputField.vue';
import DataGrid from '../components/DataGrid.vue';
import PromptDialog from '../components/Prompt.vue';
import CustomDialog from '../components/CustomDialog.vue';
import CustomTooltip from '../components/CustomTooltip.vue';
import TransmissionDetails from '../components/TransmissionDetails.vue';
import ConsumerRefundDetails from '../components/ConsumerRefundDetails/ConsumerRefundDetails.vue';
import CustomFieldsForm from '../components/CustomFieldsForm.vue';
import ProductPaymentDetails from '@/components/ProductPaymentDetails.vue';
import RemoveProductPaymentAction from '@/components/RemoveProductPaymentAction.vue';
import RemoveConsumerRefundAction from '@/components/RemoveConsumerRefundAction.vue';
import ConsumerRefundCreate from '../components/ConsumerRefundDetails/ConsumerRefundCreate.vue';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import SendCancellationAction from '@/components/SendCancellationAction.vue';
import ReSendCancellationAction from '@/components/ReSendCancellationAction.vue';
import ReQuoteProductAction from '@/components/ReQuoteProductAction.vue';
import FormatDisplayService from '../services/FormatDisplay.service';
import moment from 'moment';
import MessagesCard from '@/components/MessagesCard.vue';
import UserService from '../services/api/User.service';
import AuditHistoryComponent from '@/components/SFComponents/AuditHistory/AuditHistoryComponent.vue';
import NotesComponent from '@/components/SFComponents/Notes/NotesComponent.vue';
import EventBus from '@/events/EventBus';

export default Vue.extend({
  name: 'ProductDetails',
  components: {
    SearchInputField,
    DataGrid,
    PromptDialog,
    CustomDialog,
    CustomTooltip,
    TransmissionDetails,
    ConsumerRefundDetails,
    ConsumerRefundCreate,
    SendCancellationAction,
    ReSendCancellationAction,
    ReQuoteProductAction,
    ProductPaymentDetails,
    RemoveProductPaymentAction,
    RemoveConsumerRefundAction,
    MessagesCard,
    CustomFieldsForm,
    AuditHistoryComponent,
    NotesComponent,
  },
  data: () => ({
    infoBoxTopMargin: 0 as number,
    selectedTab: '' as string,
    moment: moment,
    userService: UserService,
    otherAccountProducts: [] as Product[],
    formatDisplayService: FormatDisplayService,
    cancellationSentDateMenu: false,
    RemoveConsumerRefundActionu: false,
    contractDateMenu: false,
    lenderCancelDateMenu: false,
    originalCancellationSentDateMenu: false,
    refundReceivedDateMenu: false,
    providerCancelDateMenu: false,
    sendDelayDateMenu: false,
    finalizedDateMenu: false,
    overlay: false,
    valid: true,
    transmissionAmount: 0,
    productPaymentAmount: 0,
    consumerRefundAmount: 0,
    refundCalculationsAmount: 0,
    createProductPaymentMode: true,
    productPaymentDialogOpen: false,
    productPaymentDetails: {},
    errors: [] as string[],
    messages: [] as string[],
    reprocessPrompt: false,
    snackBarSuccess: false,
    snackBarError: false,
    snackBarValidateError: false,
    editedRecordSnapshot: null as null | Product,
    editedRecord: {
      productProcessingStatus: 'Missing Data',
      account: {
        dealer: {},
      },
      provider: {},
    } as Product,
    ValidationService: ValidationService as Object,
    countryItems: [] as EnumItemI[],
    consumerRefundDialogOpen: false,
    isPreviouslyCancelled: false,
    allowedFinalize: false,
    finalizeStateDisableCRUD: false,
    finalizePrompt: false,
    consumerRefundDialogCreateOpen: false,
    transmissionDialogOpen: false,
    editedRecordTransmissionDetails: {},
    editedRecordConsumerRefundDetails: {},
    service: MetadataService,
    productMetadataEnumsMap: new Map(),
    accountMetadataEnumsMap: new Map(),
    productIndex: 0 as number,
    contractTermTypeItems: ['Contract Date', 'In-Service Date'],
    productProcessingStatusItems: [
      'Missing Data',
      'Ready to Send',
      'Pending Send',
      'Cancellation Sent',
      'Pending Refund Data',
      'Lender Review',
      'Refund Data Available',
      'Finalized',
      'No Cancellable Product',
      'LCT Review',
    ],
    productProcessingStatusesMap: {
      'Missing Data': 'Missing Data',
      'Ready to Send': 'Ready to Send',
      'Pending Send': 'Ready to Send',
      'Cancellation Sent': 'Cancellation Sent',
      'Pending Refund Data': 'Cancellation Sent',
      'Lender Review': 'Refund Data Available',
      'Refund Data Available': 'Refund Data Available',
      Finalized: 'Finalized',
      'No Cancellable Product': 'Finalized',
      'LCT Review': 'Refund Data Available',
    },
    chevronStatuses: ['Missing Data', 'Ready to Send', 'Cancellation Sent', 'Refund Data Available', 'Finalized'],
  }),
  watch: {
    errors: {
      handler(n, o) {
        EventBus.$emit('FLOATING_MESSAGES_CHANGED', {
          messages: this.messages,
          errors: n,
          editedRecord: this.editedRecord,
        });
      },
      deep: true,
    },
    messages: {
      handler(n, o) {
        EventBus.$emit('FLOATING_MESSAGES_CHANGED', {
          messages: n,
          errors: this.errors,
          editedRecord: this.editedRecord,
        });
      },
      deep: true,
    },
    editedRecord: {
      handler(n, o) {
        EventBus.$emit('FLOATING_MESSAGES_CHANGED', {
          messages: this.messages,
          errors: this.errors,
          editedRecord: n,
        });
      },
      deep: true,
    },
  },
  methods: {
    providerPhoneNumber(): string | null {
      if (this.editedRecord && this.editedRecord.provider) {
        if (this.editedRecord?.provider?.refundPhone) {
          return this.editedRecord?.provider?.refundPhone;
        }
      }
      return null;
    },
    disabledForUser() {
      if (this.userService.getUserRole() === 'Admin') {
        return false;
      }
      return true;
    },
    updateFinalizedData(date: any) {
      if (moment(date, 'DD-MM-YYYY HH:mm:ss', true).isValid()) {
        date = null;
      }
      this.editedRecord.finalizedDate = date;
    },
    editButtonsFinalize(): boolean {
      if (this.editButtonsRepreocessOn()) {
        return false;
      }
      return this.getIsAllowedToFinalize;
    },
    editButtonsReSendCancellation(): boolean {
      if (this.editButtonsRepreocessOn()) {
        return false;
      }
      return this.enableInternalButtons && this.getReCancellationState;
    },
    editButtonsReQuote(): boolean {
      if (this.editButtonsRepreocessOn()) {
        return false;
      }
      return this.enableInternalButtons && this.getReCancellationState;
    },
    editButtonsSendCancellationOn(): boolean {
      return this.enableInternalButtons && this.getCancellationState;
    },
    editButtonsRepreocessOn(): boolean {
      if (this.disabled) {
        return false;
      }
      return this.editedRecord.reprocessingRequired || false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validFunc: function(v: string | null) {
      const scope = this;
      return {
        validateCancellationPercentage() {
          let success = true as boolean | string;
          let cancellationPercentageValue = scope.editedRecord.cancellationPercentage as number;

          if (cancellationPercentageValue && cancellationPercentageValue < 0) {
            return 'Cancellation Percentage cannot be less than 0';
          }

          if (cancellationPercentageValue && cancellationPercentageValue > 100) {
            return 'Cancellation Percentage cannot be more than 100';
          }

          return success;
        },
        validateCancellationFee() {
          let success = true as boolean | string;
          let cancellationFeeValue = scope.editedRecord.cancellationFee as number;

          if (cancellationFeeValue && cancellationFeeValue < 0) {
            return 'Cancellation Fee cannot be less than 0';
          }

          return success;
        },
        validateProviderRefundDetails() {
          let success = true as boolean | string;

          if (
            scope.editedRecord.refundDue &&
            scope.editedRecord.refundDue != null &&
            scope.editedRecord.contractPrice &&
            scope.editedRecord.contractPrice != null &&
            !scope.editedRecord.providerRefundDetails &&
            parseFloat(scope.editedRecord.refundDue.toString()) >
              parseFloat(scope.editedRecord.contractPrice.toString())
          ) {
            return 'Provider Refund Details required when Refund Due is greater than Contract Price';
          }

          return success;
        },
        validateRefundDue() {
          let success = true as boolean | string;
          //Refund Due cannot be less than 0 if value is present
          if (
            scope.editedRecord.refundDue &&
            scope.editedRecord.refundDue != null &&
            parseFloat(scope.editedRecord.refundDue.toString()) < 0
          ) {
            return 'Refund Due cannot be less than 0';
          }

          if (
            scope.editedRecord.refundDue &&
            scope.editedRecord.refundDue != null &&
            scope.editedRecord.contractPrice &&
            scope.editedRecord.contractPrice != null &&
            !scope.editedRecord.providerRefundDetails &&
            parseFloat(scope.editedRecord.refundDue.toString()) >
              parseFloat(scope.editedRecord.contractPrice.toString())
          ) {
            return 'Refund Due greater than Contract Price';
          }

          return success;
        },
        validateContractPrice() {
          let success = true as boolean | string;
          let refundDueValue = scope.editedRecord.refundDue as number;
          let contractPriceValue = scope.editedRecord.contractPrice as number;

          if (
            refundDueValue &&
            refundDueValue != null &&
            contractPriceValue &&
            contractPriceValue != null &&
            parseFloat(refundDueValue.toString()) > parseFloat(contractPriceValue.toString()) &&
            !scope.editedRecord.providerRefundDetails
          ) {
            return 'Contract Price less than Refund Due, Provider Refund Details required';
          }

          return success;
        },
        checkProductType() {
          let success = true as boolean | string;
          if (!scope.editedRecord.accountNumber || scope.editedRecord.accountNumber === '') {
            return 'Select Account';
          }
          scope.otherAccountProducts.forEach((product: Product) => {
            if (product.productType === scope.editedRecord.productType && product.id !== scope.editedRecord.id) {
              success =
                'Product Type: ' +
                scope.productMetadataEnumsMap
                  .get('productType')
                  .find((el: any) => el === scope.editedRecord.productType) +
                ' already exists on account: ' +
                scope.editedRecord.accountNumber;
            }
          });

          return success;
        },
        checkIfPurchaseOdometerIsGreaterThanAccountCancelOdometer() {
          let success = true as boolean | string,
            cancelOdometer = scope.editedRecord.account?.cancelOdometer as number,
            purchaseOdometer = scope.editedRecord.purchaseOdometer as number;

          if (!purchaseOdometer) {
            purchaseOdometer = 0;
          }

          if (purchaseOdometer > cancelOdometer) {
            return 'Purchase Odometer not saved - cannot be greater than Cancel Odometer';
          }

          return success;
        },
        checkIfCancelOdometerIsLessThanPurchaseOdometer() {
          let success = true as boolean | string;
          let cancelOdometer = 0 as number;
          const purchaseOdometer = scope.editedRecord.purchaseOdometer as number;

          if (scope.editedRecord.account.cancelOdometer) {
            cancelOdometer = scope.editedRecord.account.cancelOdometer as number;
          } else {
            cancelOdometer = 0;
          }

          let cancelOdometerLessThanPurchaseOdometer = false;

          //Check for current account product
          if (purchaseOdometer && purchaseOdometer > cancelOdometer) {
            cancelOdometerLessThanPurchaseOdometer = true;
          }

          //Check for other account products
          scope.otherAccountProducts.forEach(product => {
            if (product.purchaseOdometer && product.purchaseOdometer > cancelOdometer) {
              cancelOdometerLessThanPurchaseOdometer = true;
            }
          });

          if (cancelOdometerLessThanPurchaseOdometer) {
            return 'Cancel Odometer not saved - cannot be less than Purchase Odometer.';
          }

          return success;
        },
        checkIfContractDateIsSmallerOrEqualToAccountVehicleInContractDate() {
          let success = true as boolean | string,
            vehicleInServiceDate = scope.editedRecord.account?.vehicleInServiceDate;

          if (
            scope.editedRecord.contractDate &&
            vehicleInServiceDate &&
            moment(vehicleInServiceDate).isAfter(scope.editedRecord.contractDate)
          ) {
            return (
              'Product Contract Date cannot be prior to the Vehicle In-Service Date ' +
              moment(vehicleInServiceDate).format('MM/DD/YYYY')
            );
          }

          return success;
        },
        checkIfVehicleInServiceDateIsEqualToOrSmallerThanProductsContractDate() {
          let success = true as boolean | string;
          const errorMessage =
            'Please check Vehicle In-Service Date - Vehicle In-Service Date may not be after Contract Date';

          if (
            scope.editedRecord.account?.vehicleInServiceDate &&
            scope.editedRecord.contractDate &&
            moment(scope.editedRecord.account?.vehicleInServiceDate).isAfter(scope.editedRecord.contractDate)
          ) {
            return errorMessage;
          }

          if (scope.editedRecord.account?.vehicleInServiceDate) {
            let invalidVehicleInServiceDate = false;

            scope.otherAccountProducts.forEach(product => {
              if (
                product.contractDate &&
                moment(scope.editedRecord.account.vehicleInServiceDate).isAfter(product.contractDate)
              ) {
                invalidVehicleInServiceDate = true;
              }
            });

            if (invalidVehicleInServiceDate) {
              return errorMessage;
            }
          }

          return success;
        },
      };
    },
    recordDataRefresh() {
      if (!(this.editedRecord && this.editedRecord.id)) {
        return false;
      }
      this.formDataRefresh();
      this.error().clear();
      this.getRemoteComponent('ProductPaymentsGrid')?.submitDataRequest();
      this.getRemoteComponent('ConsumerRefundsGrid')?.submitDataRequest();
      this.getRemoteComponent('TransmissionsGrid')?.submitDataRequest();
      this.getRemoteComponent('RefundCalculationsGrid')?.submitDataRequest();
      this.getRemoteComponent('ProductNotesComponent')?.loadData();
    },
    async getProductsInAccount(
      accountNumber: string | null | undefined,
      orgId: string | null | undefined,
    ): Promise<Product[]> {
      return (await MetadataService.getRecords(
        { accountNumber: accountNumber || this.editedRecord.accountNumber, orgId: orgId || this.editedRecord.orgId },
        'All',
        '/uiapi/product/search',
      )) as Product[];
    },
    async formDataRefresh() {
      if (this.editedRecord.id !== null) {
        this.editedRecord = (await MetadataService.getRecord(
          { id: this.editedRecord.id },
          'All',
          '/uiapi/product/search',
        )) as Product;
        this.$emit('product-data-refresh', this.editedRecord);
        if (this.editedRecord.accountNumber !== null) {
          this.otherAccountProducts = await this.getProductsInAccount(
            this.editedRecord.accountNumber,
            this.editedRecord.orgId,
          );
        }
        if (this.getRemoteComponent('AuditHistoryComponent')) {
          this.getRemoteComponent('AuditHistoryComponent')
            .getRemoteComponent('AuditGrid')
            .loadData();
        }
        this.updateProductSnapshot();
        this.isFinalizeStateDisableCRUD();
        this.updateIsFinalizeStateDisableCRUD();
        if (this.getRemoteComponent('RefundCalculationsGrid')) {
          this.getRemoteComponent('RefundCalculationsGrid')?.loadData();
        }
        if (this.getRemoteComponent('ProductNotesComponent')) {
          this.getRemoteComponent('ProductNotesComponent')?.loadData();
        }
      }
    },
    // this adding cancelation functionality to product details
    sendCancellation() {
      this.getRemoteComponent('SendCancellationAction').open([this.editedRecord], 'Products');
    },
    reSendCancellation() {
      if (this.checkIfReporocessIsRequiredOnSave()) {
        this.getRemoteComponent('SaveWithReprocessPrompt')
          .external()
          .open();
        return false;
      }
      this.getRemoteComponent('ReSendCancellationAction').open(this.editedRecord as Product);
    },
    reQuoteProduct() {
      this.getRemoteComponent('ReQuoteProductAction').open(this.editedRecord as Product);
    },
    restoreProcessingStatus() {
      this.overlay = true;
      this.error().clear();
      this.message().clear();

      ProductService.restoreProcessingStatus({ productId: this.editedRecord.id as number })
        .then(result => {
          this.overlay = false;
          if (result.success && result.messages && result.data) {
            this.editedRecord = result.data;
            this.updateProductSnapshot();
            this.snackBarSuccess = true;
            this.reprocessPrompt = false;
            this.$emit('productUpdated');
            this.isAllowedToFinalize();
            this.isFinalizeStateDisableCRUD();
            this.updateIsFinalizeStateDisableCRUD();

            if (this.getRemoteComponent('AuditHistoryComponent')) {
              this.getRemoteComponent('AuditHistoryComponent')
                .getRemoteComponent('AuditGrid')
                .loadData();
            }
          } else {
            this.error().set(result.errors || ['Internal Error']);
            this.snackBarError = true;
            this.reprocessPrompt = false;
          }
        })
        .catch((error: string) => {
          this.error().add(error);
          this.overlay = false;
          this.snackBarError = true;
          this.reprocessPrompt = false;
        });

      return true;
    },
    showReprocessPrompt() {
      this.reprocessPrompt = true;
    },
    showFinalizePrompt() {
      if (this.checkIfReporocessIsRequiredOnSave()) {
        this.getRemoteComponent('SaveWithReprocessPrompt')
          .external()
          .open();
        return false;
      }
      this.finalizePrompt = true;
    },
    onProductPaymentsonGridItemsCount(amount: number) {
      this.productPaymentAmount = amount;
    },
    onTransmissionGridItemsCount(amount: number) {
      this.transmissionAmount = amount;
    },
    onConsumerRefundGridItemsCount(amount: number) {
      this.consumerRefundAmount = amount;
    },
    onRefundCalculationsGridItemsCount(amount: number) {
      this.refundCalculationsAmount = amount;
    },
    productPaymentDeleteSuccess() {
      this.getRemoteComponent('ProductPaymentsGrid').submitDataRequest();
      this.getRemoteComponent('RefundCalculationsGrid')?.loadData();
      this.formDataRefresh();
    },
    consumerRefundDeleteSuccess() {
      this.getRemoteComponent('ConsumerRefundsGrid').submitDataRequest();
      this.getRemoteComponent('RefundCalculationsGrid')?.loadData();
      this.formDataRefresh();
    },
    onConsumerRefundUpdated() {
      this.getRemoteComponent('ConsumerRefundsGrid').submitDataRequest();
      this.getRemoteComponent('RefundCalculationsGrid')?.loadData();
      this.formDataRefresh();
    },
    onReQuoteSuccess() {
      this.getRemoteComponent('ProductNotesComponent')?.loadData();
      this.formDataRefresh();
    },
    onConsumerRefundCreated() {
      this.getRemoteComponent('ConsumerRefundsGrid').submitDataRequest();
      this.getRemoteComponent('RefundCalculationsGrid')?.loadData();
      this.formDataRefresh();
      setTimeout(() => {
        this.setConsumerRefundCreateDialogOpen(false);
      }, 2500);
    },
    onProductPaymentUpdated() {
      this.getRemoteComponent('ProductPaymentsGrid').submitDataRequest();
      this.getRemoteComponent('RefundCalculationsGrid')?.loadData();
      this.formDataRefresh();
      setTimeout(() => {
        this.setProductPaymentDialogOpen(false);
      }, 2500);
    },
    onProductPaymentDialogClose() {
      this.setProductPaymentDialogOpen(false);
    },
    onConsumerRefundCreateDialogClose() {
      this.setConsumerRefundCreateDialogOpen(false);
    },
    async updateEditedRecord() {
      if (!this.createMode) {
        this.editedRecord = { ...this.passEditedRecord };
        if (!this.editedRecord.customFieldsValues) {
          this.editedRecord.customFieldsValues = {};
        }
      } else {
        if (this.prefilledAccount) {
          this.editedRecord.account = this.prefilledAccount;
          this.editedRecord.accountNumber = this.prefilledAccount.accountNumber;
          this.editedRecord.orgId = this.prefilledAccount.orgId;
          this.otherAccountProducts = await this.getProductsInAccount(
            this.prefilledAccount.accountNumber,
            this.prefilledAccount.orgId,
          );
        }
        if (this.orgId) {
          this.editedRecord.orgId = this.orgId;
          this.editedRecord.customFieldsValues = {};
        }
      }
      this.updateProductSnapshot();
    },
    selectDealer(rowData: { name: string; dealerNumber: string; dealerState: string }) {
      this.editedRecord.account.dealerState = rowData.dealerState;
      this.editedRecord.account.dealer = rowData;
      this.editedRecord.account.dealerNumber = rowData.dealerNumber;
    },
    async selectAccount(rowData: Account) {
      this.editedRecord.account = rowData;
      this.editedRecord.accountNumber = rowData.accountNumber;
      const orgId = this.prefilledAccount ? this.prefilledAccount.orgId : null;
      this.otherAccountProducts = await this.getProductsInAccount(this.editedRecord.accountNumber, orgId);
    },
    selectProvider(rowData: Provider) {
      this.editedRecord.provider = rowData;
      this.editedRecord.providerId = rowData.id;
    },
    setError(errors: string[]) {
      this.errors.length = 0;
      Object.assign(this.errors, errors);
    },
    async reprocess() {
      this.editedRecord.productProcessingStatus = 'Pending Send';
      await this.updateProduct();
      this.getRemoteComponent('SaveWithReprocessPrompt')
        .external()
        .close();
      this.reprocessPrompt = false;
    },
    async finalizeProduct() {
      this.overlay = true;
      this.error().clear();
      this.message().clear();

      ProductService.finalizeProcessingStatus({ productId: this.editedRecord.id as number })
        .then(result => {
          this.overlay = false;
          if (result.success && result.messages && result.data) {
            this.editedRecord = result.data;
            this.updateProductSnapshot();
            this.snackBarSuccess = true;
            this.finalizePrompt = false;
            this.$emit('productUpdated');
            this.isAllowedToFinalize();
            this.isFinalizeStateDisableCRUD();
            this.updateIsFinalizeStateDisableCRUD();
          } else {
            this.error().set(result.errors || ['Internal Error']);
            this.snackBarError = true;
            this.finalizePrompt = false;
          }
        })
        .catch((error: string) => {
          this.error().add(error);
          this.overlay = false;
          this.snackBarError = true;
          this.finalizePrompt = false;
        });

      return true;
    },
    checkIfReporocessIsRequiredOnSave(): boolean {
      if (this.editedRecord.id && this.editButtonsReSendCancellation() && this.isEditedRecordLenderActive) {
        if (this.editedRecordSnapshot?.providerId !== this.editedRecord.providerId) {
          return true;
        }
        if (this.editedRecordSnapshot?.account.VIN !== this.editedRecord.account.VIN) {
          return true;
        }
        if (this.editedRecordSnapshot?.cancelType !== this.editedRecord.cancelType) {
          return true;
        }
        if (this.editedRecordSnapshot?.account?.dealer?.id !== this.editedRecord.account?.dealer?.id) {
          return true;
        }
        if (this.editedRecordSnapshot?.productType !== this.editedRecord.productType) {
          return true;
        }
      }
      return false;
    },
    updateProductSnapshot() {
      this.editedRecordSnapshot = JSON.parse(JSON.stringify(this.editedRecord));
      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
    },
    isFormChanged() {
      return JSON.stringify(this.editedRecord) !== JSON.stringify(this.editedRecordSnapshot);
    },
    async validateProductRefundData() {
      try {
        this.snackBarValidateError = false;
        this.overlay = true;
        let response = await ProductService.validateProductData({
          product: this.editedRecord as Product,
        });
        this.error().clear();

        if (response.errors) {
          this.error().set(response.errors);
        }

        if (response.success) {
          this.editedRecord.feeRuleResult = response.data?.product?.feeRuleResult;
          this.editedRecord.feeRuleDetail = response.data?.product?.feeRuleDetail;
          this.editedRecord.termTolerance = response.data?.product?.termTolerance;
          this.editedRecord.termToleranceStatus = response.data?.product?.termToleranceStatus;
          this.editedRecord.milesTolerance = response.data?.product?.milesTolerance;
          this.editedRecord.milesToleranceStatus = response.data?.product?.milesToleranceStatus;
          this.editedRecord.refundTolerance = response.data?.product?.refundTolerance;
          this.editedRecord.refundToleranceStatus = response.data?.product?.refundToleranceStatus;
          this.editedRecord.messages = response.data?.product?.messages;
          this.$emit('productUpdated');
        }

        this.snackBarSuccess = true;
        this.overlay = false;
      } catch (e) {
        this.error().clear();
        this.error().set(['Unable to validate the product']);
        this.snackBarValidateError = true;
        this.overlay = false;
      }
    },
    async updateProduct(openNext: boolean = false) {
      if (this.checkIfReporocessIsRequiredOnSave()) {
        this.getRemoteComponent('SaveWithReprocessPrompt')
          .external()
          .open();
        return false;
      }
      this.error().clear();
      this.message().clear();
      if (!this.editedRecord.providerId) {
        this.editedRecord.provider = null;
      }
      try {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          this.overlay = true;
          let res = await ProductService.updateProductAndAccount({
            product: this.editedRecord as Product,
            account: this.editedRecord.account as Account,
          });

          if (res.success) {
            this.snackBarSuccess = true;
            this.overlay = false;
            this.editedRecord = res.data?.product as Product;
            this.editedRecord.account = res.data?.account as Account;
            this.updateProductSnapshot();
            if (this.getRemoteComponent('RefundCalculationsGrid')) {
              this.getRemoteComponent('RefundCalculationsGrid')?.loadData();
            }
            this.$emit('productUpdated');
            this.isAllowedToFinalize();
            this.isFinalizeStateDisableCRUD();
            if (openNext && this.filteredProducts[this.productIndex + 1]) {
              this.overlay = true;
              ProductService.getById(this.filteredProducts[this.productIndex + 1].id as number)
                .then(res => {
                  this.editedRecord = { ...res };
                  this.updateProductSnapshot();
                  this.overlay = false;
                  this.productIndex++;
                  this.updateSaveAndNextButtonVisibility(this.productIndex);
                  this.updateIsFinalizeStateDisableCRUD();
                })
                .catch(() => {
                  this.overlay = false;
                  this.snackBarError = true;
                });
            }
          } else {
            if (res.errors?.account) {
              this.error().set(res.errors?.account);
            }
            if (res.errors?.product) {
              this.error().set(res.errors?.product);
            }
            this.snackBarError = true;
            this.overlay = false;
            this.reprocessPrompt = false;
          }
        }
      } catch (err) {
        this.error().set(['Internal error']);
        this.snackBarError = true;
        this.overlay = false;
        this.reprocessPrompt = false;
      }
    },
    async createProduct() {
      this.error().clear();
      this.message().clear();
      if (!this.editedRecord.providerId) {
        this.editedRecord.provider = null;
      }
      try {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          this.overlay = true;
          let res = await ProductService.create(this.editedRecord as Product);

          if (res.success) {
            this.snackBarSuccess = true;
            this.overlay = false;
            this.editedRecord = res.data as Product;
            this.updateProductSnapshot();
            this.getRemoteComponent('RefundCalculationsGrid')?.loadData();
            this.$emit('update:createMode', false);
            this.$emit('productUpdated');
            this.$emit('saveAndNextButtonVisible', false);
            this.isFinalizeStateDisableCRUD();
            this.updateIsFinalizeStateDisableCRUD();
          } else {
            if (res.errors) {
              this.error().set(res.errors);
            }
            this.snackBarError = true;
            this.overlay = false;
          }
        }
      } catch (err) {
        this.error().set(['Internal error']);
        this.snackBarError = true;
        this.overlay = false;
      }
    },
    updateConsumerRefund() {
      this.getRemoteComponent('consumerRefundDetails').updateConsumerRefund();
      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
    },
    createConsumerRefund() {
      this.getRemoteComponent('consumerRefundCreate').createConsumerRefund();
      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
    },
    updateProductPaymentDetails() {
      this.getRemoteComponent('productPaymentDetails').updateProductPayment();
      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      const scope = this;
      return {
        set: (messages: string[]) => {
          scope.messages.push(...messages);
          return this;
        },
        add: (messages: string) => {
          scope.messages.push(messages);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
    isAllowedToFinalize() {
      if (
        this.editedRecord &&
        this.editedRecord.productProcessingStatus &&
        ['Pending Send', 'No Cancellable Product', 'Finalized'].indexOf(this.editedRecord.productProcessingStatus) == -1
      ) {
        this.allowedFinalize = true;
      } else {
        this.allowedFinalize = false;
      }
      return this.allowedFinalize;
    },
    isFinalizeStateDisableCRUD() {
      this.finalizeStateDisableCRUD =
        this.editedRecord?.finalized ||
        this.editedRecord?.productProcessingStatus === 'No Cancellable Product' ||
        !this.editedRecord?.orgPreference?.active;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    setTransmissionDialogOpen(state: boolean) {
      if (state) {
        this.getRemoteComponent('TransmissionDetailsDialog')
          .external()
          .open();
        this.transmissionDialogOpen = true;
      } else {
        this.getRemoteComponent('TransmissionDetailsDialog')
          .external()
          .close();
        this.transmissionDialogOpen = false;
      }
    },
    setConsumerRefundDialogOpen(state: boolean) {
      if (state) {
        this.getRemoteComponent('ConsumerRefundViewDialog')
          .external()
          .open();
        this.consumerRefundDialogOpen = true;
      } else {
        this.getRemoteComponent('ConsumerRefundViewDialog')
          .external()
          .close();
        this.consumerRefundDialogOpen = false;
      }
    },
    setConsumerRefundCreateDialogOpen(state: boolean) {
      if (state) {
        this.getRemoteComponent('ConsumerRefundCreateDialog')
          .external()
          .open();
        this.consumerRefundDialogCreateOpen = true;
      } else {
        this.getRemoteComponent('ConsumerRefundCreateDialog')
          .external()
          .close();
        this.consumerRefundDialogCreateOpen = false;
      }
    },
    setProductPaymentDialogOpen(state: boolean) {
      if (state) {
        this.getRemoteComponent('productPaymentDialog')
          .external()
          .open();
        this.productPaymentDialogOpen = true;
      } else {
        this.getRemoteComponent('productPaymentDialog')
          .external()
          .close();
        this.productPaymentDialogOpen = false;
      }
    },
    updateSaveAndNextButtonVisibility(index: number): void {
      if (this.filteredProducts.length < 2 || index === this.filteredProducts.length - 1) {
        this.$emit('saveAndNextButtonVisible', false);
      } else {
        this.$emit('saveAndNextButtonVisible', true);
      }
    },
    updateIsFinalizeStateDisableCRUD(): void {
      this.$emit('setFinalizeStateDisableCRUD', this.finalizeStateDisableCRUD);
    },
    checkVisibility(scrollY: any) {
      const targets = {
        RefProductDetailsMessages: this.messages,
        RefProductDetailsErrors: this.errors,
        RefProductDetailsRefundMessages: this.editedRecord?.messages?.refund_details_message,
        RefProductDetailsOdometerMessages: this.editedRecord?.messages?.purchase_odometer_messages,
      };
      Object.keys(targets).forEach((targetRefName: any) => {
        let targetItem = undefined;

        try {
          targetItem = this.$refs[targetRefName] as any;
          // @ts-ignore: Unreachable code error
          if (!targetItem || !targets[targetRefName]?.length) return;
        } catch (e) {
          return;
        }

        const targetRect = targetItem.$el.getBoundingClientRect();
        if (targetRect.height + targetRect.top < scrollY) {
          // show message implementation
        }
      });
    },
  },
  computed: {
    infos() {
      const requiredFields = {
        product: [
          { field: 'contractDate', label: 'Contract Date' },
          { field: 'contractPrice', label: 'Contract Price' },
          { field: 'contractTerm', label: 'Contract Term' },
          { field: 'lenderCancelDate', label: 'Lender Cancel Date' },
          { field: 'providerId', label: 'Provider' },
          { field: 'cancelType', label: 'Cancel Type' },
        ] as { field: keyof Product; label: string }[],
        account: [
          { field: 'make', label: 'Make' },
          { field: 'model', label: 'Model' },
          { field: 'year', label: 'Year' },
          { field: 'dealer', label: 'Dealer' },
        ] as { field: keyof Account; label: string }[],
      };

      if (
        (!this.editedRecord.account.customerFirstName || this.editedRecord.account.customerFirstName === '') &&
        (!this.editedRecord.account.customerLastName || this.editedRecord.account.customerLastName === '') &&
        (!this.editedRecord.account.customerBusinessName || this.editedRecord.account.customerBusinessName === '')
      ) {
        requiredFields.account.push({ field: 'customerBusinessName', label: 'Customer Business Name' });
        requiredFields.account.push({ field: 'customerFirstName', label: 'Customer First Name' });
        requiredFields.account.push({ field: 'customerLastName', label: 'Customer Last Name' });
      }
      if (
        !this.editedRecord.account.cancelOdometer &&
        (this.editedRecord.productType == 'VPP' ||
          this.editedRecord.productType == 'VSC' ||
          this.editedRecord.productType == 'WAR')
      ) {
        requiredFields.account.push({ field: 'cancelOdometer', label: 'Cancel Odometer' });
      }

      if (
        !this.editedRecord.contractMiles &&
        (this.editedRecord.productType == 'VPP' ||
          this.editedRecord.productType == 'VSC' ||
          this.editedRecord.productType == 'WAR')
      ) {
        requiredFields.product.push({ field: 'contractMiles', label: 'Contract Miles' });
      }

      const requiredFieldsArray = {} as { product: string[]; account: string[] };

      requiredFields.product.forEach((field: { field: keyof Product; label: string }) => {
        if (
          this.editedRecord[field.field] === null ||
          this.editedRecord[field.field] === false ||
          this.editedRecord[field.field] === ''
        ) {
          !requiredFieldsArray.product ? (requiredFieldsArray.product = []) : null;
          requiredFieldsArray.product.push(field.label);
        }
      });

      requiredFields.account.forEach((field: { field: keyof Account; label: string }) => {
        if (
          this.editedRecord.account[field.field] === null ||
          // @ts-ignore
          this.editedRecord.account[field.field] === false ||
          this.editedRecord.account[field.field] === ''
        ) {
          !requiredFieldsArray.account ? (requiredFieldsArray.account = []) : null;
          requiredFieldsArray.account.push(field.label);
        }
      });

      return requiredFieldsArray;
    },
    getProductAge() {
      if (!this.editedRecord.dateTimeCreated) {
        return '';
      }
      const currentDate = moment();
      const createdDate = moment(this.editedRecord.dateTimeCreated);
      let finalizedDate = null;
      if (this.editedRecord.finalizedDate) {
        finalizedDate = moment(this.editedRecord.finalizedDate);
      }
      let daysDifferent = currentDate.diff(createdDate, 'days');
      if (finalizedDate) {
        daysDifferent = finalizedDate.diff(createdDate, 'days') + 1;
      }
      return daysDifferent;
    },
    isEditedRecordLenderActive() {
      return this.editedRecord?.orgPreference?.active;
    },
    isReQuoteButtonActive() {
      if (
        this.editedRecord &&
        this.editedRecord.productProcessingStatus &&
        ['No Cancellable Product', 'Lender Review', 'Refund Data Available', 'LCT Review'].indexOf(
          this.editedRecord.productProcessingStatus,
        ) > -1
      ) {
        return true;
      }
      return false;
    },
    getReCancellationState() {
      if (
        this.editedRecord &&
        this.editedRecord.productProcessingStatus &&
        ['Cancellation Sent', 'Pending Refund Data', 'Lender Review', 'Refund Data Available', 'LCT Review'].indexOf(
          this.editedRecord.productProcessingStatus,
        ) > -1
      ) {
        return true;
      }
      return false;
    },
    getCancellationState(): boolean {
      if (this.editedRecord?.productProcessingStatus === 'Ready to Send') {
        return true;
      }
      return false;
    },
    getFinalizedState() {
      if (this.editedRecord?.productProcessingStatus === 'Finalized') {
        return true;
      }
      return false;
    },
    getIsAllowedToFinalize() {
      return this.allowedFinalize;
    },
    transmissionPreviews() {
      return {
        columnName: 'Docs',
        items: [
          {
            tooltip: (item: Transmission) => {
              return item.party + ' Letter Preview';
            },
            action: (item: Transmission) => {
              fetch(
                `/uiapi/cancellation-letter/get-transmission-preview?orgId=${item.orgId}&externalId=${item.externalId}`,
              )
                .then(response => response.json())
                .then(data => {
                  window.open(data.url);
                });
            },
            checkVisibility: (item: Transmission) => {
              return item.fileURL ? true : false;
            },
            image: (item: Transmission) => {
              if (item.party) {
                return ProductService.GetLetterIconLink(item.party);
              } else {
                return '';
              }
            },
          },
        ],
      };
    },
    transmissionActions() {
      return [
        {
          icon: 'mdi-table',
          tooltip: 'View',
          callback: (rowData: {}): void => {
            this.setTransmissionDialogOpen(true);
            this.editedRecordTransmissionDetails = rowData;
          },
        },
      ];
    },
    productPaymentActions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit',
          disabled:
            this.editedRecord?.productProcessingStatus === 'No Cancellable Product' ||
            !this.editedRecord?.orgPreference?.active,
          callback: (rowData: ProductPayment): void => {
            this.createProductPaymentMode = false;
            this.productPaymentDetails = rowData;
            this.setProductPaymentDialogOpen(true);
          },
        },
        {
          icon: 'mdi-delete',
          tooltip: 'Delete',
          name: 'Delete',
          disabled:
            this.editedRecord?.productProcessingStatus === 'No Cancellable Product' ||
            !this.editedRecord?.orgPreference?.active,
          callback: (rowData: ProductPayment) => {
            this.getRemoteComponent('RemoveProductPaymentAction').open(rowData);
          },
        },
      ];
    },
    buttonProductPaymentActions() {
      return [
        {
          icon: 'mdi-pencil',
          name: 'Create New Product Payment',
          disabled:
            this.editedRecord?.productProcessingStatus === 'No Cancellable Product' ||
            !this.editedRecord?.orgPreference?.active,
          callback: (): void => {
            this.createProductPaymentMode = true;
            this.setProductPaymentDialogOpen(true);
          },
        },
      ];
    },
    consumerRefundActions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit',
          disabled:
            this.editedRecord?.productProcessingStatus === 'No Cancellable Product' ||
            !this.editedRecord?.orgPreference?.active,
          callback: (rowData: {}): void => {
            this.editedRecordConsumerRefundDetails = rowData;
            this.setConsumerRefundDialogOpen(true);
          },
        },
        {
          icon: 'mdi-delete',
          tooltip: 'Delete',
          name: 'Delete',
          disabled:
            this.editedRecord?.productProcessingStatus === 'No Cancellable Product' ||
            !this.editedRecord?.orgPreference?.active,
          callback: (rowData: ProductPayment) => {
            this.getRemoteComponent('RemoveConsumerRefundAction').open(rowData);
          },
        },
      ];
    },
    buttonConsumerRefundActions() {
      return [
        {
          icon: 'mdi-pencil',
          name: 'Create New Consumer Refund',
          disabled:
            this.editedRecord?.productProcessingStatus === 'No Cancellable Product' ||
            !this.editedRecord?.orgPreference?.active,
          callback: (): void => {
            this.setConsumerRefundCreateDialogOpen(true);
          },
        },
      ];
    },
  },
  beforeDestroy() {
    EventBus.$emit('FLOATING_MESSAGES_CHANGED');
    EventBus.$off('PAGE_LAYOUT_CONTENT_SCROLL');
  },
  async created() {
    EventBus.$on('PAGE_LAYOUT_CONTENT_SCROLL', (data: any) => {
      if (data.pageName === 'Products' && this.selectedTab === 'notes') {
        if (data.scrollY < 159) {
          this.infoBoxTopMargin = 0;
        } else {
          this.infoBoxTopMargin = data.scrollY - 159;
        }
      } else {
        this.infoBoxTopMargin = 0;
      }
      this.checkVisibility(data.scrollY);
    });
    await this.updateEditedRecord();
    if (this.filteredProducts && !this.createMode) {
      this.productIndex = this.selectedProductIndex;
      this.updateSaveAndNextButtonVisibility(this.productIndex);
    }
    this.productMetadataEnumsMap = await this.service.loadData(MetadataType.PRODUCT);
    this.accountMetadataEnumsMap = await this.service.loadData(MetadataType.ACCOUNT);
    this.countryItems = await DealerService.getItems('DealerCountry');
    this.isAllowedToFinalize();
    this.isFinalizeStateDisableCRUD();
    this.updateIsFinalizeStateDisableCRUD();
    setTimeout(async () => {
      this.otherAccountProducts = await this.getProductsInAccount(
        this.editedRecord.accountNumber,
        this.editedRecord.orgId,
      );
      this.getRemoteComponent('form')?.validate();
    }, 250);
  },
  filters: {
    showPossibleTypes(productTypes: string[], accountPrducts: Product[], productId: number | undefined) {
      return (productTypes || []).filter((productType: string) => {
        return (
          accountPrducts.filter((accountProduct: Product) => {
            return accountProduct.productType === productType && productId !== accountProduct.id;
          }).length === 0
        );
      });
    },
    formatUserDateTime(user: string | null, dateTime: string | null) {
      const userDisplay = user === 'null' || user === '' || user === null || user === undefined ? '' : user + ', ';
      return userDisplay + (FormatDisplayService.getRender('toDateTime')(dateTime) || '');
    },
  },
  props: {
    passEditedRecord: {
      type: Object as PropType<Product>,
    },
    showRefresh: {
      type: Boolean,
      default: false,
    },
    createMode: {
      type: Boolean,
      default: false,
    },
    prefilledAccount: {
      type: Object,
      default: null,
    },
    orgId: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enableInternalButtons: {
      type: Boolean,
      default: false,
    },
    refundDetailsShow: {
      type: Boolean,
      default: false,
    },
    refundDetailsHideTopSection: {
      type: Boolean,
      default: false,
    },
    defaultTab: {
      type: String,
      default: 'notes',
    },
    filteredProducts: {
      type: Array as PropType<Product[]>,
      default: null,
    },
    selectedProductIndex: {
      type: Number,
    },
  },
});
