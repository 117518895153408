var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "FeeFormComponent",
      staticStyle: { display: "flex", flex: "1", "flex-direction": "column" },
      attrs: { readonly: _vm.readonly },
      model: {
        value: _vm.formValid,
        callback: function($$v) {
          _vm.formValid = $$v
        },
        expression: "formValid"
      }
    },
    [
      _c("SfCustomDialogLayout", { staticClass: "input-shrink" }, [
        _c(
          "div",
          {
            staticStyle: { width: "100%" },
            attrs: { slot: "base-info" },
            slot: "base-info"
          },
          [
            _c("v-progress-linear", {
              directives: [
                {
                  name: "visible",
                  rawName: "v-visible",
                  value: _vm.progress,
                  expression: "progress"
                }
              ],
              attrs: { indeterminate: "", color: "#0176d3" }
            }),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.messages.length > 0,
                    expression: "messages.length > 0"
                  }
                ],
                staticClass: "confirmation-message"
              },
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-alert",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "line-height": "15px"
                            },
                            attrs: {
                              type: "info",
                              border: "left",
                              text: "",
                              outlined: "",
                              dense: "",
                              autocomplete: "none"
                            }
                          },
                          [
                            _c("div", { staticClass: "mb-1" }, [
                              _c("b", [_vm._v("Messages:")])
                            ]),
                            _vm._l(_vm.messages, function(
                              message,
                              messageIndex
                            ) {
                              return _c("div", { key: messageIndex }, [
                                _vm._v("* " + _vm._s(message))
                              ])
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.length > 0,
                    expression: "errors.length > 0"
                  }
                ],
                staticClass: "error-message"
              },
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-alert",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "14px",
                              "line-height": "15px"
                            },
                            attrs: {
                              type: "error",
                              border: "left",
                              text: "",
                              outlined: "",
                              dense: ""
                            }
                          },
                          [
                            _c("div", { staticClass: "mb-1" }, [
                              _c("b", [_vm._v("Errors:")])
                            ]),
                            _vm._l(_vm.errors, function(error, errorIndex) {
                              return _c("div", {
                                key: errorIndex,
                                domProps: { innerHTML: _vm._s("* " + error) }
                              })
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "pl-5 pt-5",
            attrs: { slot: "main-right" },
            slot: "main-right"
          },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label" },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.metadataEnumsMap.get("effectiveTypeDate"),
                        label: "Effective Date Type",
                        outlined: "",
                        dense: ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.effectiveDateTypeInput()
                        }
                      },
                      model: {
                        value: _vm.content.effectiveTypeDate,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "effectiveTypeDate", $$v)
                        },
                        expression: "content.effectiveTypeDate"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label" },
                  [
                    _c("custom-date-picker", {
                      attrs: {
                        readonly: _vm.readonly,
                        outlined: "",
                        dense: "",
                        label: "Effective Date",
                        disabled: _vm.content.effectiveTypeDate === "AllTime",
                        rules:
                          _vm.content.effectiveTypeDate !== "AllTime"
                            ? [
                                _vm.ValidationService.getValidator(
                                  "required",
                                  "Effective Date"
                                )
                              ]
                            : []
                      },
                      model: {
                        value: _vm.content.effectiveDate,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "effectiveDate", $$v)
                        },
                        expression: "content.effectiveDate"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label" },
                  [
                    _vm.content.effectiveTypeDate !== "AllTime"
                      ? _c("custom-date-picker", {
                          attrs: {
                            readonly: _vm.readonly,
                            outlined: "",
                            dense: "",
                            disabled: "",
                            label: "Start Date"
                          },
                          model: {
                            value: _vm.content.effectiveDate,
                            callback: function($$v) {
                              _vm.$set(_vm.content, "effectiveDate", $$v)
                            },
                            expression: "content.effectiveDate"
                          }
                        })
                      : _vm._e(),
                    _vm.content.effectiveTypeDate === "AllTime"
                      ? _c("custom-date-picker", {
                          attrs: {
                            readonly: _vm.readonly,
                            outlined: "",
                            dense: "",
                            rules: [
                              _vm.ValidationService.getValidator(
                                "required",
                                "Start Date"
                              )
                            ],
                            label: "Start Date"
                          },
                          model: {
                            value: _vm.content.startDate,
                            callback: function($$v) {
                              _vm.$set(_vm.content, "startDate", $$v)
                            },
                            expression: "content.startDate"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label" },
                  [
                    _c("custom-date-picker", {
                      attrs: {
                        readonly: _vm.readonly,
                        outlined: "",
                        dense: "",
                        label: "End Date"
                      },
                      on: {
                        input: function($event) {
                          return _vm.input()
                        }
                      },
                      model: {
                        value: _vm.content.endDate,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "endDate", $$v)
                        },
                        expression: "content.endDate"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label" },
                  [
                    _c("v-select", {
                      attrs: {
                        label: "Fee Type",
                        outlined: "",
                        dense: "",
                        items: _vm.metadataEnumsMap.get("allowableFeeType")
                      },
                      model: {
                        value: _vm.content.allowableFeeType,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "allowableFeeType", $$v)
                        },
                        expression: "content.allowableFeeType"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label" },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "Allowable Fee",
                        rules: [
                          _vm.ValidationService.getValidator(
                            "required-any",
                            "Allowable Fee"
                          )
                        ],
                        outlined: "",
                        dense: ""
                      },
                      on: { keypress: _vm.$keyPressIntegerValidation },
                      model: {
                        value: _vm.content.allowableFee,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "allowableFee", $$v)
                        },
                        expression: "content.allowableFee"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c("v-checkbox", {
                      staticClass: "mt-1",
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        label: "Send to Lender Review"
                      },
                      model: {
                        value: _vm.content.sendToLenderReview,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "sendToLenderReview", $$v)
                        },
                        expression: "content.sendToLenderReview"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "pt-5",
            attrs: { slot: "param-up" },
            slot: "param-up"
          },
          [
            _c(
              "v-row",
              { staticStyle: { "margin-left": "0px" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "2" } },
                  [
                    _c("v-switch", {
                      staticClass: "mt-1",
                      attrs: { dense: "", label: "Active" },
                      on: {
                        change: function($event) {
                          return _vm.input()
                        }
                      },
                      model: {
                        value: _vm.content.active,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "active", $$v)
                        },
                        expression: "content.active"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "sfl-fix-label", attrs: { cols: "10" } },
                  [
                    _c("v-text-field", {
                      attrs: { label: "Rule Name", outlined: "", dense: "" },
                      model: {
                        value: _vm.content.name,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "name", $$v)
                        },
                        expression: "content.name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticStyle: { "margin-left": "0px" } },
              [
                _c(
                  "v-col",
                  [
                    _vm.metadataEnumsMap.get("productTypes")
                      ? _c("sf-enum-field", {
                          staticClass: "sfl-fix-label",
                          attrs: {
                            readonly: _vm.readonly,
                            label: "Product Type",
                            "field-data-type": "ARRAY",
                            "key-field": "value",
                            "search-field": "text",
                            items: _vm.metadataEnumsMap
                              .get("productTypes")
                              .map(function(o) {
                                return o.value
                              }),
                            itemsObjects: _vm.metadataEnumsMap.get(
                              "productTypes"
                            ),
                            rules: [
                              _vm.ValidationService.getValidator(
                                "required",
                                "Product Type"
                              )
                            ]
                          },
                          model: {
                            value: _vm.content.productTypes,
                            callback: function($$v) {
                              _vm.$set(_vm.content, "productTypes", $$v)
                            },
                            expression: "content.productTypes"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _vm.metadataEnumsMap.get("states")
                      ? _c("sf-enum-field", {
                          staticClass: "sfl-fix-label",
                          attrs: {
                            readonly: _vm.readonly,
                            label: "Dealer State",
                            "field-data-type": "ARRAY",
                            "key-field": "value",
                            "search-field": "text",
                            items: _vm.metadataEnumsMap
                              .get("states")
                              .map(function(o) {
                                return o.value
                              }),
                            itemsObjects: _vm.metadataEnumsMap.get("states"),
                            rules: [
                              _vm.ValidationService.getValidator(
                                "required",
                                "States"
                              )
                            ]
                          },
                          model: {
                            value: _vm.content.states,
                            callback: function($$v) {
                              _vm.$set(_vm.content, "states", $$v)
                            },
                            expression: "content.states"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _c("sf-lookup-field", {
                      staticClass: "sfl-fix-label",
                      attrs: {
                        readonly: _vm.readonly,
                        label: "Provider Name",
                        "field-data-type": "OBJECT_ARRAY",
                        "key-field": "id",
                        "search-field": "name",
                        "search-url": "/uiapi/provider/search",
                        "meta-data-url": "/uiapi/grids/provider"
                      },
                      model: {
                        value: _vm.content.providers,
                        callback: function($$v) {
                          _vm.$set(_vm.content, "providers", $$v)
                        },
                        expression: "content.providers"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: { flex: "1", display: "flex" },
            attrs: { slot: "param-down" },
            slot: "param-down"
          },
          [
            _c("div", { staticClass: "fee-list" }, [
              _c(
                "div",
                { staticClass: "fee-sub-list" },
                _vm._l(_vm.content.productTypes, function(
                  product,
                  productIndex
                ) {
                  return _c("div", { key: productIndex }, [
                    _vm._v(" " + _vm._s(product) + " ")
                  ])
                }),
                0
              )
            ]),
            _c("div", { staticClass: "fee-list" }, [
              _c(
                "div",
                { staticClass: "fee-sub-list" },
                _vm._l(_vm.content.states, function(state, stateIndex) {
                  return _c("div", { key: stateIndex }, [_vm._v(_vm._s(state))])
                }),
                0
              )
            ]),
            _c("div", { staticClass: "fee-list" }, [
              _c(
                "div",
                { staticClass: "fee-sub-list" },
                _vm._l(_vm.content.providers, function(
                  provider,
                  providerIndex
                ) {
                  return _c("div", { key: providerIndex }, [
                    _vm._v(" " + _vm._s(provider.name) + " ")
                  ])
                }),
                0
              )
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }