

































































import { Vue } from 'vue-property-decorator';
import DataGrid from '@/components/DataGrid.vue';
import { SubFilterConditionI } from '../interfaces';

export default Vue.extend({
  name: 'SearchInputField',
  components: { DataGrid },
  data: () => ({
    delay: null as null | number,
    processing: false,
    searchDialogOpen: false,
    search: '',
    conditions: [] as SubFilterConditionI[],
  }),
  methods: {
    rowClickEvent(rowData: {}) {
      this.searchDialogOpen = false;
      // @ts-ignore: Unreachable code error
      this.selectEvent(rowData);
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    openSearchDialog() {
      if (this.readonly === true) {
        return false;
      }
      if (this.conditions.length > 0) {
        this.conditions.length = 0;
        this.getRemoteComponent('SearchResultsGrid').submitDataRequest();
      }
      this.searchDialogOpen = true;
      this.search = '';
    },
    prepareConditions() {
      this.conditions.length = 0;
      this.conditions.push({
        conditionComparatorOption: 'contain',
        fieldName: this.searchField,
        fieldType: 'String',
        value: this.search,
      } as SubFilterConditionI);
      if (this.additionalConditions.length > 0) {
        this.additionalConditions.forEach(condition => {
          this.conditions.push(condition as SubFilterConditionI);
        });
      }
    },
    input() {
      if (!this.search) {
        this.search = '';
      }
      if (this.search.length >= 3 || this.search.length === 0) {
        if (this.delay) {
          clearTimeout(this.delay);
        }
        this.prepareConditions();
        this.delay = setTimeout(() => {
          this.getRemoteComponent('SearchResultsGrid').submitDataRequest();
        }, 500);
      }
    },
  },
  watch: {
    searchDialogOpen: {
      handler(newValue) {
        if (newValue) {
          if (this.delay) {
            clearTimeout(this.delay);
          }
          this.prepareConditions();
          this.delay = setTimeout(() => {
            this.getRemoteComponent('searchField').focus();
            this.getRemoteComponent('SearchResultsGrid').submitDataRequest();
          }, 100);
        }
      },
    },
  },
  props: {
    additionalConditions: {
      type: Array,
      default: () => {
        return []; // Array with Additional Conditions
      },
    },
    searchDialogOpenForce: {
      type: Boolean,
      default: false,
    },
    searchField: {
      type: String,
      default: 'name',
    },
    maxWidth: {
      type: String,
      default: '550px',
    },
    title: {
      type: String,
      default: 'Search',
    },
    fieldProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    columns: {
      type: Array,
      default: () => {
        return {};
      },
    },
    apiDataUrl: {
      type: String,
      default: 'emptyDataUrl', // url to data endpoint for current grid
    },
    apiMetadataUrl: {
      type: String,
      default: 'emptyMetaDataUrl', // url to metadata endpoint for current grid
    },
    actions: {
      type: Array,
      default: () => {
        return [{ icon: 'mdi-pencil', callback: () => {}, tooltip: 'Select Item' }]; // Array with Acions which will be added to grid as additional Action Column
      },
    },
    searchInputLabel: {
      type: String,
      default: 'Search',
    },
    selectEvent: {
      type: Function,
      default: (rowData: {}, cell: {}): void => {
        // console.log('rowclick', rowData, cell);
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    initialSortOrder: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
});
