













import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  name: 'SfBooleanSelectField',
  components: {},
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    input() {
      this.$emit('input', this.content);
    },
  },
  computed: {},
  watch: {
    '$props.value'(v) {
      this.content = v;
    },
  },
  props: ['value'],
});
