var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        flex: "1",
        background: "white",
        border: "1px solid #f3f1f4"
      }
    },
    [
      _c(
        "nav",
        {
          staticClass: "slds-nav-vertical",
          staticStyle: { "font-size": "13px" },
          attrs: { "aria-label": "Sub page" }
        },
        _vm._l(_vm.menu, function(menuData) {
          return _c(
            "div",
            {
              key: menuData.menuName,
              staticClass: "slds-nav-vertical__section"
            },
            [
              _c(
                "h2",
                {
                  staticClass: "slds-nav-vertical__title",
                  attrs: { id: "entity-header" }
                },
                [_vm._v(_vm._s(menuData.menuName))]
              ),
              _c(
                "ul",
                { attrs: { "aria-describedby": "entity-header" } },
                _vm._l(menuData.menuItems, function(item, index) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "visible",
                          rawName: "v-visible",
                          value: !item.disabled,
                          expression: "!item.disabled"
                        }
                      ],
                      key: index,
                      class: _vm.getLiClass(item, _vm.content)
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "slds-nav-vertical__action",
                          class: item.children ? "pl-2" : "",
                          attrs: {
                            href: "#",
                            "aria-current":
                              item === _vm.content ? "true" : "false"
                          },
                          on: {
                            click: function($event) {
                              item.children
                                ? _vm.expandCollapseChildren(item)
                                : _vm.selectMenuItem(item)
                            }
                          }
                        },
                        [
                          item.children
                            ? _c("v-icon", {
                                domProps: {
                                  textContent: _vm._s(
                                    item.expand
                                      ? "mdi-menu-up"
                                      : "mdi-menu-down"
                                  )
                                }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              style: {
                                textDecoration:
                                  item === _vm.content ? "underline" : "none"
                              }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        ],
                        1
                      ),
                      (item.children && item.expand
                      ? true
                      : false)
                        ? _c(
                            "ul",
                            { attrs: { "aria-describedby": "entity-header" } },
                            _vm._l(item.children, function(
                              subItem,
                              subItemIndex
                            ) {
                              return _c(
                                "li",
                                {
                                  directives: [
                                    {
                                      name: "visible",
                                      rawName: "v-visible",
                                      value: !item.disabled,
                                      expression: "!item.disabled"
                                    }
                                  ],
                                  key: subItemIndex,
                                  staticClass: "slds-nav-vertical__item",
                                  class:
                                    subItem.name === _vm.content.name
                                      ? "slds-is-active"
                                      : ""
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "pl-11 slds-nav-vertical__action",
                                      attrs: {
                                        href: "#",
                                        "aria-current":
                                          subItem === _vm.content
                                            ? "true"
                                            : "false"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectMenuItem(subItem)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          style: {
                                            textDecoration:
                                              subItem === _vm.content
                                                ? "underline !important"
                                                : "none"
                                          }
                                        },
                                        [_vm._v(_vm._s(subItem.name))]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }