































































































































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import ValidationService from '@/services/Validations.service';
import FieldDefinitionService from '@/services/api/FieldDefinition.service';
import ProviderPageService, { EnumItemI } from '@/services/ProviderPage.service';

@Component({
  components: {},
})
export default class SfCustomParamBoolean extends Vue {
  parameters!: any[];
  stateList: any;
  statesList: any;
  vMask(vMask: any) {
    throw new Error('Method not implemented.');
  }
  @PropSync('letterOverrides') fieldValueSync!: any;
  @Prop([String]) readonly orgId: boolean | undefined;
  @Prop([Boolean]) readonly readonly: boolean | undefined;
  @Prop([Boolean]) readonly disabled: boolean | undefined;
  @Prop([Boolean]) readonly hideDetails: boolean | undefined;
  commonFieldsConf: any;
  validationService: any;
  data() {
    return {
      ready: false,
      commonFieldsConf: {
        dense: true,
        hideDetails: true,
        outlined: true,
      },
      validationService: ValidationService,
      parameters: [] as any[],
      stateList: [] as { value: any; text: any }[],
    };
  }
  async mounted() {
    this.stateList = await ProviderPageService.getItems('ProviderStates');
    this.$data.ready = true;
    setTimeout(() => {
      Object.keys(this.$refs).forEach((p: any) => {
        (this.$refs[p] as any)?.$emit('input');
      });
    }, 500);
    this.$data.ready = true;
  }
}
