































import { Vue } from 'vue-property-decorator';
import { Product } from '../interfaces';
import CustomDialog from '@/components/CustomDialog.vue';
import axios from '../services/api/Api.service';
import ValidationService from './../services/Validations.service';
import { AxiosResponse } from 'axios';

const axiosInstance = new axios().getClient();

const reQuoteProductUrl = '/uiapi/product/re-quote';

export default Vue.extend({
  name: 'ReQuoteProductAction',
  components: { CustomDialog },
  data: () => ({
    reQuoteProcessed: false as boolean,
    ValidationService: ValidationService,
    item: null as null | Product[],
    openDialog: false,
    errors: [] as string[],
    messages: [] as string[],
    product: null as Product | null,
    processing: false,
    CustomDialog: {
      ref: 'CustomDialog',
    },
  }),
  methods: {
    sendReQuoteRequest(): boolean {
      this.error().clear();
      this.message().clear();

      let url = '';

      url = reQuoteProductUrl;

      this.processing = true;

      const requestObject = {
        productId: this.product?.id,
        orgId: this.product?.orgId,
        accountNumber: this.product?.accountNumber,
        productExternalId: this.product?.externalId,
      };

      axiosInstance
        .post<any, AxiosResponse<{ success: boolean; errors: string[]; messages: string[] }>>(url, requestObject)
        .then(result => {
          if (result.data.success) {
            this.message().set(result.data.messages);
            if (result.data.errors?.length > 0) {
              this.error().set(result.data.errors);
            }
            this.reQuoteProcessed = true;
            this.$emit('success');
          } else {
            this.error().set(result.data.errors);
            this.$emit('error');
          }
          this.processing = false;
          this.getRemoteComponent('CustomDialog')
            .external()
            .open();
        })
        .catch((error: string) => {
          this.error().add(error);
          this.processing = false;
          this.getRemoteComponent('CustomDialog')
            .external()
            .open();
        });
      return true;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    open(product: Product): void {
      this.product = product;
      this.processing = false;
      this.reQuoteProcessed = false;
      this.error().clear();
      this.message().clear();
      this.getRemoteComponent('CustomDialog')
        .external()
        .open();
      this.openDialog = true;
    },
    error() {
      return {
        set: (errors: string[]) => {
          this.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      return {
        set: (errors: string[]) => {
          this.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.messages.push(error);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
  },
});
