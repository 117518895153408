






















































































































































import { Vue } from 'vue-property-decorator';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import ValidationService from '@/services/Validations.service';
import axios from '@/services/api/Api.service';
import UserService from '@/services/api/User.service';
import { AxiosResponse } from 'axios';
import SfCustomParameters from '@/components/SFComponents/CustomParameters/SfCustomParameters.vue';
import SfCustomDialogLayout from '@/components/SFComponents/SfCustomDialogLayout.vue';
import { DaysToRefundI } from '@/interfaces/days-to-refund.interface';
const axiosInstance = new axios().getClient();
import { prepareSaveDate } from '@/components/SFComponents/LenderServiceConfiguration/utils/ManagementRules';

const saveUrl = '/uiapi/config-days-to-refund';

export default Vue.extend({
  data() {
    return {
      selectedTab: 'RULE',
      refresh: false,
      debug: true,
      isSelecting: false,
      selectedFile: null,
      ValidationService: ValidationService,
      formValid: true,
      metadataEnumsMapLetter: new Map(),
      service: MetadataService,
      content: this.value as DaysToRefundI,
      errors: [] as string[],
      messages: [] as string[],
      progress: false as boolean,
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      options: {
        sendTo: [
          { text: 'Select', value: null },
          { text: 'Dealer', value: 'Dealer' },
          { text: 'Provider', value: 'Provider' },
          { text: 'Both', value: 'Both' },
        ],
        noticeTypes: [
          { text: '1st Notice', value: '1st Notice' },
          { text: '2nd Notice', value: '2nd Notice' },
          { text: 'Final Notice', value: 'Final Notice' },
        ],
      },
    };
  },
  components: { SfCustomParameters, SfCustomDialogLayout },
  name: 'DaysToRefundEditForm',
  watch: {
    '$props.value'(v) {
      this.content = v;
    },
    'content.managementRuleParameters': {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
    content() {
      this.$emit('input', this.content);
    },
  },
  async created() {
    await this.refreshRecordData();
    this.refresh = true;
    this.error().clear();
    this.message().clear();
    this.metadataEnumsMapLetter = await this.service.loadData(MetadataType.CONFIG_LETTER);
    this.validateForm(true);
  },
  methods: {
    /**
     * Important !!
     * @CustomFieldMetadata in Entity need to have dataFilter Object for control
     * which fields need to stay as properties in value data object
     */
    prepareSaveDate() {
      prepareSaveDate(this.content);
    },
    addCustomParameters() {
      return this.$getRemoteComponent('REFCustomParameters', this).addParam();
    },
    input() {
      this.$emit('input', this.content);
    },
    async refreshRecordData() {
      if (this.content.id) {
        this.content = this.updateRecordAfterRefresh(
          await MetadataService.getRecord(
            { orgId: this.content.orgId, id: this.content.id },
            'All',
            '/uiapi/config-days-to-refund/search',
          ),
        ) as DaysToRefundI;
        this.input();
      }
    },
    updateRecordAfterRefresh(content: any) {
      return content;
    },
    effectiveDateTypeInput() {
      this.content.effectiveDate = this.content.effectiveDateType === 'AllTime' ? null : this.content.effectiveDate;
    },
    validateForm(hideErrorBox?: boolean) {
      setTimeout(() => {
        hideErrorBox = hideErrorBox || false;
        const form = this.$getRemoteComponent('DaysToRefundFormComponent', this);

        if (form) {
          form.validate();
        }

        if (hideErrorBox !== true) {
          if (form) {
            const errors = [] as string[];

            if (this.errors && this.errors.length > 0) {
              this.errors.forEach(errorMessage => {
                if (!errors.includes(errorMessage)) {
                  errors.push(errorMessage);
                }
              });
            }

            this.$getAllNestedInputs(form, true).forEach((inputWithError: any) => {
              errors.push(...inputWithError.errorBucket.map((e: string) => e + ', please update and try again.'));
            });
            this.error().set(errors);
          }
        }
      }, 250);
    },
    setProgress(val: boolean): boolean {
      this.progress = val;
      this.$forceUpdate();
      return this.progress;
    },
    message() {
      const scope = this;
      return {
        set: (messages: string[]) => {
          scope.messages.push(...messages);
          return this;
        },
        add: (messages: string) => {
          scope.messages.push(messages);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.length = 0;
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    async submitForm() {
      await this.saveData();
      if (this.errors.length > 0) {
        return Promise.reject(false);
      } else {
        setTimeout(() => {
          return Promise.resolve(true);
        }, 1500);
      }
    },
    saveData() {
      this.validateForm();
      if (!this.formValid) {
        this.error().clear();
        this.message().clear();

        return Promise.reject(false);
      }
      let url = saveUrl;
      let insert = true;

      const requestData = JSON.parse(JSON.stringify(this.content));

      if (requestData && requestData.id && requestData.id > 0) {
        url += '/' + requestData.id;
        insert = false;
      }

      if (requestData && requestData.effectiveDateType !== 'AllTime') {
        requestData.startDate = requestData.effectiveDate;
      }

      if (insert) {
        delete requestData.externalId;
        delete requestData.createdDate;
        delete requestData.createdById;
        delete requestData.updatedById;
        delete requestData.dateTimeUpdated;
      } else {
        delete requestData.externalId;
        delete requestData.createdDate;
        delete requestData.createdById;
        delete requestData.updatedById;
      }

      this.setProgress(true);
      this.error().clear();
      this.message().clear();
      return axiosInstance
        .post<
          DaysToRefundI,
          AxiosResponse<{ success: boolean; messages: string[]; errors: string[]; data: DaysToRefundI }>
        >(url, requestData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(result => {
          this.setProgress(false);
          if (result.data.success) {
            this.content = result.data.data;
            this.$emit('input', this.content);
            this.$emit(insert ? 'row-added' : 'row-updated');
            if (result.data.messages && result.data.messages.length > 0) this.message().set(result.data.messages);
          } else {
            // Setting errors will show them on form
            this.error().set(result.data.errors);
          }
        })
        .catch((error: string) => {
          this.setProgress(false);
          this.error().set([error]);
        });
    },
  },
  props: ['value', 'id', 'orgId'],
});
