























































































































































































































import { Vue } from 'vue-property-decorator';
import { LetterI } from '@/interfaces';
import SfLetterSelectUpload from '@/components/SFComponents/SfLetterSelectUpload.vue';
import LetterOverridesForm from '@/components/SFComponents/LenderServiceConfiguration/Letters/LetterOverridesForm.vue';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import ValidationService from '@/services/Validations.service';
import axios from '@/services/api/Api.service';
import UserService from '@/services/api/User.service';
import { AxiosResponse } from 'axios';
import SfCustomParameters from '@/components/SFComponents/CustomParameters/SfCustomParameters.vue';
import SfCustomDialogLayout from '@/components/SFComponents/SfCustomDialogLayout.vue';
import { prepareSaveDate } from '@/components/SFComponents/LenderServiceConfiguration/utils/ManagementRules';
const axiosInstance = new axios().getClient();

const saveUrl = '/uiapi/management-rule';

export default Vue.extend({
  data() {
    return {
      selectedTab: 'RULE',
      refresh: false,
      debug: true,
      isSelecting: false,
      selectedFile: null,
      ValidationService: ValidationService,
      formValid: true,
      metadataEnumsMapLetter: new Map(),
      service: MetadataService,
      content: this.value as LetterI,
      errors: [] as string[],
      messages: [] as string[],
      progress: false as boolean,
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      options: {
        sendTo: [
          { text: 'Select', value: null },
          { text: 'Dealer', value: 'Dealer' },
          { text: 'Provider', value: 'Provider' },
          { text: 'Both', value: 'Both' },
        ],
        noticeTypes: [
          { text: '1st Notice', value: '1st Notice' },
          { text: '2nd Notice', value: '2nd Notice' },
          { text: 'Final Notice', value: 'Final Notice' },
        ],
      },
    };
  },
  components: { SfLetterSelectUpload, SfCustomParameters, SfCustomDialogLayout, LetterOverridesForm },
  name: 'LenderServiceConfigurationLetterEditForm',
  watch: {
    '$props.value'(v) {
      this.content = v;
    },
    'content.managementRuleParameters': {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
    content(v) {
      this.$emit('input', this.content);
    },
  },
  async created() {
    await this.refreshRecordData();
    this.content.letterOverrides = this.content.letterOverrides || {};
    this.refresh = true;
    this.error().clear();
    this.message().clear();
    this.metadataEnumsMapLetter = await this.service.loadData(MetadataType.CONFIG_LETTER);
    this.validateForm(true);
  },
  methods: {
    prepareSaveDate() {
      prepareSaveDate(this.content);
    },
    addCustomParameters() {
      return this.$getRemoteComponent('REFCustomParameters', this).addParam();
    },
    autoSendIntervalInfo() {
      if (['Provider', null].indexOf(this.content.sendTo) > -1) {
        return 'Only for Send To: Dealer, Both';
      } else {
        if (this.content.noticeType === '1st Notice') {
          return 'Not for 1st Notice Type';
        }
      }
      return '';
    },
    updateAfternoticeTypeChange(value: any) {
      if (value === '1st Notice') {
        this.content.autoSendInterval = null;
      }
    },
    updateAfterSendToChange(value: any) {
      this.content.letterFile = null;
      this.content.letterFileExternalId = null;
      if (['Provider', null].indexOf(value) > -1) {
        this.content.autoSendInterval = null;
      }
    },
    getPartyCondition() {
      switch (this.content.sendTo) {
        case 'Provider':
          return {
            fieldName: 'party',
            value: ['Provider', 'Both'],
            fieldType: 'string',
            conditionComparatorOption: 'in',
          };
        case 'Dealer':
          return {
            fieldName: 'party',
            value: ['Dealer', 'Both'],
            fieldType: 'string',
            conditionComparatorOption: 'in',
          };
        case 'Both':
          return {
            fieldName: 'party',
            value: ['Both'],
            fieldType: 'string',
            conditionComparatorOption: 'in',
          };
      }
      return {
        fieldName: 'party',
        value: ['Both', 'Provider', 'Dealer'],
        fieldType: 'string',
        conditionComparatorOption: 'in',
      };
    },
    selectLetter(letterRecord: any) {
      this.content.letterFile = letterRecord;
      this.content.letterFileExternalId = letterRecord.externalId;
      this.input();
    },
    input() {
      this.$emit('input', this.content);
    },
    async refreshRecordData() {
      if (this.content.id) {
        this.content = this.updateRecordAfterRefresh(
          await MetadataService.getRecord(
            { orgId: this.content.orgId, id: this.content.id },
            'All',
            '/uiapi/management-rule/search',
          ),
        ) as LetterI;
        this.input();
      }
    },
    updateRecordAfterRefresh(content: any) {
      return content;
    },
    effectiveDateTypeInput() {
      this.content.effectiveDate = this.content.effectiveDateType === 'AllTime' ? null : this.content.effectiveDate;
    },
    validateForm(hideErrorBox?: boolean) {
      setTimeout(() => {
        hideErrorBox = hideErrorBox || false;
        const form = this.$getRemoteComponent('LetterFormComponent', this);

        if (form) {
          form.validate();
        }

        if (hideErrorBox !== true) {
          if (form) {
            const errors = [] as string[];

            if (this.errors && this.errors.length > 0) {
              this.errors.forEach(errorMessage => {
                if (!errors.includes(errorMessage)) {
                  errors.push(errorMessage);
                }
              });
            }

            this.$getAllNestedInputs(form, true).forEach((inputWithError: any) => {
              errors.push(...inputWithError.errorBucket.map((e: string) => e + ', please update and try again.'));
            });
            this.error().set(errors);
          }
        }
      }, 250);
    },
    setProgress(val: boolean): boolean {
      this.progress = val;
      this.$forceUpdate();
      return this.progress;
    },
    message() {
      const scope = this;
      return {
        set: (messages: string[]) => {
          scope.messages.push(...messages);
          return this;
        },
        add: (messages: string) => {
          scope.messages.push(messages);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.length = 0;
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    async submitForm() {
      await this.saveData();
      if (this.errors.length > 0) {
        return Promise.reject(false);
      } else {
        setTimeout(() => {
          return Promise.resolve(true);
        }, 1500);
      }
    },
    checkLetterFileStatus() {
      return !this.content.letterFileExternalId ? false : true;
    },
    saveData() {
      this.validateForm();
      if (!this.formValid) {
        this.error().clear();
        this.message().clear();
        if (!this.checkLetterFileStatus()) {
          this.error().add('Missing Letter File');
        }
        return Promise.reject(false);
      }
      let url = saveUrl;
      let insert = true;

      const requestData = JSON.parse(JSON.stringify(this.content));

      if (requestData && requestData.id && requestData.id > 0) {
        url += '/' + requestData.id;
        insert = false;
      }

      if (requestData && requestData.effectiveDateType !== 'AllTime') {
        requestData.startDate = requestData.effectiveDate;
      }

      if (requestData && requestData.applyFreeLookPeriod === false) {
        requestData.freeLookPeriod = 0;
      }

      if (requestData.autoSendInterval === '' || !requestData.autoSendInterval) {
        requestData.autoSendInterval = null;
      }

      if (insert) {
        delete requestData.externalId;
        delete requestData.createdDate;
        delete requestData.createdById;
        delete requestData.updatedById;
        delete requestData.dateTimeUpdated;
      } else {
        delete requestData.externalId;
        delete requestData.createdDate;
        delete requestData.createdById;
        delete requestData.updatedById;
      }

      this.setProgress(true);
      this.error().clear();
      this.message().clear();
      return axiosInstance
        .post<LetterI, AxiosResponse<{ success: boolean; messages: string[]; errors: string[]; data: LetterI }>>(
          url,
          requestData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(result => {
          this.setProgress(false);
          if (result.data.success) {
            this.content = result.data.data;
            this.$emit('input', this.content);
            this.$emit(insert ? 'row-added' : 'row-updated');
            if (result.data.messages && result.data.messages.length > 0) this.message().set(result.data.messages);
          } else {
            // Setting errors will show them on form
            this.error().set(result.data.errors);
          }
        })
        .catch((error: string) => {
          this.setProgress(false);
          this.error().set([error]);
        });
    },
  },
  filters: {
    extendCancelType(v: 'O' | 'P' | 'R') {
      const translateCancelType = [
        { code: 'O', name: 'OTR' },
        { code: 'P', name: 'PO' },
        { code: 'R', name: 'REPO' },
      ] as { code: string; name: string }[];
      const output = translateCancelType.find(t => t.code === v);
      return output ? output.name : v;
    },
  },
  props: ['value', 'id', 'orgId'],
});
