


































































import { Vue } from 'vue-property-decorator';
import SfDataGrid from './SfDataGrid.vue';
import SfLetterUpload from '@/components/SFComponents/SfLetterUpload.vue';
import EventBus from '@/events/EventBus';

export default Vue.extend({
  name: 'SfLetterSelectUpload',
  data() {
    return {
      search: '' as string,
      errors: [] as string[],
      messages: [] as string[],
      loading: false as boolean,
      open: false,
      content: this.value || null,
      timeoutDelay: null as null | number,
    };
  },
  components: { SfDataGrid, SfLetterUpload },
  methods: {
    getDefaultParty() {
      const defaultParty = this.gridConditions.find((gc: any) => gc.fieldName === 'party') as any;
      return defaultParty ? defaultParty.value : null;
    },
    getConditions() {
      const gridConditions = this.$getClone(this.gridConditions);
      if (this.search && typeof this.search === 'string') {
        gridConditions.push({
          fieldName: 'name',
          value: this.search,
          fieldType: 'string',
          conditionComparatorOption: 'contain',
        });
      }
      return gridConditions;
    },
    rowClick(row: any) {
      this.$emit('select-letter', row);
      this.open = false;
    },
    clearLetter() {
      this.$emit('select-letter', {
        letterFileExternalId: null,
        letterFile: null,
      });
    },
    stopTheEvent: (event: Event) => event.stopPropagation(),
    message() {
      const scope = this;
      return {
        set: (messages: string[]) => {
          scope.messages.push(...messages);
          return this;
        },
        add: (message: string) => {
          scope.errors.push(message);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
  },
  created() {
    EventBus.$on('sf-letter-upload-success', (success: boolean) => {
      if (success) {
        this.$getRemoteComponent('SfLeterSelectUploadGrid', this)?.loadData();
      }
    });
  },
  watch: {
    content: {
      handler(n) {
        this.$emit('input', this.content);
      },
    },
    '$props.value'(v) {
      this.content = v;
    },
  },
  props: {
    orgId: {
      type: String,
      default: () => {
        return '';
      },
    },
    value: {
      type: String || null,
      default: () => {
        return '';
      },
    },
    gridConditions: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
});
