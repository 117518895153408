










































































































































































































import { Vue } from 'vue-property-decorator';
import { FreeLookI } from '@/interfaces/free-look.interface';
import SfEnumField from '@/components/SFComponents/SfEnumField.vue';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import ValidationService from '@/services/Validations.service';
import axios from '@/services/api/Api.service';
import UserService from '@/services/api/User.service';
import { AxiosResponse } from 'axios';
import SfCustomDialogLayout from '@/components/SFComponents/SfCustomDialogLayout.vue';
const axiosInstance = new axios().getClient();

const saveUrl = '/uiapi/config-free-look';

export default Vue.extend({
  data() {
    return {
      ValidationService: ValidationService,
      formValid: true,
      metadataEnumsMap: new Map(),
      service: MetadataService,
      content: this.value as FreeLookI,
      errors: [] as string[],
      messages: [] as string[],
      progress: false as boolean,
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
    };
  },
  components: { SfEnumField, SfCustomDialogLayout },
  name: 'LenderServiceConfigurationFreeLookEditForm',
  watch: {
    '$props.value'(v) {
      this.content = v;
    },
  },
  async created() {
    await this.refreshRecordData();
    this.error().clear();
    this.message().clear();
    this.metadataEnumsMap = await this.service.loadData(MetadataType.CONFIG_FREE_LOOK);
    this.validateForm();
  },
  methods: {
    input() {
      this.$emit('input', this.content);
    },
    async refreshRecordData() {
      if (this.content.id) {
        this.content = (await MetadataService.getRecord(
          { orgId: this.content.orgId, id: this.content.id },
          'All',
          '/uiapi/config-free-look/search',
        )) as FreeLookI;
        this.input();
      }
    },
    effectiveDateTypeInput() {
      this.content.effectiveDate = this.content.effectiveDateType === 'AllTime' ? null : this.content.effectiveDate;
    },
    validateForm() {
      setTimeout(() => {
        this.$getRemoteComponent('FeeFormComponent', this).validate();
      }, 250);
    },
    setProgress(val: boolean): boolean {
      this.progress = val;
      this.$forceUpdate();
      return this.progress;
    },
    message() {
      const scope = this;
      return {
        set: (messages: string[]) => {
          scope.messages.push(...messages);
          return this;
        },
        add: (messages: string) => {
          scope.messages.push(messages);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    async submitForm() {
      await this.saveData();
      if (this.errors.length > 0) {
        return Promise.reject(false);
      } else {
        setTimeout(() => {
          return Promise.resolve(true);
        }, 1500);
      }
    },
    async applyToPastRecords() {
      await this.saveData();
      if (this.errors.length > 0) {
        return Promise.reject(false);
      } else {
        setTimeout(() => {
          return Promise.resolve(true);
        }, 1500);
      }
    },
    saveData() {
      if (!this.formValid) {
        this.error().clear();
        this.message().clear();
        this.error().add('Form data is not valid');
        return Promise.reject(false);
      }
      let url = saveUrl;
      let insert = true;

      const requestData = JSON.parse(JSON.stringify(this.content));

      if (requestData && requestData.id && requestData.id > 0) {
        url += '/' + requestData.id;
        insert = false;
      }

      if (requestData && requestData.effectiveDateType !== 'AllTime') {
        requestData.startDate = requestData.effectiveDate;
      }

      if (insert) {
        delete requestData.externalId;
        delete requestData.createdDate;
        delete requestData.createdById;
        delete requestData.updatedById;
        delete requestData.dateTimeUpdated;
      } else {
        delete requestData.externalId;
        delete requestData.createdDate;
        delete requestData.createdById;
        delete requestData.updatedById;
      }

      this.setProgress(true);
      this.error().clear();
      this.message().clear();
      return axiosInstance
        .post<FreeLookI, AxiosResponse<{ success: boolean; messages: string[]; errors: string[]; data: FreeLookI }>>(
          url,
          requestData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(result => {
          this.setProgress(false);
          if (result.data.success) {
            this.content = result.data.data;
            this.$emit('input', this.content);
            this.$emit(insert ? 'row-added' : 'row-updated');
            if (result.data.messages && result.data.messages.length > 0) this.message().set(result.data.messages);
          } else {
            // Setting errors will show them on form
            this.error().set(result.data.errors);
          }
        })
        .catch((error: string) => {
          this.setProgress(false);
          this.error().set([error]);
        });
    },
  },
  props: ['value', 'id', 'orgId'],
});
