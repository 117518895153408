var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "complience-tracker",
      staticStyle: { position: "relative" }
    },
    [
      _c(
        "v-overlay",
        {
          attrs: { absolute: "", light: "", opacity: ".1" },
          model: {
            value: _vm.$data.processing,
            callback: function($$v) {
              _vm.$set(_vm.$data, "processing", $$v)
            },
            expression: "$data.processing"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                border: "1px solid #bbb",
                padding: "10px",
                "border-radius": "5px",
                background: "#2b81d6",
                opacity: ".9"
              }
            },
            [
              _c("v-progress-circular", {
                staticStyle: { "margin-right": "10px" },
                attrs: { indeterminate: "", small: "", color: "white" }
              }),
              _vm._v("Saving Dates ... ")
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "complience-tracker-main-frame" }, [
        _c(
          "div",
          {
            staticClass: "title-frame",
            class: _vm.getComplianceTrackerStatus()
          },
          [
            _c("div", { staticClass: "name" }, [_vm._v("Compliance Tracker")]),
            _c("div", {
              staticClass: "status",
              domProps: { innerHTML: _vm._s(_vm.getComplianceTrackerStatus()) }
            })
          ]
        ),
        _c("div", { staticClass: "section-block" }, [
          _c("div", { staticClass: "section-block-frame" }, [
            _c("div", { staticClass: "data-section" }, [
              _c("div", [_vm._v("Days to Refund Rule")]),
              _c("div", {
                staticClass: "data-section-value",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.convertValue(_vm.record().daysToRefundRule)
                  )
                }
              })
            ]),
            _c("div", { staticClass: "data-section mt-2" }, [
              _c("div", { staticClass: "data-section-title" }, [
                _vm._v("Days Since Cancellation")
              ]),
              _c("div", {
                staticClass: "data-section-value",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.convertValue(_vm.record().daysSinceCancellation)
                  )
                }
              })
            ])
          ]),
          _c("div", { staticClass: "section-block-frame" }, [
            _c("div", { staticClass: "data-section" }, [
              _c("div", { staticClass: "data-section-title" }, [
                _vm._v("Days to Refund")
              ]),
              _c("div", { staticClass: "data-section-value" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.convertValue(_vm.record().daysToRefund)
                    )
                  }
                })
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "data-section mt-2",
                class:
                  _vm.convertValue(_vm.record().remainingDays) !== "---"
                    ? _vm.record().remainingDays > -1
                      ? "valid"
                      : "negative"
                    : ""
              },
              [
                _c("div", { staticClass: "data-section-title" }, [
                  _vm._v("Remaining Days")
                ]),
                _c("div", { staticClass: "data-section-value" }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.convertValue(_vm.record().remainingDays)
                      )
                    }
                  })
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "section-block" }, [
          _c("div", { staticClass: "section-block-frame" }, [
            _c("div", { staticClass: "data-section" }, [
              _c("div", { staticClass: "data-section-title" }, [
                _vm._v("Date Refunded")
              ]),
              _c("div", { staticClass: "data-section-dates" }, [
                _c(
                  "div",
                  { staticClass: "data-section-dates-frame" },
                  [
                    _c(
                      "div",
                      { staticClass: "date-selection-custom" },
                      [
                        !_vm.record().refundedDate &&
                        !_vm.$data.refundedOverrideDateSwitch
                          ? _c("div", {
                              staticClass: "data-section-value",
                              staticStyle: {
                                height: "48px",
                                "padding-top": "10px"
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.convertValue(_vm.record().refundedDate)
                                )
                              }
                            })
                          : _vm._e(),
                        !_vm.$data.refundedOverrideDateSwitch &&
                        _vm.record().refundedDate
                          ? _c("custom-date-picker", {
                              attrs: { disabled: "", disableclear: "" },
                              model: {
                                value: _vm.productRecordSync.refundedDate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.productRecordSync,
                                    "refundedDate",
                                    $$v
                                  )
                                },
                                expression: "productRecordSync.refundedDate"
                              }
                            })
                          : _vm._e(),
                        _vm.$data.refundedOverrideDateSwitch
                          ? _c("custom-date-picker", {
                              attrs: { disableclear: "" },
                              model: {
                                value:
                                  _vm.productRecordSync.refundedOverrideDate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.productRecordSync,
                                    "refundedOverrideDate",
                                    $$v
                                  )
                                },
                                expression:
                                  "productRecordSync.refundedOverrideDate"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "icon-lock",
                        attrs: {
                          small: "",
                          dense: "",
                          title: _vm.$data.refundedOverrideDateSwitch
                            ? "Disable Override"
                            : "Enable Override"
                        },
                        on: {
                          click: function($event) {
                            return _vm.dateRefunded()
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$data.refundedOverrideDateSwitch
                              ? "mdi-lock-open-variant"
                              : "mdi-lock"
                          )
                        )
                      ]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "icon-save",
                        class: _vm.getSaveButtonClass("refundedOverrideDate"),
                        attrs: { small: "", dense: "", title: "Save Changes" },
                        on: {
                          click: function($event) {
                            return _vm.save()
                          }
                        }
                      },
                      [_vm._v("mdi-content-save")]
                    )
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "data-section" }, [
              _c("div", { staticClass: "data-section-title" }, [
                _vm._v("Date Payment Received")
              ]),
              _c("div", { staticClass: "data-section-dates" }, [
                _c(
                  "div",
                  { staticClass: "data-section-dates-frame" },
                  [
                    _c(
                      "div",
                      { staticClass: "date-selection-custom" },
                      [
                        !_vm.record().paymentReceivedDate &&
                        !_vm.$data.paymentReceivedOverrideDateSwitch
                          ? _c("div", {
                              staticClass: "data-section-value",
                              staticStyle: {
                                height: "48px",
                                "padding-top": "10px"
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.convertValue(
                                    _vm.record().paymentReceivedDate
                                  )
                                )
                              }
                            })
                          : _vm._e(),
                        !_vm.$data.paymentReceivedOverrideDateSwitch &&
                        _vm.record().paymentReceivedDate
                          ? _c("custom-date-picker", {
                              attrs: { disabled: "", disableclear: "" },
                              model: {
                                value:
                                  _vm.productRecordSync.paymentReceivedDate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.productRecordSync,
                                    "paymentReceivedDate",
                                    $$v
                                  )
                                },
                                expression:
                                  "productRecordSync.paymentReceivedDate"
                              }
                            })
                          : _vm._e(),
                        _vm.$data.paymentReceivedOverrideDateSwitch
                          ? _c("custom-date-picker", {
                              attrs: { disableclear: "" },
                              model: {
                                value:
                                  _vm.productRecordSync
                                    .paymentReceivedOverrideDate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.productRecordSync,
                                    "paymentReceivedOverrideDate",
                                    $$v
                                  )
                                },
                                expression:
                                  "productRecordSync.paymentReceivedOverrideDate"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "icon-lock",
                        attrs: {
                          small: "",
                          dense: "",
                          title: _vm.$data.paymentReceivedOverrideDateSwitch
                            ? "Disable Override"
                            : "Enable Override"
                        },
                        on: {
                          click: function($event) {
                            return _vm.datePaymentReceivedOverride()
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$data.paymentReceivedOverrideDateSwitch
                              ? "mdi-lock-open-variant"
                              : "mdi-lock"
                          )
                        )
                      ]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "icon-save",
                        class: _vm.getSaveButtonClass(
                          "paymentReceivedOverrideDate"
                        ),
                        attrs: { small: "", dense: "", title: "Save Changes" },
                        on: {
                          click: function($event) {
                            return _vm.save()
                          }
                        }
                      },
                      [_vm._v("mdi-content-save")]
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "section-block-frame" }, [
            _c("div", { staticClass: "data-section" }, [
              _c("div", { staticClass: "data-section-title" }, [
                _vm._v("Total Days - Refund")
              ]),
              _c("div", {
                staticClass: "data-section-value",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.convertValue(_vm.record().totalDaysToRefund)
                  )
                }
              })
            ]),
            _c("div", { staticClass: "data-section" }, [
              _c("div", { staticClass: "data-section-title" }, [
                _vm._v("Total Days - Payment")
              ]),
              _c("div", {
                staticClass: "data-section-value",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.convertValue(_vm.record().totalDaysToReceivedPayment)
                  )
                }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "messages-section" }, [
          _c(
            "div",
            {
              staticClass: "message",
              class: _vm.getMessageClass(_vm.record().feeComplianceStatus)
            },
            [
              _c("div", { staticClass: "message-title" }, [
                _vm._v(" Fee Compliance Status "),
                _c("div", {
                  staticClass: "message-status",
                  domProps: {
                    innerHTML: _vm._s(_vm.record().feeComplianceStatus)
                  }
                })
              ]),
              _c("div", {
                staticClass: "message-text",
                domProps: {
                  innerHTML: _vm._s(_vm.record().feeComplianceDetails)
                }
              })
            ]
          ),
          _c(
            "div",
            {
              staticClass: "message",
              class: _vm.getMessageClass(_vm.record().freeLookStatus)
            },
            [
              _c("div", { staticClass: "message-title" }, [
                _vm._v(" Free Look Status "),
                _c("div", {
                  staticClass: "message-status",
                  domProps: { innerHTML: _vm._s(_vm.record().freeLookStatus) }
                })
              ]),
              _c("div", {
                staticClass: "message-text",
                domProps: { innerHTML: _vm._s(_vm.record().freeLookDetails) }
              })
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }