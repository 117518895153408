













import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import ValidationService from '@/services/Validations.service';

@Component({
  components: {},
})
export default class SfCustomParamBoolean extends Vue {
  @PropSync('fieldValue') fieldValueSync!: any;
  @PropSync('fieldOperator') fieldOperatorSync!: any;
  @PropSync('fieldDefinition') fieldDefinitionSync!: any;
  @Prop([String]) readonly orgId: boolean | undefined;
  @Prop([Boolean]) readonly readonly: boolean | undefined;
  @Prop([Boolean]) readonly disabled: boolean | undefined;
  @Prop([Boolean]) readonly hideDetails: boolean | undefined;
  services: any;
  data() {
    return {
      enums: null as null | any[],
      ready: false,
      services: {
        validationService: ValidationService,
      },
    };
  }
  async mounted() {
    this.$data.ready = true;
  }
}
