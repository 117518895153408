var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { flex: "1", position: "relative" } }, [
    _vm.fieldOperatorSync !== "in" &&
    _vm.fieldOperatorSync !== "not in" &&
    _vm.fieldValueSync.type === "String"
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c("v-text-field", {
              staticStyle: { width: "100%" },
              attrs: {
                outlined: "",
                dense: "",
                "hide-details": _vm.hideDetails,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
                rules: [
                  _vm.services.validationService.getValidator(
                    "required",
                    _vm.fieldDefinitionSync.fieldLabel
                  )
                ]
              },
              model: {
                value: _vm.fieldValueSync.value,
                callback: function($$v) {
                  _vm.$set(_vm.fieldValueSync, "value", $$v)
                },
                expression: "fieldValueSync.value"
              }
            })
          ],
          1
        )
      : _vm._e(),
    (_vm.fieldOperatorSync === "in" || _vm.fieldOperatorSync === "not in") &&
    _vm.fieldDefinitionSync.lookupData
      ? _c(
          "div",
          [
            _c("sf-lookup-field", {
              staticClass: "sfl-fix-label",
              attrs: {
                dense: "",
                "hide-details": _vm.hideDetails,
                readonly: _vm.readonly,
                "field-data-type": "OBJECT_ARRAY",
                "key-field": "id",
                "search-field":
                  _vm.fieldDefinitionSync.lookupData.searchFieldName,
                "search-url": _vm.fieldDefinitionSync.lookupData.apiDataUrl,
                "meta-data-url":
                  _vm.fieldDefinitionSync.lookupData.apiMetadataUrl,
                orgId: _vm.orgId,
                fixedConditions: _vm.fieldDefinitionSync.fixedConditions,
                searchFieldPrefix: _vm.fieldDefinitionSync.searchFieldPrefix,
                externalSearchConditions:
                  _vm.fieldDefinitionSync.externalSearchConditions,
                rules: [
                  _vm.services.validationService.getValidator(
                    "required",
                    _vm.fieldDefinitionSync.fieldLabel
                  )
                ]
              },
              model: {
                value: _vm.fieldValueSync.value,
                callback: function($$v) {
                  _vm.$set(_vm.fieldValueSync, "value", $$v)
                },
                expression: "fieldValueSync.value"
              }
            })
          ],
          1
        )
      : _vm._e(),
    (_vm.fieldOperatorSync === "in" || _vm.fieldOperatorSync === "not in") &&
    _vm.fieldValueSync.type === "Array" &&
    !_vm.fieldDefinitionSync.lookupData
      ? _c(
          "div",
          { staticStyle: { position: "relative" } },
          [
            _c(
              "v-menu",
              {
                attrs: {
                  "offset-y": "",
                  maxHeight: "430px",
                  minWidth: "237px",
                  maxWidth: "430px",
                  rounded: "4"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    "hide-details": _vm.hideDetails,
                                    readonly: _vm.readonly,
                                    disabled: _vm.disabled,
                                    rules: [
                                      _vm.services.validationService.getValidator(
                                        "required",
                                        _vm.fieldDefinitionSync.fieldLabel
                                      )
                                    ]
                                  },
                                  on: {
                                    keydown: function($event) {
                                      return _vm.updateOptions()
                                    },
                                    keyup: function($event) {
                                      return _vm.updateOptions()
                                    }
                                  },
                                  model: {
                                    value: _vm.$data.in,
                                    callback: function($$v) {
                                      _vm.$set(_vm.$data, "in", $$v)
                                    },
                                    expression: "$data.in"
                                  }
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { slot: "append", color: "red" },
                                  slot: "append"
                                },
                                [_vm._v(" mdi-zoom ")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  918569479
                ),
                model: {
                  value: _vm.menu,
                  callback: function($$v) {
                    _vm.menu = $$v
                  },
                  expression: "menu"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      border: "1px solid #ddd",
                      padding: "5px",
                      margin: "1px",
                      "background-color": "white"
                    }
                  },
                  [
                    _vm._l(_vm.fieldValueSync.value, function(
                      option,
                      optionIndex
                    ) {
                      return _c(
                        "v-row",
                        { key: optionIndex, staticClass: "ma-0 pa-0" },
                        [
                          _c("v-col", { staticClass: "ma-0 pa-0" }, [
                            _vm._v(_vm._s(option))
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "ma-0 pa-0", attrs: { cols: "1" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  style: {
                                    visibility: option ? "visible" : "hidden"
                                  },
                                  attrs: {
                                    small: "",
                                    title: option ? "Remove Option" : null
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeOption(
                                        _vm.fieldValueSync.value,
                                        optionIndex
                                      )
                                    }
                                  }
                                },
                                [_vm._v("mdi-minus")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    _vm.fieldValueSync.length === 0
                      ? _c("div", [_vm._v("Empty List")])
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.debug
      ? _c(
          "div",
          {
            staticStyle: {
              position: "absolute",
              bottom: "-30px",
              "min-width": "400px",
              "border-bottom": "1px solid gold"
            }
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.fieldValueSync) +
                " | " +
                _vm._s(_vm.fieldOperatorSync) +
                " "
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }