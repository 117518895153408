var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-scroll-layout",
        { attrs: { pageName: "Data Upload", pageIcon: "mdi-table-arrow-up" } },
        [
          _c(
            "div",
            { attrs: { slot: "toolbar" }, slot: "toolbar" },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pageView === "DETAILS",
                      expression: "pageView === 'DETAILS'"
                    }
                  ],
                  attrs: { color: "primary", elevation: "2", medium: "" },
                  on: {
                    click: function($event) {
                      return _vm.goBackToList()
                    }
                  }
                },
                [_c("v-icon", [_vm._v(" mdi-arrow-left")])],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "mb-3 mr-2",
              attrs: { slot: "buttons" },
              slot: "buttons"
            },
            [
              _vm.buttonRenderCondition()
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: {
                        disabled: _vm.processing,
                        color: "primary",
                        elevation: "2",
                        medium: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendApproveRequest(true)
                        }
                      }
                    },
                    [_vm._v(" Approve ")]
                  )
                : _vm._e(),
              _vm.buttonRenderCondition()
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: {
                        disabled: _vm.processing,
                        color: "primary",
                        elevation: "2",
                        medium: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendApproveRequest(false)
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pageView === "DETAILS",
                      expression: "pageView === 'DETAILS'"
                    }
                  ],
                  staticClass: "mr-1",
                  attrs: { left: "", elevation: "2" },
                  on: {
                    click: function($event) {
                      return _vm.downloadFile()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-download")
                  ]),
                  _vm._v(" Download File ")
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pageView === "DETAILS",
                      expression: "pageView === 'DETAILS'"
                    }
                  ],
                  staticClass: "mr-1",
                  attrs: { elevation: "2" },
                  on: {
                    click: function($event) {
                      return _vm.openFileUploadDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-upload")]),
                  _vm._v(" Upload File ")
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    persistent: "",
                    "max-width": "500px",
                    scrollable: "",
                    eager: ""
                  },
                  model: {
                    value: _vm.FileUploadDialogOpen,
                    callback: function($$v) {
                      _vm.FileUploadDialogOpen = $$v
                    },
                    expression: "FileUploadDialogOpen"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "dialog-card-header" },
                        [
                          _c("span", { staticClass: "headline" }, [
                            _vm._v("Upload File")
                          ])
                        ]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            cursor: "pointer"
                          },
                          attrs: { center: "", large: "", color: "white" },
                          on: {
                            click: function($event) {
                              _vm.FileUploadDialogOpen = false
                            }
                          }
                        },
                        [_vm._v(" mdi-close ")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        { staticStyle: { height: "95%" } },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "s3-file-upload",
                                        _vm._b(
                                          {
                                            on: {
                                              "file-saved-success":
                                                _vm.fileUploadCallback
                                            }
                                          },
                                          "s3-file-upload",
                                          _vm.s3FileUpload,
                                          false
                                        )
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", elevation: "2" },
                              on: {
                                click: function($event) {
                                  _vm.FileUploadDialogOpen = false
                                }
                              }
                            },
                            [_vm._v(" Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pageView === "LIST",
                    expression: "pageView === 'LIST'"
                  }
                ]
              },
              [
                _c(
                  "data-grid",
                  _vm._b(
                    {
                      attrs: {
                        actions: _vm.actions,
                        itemsPerPage: [50, 100, 200, 300, 400, 500],
                        itemsPerPageInitial: 100,
                        buttonActions: _vm.buttonActions,
                        "fixed-conditions":
                          typeof _vm.lender === "string" && _vm.lender !== ""
                            ? [
                                {
                                  fieldName: "orgId",
                                  value: _vm.lender,
                                  fieldType: "string",
                                  conditionComparatorOption: "equal"
                                }
                              ]
                            : [],
                        "fixed-conditions-prevent-call": false
                      }
                    },
                    "data-grid",
                    _vm.customDataGrid,
                    false
                  )
                )
              ],
              1
            ),
            _vm.pageView === "DETAILS"
              ? _c(
                  "div",
                  [
                    _c(
                      "v-snackbar",
                      {
                        attrs: {
                          centered: "",
                          color: "error",
                          text: "",
                          timeout: 2000
                        },
                        model: {
                          value: _vm.snackBarError,
                          callback: function($$v) {
                            _vm.snackBarError = $$v
                          },
                          expression: "snackBarError"
                        }
                      },
                      [
                        _c("v-icon", { attrs: { color: "error" } }, [
                          _vm._v(" mdi-alert-circle-outline ")
                        ]),
                        _vm._v(" There was an error downloading the file ")
                      ],
                      1
                    ),
                    _c(
                      "v-container",
                      { attrs: { fluid: "", "fill-height": "" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("v-progress-linear", {
                                  directives: [
                                    {
                                      name: "visible",
                                      rawName: "v-visible",
                                      value: _vm.processing,
                                      expression: "processing"
                                    }
                                  ],
                                  attrs: {
                                    indeterminate: "",
                                    query: true,
                                    color: "blue"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.length > 0,
                                expression: "errors.length > 0"
                              }
                            ]
                          },
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-alert",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          "line-height": "15px"
                                        },
                                        attrs: {
                                          type: "error",
                                          border: "left",
                                          text: "",
                                          outlined: "",
                                          dense: "",
                                          autocomplete: "none"
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _c("b", [_vm._v("Errors:")])
                                        ]),
                                        _vm._l(_vm.errors, function(
                                          error,
                                          errorIndex
                                        ) {
                                          return _c(
                                            "div",
                                            { key: errorIndex },
                                            [_vm._v("* " + _vm._s(error))]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.messages.length > 0,
                                expression: "messages.length > 0"
                              }
                            ]
                          },
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-alert",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          "line-height": "15px"
                                        },
                                        attrs: {
                                          type: "info",
                                          border: "left",
                                          text: "",
                                          outlined: "",
                                          dense: "",
                                          autocomplete: "none"
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _c("b", [_vm._v("Mesagess:")])
                                        ]),
                                        _vm._l(_vm.messages, function(
                                          message,
                                          messageIndex
                                        ) {
                                          return _c(
                                            "div",
                                            { key: messageIndex },
                                            [_vm._v("* " + _vm._s(message))]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "v-container",
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "25px"
                                        }
                                      },
                                      [
                                        _c("v-card-subtitle", [
                                          _vm._v("Upload Data Details")
                                        ]),
                                        _c(
                                          "v-container",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label:
                                                              "Date/Time Processed",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailRecord
                                                                .dateTimeProcessed,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.detailRecord,
                                                                "dateTimeProcessed",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRecord.dateTimeProcessed"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label: "File Type",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailRecord
                                                                .fileType,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.detailRecord,
                                                                "fileType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRecord.fileType"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label: "File Name",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailRecord
                                                                .fileName,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.detailRecord,
                                                                "fileName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRecord.fileName"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label: "Method",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailRecord
                                                                .method,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.detailRecord,
                                                                "method",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRecord.method"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label:
                                                              "Lender Name",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailRecord
                                                                .orgPreference
                                                                .lenderName,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.detailRecord
                                                                  .orgPreference,
                                                                "lenderName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRecord.orgPreference.lenderName"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label: "Who",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailRecord
                                                                .createdByName,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.detailRecord,
                                                                "createdByName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRecord.createdByName"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label: "Status",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailRecord
                                                                .status,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.detailRecord,
                                                                "status",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRecord.status"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label:
                                                              "Number of Records in the File",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailRecord
                                                                .recordsTotal,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.detailRecord,
                                                                "recordsTotal",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRecord.recordsTotal"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label:
                                                              "Number of Records Successfully Loaded",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailRecord
                                                                .recordsSuccessful,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.detailRecord,
                                                                "recordsSuccessful",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRecord.recordsSuccessful"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            color: "error",
                                                            error:
                                                              _vm.detailRecord !==
                                                              null
                                                                ? _vm.detailRecord >
                                                                  0
                                                                  ? true
                                                                  : false
                                                                : false,
                                                            dense: "",
                                                            "hide-details": "",
                                                            label:
                                                              "Number of Records Failed to Load",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailRecord
                                                                .recordsError,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.detailRecord,
                                                                "recordsError",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRecord.recordsError"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label:
                                                              "Approved By",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.approvedBy,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.approvedBy = $$v
                                                            },
                                                            expression:
                                                              "approvedBy"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label:
                                                              "Approved At",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailRecord
                                                                .approvedAt,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.detailRecord,
                                                                "approvedAt",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRecord.approvedAt"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "v-container",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("div", [_vm._v("Messages:")]),
                                            _c(
                                              "data-grid",
                                              _vm._b(
                                                { attrs: { viewAll: true } },
                                                "data-grid",
                                                _vm.errorGrid,
                                                false
                                              )
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }