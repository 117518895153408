var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { elevation: "0" } },
                [
                  _c(
                    "v-card-text",
                    {
                      directives: [
                        {
                          name: "resize",
                          rawName: "v-resize",
                          value: _vm.onResize,
                          expression: "onResize"
                        }
                      ],
                      staticStyle: { height: "95%" }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "lenderForm",
                          attrs: {
                            "lazy-validation": "",
                            disabled: _vm.overlay
                          },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "v-tabs",
                            {
                              model: {
                                value: _vm.activeTab,
                                callback: function($$v) {
                                  _vm.activeTab = $$v
                                },
                                expression: "activeTab"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                { attrs: { href: "#lenderPreferences" } },
                                [_vm._v("Lender Preferences")]
                              ),
                              _c(
                                "v-tab-item",
                                { attrs: { value: "lenderPreferences" } },
                                [
                                  _c("lender-preferences-tab", {
                                    ref: "preferencesTab",
                                    attrs: {
                                      passEditedRecord: _vm.passEditedRecord
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-tab",
                                { attrs: { href: "#apiConfiguration" } },
                                [_vm._v("API Partner Configuration")]
                              ),
                              _c(
                                "v-tab-item",
                                { attrs: { value: "apiConfiguration" } },
                                [
                                  _c("api-configuration-tab", {
                                    attrs: {
                                      passEditedRecord: _vm.passEditedRecord,
                                      disabled: !this.editedRecord.active
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-tab",
                                { attrs: { href: "#productPreferences" } },
                                [_vm._v("Product Preferences")]
                              ),
                              _c(
                                "v-tab-item",
                                { attrs: { value: "productPreferences" } },
                                [
                                  _c("product-preferences-tab", {
                                    attrs: {
                                      passEditedRecord: _vm.passEditedRecord,
                                      disabled: !this.editedRecord.active
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-tab", { attrs: { href: "#logo" } }, [
                                _vm._v("Logo")
                              ]),
                              _c(
                                "v-tab-item",
                                { attrs: { value: "logo" } },
                                [
                                  _c("logo-tab", {
                                    attrs: {
                                      passEditedRecord: _vm.passEditedRecord,
                                      disabled: !this.editedRecord.active
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-tab",
                                { attrs: { href: "#customFields" } },
                                [_vm._v("Custom Fields")]
                              ),
                              _c(
                                "v-tab-item",
                                { attrs: { value: "customFields" } },
                                [
                                  _vm.activeTab === "customFields"
                                    ? _c("lender-custom-fields-tab", {
                                        attrs: {
                                          passEditedRecord:
                                            _vm.passEditedRecord,
                                          disabled: !this.editedRecord.active
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-tab",
                                { attrs: { href: "#configuration" } },
                                [_vm._v("Rules Management")]
                              ),
                              _c(
                                "v-tab-item",
                                {
                                  staticClass: "pa-0 ma-0",
                                  attrs: { value: "configuration" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      ref: "resizableDiv",
                                      staticClass: "pa-0"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "row",
                                            position: "relative",
                                            "padding-bottom": "5px"
                                          },
                                          style: { height: _vm.height + "px" }
                                        },
                                        [
                                          _c("lender-service-configuration", {
                                            attrs: {
                                              orgId: _vm.editedRecord.orgId,
                                              "footer-height": 70,
                                              "show-menu": "true"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }