var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page-layout", [
    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize"
            }
          ],
          ref: "resizableDiv",
          staticClass: "pa-2",
          staticStyle: { background: "#eeeeee" }
        },
        [
          _c(
            "v-data-table",
            _vm._b(
              {
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.tableConfiguration.headers, function(header) {
                      return {
                        key: "item." + header.value,
                        fn: function(ref) {
                          var item = ref.item
                          var value = ref.value
                          return [
                            header.type === "text"
                              ? _c("span", { key: header.value }, [
                                  _vm._v(_vm._s(value))
                                ])
                              : _vm._e(),
                            header.type === "boolean"
                              ? _c(
                                  "span",
                                  { key: header.value },
                                  [
                                    _c("v-checkbox", {
                                      staticClass: "ma-0",
                                      attrs: {
                                        "input-value": value,
                                        readonly: "",
                                        disabled: "",
                                        "hide-details": ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    })
                  ],
                  null,
                  true
                )
              },
              "v-data-table",
              _vm.tableConfiguration,
              false
            )
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }