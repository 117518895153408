





































































































































































































































































import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  name: 'SfEnumField',
  components: {},
  data() {
    return {
      selection: {
        left: null as null | string,
        right: null as null | string,
        leftMulti: [] as string[],
        rightMulti: [] as string[],
        leftAll: false as boolean,
        rightAll: false as boolean,
      },
      ids: {
        leftSelectAll: this.$getRandomId(),
        rightSelectAll: this.$getRandomId(),
      },
      menu: false as boolean,
      content: this.value || [],
      search: '' as string,
      searchDelay: null as number | null,
      errors: [] as string[],
      dataTable: {},
    };
  },
  methods: {
    moveAllOptionsToSelected() {
      this.content = [];
      this.content = this.$getClone(
        this.getEnumFieldOptionsFiltered.filter((o: string) => !this.content.find((x: string) => x === o)),
      );
      this.selection.rightMulti.length = 0;
      this.selection.right = null;
      this.input();
      this.$forceUpdate();
    },
    moveAllToSelectedToOptions() {
      this.content = [];
      this.selection.rightMulti.length = 0;
      this.selection.right = null;
      this.input();
      this.$forceUpdate();
    },
    selectAllAvailableOptions() {
      if (this.selection.leftAll) {
        const allLeftItems = this.getEnumFieldOptionsFiltered.filter(
          (o: string) => !this.content.find((x: string) => x === o),
        );
        this.selection.leftMulti.length = 0;
        this.selection.leftMulti = this.$getClone(allLeftItems);
      } else {
        this.selection.leftMulti.length = 0;
      }
    },
    selectAllSelectedOptions() {
      if (this.selection.rightAll) {
        this.selection.rightMulti.length = 0;
        this.selection.rightMulti = this.$getClone(this.content);
      } else {
        this.selection.rightMulti.length = 0;
      }
    },
    clearSelection() {
      this.content = [];
      this.selection.rightMulti.length = 0;
      this.selection.leftMulti.length = 0;
      this.input();
      this.$forceUpdate();
    },
    addSingleOption() {
      if (!this.content.includes(this.selection.left)) {
        this.content.push(this.selection.left);
      }
      this.input();
    },
    removeSingleOption() {
      if (this.selection.right && this.content.includes(this.selection.right)) {
        this.content.splice(
          this.content.findIndex((o: string) => o === this.selection.right),
          1,
        );
      }
      console.log(this.selection.right);
    },
    addSelectedOption() {
      if (this.selection.leftMulti.length > 0) {
        this.content.push(...this.$getClone(this.selection.leftMulti));
        this.selection.leftMulti.length = 0;
        this.selection.leftAll = false;
        this.input();
      }
    },
    removeSelectedOption() {
      if (this.selection.rightMulti.length > 0) {
        this.content = this.content.filter((item: string) => {
          return !this.selection.rightMulti.includes(item);
        });
        this.selection.rightMulti.length = 0;
        this.selection.rightAll = false;
        this.input();
      }
    },
    stopTheEvent: (event: Event) => event.stopPropagation(),
    input() {
      this.$emit('input', this.content);
    },
  },
  computed: {
    getDisplayContent(): string {
      return (this.content || []).join(', ');
    },
    getEnumFieldOptionsFiltered() {
      // @ts-ignore: Unreachable code error
      return this.items
        .filter((option: string) => {
          if (this.search && this.search !== null && this.search !== '') {
            if (this.itemsObjects) {
              const found = this.itemsObjects.find((c: { value: string; text: string }) => c.value === option);
              return found.text.toLowerCase().includes(this.search.toLowerCase());
            } else {
              return option.toLowerCase().includes(this.search.toLowerCase());
            }
          } else {
            return true;
          }
        })
        .sort(function compare(a: any, b: any) {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });
    },
  },
  watch: {
    search(n) {
      if (n && n !== '') {
        this.selection.rightMulti.length = 0;
        this.selection.leftMulti.length = 0;
        this.selection.leftAll = false;
        this.selection.rightAll = false;
      }
    },
    menu(n) {
      if (n) {
        this.selection.left = null;
        this.selection.leftMulti.length = 0;
        this.selection.right = null;
        this.selection.rightMulti.length = 0;
        this.selection.leftAll = false;
        this.selection.rightAll = false;
      }
    },
    '$props.value'(v) {
      this.content = v;
    },
  },
  filters: {
    translateOption(option: string, items: { value: string; text: string }[]) {
      if (items) {
        // @ts-ignore: Unreachable code error
        return items.find(
          (o: { value: string; text: any }): { value: string; text: any } => o.value === option && o.text,
        )?.text;
      } else {
        return option;
      }
    },
  },
  props: [
    'value',
    'keyField',
    'searchField',
    'maxHeight',
    'minHeight',
    'minWidth',
    'maxWidth',
    'fieldDataType' as string,
    'items',
    'itemsObjects',
    'readonly',
  ],
});
