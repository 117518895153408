





























































































































































































































































import { Account } from '@/interfaces';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import IDPProductForm from '@/components/IDP/IDPProductForm.vue';
import IDPAccountForm from '@/components/IDP/IDPAccountForm.vue';
import ApiService from '@/services/api/Api.service';
import CustomDialog from '@/components/CustomDialog.vue';
import InformedApiLogDetails from '@/components/InformedApiLogDetails.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import eventBus from '@/events/EventBus';

const axiosInstance = new ApiService().getClient();
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/informed-account/';
const dataEndpointUrlDealer = '/uiapi/dealer/search';
const apiDownloadLogUrl = '/uiapi/informed-api-logs/download-log/{id}';

@Component({
  components: {
    IDPProductForm,
    IDPAccountForm,
    CustomDialog,
    InformedApiLogDetails,
    ConfirmationDialog,
  },
})
export default class IDPAccountDetails extends Vue {
  @Prop([String, Boolean]) readonly id: string | undefined;
  @Watch('previewDocumentPathSelected')
  previewDocumentPathSelectedFn(val: string, oldVal: string) {}
  data() {
    return {
      tempErrors: [],
      accountData: null as null | any,
      control: {
        selectedForm: 'ACCOUNT',
      },
      documentLoading: true,
      previewDocumentPathSelected: null,
      detailRecord: false,
      customDialogDetails: {
        ref: 'customDialogDetails',
      },
      accountDataSnapshot: null,
      openDialog: true,
      openDialogExit: true,
      sendToRefundControl: false,
      confirmationDialog: {
        processing: false,
        ref: 'ConfirmationDialog',
        title: 'Save Account Data' as string,
        message: '' as string,
        errors: [] as string[],
        messages: [] as string[],
      },
      confirmationDialogExit: {
        processing: false,
        ref: 'ConfirmationDialogExit',
        title: 'Save Account Data' as string,
        message: '' as string,
        errors: [] as string[],
        messages: [] as string[],
      },
      processing: false,
    };
  }
  async mounted() {
    this.$data.accountData = await axiosInstance.get(dataEndpointUrl + this.id).then(r => r.data);
    this.$data.accountData.informedProducts.map((ip: any, ipi: number) => {
      if (!ip.decisioned) {
        ip.decisioned = {};
      }
      if (!ip.extracted) {
        ip.extracted = {};
      }
      if (!ip.submitted) {
        ip.submitted = {};
      }
      return ip;
    });
    this.$data.documentLoading = true;
    this.$data.previewDocumentPathSelected = this.getListOfAccountPDfs()[0].value;
    this.takeDataSnapshot();
    eventBus.$emit(
      'IDP_PAGE_TITLE',
      'IDP Account Details' +
        (['In-Progress', 'Complete', 'Error'].includes(this.$data.accountData.status as string) &&
        this.$data.accountData.informedProducts.filter((product: any) => !product.dateTimeSendToRefundControl).length ==
          0
          ? ' (Read-Only)'
          : ''),
    );
    eventBus.$on('ADD_TEMP_ERROR', (error: any) => {
      const index = this.$data.tempErrors.push(error);
      setTimeout(() => {
        this.$data.tempErrors.splice(index - 1, 1);
        this.$forceUpdate();
      }, 2000);
    });
  }
  destroyed() {
    eventBus.$off('ADD_TEMP_ERROR');
  }
  buttons() {
    return [
      {
        name: 'Save',
        color: 'primary',
        callback: () => {
          this.saveData();
          this.getRemoteComponent('ConfirmationDialog')
            .external()
            .close();
        },
      },
    ];
  }
  buttonsExit() {
    return [
      {
        name: 'Exit',
        color: 'primary',
        callback: () => {
          setTimeout(() => {
            this.getRemoteComponent('ConfirmationDialogExit').open = false;
            this.$router.push({ path: `/idp` });
          }, 100);
        },
      },
      {
        name: 'Save',
        color: 'primary',
        callback: () => {
          this.requestSaveData();
          setTimeout(() => {
            this.getRemoteComponent('ConfirmationDialogExit').open = false;
          }, 100);
        },
      },
    ];
  }
  getLogTitle() {
    if (this.$data.detailRecord) {
      if (this.$data.control.selectedForm === 'ACCOUNT') {
        return `Account ` + this.$data.detailRecord.endpointName + ' Log';
      } else {
        return (
          `Product ` +
          this.$data.accountData.informedProducts[this.$data.control.selectedForm].productType +
          ' Callback Log'
        );
      }
    }
    return '';
  }
  async downloadFile(id: string, fieldName?: string): Promise<void> {
    const url = apiDownloadLogUrl.replace('{id}', id);
    return axiosInstance
      .get<Blob>(url, { responseType: 'blob' })
      .then(response => {
        if (response.data.size === 0) {
          // this.snackBarError = true;
        } else {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', fieldName ? fieldName + '.json' : 'api_log.json');
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      })
      .catch(() => {
        // this.snackBarError = true;
      });
  }
  getAccountErrors() {
    if (
      this.$data.control.selectedForm === 'ACCOUNT' &&
      this.$data.accountData &&
      this.$data.accountData.errors &&
      this.$data.accountData.errors.length > 0
    ) {
      return this.$data.accountData.errors;
    }
    return false;
  }
  getProductErrors() {
    if (
      this.$data.control.selectedForm !== 'ACCOUNT' &&
      this.$data.accountData &&
      this.$data.accountData.informedProducts[this.$data.control.selectedForm] &&
      this.$data.accountData.informedProducts[this.$data.control.selectedForm].errors &&
      this.$data.accountData.informedProducts[this.$data.control.selectedForm].errors.length > 0
    ) {
      return this.$data.accountData.informedProducts[this.$data.control.selectedForm].errors;
    }
    return false;
  }
  takeDataSnapshot() {
    this.$data.accountDataSnapshot = this.$getClone(this.$data.accountData);
  }
  ifAccountReadOnly(returnReadonlyMessage: boolean) {
    if (['Complete', 'In-Progress', 'Error'].includes(this.$data.accountData.status)) {
      if (returnReadonlyMessage) {
        return 'Account cannot be edited because status: ' + this.$data.accountData.status;
      }
      return true;
    }
    return false;
  }
  dataChange() {
    if (
      this.ifAccountReadOnly(false) === true &&
      this.$data.accountData.informedProducts.filter((product: any) => !product.dateTimeSendToRefundControl).length == 0
    ) {
      return true;
    }
    if (this.$data.accountData && this.$data.accountDataSnapshot) {
      return JSON.stringify(this.$data.accountData) === JSON.stringify(this.$data.accountDataSnapshot);
    }
    return true;
  }
  shouldDisableSaveButton() {
    const { accountData, accountDataSnapshot } = this.$data;

    if (
      this.ifAccountReadOnly(false) === true &&
      accountData?.informedProducts.filter((product: any) => !product.dateTimeSendToRefundControl).length == 0
    ) {
      return true;
    }

    if (accountData) {
      if (!accountData.sendToRefundControl && this.checkIfAccountIsCompleted()) {
        return false;
      }
    }

    if (accountData && accountDataSnapshot) {
      return JSON.stringify(accountData) === JSON.stringify(accountDataSnapshot);
    }

    return true;
  }
  openExtractionLog(informedApiLog: any) {
    this.$data.detailRecord = null;
    setTimeout(() => {
      this.$data.detailRecord = informedApiLog;
      this.getRemoteComponent('customDialogDetails')
        ?.external()
        .open();
    }, 100);
  }
  requestSaveData() {
    this.$data.sendToRefundControl = false;
    const accountTotalProductsCount = this.$data.accountData.informedProducts.length;
    const accountReviewCompletedProductsCount = this.$data.accountData.informedProducts.filter((p: any) => {
      return p.notIncluded || p.verified;
    }).length;

    if (this.checkIfAccountIsCompleted()) {
      setTimeout(() => {
        this.getRemoteComponent('ConfirmationDialog').open = true;
      }, 100);
    } else {
      this.saveData();
    }
  }
  async saveData() {
    this.$data.processing = true;

    const requestData = this.$getClone(this.$data.accountData);
    requestData.sendToRefundControl = this.$data.sendToRefundControl;

    const response = (await axiosInstance.post(dataEndpointUrl + this.id, requestData).then(r => r.data)) as any;
    this.$data.processing = false;
    this.$data.sendToRefundControl = false;

    this.$data.accountData = response.data;
    // Object.assign(this.$data.accountData, response.data);

    if (response.success === true) {
      this.takeDataSnapshot();
    }
    this.$forceUpdate();
  }
  getRemoteComponent(refComponentName: string): HTMLFormElement {
    return this.$refs[refComponentName] as HTMLFormElement;
  }
  openPDFInNewWindow() {
    window.open(this.$data.previewDocumentPathSelected);
  }
  accountPDFSelect(value: any) {
    this.$data.documentLoading = true;
    this.$data.previewDocumentPathSelected = value;
  }
  selectProduct(product: any, index: number) {
    this.$data.documentLoading = true;
    this.$data.previewDocumentPathSelected = product.pdfFile.url;
    this.$data.control.selectedForm = index;
  }
  selectAccount() {
    this.$data.documentLoading = true;
    this.$data.previewDocumentPathSelected = this.getListOfAccountPDfs()[0].value;
    this.$data.control.selectedForm = 'ACCOUNT';
  }
  getListOfAccountPDfs() {
    const files = [] as any[];
    Object.keys(this.$data.accountData.pdfFiles).forEach((property: string) => {
      files.push(
        ...this.$data.accountData.pdfFiles[property].map((file: any) => {
          return {
            value: file.url,
            type: property,
            text: file.fileName + ` (${property.toUpperCase()})`,
          };
        }),
      );
    });
    return files;
  }
  iframeLoaded() {
    this.$data.documentLoading = false;
  }
  GetTabTitle(informedProduct: any) {
    let title = 'Product Type: ' + informedProduct.productType + ' -  State: ';
    if (informedProduct.notIncluded) {
      title += 'NOT INCLUDED';
    }
    if (informedProduct.error) {
      title += 'ERROR';
    }
    if (informedProduct.verified) {
      title += 'VERIFIED';
    }
    if (!informedProduct.verified && !informedProduct.error && !informedProduct.notIncluded) {
      title += 'IN REVIEW';
    }
    return title;
  }
  getTabClass(informedProduct: any) {
    if (informedProduct.notIncluded && !!informedProduct.dateTimeSendToRefundControl) {
      return 'not-included ';
    }
    return '';
  }
  getTabSuccessIndicator(informedProduct: any) {
    let classes = '';
    if (informedProduct.verified) {
      classes = classes + ' success-indicator';
    }

    return classes;
  }
  getTabErrorIndicator(informedProduct: any) {
    let classes = '';
    if (informedProduct.errors && informedProduct.errors.length > 0) {
      classes = classes + ' error-indicator';
    }

    return classes;
  }
  checkIfAccountIsCompleted() {
    const accountTotalProductsCount = this.$data.accountData.informedProducts.length;
    const accountReviewCompletedProductsCount = this.$data.accountData.informedProducts.filter((p: any) => {
      return p.notIncluded || p.verified;
    }).length;
    return accountTotalProductsCount === accountReviewCompletedProductsCount;
  }
  getAccountProgressIcon() {
    const iconsMatrix = [
      'mdi-circle-outline',
      'mdi-circle-slice-1',
      'mdi-circle-slice-2',
      'mdi-circle-slice-3',
      'mdi-circle-slice-4',
      'mdi-circle-slice-5',
      'mdi-circle-slice-6',
      'mdi-circle-slice-7',
      'mdi-circle-slice-8',
    ];
    const accountTotalProductsCount = this.$data.accountData.informedProducts.length;
    const accountReviewCompletedProductsCount = this.$data.accountData.informedProducts.filter((p: any) => {
      return p.notIncluded || p.verified;
    }).length;
    const completionRatio = accountReviewCompletedProductsCount / accountTotalProductsCount;
    const iconIndex = Math.min(Math.ceil(completionRatio * 8), 8);

    return iconsMatrix[iconIndex];
  }
  goBack() {
    if (!this.dataChange()) {
      setTimeout(() => {
        this.getRemoteComponent('ConfirmationDialogExit').open = true;
      }, 100);
    } else {
      this.$router.push({ path: `/idp` });
    }
  }
}
