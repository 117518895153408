






























































import { Vue } from 'vue-property-decorator';
import { Product, Account } from '../interfaces';
import CustomDialog from '@/components/CustomDialog.vue';
import axios from '../services/api/Api.service';
import MetadataService, { MetadataType } from '../services/api/Metadata.service';
import { AxiosResponse } from 'axios';

interface AccountSendI extends Account {
  REQUESTSTATUS: string | null;
}

interface ExtendedProductI extends Product {
  finalizationStatus?: string | null;
}

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

const finalizeProductsProductEndpointUrl = '/uiapi/multiple-product-finalize-processing-status';

export default Vue.extend({
  name: 'FinalizeProductsAction',
  components: { CustomDialog },
  data: () => ({
    sendProcessed: false,
    service: MetadataService,
    metadataEnumsMap: new Map(),
    items: [] as ExtendedProductI[],
    itemsError: [] as { ids: number[]; reason: string }[],
    dataTable: {
      fixedHeader: true,
      disablePagination: false,
      hideDefaultFooter: false,
      footerProps: {
        itemsPerPageOptions: [9999],
      },
      itemsPerPage: 9999,
      options: {
        itemsPerPage: 9999,
      },
    },
    cancellationType: 'PRODUCT' as 'PRODUCT',
    openDialog: false,
    errors: [] as string[],
    messages: [] as string[],
    processing: false,
    CustomDialog: {
      ref: 'CustomDialog',
    },
  }),
  methods: {
    getStatusColor(status: string) {
      if (status == 'Success') {
        return '#43A047';
      }
      if (status == 'Not Send') {
        return '#1E88E5';
      }
      return '#E53935';
    },
    submitDataRequest(): boolean {
      // reset error box on send request
      this.error().clear();
      this.message().clear();
      this.itemsError.length = 0;

      this.processing = true;

      const requestObject = {
        // @ts-ignore: Unreachable code error
        productIds: this.items.map((item: ExtendedProductI) => {
          return parseInt((item.id || '').toString());
        }) as string[],
      };

      axiosInstance
        .post<
          any,
          AxiosResponse<{
            success: boolean;
            errors: string[];
            messages: string[];
            data: { data: ExtendedProductI[]; dataError: [] };
          }>
        >(finalizeProductsProductEndpointUrl, requestObject)
        .then(result => {
          const receivedItems = result.data.data.data;
          this.itemsError = result.data.data.dataError;

          this.items = this.items.map((product: ExtendedProductI) => {
            const returnProduct = receivedItems.find(
              (receivedProduct: ExtendedProductI) => receivedProduct.id == product.id,
            );
            if (returnProduct) {
              product = returnProduct as ExtendedProductI;
            } else {
              product.finalizationStatus = 'Not Exists';
            }

            product.finalizationStatus = 'Success';
            this.itemsError.forEach((errorItem: { ids: number[]; reason: string }) => {
              if (errorItem.ids.find((id: number) => id == product.id)) {
                product.finalizationStatus = errorItem.reason;
              }
            });
            return product;
          });

          if (result.data.messages?.length > 0) {
            this.message().set(result.data.messages);
          }
          if (result.data.errors?.length > 0) {
            this.error().set(result.data.errors);
          }
          this.$emit('finalization-request-success');
          this.processing = false;
          this.getRemoteComponent('CustomDialog')
            .external()
            .open();
        })
        .catch((error: string) => {
          this.error().add(error);
          this.processing = false;
          this.getRemoteComponent('CustomDialog')
            .external()
            .open();
        });
      return true;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    open(rowSelections: Product[]): void {
      this.sendProcessed = false;
      this.processing = false;
      this.error().clear();
      this.message().clear();
      this.items.length = 0;
      this.itemsError.length = 0;

      if (rowSelections.length < 1) {
        this.error().add('No items were selected');
      }

      this.items = rowSelections.map((product: ExtendedProductI) => {
        product.finalizationStatus = null;
        return product;
      }) as ExtendedProductI[];
      this.getRemoteComponent('CustomDialog')
        .external()
        .open();
    },
    error() {
      return {
        set: (errors: string[]) => {
          this.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      return {
        set: (errors: string[]) => {
          this.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.messages.push(error);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
  },
  async created() {
    this.metadataEnumsMap = await this.service.loadData(MetadataType.PRODUCT);
  },
  computed: {
    accountHeaders() {
      return [
        {
          text: 'Account #',
          value: 'accountNumber',
        },
        {
          text: 'Product Type',
          value: 'productType',
          parse: (v: string) => {
            const types = this.metadataEnumsMap.get('productType');
            let output = v;
            if (types) {
              types.forEach((type: { text: string; value: string }) => {
                if (v === type.value) {
                  output = type.text;
                }
              });
            }
            return output;
          },
        },
        {
          text: 'Provider Name',
          value: 'provider.name',
        },
        {
          text: 'Product Processing Status',
          value: 'productProcessingStatus',
        },
        {
          text: 'Finalization Status',
          value: 'finalizationStatus',
        },
      ];
    },
    buttons() {
      return [
        {
          name: 'Finalize Products',
          color: 'primary',
          disabled: false,
          callback: () => {
            this.submitDataRequest();
          },
        },
      ];
    },
  },
});
