
















































import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  data() {
    return {
      content: this.value,
    };
  },
  name: 'LenderServiceConfigurationMenu',
  watch: {
    '$props.value'(v) {
      this.content = v;
    },
  },
  methods: {
    getLiClass(item: any, content: any) {
      let outputClass = '';
      if (item.name === content.name) {
        outputClass += ' slds-is-active ';
      }
      if (!item.children) {
        outputClass += ' slds-nav-vertical__item ';
      }

      return outputClass;
    },
    expandCollapseChildren(item: any) {
      if (!item.expand) {
        item.expand = true;
      } else {
        item.expand = false;
      }
      this.$forceUpdate();
    },
    selectMenuItem(item: { name: string; disabled: boolean }) {
      this.$emit('input', item);
      this.content = item;
    },
  },
  props: ['value', 'menu'],
});
