































































import { Vue } from 'vue-property-decorator';
import FormatDisplayService from '../services/FormatDisplay.service';
import { LenderCustomFieldsI } from '../interfaces/lender.interface';
import ValidationService from './../services/Validations.service';
import moment from 'moment';

export interface RenderCustomFieldsItemI {
  name: string;
  items: [LenderCustomFieldsI, LenderCustomFieldsI | null][];
}

export default Vue.extend({
  name: 'CustomFieldsForm',
  components: {},
  data() {
    return {
      render: false,
      FormatDisplayService: FormatDisplayService,
      ValidationService: ValidationService,
      customFieldsDefinition: [] as LenderCustomFieldsI[],
      content: this.value,
      typeLayoutTranslation: {
        Checkbox: 'ONE_LINE',
        Currency: 'ONE_LINE',
        Date: 'ONE_LINE',
        'Date/time': 'ONE_LINE',
        Double: 'ONE_LINE',
        Email: 'ONE_LINE',
        Geolocation: 'TWO_LINE',
        Number: 'ONE_LINE',
        Percent: 'ONE_LINE',
        Phone: 'ONE_LINE',
        Time: 'ONE_LINE',
        Text: 'ONE_LINE',
        URL: 'TWO_LINE',
        'Text Area': 'TWO_LINE',
      },
    };
  },
  created() {
    // @ts-ignore: Unreachable code error
    this.customFieldsDefinition = this.$store.getters.getLenderCustomFields(this.objectType, this.orgId);
    if (!this.value) {
      this.content = {};
    }

    this.customFieldsDefinition.forEach((field: LenderCustomFieldsI) => {
      // if (this.content[field.apiName] === undefined) {
      //   this.content[field.apiName] = null;
      // }
      if (field.type === 'Time' && this.content[field.apiName]) {
        this.content[field.apiName] = moment.utc(this.content[field.apiName], 'HH:mm Z').format('HH:mm');
      }
    });
    this.render = true;
  },
  computed: {
    getFieldsRenderList(): RenderCustomFieldsItemI[] {
      const output = [] as RenderCustomFieldsItemI[];
      const fields = JSON.parse(JSON.stringify(this.customFieldsDefinition)).sort(
        (a: LenderCustomFieldsI, b: LenderCustomFieldsI) => {
          if (a.type < b.type) {
            return -1;
          }
          if (a.type > b.type) {
            return 1;
          }
          return 0;
        },
      );
      fields.forEach((field: LenderCustomFieldsI) => {
        let fieldType = output.find(outputField => this.getTypeTranslation(field.type) === outputField.name);
        if (!fieldType) {
          fieldType = {
            name: this.getTypeTranslation(field.type),
            items: [[field, null]],
          };
          output.push(fieldType);
        } else {
          let findNotEmpty = fieldType.items.find(
            (entry: [LenderCustomFieldsI, LenderCustomFieldsI | null]) => entry[1] === null,
          );
          if (!findNotEmpty) {
            fieldType.items.push([field, null]);
          } else {
            findNotEmpty[1] = field;
          }
        }
      });
      return output;
    },
  },
  watch: {
    '$props.value'(v) {
      this.content = v;
    },
  },
  methods: {
    getTypeTranslation(
      type:
        | 'Checkbox'
        | 'Currency'
        | 'Date'
        | 'Date/time'
        | 'Double'
        | 'Email'
        | 'Geolocation'
        | 'Number'
        | 'Percent'
        | 'Phone'
        | 'Time'
        | 'Text'
        | 'URL'
        | 'Text Area',
    ) {
      if (this.typeLayoutTranslation[type]) {
        return this.typeLayoutTranslation[type];
      } else {
        return 'ONE_LINE';
      }
    },
    makeid(length: number) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
  },
  props: [
    'value',
    'readonly',
    'disabled',
    'label',
    'rules',
    'placeholder',
    'disableclear',
    'customFieldsObject',
    'orgId',
    'objectType',
    'noTitle',
    'noFrame',
  ],
});
