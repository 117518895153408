var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      style: {
        visibility:
          _vm.messages.length > 0 || _vm.errors.length > 0
            ? "visible"
            : "hidden",
        padding:
          _vm.messages.length > 0 || _vm.errors.length > 0 ? "5px" : "0px"
      }
    },
    [
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.messages.length > 0,
              expression: "messages.length > 0"
            }
          ],
          staticClass: "confirmation-message"
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-alert",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "line-height": "15px"
                      },
                      attrs: {
                        type: "info",
                        border: "left",
                        text: "",
                        outlined: "",
                        dense: "",
                        autocomplete: "none"
                      }
                    },
                    [
                      _c("div", { staticClass: "mb-1" }, [
                        _c("b", [
                          _vm._v(
                            "Message" +
                              _vm._s(_vm.messages.length > 1 ? "s" : "") +
                              ":"
                          )
                        ])
                      ]),
                      _vm._l(_vm.messages, function(message, messageIndex) {
                        return _c("div", { key: messageIndex }, [
                          _vm._v("* " + _vm._s(message))
                        ])
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.length > 0,
              expression: "errors.length > 0"
            }
          ],
          staticClass: "error-message"
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-alert",
                    {
                      staticStyle: {
                        "font-weight": "strong",
                        "font-size": "14px",
                        "line-height": "15px"
                      },
                      attrs: {
                        type: "error",
                        border: "left",
                        text: "",
                        outlined: "",
                        dense: ""
                      }
                    },
                    [
                      _c("div", { staticClass: "mb-1" }, [
                        _c("b", [
                          _vm._v(
                            "Error" +
                              _vm._s(_vm.errors.length > 1 ? "s" : "") +
                              ":"
                          )
                        ])
                      ]),
                      _vm._l(_vm.errors, function(error, errorIndex) {
                        return _c("div", {
                          key: errorIndex,
                          domProps: { innerHTML: _vm._s("* " + error) }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }