































































































































































import { Vue } from 'vue-property-decorator';
import axios from '../../services/api/Api.service';
import EventBus from '@/events/EventBus';
import SfDataGrid from './SfDataGrid.vue';
const axiosInstance = new axios().getClient();
import { Product, CancellationLetterFormI } from '@/interfaces/';

export default Vue.extend({
  name: 'SfLetterPreview',
  data() {
    return {
      isSelecting: false,
      formValid: false,
      errors: [] as string[],
      messages: [] as string[],
      progress: false as boolean,
      open: false,
      search: '',
      selected: null,
      letterFile: null as CancellationLetterFormI | null,
      searchDelay: null as null | number,
      fixedConditions: [
        {
          fieldName: 'orgId',
          value: this.orgId,
          fieldType: 'string',
          conditionComparatorOption: 'equal',
        },
      ],
    };
  },
  components: { SfDataGrid },
  created() {
    this.error().clear();
    this.message().clear();
    if (!this.orgId) {
      this.error().clear();
      this.message().clear();
      this.error().add('Missing Organization ID');
    }
    EventBus.$off('SF_OPEN_LETTER_FORM_PREVIEW');
    EventBus.$on('SF_OPEN_LETTER_FORM_PREVIEW', (letterFile: CancellationLetterFormI | any) => {
      this.error().clear();
      this.message().clear();
      this.letterFile = letterFile;
      this.open = true;
    });
  },
  watch: {
    open: {
      handler(n, o) {
        if (n && n !== o) {
          this.search = '';
          this.selected = null;
          setTimeout(() => {
            this.$getRemoteComponent('SfPreviewSearchField', this).focus();
          }, 500);
        }
      },
    },
  },
  methods: {
    searchInput(input: string) {
      this.fixedConditions.length = 0;
      if (input !== null && input !== '') {
        this.fixedConditions.push({
          fieldName: 'accountNumber',
          value: input,
          fieldType: 'string',
          conditionComparatorOption: 'contain',
        });
      }
      this.fixedConditions.push({
        fieldName: 'orgId',
        value: this.orgId,
        fieldType: 'string',
        conditionComparatorOption: 'equal',
      });
      if (this.searchDelay) clearTimeout(this.searchDelay);
      this.searchDelay = setTimeout(() => {
        this.progress = true;
        this.$getRemoteComponent('SfLetterPreviewSearchGrid', this)
          .loadData()
          .then(() => {
            this.progress = false;
          });
      }, 1000);
    },
    getPreviewFile(selected: Product) {
      if (!selected && this.letterFile) {
        return false;
      } else {
        EventBus.$emit('SF_PROCESSING', true);
      }
      this.error().clear();
      this.message().clear();

      const testAccountNumber = 'LCTSample001';
      let url = '';

      if (selected.accountNumber === testAccountNumber) {
        url = `/uiapi/cancellation-letter/get-sample-letter-form-preview?orgId=${this.orgId}&Id=${this.letterFile?.id}`;
      } else {
        url = `/uiapi/cancellation-letter/get-letter-form-preview?orgId=${this.orgId}&Id=${this.letterFile?.id}&externalId=${selected.externalId}`;
      }

      if (this.letterFile?.temp && selected.accountNumber !== testAccountNumber) {
        url = `/uiapi/cancellation-letter/get-letter-form-temp-preview?orgId=${this.orgId}&Id=${this.letterFile?.id}&externalId=${selected.externalId}`;
      } else if (this.letterFile?.temp && selected.accountNumber === testAccountNumber) {
        url = `/uiapi/cancellation-letter/get-sample-letter-form-temp-preview?orgId=${this.orgId}&Id=${this.letterFile?.id}`;
      }

      this.sendRequestForPreview(url);
    },
    sendRequestForPreview(url: string) {
      axiosInstance
        .get<Blob>(url, {
          responseType: 'blob',
          headers: {
            orgId: this.orgId,
          },
        })
        .then(response => {
          EventBus.$emit('SF_PROCESSING', false);
          if (response.data.size === 0) {
            this.error().add('Error Empty File....');
          } else {
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const newWindow = window.open(fileURL, '_BLANK');
            if (newWindow) {
              setTimeout(() => {
                newWindow.document.title = this.letterFile?.fileURL || 'LetterFormFilePreview.pdf';
              }, 300);
            }
          }
        })
        .catch(async (e: any) => {
          this.error().add('Error Generating Preview ...');
          try {
            const blobData = new Blob([e.response.data]);
            const responseResult = JSON.parse(await blobData.text());

            this.error().add(responseResult.error + ', ' + responseResult.message);
          } catch (e) {
            console.log('Unresolved error...');
          }

          EventBus.$emit('SF_PROCESSING', false);
        });
    },
    message() {
      const scope = this;
      return {
        set: (messages: string[]) => {
          scope.messages.push(...messages);
          return this;
        },
        add: (message: string) => {
          scope.messages.push(message);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
  },
  props: ['orgId'],
});
