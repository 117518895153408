


















































































































































































































































































































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import ValidationService from '@/services/Validations.service';
import FormatDisplayService from '@/services/FormatDisplay.service';
import ConsumerRefundService from '@/services/api/ConsumerRefund.service';
import { ConsumerRefund } from '../../interfaces';

export default Vue.extend({
  name: 'ConsumerRefundDetails',
  components: {},
  data: () => ({
    formatDisplayService: FormatDisplayService,
    valid: true,
    overlay: false,
    errors: [] as string[],
    processing: false,
    snackBarSuccess: false,
    snackBarError: false,
    service: MetadataService,
    ValidationService: ValidationService as Object,
    metadataEnumsMapProduct: new Map(),
    metadataEnumsMapAccount: new Map(),
    editedRecord: {
      product: { provider: {} },
      account: { dealer: {} },
    } as ConsumerRefund,
    consumerRefundDateMenu: false,
  }),
  methods: {
    async updateConsumerRefund() {
      this.error().clear();
      try {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          this.overlay = true;
          let res = await ConsumerRefundService.update(this.editedRecord);
          if (res.success) {
            this.snackBarSuccess = true;
            this.overlay = false;
            this.$emit('consumerRefundUpdated');
          } else {
            if (res.errors) {
              this.error().set(res.errors);
            }
            this.snackBarError = true;
            this.overlay = false;
          }
        }
      } catch (err) {
        this.error().set([err.response.data.message]);
        this.snackBarError = true;
        this.overlay = false;
      }
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    setError(errors: string[]) {
      this.errors.length = 0;
      Object.assign(this.errors, errors);
    },
  },
  async created() {
    this.editedRecord = Object.assign(this.editedRecord, this.passEditedRecord);
    this.metadataEnumsMapProduct = await this.service.loadData(MetadataType.PRODUCT);
    this.metadataEnumsMapAccount = await this.service.loadData(MetadataType.ACCOUNT);
  },
  filters: {
    toDate(v: string | null) {
      if (v) {
        return FormatDisplayService.getRender('toDateTime')(v);
      } else {
        return ' ';
      }
    },
    formatUserDateTime(user: string | null, dateTime: string | null) {
      const userDisplay = user === 'null' || user === '' || user === null || user === undefined ? '' : user + ', ';
      return userDisplay + (FormatDisplayService.getRender('toDateTime')(dateTime) || '');
    },
    nullToEmpty(v: string | null) {
      return v ? v : ' ';
    },
  },
  watch: {
    passEditedRecord: function(newVal) {
      this.editedRecord = Object.assign(this.editedRecord, newVal);

      this.errors.length = 0;
    },
  },
  props: {
    passEditedRecord: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
