

































































import { Vue } from 'vue-property-decorator';
import ValidationService from '../services/Validations.service';
import { EnumItemI } from '../services/DealerPage.service';
import DealerPageService from '../services/DealerPage.service';
import { Lender } from '../interfaces/lender.interface';

import LenderPreferencesTab from './lenderDetailsTabs/lenderPreferencesTab.vue';
import ProductPreferencesTab from './lenderDetailsTabs/productPreferencesTab.vue';
import ApiConfigurationTab from './lenderDetailsTabs/apiConfigurationTab.vue';
import LogoTab from './lenderDetailsTabs/logoTab.vue';
import LenderCustomFieldsTab from './lenderDetailsTabs/lenderCustomFieldsTab.vue';
import LenderServiceConfiguration from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfiguration.vue';

export default Vue.extend({
  name: 'LenderDetails',
  components: {
    LenderPreferencesTab,
    ProductPreferencesTab,
    ApiConfigurationTab,
    LogoTab,
    LenderCustomFieldsTab,
    LenderServiceConfiguration,
  },
  data: () => ({
    editedRecord: {} as Lender,
    ValidationService: ValidationService as Object,
    dialog: false,
    valid: true,
    overlay: false,
    message: '',
    activeTab: 'lenderPreferences',
    countriesList: [] as EnumItemI[],
    timezonesList: [] as EnumItemI[],
    height: 500,
  }),
  computed: {
    isEditedRecordLenderActive() {
      return this.editedRecord.active;
    },
  },
  methods: {
    updateEditedRecord() {
      this.editedRecord = this.passEditedRecord;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    updateLender() {
      this.getRemoteComponent('preferencesTab').submitDataRequest();
    },
    onResize() {
      this.height = window.innerHeight - this.getRemoteComponent('resizableDiv')?.getBoundingClientRect().y;
      this.height -= 28;
    },
  },
  async created() {
    this.countriesList = await DealerPageService.getItems('DealerCountry');
    this.timezonesList = await DealerPageService.getItems('DealerTimeZones');
    this.updateEditedRecord();
  },
  watch: {
    activeTab: {
      handler(newVal) {
        this.$emit('tabChanged', newVal);
        setTimeout(() => {
          this.onResize();
        });
      },
    },
  },
  props: {
    passEditedRecord: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
});
