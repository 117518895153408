



import { Vue } from 'vue-property-decorator';
import { utils, writeFileXLSX } from 'xlsx';

export default Vue.extend({
  name: 'XlsxExport',
  components: {},
  data: () => ({}),
  methods: {
    download_file(content: any, fileName: string, mimeType: string) {
      var a = document.createElement('a');
      mimeType = mimeType || 'application/octet-stream';
      // @ts-ignore: Unreachable code error
      if (navigator.msSaveBlob) {
        // IE10
        // @ts-ignore: Unreachable code error
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName,
        );
      } else if (URL && 'download' in a) {
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          }),
        );
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content);
      }
    },
    exportFileTXT(fileName: string, exportData: any[]) {
      const ws = utils.json_to_sheet(exportData);
      var txt = utils.sheet_to_txt(ws, { strip: true });
      this.download_file(txt, fileName, 'text/txt;encoding:utf-8');
    },
    exportFileCSV(fileName: string, exportData: any[]) {
      const ws = utils.json_to_sheet(exportData);
      var csv = utils.sheet_to_csv(ws, { strip: true });
      this.download_file(csv, fileName, 'text/csv;encoding:utf-8');
    },
    exportFileXLSX(fileName: string, exportData: any[]) {
      const data = exportData as any;
      const wb = utils.book_new();
      if (data.sheets && data.sheets.length) {
        data.sheets.forEach((sheetData: any[], sheetIndex: number) => {
          const ws = utils.json_to_sheet(sheetData);
          utils.book_append_sheet(wb, ws, 'Data ' + sheetIndex);
        });
      } else {
        const ws = utils.json_to_sheet(data);
        utils.book_append_sheet(wb, ws, 'Data');
      }
      writeFileXLSX(wb, fileName);
    },
    exportFile(fileName: string, type: 'TXT' | 'XLSX' | 'CSV', data: any[]) {
      if (!data) {
        data = this.exportData;
      }
      switch (type) {
        case 'XLSX':
          this.exportFileXLSX(fileName, data);
          break;
        case 'CSV':
          this.exportFileCSV(fileName, data);
          break;
        case 'TXT':
          this.exportFileTXT(fileName, data);
          break;
      }
    },
    deserializeData(data: any[]) {
      const deserializedData = [] as {}[];
      const outputdData = [] as {}[];
      const keysSummary = [] as string[];
      data.forEach((row: any) => {
        deserializedData.push(
          Object.assign(
            {},
            // @ts-ignore: Unreachable code error
            ...(function _flatten(o: any) {
              return [].concat(
                ...Object.keys(o).map(k =>
                  typeof o[k] === 'object' && o[k] !== null ? _flatten(o[k]) : { [k]: o[k] },
                ),
              );
            })(row),
          ),
        );
      });
      deserializedData.forEach((row: any) => {
        Object.keys(row).forEach((key: any) => {
          if (keysSummary.indexOf(key) === -1) {
            keysSummary.push(key);
          }
        });
      });
      deserializedData.forEach((row: any) => {
        const newRow = {};
        keysSummary.forEach((key: any) => {
          if (row[key] !== undefined) {
            // @ts-ignore: Unreachable code error
            newRow[key] = row[key];
          } else {
            // @ts-ignore: Unreachable code error
            newRow[key] = null;
          }
        });
        outputdData.push(newRow);
      });
      return outputdData;
    },
  },
  props: {
    exportData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
});
