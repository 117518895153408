var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-alert",
        {
          staticStyle: { "font-size": "12px", "line-height": "15px" },
          attrs: {
            type: "info",
            border: "left",
            text: "",
            outlined: "",
            dense: "",
            autocomplete: "none"
          }
        },
        [
          _vm.$props.title
            ? _c(
                "div",
                [
                  _c("h3", { staticClass: "mt-1 mb-4" }, [
                    _vm._v(_vm._s(_vm.title))
                  ]),
                  _vm._l(_vm.messages, function(message, messageIndex) {
                    return _c(
                      "div",
                      {
                        key: messageIndex,
                        staticStyle: { "line-height": "18px" }
                      },
                      [
                        _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                          _vm._v(_vm._s(message))
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            : _c(
                "div",
                _vm._l(_vm.messages, function(message, messageIndex) {
                  return _c(
                    "div",
                    {
                      key: messageIndex,
                      staticStyle: { "line-height": "25px" }
                    },
                    [
                      _c("p", { staticStyle: { "margin-bottom": "0px" } }, [
                        _vm._v(_vm._s(message))
                      ])
                    ]
                  )
                }),
                0
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }