var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cl-root" }, [
    _c(
      "div",
      { staticClass: "cl-base-info" },
      [_vm._t("base-info", [_vm._v("SLOT BASE INFO")])],
      2
    ),
    _c("div", { staticClass: "cl-base" }, [
      _c(
        "div",
        { staticClass: "cl-main-left" },
        [
          _vm._t("main-left", [
            _c(
              "div",
              { staticClass: "cl-params-up" },
              [_vm._t("param-up", [_vm._v("SLOT PARAM UP")])],
              2
            ),
            _c(
              "div",
              { staticClass: "cl-params-down" },
              [_vm._t("param-down", [_vm._v("SLOT PARAM DOWN")])],
              2
            )
          ])
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "cl-main-right" },
        [_vm._t("main-right", [_vm._v("SLOT MAIN-RIGHT")])],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }