var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._b(
      {
        attrs: {
          items: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
            { value: null, text: "Select" }
          ]
        },
        on: { input: _vm.input },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      },
      "v-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }