import ApiService from '@/services/api/Api.service';
import { Account, Response } from '../../interfaces';
import { AxiosResponse } from 'axios';

class AccountService extends ApiService {
  async getById(id: number): Promise<Account> {
    return (await this.axios.get('/uiapi/account/' + id)).data;
  }

  async create(data: Account): Promise<Response<Account>> {
    return (await this.axios.post<Account, AxiosResponse<Response<Account>>>('/uiapi/account/', data)).data;
  }

  async update(data: Account): Promise<Response<Account>> {
    return (await this.axios.post<Account, AxiosResponse<Response<Account>>>('/uiapi/account/' + data.id, data)).data;
  }
}

export default new AccountService();
