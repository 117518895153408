














































































import DataGrid from '../components/DataGrid.vue';
import { Vue } from 'vue-property-decorator';
import PageScrollLayout from '../components/PageScrollLayout.vue';

import { Lender } from '../interfaces';
import LenderDetails from '../components/lenderDetails.vue';
import CustomDialog from '../components/CustomDialog.vue';
import LenderCreate from '../components/LenderCreate.vue';

/**
 *  Metadata service URL
 */

const metadataEndpointUrl = '/uiapi/grids/lender';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/lender/search';

const emptyLenderDataRecord: Lender = {
  lenderID: '',
  orgId: '',
  name: '',
  mailingCountry: '',
  integrationCommunicationSetup: [
    {
      providerCode: 'INTERFAX',
      username: null,
      password: null,
      email: null,
      dateTimeCreated: null,
      createdBy: null,
      dateTimeUpdated: null,
      updatedBy: null,
      active: true,
      id: null,
    },
  ],
  isSalesforce: true,
};

export default Vue.extend({
  name: 'Lender',
  components: {
    PageScrollLayout,
    DataGrid,
    LenderDetails,
    CustomDialog,
    LenderCreate,
  },
  data: () => ({
    lender: null as null | string,
    dialog: false,
    content: 0,
    lenderDetailsEnabled: false,
    activeTab: 'lenderPreferences',
    editedRecord: Object.assign({}, emptyLenderDataRecord),
    reRenderGrid: 0,
    editedRecordLenderDetails: {},
    hideFilters: false,
    lenderDialogCreateOpen: false,
    endPoint: {
      metadataEndpointUrl: metadataEndpointUrl,
      dataEndpointUrl: dataEndpointUrl,
    },
  }),
  watch: {
    '$store.getters.lender': {
      handler() {
        this.lender = this.$store.getters.lender as string;
      },
    },
  },
  methods: {
    refreshEditedPreferences() {
      this.getRemoteComponent('lenderDetails')
        .getRemoteComponent('preferencesTab')
        .formDataRefresh();
    },
    goBack() {
      this.lenderDetailsEnabled = false;
      this.content = 0;
      setTimeout(() => {
        (this.$refs.dataGrid as HTMLFormElement)?.onResize();
      }, 250);
    },
    onTabChanged(val: string) {
      this.activeTab = val;
    },
    onLenderCreated() {
      this.reRenderGrid++;
      setTimeout(() => {
        this.setLenderCreateDialogOpen(false);
      }, 2000);
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    updateLender() {
      this.getRemoteComponent('lenderDetails').updateLender();
    },
    setLenderCreateDialogOpen(state: boolean) {
      if (state) {
        this.getRemoteComponent('LenderCreateDialog')
          .external()
          .open();
        this.lenderDialogCreateOpen = true;
      } else {
        this.getRemoteComponent('LenderCreateDialog')
          .external()
          .close();
        this.lenderDialogCreateOpen = false;
      }
    },
    createLender() {
      this.getRemoteComponent('lenderCreate').createLender();
    },
  },
  computed: {
    actions() {
      return [
        {
          icon: 'mdi-table-cog',
          tooltip: 'View Lender',
          callback: (rowData: Lender): void => {
            this.editedRecordLenderDetails = Object.assign({}, JSON.parse(JSON.stringify(rowData as Lender)));
            this.content = 1;
            this.lenderDetailsEnabled = true;
          },
        },
      ];
    },
    buttonActions() {
      return [
        {
          name: 'New Lender',
          icon: 'mdi-pencil',
          callback: () => {
            this.setLenderCreateDialogOpen(true);
          },
        },
      ];
    },
  },
  created() {
    this.lender = this.$store.getters.lender;
  },
});
