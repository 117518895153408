var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "lenderForm",
      attrs: { "lazy-validation": "", disabled: _vm.overlay },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.length > 0,
                  expression: "errors.length > 0"
                }
              ]
            },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-alert",
                        {
                          staticStyle: {
                            "font-weight": "strong",
                            "font-size": "14px",
                            "line-height": "15px"
                          },
                          attrs: {
                            type: "error",
                            border: "left",
                            text: "",
                            outlined: "",
                            dense: ""
                          }
                        },
                        [
                          _c("div", [_c("b", [_vm._v("Errors:")])]),
                          _vm._l(_vm.errors, function(error, errorIndex) {
                            return _c("div", { key: errorIndex }, [
                              _vm._v("* " + _vm._s(error))
                            ])
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-card-header" },
                [_c("v-row", [_c("v-col", [_vm._v(" Lender Info ")])], 1)],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      label: "Lender Id",
                                      disabled: "",
                                      value: _vm.editedRecord.lenderID
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Lender Name",
                                      required: "",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "required",
                                          "Lender Name"
                                        ),
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Lender Name",
                                          {
                                            length: 80
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value: _vm.editedRecord.lenderName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "lenderName",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.lenderName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Lender Short Name",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Lender Short Name",
                                          {
                                            length: 80
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value: _vm.editedRecord.lenderShortName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "lenderShortName",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.lenderShortName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "(###) ###-####",
                                        expression: "'(###) ###-####'"
                                      }
                                    ],
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Lender Phone",
                                      required: "",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "phone",
                                          "Phone Number"
                                        )
                                      ]
                                    },
                                    model: {
                                      value: _vm.editedRecord.phoneNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "phoneNumber",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.phoneNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "(###) ###-####",
                                        expression: "'(###) ###-####'"
                                      }
                                    ],
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Lender Fax",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "phone",
                                          "Lender Fax"
                                        )
                                      ]
                                    },
                                    model: {
                                      value: _vm.editedRecord.lenderFax,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "lenderFax",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.lenderFax"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "menu",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        "return-value":
                                          _vm.editedRecord.dailyCutOffTime,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "max-width": "290px",
                                        "min-width": "290px"
                                      },
                                      on: {
                                        "update:returnValue": function($event) {
                                          return _vm.$set(
                                            _vm.editedRecord,
                                            "dailyCutOffTime",
                                            $event
                                          )
                                        },
                                        "update:return-value": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.editedRecord,
                                            "dailyCutOffTime",
                                            $event
                                          )
                                        }
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        dense: "",
                                                        label:
                                                          "Daily Cut-off Time",
                                                        "append-icon":
                                                          "mdi-clock-time-four-outline",
                                                        readonly: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedRecord
                                                            .dailyCutOffTime,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedRecord,
                                                            "dailyCutOffTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedRecord.dailyCutOffTime"
                                                      }
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.dailyCutOffTimeMenu,
                                        callback: function($$v) {
                                          _vm.dailyCutOffTimeMenu = $$v
                                        },
                                        expression: "dailyCutOffTimeMenu"
                                      }
                                    },
                                    [
                                      _vm.dailyCutOffTimeMenu
                                        ? _c("v-time-picker", {
                                            attrs: {
                                              "ampm-in-title": "",
                                              "allowed-minutes":
                                                _vm.allowedStep,
                                              "full-width": ""
                                            },
                                            on: {
                                              "click:minute": function($event) {
                                                return _vm.$refs.menu.save(
                                                  _vm.editedRecord
                                                    .dailyCutOffTime
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.editedRecord
                                                  .dailyCutOffTime,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editedRecord,
                                                  "dailyCutOffTime",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedRecord.dailyCutOffTime"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: _vm.timezonesList,
                                      label: "Lender Time Zone"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.editedRecord.lenderTimeZone,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "lenderTimeZone",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.lenderTimeZone"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Cancel Department Name",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Cancel Department Name",
                                          {
                                            length: 255
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.cancelDepartmentName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "cancelDepartmentName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.cancelDepartmentName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "(###) ###-####",
                                        expression: "'(###) ###-####'"
                                      }
                                    ],
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Cancel Department Phone",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "phone",
                                          "Cancel Department Phone"
                                        ),
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Cancel Department Phone",
                                          {
                                            length: 255
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.cancelDepartmentPhone,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "cancelDepartmentPhone",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.cancelDepartmentPhone"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Cancel Department Email",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "email",
                                          "Email"
                                        ),
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Cancel Department Email",
                                          {
                                            length: 255
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.cancelDepartmentEmail,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "cancelDepartmentEmail",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.cancelDepartmentEmail"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("custom-date-picker", {
                                    attrs: { label: "Agreement Date" },
                                    model: {
                                      value: _vm.editedRecord.contractDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "contractDate",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.contractDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "Active" },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.editedRecord.active,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "active",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.active"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Mailing Street Address",
                                      required: "",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Mailing Street Address",
                                          {
                                            length: 255
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.mailingStreetAddress,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "mailingStreetAddress",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.mailingStreetAddress"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Mailing City",
                                      required: "",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Mailing City",
                                          {
                                            length: 255
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value: _vm.editedRecord.mailingCity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "mailingCity",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.mailingCity"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Mailing Zip",
                                      required: "",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Mailing Zip",
                                          {
                                            length: 255
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value: _vm.editedRecord.mailingZip,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "mailingZip",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.mailingZip"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: _vm.statesList,
                                      label: "Mailing State",
                                      disabled: !_vm.isMailingCountrySelected
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.editedRecord.mailingState,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "mailingState",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.mailingState"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: _vm.countriesList,
                                      label: "Mailing Country"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.loadStates()
                                      }
                                    },
                                    model: {
                                      value: _vm.editedRecord.mailingCountry,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "mailingCountry",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.mailingCountry"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Physical Street Address",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Physical Street Address",
                                          {
                                            length: 255
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.physicalStreetAddress,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "physicalStreetAddress",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.physicalStreetAddress"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Physical City",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Physical City",
                                          {
                                            length: 255
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value: _vm.editedRecord.physicalCity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "physicalCity",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.physicalCity"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Physical Zip",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Physical Zip",
                                          {
                                            length: 255
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value: _vm.editedRecord.physicalZip,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "physicalZip",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.physicalZip"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: _vm.statesList,
                                      label: "Physical State"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.editedRecord.physicalState,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "physicalState",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.physicalState"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: _vm.countriesList,
                                      label: "Physical Country"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.editedRecord.physicalCountry,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "physicalCountry",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.physicalCountry"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "mt-5" },
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-card-header" },
                [_c("v-row", [_c("v-col", [_vm._v(" Salesforce ")])], 1)],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "Enabled", disabled: "" },
                                    model: {
                                      value: _vm.editedRecord.isSalesforce,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "isSalesforce",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.isSalesforce"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      label: "Organization Id",
                                      disabled: "",
                                      value: _vm.editedRecord.orgId
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "mt-5" },
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-card-header" },
                [_c("v-row", [_c("v-col", [_vm._v(" Services ")])], 1)],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "Refund Services" },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.editedRecord.refund,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "refund",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.refund"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: ["Manual", "Automated"],
                                      label: "File Processing Method"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.fileProcessingMethod,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "fileProcessingMethod",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.fileProcessingMethod"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "Product Research" },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.editedRecord.productResearch,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "productResearch",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.productResearch"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: [
                                        "NA",
                                        "Open/Cancellation Sent",
                                        "Open/Finalized",
                                        "Closed/Cancellation Sent",
                                        "Closed/Finalized"
                                      ],
                                      label: "Cancellation Only - Final State"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .cancellationOnlyFinalStatuses,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "cancellationOnlyFinalStatuses",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.cancellationOnlyFinalStatuses"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dense: "",
                                      label: "U.S. Mail - Dealer Letters"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .postalServiceForDealerCancellationLetters,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "postalServiceForDealerCancellationLetters",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.postalServiceForDealerCancellationLetters"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "ADS ID"
                                    },
                                    model: {
                                      value: _vm.editedRecord.adsId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editedRecord, "adsId", $$v)
                                      },
                                      expression: "editedRecord.adsId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "mt-5" },
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-card-header" },
                [_c("v-row", [_c("v-col", [_vm._v(" Workflow ")])], 1)],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "Auto Send Cancellations" },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .automaticCancellationSend,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "automaticCancellationSend",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.automaticCancellationSend"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: ["NA", "Global", "Dealer Record"],
                                      label: "Debit Dealer Reserve"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.editedRecord.dealerReserve,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "dealerReserve",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.dealerReserve"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: ["NA", "Yes", "No"],
                                      label: "Credit Consumer Balance"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.consumerDeficiency,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "consumerDeficiency",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.consumerDeficiency"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dense: "",
                                      label:
                                        "Finalize if in Closed Servicing Batch"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .finalizeIfInClosedServicingBatch,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "finalizeIfInClosedServicingBatch",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.finalizeIfInClosedServicingBatch"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dense: "",
                                      label:
                                        "Finalize if $0 Refund Within Tolerance"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .finalizeIfRefundDueWithinTolerance,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "finalizeIfRefundDueWithinTolerance",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.finalizeIfRefundDueWithinTolerance"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: [
                                        "Yes - Only CSV file",
                                        "Yes - CSV file with PDF documents",
                                        "No"
                                      ],
                                      label: "Daily Return File Delivery"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.editedRecord.dailyReturnFile,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "dailyReturnFile",
                                          $$v
                                        )
                                      },
                                      expression: "editedRecord.dailyReturnFile"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: [
                                        "CSV with JSON Collection",
                                        "Legacy CSV"
                                      ],
                                      label: "Return File Format"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.editedRecord.returnFileFormat,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "returnFileFormat",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.returnFileFormat"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dense: "",
                                      label:
                                        "Servicing Batch with PDF Documents"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.servicingBatchWithDocs,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "servicingBatchWithDocs",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.servicingBatchWithDocs"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "mt-5" },
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-card-header" },
                [_c("v-row", [_c("v-col", [_vm._v(" Notifications ")])], 1)],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Notification Email",
                                      required: "",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "email",
                                          "Email"
                                        ),
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Notification Email",
                                          {
                                            length: 255
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value: _vm.editedRecord.notificationEmail,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "notificationEmail",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.notificationEmail"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Notification Delay Charge Off",
                                      required: "",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "maxLength",
                                          "Notification Email",
                                          {
                                            length: 255
                                          }
                                        )
                                      ]
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .notificationDelayChargeOff,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "notificationDelayChargeOff",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.notificationDelayChargeOff"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label:
                                        "Notification Delay Customer Request",
                                      type: "number"
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .notificationDelayCustomerRequest,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "notificationDelayCustomerRequest",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.notificationDelayCustomerRequest"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Notification Delay Grounded",
                                      type: "number",
                                      rules: []
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .notificationDelayGrounded,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "notificationDelayGrounded",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.notificationDelayGrounded"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Notification Delay Lien Loss",
                                      type: "number",
                                      rules: []
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .notificationDelayLienLoss,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "notificationDelayLienLoss",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.notificationDelayLienLoss"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Notification Delay Other",
                                      type: "number",
                                      rules: []
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.notificationDelayOther,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "notificationDelayOther",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.notificationDelayOther"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Notification Delay Out Of Policy",
                                      type: "number",
                                      rules: []
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .notificationDelayOutOfPolicy,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "notificationDelayOutOfPolicy",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.notificationDelayOutOfPolicy"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Notification Delay Payoff",
                                      type: "number",
                                      rules: []
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .notificationDelayPayoff,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "notificationDelayPayoff",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.notificationDelayPayoff"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Notification Delay Repossession",
                                      type: "number",
                                      rules: []
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .notificationDelayRepossession,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "notificationDelayRepossession",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.notificationDelayRepossession"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      autocomplete: "none",
                                      label: "Notification Delay Total Loss",
                                      type: "number",
                                      rules: []
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .notificationDelayTotalLoss,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "notificationDelayTotalLoss",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.notificationDelayTotalLoss"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "mt-5" },
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-card-header" },
                [_c("v-row", [_c("v-col", [_vm._v(" Security ")])], 1)],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: ["Yes", "No"],
                                      label: "File Encryption From LCT"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.fileEncryptionfromLCT,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "fileEncryptionfromLCT",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.fileEncryptionfromLCT"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: ["Yes", "No"],
                                      label: "File Encryption To LCT"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.fileEncryptiontoLCT,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "fileEncryptiontoLCT",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.fileEncryptiontoLCT"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "mt-5",
              attrs: {
                disabled: !_vm.userService.checkUserActionAccess(
                  "LENDER_EDIT_INTERFAX"
                )
              }
            },
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-card-header" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _vm._v(" Integration Information (Interfax) ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      clearable: "",
                                      label: "Username",
                                      rules: [
                                        _vm.integrationComunicationRules()
                                      ]
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .integrationCommunicationSetup[0]
                                          .username,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord
                                            .integrationCommunicationSetup[0],
                                          "username",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.integrationCommunicationSetup[0].username"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      clearable: "",
                                      label: "Password",
                                      type: _vm.integrationPasswordShow
                                        ? "text"
                                        : "password",
                                      "append-icon": _vm.integrationPasswordShow
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      rules: [
                                        _vm.integrationComunicationRules()
                                      ]
                                    },
                                    on: {
                                      "click:append": function($event) {
                                        return _vm.clickShowPassword()
                                      },
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .integrationCommunicationSetup[0]
                                          .password,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord
                                            .integrationCommunicationSetup[0],
                                          "password",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.integrationCommunicationSetup[0].password"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      clearable: "",
                                      label: "Email",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "email",
                                          "Email"
                                        ),
                                        _vm.integrationComunicationRules()
                                      ]
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.formValueChange()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord
                                          .integrationCommunicationSetup[0]
                                          .email,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord
                                            .integrationCommunicationSetup[0],
                                          "email",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.integrationCommunicationSetup[0].email"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "6" } }, [_c("v-row")], 1)
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "mt-5" },
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-card-header" },
                [
                  _c(
                    "v-row",
                    [_c("v-col", [_vm._v(" System Information ")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      label: "Created By",
                                      disabled: "",
                                      value: _vm._f("formatUserDateTime")(
                                        _vm.editedRecord.createdByName,
                                        _vm.editedRecord.dateTimeCreated
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      label:
                                        "Last " +
                                        (_vm.editedRecord.active
                                          ? "Activated"
                                          : "Deactivated") +
                                        " By",
                                      disabled: "",
                                      value: _vm._f("formatUserDateTime")(
                                        this.editedRecord.activeChangedByName,
                                        this.editedRecord.activeChangedAt
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      label: "Last Modified By",
                                      disabled: "",
                                      value: _vm._f("formatUserDateTime")(
                                        _vm.editedRecord.updatedByName,
                                        _vm.editedRecord.dateTimeUpdated
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      label: "Owner",
                                      disabled: "",
                                      value: _vm.editedRecord.createdByName
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-snackbar",
            {
              attrs: {
                centered: "",
                color: "success",
                text: "",
                timeout: 3000
              },
              model: {
                value: _vm.snackBarSuccess,
                callback: function($$v) {
                  _vm.snackBarSuccess = $$v
                },
                expression: "snackBarSuccess"
              }
            },
            [
              _c("v-icon", { attrs: { color: "success" } }, [
                _vm._v(" mdi-check-bold ")
              ]),
              _vm._v(" Completed successfully ")
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { centered: "", color: "error", text: "", timeout: 4000 },
              model: {
                value: _vm.snackBarError,
                callback: function($$v) {
                  _vm.snackBarError = $$v
                },
                expression: "snackBarError"
              }
            },
            [
              _c("v-icon", { attrs: { color: "error" } }, [
                _vm._v(" mdi-alert-circle-outline ")
              ]),
              _vm._v(" Save Failed ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }