import { render, staticRenderFns } from "./NotesComponent.vue?vue&type=template&id=14133530&scoped=true&"
import script from "./NotesComponent.vue?vue&type=script&lang=ts&"
export * from "./NotesComponent.vue?vue&type=script&lang=ts&"
import style0 from "./NotesComponent.vue?vue&type=style&index=0&id=14133530&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14133530",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VDataTable,VIcon,VProgressCircular,VTextarea})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('14133530')) {
      api.createRecord('14133530', component.options)
    } else {
      api.reload('14133530', component.options)
    }
    module.hot.accept("./NotesComponent.vue?vue&type=template&id=14133530&scoped=true&", function () {
      api.rerender('14133530', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SFComponents/Notes/NotesComponent.vue"
export default component.exports