






















































import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  name: 'promptDialog',
  components: {},
  methods: {
    closePrompt() {
      this.$emit('closePrompt');
    },
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    headerTemplate: {
      type: String,
      default: '',
    },
    textTemplate: {
      type: String,
      default: '',
    },
    processing: {
      type: Boolean,
      default: false,
    },
    confirmHandler: {
      type: Function,
    },
  },
});
