var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.openDialog
        ? _c(
            "confirmation-dialog",
            _vm._b(
              { attrs: { buttons: _vm.buttons, eager: "" } },
              "confirmation-dialog",
              _vm.confirmationDialog,
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }