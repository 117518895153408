var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { flex: "1", display: "flex", "flex-direction": "column" } },
    [
      _c(
        "div",
        { staticStyle: { height: "83px", "margin-right": "18px" } },
        [
          _c(
            "v-row",
            {
              staticClass: "ml-0 mr-0",
              staticStyle: { "border-bottom": "1px solid #ddd" }
            },
            [
              _c("v-col", { staticClass: "vertical-center" }, [
                _vm._v("Field Name")
              ]),
              _c(
                "v-col",
                { staticClass: "vertical-center", attrs: { cols: "3" } },
                [_vm._v("Condition")]
              ),
              _c("v-col", [_vm._v("Value")]),
              _c("v-col", {
                staticClass: "vertical-center",
                attrs: { cols: "1" }
              })
            ],
            1
          ),
          _c(
            "v-form",
            { ref: "REFInsertParameterForm", staticClass: "mt-3" },
            [
              _c(
                "v-row",
                { staticClass: "ml-0 mr-0 " },
                [
                  _c(
                    "v-col",
                    { staticClass: "sfl-fix-label" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          disabled: _vm.customParameters.length >= 5,
                          outlined: "",
                          dense: "",
                          hidden: _vm.isStaticParametersEnabled,
                          items: _vm.parameters,
                          "item-text": "fieldLabel",
                          "item-value": "fieldId",
                          "hide-no-data": "",
                          filter: _vm.filterWithGroups
                        },
                        on: {
                          change: function($event) {
                            return _vm.clearOperatorAndValue()
                          }
                        },
                        model: {
                          value: _vm.parameterSelected,
                          callback: function($$v) {
                            _vm.parameterSelected = $$v
                          },
                          expression: "parameterSelected"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "vertical-center", attrs: { cols: "3" } },
                    [
                      _vm.parameterSelected
                        ? _c("v-select", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              items: _vm.getOperators(),
                              "item-text": "text",
                              "item-value": "value",
                              rules: [
                                _vm.$data.services.validationService.getValidator(
                                  "required",
                                  "Operator"
                                )
                              ]
                            },
                            on: {
                              change: function($event) {
                                return _vm.clearAndSetupValue()
                              }
                            },
                            model: {
                              value: _vm.$data.parameterOperatorSelected,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$data,
                                  "parameterOperatorSelected",
                                  $$v
                                )
                              },
                              expression: "$data.parameterOperatorSelected"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "vertical-center" },
                    [
                      _vm.parameterSelected
                        ? _c(
                            _vm.components[
                              "SfCustomParam" + _vm.getDefinition().fieldType
                            ],
                            {
                              tag: "component",
                              attrs: {
                                fieldValue: _vm.parameterValueSelected,
                                fieldOperator:
                                  _vm.$data.parameterOperatorSelected,
                                fieldDefinition: _vm.getDefinition(),
                                orgId: _vm.orgId
                              },
                              on: {
                                "update:fieldValue": function($event) {
                                  _vm.parameterValueSelected = $event
                                },
                                "update:field-value": function($event) {
                                  _vm.parameterValueSelected = $event
                                },
                                "update:fieldOperator": function($event) {
                                  return _vm.$set(
                                    _vm.$data,
                                    "parameterOperatorSelected",
                                    $event
                                  )
                                },
                                "update:field-operator": function($event) {
                                  return _vm.$set(
                                    _vm.$data,
                                    "parameterOperatorSelected",
                                    $event
                                  )
                                }
                              }
                            }
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "vertical-center",
                      staticStyle: {
                        cursor: "pointer",
                        "padding-bottom": "35px"
                      },
                      attrs: { cols: "1" }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            disabled:
                              _vm.customParameters.length >= 5 ||
                              _vm.staticParameters
                          },
                          on: {
                            click: function($event) {
                              return _vm.addParam()
                            }
                          }
                        },
                        [_vm._v("mdi-plus")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            flex: "1",
            "padding-top": "10px",
            "overflow-y": "scroll",
            "overflow-x": "hidden"
          },
          style: { marginTop: _vm.isStaticParametersEnabled ? "0px" : "25px" }
        },
        _vm._l(_vm.customParameters, function(
          customParameter,
          customParameterIndex
        ) {
          return _c(
            "v-row",
            {
              key: customParameterIndex + customParameter.operator,
              staticClass: "ml-0 mr-0",
              staticStyle: { "margin-top": "-6px", "margin-bottom": "-6px" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "vertical-center ",
                  staticStyle: { "padding-bottom": "35px" }
                },
                [_vm._v(_vm._s(customParameter.fieldLabel))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "vertical-center  pt-0 pb-0",
                  attrs: { cols: "3" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      items: customParameter.operators,
                      rules: [
                        _vm.$data.services.validationService.getValidator(
                          "required",
                          "Operator"
                        )
                      ]
                    },
                    on: {
                      change: function($event) {
                        return _vm.clearAndSetupValueForStoredParameter(
                          customParameter,
                          customParameter.operator
                        )
                      }
                    },
                    model: {
                      value: customParameter.operator,
                      callback: function($$v) {
                        _vm.$set(customParameter, "operator", $$v)
                      },
                      expression: "customParameter.operator"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "vertical-center  pt-0 pb-0" },
                [
                  _c(
                    _vm.components["SfCustomParam" + customParameter.fieldType],
                    {
                      tag: "component",
                      attrs: {
                        fieldValue: customParameter.value,
                        fieldOperator: customParameter.operator,
                        fieldDefinition: customParameter,
                        orgId: _vm.orgId,
                        hideDetails: false
                      },
                      on: {
                        "update:fieldValue": function($event) {
                          return _vm.$set(customParameter, "value", $event)
                        },
                        "update:field-value": function($event) {
                          return _vm.$set(customParameter, "value", $event)
                        },
                        "update:fieldOperator": function($event) {
                          return _vm.$set(customParameter, "operator", $event)
                        },
                        "update:field-operator": function($event) {
                          return _vm.$set(customParameter, "operator", $event)
                        }
                      }
                    }
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "vertical-center",
                  staticStyle: { "padding-bottom": "35px" },
                  attrs: { cols: "1" }
                },
                [
                  (customParameter.fieldName !== "cancelType" &&
                    !_vm.staticParameters) ||
                  (_vm.customParameters.filter(function(cp) {
                    return cp.fieldName === "cancelType"
                  }).length > 1 &&
                    !_vm.staticParameters)
                    ? _c(
                        "v-icon",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function($event) {
                              return _vm.removeParam(customParameterIndex)
                            }
                          }
                        },
                        [_vm._v("mdi-minus")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }