import moment from 'moment';

class FormatDisplayService {
  public getRender(validatorName: string): (v: string | null) => string | null {
    return (v: string | null) => {
      switch (validatorName) {
        case 'toDate':
          return this.toDate(v);
        case 'toTime':
          return this.toTime(v);
        case 'toDateTime':
          return this.toDateTime(v);
        case 'toDateTimeSeconds':
          return this.toDateTimeSeconds(v);
        case 'toYesNo':
          return this.toYesNo(v);
        case 'toSecond':
          return this.toSecond(v);
        case 'toPhone':
          return this.toPhone(v);
        case 'toCurrency':
          return this.toCurrency(v);
        default:
          return v;
      }
    };
  }

  private toDate(v: string | null) {
    if (v === null) return v;
    const returnDate = moment.utc(v, 'YYYY-MM-DD HH:mm Z').format('MM/DD/YYYY');
    return returnDate === 'Invalid date' ? '' : returnDate;
  }

  private toTime(v: string | null) {
    if (v === null) return v;
    v = moment.utc(v).format('HH:mm');

    return v === 'Invalid date' ? '' : v;
  }

  private toDateTime(v: string | null) {
    if (v === null) return v;
    const returnDate = moment.utc(v, 'YYYY-MM-DD HH:mm Z').format('MM/DD/YYYY h:mm a');
    return returnDate === 'Invalid date' ? '' : returnDate;
  }

  private toDateTimeSeconds(v: string | null) {
    if (v === null) return v;
    const returnDate = moment.utc(v, 'YYYY-MM-DD HH:mm:ss Z').format('MM/DD/YYYY h:mm:ss a');
    return returnDate === 'Invalid date' ? '' : returnDate;
  }

  private toYesNo(v: string | null) {
    if (v === null) return v;
    return v ? 'Yes' : 'No';
  }

  private toPhone(v: string | null) {
    const cleaned = ('' + v).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return v;
  }

  private toSecond(v: string | null) {
    if (v) {
      return (parseFloat(v) / 1000).toFixed(3) + ' [s]';
    } else {
      return v;
    }
  }

  private toCurrency(v: string | null): string | null {
    if (v === null) return '';
    return parseFloat(v).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
}

export default new FormatDisplayService();
