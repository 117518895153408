





































import { Vue } from 'vue-property-decorator';
import { Product, Account } from '../interfaces';
import CustomDialog from '@/components/CustomDialog.vue';
import axios from '../services/api/Api.service';
import ValidationService from './../services/Validations.service';
import { AxiosError, AxiosResponse } from 'axios';

interface AccountSendI extends Account {
  REQUESTSTATUS: string | null;
}

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

const reSendCancellationProductEndpointUrl = '/uiapi/product/resend-cancellation';

export type CancellationTypeI = 'Products' | 'Accounts';

export default Vue.extend({
  name: 'ReSendCancellationAction',
  components: { CustomDialog },
  data: () => ({
    sendProcessed: false as boolean,
    ValidationService: ValidationService,
    item: null as null | Product[],
    openDialog: false,
    errors: [] as string[],
    messages: [] as string[],
    product: null as Product | null,
    processing: false,
    CustomDialog: {
      ref: 'CustomDialog',
    },
    options: [
      {
        text: '1st Notice - Dealer',
        value: 'Dealer',
      },
      {
        text: '1st Notice - Provider',
        value: 'Provider',
      },
      {
        text: '1st Notice - Dealer & Provider',
        value: 'Both',
      },
    ] as { text: string; value: string }[],
    selectedOption: null as 'Dealer' | 'Provider' | 'Both' | null,
  }),
  methods: {
    submitDataRequest(): boolean {
      // reset error box on send request
      this.error().clear();
      this.message().clear();

      let url = '';

      url = reSendCancellationProductEndpointUrl;

      this.processing = true;

      const requestObject = {
        id: this.product?.id,
        recipient: this.selectedOption,
      };

      axiosInstance
        .post<any, AxiosResponse<{ success: boolean; errors: string[]; messages: string[] }>>(url, requestObject)
        .then(result => {
          if (result.data.success) {
            this.message().set(result.data.messages);
            if (result.data.errors?.length > 0) {
              this.error().set(result.data.errors);
            }
            this.sendProcessed = true;
            this.$emit('success');
          } else {
            this.error().set(result.data.errors);
            this.$emit('error');
          }
          this.processing = false;
          this.getRemoteComponent('CustomDialog')
            .external()
            .open();
        })
        .catch((error: AxiosError) => {
          if (error.response?.data && (error.response.data as any)['message']) {
            this.error().add((error.response.data as any).message);
          } else {
            this.error().add(error.message);
          }
          this.processing = false;
          this.getRemoteComponent('CustomDialog')
            .external()
            .open();
        });
      return true;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    open(product: Product): void {
      this.sendProcessed = false;
      this.product = product;

      if (this.product.lastDealer2ndFollowupTransmissionId) {
        this.options.push({
          text: '2nd Notice – Dealer',
          value: 'Dealer 2nd Notice',
        });
      }

      if (this.product.lastDealerFollowupTransmissionExternalId) {
        this.options.push({
          text: 'Final Notice – Dealer',
          value: 'Dealer Final Notice',
        });
      }

      this.processing = false;
      this.selectedOption = null;
      this.error().clear();
      this.message().clear();
      this.getRemoteComponent('CustomDialog')
        .external()
        .open();
      this.openDialog = true;
    },
    error() {
      return {
        set: (errors: string[]) => {
          this.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      return {
        set: (errors: string[]) => {
          this.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.messages.push(error);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
  },
});
