




























































































































































































































































































































import { Vue } from 'vue-property-decorator';
import PageScrollLayout from '@/components/PageScrollLayout.vue';
import DataGrid from '@/components/DataGrid.vue';
import S3FileUpload from '@/components/S3FileUpload.vue';
import FormatDisplayService from '../services/FormatDisplay.service';
import axios from '../services/api/Api.service';
import { AxiosResponse } from 'axios';

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

/**
 *  Download Endpoint Url
 */
const downloadEndpointUrl = '/uiapi/data-upload/download/{id}';

/**
 *  Approve Endpoint Url
 */
const approveEndpointUrl = '/uiapi/data-upload/approve/{id}';

/**
 *  Metadata service URL
 */
const metadataEndpointUrl = '/uiapi/grids/data-upload';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/data-upload/search';

/**
 *  Metadata service URL
 */
const errorMetadataEndpointUrl = '/uiapi/grids/data-upload-error';
/**
 *  Data Search service URL
 */
const errorDataEndpointUrl = '/uiapi/data-upload-error/search';

export interface CustomDataGridHeaderI {
  text: string;
  value: string;
  parse?: (v: string | null) => string | null;
  sortable?: boolean;
}

export interface SummaryRecordI {
  dateTimeProcessed: string | null;
  externalId: string | null;
  fileType: 'Account' | 'Dealer' | 'Payment' | 'RefundQuote' | 'HistoricalDataUpload' | null;
  id: string | null;
  method: 'SFTP' | 'UI' | null;
  fileName: string | null;
  orgId: string | null;
  recordsError: number | null;
  recordsSuccessful: number | null;
  recordsTotal: number | null;
  sfid: null;
  status: 'Failed' | 'Success' | 'Success with Errors' | 'Processing' | 'Waiting for Approval' | 'Cancelled' | null;
  whoName: string | null;
  createdByName: string | null;
  approvedByName: string | null;
  approvedBy: { firstName: string; lastName: string } | null;
  approvedAt: string | null;
}

const emptySummaryRecord = {
  dateTimeProcessed: null,
  externalId: null,
  fileType: null,
  id: null,
  method: null,
  name: null,
  orgId: null,
  recordsError: null,
  recordsSuccessful: null,
  recordsTotal: null,
  sfid: null,
  status: null,
  who: null,
  createdByName: null,
  approvedByName: null,
  approvedBy: null,
  approvedAt: null,
};

export interface FixedCondition {
  fieldName: string;
  value: string;
  fieldType: 'string' | 'boolean' | 'enum' | 'checkbox';
  conditionComparatorOption: string;
}

export interface DataUploadPageI {
  lender: null | string;
  snackBarError: boolean;
  errors: string[];
  messages: string[];
  processing: boolean;
  FileUploadDialogOpen: boolean;
  buttonEnabled: boolean;
  detailRecord: SummaryRecordI;
  pageView: 'LIST' | 'DETAILS';
  errorGrid: {
    headersAutoParserMapping: boolean;
    apiDataUrl: string;
    apiMetadataUrl: string;
    ref: string;
    hideFilterSetup: boolean;
    forceTableHeight: number;
    ignoreResize: boolean;
    fixedConditionsPreventCall: boolean;
    fixedConditions: FixedCondition[];
    dataIdField: string;
  };
  customDataGrid: {
    listViewHide: boolean;
    headersAutoParserMapping: boolean;
    sortBy?: string;
    sortDesc?: boolean;
    apiDataUrl: string;
    apiMetadataUrl: string;
    ref: string;
  };
  s3FileUpload: {
    ref: string;
  };
}

export default Vue.extend({
  name: 'DataUpload',
  components: {
    PageScrollLayout,
    DataGrid,
    S3FileUpload,
  },
  /**
   * Page Component data object
   */
  data: (): DataUploadPageI => ({
    lender: null,
    snackBarError: false,
    errors: [],
    messages: [],
    processing: false,
    FileUploadDialogOpen: false,
    buttonEnabled: false,
    detailRecord: JSON.parse(JSON.stringify(emptySummaryRecord)) as SummaryRecordI,
    pageView: 'LIST',
    errorGrid: {
      headersAutoParserMapping: true,
      apiDataUrl: errorDataEndpointUrl,
      apiMetadataUrl: errorMetadataEndpointUrl,
      ref: 'errorGrid',
      hideFilterSetup: true,
      forceTableHeight: 300,
      ignoreResize: true,
      fixedConditionsPreventCall: true,
      fixedConditions: [],
      dataIdField: 'id',
    },
    customDataGrid: {
      listViewHide: false,
      headersAutoParserMapping: true,
      sortBy: 'dateTimeProcessed',
      sortDesc: true,
      apiDataUrl: dataEndpointUrl,
      apiMetadataUrl: metadataEndpointUrl,
      ref: 'customDataGrid',
    },
    s3FileUpload: {
      ref: 's3FileUploadInstance',
    },
  }),
  watch: {
    '$store.getters.lender': {
      handler() {
        this.lender = this.$store.getters.lender as string;
      },
    },
  },
  methods: {
    downloadFile() {
      if (!this.detailRecord) {
        return false;
      }
      this.processing = true;
      let url = downloadEndpointUrl.replace('{id}', this.detailRecord.id + '');
      return axiosInstance
        .get<Blob>(url, { responseType: 'blob' })
        .then(response => {
          if (response.data.size === 0) {
            this.snackBarError = true;
            this.processing = false;
          } else {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', this.detailRecord.fileName || 'summary.csv');
            document.body.appendChild(fileLink);
            fileLink.click();
            this.processing = false;
          }
        })
        .catch(() => {
          this.snackBarError = true;
          this.processing = false;
        });
    },
    sendApproveRequest(approved: boolean): boolean {
      this.error().clear();
      this.message().clear();
      if (!this.detailRecord) {
        return false;
      }
      this.processing = true;
      let url = approveEndpointUrl.replace('{id}', this.detailRecord.id + '');
      axiosInstance
        .post<
          any,
          AxiosResponse<{
            success: boolean;
            errors: string[];
            messages: string[];
            data?: {
              status: 'Processing' | 'Cancelled';
            };
          }>
        >(
          url,
          { approved },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(result => {
          if (result.data.success) {
            this.message().set(result.data.messages);
            this.detailRecord.status = result.data.data?.status ? result.data.data.status : null;
          } else {
            this.error().set(result.data.errors);
          }
          this.processing = false;
        })
        .catch((error: string) => {
          this.error().add(error);
          this.processing = false;
        });
      return true;
    },
    buttonRenderCondition(): boolean {
      if (
        this.pageView === 'DETAILS' &&
        this.detailRecord.fileType === 'RefundQuote' &&
        this.detailRecord.status === 'Waiting for Approval'
      ) {
        return true;
      }
      return false;
    },
    openFileUploadDialog(): void {
      this.getRemoteComponent('s3FileUploadInstance')
        .remoteAccess()
        .resetForm();
      this.FileUploadDialogOpen = true;
    },
    fileUploadCallback(success: boolean): void {
      if (success) {
        this.FileUploadDialogOpen = false;
        this.getRemoteComponent('customDataGrid').submitDataRequest();
      }
    },
    getRemoteComponent(componentRef: string): HTMLFormElement {
      if (!this.$refs[componentRef]) {
        console.log('Component: ' + componentRef + ' not found in references : ', this.$refs);
      }
      return this.$refs[componentRef] as HTMLFormElement;
    },
    goBackToList() {
      this.processing = false;
      this.error().clear();
      this.message().clear();
      this.pageView = 'LIST';
    },
    error() {
      return {
        set: (errors: string[]) => {
          this.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      return {
        set: (errors: string[]) => {
          this.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.messages.push(error);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
  },
  computed: {
    approvedBy() {
      if (this.detailRecord.approvedByName) {
        try {
          return this.detailRecord.approvedBy?.firstName + ' ' + this.detailRecord.approvedBy?.lastName;
        } catch (e) {
          return this.detailRecord.approvedByName;
        }
      }
      return '';
    },
    buttonActions() {
      return [
        {
          name: 'Upload file',
          icon: 'mdi-upload',
          callback: () => {
            this.openFileUploadDialog();
          },
        },
      ];
    },
    /**
     * Defined action for GridComponent
     */
    actions() {
      return [
        {
          icon: 'mdi-table-cog',
          tooltip: 'Data Upload Details',
          /**
           *  Callback function for action click
           *  params:
           *  - rowData - grid data for selected row (Object)
           *  - rowIndex - grid store index for selected row (in case of delete or other manipulation on data) (number)
           *  - gridData - grid store data (Array of Objects)
           */
          callback: (rowData: SummaryRecordI): void => {
            // Put data from grid row to editor
            this.detailRecord = Object.assign({}, JSON.parse(JSON.stringify(rowData)));
            this.detailRecord.dateTimeProcessed = FormatDisplayService.getRender('toDateTime')(
              this.detailRecord.dateTimeProcessed,
            );
            this.detailRecord.approvedAt = FormatDisplayService.getRender('toDateTime')(this.detailRecord.approvedAt);
            this.pageView = 'DETAILS';
            if (this.detailRecord.externalId) {
              this.errorGrid.fixedConditions.length = 0;
              this.errorGrid.fixedConditions.push({
                fieldName: 'summaryExternalId',
                value: this.detailRecord.externalId,
                fieldType: 'string',
                conditionComparatorOption: 'equal',
              });
            }
          },
        },
      ];
    },
  },
  /**
   * Method will run when comoponent is created
   */
  created() {},
  mounted: function() {},
});
