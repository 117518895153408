import axios from '../services/api/Api.service';
import { AxiosResponse } from 'axios';
import { SerchResponse } from '../../../src/domain/grid/interfaces';
import { ProviderForm } from '@/interfaces';

/**
 * Axios Instance for api communication
 */
const axiosInstance = new axios().getClient();
export interface EnumItemI {
  text: string;
  value: string | boolean | null;
}

class ProviderPageService {
  protected ProviderStates: EnumItemI[];
  protected ProviderRefundRequestMethod: EnumItemI[];
  protected ProviderCountry: EnumItemI[];
  protected CustomCancellationForm: EnumItemI[];
  protected RefundAPI: EnumItemI[];
  protected EmailFormat: EnumItemI[];

  constructor() {
    (this.CustomCancellationForm = []),
      (this.ProviderCountry = [
        {
          text: 'United States',
          value: 'US',
        },
        {
          text: 'Canada',
          value: 'CA',
        },
      ]),
      (this.ProviderRefundRequestMethod = [
        { text: 'API', value: 'API' },
        { text: 'Email', value: 'Email' },
        { text: 'Provider Access', value: 'Provider Access' },
        { text: 'Phone', value: 'Phone' },
      ]),
      (this.RefundAPI = [
        { text: 'None', value: null },
        { text: 'Safe-Guard', value: 'Safe-Guard' },
        { text: 'FiAdmin', value: 'FiAdmin' },
        { text: 'APCO', value: 'APCO' },
        { text: 'Allstate Quote', value: 'Allstate' },
        { text: 'Premier Dealer Services Quote', value: 'Premier Dealer Services' },
        { text: 'Protective Quote', value: 'Protective Quote' },
        { text: 'Assurant', value: 'Assurant' },
        { text: 'PRco API', value: 'PRco API' },
        { text: 'Zurich', value: 'Zurich' },
      ]),
      (this.EmailFormat = [
        { text: 'Attachments', value: 'Attachments' },
        { text: 'Links', value: 'Links' },
      ]),
      (this.ProviderStates = [
        {
          text: 'Alabama',
          value: 'AL',
        },
        {
          text: 'Alaska',
          value: 'AK',
        },
        {
          text: 'American Samoa',
          value: 'AS',
        },
        {
          text: 'American Forces Americas',
          value: 'AA',
        },
        {
          text: 'American Forces Europe',
          value: 'AE',
        },
        {
          text: 'American Forces Pacific',
          value: 'AP',
        },
        {
          text: 'Arizona',
          value: 'AZ',
        },
        {
          text: 'Arkansas',
          value: 'AR',
        },
        {
          text: 'California',
          value: 'CA',
        },
        {
          text: 'Colorado',
          value: 'CO',
        },
        {
          text: 'Connecticut',
          value: 'CT',
        },
        {
          text: 'Delaware',
          value: 'DE',
        },
        {
          text: 'District Of Columbia',
          value: 'DC',
        },
        {
          text: 'Federated States Of Micronesia',
          value: 'FM',
        },
        {
          text: 'Florida',
          value: 'FL',
        },
        {
          text: 'Georgia',
          value: 'GA',
        },
        {
          text: 'Guam',
          value: 'GU',
        },
        {
          text: 'Hawaii',
          value: 'HI',
        },
        {
          text: 'Idaho',
          value: 'ID',
        },
        {
          text: 'Illinois',
          value: 'IL',
        },
        {
          text: 'Indiana',
          value: 'IN',
        },
        {
          text: 'Iowa',
          value: 'IA',
        },
        {
          text: 'Kansas',
          value: 'KS',
        },
        {
          text: 'Kentucky',
          value: 'KY',
        },
        {
          text: 'Louisiana',
          value: 'LA',
        },
        {
          text: 'Maine',
          value: 'ME',
        },
        {
          text: 'Marshall Islands',
          value: 'MH',
        },
        {
          text: 'Maryland',
          value: 'MD',
        },
        {
          text: 'Massachusetts',
          value: 'MA',
        },
        {
          text: 'Michigan',
          value: 'MI',
        },
        {
          text: 'Minnesota',
          value: 'MN',
        },
        {
          text: 'Mississippi',
          value: 'MS',
        },
        {
          text: 'Missouri',
          value: 'MO',
        },
        {
          text: 'Montana',
          value: 'MT',
        },
        {
          text: 'Nebraska',
          value: 'NE',
        },
        {
          text: 'Nevada',
          value: 'NV',
        },
        {
          text: 'New Hampshire',
          value: 'NH',
        },
        {
          text: 'New Jersey',
          value: 'NJ',
        },
        {
          text: 'New Mexico',
          value: 'NM',
        },
        {
          text: 'New York',
          value: 'NY',
        },
        {
          text: 'North Carolina',
          value: 'NC',
        },
        {
          text: 'North Dakota',
          value: 'ND',
        },
        {
          text: 'Northern Mariana Islands',
          value: 'MP',
        },
        {
          text: 'Ohio',
          value: 'OH',
        },
        {
          text: 'Oklahoma',
          value: 'OK',
        },
        {
          text: 'Oregon',
          value: 'OR',
        },
        {
          text: 'Palau',
          value: 'PW',
        },
        {
          text: 'Pennsylvania',
          value: 'PA',
        },
        {
          text: 'Puerto Rico',
          value: 'PR',
        },
        {
          text: 'Rhode Island',
          value: 'RI',
        },
        {
          text: 'South Carolina',
          value: 'SC',
        },
        {
          text: 'South Dakota',
          value: 'SD',
        },
        {
          text: 'Tennessee',
          value: 'TN',
        },
        {
          text: 'Texas',
          value: 'TX',
        },
        {
          text: 'Utah',
          value: 'UT',
        },
        {
          text: 'Vermont',
          value: 'VT',
        },
        {
          text: 'Virgin Islands',
          value: 'VI',
        },
        {
          text: 'Virginia',
          value: 'VA',
        },
        {
          text: 'Washington',
          value: 'WA',
        },
        {
          text: 'West Virginia',
          value: 'WV',
        },
        {
          text: 'Wisconsin',
          value: 'WI',
        },
        {
          text: 'Wyoming',
          value: 'WY',
        },
        {
          text: 'Alberta',
          value: 'AB',
        },
        {
          text: 'British Columbia',
          value: 'BC',
        },
        {
          text: 'Manitoba',
          value: 'MB',
        },
        {
          text: 'New Brunswick',
          value: 'NB',
        },
        {
          text: 'Newfoundland and Labrador',
          value: 'NL',
        },
        {
          text: 'Northwest Territories',
          value: 'NT',
        },
        {
          text: 'Nova Scotia',
          value: 'NS',
        },
        {
          text: 'Nunavut',
          value: 'NU',
        },
        {
          text: 'Ontario',
          value: 'ON',
        },
        {
          text: 'Prince Edward Island',
          value: 'PE',
        },
        {
          text: 'Quebec',
          value: 'QC',
        },
        {
          text: 'Saskatchewan',
          value: 'SK',
        },
        {
          text: 'Yukon',
          value: 'YT',
        },
      ]);
  }
  async getApiItemsCustomCancellationForm(options?: { providerId: string | null } | undefined): Promise<void> {
    const url = '/uiapi/provider-form/search';
    this.CustomCancellationForm.length = 0;
    return new Promise((resolve, reject) => {
      axiosInstance
        .post<any, AxiosResponse<SerchResponse<ProviderForm[]>>>(
          url,
          {
            filterCode: 'All',
            page: 1,
            rowsPerPage: 999,
            conditions: [
              { fieldName: 'providerId', type: 'String', comparatorOption: 'equal', value: options?.providerId },
            ],
            sort: [],
          },
          {},
        )
        .then(results => {
          results.data.data.forEach(formItem => {
            this.CustomCancellationForm.push({
              text: formItem.name,
              value: formItem.id as string | null,
            });
          });
          resolve();
        })
        // .then(results => {
        //   resolve(
        //     results.data.data.map(formItem => {
        //       const item = {
        //         text: formItem.name,
        //         value: formItem.id as string,
        //       };
        //       this.CustomCancellationForm.push(item);
        //       return item;
        //     }),
        //   );
        // })
        .catch(error => {
          reject(error);
          return [];
        });
    });
  }
  /**
   *
   * @param EnumID number
   * @param Concat boolean
   * @param forceReject boolean
   */
  async getItems(
    EnumID:
      | 'ProviderStates'
      | 'ProviderCountry'
      | 'ProviderRefundRequestMethod'
      | 'CustomCancellationForm'
      | 'RefundAPI'
      | 'EmailFormat',
    Concat?: boolean | undefined,
    forceReject?: boolean | undefined,
    options?: { providerId: string | null } | undefined,
  ): Promise<EnumItemI[]> {
    // Update list of forms on getItems will be passed as reference to output
    if (EnumID === 'CustomCancellationForm') {
      this.getApiItemsCustomCancellationForm(options);
    }
    return new Promise((resolve, reject) => {
      if (forceReject) {
        reject('Provider Page Service Error');
      } else {
        setTimeout(() => {
          if (Concat) {
            resolve(
              this[EnumID].map((item: EnumItemI) => {
                return {
                  text: '(' + item.value + ') ' + item.text,
                  value: item.value,
                };
              }) as EnumItemI[],
            );
          } else {
            resolve(this[EnumID] as EnumItemI[]);
          }
        }, 10);
      }
    });
  }
}

export default new ProviderPageService();
