var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v(" " + _vm._s(this.label) + " "),
      _c(
        "v-row",
        { staticClass: "mt-1" },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { dense: "", label: "Latitude" },
                on: {
                  input: function($event) {
                    return _vm.handleInput()
                  }
                },
                model: {
                  value:
                    _vm.content[
                      _vm.definition.apiName.replace("__c", "__Latitude__s")
                    ],
                  callback: function($$v) {
                    _vm.$set(
                      _vm.content,
                      _vm.definition.apiName.replace("__c", "__Latitude__s"),
                      $$v
                    )
                  },
                  expression:
                    "content[definition.apiName.replace('__c', '__Latitude__s')]"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { dense: "", label: "Longitude" },
                on: {
                  input: function($event) {
                    return _vm.handleInput()
                  }
                },
                model: {
                  value:
                    _vm.content[
                      _vm.definition.apiName.replace("__c", "__Longitude__s")
                    ],
                  callback: function($$v) {
                    _vm.$set(
                      _vm.content,
                      _vm.definition.apiName.replace("__c", "__Longitude__s"),
                      $$v
                    )
                  },
                  expression:
                    "content[definition.apiName.replace('__c', '__Longitude__s')]"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }