































import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import ValidationService from '@/services/Validations.service';
import SfEnumField from '../SfEnumField.vue';

@Component({
  components: { SfEnumField },
})
export default class SfCustomParamDate extends Vue {
  @PropSync('fieldValue') fieldValueSync!: any;
  @PropSync('fieldOperator') fieldOperatorSync!: any;
  @PropSync('fieldDefinition') fieldDefinitionSync!: any;
  @Prop([String]) readonly orgId: boolean | undefined;
  @Prop([Boolean]) readonly readonly: boolean | undefined;
  @Prop([Boolean]) readonly disabled: boolean | undefined;
  @Prop([Boolean]) readonly hideDetails: boolean | undefined;
  metadataEnumsMap: any;
  services: any;
  data() {
    return {
      ready: false,
      services: {
        validationService: ValidationService,
      },
    };
  }
  async mounted() {
    this.$data.ready = true;
  }
  listItems(list: any) {
    return list.map((o: any) => o.value);
  }
}
