var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      directives: [
        { name: "show", rawName: "v-show", value: true, expression: "true" }
      ],
      attrs: {
        slot: "append",
        top: _vm.top,
        bottom: _vm.bottom,
        left: _vm.left,
        right: _vm.right,
        "max-width": _vm.maxWidth,
        "nudge-top": _vm.nudgeTop,
        "nudge-bottom": _vm.nudgeBottom,
        "nudge-right": _vm.nudgeRight,
        "nudge-left": _vm.nudgeLeft,
        color: _vm.color,
        "content-class": _vm.contentCls
      },
      slot: "append",
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _c(
                  "div",
                  _vm._g(
                    _vm._b(
                      { staticClass: "custom-tooltip-container" },
                      "div",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [_vm._t("component")],
                  2
                )
              ]
            }
          }
        ],
        null,
        true
      )
    },
    [_vm._t("message")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }