































































import { Vue } from 'vue-property-decorator';
import EventBus from '@/events/EventBus';

interface SlotConfigI {
  visible: boolean;
}

interface PageLayoutDataI {
  // Here some new layouts can be added but need refer to template modification
  state: boolean | null;
}

export default Vue.extend({
  name: 'PageScrollLayout',
  components: {},
  /**
   * Page Layout Component data object
   */
  data: (): PageLayoutDataI => ({
    state: null,
  }),
  methods: {
    contentScrollHandler(e: any) {
      EventBus.$emit('PAGE_LAYOUT_CONTENT_SCROLL', {
        scrollY: e.currentTarget.scrollTop,
        pageName: this.$props.pageName,
      });
    },
  },
  computed: {
    contentStyle() {
      return {
        flex: 2,
        overflow: 'hidden',
        overflowY: this.contentScroll ? 'auto' : 'hidden',
        position: 'relative',
        display: 'flex',
        flexFlow: 'column',
      };
    },
    hasToolbarSlots() {
      // @ts-ignore: Unreachable code error
      return this.$slots.toolbar || this.$slots.options || this.$slots.buttons;
    },
    hasFooter() {
      // @ts-ignore: Unreachable code error
      return this.$slots.footer !== undefined;
    },
  },
  props: {
    // Page icon to left
    pageIcon: {
      type: String,
      default: 'mdi-icon',
    },
    // Page name top left next to page icon
    pageName: {
      type: String,
    },
    // Can add overlay on content space
    contentOverlay: {
      type: Boolean,
      default: false,
    },
    showToolbarSlots: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    contentScroll: {
      type: Boolean,
      default: true,
    },
    contentList: {
      type: Array,
      default: () => {
        return ['content'];
      },
    },
    contentSelected: {
      type: String,
      default: 'content',
    },
  },
});
