var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: {
            "lazy-validation": "",
            disabled: _vm.overlay || _vm.disabled,
            readonly: _vm.readonly
          },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "pb-3", attrs: { fluid: "" } },
            [
              _c("prompt-dialog", {
                attrs: {
                  processing: _vm.overlay,
                  confirmHandler: this.reprocess,
                  open: _vm.reprocessPrompt,
                  headerTemplate: "Reprocess",
                  textTemplate:
                    "This action will send a new cancellation letter to the parties selected. Are you sure you want to continue?"
                },
                on: {
                  closePrompt: function($event) {
                    _vm.reprocessPrompt = false
                  }
                }
              }),
              _c("prompt-dialog", {
                attrs: {
                  processing: _vm.overlay,
                  confirmHandler: this.finalizeProduct,
                  open: _vm.finalizePrompt,
                  headerTemplate: "Finalize",
                  textTemplate:
                    "Are you sure that the product needs no additional work and, do you want to finalize it?"
                },
                on: {
                  closePrompt: function($event) {
                    _vm.finalizePrompt = false
                  }
                }
              }),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showRefresh,
                              expression: "showRefresh"
                            }
                          ],
                          staticClass: "ml-1",
                          staticStyle: { float: "left" },
                          attrs: {
                            color: "primary",
                            elevation: "2",
                            medium: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.recordDataRefresh()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-refresh")])],
                        1
                      ),
                      _vm.editButtonsReSendCancellation()
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                color: "primary",
                                elevation: "2",
                                disabled: !_vm.isEditedRecordLenderActive
                              },
                              on: {
                                click: function($event) {
                                  return _vm.reSendCancellation()
                                }
                              }
                            },
                            [_vm._v("Resend Cancellation ")]
                          )
                        : _vm._e(),
                      _vm.editButtonsSendCancellationOn()
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                color: "primary",
                                elevation: "2",
                                disabled: !_vm.isEditedRecordLenderActive
                              },
                              on: {
                                click: function($event) {
                                  return _vm.sendCancellation()
                                }
                              }
                            },
                            [_vm._v("Send Cancellation ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            color: "primary",
                            elevation: "2",
                            disabled: !_vm.isReQuoteButtonActive
                          },
                          on: {
                            click: function($event) {
                              return _vm.reQuoteProduct()
                            }
                          }
                        },
                        [_vm._v("Request Re-Quote ")]
                      ),
                      _vm.editButtonsRepreocessOn()
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "primary", elevation: "2" },
                              on: {
                                click: function($event) {
                                  return _vm.showReprocessPrompt()
                                }
                              }
                            },
                            [_vm._v("Reprocess ")]
                          )
                        : _vm._e(),
                      _vm.editButtonsFinalize()
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                color: "primary",
                                elevation: "2",
                                disabled: !_vm.isEditedRecordLenderActive
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showFinalizePrompt()
                                }
                              }
                            },
                            [_vm._v("Finalize ")]
                          )
                        : _vm._e(),
                      _vm.getFinalizedState
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                elevation: "2",
                                disabled: !_vm.isEditedRecordLenderActive
                              },
                              on: {
                                click: function($event) {
                                  return _vm.restoreProcessingStatus()
                                }
                              }
                            },
                            [_vm._v("Restore ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-stepper",
                        {
                          staticClass: "mb-8",
                          attrs: {
                            value:
                              _vm.chevronStatuses.indexOf(
                                _vm.productProcessingStatusesMap[
                                  _vm.editedRecord.productProcessingStatus
                                ]
                              ) + 1
                          }
                        },
                        [
                          _c(
                            "v-stepper-header",
                            [
                              _vm._l(_vm.chevronStatuses, function(item, i) {
                                return _c(
                                  "v-stepper-step",
                                  {
                                    key: item + i,
                                    attrs: {
                                      step: i + 1,
                                      complete:
                                        i <
                                        _vm.chevronStatuses.indexOf(
                                          _vm.productProcessingStatusesMap[
                                            _vm.editedRecord
                                              .productProcessingStatus
                                          ]
                                        )
                                          ? true
                                          : false
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(item) + " ")]
                                )
                              }),
                              _c("v-divider")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.messages.length > 0,
                                      expression: "messages.length > 0"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              ref: "RefProductDetailsMessages",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "line-height": "15px"
                                              },
                                              attrs: {
                                                type: "info",
                                                border: "left",
                                                text: "",
                                                outlined: "",
                                                dense: "",
                                                autocomplete: "none"
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _c("b", [_vm._v("Messages:")])
                                              ]),
                                              _vm._l(_vm.messages, function(
                                                message,
                                                messageIndex
                                              ) {
                                                return _c(
                                                  "div",
                                                  { key: messageIndex },
                                                  [
                                                    _vm._v(
                                                      "* " + _vm._s(message)
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.editedRecord.messages &&
                              _vm.editedRecord.messages.refund_details_messages
                                ? _c(
                                    "v-row",
                                    { staticStyle: { "margin-top": "0" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _vm.refundDetailsHideTopSection ===
                                          false
                                            ? _c("MessagesCard", {
                                                ref:
                                                  "RefProductDetailsRefundMessages",
                                                attrs: {
                                                  messages:
                                                    _vm.editedRecord.messages
                                                      .refund_details_messages,
                                                  title: "Refund Details"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editedRecord.messages &&
                              _vm.editedRecord.messages
                                .purchase_odometer_messages
                                ? _c(
                                    "v-row",
                                    { staticStyle: { "margin-top": "0" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "0",
                                            "padding-top": "0"
                                          }
                                        },
                                        [
                                          _c("MessagesCard", {
                                            ref:
                                              "RefProductDetailsOdometerMessages",
                                            attrs: {
                                              messages:
                                                _vm.editedRecord.messages
                                                  .purchase_odometer_messages,
                                              title: "Missing Data"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: Object.keys(_vm.infos).length > 0,
                                      expression:
                                        "Object.keys(infos).length > 0"
                                    }
                                  ],
                                  staticStyle: { "margin-top": "0" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                                "line-height": "15px"
                                              },
                                              attrs: {
                                                border: "left",
                                                text: "",
                                                outlined: "",
                                                dense: "",
                                                autocomplete: "none"
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Missing fields:")
                                                ])
                                              ]),
                                              _vm.infos.product
                                                ? _c("span", [
                                                    _vm._v("Product: ")
                                                  ])
                                                : _vm._e(),
                                              _vm._l(
                                                _vm.infos.product,
                                                function(
                                                  product,
                                                  productIndex
                                                ) {
                                                  return _c(
                                                    "span",
                                                    { key: product.field },
                                                    [
                                                      _vm._v(_vm._s(product)),
                                                      productIndex !==
                                                      _vm.infos.product.length -
                                                        1
                                                        ? _c("span", [
                                                            _vm._v(" | ")
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                }
                                              ),
                                              _c("br"),
                                              _vm.infos.account
                                                ? _c("span", [
                                                    _vm._v("Account: ")
                                                  ])
                                                : _vm._e(),
                                              _vm._l(
                                                _vm.infos.account,
                                                function(
                                                  account,
                                                  accountIndex
                                                ) {
                                                  return _c(
                                                    "span",
                                                    { key: account.field },
                                                    [
                                                      _vm._v(_vm._s(account)),
                                                      accountIndex !==
                                                      _vm.infos.account.length -
                                                        1
                                                        ? _c("span", [
                                                            _vm._v(" | ")
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.length > 0,
                                      expression: "errors.length > 0"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _vm.refundDetailsHideTopSection ===
                                          false
                                            ? _c(
                                                "v-alert",
                                                {
                                                  ref:
                                                    "RefProductDetailsErrors",
                                                  staticStyle: {
                                                    "font-weight": "strong",
                                                    "font-size": "14px",
                                                    "line-height": "15px"
                                                  },
                                                  attrs: {
                                                    type: "error",
                                                    border: "left",
                                                    text: "",
                                                    outlined: "",
                                                    dense: ""
                                                  }
                                                },
                                                [
                                                  _c("div", [
                                                    _c("b", [_vm._v("Errors:")])
                                                  ]),
                                                  _vm._l(_vm.errors, function(
                                                    error,
                                                    errorIndex
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      { key: errorIndex },
                                                      [
                                                        _vm._v(
                                                          "* " + _vm._s(error)
                                                        )
                                                      ]
                                                    )
                                                  })
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: _vm.disabledForUser(),
                                                  items:
                                                    _vm.productProcessingStatusItems,
                                                  label:
                                                    "Product Processing Status"
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .productProcessingStatus,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "productProcessingStatus",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.productProcessingStatus"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  autocomplete: "none",
                                                  label: "Cancel Type",
                                                  rules: [],
                                                  items: _vm.productMetadataEnumsMap.get(
                                                    "cancelType"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord.cancelType,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "cancelType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.cancelType"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  label: "Product Type",
                                                  items: _vm._f(
                                                    "showPossibleTypes"
                                                  )(
                                                    _vm.productMetadataEnumsMap.get(
                                                      "productType"
                                                    ),
                                                    _vm.otherAccountProducts,
                                                    _vm.editedRecord.id
                                                  ),
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "required",
                                                      "Product Type"
                                                    ),
                                                    _vm
                                                      .validFunc()
                                                      .checkProductType()
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .productType,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "productType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.productType"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  type: "number",
                                                  label: "Contract Term",
                                                  rules: []
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .contractTerm,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "contractTerm",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.contractTerm"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  type: "number",
                                                  label: "Contract Miles",
                                                  rules: []
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .contractMiles,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "contractMiles",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.contractMiles"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-date-picker", {
                                                attrs: {
                                                  label: "Contract Date",
                                                  readonly: _vm.readonly,
                                                  rules: [
                                                    _vm
                                                      .validFunc()
                                                      .checkIfContractDateIsSmallerOrEqualToAccountVehicleInContractDate()
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .contractDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "contractDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.contractDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-tooltip", {
                                                attrs: { top: true },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "component",
                                                    fn: function() {
                                                      return [
                                                        _c("v-select", {
                                                          attrs: {
                                                            dense: "",
                                                            items:
                                                              _vm.contractTermTypeItems,
                                                            label:
                                                              "Contract Term Type"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedRecord
                                                                .contractTermType,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedRecord,
                                                                "contractTermType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "editedRecord.contractTermType"
                                                          }
                                                        })
                                                      ]
                                                    },
                                                    proxy: true
                                                  },
                                                  {
                                                    key: "message",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "13px",
                                                              "line-height":
                                                                "14px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Contract Term Type, defines which date value the contracted term months is measured against to determine the contracts date of expiration or to calculate the total amount of time and value of the unused contract term when the contract is cancelled prior to expiration. "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-date-picker", {
                                                attrs: {
                                                  label: "Lender Cancel Date",
                                                  readonly: _vm.readonly
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .lenderCancelDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "lenderCancelDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.lenderCancelDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  type: "number",
                                                  label: "Contract Price",
                                                  rules: [
                                                    _vm
                                                      .validFunc()
                                                      .validateContractPrice(),
                                                    _vm.ValidationService.getValidator(
                                                      "maxFloatValue",
                                                      "Contract Price",
                                                      { maxValue: 99999.99 }
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .contractPrice,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "contractPrice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.contractPrice"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-date-picker", {
                                                attrs: {
                                                  label:
                                                    "Cancellation Sent Date",
                                                  readonly: true
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .cancelSendDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "cancelSendDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.cancelSendDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: true,
                                                  label: "Age (days)",
                                                  value: _vm.getProductAge,
                                                  rules: []
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  disabled: _vm.disabledForUser(),
                                                  dense: "",
                                                  autocomplete: "none",
                                                  label:
                                                    "Servicing System Update"
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .servicingSystemUpdate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "servicingSystemUpdate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.servicingSystemUpdate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  label: "Provider Contract #",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Submitted Provider Name",
                                                      {
                                                        length: 50
                                                      }
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .providerContract,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "providerContract",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.providerContract"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  label: "Provider Form ID",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Provider Form ID",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .providerFormId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "providerFormId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.providerFormId"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("search-input-field", {
                                                attrs: {
                                                  fieldProps: {
                                                    value: _vm.editedRecord
                                                      .provider
                                                      ? _vm.editedRecord
                                                          .provider.name
                                                      : "",
                                                    readonly: true,
                                                    label: "Provider",
                                                    dense: true
                                                  },
                                                  actions: [],
                                                  apiMetadataUrl:
                                                    "/uiapi/grids/provider",
                                                  apiDataUrl:
                                                    "/uiapi/provider/search",
                                                  title: "Search Provider",
                                                  columns: [
                                                    {
                                                      text: "Name",
                                                      value: "name"
                                                    }
                                                  ],
                                                  selectEvent:
                                                    _vm.selectProvider
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: true,
                                                  label:
                                                    "Submitted Provider Name"
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .providerName,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "providerName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.providerName"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-text-field",
                                                {
                                                  directives: [
                                                    {
                                                      name: "mask",
                                                      rawName: "v-mask",
                                                      value: "(###) ###-####",
                                                      expression:
                                                        "'(###) ###-####'"
                                                    }
                                                  ],
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    dense: "",
                                                    readonly: "",
                                                    label:
                                                      "Provider Phone Number",
                                                    value: _vm.providerPhoneNumber(),
                                                    disabled: ""
                                                  }
                                                },
                                                [
                                                  _vm.providerPhoneNumber() !==
                                                  null
                                                    ? _c(
                                                        "div",
                                                        {
                                                          attrs: {
                                                            slot: "append"
                                                          },
                                                          slot: "append"
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href:
                                                                  "tel: +1" +
                                                                  _vm.providerPhoneNumber()
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    cursor:
                                                                      "pointer"
                                                                  },
                                                                  attrs: {
                                                                    title:
                                                                      "Call +1 " +
                                                                      _vm.formatDisplayService.getRender(
                                                                        "toPhone"
                                                                      )(
                                                                        _vm.providerPhoneNumber()
                                                                      ),
                                                                    color:
                                                                      "green"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-phone"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  type: "number",
                                                  disabled: "",
                                                  label: "Remaining Months"
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .monthsRemaining,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "monthsRemaining",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.monthsRemaining"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-tooltip", {
                                                attrs: { top: true },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "component",
                                                    fn: function() {
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            dense: "",
                                                            type: "number",
                                                            disabled: "",
                                                            label:
                                                              "Remaining Miles",
                                                            rules: []
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedRecord
                                                                .milesRemaining,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedRecord,
                                                                "milesRemaining",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "editedRecord.milesRemaining"
                                                          }
                                                        })
                                                      ]
                                                    },
                                                    proxy: true
                                                  },
                                                  {
                                                    key: "message",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "13px",
                                                              "line-height":
                                                                "14px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Calculated difference between True Mileage (Contract Miles) or Additive Mileage (Contract Miles + Purchase Odometer) and Cancel Odometer as determined by Lender at time of cancellation. "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-tooltip", {
                                                attrs: { top: true },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "component",
                                                    fn: function() {
                                                      return [
                                                        _c("v-text-field", {
                                                          directives: [
                                                            {
                                                              name: "mask",
                                                              rawName: "v-mask",
                                                              value:
                                                                "############",
                                                              expression:
                                                                "'############'"
                                                            }
                                                          ],
                                                          attrs: {
                                                            dense: "",
                                                            autocomplete:
                                                              "none",
                                                            label:
                                                              "Purchase Odometer",
                                                            rules: [
                                                              _vm.ValidationService.getValidator(
                                                                "number",
                                                                "Purchase Odometer"
                                                              ),
                                                              _vm.ValidationService.getValidator(
                                                                "maxValue",
                                                                "Purchase Odometer",
                                                                {
                                                                  maxValue: 999999
                                                                }
                                                              ),
                                                              _vm
                                                                .validFunc()
                                                                .checkIfPurchaseOdometerIsGreaterThanAccountCancelOdometer()
                                                            ]
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedRecord
                                                                .purchaseOdometer,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedRecord,
                                                                "purchaseOdometer",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "editedRecord.purchaseOdometer"
                                                          }
                                                        })
                                                      ]
                                                    },
                                                    proxy: true
                                                  },
                                                  {
                                                    key: "message",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "13px",
                                                              "line-height":
                                                                "14px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Purchase Odometer relates to the odometer reading at the time of product contract purchase. Entering the Purchase Odometer when evaluating a cancellation refund tolerance for mileage based products can provide a more accurate variance check. "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-tooltip", {
                                                attrs: { top: true },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "component",
                                                    fn: function() {
                                                      return [
                                                        _c("v-select", {
                                                          attrs: {
                                                            dense: "",
                                                            label:
                                                              "Contract Mileage Type",
                                                            items: [
                                                              "True Mileage",
                                                              "Additive Mileage"
                                                            ]
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedRecord
                                                                .contractMileageType,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedRecord,
                                                                "contractMileageType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "editedRecord.contractMileageType"
                                                          }
                                                        })
                                                      ]
                                                    },
                                                    proxy: true
                                                  },
                                                  {
                                                    key: "message",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "13px",
                                                              "line-height":
                                                                "14px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Contract Mileage Type, defines how the remaining expiration mileage will be determined, or to calculate the remaining mileage when obtaining and validating refund data. ‘True Mileage’ refers to the stated Contract Miles, where as ‘Additive Mileage’ refers to the total mileage after adding the Contract Miles and the Purchase Odometer. "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-date-picker", {
                                                attrs: {
                                                  label: "Send Delay Date",
                                                  disabled: true
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .productSendDelayDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "productSendDelayDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.productSendDelayDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("search-input-field", {
                                                attrs: {
                                                  readonly:
                                                    _vm.editedRecord.id &&
                                                    _vm.editedRecord
                                                      .accountNumber
                                                      ? true
                                                      : false,
                                                  fieldProps: {
                                                    value: _vm.editedRecord
                                                      .account
                                                      ? _vm.editedRecord.account
                                                          .accountNumber
                                                      : "",
                                                    readonly: true,
                                                    label: "Account",
                                                    rules: [
                                                      _vm.ValidationService.getValidator(
                                                        "required",
                                                        "Account"
                                                      )
                                                    ],
                                                    dense: true
                                                  },
                                                  apiMetadataUrl:
                                                    "/uiapi/grids/account",
                                                  apiDataUrl:
                                                    "/uiapi/account/search",
                                                  title: "Search Account",
                                                  searchField: "accountNumber",
                                                  actions: [],
                                                  columns: [
                                                    {
                                                      text: "Account #",
                                                      value: "accountNumber"
                                                    },
                                                    {
                                                      text: "Name",
                                                      value: "name"
                                                    }
                                                  ],
                                                  selectEvent:
                                                    _vm.selectAccount,
                                                  additionalConditions: [
                                                    {
                                                      conditionComparatorOption:
                                                        "contain",
                                                      fieldName: "orgId",
                                                      fieldType: "String",
                                                      value:
                                                        _vm.editedRecord.orgId
                                                    }
                                                  ]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label:
                                                    "Lender Representative",
                                                  value:
                                                    _vm.editedRecord
                                                      .lenderRepresentative
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label:
                                                    "Lender Representative Email",
                                                  value:
                                                    _vm.editedRecord
                                                      .lenderRepresentativeSubmittedEmail
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-date-picker", {
                                                attrs: {
                                                  label:
                                                    "Original Cancellation Sent Date",
                                                  readonly: _vm.disabledForUser()
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .originalCancellationSentDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "originalCancellationSentDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.originalCancellationSentDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-datetime-picker", {
                                                ref: "finalizedDateObject",
                                                attrs: {
                                                  dense: "",
                                                  "hide-details": "",
                                                  readonly: true,
                                                  disabled: true,
                                                  "visible-control": true,
                                                  label: "Finalized Date",
                                                  datetime: _vm.editedRecord
                                                    .finalizedDate
                                                    ? _vm
                                                        .moment(
                                                          _vm.editedRecord
                                                            .finalizedDate
                                                        )
                                                        .utc()
                                                        .format(
                                                          "YYYY-MM-DD HH:mm"
                                                        )
                                                    : ""
                                                },
                                                on: {
                                                  inputUTC:
                                                    _vm.updateFinalizedData
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("custom-fields-form", {
                        attrs: {
                          orgId: _vm.editedRecord.orgId,
                          objectType: "product"
                        },
                        model: {
                          value: _vm.editedRecord.customFieldsValues,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.editedRecord,
                              "customFieldsValues",
                              $$v
                            )
                          },
                          expression: "editedRecord.customFieldsValues"
                        }
                      }),
                      _c(
                        "v-card",
                        { staticClass: "mt-8" },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-row",
                                [_c("v-col", [_vm._v(" Refund Details ")])],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.editedRecord.messages &&
                          _vm.editedRecord.messages.refund_details_messages &&
                          _vm.$props.refundDetailsShow
                            ? _c(
                                "v-row",
                                { staticStyle: { "margin-top": "0" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pl-5 pr-5" },
                                    [
                                      _c("MessagesCard", {
                                        attrs: {
                                          messages:
                                            _vm.editedRecord.messages
                                              .refund_details_messages
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: _vm.disabledForUser(),
                                                  readonly: _vm.disabledForUser(),
                                                  label:
                                                    "Refund Processing Status",
                                                  items: _vm.productMetadataEnumsMap.get(
                                                    "refundProcessingStatus"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .refundProcessingStatus,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "refundProcessingStatus",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.refundProcessingStatus"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  type: "number",
                                                  label:
                                                    "Gross Refund Estimate",
                                                  rules: []
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .grossRefundEstimate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "grossRefundEstimate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.grossRefundEstimate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  type: "number",
                                                  label: "Net Refund Estimate",
                                                  rules: []
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .netRefundEstimate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "netRefundEstimate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.netRefundEstimate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  type: "number",
                                                  label:
                                                    "Net Calculated Refund",
                                                  rules: []
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .netCalculatedRefund,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "netCalculatedRefund",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.netCalculatedRefund"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  type: "number",
                                                  label:
                                                    "Cancellation Percentage",
                                                  rules: [
                                                    _vm
                                                      .validFunc()
                                                      .validateCancellationPercentage()
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .cancellationPercentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "cancellationPercentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.cancellationPercentage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  type: "number",
                                                  label: "Cancellation Fee",
                                                  rules: [
                                                    _vm
                                                      .validFunc()
                                                      .validateCancellationFee()
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .cancellationFee,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "cancellationFee",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.cancellationFee"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  type: "number",
                                                  label: "Refund Due",
                                                  rules: [
                                                    _vm
                                                      .validFunc()
                                                      .validateRefundDue(),
                                                    _vm.ValidationService.getValidator(
                                                      "maxFloatValue",
                                                      "Refund Due",
                                                      { maxValue: 99999.99 }
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord.refundDue,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "refundDue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.refundDue"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  type: "number",
                                                  label: "Refund Variance",
                                                  rules: []
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .refundVariance,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "refundVariance",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.refundVariance"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  type: "text",
                                                  label:
                                                    "Fee Compliance Status",
                                                  rules: []
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .feeRuleResult,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "feeRuleResult",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.feeRuleResult"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-date-picker", {
                                                attrs: {
                                                  label: "Refund Received Date",
                                                  readonly: _vm.readonly
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .refundReceivedDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "refundReceivedDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.refundReceivedDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  label:
                                                    "Refund Calculation Method",
                                                  items: [
                                                    "Pro Rata",
                                                    "Rule of 78's"
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .refundCalculationMethod,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "refundCalculationMethod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.refundCalculationMethod"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  label:
                                                    "Provider Contract Status",
                                                  items: [
                                                    "Active",
                                                    "Cancelled",
                                                    "Expired",
                                                    "Rejected",
                                                    "Voided"
                                                  ]
                                                },
                                                on: {
                                                  change: function($event) {
                                                    _vm.editedRecord.providerRejectedReason =
                                                      ""
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .providerContractStatus,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "providerContractStatus",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.providerContractStatus"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  dense: "",
                                                  label:
                                                    "Provider Refund Details",
                                                  rules:
                                                    _vm.editedRecord
                                                      .providerRejectedReason ===
                                                    "Other"
                                                      ? [
                                                          _vm.ValidationService.getValidator(
                                                            "required",
                                                            "Provider Refund Details"
                                                          ),
                                                          _vm
                                                            .validFunc()
                                                            .validateProviderRefundDetails()
                                                        ]
                                                      : [
                                                          _vm
                                                            .validFunc()
                                                            .validateProviderRefundDetails()
                                                        ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .providerRefundDetails,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "providerRefundDetails",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.providerRefundDetails"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  rules:
                                                    _vm.editedRecord
                                                      .providerContractStatus ===
                                                    "Rejected"
                                                      ? [
                                                          _vm.ValidationService.getValidator(
                                                            "required",
                                                            "Provider Rejected Reason"
                                                          )
                                                        ]
                                                      : [],
                                                  disabled:
                                                    _vm.editedRecord
                                                      .providerContractStatus !=
                                                    "Rejected",
                                                  label:
                                                    "Provider Rejected Reason",
                                                  items: [
                                                    "No Contract Found",
                                                    "Claim",
                                                    "Claim Pending",
                                                    "Payment Not Received",
                                                    "Non-Cancellable Product",
                                                    "Other",
                                                    "Previously Cancelled"
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .providerRejectedReason,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "providerRejectedReason",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.providerRejectedReason"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-date-picker", {
                                                attrs: {
                                                  label: "Provider Cancel Date",
                                                  rules:
                                                    _vm.editedRecord
                                                      .providerContractStatus ===
                                                    "Cancelled"
                                                      ? [
                                                          _vm.ValidationService.getValidator(
                                                            "required",
                                                            "Provider Cancel Date",
                                                            {
                                                              customFailMessage:
                                                                "Required when Provider Contract Status is Cancelled"
                                                            }
                                                          )
                                                        ]
                                                      : [],
                                                  readonly: _vm.readonly
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .providerProcessedCancelDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "providerProcessedCancelDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.providerProcessedCancelDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  type: "number",
                                                  label:
                                                    "Payment to Refund Variance",
                                                  rules: []
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .refundtoPaymentVariance,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "refundtoPaymentVariance",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.refundtoPaymentVariance"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  type: "text",
                                                  label:
                                                    "Fee Compliance Details",
                                                  rules: []
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .feeRuleDetail,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "feeRuleDetail",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.feeRuleDetail"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "mt-8" },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-row",
                                [_c("v-col", [_vm._v(" Tolerance ")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  disabled: "",
                                                  dense: "",
                                                  label: "Term Tolerance",
                                                  value:
                                                    _vm.editedRecord
                                                      .termTolerance
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  disabled: "",
                                                  dense: "",
                                                  label: "Miles Tolerance",
                                                  value:
                                                    _vm.editedRecord
                                                      .milesTolerance
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  disabled: "",
                                                  dense: "",
                                                  label: "Refund Tolerance",
                                                  value:
                                                    _vm.editedRecord
                                                      .refundTolerance
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  disabled: "",
                                                  dense: "",
                                                  label: "Payment Tolerance",
                                                  value:
                                                    _vm.editedRecord
                                                      .paymentTolerance
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label: "Override Reason",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Override Reason",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .overrideReason,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "overrideReason",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.overrideReason"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("custom-datetime-picker", {
                                                attrs: {
                                                  dense: "",
                                                  "hide-details": "",
                                                  disabled: "",
                                                  readonly: "",
                                                  "visible-control": true,
                                                  label: "Override Date/Time",
                                                  datetime: _vm.editedRecord
                                                    .overrideDatetime
                                                    ? _vm
                                                        .moment(
                                                          _vm.editedRecord
                                                            .overrideDatetime
                                                        )
                                                        .utc()
                                                        .format(
                                                          "YYYY-MM-DD HH:mm"
                                                        )
                                                    : ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  disabled: "",
                                                  dense: "",
                                                  label:
                                                    "Term Tolerance Status",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Term Tolerance Status",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ],
                                                  value:
                                                    _vm.editedRecord
                                                      .termToleranceStatus
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  disabled: "",
                                                  dense: "",
                                                  label:
                                                    "Miles Tolerance Status",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Miles Tolerance Status",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ],
                                                  value:
                                                    _vm.editedRecord
                                                      .milesToleranceStatus
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  disabled: "",
                                                  dense: "",
                                                  label:
                                                    "Refund Tolerance Status",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Refund Tolerance Status",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ],
                                                  value:
                                                    _vm.editedRecord
                                                      .refundToleranceStatus
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  disabled: "",
                                                  dense: "",
                                                  label:
                                                    "Payment Tolerance Status",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Payment Tolerance Status",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ],
                                                  value:
                                                    _vm.editedRecord
                                                      .paymentToleranceStatus
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label: "Override User",
                                                  value:
                                                    (_vm.editedRecord
                                                      .overrideUserData &&
                                                      _vm.editedRecord
                                                        .overrideUserData
                                                        .username) ||
                                                    _vm.editedRecord
                                                      .overrideUser
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "mt-8" },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-row",
                                [_c("v-col", [_vm._v(" Summary ")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  type: "number",
                                                  label:
                                                    "Total Payment Received",
                                                  rules: []
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .totalPaymentReceived,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "totalPaymentReceived",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.totalPaymentReceived"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  type: "number",
                                                  label:
                                                    "Total Consumer Refund Amount",
                                                  rules: []
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .totalConsumerRefundAmount,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "totalConsumerRefundAmount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.totalConsumerRefundAmount"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "mt-8" },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _vm._v(" Transmission Summary ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label:
                                                    "Last Dealer Transmission Status",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Last Dealer Transmission Status",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .lastDealerTransmissionStatus,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "lastDealerTransmissionStatus",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.lastDealerTransmissionStatus"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label:
                                                    "Last Dealer Transmission Date",
                                                  value: _vm.formatDisplayService.getRender(
                                                    "toDateTime"
                                                  )(
                                                    _vm.editedRecord
                                                      .lastDealerTransmissionDate
                                                  ),
                                                  rules: []
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label:
                                                    "Last Dealer Transmission Method",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Last Dealer Transmission Method",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .lastDealerTransmissionMethod,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "lastDealerTransmissionMethod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.lastDealerTransmissionMethod"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label:
                                                    "Last Dealer Transmission Reason",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Last Dealer Transmission Reason",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .lastDealerTransmissionReason,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "lastDealerTransmissionReason",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.lastDealerTransmissionReason"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label:
                                                    "Last Provider Transmission Status",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Last Dealer Transmission Status",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .lastProviderTransmissionStatus,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "lastProviderTransmissionStatus",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.lastProviderTransmissionStatus"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label:
                                                    "Last Provider Transmission Date",
                                                  value: _vm.formatDisplayService.getRender(
                                                    "toDateTime"
                                                  )(
                                                    _vm.editedRecord
                                                      .lastProviderTransmissionDate
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label:
                                                    "Last Provider Transmission Method",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Last Provider Transmission Method",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .lastProviderTransmissionMethod,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "lastProviderTransmissionMethod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.lastProviderTransmissionMethod"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  label:
                                                    "Last Provider Transmission Reason",
                                                  rules: [
                                                    _vm.ValidationService.getValidator(
                                                      "maxLength",
                                                      "Last Provider Transmission Reason",
                                                      {
                                                        length: 255
                                                      }
                                                    )
                                                  ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedRecord
                                                      .lastProviderTransmissionReason,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedRecord,
                                                      "lastProviderTransmissionReason",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedRecord.lastProviderTransmissionReason"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "mt-8" },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-row",
                                [_c("v-col", [_vm._v(" System Information ")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  label: "Created By",
                                                  disabled: "",
                                                  value: _vm._f(
                                                    "formatUserDateTime"
                                                  )(
                                                    _vm.editedRecord
                                                      .createdByName,
                                                    _vm.editedRecord
                                                      .dateTimeCreated
                                                  ),
                                                  hint:
                                                    "User Name, Date & Time",
                                                  "persistent-hint": ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  label: "Last Modified By",
                                                  disabled: "",
                                                  value: _vm._f(
                                                    "formatUserDateTime"
                                                  )(
                                                    _vm.editedRecord
                                                      .updatedByName,
                                                    _vm.editedRecord
                                                      .dateTimeUpdated
                                                  ),
                                                  hint:
                                                    "User Name, Date & Time",
                                                  "persistent-hint": ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.createMode
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            {
                              style: { marginTop: _vm.infoBoxTopMargin + "px" }
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-tabs",
                                    {
                                      attrs: { value: _vm.defaultTab },
                                      model: {
                                        value: _vm.selectedTab,
                                        callback: function($$v) {
                                          _vm.selectedTab = $$v
                                        },
                                        expression: "selectedTab"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-tab",
                                        { attrs: { href: "#notes" } },
                                        [_vm._v("Notes")]
                                      ),
                                      _c(
                                        "v-tab-item",
                                        {
                                          staticClass: "mt-5",
                                          attrs: { value: "notes" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "row",
                                                position: "relative",
                                                "padding-bottom": "5px",
                                                height: "calc(100vh - 480px)"
                                              }
                                            },
                                            [
                                              _vm.selectedTab === "notes"
                                                ? _c("notes-component", {
                                                    ref:
                                                      "ProductNotesComponent",
                                                    attrs: {
                                                      "fixed-conditions":
                                                        typeof _vm.editedRecord
                                                          .orgId === "string" &&
                                                        _vm.editedRecord
                                                          .orgId !== ""
                                                          ? [
                                                              {
                                                                fieldName:
                                                                  "orgId",
                                                                value:
                                                                  _vm
                                                                    .editedRecord
                                                                    .orgId,
                                                                fieldType:
                                                                  "string",
                                                                conditionComparatorOption:
                                                                  "equal"
                                                              },
                                                              {
                                                                fieldName:
                                                                  "productExternalId",
                                                                value:
                                                                  _vm
                                                                    .editedRecord
                                                                    .externalId,
                                                                fieldType:
                                                                  "string",
                                                                conditionComparatorOption:
                                                                  "equal"
                                                              }
                                                            ]
                                                          : []
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-tab",
                                        { attrs: { href: "#related" } },
                                        [_vm._v("Related")]
                                      ),
                                      _c(
                                        "v-tab-item",
                                        {
                                          staticClass: "mt-5",
                                          attrs: { value: "related" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _vm.editedRecord.id &&
                                              _vm.selectedTab === "related"
                                                ? _c("compliance-tracker", {
                                                    attrs: {
                                                      "product-record":
                                                        _vm.editedRecord,
                                                      "product-record-backup":
                                                        _vm.editedRecordSnapshot
                                                    },
                                                    on: {
                                                      "update:productRecord": function(
                                                        $event
                                                      ) {
                                                        _vm.editedRecord = $event
                                                      },
                                                      "update:product-record": function(
                                                        $event
                                                      ) {
                                                        _vm.editedRecord = $event
                                                      },
                                                      "update:productRecordBackup": function(
                                                        $event
                                                      ) {
                                                        _vm.editedRecordSnapshot = $event
                                                      },
                                                      "update:product-record-backup": function(
                                                        $event
                                                      ) {
                                                        _vm.editedRecordSnapshot = $event
                                                      },
                                                      saveData: function(
                                                        $event
                                                      ) {
                                                        return _vm.updateProduct(
                                                          false
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "15px",
                                                    "padding-top": "10px",
                                                    "padding-bottom": "0px",
                                                    background: "#EEE"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Refund Calculations (" +
                                                      _vm._s(
                                                        _vm.refundCalculationsAmount
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              ),
                                              _c("data-grid", {
                                                ref: "RefundCalculationsGrid",
                                                attrs: {
                                                  hideFilterSetup: "",
                                                  hideFooter: true,
                                                  forceTableHeight: 100,
                                                  apiDataUrl:
                                                    "/uiapi/refund-calculations/search",
                                                  apiMetadataUrl:
                                                    "/uiapi/grids/refund-calculations",
                                                  "fixed-conditions":
                                                    typeof _vm.editedRecord
                                                      .orgId === "string" &&
                                                    _vm.editedRecord.orgId !==
                                                      ""
                                                      ? [
                                                          {
                                                            fieldName: "orgId",
                                                            value:
                                                              _vm.editedRecord
                                                                .orgId,
                                                            fieldType: "string",
                                                            conditionComparatorOption:
                                                              "equal"
                                                          },
                                                          {
                                                            fieldName:
                                                              "productExternalId",
                                                            value:
                                                              _vm.editedRecord
                                                                .externalId,
                                                            fieldType: "string",
                                                            conditionComparatorOption:
                                                              "equal"
                                                          }
                                                        ]
                                                      : [],
                                                  "fixed-conditions-prevent-call": true
                                                },
                                                on: {
                                                  onGridItemsCount:
                                                    _vm.onRefundCalculationsGridItemsCount
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "15px",
                                                    "padding-top": "10px",
                                                    "padding-bottom": "0px",
                                                    background: "#EEE"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Transmissions (" +
                                                      _vm._s(
                                                        _vm.transmissionAmount
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              ),
                                              _c("data-grid", {
                                                ref: "TransmissionsGrid",
                                                attrs: {
                                                  hideFilterSetup: "",
                                                  forceTableHeight: 200,
                                                  actions:
                                                    _vm.transmissionActions,
                                                  previews:
                                                    _vm.transmissionPreviews,
                                                  headerColumnsShow: [
                                                    {
                                                      text: "Actions",
                                                      value: "Actions"
                                                    },
                                                    {
                                                      text: "Date",
                                                      value: "date",
                                                      parse: "toDateTime",
                                                      cellClass: "no-wrap"
                                                    },
                                                    {
                                                      text: "Party",
                                                      value: "party"
                                                    },
                                                    {
                                                      text: "Delivery Method",
                                                      value: "delivery"
                                                    },
                                                    {
                                                      text: "Status",
                                                      value: "status"
                                                    },
                                                    {
                                                      text: "Detail",
                                                      value: "eventType"
                                                    },
                                                    {
                                                      text: "Failure Reason",
                                                      value: "reason"
                                                    }
                                                  ],
                                                  apiDataUrl:
                                                    "/uiapi/transmission/search",
                                                  apiMetadataUrl:
                                                    "/uiapi/grids/transmission",
                                                  "fixed-conditions":
                                                    typeof _vm.editedRecord
                                                      .orgId === "string" &&
                                                    _vm.editedRecord.orgId !==
                                                      ""
                                                      ? [
                                                          {
                                                            fieldName: "orgId",
                                                            value:
                                                              _vm.editedRecord
                                                                .orgId,
                                                            fieldType: "string",
                                                            conditionComparatorOption:
                                                              "equal"
                                                          },
                                                          {
                                                            fieldName:
                                                              "transmissionProducts.productExternalId",
                                                            value:
                                                              _vm.editedRecord
                                                                .externalId,
                                                            fieldType: "string",
                                                            conditionComparatorOption:
                                                              "equal"
                                                          }
                                                        ]
                                                      : [],
                                                  "fixed-conditions-prevent-call": true
                                                },
                                                on: {
                                                  onGridItemsCount:
                                                    _vm.onTransmissionGridItemsCount
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "15px",
                                                    "padding-top": "10px",
                                                    "padding-bottom": "0px",
                                                    background: "#EEE"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Lender Receivables (" +
                                                      _vm._s(
                                                        _vm.productPaymentAmount
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              ),
                                              _c("data-grid", {
                                                ref: "ProductPaymentsGrid",
                                                attrs: {
                                                  showFilterSets: false,
                                                  filterButtonHide: "",
                                                  listViewHide: "",
                                                  defaultQueue: "Product",
                                                  "button-actions":
                                                    _vm.buttonProductPaymentActions,
                                                  actions:
                                                    _vm.productPaymentActions,
                                                  forceTableHeight: 200,
                                                  apiDataUrl:
                                                    "/uiapi/product-payment/search",
                                                  apiMetadataUrl:
                                                    "/uiapi/grids/product-payment",
                                                  "fixed-conditions":
                                                    typeof _vm.editedRecord
                                                      .orgId === "string" &&
                                                    _vm.editedRecord.orgId !==
                                                      ""
                                                      ? [
                                                          {
                                                            fieldName: "orgId",
                                                            value:
                                                              _vm.editedRecord
                                                                .orgId,
                                                            fieldType: "string",
                                                            conditionComparatorOption:
                                                              "equal"
                                                          },
                                                          {
                                                            fieldName:
                                                              "productExternalId",
                                                            value:
                                                              _vm.editedRecord
                                                                .externalId,
                                                            fieldType: "string",
                                                            conditionComparatorOption:
                                                              "equal"
                                                          }
                                                        ]
                                                      : [],
                                                  "fixed-conditions-prevent-call": true
                                                },
                                                on: {
                                                  onGridItemsCount:
                                                    _vm.onProductPaymentsonGridItemsCount
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "15px",
                                                    "padding-top": "10px",
                                                    "padding-bottom": "0px",
                                                    background: "#EEE"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-space-between"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Consumer Refunds (" +
                                                          _vm._s(
                                                            _vm.consumerRefundAmount
                                                          ) +
                                                          ")"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c("data-grid", {
                                                ref: "ConsumerRefundsGrid",
                                                attrs: {
                                                  showFilterSets: false,
                                                  filterButtonHide: "",
                                                  listViewHide: "",
                                                  forceTableHeight: 200,
                                                  apiDataUrl:
                                                    "/uiapi/consumer-refund/search",
                                                  apiMetadataUrl:
                                                    "/uiapi/grids/consumer-refund",
                                                  defaultQueue: "Product",
                                                  actions:
                                                    _vm.consumerRefundActions,
                                                  "button-actions":
                                                    _vm.buttonConsumerRefundActions,
                                                  "fixed-conditions":
                                                    typeof _vm.editedRecord
                                                      .orgId === "string" &&
                                                    _vm.editedRecord.orgId !==
                                                      ""
                                                      ? [
                                                          {
                                                            fieldName: "orgId",
                                                            value:
                                                              _vm.editedRecord
                                                                .orgId,
                                                            fieldType: "string",
                                                            conditionComparatorOption:
                                                              "equal"
                                                          },
                                                          {
                                                            fieldName:
                                                              "productExternalId",
                                                            value:
                                                              _vm.editedRecord
                                                                .externalId,
                                                            fieldType: "string",
                                                            conditionComparatorOption:
                                                              "equal"
                                                          }
                                                        ]
                                                      : [],
                                                  "fixed-conditions-prevent-call": true
                                                },
                                                on: {
                                                  onGridItemsCount:
                                                    _vm.onConsumerRefundGridItemsCount
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-tab",
                                        { attrs: { href: "#account" } },
                                        [_vm._v("Account")]
                                      ),
                                      _c(
                                        "v-tab-item",
                                        { attrs: { value: "account" } },
                                        [
                                          _c(
                                            "v-container",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "Account #",
                                                                    disabled:
                                                                      "",
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .accountNumber
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dense: "",
                                                                  items: _vm.accountMetadataEnumsMap.get(
                                                                    "businessChannel"
                                                                  ),
                                                                  label:
                                                                    "Business Channel"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .editedRecord
                                                                      .account
                                                                      .businessChannel,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .editedRecord
                                                                        .account,
                                                                      "businessChannel",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "editedRecord.account.businessChannel"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "search-input-field",
                                                                {
                                                                  attrs: {
                                                                    fieldProps: {
                                                                      value: _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .dealer
                                                                        ? _vm
                                                                            .editedRecord
                                                                            .account
                                                                            .dealer
                                                                            .name
                                                                        : "",
                                                                      readonly: true,
                                                                      label:
                                                                        "Dealer",
                                                                      dense: true
                                                                    },
                                                                    additionalConditions: [
                                                                      {
                                                                        conditionComparatorOption:
                                                                          "contain",
                                                                        fieldName:
                                                                          "orgId",
                                                                        fieldType:
                                                                          "String",
                                                                        value:
                                                                          _vm
                                                                            .editedRecord
                                                                            .orgId
                                                                      }
                                                                    ],
                                                                    apiMetadataUrl:
                                                                      "/uiapi/grids/dealer",
                                                                    apiDataUrl:
                                                                      "/uiapi/dealer/search",
                                                                    title:
                                                                      "Search Dealer",
                                                                    columns: [
                                                                      {
                                                                        text:
                                                                          "Actions",
                                                                        value:
                                                                          "Actions"
                                                                      },
                                                                      {
                                                                        text:
                                                                          "Dealer Number",
                                                                        value:
                                                                          "dealerNumber"
                                                                      },
                                                                      {
                                                                        text:
                                                                          "Name",
                                                                        value:
                                                                          "name"
                                                                      }
                                                                    ],
                                                                    selectEvent:
                                                                      _vm.selectDealer
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "VIN",
                                                                    rules: [
                                                                      _vm.ValidationService.getValidator(
                                                                        "vinValidation",
                                                                        "VIN"
                                                                      )
                                                                    ]
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .VIN,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "VIN",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.VIN"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "Model",
                                                                    rules: [
                                                                      _vm.ValidationService.getValidator(
                                                                        "maxLength",
                                                                        "Model",
                                                                        {
                                                                          length: 50
                                                                        }
                                                                      )
                                                                    ]
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .model,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "model",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.model"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    type:
                                                                      "number",
                                                                    dense: "",
                                                                    label:
                                                                      "Cancel Odometer",
                                                                    rules: [
                                                                      _vm
                                                                        .validFunc()
                                                                        .checkIfCancelOdometerIsLessThanPurchaseOdometer()
                                                                    ]
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .cancelOdometer,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "cancelOdometer",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.cancelOdometer"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "Customer First Name"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .customerFirstName,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "customerFirstName",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.customerFirstName"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "Customer Last Name"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .customerLastName,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "customerLastName",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.customerLastName"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "Customer City"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .customerCity,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "customerCity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.customerCity"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dense: "",
                                                                  items: _vm.accountMetadataEnumsMap.get(
                                                                    "dealer.dealerState"
                                                                  ),
                                                                  label:
                                                                    "Customer State"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .editedRecord
                                                                      .account
                                                                      .customerState,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .editedRecord
                                                                        .account,
                                                                      "customerState",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "editedRecord.account.customerState"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dense: "",
                                                                  items:
                                                                    _vm.countryItems,
                                                                  label:
                                                                    "Customer Country"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .editedRecord
                                                                      .account
                                                                      .customerCountry,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .editedRecord
                                                                        .account,
                                                                      "customerCountry",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "editedRecord.account.customerCountry"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    disabled:
                                                                      "",
                                                                    label:
                                                                      "Created By",
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .createdByName
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    disabled:
                                                                      "",
                                                                    label:
                                                                      "Last Modified By",
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .updatedByName
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dense: "",
                                                                  disabled: "",
                                                                  items: _vm.accountMetadataEnumsMap.get(
                                                                    "accountProcessingStatus"
                                                                  ),
                                                                  label:
                                                                    "Account Processing Status",
                                                                  value:
                                                                    _vm
                                                                      .editedRecord
                                                                      .account
                                                                      .accountProcessingStatus
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    disabled:
                                                                      "",
                                                                    label:
                                                                      "Send Delay Date"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .productSendDelayDate,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.editedRecord,
                                                                        "productSendDelayDate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.productSendDelayDate"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dense: "",
                                                                  disabled: "",
                                                                  items: _vm.accountMetadataEnumsMap.get(
                                                                    "dealer.dealerState"
                                                                  ),
                                                                  label:
                                                                    "Dealer State",
                                                                  value: _vm
                                                                    .editedRecord
                                                                    .account
                                                                    .dealer
                                                                    ? _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .dealer
                                                                        .dealerState
                                                                    : ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "Make",
                                                                    rules: [
                                                                      _vm.ValidationService.getValidator(
                                                                        "maxLength",
                                                                        "Make",
                                                                        {
                                                                          length: 50
                                                                        }
                                                                      )
                                                                    ]
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .make,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "make",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.make"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    type:
                                                                      "number",
                                                                    label:
                                                                      "Year",
                                                                    rules: [
                                                                      _vm.ValidationService.getValidator(
                                                                        "maxLength",
                                                                        "Year",
                                                                        {
                                                                          length: 4
                                                                        }
                                                                      )
                                                                    ]
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .year,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "year",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.year"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "custom-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: true
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "component",
                                                                        fn: function() {
                                                                          return [
                                                                            _c(
                                                                              "custom-date-picker",
                                                                              {
                                                                                attrs: {
                                                                                  label:
                                                                                    "Vehicle In-Service Date",
                                                                                  rules: [
                                                                                    _vm
                                                                                      .validFunc()
                                                                                      .checkIfVehicleInServiceDateIsEqualToOrSmallerThanProductsContractDate()
                                                                                  ]
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm
                                                                                      .editedRecord
                                                                                      .account
                                                                                      .vehicleInServiceDate,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .editedRecord
                                                                                        .account,
                                                                                      "vehicleInServiceDate",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "editedRecord.account.vehicleInServiceDate"
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        },
                                                                        proxy: true
                                                                      },
                                                                      {
                                                                        key:
                                                                          "message",
                                                                        fn: function() {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle: {
                                                                                  "font-size":
                                                                                    "13px",
                                                                                  "line-height":
                                                                                    "14px"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " Vehicle In-Service Date relates the original sale date of the vehicle to the first owner or original DMV registration date. Entering the Vehicle In-Service Date when evaluating a cancellation refund tolerance for mileage based products can provide a more accurate variance check. "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        },
                                                                        proxy: true
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1016533860
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "Customer Business Name",
                                                                    rules: [
                                                                      _vm.ValidationService.getValidator(
                                                                        "maxLength",
                                                                        "coCustomer Business Name",
                                                                        {
                                                                          length: 255
                                                                        }
                                                                      )
                                                                    ]
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .customerBusinessName,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "customerBusinessName",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.customerBusinessName"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "CoCustomer First Name",
                                                                    rules: [
                                                                      _vm.ValidationService.getValidator(
                                                                        "maxLength",
                                                                        "coCustomer First Name",
                                                                        {
                                                                          length: 255
                                                                        }
                                                                      )
                                                                    ]
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .coCustomerFirstName,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "coCustomerFirstName",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.coCustomerFirstName"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "CoCustomer Last Name",
                                                                    rules: [
                                                                      _vm.ValidationService.getValidator(
                                                                        "maxLength",
                                                                        "coCustomer Last Name",
                                                                        {
                                                                          length: 255
                                                                        }
                                                                      )
                                                                    ]
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .coCustomerLastName,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "coCustomerLastName",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.coCustomerLastName"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "Customer Street",
                                                                    rules: [
                                                                      _vm.ValidationService.getValidator(
                                                                        "maxLength",
                                                                        "Customer Street",
                                                                        {
                                                                          length: 150
                                                                        }
                                                                      )
                                                                    ]
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .customerStreet,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "customerStreet",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.customerStreet"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label:
                                                                      "Customer ZIP",
                                                                    rules: [
                                                                      _vm.ValidationService.getValidator(
                                                                        "maxLength",
                                                                        "Customer ZIP",
                                                                        {
                                                                          length: 10
                                                                        }
                                                                      )
                                                                    ]
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .customerZIP,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .editedRecord
                                                                          .account,
                                                                        "customerZIP",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "editedRecord.account.customerZIP"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    disabled:
                                                                      "",
                                                                    label:
                                                                      "Owner Id"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    disabled:
                                                                      "",
                                                                    label:
                                                                      "Created Date",
                                                                    value: _vm.formatDisplayService.getRender(
                                                                      "toDateTime"
                                                                    )(
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .dateTimeCreated
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    disabled:
                                                                      "",
                                                                    label:
                                                                      "Last Modified Date",
                                                                    value: _vm.formatDisplayService.getRender(
                                                                      "toDateTime"
                                                                    )(
                                                                      _vm
                                                                        .editedRecord
                                                                        .account
                                                                        .dateTimeUpdated
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tab",
                                        { attrs: { href: "#audit" } },
                                        [_vm._v("Audit History")]
                                      ),
                                      _c(
                                        "v-tab-item",
                                        {
                                          staticClass: "mt-5",
                                          attrs: { value: "audit" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "row",
                                                position: "relative",
                                                "padding-bottom": "5px",
                                                height: "calc(100vh - 480px)"
                                              }
                                            },
                                            [
                                              _c("audit-history-component", {
                                                ref: "AuditHistoryComponent",
                                                attrs: {
                                                  orgId: _vm.editedRecord.orgId,
                                                  externalId:
                                                    _vm.editedRecord.externalId,
                                                  auditType: "product",
                                                  footerHeight: 564
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-snackbar",
                {
                  attrs: {
                    centered: "",
                    color: "success",
                    text: "",
                    timeout: 3000
                  },
                  model: {
                    value: _vm.snackBarSuccess,
                    callback: function($$v) {
                      _vm.snackBarSuccess = $$v
                    },
                    expression: "snackBarSuccess"
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "success" } }, [
                    _vm._v(" mdi-check-bold ")
                  ]),
                  _vm._v(" Completed successfully ")
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    centered: "",
                    color: "error",
                    text: "",
                    timeout: 2500
                  },
                  model: {
                    value: _vm.snackBarError,
                    callback: function($$v) {
                      _vm.snackBarError = $$v
                    },
                    expression: "snackBarError"
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "error" } }, [
                    _vm._v(" mdi-alert-circle-outline ")
                  ]),
                  _vm._v(" Save Failed ")
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    centered: "",
                    color: "error",
                    text: "",
                    timeout: 2500
                  },
                  model: {
                    value: _vm.snackBarValidateError,
                    callback: function($$v) {
                      _vm.snackBarValidateError = $$v
                    },
                    expression: "snackBarValidateError"
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "error" } }, [
                    _vm._v(" mdi-alert-circle-outline ")
                  ]),
                  _vm._v(" Validation Failed ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "custom-dialog",
        { ref: "TransmissionDetailsDialog", attrs: { "max-width": "50%" } },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" Edit Transmission ")
          ]),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c("transmission-details", {
                ref: "transmissionDetails",
                attrs: {
                  "pass-product-name": _vm.editedRecord.name,
                  "pass-edited-record": _vm.editedRecordTransmissionDetails
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "buttons" }, slot: "buttons" },
            [
              _c(
                "v-btn",
                {
                  attrs: { elevation: "2" },
                  on: {
                    click: function($event) {
                      return _vm.setTransmissionDialogOpen(false)
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "custom-dialog",
        { ref: "ConsumerRefundViewDialog", attrs: { "max-width": "1920px" } },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" Consumer Refund ")
          ]),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c("consumer-refund-details", {
                ref: "consumerRefundDetails",
                attrs: {
                  disabled: !_vm.isEditedRecordLenderActive,
                  "pass-edited-record": _vm.editedRecordConsumerRefundDetails
                },
                on: {
                  consumerRefundUpdated: function($event) {
                    return _vm.onConsumerRefundUpdated($event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "buttons" }, slot: "buttons" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    color: "primary",
                    text: "",
                    elevation: "2",
                    disabled: !_vm.isEditedRecordLenderActive
                  },
                  on: {
                    click: function($event) {
                      return _vm.updateConsumerRefund()
                    }
                  }
                },
                [_vm._v(" Update ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", elevation: "2" },
                  on: {
                    click: function($event) {
                      return _vm.setConsumerRefundDialogOpen(false)
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "custom-dialog",
        {
          ref: "ConsumerRefundCreateDialog",
          attrs: { "max-width": "800px" },
          on: { close: _vm.onConsumerRefundCreateDialogClose }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" Create Consumer Refund ")
          ]),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.consumerRefundDialogCreateOpen
                ? _c("consumer-refund-create", {
                    ref: "consumerRefundCreate",
                    attrs: { "product-by-default": _vm.editedRecord },
                    on: {
                      consumerRefundCreated: function($event) {
                        return _vm.onConsumerRefundCreated($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "buttons" }, slot: "buttons" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { color: "primary", text: "", elevation: "2" },
                  on: {
                    click: function($event) {
                      return _vm.createConsumerRefund()
                    }
                  }
                },
                [_vm._v(" Create ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", elevation: "2" },
                  on: {
                    click: function($event) {
                      return _vm.setConsumerRefundCreateDialogOpen(false)
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "custom-dialog",
        {
          ref: "productPaymentDialog",
          attrs: { maxWidth: "800px" },
          on: { close: _vm.onProductPaymentDialogClose }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Product Payment")
          ]),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.productPaymentDialogOpen
                ? _c("product-payment-details", {
                    ref: "productPaymentDetails",
                    attrs: {
                      account: _vm.editedRecord.account,
                      prefilledProduct: _vm.editedRecord,
                      productPayment: _vm.productPaymentDetails,
                      createMode: _vm.createProductPaymentMode,
                      disabled: !_vm.isEditedRecordLenderActive
                    },
                    on: { productPaymentUpdated: _vm.onProductPaymentUpdated }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "buttons" }, slot: "buttons" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { text: "", elevation: "2" },
                  on: {
                    click: function($event) {
                      return _vm.setProductPaymentDialogOpen(false)
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: {
                    text: "",
                    elevation: "2",
                    disabled:
                      !_vm.createProductPaymentMode &&
                      !_vm.isEditedRecordLenderActive
                  },
                  on: {
                    click: function($event) {
                      return _vm.updateProductPaymentDetails()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.createProductPaymentMode ? "Create" : "Update"
                      ) +
                      " Product Payment "
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c("remove-product-payment-action", {
        ref: "RemoveProductPaymentAction",
        attrs: { eager: "" },
        on: {
          success: function($event) {
            return _vm.productPaymentDeleteSuccess()
          }
        }
      }),
      _c("remove-consumer-refund-action", {
        ref: "RemoveConsumerRefundAction",
        attrs: { eager: "" },
        on: {
          success: function($event) {
            return _vm.consumerRefundDeleteSuccess()
          }
        }
      }),
      _c("send-cancellation-action", {
        ref: "SendCancellationAction",
        on: {
          success: function($event) {
            _vm.getRemoteComponent("TransmissionsGrid").loadData()
            _vm.formDataRefresh()
          }
        }
      }),
      _c("re-send-cancellation-action", {
        ref: "ReSendCancellationAction",
        on: {
          success: function($event) {
            _vm.getRemoteComponent("TransmissionsGrid").loadData()
            _vm.formDataRefresh()
          }
        }
      }),
      _c("re-quote-product-action", {
        ref: "ReQuoteProductAction",
        on: {
          success: function($event) {
            return _vm.onReQuoteSuccess()
          }
        }
      }),
      _c(
        "custom-dialog",
        {
          ref: "SaveWithReprocessPrompt",
          attrs: {
            closeDialogDisabled: true,
            maxWidth: "450px",
            messages: [
              "Product Type, VIN, Dealer, Cancel Type or Provider have been changed. To save your changes the cancellation must be Reprocessed."
            ]
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Reprocess Required")
          ]),
          Object.keys(_vm.infos).length > 0
            ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "v-alert v-sheet v-sheet--outlined theme--light v-alert--border v-alert--dense v-alert--outlined v-alert--text v-alert--border-left error--text",
                    staticStyle: { "font-size": "13px", "line-height": "14px" }
                  },
                  [
                    _c("div", { staticClass: "v-alert__wrapper" }, [
                      _c("i", {
                        staticClass:
                          "v-icon notranslate v-alert__icon mdi mdi-alert theme--light error--text",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _c(
                        "div",
                        { staticClass: "v-alert__content" },
                        [
                          _c("div", [_c("b", [_vm._v("Errors:")])]),
                          _vm._l(_vm.infos.product, function(product) {
                            return _c("div", { key: product.field }, [
                              _vm._v(_vm._s(product) + " required")
                            ])
                          }),
                          _vm._l(_vm.infos.account, function(account) {
                            return _c("div", { key: account.field }, [
                              _vm._v(_vm._s(account) + " required")
                            ])
                          })
                        ],
                        2
                      ),
                      _c("div", {
                        staticClass: "v-alert__border v-alert__border--left"
                      })
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "buttons" }, slot: "buttons" },
            [
              Object.keys(_vm.infos).length == 0
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-1 primary",
                      on: {
                        click: function($event) {
                          return _vm.reprocess()
                        }
                      }
                    },
                    [_vm._v(" Reprocess ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      if (Object.keys(_vm.infos).length == 0) {
                        _vm.recordDataRefresh()
                      }
                      _vm
                        .getRemoteComponent("SaveWithReprocessPrompt")
                        .external()
                        .close()
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }