






import { Vue } from 'vue-property-decorator';
import UserService from '@/services/api/User.service';
import moment from 'moment';

export default Vue.extend({
  name: 'LenderServiceConfigurationSettings',
  data() {
    return {
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      saveButtonActive: true,
      editedRecord: null as null,
    };
  },
  components: {},
  methods: {},
  props: ['footerHeight', 'orgId'],
});
