









































































































































































































import { Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import ValidationService from './../services/Validations.service';
import SearchInputField from '../components/SearchInputField.vue';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import ProductPaymentService from '@/services/api/ProductPayment.service';
import { ProductPayment, Product, Account, Response } from '../interfaces';
import FormatDisplayService from './../services/FormatDisplay.service';

export default Vue.extend({
  name: 'ProductPaymentDetails',
  components: { SearchInputField },
  data: () => ({
    formatDisplayService: FormatDisplayService,
    valid: true,
    processing: false,
    overlay: false,
    errors: [] as string[],
    snackBarSuccess: false,
    snackBarError: false,
    editedRecord: {
      product: {},
      account: {},
    } as ProductPayment,
    selectedProduct: {
      name: '',
    },
    dateReceivedMenu: false,
    ValidationService: ValidationService as Object,
    MetadataService: MetadataService,
    productPaymentMetadata: new Map(),
    productFilterConditions: [] as Array<Object>,
  }),
  filters: {
    formatUserDateTime(user: string | null, dateTime: string | null) {
      const userDisplay = user === 'null' || user === '' || user === null || user === undefined ? '' : user + ', ';
      return userDisplay + (FormatDisplayService.getRender('toDateTime')(dateTime) || '');
    },
  },
  async created() {
    this.productFilterConditions.push({
      conditionComparatorOption: 'contain',
      fieldName: 'orgId',
      fieldType: 'string',
      value: this.account.orgId,
    });
    if (!this.createMode) {
      this.editedRecord = { ...this.productPayment };
    }
    const product = this.account?.products?.find((p: Product) => p.externalId === this.editedRecord.productExternalId);
    if (product) {
      this.selectedProduct.name = (product.name || product.id) as string;
    }
    if (this.prefilledProduct) {
      // Case if create from Product details page
      this.selectProduct(this.prefilledProduct);
    } else {
      // Case if create from Account details page
      this.productFilterConditions.push({
        conditionComparatorOption: 'equal',
        fieldName: 'accountNumber',
        fieldType: 'string',
        value: this.account.accountNumber,
      });
    }
    this.productPaymentMetadata = await this.MetadataService.loadData(MetadataType.PRODUCT_PAYMENT);
  },
  methods: {
    selectProduct(product: Product) {
      this.selectedProduct.name = (product.name || product.id) as string;
      this.editedRecord.productExternalId = product.externalId;
    },
    async updateProductPayment() {
      this.error().clear();
      try {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          this.overlay = true;
          let res: Response<ProductPayment>;
          if (this.createMode) {
            if (this.account) {
              this.editedRecord.orgId = this.account.orgId;
              this.editedRecord.accountNumber = this.account.accountNumber;
            }
            res = await ProductPaymentService.create(this.editedRecord);
          } else {
            res = await ProductPaymentService.update(this.editedRecord);
          }
          if (res.success) {
            this.snackBarSuccess = true;
            this.overlay = false;
            this.editedRecord = res.data as ProductPayment;
            this.$emit('productPaymentUpdated');
          } else {
            if (res.errors) {
              this.error().set(res.errors);
            }
            this.snackBarError = true;
            this.overlay = false;
          }
        }
      } catch (err) {
        this.error().set([err.response.data.message]);
        this.snackBarError = true;
        this.overlay = false;
      }
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
  },
  props: {
    productPayment: {
      type: Object as PropType<ProductPayment>,
      default: () => {},
    },
    createMode: {
      type: Boolean,
      default: true,
    },
    account: {
      type: Object as PropType<Account>,
    },
    prefilledProduct: {
      type: Object as PropType<Product>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
