var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        overflow: "hidden",
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px"
      }
    },
    [
      _c(
        "section",
        {
          staticStyle: {
            margin: "0px",
            padding: "0px",
            height: "100%",
            display: "flex",
            "flex-flow": "column",
            overflow: "hidden"
          }
        },
        [
          _c(
            "header",
            { staticClass: "page-header" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "page-title v-card__title page-layout-title-class"
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "page-title-icon",
                      attrs: { "aria-hidden": "false", color: "primary" }
                    },
                    [_vm._v(" " + _vm._s(_vm.pageIcon) + " ")]
                  ),
                  _vm._v(" " + _vm._s(_vm.pageName) + " ")
                ],
                1
              ),
              _vm._t("header"),
              _vm.hasToolbarSlots || _vm.showToolbarSlots === true
                ? _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-2 pb-3", attrs: { cols: "4" } },
                        [_vm._t("toolbar")],
                        2
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-2 pb-3",
                          staticStyle: { position: "relative" },
                          attrs: { cols: "2" }
                        },
                        [_vm._t("options")],
                        2
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-2 pb-3",
                          staticStyle: { "text-align": "right" },
                          attrs: { cols: "6" }
                        },
                        [_vm._t("buttons")],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "div",
            { staticStyle: { position: "relative" } },
            [_vm._t("precontent")],
            2
          ),
          _vm._l(_vm.contentList, function(content) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.contentSelected === content,
                    expression: "contentSelected === content"
                  }
                ],
                key: content,
                ref: "PageLayoutContentElement",
                refInFor: true,
                style: _vm.contentStyle,
                attrs: { transition: "scroll-x-reverse-transition" },
                on: { scroll: _vm.contentScrollHandler }
              },
              [_vm._t(content)],
              2
            )
          }),
          _c("div", [_vm._t("hidden")], 2),
          _vm.hasFooter ? _c("footer", [_vm._t("footer")], 2) : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }