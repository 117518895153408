var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: false, expression: "false" }
      ]
    },
    [_vm._v("Export Component")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }