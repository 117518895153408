














































































































































import { Vue } from 'vue-property-decorator';

import LenderServiceConfigurationMenu from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfigurationMenu.vue';
import LenderServiceConfigurationSettings from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfigurationSettings.vue';
import LenderServiceConfigurationSettingsDealerInvoice from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfigurationSettingsDealerInvoice.vue';
import LenderServiceConfigurationSettingsGeneral from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfigurationSettingsGeneral.vue';
import LenderServiceConfigurationFee from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfigurationFee.vue';
import LenderServiceConfigurationPaths from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfigurationPaths.vue';
import LenderServiceConfigurationFreeLook from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfigurationFreeLook.vue';
import LenderServiceConfigurationDaysToRefund from '@/components/SFComponents/LenderServiceConfiguration/DaysToRefund/DaysToRefundGrid.vue';
import LenderServiceConfigurationLetters from '@/components/SFComponents/LenderServiceConfiguration/Letters/LetterGrid.vue';
import LenderServiceConfigurationLettersLibrary from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfigurationLettersLibrary.vue';
import UserService from '@/services/api/User.service';
import { SfServiceConfigurationMenuItemI } from '@/interfaces';
import SfLetterUpload from '@/components/SFComponents/SfLetterUpload.vue';
import EventBus from '@/events/EventBus';
import SfLetterPreview from '@/components/SFComponents/SfLetterPreview.vue';

export default Vue.extend({
  data() {
    return {
      processing: false,
      processingError: false,
      SavedSuccessfully: false,
      processingErrorMessage: null as null | string,
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      menuSelection: { name: 'Fees', disabled: false, title: 'Fee Management' },
      title: 'Fee Management',
      menu: [
        {
          menuName: 'Rules Management',
          menuItems: [
            // { name: 'Settings', disabled: false, title: 'Settings' },
            {
              name: 'Settings',
              disabled: false,
              title: 'Settings',
              menu: true,
              children: [
                { name: 'General', disabled: false, title: 'General' },
                { name: 'Dealer Invoice', disabled: false, title: 'Dealer Invoice' },
              ],
            },
            { name: 'Fees', disabled: false, title: 'Fee Management' },
            { name: 'Free Look', disabled: false, title: 'Free Look' },
            { name: 'Paths', disabled: false, title: 'Path Management' },
            { name: 'Days to Refund', disabled: false, title: 'Days to Refund' },
            {
              name: 'Letters',
              disabled: false,
              title: 'Letter Management',
              menu: true,
              children: [
                { name: 'Rules', disabled: false, title: 'Letter Rules Management' },
                { name: 'Library', disabled: false, title: 'Letter Library Management' },
              ],
            },
          ],
        },
      ],
    };
  },
  created() {
    EventBus.$on('SF_PROCESSING', (enabled: boolean) => {
      this.processing = enabled;
    });
    EventBus.$on('SF_PROCESSING_ERROR', (message: string) => {
      this.processingError = true;
      this.processingErrorMessage = message;
      setTimeout(() => {
        this.processingError = false;
        this.processingErrorMessage = '';
      }, 2000);
    });
    EventBus.$on('SF_SAVED_SUCCESSFULLY', () => {
      this.SavedSuccessfully = true;
      setTimeout(() => {
        this.SavedSuccessfully = false;
      }, 1500);
    });
  },
  methods: {
    buttonText() {
      switch (this.menuSelection.name) {
        case 'Fees':
          return 'Add Rule';
        case 'Paths':
          return 'Add Path';
        case 'Free Look':
          return 'Add Rule';
        case 'Rules':
          return 'Add Rule';
        case 'Days to Refund':
          return 'Add Rule';
      }
      return false;
    },
    menuSelect(menuItem: SfServiceConfigurationMenuItemI) {
      this.title = menuItem.title;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    addNewRule() {
      if (this.menuSelection.name === 'Fees') {
        this.getRemoteComponent('LenderServiceConfigurationFeeComponent').openEditDialog();
        this.getRemoteComponent('LenderServiceConfigurationFeeComponent').addNewFeeRule();
      }
      if (this.menuSelection.name === 'Paths') {
        this.getRemoteComponent('LenderServiceConfigurationPathsComponent').openEditDialog();
        this.getRemoteComponent('LenderServiceConfigurationPathsComponent').addNewPathsRule();
      }
      if (this.menuSelection.name === 'Rules') {
        this.getRemoteComponent('LenderServiceConfigurationLettersComponent').openEditDialog();
        this.getRemoteComponent('LenderServiceConfigurationLettersComponent').addNewLettersRule();
      }
      if (this.menuSelection.name === 'Free Look') {
        this.getRemoteComponent('LenderServiceConfigurationFreeLookComponent').openEditDialog();
        this.getRemoteComponent('LenderServiceConfigurationFreeLookComponent').addNewFreeLookRule();
      }
      if (this.menuSelection.name === 'Days to Refund') {
        this.getRemoteComponent('LenderServiceConfigurationDaysToRefundComponent').openEditDialog();
        this.getRemoteComponent('LenderServiceConfigurationDaysToRefundComponent').addNewRule();
      }
    },
  },
  components: {
    LenderServiceConfigurationMenu,
    LenderServiceConfigurationSettings,
    LenderServiceConfigurationSettingsDealerInvoice,
    LenderServiceConfigurationFee,
    LenderServiceConfigurationPaths,
    LenderServiceConfigurationLetters,
    LenderServiceConfigurationLettersLibrary,
    LenderServiceConfigurationFreeLook,
    LenderServiceConfigurationDaysToRefund,
    SfLetterUpload,
    SfLetterPreview,
    LenderServiceConfigurationSettingsGeneral,
  },
  name: 'LenderServiceConfiguration',
  props: ['showMenu', 'footerHeight', 'orgId'],
});
