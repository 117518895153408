var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "", rounded: "4", scrollable: "false" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            "prepend-icon": "mdi-magnify",
                            label: "Letter",
                            readonly: "",
                            dense: "",
                            "hide-details": "",
                            outlined: "",
                            clearable: ""
                          },
                          on: { "click:clear": _vm.clearLetter },
                          model: {
                            value: _vm.content,
                            callback: function($$v) {
                              _vm.content = $$v
                            },
                            expression: "content"
                          }
                        },
                        "v-text-field",
                        _vm.$attrs,
                        false
                      ),
                      on
                    )
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.open,
            callback: function($$v) {
              _vm.open = $$v
            },
            expression: "open"
          }
        },
        [
          _c(
            "div",
            { staticClass: "input-shrink", on: { click: _vm.stopTheEvent } },
            [
              _c(
                "v-row",
                { staticClass: "pa-3 pb-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "7", clas: "sfl-fix-label" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Search",
                          dense: "",
                          "hide-details": "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-0 pr-0" },
                    [
                      _c(
                        "sf-letter-upload",
                        {
                          attrs: {
                            orgId: _vm.orgId,
                            party: _vm.getDefaultParty()
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { slot: "button" },
                              slot: "button"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "slds-button slds-button_neutral",
                                  staticStyle: {
                                    height: "39px",
                                    "white-space": "nowrap"
                                  },
                                  attrs: { title: "Upload Letter" }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "slds-button__icon slds-button__icon_left",
                                      attrs: { "aria-hidden": "true" }
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                                            "#upload"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" Upload Letter "),
                                  _c(
                                    "span",
                                    { staticClass: "slds-assistive-text" },
                                    [_vm._v("Upload Letter")]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pa-0 pt-4", attrs: { cols: "1" } },
                    [
                      _c(
                        "div",
                        [
                          _c("v-icon", {
                            domProps: { textContent: _vm._s("mdi-close") },
                            on: {
                              click: function($event) {
                                _vm.open = false
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pa-5 pt-0 pb-5" },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        height: "385px",
                        border: "1px solid: #ddd",
                        position: "relative",
                        overflow: "hidden"
                      }
                    },
                    [
                      _vm.open
                        ? _c("sf-data-grid", {
                            ref: "SfLeterSelectUploadGrid",
                            attrs: {
                              footerHeight: 140,
                              lookups: {},
                              apiMetadataUrl:
                                "/uiapi/grids/cancellation-letter",
                              apiDataUrl: "/uiapi/cancellation-letter/search",
                              rowClick: _vm.rowClick,
                              "fixed-conditions": _vm.getConditions(),
                              initialSortOrder: [
                                { fieldName: "id", desc: true }
                              ]
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }