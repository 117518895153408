var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        flex: "1",
        height: "100%",
        "flex-direction": "row"
      }
    },
    [
      _c(
        "div",
        { staticStyle: { display: "none" } },
        [
          _c("sf-letter-upload", {
            ref: "LetterLibraryUploadEditComponent",
            attrs: { orgId: _vm.orgId }
          })
        ],
        1
      ),
      _c("sf-data-grid", {
        ref: "LenderServiceConfigurationLetterLibraryGrid",
        attrs: {
          actions: _vm.actions(),
          lookups: _vm.gridApiUrls.lookups,
          apiMetadataUrl: _vm.gridApiUrls.metadata,
          apiDataUrl: _vm.gridApiUrls.data,
          "footer-height": _vm.footerHeight,
          initialSortOrder: [{ fieldName: "id", desc: true }],
          "fixed-conditions": [
            {
              fieldName: "orgId",
              value: _vm.orgId,
              fieldType: "string",
              conditionComparatorOption: "equal"
            }
          ]
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }