import ApiService from '@/services/api/Api.service';
import { ProductPayment, Response } from '../../interfaces';
import { AxiosResponse } from 'axios';

class ProductPaymentService extends ApiService {
  async update(params: ProductPayment): Promise<Response<ProductPayment>> {
    return (
      await this.axios.post<ProductPayment, AxiosResponse<Response<ProductPayment>>>(
        '/uiapi/product-payment/' + params.id,
        params,
      )
    ).data;
  }

  async create(params: ProductPayment): Promise<Response<ProductPayment>> {
    return (
      await this.axios.post<ProductPayment, AxiosResponse<Response<ProductPayment>>>('/uiapi/product-payment/', params)
    ).data;
  }

  async delete(id: number): Promise<Response<ProductPayment>> {
    return (
      await this.axios.delete<ProductPayment, AxiosResponse<Response<ProductPayment>>>('/uiapi/product-payment/' + id)
    ).data;
  }
}

export default new ProductPaymentService();
