






















import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SfCustomDialogLayout extends Vue {
  data() {
    return {};
  }
  async mounted() {
    this.$data.ready = true;
  }
}
