import { render, staticRenderFns } from "./lenderDetails.vue?vue&type=template&id=03b73de6&scoped=true&"
import script from "./lenderDetails.vue?vue&type=script&lang=ts&"
export * from "./lenderDetails.vue?vue&type=script&lang=ts&"
import style0 from "./lenderDetails.vue?vue&type=style&index=0&id=03b73de6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03b73de6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardText,VCol,VContainer,VForm,VRow,VTab,VTabItem,VTabs})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03b73de6')) {
      api.createRecord('03b73de6', component.options)
    } else {
      api.reload('03b73de6', component.options)
    }
    module.hot.accept("./lenderDetails.vue?vue&type=template&id=03b73de6&scoped=true&", function () {
      api.rerender('03b73de6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/lenderDetails.vue"
export default component.exports