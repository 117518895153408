import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueMask from 'vue-the-mask';
import CustomDatePicker from '@/components/CustomDatePicker.vue';
import CustomTimePicker from '@/components/CustomTimePicker.vue';
import CustomGeolocationField from '@/components/CustomGeolocationField.vue';
import FormGenerator from '@/components/FormGenerator.vue';
import CustomField from '@/components/CustomField.vue';
import CustomTooltip from '@/components/CustomTooltip.vue';
import LayoutFloatingMessages from '@/components/LayoutFloatingMessages.vue';
// @ts-ignore:
import DatetimePicker from '@/components/DatetimePicker.vue';
import DataGrid from '@/components/DataGrid.vue';
import ComplianceTracker from '@/components/ComplianceTracker.vue';

Vue.config.productionTip = false;
/**
 *  Library for vuetify fields mask
 *  Added for phone number formatting
 */
Vue.use(VueMask);
Vue.component('custom-geolocation-field', CustomGeolocationField);
Vue.component('custom-datetime-picker', DatetimePicker);
Vue.component('custom-date-picker', CustomDatePicker);
Vue.component('custom-time-picker', CustomTimePicker);
Vue.component('form-generator', FormGenerator);
Vue.component('custom-field', CustomField);
Vue.component('data-grid', DataGrid);
Vue.component('custom-tooltip', CustomTooltip);
Vue.component('layout-floating-messages', LayoutFloatingMessages);
Vue.component('compliance-tracker', ComplianceTracker);

import Axios from 'axios';
import { UserI } from '@/interfaces';
import UserService from './services/api/User.service';
import { LctPlugin } from '@/plugins/lctCustomPlugins';

Vue.use(LctPlugin);

// Render application only after success status request.
Axios.get('/uiapi/aws-cognito/status')
  .then(response => {
    UserService.setUserData(response.data);
    initializeVue(response.data);
  })
  .catch(error => {
    if (error?.response?.status === 401) {
      window.localStorage.clear();
      window.location.href = `/uiapi/aws-cognito/login`;
    } else {
      return Promise.reject(error);
    }
  });

function initializeVue(statusData: UserI) {
  new Vue({
    data() {
      return {
        status: statusData as UserI,
      };
    },
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount('#app');
}
