



































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import { EnumItemI } from '../../services/DealerPage.service';
import ValidationService from '../../services/Validations.service';
import DealerPageService from '../../services/DealerPage.service';
import LenderService from '../../services/api/Lender.service';
import { Lender, CancellationLetter } from '../../interfaces';
import FormatDisplayService from '../../services/FormatDisplay.service';
import UserService from '../../services/api/User.service';
import MetadataService from '../../services/api/Metadata.service';

const DUMMY_PASSWORD = 'DUMMY_PASSWORD';
const emptyLenderDataRecord: Lender = {
  lenderID: '',
  orgId: '',
  name: '',
  mailingCountry: '',
  integrationCommunicationSetup: [
    {
      providerCode: 'INTERFAX',
      username: null,
      password: null,
      email: null,
      dateTimeCreated: null,
      createdBy: null,
      dateTimeUpdated: null,
      updatedBy: null,
      active: true,
      id: null,
    },
  ],
  isSalesforce: true,
  returnFileFormat: 'Legacy CSV',
};

export default Vue.extend({
  name: 'LenderPreferencesTab',
  components: {},
  data: () => ({
    integrationPasswordShow: false,
    userService: UserService,
    formatDisplayService: FormatDisplayService,
    editedRecord: Object.assign({}, emptyLenderDataRecord),
    ValidationService: ValidationService as Object,
    dialog: false,
    alertType: 'success',
    valid: true,
    overlay: false,
    message: '',
    statesList: [] as EnumItemI[],
    countriesList: [] as EnumItemI[],
    timezonesList: [] as EnumItemI[],
    errors: [] as string[],
    snackBarSuccess: false,
    snackBarError: false,
    dailyCutOffTimeMenu: false,
  }),
  computed: {
    isMailingCountrySelected() {
      return !!this.editedRecord.mailingCountry;
    },
  },
  filters: {
    formatUserDateTime(user: string | null, dateTime: string | null) {
      const userDisplay = user === 'null' || user === '' || user === null || user === undefined ? '' : user + ', ';
      return userDisplay + (FormatDisplayService.getRender('toDateTime')(dateTime) || '');
    },
  },
  methods: {
    /**
     * Asynchronously loads the list of states based on the mailing country of the edited record.
     * Updates the `statesList` property with the retrieved states.
     * If the mailing country is Canada (CA), it loads the list of Canadian provinces.
     * Otherwise, it loads the list of US states.
     *
     * @return {Promise<void>} A promise that resolves when the states have been loaded.
     */
    async loadStates() {
      this.message = '';

      if (this.editedRecord.mailingCountry === 'CA') {
        this.statesList = await DealerPageService.getItems('DealerStatesCanada');
      } else {
        this.statesList = await DealerPageService.getItems('DealerStatesUS');
      }
    },
    clickShowPassword() {
      this.integrationPasswordShow = !this.integrationPasswordShow;
      if (
        this.integrationPasswordShow &&
        this.editedRecord.integrationCommunicationSetup[0].password === DUMMY_PASSWORD
      ) {
        this.editedRecord.integrationCommunicationSetup[0].password = null;
      }
      if (
        !this.integrationPasswordShow &&
        (!this.editedRecord.integrationCommunicationSetup[0].password ||
          this.editedRecord.integrationCommunicationSetup[0].password === '')
      ) {
        this.editedRecord.integrationCommunicationSetup[0].password = DUMMY_PASSWORD;
      }
    },
    integrationComunicationRules() {
      if (
        !this.editedRecord.integrationCommunicationSetup[0].email &&
        !this.editedRecord.integrationCommunicationSetup[0].password &&
        !this.editedRecord.integrationCommunicationSetup[0].username
      ) {
        return true;
      } else {
        if (!this.editedRecord.integrationCommunicationSetup[0].id) {
          if (
            !this.editedRecord.integrationCommunicationSetup[0].email ||
            !this.editedRecord.integrationCommunicationSetup[0].username ||
            !this.editedRecord.integrationCommunicationSetup[0].password
          ) {
            return 'Interfax Integration Fields: Username, Email, Password cannot be empty';
          }
        }
        return true;
      }
    },
    updateEditedRecordIntegration() {
      this.integrationPasswordShow = false;
      if (this.editedRecord.integrationCommunicationSetup.length === 0) {
        this.editedRecord.integrationCommunicationSetup = [
          {
            providerCode: 'INTERFAX',
            username: null,
            password: null,
            email: null,
            dateTimeCreated: null,
            createdBy: null,
            dateTimeUpdated: null,
            updatedBy: null,
            active: true,
            id: null,
          },
        ];
      } else {
        this.editedRecord.integrationCommunicationSetup[0].password = DUMMY_PASSWORD;
      }
    },
    updateEditedRecord() {
      this.editedRecord = this.passEditedRecord;
      this.updateEditedRecordIntegration();
    },
    async formDataRefresh() {
      this.error().clear();
      if (this.editedRecord.id !== null) {
        this.editedRecord = (await MetadataService.getRecord(
          { id: this.editedRecord.id },
          'All',
          '/uiapi/lender/search',
        )) as Lender;
        this.updateEditedRecordIntegration();
        this.$emit('lender-data-refresh', this.editedRecord);
      }
    },
    validate() {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).lenderForm.validate();
      }, 200);
    },
    resetValidation() {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).lenderForm.resetValidation();
      }, 100);
    },
    formValueChange() {
      this.message = '';
    },
    async submitDataRequest() {
      let request = JSON.parse(JSON.stringify(this.editedRecord));

      if (!request.dealerPayoffCancellationLetterExternalId) {
        request.dealerPayoffCancellationLetter = null;
      }
      if (!request.dealerNonPayoffCancellationLetterExternalId) {
        request.dealerNonPayoffCancellationLetter = null;
      }
      if (!request.providerPayoffCancellationLetterExternalId) {
        request.providerPayoffCancellationLetter = null;
      }
      if (!request.providerNonPayoffCancellationLetterExternalId) {
        this.editedRecord.providerNonPayoffCancellationLetter = null;
      }
      if (
        !request.integrationCommunicationSetup[0].email &&
        (!request.integrationCommunicationSetup[0].password ||
          request.integrationCommunicationSetup[0]?.password === DUMMY_PASSWORD) &&
        !request.integrationCommunicationSetup[0].username
      ) {
        request.integrationCommunicationSetup.length = 0;
      }

      if (request.integrationCommunicationSetup[0]?.password === DUMMY_PASSWORD) {
        delete request.integrationCommunicationSetup[0]['password'];
      }

      try {
        if ((this.$refs.lenderForm as Vue & { validate: () => boolean }).validate()) {
          this.overlay = true;
          this.error().clear();
          let res = await LenderService.update(request);
          if (res.success) {
            this.snackBarSuccess = true;
            this.overlay = false;
            await this.formDataRefresh();
            setTimeout(() => {
              this.dialog = false;
            }, 1500);
          } else {
            if (res.errors) {
              this.error().set(res.errors);
            }
            this.snackBarError = true;
            this.overlay = false;
          }
        }
      } catch (err) {
        this.error().set([err.response.data.message]);
        this.snackBarError = true;
        this.overlay = false;
      }
    },
    selectDealerPayoffCancellationLetter(rowData: CancellationLetter) {
      this.editedRecord.dealerPayoffCancellationLetter = rowData;
      this.editedRecord.dealerPayoffCancellationLetterExternalId = rowData.externalId;
    },
    selectDealerNonPayoffCancellationLetter(rowData: CancellationLetter) {
      this.editedRecord.dealerNonPayoffCancellationLetter = rowData;
      this.editedRecord.dealerNonPayoffCancellationLetterExternalId = rowData.externalId;
    },
    selectProviderPayoffCancellationLetter(rowData: CancellationLetter) {
      this.editedRecord.providerPayoffCancellationLetter = rowData;
      this.editedRecord.providerPayoffCancellationLetterExternalId = rowData.externalId;
    },
    selectProviderNonPayoffCancellationLetter(rowData: CancellationLetter) {
      this.editedRecord.providerNonPayoffCancellationLetter = rowData;
      this.editedRecord.providerNonPayoffCancellationLetterExternalId = rowData.externalId;
    },
    selectNonPayoff2ndNotificationLetter(rowData: CancellationLetter) {
      this.editedRecord.nonPayoff2ndNotificationLetter = rowData;
      this.editedRecord.nonPayoff2ndNotificationLetterExternalId = rowData.externalId;
    },
    selectPayoff2ndNotificationLetter(rowData: CancellationLetter) {
      this.editedRecord.payoff2ndNotificationLetter = rowData;
      this.editedRecord.payoff2ndNotificationLetterExternalId = rowData.externalId;
    },
    selectPayoffFinalNotificationLetter(rowData: CancellationLetter) {
      this.editedRecord.payoffFinalNotificationLetter = rowData;
      this.editedRecord.payoffFinalNotificationLetterExternalId = rowData.externalId;
    },
    selectNonPayoffFinalNotificationLetter(rowData: CancellationLetter) {
      this.editedRecord.nonPayoffFinalNotificationLetter = rowData;
      this.editedRecord.nonPayoffFinalNotificationLetterExternalId = rowData.externalId;
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    allowedStep: (m: number): boolean => m % 15 === 0,
  },
  async created() {
    setTimeout(async () => {
      await this.loadStates();
    }, 100);

    this.countriesList = await DealerPageService.getItems('DealerCountry');
    this.timezonesList = await DealerPageService.getItems('DealerTimeZones');
    this.updateEditedRecord();
  },
  props: {
    passEditedRecord: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
});
