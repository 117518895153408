
























































import { Vue } from 'vue-property-decorator';
import { Product, Account } from '../interfaces';
import CustomDialog from '@/components/CustomDialog.vue';
import axios from '../services/api/Api.service';
import MetadataService, { MetadataType } from '../services/api/Metadata.service';
import moment from 'moment';

interface AccountSendI extends Account {
  REQUESTSTATUS: string | null;
}

interface ExtendedProductI extends Product {
  error?: string | null;
}

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

const sendRefundEndpointUrl = '/uiapi/v1/email-refund-request/send';
const previewRefundEndpointUrl = '/uiapi/v1/email-refund-request/preview';

export type CancellationTypeI = 'Products' | 'Accounts';

export default Vue.extend({
  name: 'RequestRefundAction',
  components: { CustomDialog },
  data: () => ({
    sendProcessed: false,
    service: MetadataService,
    metadataEnumsMap: new Map(),
    items: [] as ExtendedProductI[],
    itemsError: [] as { ids: number[]; reason: string }[],
    filterCode: 'RequestRefundEmail',
    dataTable: {
      fixedHeader: true,
      disablePagination: false,
      hideDefaultFooter: false,
      footerProps: {
        itemsPerPageOptions: [9999],
      },
      itemsPerPage: 9999,
      options: {
        itemsPerPage: 9999,
      },
    },
    cancellationType: 'PRODUCT' as 'PRODUCT',
    openDialog: false,
    errors: [] as string[],
    messages: [] as string[],
    processing: false,
    CustomDialog: {
      ref: 'CustomDialog',
    },
    enabledSendRequestRefundEmail: false,
  }),
  methods: {
    sendPreviewRequest(): void {
      this.error().clear();
      this.message().clear();
      this.processing = true;

      const requestParams = {
        filterCode: this.filterCode,
        conditions: [
          {
            comparatorOption: 'in',
            fieldName: 'id',
            fixed: false,
            type: 'String',
            value: this.items.map(item => item.id),
          },
        ],
      };

      axiosInstance
        .post(previewRefundEndpointUrl, JSON.parse(JSON.stringify(requestParams)), { responseType: 'blob' })
        .then(async result => {
          const blobData = new Blob([result.data]);
          try {
            const responseResult = JSON.parse(await blobData.text());
            if (responseResult.success == false) {
              this.error().set(responseResult.errors);
              this.$emit('error');
            }
          } catch (e) {
            const fileURL = window.URL.createObjectURL(blobData);
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `Refund_Request_Email_Preview_${moment().format('MM-DD-YYYY')}.zip`);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.$emit('success');
          }
          this.processing = false;
        })
        .catch((error: string) => {
          this.error().add(error);
          this.processing = false;
        });
    },
    submitDataRequest(): void {
      // reset error box on send request
      this.error().clear();
      this.message().clear();
      this.processing = true;
      const requestParams = {
        filterCode: this.filterCode,
        conditions: [
          {
            comparatorOption: 'in',
            fieldName: 'id',
            fixed: false,
            type: 'String',
            value: this.items.map(item => item.id),
          },
        ],
      };

      axiosInstance
        .post(sendRefundEndpointUrl, JSON.parse(JSON.stringify(requestParams)))
        .then((result: { data: { success: boolean; errors: string[]; messages: string[] } }) => {
          if (result.data.success) {
            this.message().set(result.data.messages);
            if (result.data.errors?.length > 0) {
              this.error().set(result.data.errors);
            } else {
              this.processing = false;
              this.$emit('success');
              setTimeout(() => {
                this.getRemoteComponent('CustomDialog')
                  .external()
                  .close();
              }, 2000);
            }
          } else {
            this.error().set(result.data.errors);
            this.$emit('error');
          }
          this.processing = false;
          this.$emit('refreshGrid');
        })
        .catch((error: string) => {
          this.error().add(error);
          this.processing = false;
          this.$emit('refreshGrid');
        });
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    open(rowSelections: Product[], filterCode: string): void {
      this.sendProcessed = false;
      this.processing = false;
      this.error().clear();
      this.message().clear();
      this.items.length = 0;
      this.itemsError.length = 0;
      this.filterCode = filterCode;

      if (rowSelections.length < 1) {
        this.error().add('No items were selected');
      }

      this.enabledSendRequestRefundEmail = false;
      this.items = rowSelections.map((product: ExtendedProductI) => {
        product.error = null;

        if (product.provider?.providerRefundRequestMethod != 'Email') {
          product.error = `Provider Refund Request Method is "${product.provider?.providerRefundRequestMethod}", unable to request quote via email.`;
        }

        if (!product.orgPreference?.active) {
          product.error = `${product.orgPreference?.name} status inactive, unable to request quote via email.`;
        }

        const currentDate = new Date();
        const emailRefundRequestSendDaysLimit = process.env.EMAIL_REFUND_REQUEST_SEND_DAYS_LIMIT
          ? parseInt(process.env.EMAIL_REFUND_REQUEST_SEND_DAYS_LIMIT, 10)
          : 15;
        currentDate.setDate(currentDate.getDate() - emailRefundRequestSendDaysLimit);
        if (
          product.emailRefundRequestSendDate != null &&
          new Date(product.emailRefundRequestSendDate).valueOf() > currentDate.valueOf()
        ) {
          product.error = `Quote already requested via email on ${moment(
            product.emailRefundRequestSendDate.valueOf(),
          ).format('MM/DD/YYYY')}, try again in ${Math.ceil(
            (new Date(product.emailRefundRequestSendDate).valueOf() - currentDate.valueOf()) / 24 / 60 / 60 / 1000,
          )} days`;
        }

        if (product.error == null) {
          this.enabledSendRequestRefundEmail = true;
        }

        return product;
      }) as ExtendedProductI[];
      this.getRemoteComponent('CustomDialog')
        .external()
        .open();
    },
    error() {
      return {
        set: (errors: string[]) => {
          this.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      return {
        set: (errors: string[]) => {
          this.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.messages.push(error);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
  },
  async created() {
    this.metadataEnumsMap = await this.service.loadData(MetadataType.PRODUCT);
  },
  computed: {
    productHeaders() {
      return [
        {
          text: 'Account #',
          value: 'accountNumber',
        },
        {
          text: 'Product Type',
          value: 'productType',
          parse: (v: string) => {
            const types = this.metadataEnumsMap.get('productType');
            let output = v;
            if (types) {
              types.forEach((type: { text: string; value: string }) => {
                if (v === type.value) {
                  output = type.text;
                }
              });
            }
            return output;
          },
        },
        {
          text: 'Provider Name',
          value: 'provider.name',
        },
        {
          text: 'Product Processing Status',
          value: 'productProcessingStatus',
        },
        {
          text: 'Errors',
          value: 'error',
        },
      ];
    },
    buttons() {
      return [
        {
          name: 'Send Request Refund Email',
          color: 'primary',
          disabled: false,
          callback: () => {
            this.submitDataRequest();
          },
        },
      ];
    },
  },
});
