











































































import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  name: 'CustomDialog',
  components: {},
  data: () => ({
    open: false,
  }),
  watch: {
    open: {
      handler: function(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.$emit('open', true);
        }
        if (!newValue && oldValue) {
          this.$emit('close', true);
        }
        if (!newValue && oldValue) {
          this.$emit('change', newValue);
        }
        if (newValue !== oldValue) {
          this.$emit('updated', newValue);
        }
      },
    },
  },
  methods: {
    external() {
      const scope = this;
      return {
        open() {
          scope.open = true;
        },
        close() {
          scope.open = false;
        },
      };
    },
  },
  props: {
    closeDialogDisabled: {
      type: Boolean,
      default: false,
    },
    presistDialog: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: () => {
        return [];
      },
    },
    errors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    maxWidth: {
      type: String,
      default: '95%',
    },
  },
});
