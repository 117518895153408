var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-dialog",
    _vm._b(
      {
        attrs: {
          maxWidth: "50%",
          errors: _vm.errors,
          messages: _vm.messages,
          processing: _vm.processing
        }
      },
      "custom-dialog",
      _vm.CustomDialog,
      false
    ),
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("Resend Cancellation")
      ]),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("v-select", {
            attrs: {
              items: _vm.options,
              label: "Send Cancellation To",
              "lazy-validation": "false",
              rules: [
                _vm.ValidationService.getValidator("required", "Resend Option")
              ]
            },
            model: {
              value: _vm.selectedOption,
              callback: function($$v) {
                _vm.selectedOption = $$v
              },
              expression: "selectedOption"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "buttons" }, slot: "buttons" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-1",
              attrs: { disabled: _vm.processing, elevation: "2" },
              on: {
                click: function($event) {
                  _vm
                    .getRemoteComponent("CustomDialog")
                    .external()
                    .close()
                }
              }
            },
            [_vm._v(" Close ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-1 primary",
              attrs: {
                disabled:
                  _vm.processing || !_vm.selectedOption || _vm.sendProcessed,
                elevation: "2"
              },
              on: {
                click: function($event) {
                  return _vm.submitDataRequest()
                }
              }
            },
            [_vm._v(" Resend ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }