






import { Vue } from 'vue-property-decorator';
import { Account } from '../interfaces';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import ProductService from '../services/api/Product.service';

export default Vue.extend({
  name: 'CreateNoCancellableProductAction',
  components: { ConfirmationDialog },
  data: () => ({
    submitButtonDisabled: false as boolean,
    rowData: {} as Account,
    openDialog: false,
    confirmationDialog: {
      processing: false,
      ref: 'ConfirmationDialog',
      title: 'No Cancellable Product' as string,
      message: '' as string,
      errors: [] as string[],
      messages: [] as string[],
    },
  }),
  methods: {
    setSubmitButtonDisabled(option: boolean) {
      this.submitButtonDisabled = option;
    },
    async createNoCancellableProduct() {
      this.error().clear();
      this.message().clear();
      this.confirmationDialog.processing = true;

      try {
        const productRes = await ProductService.createNoCancellableProduct({
          accountId: this.rowData.id as number,
        });

        if (productRes.success) {
          this.message().set(productRes.messages as string[]);
          this.$emit('success', productRes.data?.account);
          setTimeout(() => {
            this.openDialog = false;
            this.confirmationDialog.processing = false;
          }, 2000);
        } else {
          this.confirmationDialog.processing = false;
          if (productRes.errors) {
            this.error().set(productRes.errors);
          }
          this.$emit('error');
        }
      } catch (err) {
        this.confirmationDialog.processing = false;
        this.error().set([err.response.data.message]);
      }
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    open(rowData: Account): void {
      this.rowData = rowData;
      this.confirmationDialog.processing = false;
      this.error().clear();
      this.message().clear();
      this.setSubmitButtonDisabled(false);

      this.confirmationDialog.message =
        'I have reviewed the account and deemed that it has no cancellable products on account.';

      this.openDialog = true;

      setTimeout(() => {
        this.getRemoteComponent('ConfirmationDialog').open = true;
      }, 100);
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.confirmationDialog.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.confirmationDialog.errors.push(error);
          return this;
        },
        clear: () => {
          this.confirmationDialog.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.confirmationDialog.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.confirmationDialog.messages.push(error);
          return this;
        },
        clear: () => {
          this.confirmationDialog.messages.length = 0;
          return this;
        },
      };
    },
  },
  computed: {
    buttons() {
      return [
        {
          name: 'Next',
          color: 'primary',
          disabled: this.submitButtonDisabled,
          callback: () => {
            this.createNoCancellableProduct();
          },
        },
      ];
    },
  },
});
