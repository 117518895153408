

























import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  name: 'CustomTooltip',
  components: {},
  data() {
    return {
      contentCls: this.contentClass,
    };
  },
  mounted() {
    if (this.arrow) {
      if (this.top) {
        this.contentCls = 'tooltip-top';
      }
    }
  },
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    contentClass: {
      type: String,
      default: '',
    },
    nudgeTop: {
      type: String,
      default: '10',
    },
    nudgeBottom: {
      type: String,
      default: '',
    },
    nudgeRight: {
      type: String,
      default: '70',
    },
    nudgeLeft: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '400',
    },
    color: {
      type: String,
      default: '#587FAA',
    },
  },
});
