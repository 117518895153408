var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "about",
        staticStyle: { "text-align": "center", height: "100%" }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              margin: "auto",
              "margin-top": "25%",
              "text-align": "center"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "auto",
                  "border-bottom": "1px solid #ddd",
                  width: "30%"
                }
              },
              [_vm._v("Home")]
            ),
            _c("img", {
              staticClass: "ml-2 mt-1",
              staticStyle: { height: "53px", width: "180px" },
              attrs: { src: require("../assets/logo.png") }
            })
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }