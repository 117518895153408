

























































































































































import { Vue } from 'vue-property-decorator';
import FormatDisplayService from '../services/FormatDisplay.service';
import CustomFieldsForm from './CustomFieldsForm.vue';

export default Vue.extend({
  name: 'FormGenerator',
  components: { CustomFieldsForm },
  data() {
    return {
      formatDisplayService: FormatDisplayService,
      editedRecord: this.value,
    };
  },
  computed: {},
  watch: {
    '$props.value'(v) {
      this.editedRecord = v;
    },
  },
  methods: {
    makeid(length: number) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    external() {
      const scope = this;
      return {
        getForm() {
          return scope.getRemoteComponent(scope.formRef);
        },
        reValidate() {
          setTimeout(() => {
            scope.getRemoteComponent(scope.formRef).resetValidation();
          }, 100);
          setTimeout(() => {
            scope.getRemoteComponent(scope.formRef).validate();
          }, 200);
        },
      };
    },
    handleInput() {
      this.$emit('input', this.editedRecord);
    },
  },
  props: ['value', 'formConfig', 'formRef', 'formData'],
});
