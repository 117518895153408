





















































import { Vue } from 'vue-property-decorator';
import UserService from '@/services/api/User.service';
import EventBus from '@/events/EventBus';
import AuditHistoryComponent from '@/components/SFComponents/AuditHistory/AuditHistoryComponent.vue';

export default Vue.extend({
  data() {
    return {
      viewAll: true,
      orgId: null as null | string,
      timeInterval: null as null | number,
      networkDisconnected: false as boolean,
      sessionLost: false,
    };
  },
  components: { AuditHistoryComponent },
  name: 'SfProductAuditHistory',
  async created() {
    const userData = await UserService.getUserData();
    this.orgId = userData?.orgId || null;
    this.viewAll =
      userData?.userRole == 'Salesforce Admin' ||
      userData?.userRole == 'Salesforce Standard' ||
      userData?.userRole == 'Salesforce Employee'
        ? false
        : true;
    EventBus.$on('NetworkDisconnected', () => {
      this.networkDisconnected = true;
    });
    EventBus.$on('NetworkConnected', () => {
      if (this.networkDisconnected) {
        this.networkDisconnected = false;
        window.location.href = '/audit-history/product/' + this.$route.params.sfid;
      }
    });
    EventBus.$on('ForceRefreshPageInfo', () => {
      this.sessionLost = true;
    });
    // prevent session go to sleep
    this.timeInterval = setInterval(() => {
      UserService.getUserData(true);
    }, 30000);
  },
});
