import Vue from 'vue';
import Vuex from 'vuex';
import axios, { AxiosResponse } from 'axios';
import router from '@/router/index';
import { LenderCustomFieldsI } from '@/interfaces/lender.interface';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      idToken: null,
      userId: null,
      userData: null,
    },
    ui: {
      lender: null,
      lenderState: false,
      lenderCustomFields: {} as { key: string; value: LenderCustomFieldsI[] },
      menuItems: [
        { title: 'Home', path: '/', icon: 'mdi-home', mainLayout: null },
        { title: 'Accounts', path: '/accounts', icon: 'mdi-format-list-text', mainLayout: null },
        { title: 'Products', path: '/products', icon: 'mdi-view-list-outline', mainLayout: null },
        { title: 'Data Upload', path: '/data-upload', icon: 'mdi-table-arrow-up', mainLayout: null },
        { title: 'Dealers', path: '/dealers', icon: 'mdi-smart-card-outline', mainLayout: null },
        { title: 'Providers', path: '/providers', icon: 'mdi-smart-card', mainLayout: null },
        { title: 'Provider Form', path: '/provider-form', icon: 'mdi-form-select', mainLayout: null },
        { title: 'Provider Alias', path: '/provider-alias', icon: 'mdi-transition', mainLayout: null },
        { title: 'Product Type Alias', path: '/product-type-alias', icon: 'mdi-link-variant', mainLayout: null },
        { title: 'Lenders', path: '/lenders', icon: 'mdi-bank', mainLayout: null },
        { title: 'Log Browser', path: '/log-browser', icon: 'mdi-format-list-checks', mainLayout: null },
        {
          title: 'API Partner Log Browser',
          path: '/api-partner-log-browser',
          icon: 'mdi-format-list-checks',
          mainLayout: null,
        },
        {
          title: 'Informed Log Browser',
          path: '/informed-log-browser',
          icon: 'mdi-format-list-checks',
          mainLayout: null,
        },
        { title: 'Export Document', path: '/export-document', icon: 'mdi-file-export-outline', mainLayout: null },
        { title: 'IDP', path: '/idp', icon: 'mdi-text-box-search-outline', mainLayout: null },
        {
          title: 'SF Lender Service Config',
          path: '/sf-lender-service-config',
          icon: 'mdi-mdiSalesforce',
          mainLayout: 'SFComponent',
        },
        {
          title: 'SF Product Audit History',
          path: '/sf-audit-history/product/:sfid',
          icon: 'mdi-mdiSalesforce',
          mainLayout: 'SFComponent',
        },
        {
          title: 'SF Account Audit History',
          path: '/sf-audit-history/account/:sfid',
          icon: 'mdi-mdiSalesforce',
          mainLayout: 'SFComponent',
        },
      ],
    },
  },
  mutations: {
    authUser(state, userData) {
      state.user.idToken = userData.token;
      state.user.userId = userData.userId;
      state.user.userData = userData.userData;
      state.ui.menuItems = userData.menuItems;
    },
    setLender(state, lender) {
      state.ui.lender = lender;
    },
    setLenderState(state, lenderState: boolean) {
      state.ui.lenderState = lenderState;
    },
    setLenderCustomFields(state, lenderCustomFields: { key: string; value: LenderCustomFieldsI[] }) {
      state.ui.lenderCustomFields = lenderCustomFields;
    },
  },
  actions: {
    login({ commit }, authData) {
      axios
        .post<any, AxiosResponse<{ idToken: string; userId: string; menuItems: any[]; userData: any }>>('login-url', {
          email: authData.email,
          password: authData.password,
        })
        .then(res => {
          localStorage.setItem('token', res.data.idToken);
          localStorage.setItem('userId', res.data.userId);
          commit('authUser', {
            token: res.data.idToken,
            userId: res.data.userId,
            menuItems: res.data.menuItems,
            userData: res.data.userData,
          });
          router.push('/');
        })
        .catch(error => console.log(error));
    },
  },
  modules: {},
  getters: {
    menuItems: state => {
      return state.ui.menuItems;
    },
    lender: state => {
      return state.ui.lender;
    },
    lenderState: state => {
      return state.ui.lenderState;
    },
    lenderCustomFields: state => {
      return state.ui.lenderCustomFields;
    },
    getLenderCustomFields: state => (objectType: string, orgId: string) => {
      // @ts-ignore: Unreachable code error
      return (state.ui.lenderCustomFields[orgId] || []).filter((item: LenderCustomFieldsI) => {
        if (item.apiObjectName.toLowerCase().indexOf(objectType) > -1) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
});
