
































import { Vue } from 'vue-property-decorator';
import DataGrid from '@/components/DataGrid.vue';
import PageScrollLayout from '@/components/PageScrollLayout.vue';
import { Account } from '@/interfaces';

/**
 *  Metadata service URL
 */
const metadataEndpointUrl = '/uiapi/grids/informed-account';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/informed-account/search';

export default Vue.extend({
  name: 'IDPPage',
  components: {
    DataGrid,
    PageScrollLayout,
  },
  /**
   * Page Component data object
   */
  data: () => ({
    screen: 'grid' as string,
    screenList: ['grid'] as string[],
    lender: '' as string,
    selectedRows: [] as {}[],
    dataGrid: {
      apiMetadataUrl: metadataEndpointUrl as string,
      apiDataUrl: dataEndpointUrl as string,
      ref: 'dataGrid' as string,
      showSelection: false as boolean,
      defaultQueue: 'All' as string,
    },
  }),
  watch: {
    screen: {
      handler(newScreen, oldScreen) {
        if (oldScreen === 'details' && newScreen === 'grid') {
          this.$getRemoteComponent('AccountListGrid', this).submitDataRequest();
        }
      },
    },
    '$store.getters.lender': {
      handler() {
        this.lender = this.$store.getters.lender as string;
      },
    },
  },
  methods: {
    refreshGrid() {
      this.$getRemoteComponent('AccountListGrid', this).loadData();
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
  },
  created() {
    this.lender = this.$store.getters.lender as string;
  },
  computed: {
    /**
     * Defined action for GridComponent
     */
    actions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit Account',
          /**
           *  Callback function for action click
           *  params:
           *  - rowData - grid data for selected row (Object)
           *  - rowIndex - grid store index for selected row (in case of delete or other manipulation on data) (number)
           *  - gridData - grid store data (Array of Objects)
           */
          callback: (rowData: Account): void => {
            this.$router.push({ path: `/idp-details/${rowData.id as string}` });
          },
        },
      ];
    },
  },
});
