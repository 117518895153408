






















































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import DataGrid from '@/components/DataGrid.vue';
import AccountDetailsForm from './AccountDetailsForm.vue';
import CustomDialog from '@/components/CustomDialog.vue';
import RemoveProductAction from '@/components/RemoveProductAction.vue';
import RemoveProductPaymentAction from '@/components/RemoveProductPaymentAction.vue';
import RemoveAttachmentAction from '@/components/RemoveAttachmentAction.vue';
import CreateNoCancellableProductAction from '../../components/CreateNoCancellableProductAction.vue';
import ProductDetails from '@/components/ProductDetails.vue';
import ProductPaymentDetails from '@/components/ProductPaymentDetails.vue';
import AccountAttachmentForm from '../AccountAttachmentForm.vue';
import S3FileService from '../../services/api/S3File.service';
import { Attachment, Account, ProductPayment, Product } from '../../interfaces';
import AccountService from '../../services/api/Account.service';
import PageScrollLayout from '../PageScrollLayout.vue';
import MetadataService from '@/services/api/Metadata.service';
import AuditHistoryComponent from '@/components/SFComponents/AuditHistory/AuditHistoryComponent.vue';
import UserService from '@/services/api/User.service';
import AttachmentService from '@/services/api/Attachment.service';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';

const _PRODUCT_AVOID_REPROCESSING_STATUSES = ['Missing Data', 'Ready to Send', 'Pending Send', undefined];

export default Vue.extend({
  name: 'AccountDetails',
  components: {
    ConfirmationDialog,
    AccountDetailsForm,
    DataGrid,
    CustomDialog,
    ProductDetails,
    RemoveProductAction,
    RemoveAttachmentAction,
    RemoveProductPaymentAction,
    AccountAttachmentForm,
    ProductPaymentDetails,
    CreateNoCancellableProductAction,
    PageScrollLayout,
    AuditHistoryComponent,
  },
  data: () => ({
    userService: UserService,
    dataPassed: {} as Account,
    dataPassedSnapshot: {} as Account,
    accountFormValid: true as boolean,
    productsCount: 0,
    productsPaymentsCount: 0,
    productDetails: {},
    productPaymentDetails: {},
    productDialogOpen: false,
    attachmentDialogOpen: false,
    productPaymentDialogOpen: false,
    createProductMode: false,
    createProductPaymentMode: false,
    snackBarError: false,
    snackBarErrorMessage: '',
    openNextRecordAfterSaving: false,
    accountIndex: 0 as number,
    saveAndNextButtonVisible: true,
    isValidateButtonClicked: false,
    confirmationDialogExit: {
      processing: false,
      ref: 'ConfirmationDialogExit',
      title: 'Save Product Data' as string,
      message: '' as string,
      errors: [] as string[],
      messages: [] as string[],
    },
    confirmationButtons: [
      {
        name: 'Yes',
        color: 'primary',
        callback: () => {},
      },
      {
        name: 'No',
        color: 'primary',
        callback: () => {},
      },
    ],
  }),
  methods: {
    checkIfReporocessIsRequiredOnSave(): boolean {
      if (this.dataPassed.id) {
        // Required reprocess if even one product is in state equal of after Cancellation Sent
        let productToRepreosess = false;
        if (this.dataPassed?.products) {
          this.dataPassed?.products.forEach((product: Product) => {
            if (!(_PRODUCT_AVOID_REPROCESSING_STATUSES.indexOf(product.productProcessingStatus) > -1)) {
              productToRepreosess = true;
            }
          });
        }
        if (this.dataPassed?.dealer?.id !== this.dataPassedSnapshot?.dealer?.id && productToRepreosess) {
          return true;
        }
        if (this.dataPassed?.VIN !== this.dataPassedSnapshot?.VIN && productToRepreosess) {
          return true;
        }
      }
      return false;
    },
    validateProduct() {
      this.isValidateButtonClicked = true;

      this.getRemoteComponent('productDetails').validateProductRefundData();
    },
    isProductsDetailsFormChanged() {
      return this.getRemoteComponent('productDetails')
        ? this.getRemoteComponent('productDetails').isFormChanged()
        : false;
    },
    updateProductSnapshot() {
      this.dataPassedSnapshot = JSON.parse(JSON.stringify(this.dataPassed));
    },
    async recordDataRefresh() {
      if (this.dataPassed.id !== null) {
        this.dataPassed = (await MetadataService.getRecord(
          { id: this.dataPassed.id },
          'All',
          '/uiapi/account/search',
        )) as Account;
        this.$emit('account-data-refresh', this.dataPassed);
      }
      this.getRemoteComponent('ProductPaymentsGrid').submitDataRequest();
      this.getRemoteComponent('ProductsGrid').submitDataRequest();
      this.getRemoteComponent('AttachmentsGrid').submitDataRequest();
      this.getRemoteComponent('accountDetailsForm')
        .error()
        .clear();
      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
      this.updateProductSnapshot();
    },
    productDeleteSuccess() {
      this.getRemoteComponent('ProductsGrid').submitDataRequest();
      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
    },
    productPaymentDeleteSuccess() {
      this.getRemoteComponent('ProductPaymentsGrid').submitDataRequest();
      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
    },
    attachmentDeleteSuccess() {
      this.getRemoteComponent('AttachmentsGrid').submitDataRequest();
      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
    },
    async submitDataRequestAndRepreocess() {
      await this.getRemoteComponent('accountDetailsForm').submitDataRequest();
      this.updateProductSnapshot();
      if (this.getRemoteComponent('ProductsGrid')) {
        this.getRemoteComponent('ProductsGrid').submitDataRequest();
      }
      this.getRemoteComponent('SaveWithReprocessPromptAccount')
        .external()
        .close();

      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
    },
    async submitDataRequest(openNext: boolean = false) {
      if (openNext) {
        this.openNextRecordAfterSaving = true;
      } else {
        this.openNextRecordAfterSaving = false;
      }
      if (this.checkIfReporocessIsRequiredOnSave()) {
        this.getRemoteComponent('SaveWithReprocessPromptAccount')
          .external()
          .open();
      } else {
        if (this.getRemoteComponent('accountDetailsForm')) {
          await this.getRemoteComponent('accountDetailsForm').submitDataRequest();
          this.updateProductSnapshot();
          if (this.getRemoteComponent('ProductsGrid')) {
            this.getRemoteComponent('ProductsGrid').submitDataRequest();
          }
          if (this.getRemoteComponent('AuditHistoryComponent')) {
            this.getRemoteComponent('AuditHistoryComponent')
              .getRemoteComponent('AuditGrid')
              .loadData();
          }
        }
      }
    },
    noCalcellableProductCreateSuccess(account: Account) {
      this.getRemoteComponent('ProductsGrid').submitDataRequest();
      this.dataPassed.accountProcessingStatus = account.accountProcessingStatus;
      this.dataPassed.dateTimeUpdated = account.dateTimeUpdated;
      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
    },
    editType(): 'UPDATE' | 'INSERT' {
      if (this.getRemoteComponent('accountDetailsForm')) {
        return this.getRemoteComponent('accountDetailsForm').editType();
      }
      return 'UPDATE';
    },
    goBackToGrid() {
      this.$emit('switchScreen', 'grid');
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    getProductPaymentsCount() {
      if (this.getRemoteComponent('ProductPaymentsGrid')) {
        this.productsPaymentsCount = this.getRemoteComponent('ProductPaymentsGrid').dataTable.serverItemsLength;
      }
    },
    getProductsCount() {
      if (this.getRemoteComponent('ProductsGrid')) {
        this.productsCount = this.getRemoteComponent('ProductsGrid').dataTable.serverItemsLength;
      }
    },
    onAccountUpdated(account: Account) {
      if (this.openNextRecordAfterSaving) {
        if (this.filteredAccounts[this.accountIndex + 1]) {
          AccountService.getById(this.filteredAccounts[this.accountIndex + 1].id as number)
            .then(res => {
              this.dataPassed = { ...res };
              this.accountIndex++;
              this.updateSaveAndNextButtonVisibility(this.accountIndex);
            })
            .catch(() => {
              this.snackBarError = true;
            });
        }
      } else {
        this.dataPassed = { ...account };
      }
    },
    onProductUpdated() {
      this.recordDataRefresh();
      this.getRemoteComponent('ProductsGrid').submitDataRequest();
      /**
       * Refresh Account Form After Products Changes
       */
      this.getRemoteComponent('accountDetailsForm').formDataRefresh();
      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
    },
    onProductPaymentUpdated() {
      this.getRemoteComponent('ProductPaymentsGrid').submitDataRequest();
      setTimeout(() => {
        this.setProductPaymentDialogOpen(false);
      }, 2500);
      /**
       * Refresh Account Form After Products Payment Changes
       */
      this.getRemoteComponent('accountDetailsForm').formDataRefresh();
      if (this.getRemoteComponent('AuditHistoryComponent')) {
        this.getRemoteComponent('AuditHistoryComponent')
          .getRemoteComponent('AuditGrid')
          .loadData();
      }
    },
    onAttachmentUploadCompleted() {
      this.getRemoteComponent('AttachmentsGrid').submitDataRequest();
      this.setAttachmentDialogOpen(false);
      /**
       * Refresh Account Form After Products Upload Changes
       */
      this.getRemoteComponent('accountDetailsForm').formDataRefresh();
    },
    addAttachment() {
      this.getRemoteComponent('accountAttachmentForm').createAttachment();
    },
    refreshGridsAndPrepareForm(): void {
      if (this.getRemoteComponent('ProductPaymentsGrid')) {
        this.getRemoteComponent('ProductPaymentsGrid').submitDataRequest();
      }
      if (this.getRemoteComponent('ProductsGrid')) {
        this.getRemoteComponent('ProductsGrid').submitDataRequest();
      }
      if (this.getRemoteComponent('accountDetailsForm')) {
        this.getRemoteComponent('accountDetailsForm').initForm();
      }
    },
    setProductDialogOpen(state: boolean) {
      if (this.isValidateButtonClicked && this.isProductsDetailsFormChanged()) {
        this.getRemoteComponent('CustomDialog').open = true;
        setTimeout(() => {
          this.confirmationButtons = [
            {
              name: 'Yes',
              color: 'primary',
              callback: () => {
                setTimeout(() => {
                  this.getRemoteComponent('ConfirmationDialogExit').open = false;
                  if (state) {
                    this.getRemoteComponent('CustomDialog')
                      .external()
                      .open();
                    this.productDialogOpen = true;
                  } else {
                    this.getRemoteComponent('CustomDialog')
                      .external()
                      .close();
                    this.productDialogOpen = false;
                  }
                  this.isValidateButtonClicked = false;
                }, 100);
              },
            },
            {
              name: 'No',
              color: 'primary',
              callback: () => {
                setTimeout(() => {
                  this.getRemoteComponent('ConfirmationDialogExit').open = false;
                }, 100);
              },
            },
          ];
          this.getRemoteComponent('ConfirmationDialogExit').open = true;
        }, 100);
        return;
      } else {
        if (state) {
          this.getRemoteComponent('CustomDialog')
            .external()
            .open();
          this.productDialogOpen = true;
        } else {
          this.getRemoteComponent('CustomDialog')
            .external()
            .close();
          this.productDialogOpen = false;
        }
      }
    },
    setAttachmentDialogOpen(state: boolean) {
      if (state) {
        this.getRemoteComponent('attachmentDialog')
          .external()
          .open();
        this.attachmentDialogOpen = true;
      } else {
        this.getRemoteComponent('attachmentDialog')
          .external()
          .close();
        this.attachmentDialogOpen = false;
      }
    },
    saveAccountAndOpenProduct() {
      this.submitDataRequest();
      this.setNewProductNotificationDialogOpen(false);
      this.accountFormValid = true;
      this.createProductMode = true;
      this.setProductDialogOpen(true);
    },
    setNewProductNotificationDialogOpen(state: boolean) {
      if (state) {
        this.getRemoteComponent('newProductNotification')
          .external()
          .open();
        this.productPaymentDialogOpen = true;
      } else {
        this.getRemoteComponent('newProductNotification')
          .external()
          .close();
        this.productPaymentDialogOpen = false;
      }
    },
    setProductPaymentDialogOpen(state: boolean) {
      if (state) {
        this.getRemoteComponent('productPaymentDialog')
          .external()
          .open();
        this.productPaymentDialogOpen = true;
      } else {
        this.getRemoteComponent('productPaymentDialog')
          .external()
          .close();
        this.productPaymentDialogOpen = false;
      }
    },
    updateProductDetails() {
      if (!this.createProductMode) {
        this.getRemoteComponent('productDetails').updateProduct();
      } else {
        this.getRemoteComponent('productDetails').createProduct();
      }
    },
    updateProductPaymentDetails() {
      this.getRemoteComponent('productPaymentDetails').updateProductPayment();
    },
    createNoCancellableProduct() {
      this.getRemoteComponent('CreateNoCancellableProductAction').open(this.dataPassed);
    },
    onProductDetailClose() {
      this.setProductDialogOpen(false);
    },
    onAttachmentDetailClose() {
      this.setAttachmentDialogOpen(false);
    },
    onProductPaymentDialogClose() {
      this.setProductPaymentDialogOpen(false);
    },
    updateSaveAndNextButtonVisibility(index: number): void {
      if (this.filteredAccounts.length < 2 || index === this.filteredAccounts.length - 1) {
        this.saveAndNextButtonVisible = false;
      } else {
        this.saveAndNextButtonVisible = true;
      }
    },
  },
  computed: {
    processing(): boolean {
      if (this.getRemoteComponent('accountDetailsForm')) {
        return this.getRemoteComponent('accountDetailsForm').processing;
      }

      return false;
    },
    attachmentDownload() {
      return {
        columnName: 'File',
        items: [
          {
            tooltip: 'Download',
            action: (attachment: Attachment) => {
              S3FileService.getPresignedDownloadUrlForAttachment(
                attachment.fileURL as string,
                attachment.accountNumber as string,
                attachment.orgId as string,
              ).then(url => {
                window.open(url, '_blank');
              });
            },
            checkVisibility: (_item: Attachment) => {
              return true;
            },
            style: { width: '94px', height: '27px', cursor: 'pointer', marginTop: '5px' },
            image: AttachmentService.GetDownloadButton(),
          },
        ],
      };
    },
    attachmentActions() {
      return [
        {
          icon: 'mdi-delete',
          tooltip: 'Delete',
          name: 'Delete',
          disabled: this.disabled,
          hide: (item: Attachment) => {
            return item.createdById && this.userService.getUserId() == item.createdById ? true : false;
          },
          callback: async (rowData: Attachment) => {
            // if (this.accountData.accountProcessingStatus === 'Open') {
            this.getRemoteComponent('RemoveAttachmentAction').open(rowData);
            // } else {
            //   this.snackBarErrorMessage = 'Account Processing Status should be "Open"';
            //   this.snackBarError = true;
            // }
          },
        },
      ];
    },
    productActions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit Product',
          /**
           *  Callback function for action click
           *  params:
           *  - rowData - grid data for selected row (Object)
           *  - rowIndex - grid store index for selected row (in case of delete or other manipulation on data) (number)
           *  - gridData - grid store data (Array of Objects)
           */
          callback: (rowData: {}): void => {
            this.createProductMode = false;
            this.setProductDialogOpen(true);
            this.productDetails = rowData;
          },
        },
        {
          icon: 'mdi-delete',
          tooltip: 'Delete Product',
          disabled: this.disabled,
          /**
           *  Callback function for action click
           *  params:
           *  - rowData - grid data for selected row (Object)
           *  - rowIndex - grid store index for selected row (in case of delete or other manipulation on data) (number)
           *  - gridData - grid store data (Array of Objects)
           */
          callback: (rowData: {}): void => {
            this.getRemoteComponent('RemoveProductAction').open(rowData);
          },
        },
      ];
    },
    productPaymentActions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit Product Payment',
          callback: (rowData: ProductPayment): void => {
            this.createProductPaymentMode = false;
            this.productPaymentDetails = rowData;
            this.setProductPaymentDialogOpen(true);
          },
        },
        {
          icon: 'mdi-delete',
          tooltip: 'Delete',
          name: 'Delete',
          disabled: this.disabled,
          callback: (rowData: ProductPayment) => {
            this.getRemoteComponent('RemoveProductPaymentAction').open(rowData);
          },
        },
      ];
    },
    buttonAttachmentActions() {
      return [
        {
          icon: 'mdi-pencil',
          name: 'Create New Attachment',
          disabled: this.disabled,
          callback: (): void => {
            this.setAttachmentDialogOpen(true);
          },
        },
      ];
    },
    buttonProductActions() {
      return [
        {
          icon: 'mdi-pencil',
          name: 'Create New Product',
          disabled: this.disabled,
          callback: (): void => {
            if (this.dataPassed.id === null) {
              this.getRemoteComponent('accountDetailsForm').resetValidation();
              this.getRemoteComponent('accountDetailsForm').validate();
              this.accountFormValid = this.getRemoteComponent('accountDetailsForm').valid;
              this.setNewProductNotificationDialogOpen(true);
            } else {
              this.accountFormValid = true;
              this.createProductMode = true;
              this.setProductDialogOpen(true);
            }
          },
        },
      ];
    },
    buttonProductPaymentActions() {
      return [
        {
          icon: 'mdi-pencil',
          name: 'Create New Product Payment',
          disabled: this.dataPassed?.accountProcessingStatus === 'Closed' || this.disabled,
          callback: (): void => {
            this.createProductPaymentMode = true;
            this.setProductPaymentDialogOpen(true);
          },
        },
      ];
    },
  },
  created() {
    this.dataPassed = { ...this.accountData };
    this.updateProductSnapshot();
    if (this.filteredAccounts) {
      this.accountIndex = this.selectedAccountIndex;
      this.updateSaveAndNextButtonVisibility(this.accountIndex);
    }
    setTimeout(() => {
      this.getProductPaymentsCount();
      this.getProductsCount();
    }, 2000);
  },
  watch: {
    $props: {
      handler(newValue) {
        if (this.dataPassed.accountNumber !== newValue.accountData.accountNumber) {
          this.refreshGridsAndPrepareForm();
        }
        this.dataPassed = { ...this.accountData };
        if (!newValue.accountData.id) {
          if (this.getRemoteComponent('ProductPaymentsGrid')) {
            this.getRemoteComponent('ProductPaymentsGrid')
              .external()
              .resetGridData();
          }
          if (this.getRemoteComponent('ProductsGrid')) {
            this.getRemoteComponent('ProductsGrid')
              .external()
              .resetGridData();
          }
        }
        setTimeout(() => {
          this.getProductPaymentsCount();
          this.getProductsCount();
        }, 1000);
      },
      deep: true,
    },
  },
  props: {
    accountData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    filteredAccounts: {
      type: Array as PropType<Account[]>,
      default: () => {
        return [];
      },
    },
    selectedAccountIndex: {
      type: Number,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
