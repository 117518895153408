





















































































































































































































import Vue from 'vue';
import ValidationService from './../services/Validations.service';
import { EnumItemI } from './../services/DealerPage.service';
import DealerPageService from './../services/DealerPage.service';
import LenderService from '../services/api/Lender.service';
import { Lender } from './../interfaces';
import EventBus from './../events/EventBus';

export default Vue.extend({
  name: 'LenderCreate',
  data: () => ({
    valid: true,
    overlay: false,
    errors: [] as any[],
    message: '',
    snackBarSuccess: false,
    snackBarError: false,
    editedRecord: { isSalesforce: true } as Lender,
    contractDateMenu: false,
    ValidationService: ValidationService,
    timezonesList: [] as EnumItemI[],
  }),
  async created() {
    this.timezonesList = await DealerPageService.getItems('DealerTimeZones');
  },
  computed: {
    getSalesforceOrgIdRules() {
      if (this.editedRecord.isSalesforce) {
        return [
          ValidationService.getValidator('required', 'Salesforce Org ID'),
          ValidationService.getValidator('maxLength', 'Salesforce Org ID', {
            length: 18,
          }),
          (v: string) => (v && v.length === 18) || 'Org Id should contain 18 characters',
        ];
      }

      return [];
    },
  },
  methods: {
    async createLender() {
      try {
        if ((this.$refs.lenderForm as Vue & { validate: () => boolean }).validate()) {
          this.overlay = true;
          this.error().clear();
          let res = await LenderService.create(this.editedRecord);
          if (res.success) {
            this.snackBarSuccess = true;
            this.overlay = false;
            this.$emit('lenderCreated');
            EventBus.$emit('refresh-lender');
          } else {
            if (res.errors) {
              this.error().set(res.errors);
            }
            this.snackBarError = true;
            this.overlay = false;
          }
        }
      } catch (err) {
        this.error().set([err.response.data.message]);
        this.snackBarError = true;
        this.overlay = false;
      }
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
  },
});
