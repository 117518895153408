

















































import { Vue } from 'vue-property-decorator';
import SfCustomDialog from '@/components/SFComponents/SfCustomDialog.vue';
import SfDataGrid from '@/components/SFComponents/SfDataGrid.vue';
import LenderServiceConfigurationPathsEditForm from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfigurationPathsEditForm.vue';
import { PathI } from '@/interfaces';
import UserService from '@/services/api/User.service';

const emptyPathsRecord = {
  id: null,
  orgId: null,
  orgPreference: null,
  active: false,
  externalId: null,
  dealerStates: [],
  cancelTypes: [],
  productTypes: [],
  effectiveDateType: 'AllTime',
  effectiveDate: null,
  startDate: null,
  endDate: null,
  autoFinalizeAfterCreateProduct: false,
  cancellationSendOnly: false,
  autoSendCancellation: null,
  finalizeAfterSending: null,
  cancellationSendAndGetRefundQuote: false,
  autoSendCancellationRefundQuote: null,
  refundCustomer: false,
  lenderReceivables: false,
  debitDealerReserve: null,
  perDealer: null,
  createdDate: null,
  createdBy: null,
  createdById: null,
  dateTimeUpdated: null,
  updatedBy: null,
  updatedById: null,
  createdByName: null,
  updatedByName: null,
  providers: [],
  dealers: [],
  businessChannelTypes: [],
} as PathI;

export default Vue.extend({
  name: 'LenderServiceConfigurationPaths',
  data() {
    return {
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      saveButtonActive: true,
      editedRecord: null as null | PathI,
      gridApiUrls: {
        metadata: '/uiapi/grids/config-path',
        data: '/uiapi/config-path/search',
        lookups: {},
      },
    };
  },
  components: { SfDataGrid, SfCustomDialog, LenderServiceConfigurationPathsEditForm },
  methods: {
    refreshGrid() {
      this.getRemoteComponent('LenderServiceConfigurationPathsGrid').loadData();
    },
    async saveFormData() {
      this.saveButtonActive = false;
      this.getRemoteComponent('LenderServiceConfigurationPathsEditForm')
        .submitForm()
        .then(() => {
          this.saveButtonActive = true;
          this.getRemoteComponent('PathsCustomDialogForm')
            .external()
            .close();
        })
        .catch(() => {
          this.saveButtonActive = true;
        });
    },
    addNewPathsRule() {
      this.editedRecord = JSON.parse(JSON.stringify(emptyPathsRecord));
      if (this.editedRecord) {
        this.editedRecord.orgId = this.orgId;
      }
    },
    editRecord(record: PathI) {
      this.editedRecord = JSON.parse(JSON.stringify(record));
      this.openEditDialog();
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    openEditDialog() {
      this.getRemoteComponent('PathsCustomDialogForm')
        .external()
        .open();
    },
  },
  props: ['footerHeight', 'orgId'],
});
