var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    attrs: {
      outlined: "",
      dense: "",
      "hide-details": _vm.hideDetails,
      rules: [
        _vm.services.validationService.getValidator(
          "required",
          _vm.fieldDefinitionSync.fieldLabel
        )
      ]
    },
    on: { keypress: _vm.$keyPressFloatValidation },
    model: {
      value: _vm.fieldValueSync.value,
      callback: function($$v) {
        _vm.$set(_vm.fieldValueSync, "value", $$v)
      },
      expression: "fieldValueSync.value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }