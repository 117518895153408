var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-layout",
    { attrs: { pageName: "Provider Forms", pageIcon: "mdi-form-select" } },
    [
      _c("div", { attrs: { slot: "toolbar" }, slot: "toolbar" }),
      _c(
        "div",
        { attrs: { slot: "buttons" }, slot: "buttons" },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "900px", scrollable: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            staticClass: "dealer-page-button",
                            attrs: {
                              color: "primary",
                              elevation: "2",
                              disabled: false,
                              medium: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.openDialog()
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" NEW PROVIDER FORM ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "dialog-card-header" }, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Provider Form Data")
                    ])
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "confirmation-dialog-close-button",
                      attrs: { center: "", large: "", color: "white" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" mdi-close ")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticStyle: { height: "95%" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    directives: [
                                      {
                                        name: "visible",
                                        rawName: "v-visible",
                                        value: _vm.overlay,
                                        expression: "overlay"
                                      }
                                    ],
                                    attrs: {
                                      indeterminate: "",
                                      query: true,
                                      color: "blue"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.messages.length > 0,
                                  expression: "messages.length > 0"
                                }
                              ]
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            "line-height": "15px"
                                          },
                                          attrs: {
                                            type: "info",
                                            border: "left",
                                            text: "",
                                            outlined: "",
                                            dense: "",
                                            autocomplete: "none"
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c("b", [_vm._v("Mesagess:")])
                                          ]),
                                          _vm._l(_vm.messages, function(
                                            message,
                                            messageIndex
                                          ) {
                                            return _c(
                                              "div",
                                              { key: messageIndex },
                                              [_vm._v("* " + _vm._s(message))]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.length > 0,
                                  expression: "errors.length > 0"
                                }
                              ]
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          staticClass:
                                            "confirmation-dialog-errors",
                                          attrs: {
                                            type: "error",
                                            border: "left",
                                            text: "",
                                            outlined: "",
                                            dense: "",
                                            autocomplete: "none"
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c("b", [_vm._v("Errors:")])
                                          ]),
                                          _vm._l(_vm.errors, function(
                                            error,
                                            errorIndex
                                          ) {
                                            return _c(
                                              "div",
                                              { key: errorIndex },
                                              [_vm._v("* " + _vm._s(error))]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-form",
                            {
                              ref: "providerForm",
                              attrs: {
                                "lazy-validation": "",
                                readonly: _vm.overlay
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-container",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-checkbox", {
                                                            attrs: {
                                                              label: "Active"
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .active,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "active",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.active"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-autocomplete", {
                                                            attrs: {
                                                              dense: "",
                                                              items:
                                                                _vm.providerListOptions,
                                                              label: "Provider",
                                                              clearable: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .providerId,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "providerId",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.providerId"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              label: "Name",
                                                              rules:
                                                                _vm.nameInputRules,
                                                              required: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.name"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              label:
                                                                "Form Identifier"
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .formIdentifier,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "formIdentifier",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.formIdentifier"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "custom-date-picker",
                                                            {
                                                              attrs: {
                                                                "prepend-icon":
                                                                  "mdi-calendar",
                                                                label:
                                                                  "Form date",
                                                                disableclear: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .editedRecord
                                                                    .formDate,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.editedRecord,
                                                                    "formDate",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "editedRecord.formDate"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-file-input", {
                                                            attrs: {
                                                              "show-size": "",
                                                              label:
                                                                "File input",
                                                              accept: ".pdf",
                                                              rules:
                                                                _vm.editActionType ===
                                                                "INSERT"
                                                                  ? _vm.selectedFileRules
                                                                  : [],
                                                              required: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.formValueChange()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedFile,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.selectedFile = $$v
                                                              },
                                                              expression:
                                                                "selectedFile"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-3",
                                      attrs: { text: "", elevation: "2" },
                                      on: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary",
                                      attrs: {
                                        text: "",
                                        elevation: "2",
                                        disabled: _vm.overlay
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.submitDataRequest()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.editActionType === "UPDATE"
                                              ? "Update Provider Form"
                                              : "Add New Provider Form"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "custom-dialog",
            {
              ref: "PreviewDialog",
              attrs: { errors: _vm.errorsPDF, "max-width": "35%" }
            },
            [
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v("Preview")
              ]),
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: " pa-3" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-0 pa-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "ma-0 pa-0" },
                                [
                                  _c("search-input-field", {
                                    attrs: {
                                      fieldProps: {
                                        value: _vm.selectedProductName,
                                        readonly: true,
                                        label: "Product",
                                        dense: true
                                      },
                                      apiMetadataUrl: "/uiapi/grids/product",
                                      apiDataUrl: "/uiapi/product/search",
                                      title: "Search Product by Account Number",
                                      actions: [],
                                      columns: [
                                        {
                                          text: "Product Type",
                                          value: "productType"
                                        },
                                        {
                                          text: "Account Number",
                                          value: "accountNumber"
                                        },
                                        {
                                          text: "Created at",
                                          value: "dateTimeCreated",
                                          parse: "toDateTime"
                                        }
                                      ],
                                      selectEvent: _vm.selectProduct,
                                      itemsPerPage: 10,
                                      searchField: "accountNumber",
                                      additionalConditions: _vm.getAdditionalConditionsProductSearch(),
                                      initialSortOrder: [
                                        {
                                          fieldName: "dateTimeCreated",
                                          desc: true
                                        }
                                      ]
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "buttons" }, slot: "buttons" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3 default",
                      attrs: { elevation: "2" },
                      on: {
                        click: function($event) {
                          _vm
                            .getRemoteComponent("PreviewDialog")
                            .external()
                            .close()
                          _vm.currentProviderFormId = null
                          _vm.selectedProductName = ""
                          _vm.errorsPDF = []
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: {
                        elevation: "2",
                        disabled: !_vm.selectedProductId
                      },
                      on: {
                        click: function($event) {
                          return _vm.generateProviderFormPreview(
                            _vm.selectedProductId,
                            _vm.currentProviderFormId
                          )
                        }
                      }
                    },
                    [_vm._v("Preview")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("data-grid", {
            key: _vm.reRenderGrid,
            attrs: {
              "api-metadata-url": _vm.endPoint.metadataEndpointUrl,
              "api-data-url": _vm.endPoint.dataEndpointUrl,
              "footer-height": 70,
              itemsPerPage: [50, 100, 200, 300, 400, 500],
              itemsPerPageInitial: 100,
              actions: _vm.actions,
              "fixed-conditions": [],
              "fixed-conditions-prevent-call": false,
              headersAutoParserMapping: "",
              buttonActions: _vm.buttonActions
            },
            on: { "on-meta-data-loaded": _vm.onMetaDataLoaded }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }