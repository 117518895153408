var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-layout",
        { attrs: { pageName: "Provider Alias", pageIcon: "mdi-transition" } },
        [
          _c("div", { attrs: { slot: "toolbar" }, slot: "toolbar" }),
          _c(
            "div",
            { attrs: { slot: "buttons" }, slot: "buttons" },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  attrs: { color: "primary", elevation: "2", medium: "" },
                  on: {
                    click: function($event) {
                      return _vm.openEditDialog()
                    }
                  }
                },
                [_vm._v(" NEW ALIAS ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "content", elevation: "2" }, slot: "content" },
            [_c("provider-alias-edit-grid", { ref: "providerAliasEditGrid" })],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }