var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      staticClass: "data-grid-main-container",
      attrs: { fluid: "" }
    },
    [
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hideFilterSetup,
              expression: "!hideFilterSetup"
            }
          ]
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-autocomplete", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      (_vm.gridData.metaData.filterSets || []).length >= 2 &&
                      _vm.showFilterSets,
                    expression:
                      "(gridData.metaData.filterSets || []).length >= 2 && showFilterSets"
                  }
                ],
                staticClass: "pt-0 queue-list-control",
                attrs: {
                  "return-object": "",
                  items: (_vm.gridData.metaData.filterSets || []).filter(
                    function(filter) {
                      return filter.optional === false
                    }
                  ),
                  "item-text": "filterSetName",
                  label: "Queue",
                  dense: "",
                  solo: "",
                  "hide-details": ""
                },
                on: {
                  change: function($event) {
                    return _vm.listViewChange()
                  }
                },
                model: {
                  value: _vm.selection.filterSet,
                  callback: function($$v) {
                    _vm.$set(_vm.selection, "filterSet", $$v)
                  },
                  expression: "selection.filterSet"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { cols: "10" }
                    },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.buttonActionsHide &&
                              _vm.buttonActions.length > 1,
                            expression:
                              "!buttonActionsHide && buttonActions.length > 1"
                          }
                        ],
                        staticClass: "pt-0",
                        attrs: {
                          "return-object": "",
                          color: "#90CAF9",
                          items: _vm._f("filterActions")(_vm.buttonActions),
                          "item-text": "name",
                          label: "Select Action",
                          dense: "",
                          solo: "",
                          "hide-details": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.buttonActionExecute()
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      staticStyle: { "margin-right": "10px" },
                                      attrs: {
                                        disabled: item.disabled ? true : false,
                                        small: ""
                                      }
                                    },
                                    [_vm._v(_vm._s(item.icon))]
                                  ),
                                  _vm._v(" " + _vm._s(item.name) + " ")
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.selectedAction,
                          callback: function($$v) {
                            _vm.selectedAction = $$v
                          },
                          expression: "selectedAction"
                        }
                      }),
                      !_vm.buttonActionsHide && _vm.buttonActions.length === 1
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.buttonActions[0].disabled
                                  ? true
                                  : false,
                                left: "",
                                elevation: "2"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.buttonActionExecute(
                                    _vm.buttonActions[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(
                                  " " + _vm._s(_vm.buttonActions[0].icon) + " "
                                )
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.buttonActions[0].name) + " "
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "text-align": "right",
                        "white-space": "nowrap"
                      },
                      attrs: { cols: "2" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary ml-1",
                          attrs: {
                            elevation: "2",
                            icon: "",
                            tile: "",
                            medium: "",
                            color: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.refreshData()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: { center: "", medium: "", color: "white" }
                            },
                            [_vm._v(" mdi-refresh ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.filterButtonHide,
                              expression: "!filterButtonHide"
                            }
                          ],
                          class: _vm.filterVisible ? "primary" : "default",
                          attrs: {
                            elevation: "2",
                            icon: "",
                            tile: "",
                            medium: "",
                            color: _vm.filterVisible ? "primary" : "default"
                          },
                          on: {
                            click: function($event) {
                              _vm.filterVisible = !_vm.filterVisible
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                center: "",
                                medium: "",
                                color: _vm.filterVisible ? "white" : "default"
                              }
                            },
                            [_vm._v(" mdi-filter-outline ")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    (_vm.selection.subFilters
                                      ? _vm.selection.subFilters.length
                                      : 0) > 0,
                                  expression:
                                    "(selection.subFilters ? selection.subFilters.length : 0) > 0"
                                }
                              ],
                              staticStyle: {
                                position: "absolute",
                                top: "-13px",
                                right: "-5px",
                                "background-color": "#66BB6A",
                                color: "white",
                                padding: "1px",
                                "padding-right": "2px",
                                "padding-left": "2px",
                                "font-size": "11px",
                                "border-radius": "3px",
                                border: "1px solid #A5D6A7"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selection.subFilters
                                      ? _vm.selection.subFilters.length
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              { ref: "resizableDiv", staticClass: "grid-placeholeder" },
              [
                _c(
                  "v-data-table",
                  _vm._b(
                    {
                      staticClass: "elevation-2",
                      attrs: {
                        "hide-default-footer": _vm.hideFooter,
                        headers: _vm.getHeaders,
                        options: _vm.dataTable.options,
                        loading: _vm.processing
                      },
                      on: {
                        "update:options": function($event) {
                          return _vm.$set(_vm.dataTable, "options", $event)
                        },
                        "click:row": _vm.rowClickEvent,
                        "update:sort-by": function($event) {
                          return _vm.storeCurrentGridState()
                        },
                        "update:sort-desc": function($event) {
                          return _vm.storeCurrentGridState()
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.Actions",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "div",
                                  { staticStyle: { "white-space": "nowrap" } },
                                  _vm._l(_vm.actions, function(
                                    action,
                                    actionIndex
                                  ) {
                                    return _c(
                                      "v-tooltip",
                                      {
                                        key: actionIndex,
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              typeof action.hide ===
                                                              "function"
                                                                ? action.hide(
                                                                    item
                                                                  )
                                                                : action.hide ===
                                                                  true
                                                                ? false
                                                                : true,
                                                            expression:
                                                              "\n                      typeof action.hide === 'function' ? action.hide(item) : action.hide === true ? false : true\n                    "
                                                          }
                                                        ],
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          small: "",
                                                          disabled: _vm._f(
                                                            "actionDisabled"
                                                          )(action, item),
                                                          color: action.color
                                                            ? action.color
                                                            : "#6691c5"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            action.callback(
                                                              item,
                                                              _vm.getArrayIndexOfRow(
                                                                item[
                                                                  _vm
                                                                    .dataIdField
                                                                ]
                                                              ),
                                                              _vm.gridData
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(action.icon) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("actionTooltip")(
                                                action,
                                                item
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "item.Previews",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "div",
                                  { staticStyle: { "white-space": "nowrap" } },
                                  _vm._l(_vm.previews.items, function(
                                    previewItem,
                                    previewItemIndex
                                  ) {
                                    return _c(
                                      "v-tooltip",
                                      {
                                        key: previewItemIndex,
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "img",
                                                    _vm._g(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: previewItem.checkVisibility(
                                                              item
                                                            ),
                                                            expression:
                                                              "previewItem.checkVisibility(item)"
                                                          }
                                                        ],
                                                        style: previewItem.style || {
                                                          width: "28px",
                                                          height: "28px",
                                                          cursor: "pointer"
                                                        },
                                                        attrs: {
                                                          src:
                                                            typeof previewItem.image ===
                                                            "string"
                                                              ? previewItem.image
                                                              : previewItem.image(
                                                                  item
                                                                )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            previewItem.action(
                                                              item,
                                                              _vm.getArrayIndexOfRow(
                                                                item[
                                                                  _vm
                                                                    .dataIdField
                                                                ]
                                                              ),
                                                              _vm.gridData
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              typeof previewItem.tooltip ===
                                                "string"
                                                ? previewItem.tooltip
                                                : previewItem.tooltip(item)
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ]
                            }
                          },
                          _vm._l(
                            _vm.getHeaders.filter(function(header) {
                              return header.hasOwnProperty("parse")
                            }),
                            function(header) {
                              return {
                                key: "item." + header.value,
                                fn: function(ref) {
                                  var item = ref.item
                                  var value = ref.value
                                  return [
                                    _c(
                                      "div",
                                      { key: header.value },
                                      [
                                        header.parserCode ==
                                        "displayValueTextToToolTip"
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticStyle: {
                                                                    cursor:
                                                                      "pointer"
                                                                  }
                                                                },
                                                                "span",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    value
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(header.parse(value))
                                                  )
                                                ])
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.cellClickEvent(
                                                      header,
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      header.parse(value)
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            }
                          ),
                          _vm.viewAll
                            ? {
                                key: "footer",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pa-0 pl-5 pt-5",
                                        staticStyle: {
                                          position: "absolute",
                                          "font-size": "14px",
                                          "line-height": "15px",
                                          "text-decoration": "underline",
                                          cursor: "pointer"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              click: function($event) {
                                                _vm.viewAllDialog = true
                                              }
                                            }
                                          },
                                          [_vm._v("view all")]
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            : null
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.selectedRows,
                        callback: function($$v) {
                          _vm.selectedRows = $$v
                        },
                        expression: "selectedRows"
                      }
                    },
                    "v-data-table",
                    _vm.dataTable,
                    false
                  ),
                  [
                    _vm.errors.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "data-grid-loading-container",
                            attrs: { slot: "loading" },
                            slot: "loading"
                          },
                          [
                            _c(
                              "v-alert",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  "line-height": "15px",
                                  width: "100%",
                                  "text-align": "left"
                                },
                                attrs: {
                                  type: "error",
                                  border: "left",
                                  text: "",
                                  outlined: "",
                                  dense: ""
                                }
                              },
                              [
                                _c("div", [_c("b", [_vm._v("Errors:")])]),
                                _vm._l(_vm.errors, function(error, errorIndex) {
                                  return _c("div", { key: errorIndex }, [
                                    _vm._v("* " + _vm._s(error))
                                  ])
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                ),
                _vm.viewAll
                  ? _c(
                      "v-dialog",
                      {
                        staticStyle: { position: "relative" },
                        attrs: {
                          persistent: "",
                          scrollable: false,
                          "min-height": "95%",
                          height: "95%"
                        },
                        model: {
                          value: _vm.viewAllDialog,
                          callback: function($$v) {
                            _vm.viewAllDialog = $$v
                          },
                          expression: "viewAllDialog"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              height: "90vh",
                              width: "100%",
                              display: "flex",
                              "flex-direction": "column"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "headline dialog-card-header",
                                staticStyle: { height: "50px" }
                              },
                              [
                                _c("div", { staticClass: "pt-2 pl-2 " }, [
                                  _vm._v("View All")
                                ]),
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      cursor: "pointer"
                                    },
                                    attrs: {
                                      center: "",
                                      large: "",
                                      color: "white"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.viewAllDialog = false
                                      }
                                    }
                                  },
                                  [_vm._v(" mdi-close ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  flex: "1",
                                  background: "#eee"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "0px",
                                      left: "0px",
                                      right: "0px",
                                      bottom: "0px",
                                      border: "1px solid gold"
                                    }
                                  },
                                  [
                                    _vm.viewAllDialog
                                      ? _c("data-grid", {
                                          ref: "viewAllGrid",
                                          attrs: {
                                            buttonActionsHide: true,
                                            listViewHide: true,
                                            filterButtonHide: true,
                                            hideFilterSetup: true,
                                            itemsPerPageInitial: 1000,
                                            itemsPerPage: [10, 500, 1000, 5000],
                                            "api-metadata-url":
                                              _vm.apiMetadataUrl,
                                            "api-data-url": _vm.apiDataUrl,
                                            "fixed-conditions":
                                              _vm.fixedConditions,
                                            "fixed-conditions-prevent-call": false,
                                            "view-all": false,
                                            footerHeight: 124
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.filterVisible && !_vm.hideFilterSetup,
              expression: "filterVisible && !hideFilterSetup"
            }
          ],
          staticClass: "data-grid-filter-overlay-container"
        },
        [
          _c(
            "div",
            { staticStyle: { xborder: "1px solid red", position: "relative" } },
            [
              _c(
                "v-container",
                {
                  staticClass: "pa-8",
                  staticStyle: { "white-space": "nowrap" }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { "white-space": "nowrap" },
                      attrs: { justify: "center" }
                    },
                    [
                      _c("v-autocomplete", {
                        staticStyle: { "margin-right": "5px" },
                        attrs: {
                          "return-object": "",
                          items: _vm.gridData.metaData.subFilterSets,
                          "item-text": "subFilterName",
                          placeholder: "Select Filter",
                          dense: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.selection.subFilter,
                          callback: function($$v) {
                            _vm.$set(_vm.selection, "subFilter", $$v)
                          },
                          expression: "selection.subFilter"
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: {
                            elevation: "2",
                            icon: "",
                            tile: "",
                            small: "",
                            color: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.addSubFilter()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: { center: "", small: "", color: "white" }
                            },
                            [_vm._v(" mdi-plus ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "filters-container-box",
              class:
                _vm.queryLogicExpanded === false ? "query-logic-collapsed" : ""
            },
            [
              _c(
                "v-container",
                { staticStyle: { "padding-top": "0px" }, attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "flex-direction": "column-reverse",
                        display: "flex"
                      }
                    },
                    _vm._l(_vm.selection.subFilters, function(
                      subFilter,
                      subFilterIndex
                    ) {
                      return _c(
                        "div",
                        {
                          key: subFilterIndex,
                          staticStyle: { padding: "1px" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-0",
                              staticStyle: {
                                "margin-top": "15px",
                                "margin-bottom": "15px"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-2 pl-0 pr-0",
                                  staticStyle: {
                                    position: "absolute",
                                    top: "2px",
                                    left: "-4px"
                                  },
                                  attrs: {
                                    elevation: "0",
                                    "x-small": "",
                                    color: "primary",
                                    outlined: "",
                                    text: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selection.subFilters.splice(
                                        subFilterIndex,
                                        1
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(subFilterIndex + 1))]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-2",
                                  staticStyle: {
                                    position: "absolute",
                                    top: "2px",
                                    right: "-4px"
                                  },
                                  attrs: {
                                    elevation: "1",
                                    right: "",
                                    icon: "",
                                    tile: "",
                                    "x-small": "",
                                    color: "default"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.selection.subFilters.splice(
                                        subFilterIndex,
                                        1
                                      )
                                      _vm.removeSubFilter()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        center: "",
                                        small: "",
                                        color: "default"
                                      }
                                    },
                                    [_vm._v(" mdi-close ")]
                                  )
                                ],
                                1
                              ),
                              _vm._l(subFilter.conditions, function(
                                condition,
                                conditionIndex
                              ) {
                                return _c(
                                  "v-row",
                                  {
                                    key: conditionIndex,
                                    staticClass: "data-grid-shrink-filters"
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-1",
                                        attrs: { cols: "5" }
                                      },
                                      [
                                        _c("v-card-text", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getHeaderText(
                                                condition.fieldName
                                              ) ||
                                                _vm.fixCamelCaseToWords(
                                                  condition.fieldName
                                                )
                                            )
                                          )
                                        ]),
                                        condition.conditionComparatorOption ===
                                        "between"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "ml-4",
                                                staticStyle: {
                                                  position: "absolute",
                                                  color: "red",
                                                  "font-weigth": "bold",
                                                  "font-size": "14px"
                                                },
                                                style: {
                                                  color:
                                                    _vm.checkBetween(
                                                      condition
                                                    ) === true
                                                      ? "#888"
                                                      : "red"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.checkBetween(
                                                        condition
                                                      ) === true
                                                        ? _vm.calculateDays(
                                                            condition
                                                          )
                                                        : _vm.checkBetween(
                                                            condition
                                                          )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    [
                                      "String",
                                      "Date",
                                      "Enum",
                                      "Integer",
                                      "Float"
                                    ].indexOf(condition.fieldType) > -1 &&
                                    condition.conditionComparatorOptions
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1  pt-3",
                                            attrs: { cols: "4" }
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                rounded: "",
                                                dense: "",
                                                "hide-details": "",
                                                items:
                                                  condition.conditionComparatorOptions
                                              },
                                              on: {
                                                change: function($event) {
                                                  ;["in", "not in"].indexOf(
                                                    condition.conditionComparatorOption
                                                  ) > -1
                                                    ? ["Enum"].indexOf(
                                                        condition.fieldType
                                                      ) > -1
                                                      ? (condition.value = [])
                                                      : (condition.value =
                                                          condition.value)
                                                    : (condition.value =
                                                        condition.value)
                                                }
                                              },
                                              model: {
                                                value:
                                                  condition.conditionComparatorOption,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    condition,
                                                    "conditionComparatorOption",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "condition.conditionComparatorOption"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    ["Checkbox", "Boolean"].indexOf(
                                      condition.fieldType
                                    ) > -1
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1  pt-3",
                                            attrs: { cols: "4" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                dense: "",
                                                "hide-details": "",
                                                readonly: ""
                                              },
                                              model: {
                                                value:
                                                  condition.conditionComparatorOption,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    condition,
                                                    "conditionComparatorOption",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "condition.conditionComparatorOption"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    ["Enum"].indexOf(condition.fieldType) > -1
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1  pt-3",
                                            attrs: { cols: "3" }
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                autocomplete: "none",
                                                dense: "",
                                                multiple:
                                                  ["in", "not in"].indexOf(
                                                    condition.conditionComparatorOption
                                                  ) > -1,
                                                "hide-details": "",
                                                items: condition.fieldOptions
                                              },
                                              model: {
                                                value: condition.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    condition,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "condition.value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    ["String"].indexOf(condition.fieldType) > -1
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1  pt-3",
                                            attrs: { cols: "3" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                autocomplete: "none",
                                                dense: "",
                                                "hide-details": ""
                                              },
                                              on: {
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.refreshData()
                                                }
                                              },
                                              model: {
                                                value: condition.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    condition,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "condition.value"
                                              }
                                            }),
                                            _vm.checkIfPassable(
                                              condition.conditionComparatorOption,
                                              condition.value
                                            )
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    condition.value.split(","),
                                                    function(item, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item &&
                                                                item !== "",
                                                              expression:
                                                                "item && item !== ''"
                                                            }
                                                          ],
                                                          key: index,
                                                          staticStyle: {
                                                            margin: "1px",
                                                            border:
                                                              "1px solid #eee",
                                                            "padding-top":
                                                              "3px",
                                                            position:
                                                              "relative",
                                                            "padding-right":
                                                              "15px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(item) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticStyle: {
                                                                position:
                                                                  "absolute",
                                                                right: "4px",
                                                                top: "5px",
                                                                cursor:
                                                                  "pointer",
                                                                "border-radius":
                                                                  "3px"
                                                              },
                                                              attrs: {
                                                                center: "",
                                                                small: "",
                                                                color: "default"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  condition.value = condition.value
                                                                    .split(",")
                                                                    .filter(
                                                                      function(
                                                                        filteredItem,
                                                                        itemIndex
                                                                      ) {
                                                                        return (
                                                                          itemIndex !=
                                                                          index
                                                                        )
                                                                      }
                                                                    )
                                                                    .join(",")
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-close "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    ["Float"].indexOf(condition.fieldType) > -1
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1  pt-3",
                                            attrs: { cols: "3" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                autocomplete: "none",
                                                dense: "",
                                                "hide-details": ""
                                              },
                                              on: {
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.refreshData()
                                                },
                                                keydown: _vm.decimalOnly
                                              },
                                              model: {
                                                value: condition.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    condition,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "condition.value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    ["Integer"].indexOf(condition.fieldType) >
                                    -1
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1  pt-3",
                                            attrs: { cols: "3" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              directives: [
                                                {
                                                  name: "mask",
                                                  rawName: "v-mask",
                                                  value: "###########",
                                                  expression: "'###########'"
                                                }
                                              ],
                                              attrs: {
                                                autocomplete: "none",
                                                dense: "",
                                                "hide-details": ""
                                              },
                                              on: {
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.refreshData()
                                                }
                                              },
                                              model: {
                                                value: condition.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    condition,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "condition.value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    ["Checkbox", "Boolean"].indexOf(
                                      condition.fieldType
                                    ) > -1
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1  pt-3",
                                            attrs: { cols: "3" }
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                dense: "",
                                                autocomplete: "none",
                                                items: condition.fieldOptions
                                              },
                                              model: {
                                                value: condition.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    condition,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "condition.value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    ["Date"].indexOf(condition.fieldType) > -1
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1  pt-3",
                                            attrs: { cols: "3" }
                                          },
                                          [
                                            _c(
                                              "v-menu",
                                              {
                                                ref: "condition.dateMenu",
                                                refInFor: true,
                                                attrs: {
                                                  "close-on-content-click": false
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    autocomplete:
                                                                      "none",
                                                                    readonly:
                                                                      "",
                                                                    value: _vm.parseDate(
                                                                      condition.value
                                                                    ),
                                                                    "prepend-icon":
                                                                      "mdi-calendar",
                                                                    "hide-details":
                                                                      "",
                                                                    dense: ""
                                                                  }
                                                                },
                                                                "v-text-field",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: condition.dateMenu,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      condition,
                                                      "dateMenu",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "condition.dateMenu"
                                                }
                                              },
                                              [
                                                _c("v-date-picker", {
                                                  attrs: { "no-title": "" },
                                                  on: {
                                                    input: function($event) {
                                                      condition.dateMenu = false
                                                    }
                                                  },
                                                  model: {
                                                    value: condition.value,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        condition,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "condition.value"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            condition.conditionComparatorOption ===
                                            "between"
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-menu",
                                                      {
                                                        ref:
                                                          "condition.dateMenuEnd",
                                                        refInFor: true,
                                                        attrs: {
                                                          "close-on-content-click": false
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-text-field",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          attrs: {
                                                                            autocomplete:
                                                                              "none",
                                                                            readonly:
                                                                              "",
                                                                            value: _vm.parseDate(
                                                                              condition.valueEnd
                                                                            ),
                                                                            "prepend-icon":
                                                                              "mdi-calendar",
                                                                            "hide-details":
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            rules:
                                                                              condition.conditionComparatorOption ===
                                                                              "between"
                                                                                ? [
                                                                                    _vm.checkBetween(
                                                                                      condition
                                                                                    )
                                                                                  ]
                                                                                : []
                                                                          }
                                                                        },
                                                                        "v-text-field",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            condition.dateMenuEnd,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              condition,
                                                              "dateMenuEnd",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "condition.dateMenuEnd"
                                                        }
                                                      },
                                                      [
                                                        _c("v-date-picker", {
                                                          attrs: {
                                                            "no-title": ""
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              condition.dateMenuEnd = false
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              condition.valueEnd,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                condition,
                                                                "valueEnd",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "condition.valueEnd"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.updateRefresh
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "primary ml-1",
                                            staticStyle: {
                                              position: "absolute",
                                              bottom: "-8px",
                                              right: "-32px",
                                              transform: "scale(0.6)",
                                              "transform-origin": "center",
                                              "border-radius": "5px"
                                            },
                                            attrs: {
                                              elevation: "2",
                                              icon: "",
                                              tile: "",
                                              medium: "",
                                              color: "white"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.refreshData()
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  center: "",
                                                  medium: "",
                                                  color: "default"
                                                }
                                              },
                                              [_vm._v(" mdi-refresh ")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.updateRefresh
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "default ml-1",
                                            staticStyle: {
                                              position: "absolute",
                                              bottom: "-8px",
                                              right: "-32px",
                                              transform: "scale(0.6)",
                                              "transform-origin": "center",
                                              "border-radius": "5px"
                                            },
                                            attrs: {
                                              elevation: "2",
                                              icon: "",
                                              tile: "",
                                              medium: "",
                                              color: "default"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.refreshData()
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  center: "",
                                                  medium: "",
                                                  color: "default"
                                                }
                                              },
                                              [_vm._v(" mdi-refresh ")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "v-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.selection.filterSet || { conditions: [] })
                              .conditions.length > 0,
                          expression:
                            "(selection.filterSet || { conditions: [] }).conditions.length > 0"
                        }
                      ],
                      staticClass: "pa-0",
                      staticStyle: {
                        "margin-top": "20px",
                        "margin-bottom": "20px"
                      },
                      on: {
                        click: function($event) {
                          _vm.queueConditionsExpanded = !_vm.queueConditionsExpanded
                        }
                      }
                    },
                    [
                      _c(
                        "v-card-text",
                        {
                          staticClass: "ma-0 pa-4",
                          staticStyle: { "padding-top": "5px !important" }
                        },
                        [
                          _vm._v("Queue conditions: "),
                          _c("v-icon", { staticStyle: { float: "right" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.queueConditionsExpanded
                                    ? "mdi-chevron-down"
                                    : "mdi-chevron-up"
                                )
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.queueConditionsExpanded
                        ? _c(
                            "div",
                            _vm._l(
                              (_vm.selection.filterSet || { conditions: [] })
                                .conditions,
                              function(filterCondition, filterConditionIndex) {
                                return _c(
                                  "v-row",
                                  {
                                    key:
                                      "FilterConditionIndex" +
                                      filterConditionIndex,
                                    staticClass: "data-grid-shrink-filters"
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-1",
                                        attrs: { cols: "4" }
                                      },
                                      [
                                        _c(
                                          "v-card-text",
                                          {
                                            staticStyle: {
                                              "padding-top": "0px",
                                              "margin-top": "0px"
                                            }
                                          },
                                          [
                                            _vm.selection.filterSet &&
                                            _vm.selection.filterSet
                                              .conditionsQueryLogic
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        filterConditionIndex + 1
                                                      ) +
                                                      ")"
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getHeaderText(
                                                    filterCondition.fieldName
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-1",
                                        attrs: { cols: "5" }
                                      },
                                      [
                                        _c(
                                          "v-card-text",
                                          {
                                            staticStyle: {
                                              "padding-top": "0px",
                                              "margin-top": "0px",
                                              "text-align": "center"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.extractValue(
                                                  filterCondition
                                                    .conditionComparatorOptions[0]
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-1",
                                        attrs: { cols: "3" }
                                      },
                                      [
                                        _c(
                                          "v-card-text",
                                          {
                                            staticStyle: {
                                              "padding-top": "0px",
                                              "margin-top": "0px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                filterCondition.value ===
                                                  null ||
                                                  JSON.stringify(
                                                    filterCondition.value
                                                  ) === "[null]"
                                                  ? "Empty"
                                                  : _vm.getHeaderFormatParser(
                                                      filterCondition.value,
                                                      filterCondition.fieldName,
                                                      filterCondition.fieldType,
                                                      filterCondition
                                                        .conditionComparatorOptions[0]
                                                    )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.queueConditionsExpanded,
                              expression: "queueConditionsExpanded"
                            }
                          ]
                        },
                        [
                          _vm.selection.filterSet &&
                          _vm.selection.filterSet.conditionsQueryLogic
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pa-4 pb-2",
                                  staticStyle: {
                                    "font-size": "11px",
                                    "line-height": "15px"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        padding: "10px",
                                        border: "1px solid #aaa",
                                        "border-radius": "4px",
                                        position: "relative",
                                        color: "#888"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "-8px",
                                            left: "7px",
                                            background: "white",
                                            "padding-left": "5px",
                                            "padding-right": "5px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " Queue condition Query Logic "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selection.filterSet
                                              .conditionsQueryLogic
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                bottom: "3px",
                left: "3px",
                right: "3px",
                height: "35px",
                overflow: "hidden",
                padding: "5px",
                "padding-left": "20px",
                background: "white",
                "border-radius": "5px",
                "padding-top": "8px",
                "font-size": "13px",
                "font-weight": "bold",
                cursor: "pointer"
              },
              on: {
                click: function($event) {
                  _vm.queryLogicExpanded = !_vm.queryLogicExpanded
                }
              }
            },
            [
              _vm._v(" Query Logic "),
              _c("v-icon", { staticStyle: { float: "right" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      !_vm.queryLogicExpanded
                        ? "mdi-chevron-up"
                        : "mdi-chevron-down"
                    )
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "query-logic-box",
              class:
                _vm.queryLogicExpanded === false ? "query-logic-collapsed" : ""
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "0px",
                    left: "13px",
                    "font-size": "12px",
                    background: "white",
                    "z-index": "10",
                    "padding-left": "3px",
                    "padding-right": "3px",
                    color: "#666"
                  }
                },
                [
                  _vm._v(" Query Logic: "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.queryLogicValid,
                                          expression: "!queryLogicValid"
                                        }
                                      ],
                                      attrs: {
                                        center: "",
                                        small: "",
                                        color: "error"
                                      }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" mdi-alert-rhombus ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v(" Query Logic has errors")])]
                  )
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "QueryLogicTextArea",
                      model: {
                        value: _vm.queryLogicValid,
                        callback: function($$v) {
                          _vm.queryLogicValid = $$v
                        },
                        expression: "queryLogicValid"
                      }
                    },
                    [
                      _c("v-textarea", {
                        staticClass: "query-logic-textarea",
                        attrs: {
                          rules: [
                            _vm.validationService.getValidator(
                              "queryLogicSyntax",
                              ""
                            ),
                            _vm.validationService.getValidator(
                              "queryLogicBrackets",
                              ""
                            ),
                            _vm.validationService.getValidator(
                              "queryLogicSyntaxEndTest",
                              ""
                            ),
                            _vm.validationService.getValidator(
                              "queryLogicFiltersCheck",
                              "",
                              { requestBody: _vm.getApiCallBody() }
                            )
                          ],
                          outlined: "",
                          rows: "3",
                          "row-height": "4",
                          placeholder:
                            " Example: ( filterNo AND filterNo ) OR ( filterNo )"
                        },
                        model: {
                          value: _vm.selection.queryLogic,
                          callback: function($$v) {
                            _vm.$set(_vm.selection, "queryLogic", $$v)
                          },
                          expression: "selection.queryLogic"
                        }
                      }),
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "5px",
                            bottom: "5px",
                            "z-index": "100",
                            curosr: "pointer"
                          },
                          on: {
                            click: function($event) {
                              _vm.queryLogicExpanded = !_vm.queryLogicExpanded
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                !_vm.queryLogicExpanded
                                  ? "mdi-chevron-up"
                                  : "mdi-chevron-down"
                              )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }