






























































































































































































import { Vue } from 'vue-property-decorator';
import { SearchRequestBodyI, SearchConditionI, DataGridMetaDataI, NoteI } from '@/interfaces';
import FormatDisplayService from '@/services/FormatDisplay.service';
import { SerchResponse } from '../../../../../src/domain/grid/interfaces';
import UserService from '@/services/api/User.service';
import CustomDialog from '@/components/CustomDialog.vue';
import { AxiosResponse } from 'axios';
import axios from '@/services/api/Api.service';
import ErrorMessageInfo from '@/components/ErrorMessageInfo.vue';

const axiosInstance = new axios().getClient();

export default Vue.extend({
  components: { CustomDialog, ErrorMessageInfo },
  name: 'NotesComponent',
  data() {
    return {
      processing: false,
      fixedConditionsVariables: {
        productExternalId: null,
        orgId: null,
      },
      newNoteEditMode: false,
      orgNote: null as NoteI | null,
      userData: null as any,
      expanded: [] as any[],
      viewAllOpen: false,
      expandOnClick: true,
      tableHeightOffset:
        (this.hideFooter
          ? this.showViewAll
            ? 140 - this.correctionOffset
            : 118 - this.correctionOffset
          : this.showViewAll
          ? 198 - this.correctionOffset
          : 175 - this.correctionOffset) + (this.showButtons ? 0 : -33),
      tableHeight: 400,
      formatDisplay: FormatDisplayService,
      headers: [
        {
          value: 'content',
          text: 'Note',
          parse: (v: string) => {
            return v;
          },
        },
      ],
      loading: false,
      store: {
        metadata: null as null | DataGridMetaDataI,
        data: [] as any[],
      },
      dataTable: {
        itemKey: 'id',
        itemsPerPage: 10,
        footerProps: {
          itemsPerPageOptions: [5, 10, 50, 100],
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [] as string[],
          sortDesc: [] as boolean[],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: false,
        },
        showSelect: false,
        singleSelect: false,
        fixedHeader: true,
        value: [],
        items: [] as any[],
        originalItems: [],
        serverItemsLength: 0,
      },
      msg: null as any,
    };
  },

  async mounted() {
    this.msg = this.$getRemoteComponent('NotesErrorMessageInfo', this);
    this.msg.error().clear();
    this.msg.message().clear();
    this.userData = await UserService.getUserData();
    await this.refreshData();
    setTimeout(() => {
      this.onResize();
    }, 150);
  },
  methods: {
    getNoteDate(item: NoteI) {
      let dateNoteString = null as null | string;

      if (item && !item.edit) {
        dateNoteString = this.formatDisplay.getRender('toDateTime')(item.dateTimeUpdated || '');

        if (
          !item.edit &&
          item.dateTimeCreated &&
          item.dateTimeUpdated &&
          item.dateTimeCreated !== item.dateTimeUpdated
        ) {
          dateNoteString = '<span style="font-weight:300;">Edited</span> ' + dateNoteString;
        }
      }

      if (item && item.edit && item.id) {
        dateNoteString = this.formatDisplay.getRender('toDateTime')(item.dateTimeUpdated || '');
      }

      return dateNoteString || 'New Note';
    },
    editNote(item: NoteI) {
      this.orgNote = this.$getClone(item);
      this.expanded.push(item);
      item.edit = true;
      return;
    },
    viewAllOpenUpdate(v: boolean) {
      this.viewAllOpen = v;
      if (v === false) {
        this.cancelEditNote();
      }
    },
    async refreshData() {
      await this.loadMetaData();
      await this.loadData();
    },
    openViewAll() {
      this.$getRemoteComponent('NotesViewAllDialog', this)
        ?.external()
        .open();
    },
    async closeViewAll() {
      this.$getRemoteComponent('NotesViewAllDialog', this)
        ?.external()
        .close();
      await this.loadData();
    },
    onResize() {
      this.tableHeight = this.$getRemoteComponent('notesComponentFrame', this)?.offsetHeight - this.tableHeightOffset;
    },
    cellClickEvent(header: any, item: any) {
      if (this.expandOnClick) {
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        } else {
          this.expanded.splice(this.expanded.indexOf(item), 1);
        }
      }
    },
    cancelEditNote(editNote?: NoteI) {
      if (this.orgNote && editNote) {
        const editNote = this.dataTable.items.find((n: NoteI) => n.edit);
        if (editNote) {
          if (editNote.content !== this.orgNote.content) {
            editNote.content = this.orgNote.content;
          }
          editNote.edit = false;
          this.expanded.splice(
            this.expanded.findIndex(n => n.id === this?.orgNote?.id),
            1,
          );
          this.orgNote = null;
        }
      } else {
        const editNoteIndex = this.dataTable.items.findIndex((n: NoteI) => n.edit);
        if (editNoteIndex >= 0) {
          this.dataTable.items.splice(editNoteIndex, 1);
        }
      }
      this.msg.error().clear();
      this.newNoteEditMode = false;
      this.$getRemoteComponent('NewNoteDialog', this)
        ?.external()
        .close();
      this.$forceUpdate();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addNote(type: boolean | undefined) {
      // Set Scroll To Up
      this.$el.querySelector('div.v-data-table__wrapper')?.scroll(0, 0);

      this.newNoteEditMode = true;
      this.dataTable.items.unshift({
        edit: true,
        content: '',
        createdByName: this.userData.fullUserName,
        updatedByName: this.userData.fullUserName,
        createdById: this.userData.userId,
        updatedById: this.userData.userId,
        productExternalId: this.fixedConditionsVariables?.productExternalId || null,
        orgId: this.fixedConditionsVariables?.orgId || '',
        dateTimeCreated: null,
        dateTimeUpdated: null,
      });
      this.dataTable.serverItemsLength++;
    },
    saveNote(item: any) {
      let url = 'string';
      let requestData = null as any;
      if (!item.id) {
        url = '/uiapi/note/create';
        requestData = {
          content: this.dataTable.items.find(i => i.edit).content as string,
          createdBy: this.userData.fullUserName,
          createdById: this.userData.userId,
          parentId: null,
          productExternalId: this.fixedConditionsVariables?.productExternalId || null,
          orgId: this.fixedConditionsVariables?.orgId || '',
        } as NoteI;
      } else {
        url = '/uiapi/note/update';
        requestData = {
          id: item.id,
          content: item.content as string,
          updateddBy: this.userData.fullUserName,
          updatedById: this.userData.userId,
          parentId: null,
          productExternalId: this.fixedConditionsVariables?.productExternalId || null,
          orgId: this.fixedConditionsVariables?.orgId || '',
        } as NoteI;
      }

      this.processing = true;
      return axiosInstance
        .post<NoteI, AxiosResponse<{ success: boolean; messages: string[]; errors: string[]; data: NoteI }>>(
          url,
          requestData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(result => {
          this.processing = false;
          if (result.data.success) {
            this.loadData();
            this.cancelEditNote();
            this.$emit('note-saved', true);
            if (item) {
              item.edit = undefined;
            }
          } else {
            this.processing = false;
            this.msg.error().set(result.data.errors);
          }
        })
        .catch((error: string) => {
          this.processing = false;
          this.msg.error().set([error]);
        });
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteNote(index: number) {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getApiCallBody(): SearchRequestBodyI | null {
      if (!this.store.metadata) {
        return null;
      }

      return {
        filterCode: this.store.metadata?.filterSets[0].filterCode,
        page: this.dataTable.options.page,
        rowsPerPage: this.dataTable.options.itemsPerPage,
        conditions: (() => {
          let allConditions = [] as SearchConditionI[];
          if (this.fixedConditions.length > 0) {
            // @ts-ignore: Unreachable code error
            this.fixedConditions.forEach(condition => {
              // @ts-ignore: Unreachable code error
              this.fixedConditionsVariables[condition.fieldName] = condition.value;
              allConditions.push({
                // @ts-ignore: Unreachable code error
                fieldName: condition.fieldName,
                // @ts-ignore: Unreachable code error
                type: condition.fieldType,
                // @ts-ignore: Unreachable code error
                comparatorOption: condition.conditionComparatorOption,
                // @ts-ignore: Unreachable code error
                value: condition.value,
                fixed: true,
              });
            });
          }

          return allConditions;
        })(),
        sort: (this.store?.metadata?.filterSets || []).find(fc => fc.filterCode == 'All')?.sort || [],
        queryLogic: null,
      };
    },
    async loadMetaData(url?: string | undefined) {
      if (!url) {
        url = this.apiMetadataUrl;
      }
      if (!url) {
        console.error('METADATA: missing url');
      }
      this.loading = true;
      return axiosInstance
        .get<any, AxiosResponse<DataGridMetaDataI>>(url, {})
        .then(res => {
          if (res.data.columns) {
            this.store.metadata = res.data as DataGridMetaDataI;
          }
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.msg.error().add(error + ' (Table Definition)');
        });
    },
    async loadData() {
      const requestBody = this.getApiCallBody();
      if (!requestBody) {
        return false;
      }
      const url = this.apiDataUrl;
      this.loading = true;
      this.msg.error().clear();
      if (!url) {
        console.error('DATA: missing url');
      }

      return axiosInstance
        .post<any, AxiosResponse<SerchResponse<any>>>(url, requestBody, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(res => {
          if (!res.data.success) {
            this.msg.error().set(res.data.errors);
          }
          this.store.data = res.data.data;
          this.dataTable.items = res.data.data;
          this.dataTable.originalItems = res.data.data;
          this.dataTable.serverItemsLength = res.data.totalRows;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.msg.error().add(error + ' (Table Data)');
        });
    },
  },
  watch: {
    fixedConditions: {
      handler(n, o) {
        if (n && JSON.stringify(n) !== JSON.stringify(o)) {
          this.loadData();
        }
      },
      deep: true,
    },
    'dataTable.options': {
      async handler() {
        if (this.store.metadata) {
          await this.loadData();
        }
      },
      deep: true,
    },
  },
  computed: {},
  props: {
    orgId: {
      type: String || null,
      default: null,
    },
    correctionOffset: {
      type: Number,
      default: 64,
    },
    showFullNotes: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    showViewAll: {
      type: Boolean,
      default: true,
    },
    noteClickPreview: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: true,
    },
    apiDataUrl: {
      type: String,
      default: 'uiapi/note/search',
    },
    apiMetadataUrl: {
      type: String,
      default: 'uiapi/grids/notes',
    },
    fixedConditions: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
});
