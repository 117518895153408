var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-scroll-layout",
        {
          attrs: {
            pageName: "IDP Accounts",
            pageIcon: "mdi-text-box-search-outline",
            contentScroll: false,
            contentList: _vm.screenList,
            contentSelected: _vm.screen,
            showToolbarSlots: false,
            to: false
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "grid" }, slot: "grid" },
            [
              _c(
                "data-grid",
                _vm._b(
                  {
                    ref: "AccountListGrid",
                    attrs: {
                      showFilterSets: true,
                      itemsPerPage: [50, 100, 200, 300, 400, 500],
                      itemsPerPageInitial: 100,
                      actions: _vm.actions,
                      "fixed-conditions":
                        typeof _vm.lender === "string" && _vm.lender !== ""
                          ? [
                              {
                                fieldName: "orgId",
                                value: _vm.lender,
                                fieldType: "string",
                                conditionComparatorOption: "equal"
                              }
                            ]
                          : [],
                      "fixed-conditions-prevent-call": false,
                      headersAutoParserMapping: ""
                    }
                  },
                  "data-grid",
                  _vm.dataGrid,
                  false
                )
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }