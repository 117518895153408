import ApiService from '@/services/api/Api.service';
import { CancellationLetter, Response } from '../../interfaces';
import { AxiosResponse } from 'axios';

class CancellationLetterService extends ApiService {
  async updateName(data: CancellationLetter): Promise<Response<CancellationLetter>> {
    return (
      await this.axios.post<CancellationLetter, AxiosResponse<Response<CancellationLetter>>>(
        '/uiapi/cancellation-letter/update-name/' + data.externalId,
        data,
      )
    ).data;
  }
}

export default new CancellationLetterService();
