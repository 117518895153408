var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "", disabled: _vm.progress }
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.length > 0,
                      expression: "errors.length > 0"
                    }
                  ]
                },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-alert",
                            {
                              staticStyle: {
                                "font-weight": "strong",
                                "font-size": "14px",
                                "line-height": "15px"
                              },
                              attrs: {
                                type: "error",
                                border: "left",
                                text: "",
                                outlined: "",
                                dense: ""
                              }
                            },
                            [
                              _c("div", [_c("b", [_vm._v("Errors:")])]),
                              _vm._l(_vm.errors, function(error, errorIndex) {
                                return _c("div", { key: errorIndex }, [
                                  _vm._v("* " + _vm._s(error))
                                ])
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-progress-linear", {
                        directives: [
                          {
                            name: "visible",
                            rawName: "v-visible",
                            value: _vm.progress,
                            expression: "progress"
                          }
                        ],
                        attrs: { indeterminate: "", query: true, color: "blue" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-file-input", {
                        staticClass: "mb-5",
                        attrs: {
                          "show-size": "",
                          label: "File input",
                          messages: "Accepted formats: pdf, tif, jpeg, png",
                          accept: ".pdf,.tif,.jpeg,.png",
                          rules: [
                            _vm.ValidationService.getValidator(
                              "required",
                              "File"
                            )
                          ],
                          required: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.formValueChange()
                          }
                        },
                        model: {
                          value: _vm.selectedFile,
                          callback: function($$v) {
                            _vm.selectedFile = $$v
                          },
                          expression: "selectedFile"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          autocomplete: "none",
                          label: "Description",
                          rules: [
                            _vm.ValidationService.getValidator(
                              "maxLength",
                              "Description",
                              { length: 255 }
                            )
                          ]
                        },
                        model: {
                          value: _vm.selectedFileDescription,
                          callback: function($$v) {
                            _vm.selectedFileDescription = $$v
                          },
                          expression: "selectedFileDescription"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-snackbar",
                {
                  attrs: {
                    centered: "",
                    color: "success",
                    text: "",
                    timeout: 3000
                  },
                  model: {
                    value: _vm.snackBarSuccess,
                    callback: function($$v) {
                      _vm.snackBarSuccess = $$v
                    },
                    expression: "snackBarSuccess"
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "success" } }, [
                    _vm._v(" mdi-check-bold ")
                  ]),
                  _vm._v(" Completed successfully ")
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    centered: "",
                    color: "error",
                    text: "",
                    timeout: 4000
                  },
                  model: {
                    value: _vm.snackBarError,
                    callback: function($$v) {
                      _vm.snackBarError = $$v
                    },
                    expression: "snackBarError"
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "error" } }, [
                    _vm._v(" mdi-alert-circle-outline ")
                  ]),
                  _vm._v(" Save Failed ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }