import { LetterI } from '@/interfaces';
const LetterConfigurationRecordM = {
  id: null,
  orgId: null,
  sfid: null,
  orgPreference: null,
  active: false,
  name: null,
  externalId: null,
  dealerStates: [],
  cancelTypes: [],
  productTypes: [],
  providers: [],
  dealers: [],
  sendTo: null,
  letterFileExternalId: null,
  letterFile: null,
  effectiveDateType: 'AllTime',
  effectiveDate: null,
  startDate: null,
  endDate: null,
  createdDate: null,
  createdBy: null,
  createdById: null,
  dateTimeUpdated: null,
  updatedBy: null,
  updatedById: null,
  createdByName: null,
  updatedByName: null,
  noticeType: '1st Notice',
  autoSendInterval: null,
  managementRuleParameters: [
    {
      type: 'Product',
      fieldName: 'cancelType',
      fieldLabel: 'Cancel Type',
      customField: false,
      fieldType: 'Enum',
      fieldOptions: {
        CHARGE_OFF: 'CO',
        CUSTOMER_REQUEST: 'CR',
        LIEN_LOSS: 'LL',
        OTHER: 'O',
        PAYOFF: 'P',
        REPOSSESSION: 'R',
        TOTAL_LOSS: 'TL',
      },
      lookupData: '',
      operators: [
        {
          text: 'equal',
          value: 'equal',
        },
        {
          text: 'in',
          value: 'in',
        },
        {
          text: 'not in',
          value: 'not in',
        },
      ],
      data: {},
      fieldId: 'Product@cancelType',
      orgId: '00D010000008b7ZEAQ',
      fieldOptionsConverted: [
        {
          text: 'Charge Off',
          value: 'CO',
        },
        {
          text: 'Customer Request',
          value: 'CR',
        },
        {
          text: 'Lien Loss',
          value: 'LL',
        },
        {
          text: 'Other',
          value: 'O',
        },
        {
          text: 'Payoff',
          value: 'P',
        },
        {
          text: 'Repossession',
          value: 'R',
        },
        {
          text: 'Total Loss',
          value: 'TL',
        },
      ],
      operator: 'equal',
      value: {
        type: 'String',
        value: null,
      },
    },
  ],
  type: 'Letter',
} as LetterI;

export { LetterConfigurationRecordM };
