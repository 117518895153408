







































































































































































import { Vue } from 'vue-property-decorator';
import PageScrollLayout from '../components/PageScrollLayout.vue';
import ProductDetails from '../components/ProductDetails.vue';
import { Product } from '../interfaces/product.interface';
import { ApiLogI } from '../interfaces/apiLog.interface';
import DataGrid from '../components/DataGrid.vue';
import RemoveProductAction from '@/components/RemoveProductAction.vue';
import AssignUserToProductAction from '@/components/AssignUserToProductAction.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { FilterSetI } from '../interfaces';
import ProductService, { ApiRefundResponse } from '../services/api/Product.service';
import { Response } from '@/interfaces';
import SendCancellationAction from '@/components/SendCancellationAction.vue';
import FinalizeProductsAction from '@/components/FinalizeProductsAction.vue';
import RequestRefundAction from '@/components/RequestRefundAction.vue';
import UserService from '../services/api/User.service';
import CustomDialog from '@/components/CustomDialog.vue';
import ApiLogDetails from '@/components/ApiLogDetails.vue';
import axios from '../services/api/Api.service';

const metadataEndpointUrl = '/uiapi/grids/product';
const dataEndpointUrl = '/uiapi/product/search';

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

const apiDownloadLogUrl = '/uiapi/api-logs/download-log/{id}';

export default Vue.extend({
  name: 'Product',
  components: {
    PageScrollLayout,
    ProductDetails,
    DataGrid,
    RemoveProductAction,
    AssignUserToProductAction,
    ConfirmationDialog,
    SendCancellationAction,
    RequestRefundAction,
    FinalizeProductsAction,
    CustomDialog,
    ApiLogDetails,
  },
  data: () => ({
    apiLogRecordDetails: null as null | ApiLogI,
    userService: UserService,
    screen: 'grid',
    editedRecordData: {} as Product,
    selectedRows: [] as Product[],
    lender: '',
    content: 0,
    productDetailsEnabled: false,
    editedRecordProductDetails: {} as Product,
    endPoint: {
      metadataEndpointUrl: metadataEndpointUrl,
      dataEndpointUrl: dataEndpointUrl,
    },
    sendProviderApiConfirmationMessage: '',
    currentListView: {} as FilterSetI,
    createProductMode: false,
    sendProviderApiDialog: {
      ref: 'sendProviderApiDialogRef',
      title: 'Send Provider API Request',
      message: '',
      errors: [] as string[],
      messages: [] as string[],
      sendButtonDisabled: false,
      cancelDisabled: false,
    },
    filteredProducts: [] as Product[],
    selectedProductIndex: null as null | number,
    saveAndNextButtonVisible: true,
    finalizeStateDisableCRUD: false,
    confirmationDialogExit: {
      processing: false,
      ref: 'ConfirmationDialogExit',
      title: 'Save Product Data' as string,
      message: '' as string,
      errors: [] as string[],
      messages: [] as string[],
    },
    formChanged: false as boolean,
    isValidateButtonClicked: false as boolean,
    confirmationButtons: [
      {
        name: 'Yes',
        color: 'primary',
        callback: () => {},
      },
      {
        name: 'No',
        color: 'primary',
        callback: () => {},
      },
    ],
  }),
  computed: {
    isEditedRecordLenderActive() {
      if (!this.editedRecordProductDetails.orgPreference) {
        return this.$store.getters.lenderState as boolean;
      }
      return this.editedRecordProductDetails.orgPreference && this.editedRecordProductDetails.orgPreference.active;
    },
    isFinalizeStateDisableCRUD() {
      return this.finalizeStateDisableCRUD;
    },
    buttonActions() {
      return [
        {
          name: 'Create New Product' + (this.lender === '' || this.lender === null ? ' (Select Lender)' : ''),
          icon: 'mdi-pencil',
          disabled: this.lender === '' || this.lender === null,
          callback: () => {
            this.productDetailsEnabled = true;
            this.createProductMode = true;
            this.screen = 'details';
          },
        },
        {
          name: 'Assign User ' + (this.selectedRows.length === 0 ? ' (Select Products)' : ''),
          icon: 'mdi-transition',
          show: this.userService.checkUserActionAccess('ASSIGN_USER_EDIT'),
          disabled: this.selectedRows.length === 0,
          callback: () => {
            this.getRemoteComponent('AssignUserToProductAction').open(this.getRemoteComponent('dataGrid').selectedRows);
          },
        },
        {
          name: 'Request Refund Email ' + (this.selectedRows.length === 0 ? ' (Select Products)' : ''),
          icon: 'mdi-send',
          disabled:
            this.selectedRows.length === 0 ||
            !['RequestRefundEmail', 'MyQueue', 'pendingRefundData'].includes(this.currentListView.filterCode),
          callback: () => {
            this.getRemoteComponent('RquestRefundAction')?.open(this.selectedRows, this.currentListView.filterCode);
          },
        },
        {
          name: 'Send Cancellation ' + (this.selectedRows.length === 0 ? ' (Select Products)' : ''),
          icon: 'mdi-send',
          disabled: this.selectedRows.length === 0,
          callback: () => {
            this.getRemoteComponent('SendCancellationAction')?.open(this.selectedRows, 'Products');
          },
        },
        {
          name: 'Send Provider API Request' + (this.selectedRows.length === 0 ? ' (Select Products)' : ''),
          icon: 'mdi-send',
          disabled:
            !['RefundFailed', 'RefundReadyToSend'].includes(this.currentListView.filterCode) ||
            this.selectedRows.length === 0,
          callback: () => {
            // const recordsToProcess: number = this.getRemoteComponent('dataGrid').dataTable.serverItemsLength;
            const recordsToProcess: number = this.selectedRows.length;
            this.sendProviderApiConfirmationMessage = `Products to be sent: ${recordsToProcess}`;
            this.getRemoteComponent('sendProviderApiDialogRef').open = true;
            this.sendProviderApiDialog.sendButtonDisabled = false;
            this.sendProviderApiDialog.cancelDisabled = false;
            this.sendProviderApiDialog.errors = [];
            this.sendProviderApiDialog.messages = [];
          },
        },
        {
          name: 'Finalize ' + (this.selectedRows.length === 0 ? ' (Select Products)' : ''),
          icon: 'mdi-send',
          disabled: this.selectedRows.length === 0,
          callback: () => {
            this.getRemoteComponent('FinalizeProductsAction')?.open(this.selectedRows, 'Products');
          },
        },
      ];
    },
    previews() {
      return {
        columnName: 'Docs',
        items: [
          {
            tooltip: (item: Product) => {
              return item.lastDealerTransmissionParty + ' Letter Preview';
            },
            action: (item: Product) => {
              fetch(
                `/uiapi/cancellation-letter/get-transmission-preview?orgId=${item.orgId}&externalId=${item.lastDealerTransmissionExternalId}`,
              )
                .then(response => response.json())
                .then(data => {
                  window.open(data.url);
                });
            },
            checkVisibility: (item: Product) => {
              return item.lastDealerTransmissionParty !== null ? true : false;
            },
            image: (item: Product) => {
              if (item.lastDealerTransmissionParty) {
                return ProductService.GetLetterIconLink(item.lastDealerTransmissionParty);
              } else {
                return '';
              }
            },
          },
          {
            tooltip: (item: Product) => {
              return item.lastProviderTransmissionParty + ' Letter Preview';
            },
            action: (item: Product) => {
              fetch(
                `/uiapi/cancellation-letter/get-transmission-preview?orgId=${item.orgId}&externalId=${item.lastProviderTransmissionExternalId}`,
              )
                .then(response => response.json())
                .then(data => {
                  window.open(data.url);
                });
            },
            checkVisibility: (item: Product) => {
              return item.lastProviderTransmissionParty !== null && item.lastProviderTransmissionMethod !== 'API'
                ? true
                : false;
            },
            image: (item: Product) => {
              if (item.lastProviderTransmissionParty) {
                return ProductService.GetLetterIconLink(item.lastProviderTransmissionParty);
              } else {
                return '';
              }
            },
          },
        ],
      };
    },
    actions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit',
          callback: (rowData: Product, index: number): void => {
            this.productDetailsEnabled = true;
            this.createProductMode = false;
            this.selectedProductIndex = index;
            this.screen = 'details';
            this.editedRecordProductDetails = { ...rowData };
          },
        },
        {
          icon: 'mdi-delete',
          tooltip: 'Delete Product',
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          hide: (d: Product) => {
            if (['MyQueue'].indexOf(this.getRemoteComponent('dataGrid').selection.filterSet.filterCode) > -1) {
              return false;
            }
            return true;
          },
          disabledCondition: (d: Product) => !d.orgPreference?.active,
          callback: (rowData: {}): void => {
            this.getRemoteComponent('RemoveProductAction').open(rowData);
          },
        },
        {
          icon: 'mdi-api',
          tooltip: 'Api Logs',
          hide: (d: Product) => {
            if (
              ['RefundFailed', 'RefundFailed'].indexOf(
                this.getRemoteComponent('dataGrid').selection.filterSet.filterCode,
              ) > -1 &&
              d.apiLogs
            ) {
              if (d.apiLogs.length && d.apiLogs.length > 0) {
                return true;
              }
              return false;
            }
            return false;
          },
          callback: (rowData: Product): void => {
            const product = JSON.parse(JSON.stringify(rowData));
            let logDetails = product.apiLogs[0];
            logDetails.product = product;
            this.apiLogRecordDetails = logDetails;
            this.getRemoteComponent('ProductApiCallDetails')
              .external()
              .open();
          },
        },
      ];
    },
  },
  methods: {
    editButtonsRepreocessOn() {
      if (this.getRemoteComponent('productsDetails')) {
        return !this.getRemoteComponent('productsDetails').editButtonsRepreocessOn();
      }
      return true;
    },
    syncRecordData() {
      this.getRemoteComponent('dataGrid').submitDataRequest();
    },
    // Keep local selectedRows updated with grid sellection
    rowSelection(selectedRows: Product[]) {
      this.selectedRows = selectedRows;
    },
    setFilteredRecords(products: Product[]) {
      this.filteredProducts = products;
    },
    setFinalizeStateDisableCRUD(val: boolean) {
      this.finalizeStateDisableCRUD = val;
    },
    onSaveAndNextButtonVisible(val: boolean) {
      this.saveAndNextButtonVisible = val;
    },
    refreshGrid() {
      this.getRemoteComponent('dataGrid').loadData();
    },
    async sendProviderApiRequest() {
      this.sendProviderApiDialog.sendButtonDisabled = true;
      this.sendProviderApiDialog.cancelDisabled = true;
      const request = this.getRemoteComponent('dataGrid').getApiCallBody();
      request.conditions.push({
        fieldName: 'id',
        type: 'Integer',
        comparatorOption: 'in',
        value: this.selectedRows.map((product: Product) => {
          return product.id;
        }),
        fixed: false,
      });
      const response: Response<ApiRefundResponse> = await ProductService.sendRequestApiRefund(request);
      if (response.success) {
        this.sendProviderApiDialog.messages = ['Records updated successfully: ' + response.data?.affectedRows || '0'];
        this.refreshMainGrid();
      } else {
        this.sendProviderApiDialog.errors = response.errors ? response.errors : ['Unexpected error'];
        this.refreshMainGrid();
        this.sendProviderApiDialog.cancelDisabled = false;
        this.sendProviderApiDialog.sendButtonDisabled = false;
      }
    },
    setCurrentListView(filterSet: FilterSetI) {
      this.currentListView = filterSet;
    },
    refreshMainGrid() {
      this.getRemoteComponent('dataGrid').submitDataRequest();
    },
    assignUserSuccess() {
      this.getRemoteComponent('dataGrid').submitDataRequest();
    },
    productDeleteSuccess() {
      this.getRemoteComponent('dataGrid').submitDataRequest();
    },
    updateProductsDetails(openNext: boolean = false) {
      this.isValidateButtonClicked = false;

      if (this.createProductMode) {
        this.getRemoteComponent('productsDetails').createProduct();
      } else {
        this.getRemoteComponent('productsDetails').updateProduct(openNext ? true : false);
      }
    },
    validateProduct() {
      this.isValidateButtonClicked = true;

      this.getRemoteComponent('productsDetails').validateProductRefundData();
    },
    sendCancellation() {
      this.getRemoteComponent('productsDetails').sendCancellation();
    },
    reSendCancellation() {
      this.getRemoteComponent('productsDetails').reSendCancellation();
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    goBack() {
      if (this.isValidateButtonClicked && this.isProductsDetailsFormChanged()) {
        setTimeout(() => {
          this.confirmationButtons = [
            {
              name: 'Yes',
              color: 'primary',
              callback: () => {
                setTimeout(() => {
                  this.getRemoteComponent('ConfirmationDialogExit').open = false;
                }, 100);
                this.productDetailsEnabled = false;
                this.screen = 'grid';
                this.$forceUpdate();
                setTimeout(() => {
                  (this.$refs.dataGrid as HTMLFormElement).onResize();
                }, 300);
              },
            },
            {
              name: 'No',
              color: 'primary',
              callback: () => {
                setTimeout(() => {
                  this.getRemoteComponent('ConfirmationDialogExit').open = false;
                }, 100);
              },
            },
          ];
          this.getRemoteComponent('ConfirmationDialogExit').open = true;
        }, 100);
        return;
      } else {
        this.productDetailsEnabled = false;
        this.editedRecordProductDetails = {} as Product;
        this.screen = 'grid';
        setTimeout(() => {
          (this.$refs.dataGrid as HTMLFormElement).onResize();
        }, 300);
      }
    },
    isProductsDetailsFormChanged() {
      return this.getRemoteComponent('productsDetails')
        ? this.getRemoteComponent('productsDetails').isFormChanged()
        : false;
    },
    async downloadFile(id: string, fieldName?: string): Promise<void> {
      const url = apiDownloadLogUrl.replace('{id}', id);
      return axiosInstance
        .get<Blob>(url, { responseType: 'blob' })
        .then(response => {
          if (response.data.size === 0) {
            console.log('Empty File...');
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fieldName ? fieldName + '.json' : 'api_log.json');
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        })
        .catch(() => {});
    },
  },
  created() {
    this.lender = this.$store.getters.lender as string;
  },
  beforeRouteLeave(to, from, next) {
    if (this.isValidateButtonClicked && this.isProductsDetailsFormChanged()) {
      setTimeout(() => {
        this.confirmationButtons = [
          {
            name: 'Yes',
            color: 'primary',
            callback: () => {
              setTimeout(() => {
                this.getRemoteComponent('ConfirmationDialogExit').open = false;
              }, 100);
              next();
            },
          },
          {
            name: 'No',
            color: 'primary',
            callback: () => {
              setTimeout(() => {
                this.getRemoteComponent('ConfirmationDialogExit').open = false;
              }, 100);
              next(false);
            },
          },
        ];
        this.getRemoteComponent('ConfirmationDialogExit').open = true;
      }, 100);
    } else {
      next();
    }
  },
  watch: {
    '$store.getters.lender': {
      handler() {
        this.lender = this.$store.getters.lender as string;
      },
    },
  },
});
