
















































import { Vue } from 'vue-property-decorator';
import SfCustomDialog from '@/components/SFComponents/SfCustomDialog.vue';
import SfDataGrid from '@/components/SFComponents/SfDataGrid.vue';
import LenderServiceConfigurationFreeLookEditForm from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfigurationFreeLookEditForm.vue';
import { FeeI } from '@/interfaces';
import UserService from '@/services/api/User.service';
import { FreeLookI } from '@/interfaces/free-look.interface';

const emptyFreeLookRecord = {
  id: null,
  name: null,
  orgId: '',
  sfid: null,
  active: false,
  externalId: null,
  productTypes: [],
  dealerStates: [],
  createdDate: null,
  createdById: null,
  dateTimeUpdated: null,
  updatedById: null,
  effectiveDate: null,
  effectiveDateType: null,
  endDate: null,
  startDate: null,
  days: null,
  sendToLenderReview: false,
} as FreeLookI;

export default Vue.extend({
  name: 'LenderServiceConfigurationFreeLook',
  data() {
    return {
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      saveButtonActive: true,
      editedRecord: null as null | FeeI,
      dialogTitleText: 'Edit Free Look Rule',
      gridApiUrls: {
        metadata: '/uiapi/grids/config-free-look',
        data: '/uiapi/config-free-look/search',
      },
    };
  },
  components: { SfDataGrid, SfCustomDialog, LenderServiceConfigurationFreeLookEditForm },
  methods: {
    refreshGrid() {
      this.getRemoteComponent('LenderServiceConfigurationFreeLookGrid').loadData();
    },
    async saveFormData() {
      this.saveButtonActive = false;
      this.getRemoteComponent('LenderServiceConfigurationFreeLookEditForm')
        .submitForm()
        .then(() => {
          this.saveButtonActive = true;
          this.getRemoteComponent('FreeLookCustomDialogForm')
            .external()
            .close();
        })
        .catch(() => {
          this.saveButtonActive = true;
        });
    },
    addNewFreeLookRule() {
      this.dialogTitleText = 'Add Free Look Rule';

      this.editedRecord = JSON.parse(JSON.stringify(emptyFreeLookRecord));
      if (this.editedRecord) {
        this.editedRecord.orgId = this.orgId;
      }
    },
    editRecord(record: FeeI) {
      this.dialogTitleText = 'Edit Free Look Rule';

      this.editedRecord = JSON.parse(JSON.stringify(record));
      this.openEditDialog();
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    openEditDialog() {
      this.getRemoteComponent('FreeLookCustomDialogForm')
        .external()
        .open();
    },
  },
  props: ['footerHeight', 'orgId'],
});
