




































































































































































































































































import { Vue } from 'vue-property-decorator';
import SfDataGrid from '@/components/SFComponents/SfDataGrid.vue';
import { Transmission } from '../../../interfaces';
import ProductService from '../../../services/api/Product.service';
import XlsxExport from '@/components/XlsxExport.vue';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import FormatDisplayService from '@/services/FormatDisplay.service';
import UserService from '@/services/api/User.service';
import moment from 'moment';

export default Vue.extend({
  components: { SfDataGrid, XlsxExport },
  name: 'AuditHistoryComponent',
  data() {
    return {
      productTypeCodeFromName: (value: string) => {
        return value;
      },
      salesforceViewAllUrl: '',
      isSalesforceUser: true,
      gridItems: 0,
      viewAllDialog: false,
      productTypes: ['ALL'],
      selectedProductTypes: ['ALL'],
      search: null as string | null,
      searchDelay: null as number | null,
      showSearch: true,
      showExport: true,
      showTypes: true,
      recordId: this.$route.params?.sfid,
      gridApiUrls: {
        product: {
          metadata: '/uiapi/grids/audit-history-product',
          data: '/uiapi/audit-history/product/search',
        },
        account: {
          metadata: '/uiapi/grids/audit-history-account',
          data: '/uiapi/audit-history/account/search',
        },
      },
    };
  },
  props: {
    viewAll: {
      type: Boolean,
      default: true,
    },
    auditType: {
      type: String,
    },
    orgId: {
      type: String,
    },
    externalId: {
      type: String,
    },
    sfid: {
      type: String,
    },
    footerHeight: {
      type: Number,
    },
  },
  async created() {
    const userData = await UserService.getUserData();

    const productMetadata = await MetadataService.loadData(MetadataType.PRODUCT);
    this.productTypeCodeFromName = (value: string) => {
      const found = productMetadata.get('productType').find((x: any) => x.value === value);
      return found ? found.text : value;
    };
    this.$forceUpdate();

    this.isSalesforceUser =
      userData?.userRole == 'Salesforce Admin' ||
      userData?.userRole == 'Salesforce Standard' ||
      userData?.userRole == 'Salesforce Employee';

    if (this.isSalesforceUser) {
      this.salesforceViewAllUrl = `${userData.instanceUrl}/lightning/n/lct1__${(this.auditType as string)
        .charAt(0)
        .toUpperCase()}${(this.auditType as string).slice(1)}_Audit_History?c__recordId=${
        this.recordId == null ? this.$props.sfid : this.recordId
      }`;
    }
  },
  methods: {
    exportFile(format: 'XLSX' | 'CSV' | 'TXT' | '_XLSX' | '_CSV' | '_TXT') {
      const ExportComponent = this.$getRemoteComponent('ExportComponent', this);
      const data = this.$getRemoteComponent('AuditGrid', this).$data.dataTable.items;
      const headers = this.$getRemoteComponent('AuditGrid', this).getHeaders();
      const deserializeedData = ExportComponent.deserializeData(data);

      const preparedData = [] as any[];

      data.forEach((row: any) => {
        const newRow = {};

        headers.forEach((header: any) => {
          if (!header.parse) {
            // @ts-ignore: Unreachable code error
            newRow[header.text] = row[header.value];
            if (header.parser) {
              // @ts-ignore: Unreachable code error
              newRow[header.text] = header.parser(row[header.value]);
            }
          } else {
            switch (header.parse) {
              case 'toDateTime':
                // @ts-ignore: Unreachable code error
                newRow[header.text] = FormatDisplayService.getRender('toDateTime')(row[header.value]);
                break;
              case 'JsonObjectToHtmlString':
                if (row[header.value] === null) {
                  // @ts-ignore: Unreachable code error
                  newRow[header.text] = row[header.value];
                } else {
                  let newValue = [] as string[];
                  for (const [key, value] of Object.entries(row[header.value])) {
                    newValue.push(
                      key
                        .split('_')
                        .map(v => v.charAt(0).toUpperCase() + v.slice(1))
                        .join(' ') +
                        ': ' +
                        value,
                    );
                  }
                  // @ts-ignore: Unreachable code error
                  newRow[header.text] = newValue.join('\n');
                }
                break;
            }
          }
        });

        preparedData.push(newRow);
      });
      let exportFileName = 'Audit_History_';
      if (data && data[0] && data[0].accountNumber) {
        exportFileName += data[0].accountNumber + '_' + moment().format('MM-DD-YYYY');
      }

      switch (format) {
        case 'XLSX':
          ExportComponent.exportFile(exportFileName + '.xlsx', format, preparedData);
          break;
        case 'CSV':
          ExportComponent.exportFile(exportFileName + '.csv', format, preparedData);
          break;
        case 'TXT':
          ExportComponent.exportFile(exportFileName + '.txt', format, preparedData);
          break;
        case '_XLSX':
          ExportComponent.exportFile(exportFileName + '.xlsx', format, deserializeedData);
          break;
        case '_CSV':
          ExportComponent.exportFile(exportFileName + '.csv', format, deserializeedData);
          break;
        case '_TXT':
          ExportComponent.exportFile(exportFileName + '.txt', format, deserializeedData);
          break;
      }
    },
    refreshLocalSort() {
      const tableOptions = this.$getRemoteComponent('AuditGrid', this).$data.dataTable.options;
      if (tableOptions.sortBy.length > 0) {
        this.performLocalSort({ name: tableOptions.sortBy[0] || null, desc: tableOptions.sortDesc[0] || null });
      }
    },
    performLocalSort(sortObject: { name: string | null; desc: boolean | null }) {
      this.filterProductTypesGrid();
      if (sortObject.name === null) {
        return true;
      }

      if (sortObject.desc) {
        // @ts-ignore: Unreachable code error
        this.$getRemoteComponent('AuditGrid', this).$data.dataTable.items.sort((b, a) =>
          // @ts-ignore: Unreachable code error
          JSON.stringify(a[sortObject.name]) > JSON.stringify(b[sortObject.name])
            ? 1
            : // @ts-ignore: Unreachable code error
            JSON.stringify(b[sortObject.name]) > JSON.stringify(a[sortObject.name])
            ? -1
            : 0,
        );
      } else {
        // @ts-ignore: Unreachable code error
        this.$getRemoteComponent('AuditGrid', this).$data.dataTable.items.sort((a, b) =>
          // @ts-ignore: Unreachable code error
          JSON.stringify(a[sortObject.name]) > JSON.stringify(b[sortObject.name])
            ? 1
            : // @ts-ignore: Unreachable code error
            JSON.stringify(b[sortObject.name]) > JSON.stringify(a[sortObject.name])
            ? -1
            : 0,
        );
      }
    },
    async setAuditHistoryDialogOpen(state: boolean) {
      if (!this.isSalesforceUser && this.$props.viewAll) {
        this.viewAllDialog = false;
        if (state) {
          this.viewAllDialog = true;
        }
      }
    },
    clearSearch() {
      this.search = null;
      this.filterProductTypesGrid();
      this.refreshLocalSort();
    },
    serchKeywordEvent() {
      if (this.searchDelay) {
        clearTimeout(this.searchDelay);
      }
      this.searchDelay = setTimeout(() => {
        this.filterProductTypesGrid();
        this.refreshLocalSort();
      }, 500);
    },
    refreshGrid(event: Event) {
      this.getRemoteComponent('AuditGrid', event).loadData();
    },
    productTypeSelection() {
      const scope = this;
      return {
        check(productType: string) {
          return scope.selectedProductTypes.indexOf(productType) > -1;
        },
        add(productType: string) {
          if (!scope.productTypeSelection().check(productType)) {
            scope.selectedProductTypes.push(productType);
            return true;
          } else {
            return false;
          }
        },
        remove(productType: string) {
          delete scope.selectedProductTypes[scope.selectedProductTypes.indexOf(productType)];
        },
        clear() {
          scope.selectedProductTypes.length = 0;
        },
      };
    },
    produtTypeCheckboxClick(productType: string) {
      this.productTypeSelection().clear();
      this.productTypeSelection().add(productType);
      this.filterProductTypesGrid();
      this.refreshLocalSort();
      this.$forceUpdate();
    },
    filterProductTypesGrid() {
      const originalItems = this.$getRemoteComponent('AuditGrid', this).$data.dataTable.originalItems;
      this.$getRemoteComponent('AuditGrid', this).$data.dataTable.items = originalItems
        .filter((item: any) => {
          if (
            (this.auditType === 'product' && item.eventType.event_type === 'Attachment') ||
            (!this.productTypeSelection().check('ALL') && item.eventType.event_type === 'Attachment')
          ) {
            return false;
          }
          return true;
        })
        .filter((item: any) => {
          if (this.productTypeSelection().check('ALL')) {
            return true;
          }
          return (
            (item && item.productType && this.selectedProductTypes.indexOf(item.productType) > -1) || !item.productType
          );
        })
        .filter((item: any) => {
          if (this.search === null || this.search === '') {
            return true;
          } else {
            var re = new RegExp(this.search, 'gi');
            if (!JSON.stringify(item).match(re) && !JSON.stringify(item).match(this.search.replace(/ /g, '_'))) {
              return false;
            } else {
              return true;
            }
          }
        });
    },
    getGridItems(items: any) {
      this.gridItems = items.length;
      if (items.length > 0) {
        items.forEach((item: any) => {
          if (
            this.productTypes.indexOf(item.productType) == -1 &&
            item.productType !== null &&
            item.productType !== undefined
          ) {
            this.productTypes.push(item.productType);
          }
        });
        if (
          this.selectedProductTypes.length == 1 &&
          this.selectedProductTypes[0] == 'ALL' &&
          items[0].currentProductType
        ) {
          this.selectedProductTypes = [items[0].currentProductType];
        }
        this.filterProductTypesGrid();
      }
    },
    getRemoteComponent(name: string, event: Event) {
      if (event) {
        event.preventDefault();
      }
      return this.$getRemoteComponent(name, this);
    },
    viewRecord(row: any) {
      //console.log(row);
    },
  },
  computed: {
    transmissionPreviews() {
      return {
        columnName: 'Cancellation Letters',
        items: [
          {
            tooltip: (item: Transmission) => {
              return item.party + ' Letter Preview';
            },
            action: (item: Transmission) => {
              fetch(
                `/uiapi/cancellation-letter/get-transmission-preview?orgId=${item.orgId}&externalId=${item.externalId}`,
                {
                  headers: {
                    orgId: !item.orgId ? '' : item.orgId,
                  },
                },
              )
                .then(response => response.json())
                .then(data => {
                  fetch(data.url, {
                    headers: {
                      orgId: !item.orgId ? '' : item.orgId,
                    },
                  })
                    .then(response => response.blob())
                    .then(blob => {
                      var _url = window.URL.createObjectURL(blob);
                      window.open(_url);
                    })
                    .catch(err => {
                      console.log(err);
                    });
                });
            },
            checkVisibility: (item: Transmission) => {
              return item.fileURL ? true : false;
            },
            image: (item: Transmission) => {
              if (item.party) {
                return ProductService.GetLetterIconLink(item.party);
              } else {
                return '';
              }
            },
          },
        ],
      };
    },
  },
});
