







































































































































































































































































































































































































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import DataGrid from '@/components/DataGrid.vue';
import ProviderAliasEditGrid from '@/components/ProviderAliasEditGrid.vue';
import ValidationService from '../services/Validations.service';
import ProviderPageService, { EnumItemI } from '../services/ProviderPage.service';
import { ProviderDataRecordI, emptyProviderRecordData } from '../views/ProviderPage.vue';
import axios from '../services/api/Api.service';
import FormatDisplayService from '../services/FormatDisplay.service';
import UserService from '../services/api/User.service';
import MetadataService from '@/services/api/Metadata.service';
import { AxiosResponse } from 'axios';
import { SerchResponse } from '../../../src/domain/grid/interfaces';

Vue.directive('visible', function(el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

const RecordUpdateEndpointUrl = '/uiapi/provider/{id}';
const ProviderAliasesEndpointUrl = '';

/**
 *  Metadata service URL
 */
const ProviderFormsEndpointMetadataUrl = '/uiapi/grids/provider-form';
/**
 *  Data Search service URL
 */
const ProviderFormsEndpointDataUrl = '/uiapi/provider-form/search';

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

export interface ActionI {
  icon: string;
  tooltip: string;
  callback: (rowData: { id: string }) => void;
}

// Provider Data Record Interface
export interface ProviderDetailsI {
  userService: {};
  ValidationService: {};
  overlay: boolean;
  errors: string[];
  valid: boolean;
  editedRecord: ProviderDataRecordI;
  editedRecordLists: {
    providerState: EnumItemI[];
    providerCountry: EnumItemI[];
    providerRefundRequestMethod: EnumItemI[];
    CustomCancellationForm: EnumItemI[];
    refundAPIproviders: EnumItemI[];
    emailFormat: EnumItemI[];
  };
  additionalGrids: {
    ref: string;
    gridName: string;
    gridMetadataUrl: string;
    gridDataUrl: string;
    gridColumns: {
      text: string;
      value: string;
      sortable?: boolean;
    }[];
    gridActions?: (editedRecord: ProviderDataRecordI) => ActionI[];
    showSelection?: boolean;
    singleSelect?: boolean;
    dataIdField?: string;
  }[];
  endpoint: {
    RecordUpdateEndpointUrl: string;
    ProviderAliasesEndpointUrl: string;
    ProviderFormsEndpointMetadataUrl: string;
    ProviderFormsEndpointDataUrl: string;
  };
  snackMessage: boolean;
  phoneNumberRulesList: string[];
  formatDisplayService: any;
}

export default Vue.extend({
  name: 'ProviderDetails',
  components: { DataGrid, ProviderAliasEditGrid },
  /**
   * Page Component data object
   */
  data: (): ProviderDetailsI => ({
    userService: UserService,
    ValidationService: ValidationService,
    overlay: false,
    errors: [],
    valid: false,
    editedRecord: emptyProviderRecordData,
    editedRecordLists: {
      providerState: [],
      providerCountry: [],
      providerRefundRequestMethod: [],
      CustomCancellationForm: [],
      refundAPIproviders: [],
      emailFormat: [],
    },
    additionalGrids: [
      {
        ref: 'ProviderFormGrid',
        gridName: 'Provider Forms',
        gridMetadataUrl: ProviderFormsEndpointMetadataUrl,
        gridDataUrl: ProviderFormsEndpointDataUrl,
        gridColumns: [
          { text: 'Active', value: 'active' },
          { text: 'Name', value: 'name' },
          { text: 'Form Identifier', value: 'formIdentifier' },
        ],
        gridActions: (editedRecord: ProviderDataRecordI) => [
          {
            icon: 'mdi-check-circle-outline',
            disabled: !UserService.checkUserActionAccess('PROVIDER_EDIT'),
            tooltip: 'Set this form as cancellation form for current provider',
            callback: (rowData: { id: string }): void => {
              // Put data from grid row to editor
              editedRecord.customCancellationFormId = rowData.id;
            },
          },
        ],
      },
    ],
    endpoint: {
      RecordUpdateEndpointUrl: RecordUpdateEndpointUrl,
      ProviderAliasesEndpointUrl: ProviderAliasesEndpointUrl,
      ProviderFormsEndpointDataUrl: ProviderFormsEndpointDataUrl,
      ProviderFormsEndpointMetadataUrl: ProviderFormsEndpointMetadataUrl,
    },
    snackMessage: false,
    phoneNumberRulesList: [
      'valid format (xxx) xxx-xxxx',
      'max length less than 16 characters',
      'cannot start with 1',
      'cannot contain sequential numbers (234) 567-890)',
      'cannot use the same digits (222) 222-2222)',
    ],
    formatDisplayService: FormatDisplayService,
  }),
  computed: {
    getRefundEmailsRules() {
      return [
        ValidationService.getValidator('emailSemiconList', 'Refund Email'),
        ValidationService.getValidator('isValidEmailCount', 'Refund Email'),
      ];
    },
  },
  props: {
    passEditedRecord: {
      type: Object,
      default: () => {
        return Object.assign({}, emptyProviderRecordData) as ProviderDataRecordI;
      },
    },
  },
  /**
   * Method will run when comoponent is created
   */
  created() {
    this.getEnums();
    this.updateEditedRecord();
    this.resetValidation();
    this.validate();
  },
  methods: {
    refreshValidation() {
      this.resetValidation();
      this.validate();
    },
    validateEmailFax(type: 'Email' | 'Fax') {
      return (v: string | number | null) => {
        if (this.editedRecord.communicationPreference === type && (!v || v == '')) {
          return type + ' is required if Comunication Preference is set to ' + type;
        }
        return true;
      };
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    getRemoteComponentArray(refComponentName: string): HTMLFormElement[] {
      return this.$refs[refComponentName] as HTMLFormElement[];
    },
    async recordDataRefresh() {
      this.errors.length = 0;
      if (this.editedRecord.id !== null) {
        this.editedRecord = (await MetadataService.getRecord(
          { id: this.editedRecord.id },
          'All',
          '/uiapi/provider/search',
        )) as ProviderDataRecordI;
        this.$emit('provider-data-refresh', this.editedRecord);
      }
      this.getRemoteComponent('providerAliasEditGrid').refreshGrid();
      this.getRemoteComponentArray('ProviderFormGrid')[0].submitDataRequest();
      const id = this.editedRecord.id;
      this.editedRecord.id = null;
      setTimeout(() => {
        this.editedRecord.id = id;
      }, 1);
    },
    openAddNewAliasDialog() {
      (this.$refs.providerAliasEditGrid as HTMLFormElement).openEditDialog();
    },
    submitDataRequest(): void {
      // reset error box on send request
      this.errors.length = 0;
      this.overlay = false;
      const requestObject = Object.assign({}, this.editedRecord as ProviderDataRecordI);
      let url = '';
      if (requestObject.id) {
        url = this.endpoint.RecordUpdateEndpointUrl.replace('{id}', requestObject.id.toString());
      } else {
        this.setError(['Object Record ID is empty']);
      }

      //validation part, preparing data
      if (this.valid) {
        this.overlay = true;
        axiosInstance
          .post<ProviderDataRecordI, AxiosResponse<SerchResponse<ProviderDataRecordI>>>(url, requestObject, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(result => {
            this.overlay = false;
            if (result.data.success) {
              this.snackMessage = true;
              this.recordDataRefresh();
            } else {
              // Setting errors will show them on form
              this.setError(result.data.errors);
            }
          })
          .catch(error => {
            this.overlay = false;
            this.setError([error]);
          });
      } else {
        this.resetValidation();
        this.validate();
      }
    },
    returnDateTimeRenderd(TimeDateString: string) {
      return FormatDisplayService.getRender('toDateTime')(TimeDateString);
    },
    updateEditedRecord() {
      this.editedRecord = this.passEditedRecord;
    },
    /**
     * Set Errors in Edit Details Box
     */
    setError(errors: string[]) {
      this.errors.length = 0;
      Object.assign(this.errors, errors);
    },
    /**
     * Reste validation
     */
    resetValidation(): number {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).providerDetailsForm.resetValidation();
      }, 250);
    },
    /**
     * Do validation on form
     */
    validate(): number {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).providerDetailsForm.validate();
      }, 500);
    },
    /**
     * Method feed lists with data from service ( async - need to be replaced with Enum global service )
     */
    async getEnums(): Promise<void> {
      this.editedRecordLists.providerState = (await ProviderPageService.getItems(
        'ProviderStates',
        true,
      )) as EnumItemI[];
      this.editedRecordLists.providerCountry = (await ProviderPageService.getItems('ProviderCountry')) as EnumItemI[];
      this.editedRecordLists.refundAPIproviders = (await ProviderPageService.getItems('RefundAPI')) as EnumItemI[];
      this.editedRecordLists.providerRefundRequestMethod = (await ProviderPageService.getItems(
        'ProviderRefundRequestMethod',
      )) as EnumItemI[];
      this.editedRecordLists.emailFormat = (await ProviderPageService.getItems('EmailFormat')) as EnumItemI[];
      if (this.editedRecord.id !== null) {
        if (this.editedRecord.id > 0) {
          this.editedRecordLists.CustomCancellationForm = (await ProviderPageService.getItems(
            'CustomCancellationForm',
            false,
            false,
            {
              providerId: this.editedRecord.id + '',
            },
          )) as EnumItemI[];
        }
      }
    },
  },
});
