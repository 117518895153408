import ApiService from '@/services/api/Api.service';
import {
  PresignedFileUploadI,
  PresignedI,
  PresignedLogoUploadI,
  PresignedToBeRemovedI,
  PresignedFileLetterUploadI,
} from '@/interfaces/presigned.interface';

class S3FileService extends ApiService {
  async getPresignedUploadUrl(fileType: string, fileName: string, orgId: string) {
    const response = await this.axios.get('/uiapi/s3/upload/presigned', {
      params: {
        fileType,
        fileName,
        orgId,
      },
    });
    return response.data as PresignedFileUploadI;
  }

  async getPresignedLetterUploadUrl(letterParty: string, letterName: string, fileName: string, orgId: string) {
    const response = await this.axios.get('/uiapi/cancellation-letter/upload-letter/presigned', {
      params: {
        letterParty,
        letterName,
        fileName,
        orgId,
      },
    });
    return response.data as PresignedFileLetterUploadI;
  }

  async getPresignedLetterEditUploadUrl(
    letterParty: string,
    letterName: string,
    fileName: string,
    orgId: string,
    externalId: string,
  ) {
    const response = await this.axios.get('/uiapi/cancellation-letter/upload-letter/edit/presigned', {
      params: {
        letterParty,
        letterName,
        fileName,
        orgId,
        externalId,
      },
    });
    return response.data as PresignedFileLetterUploadI;
  }

  async getPresignedLenderLogoUrl(filename: string, orgId: string) {
    const response = await this.axios.get('/uiapi/s3/upload-lender-logo/presigned', {
      params: {
        filename,
        orgId,
      },
    });
    return response.data as PresignedLogoUploadI;
  }

  async getPresignedProviderFormUploadUrl(fileName: string) {
    const response = await this.axios.get('/uiapi/s3/upload-provider-form/presigned', {
      params: {
        fileName,
      },
    });
    return response.data as PresignedToBeRemovedI;
  }

  async getPresignedProviderFormDownloadUrl(id: number) {
    const response = await this.axios.get('/uiapi/s3/download-provider-form/presigned', {
      params: {
        id,
      },
    });
    return response.data as string;
  }

  async getPresignedUploadUrlForAttachment(fileName: string, accountNumber: string, orgId: string) {
    const response = await this.axios.get('/uiapi/s3/upload-attachment/presigned', {
      params: {
        fileName,
        accountNumber,
        orgId,
      },
    });
    return response.data as PresignedI;
  }

  async getPresignedDownloadUrlForAttachment(fileName: string, accountNumber: string, orgId: string) {
    const response = await this.axios.get('/uiapi/s3/download-attachment/presigned', {
      params: {
        fileName,
        accountNumber,
        orgId,
      },
    });
    return response.data as string;
  }
}

export default new S3FileService();
