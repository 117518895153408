/**
 * Important !!
 * @CustomFieldMetadata in Entity need to have dataFilter Object for control
 * which fields need to stay as properties in value data object
 */
export function prepareSaveDate(content: any) {
  content.managementRuleParameters.forEach((customParameter: any) => {
    // activated only for `in` and `not in` operators and value type array
    if (['in', 'not in'].includes(customParameter.operator) && customParameter.value.type === 'Array') {
      const objectFilter = customParameter.data?.objectFilter?.find(
        (f: any) => f.collection === 'LETTER_RULES_CUSTOM_PARAMETERS',
      );
      if (objectFilter) {
        customParameter.value.value = customParameter.value.value.map((v: any) => {
          const filteredObject = {} as any;
          objectFilter.fields.forEach((field: any) => {
            filteredObject[field] = v[field];
          });
          return filteredObject;
        });
      }
    }
  });
}

export default { prepareSaveDate };
