





















































import { Vue } from 'vue-property-decorator';
import LenderServiceConfiguration from '@/components/SFComponents/LenderServiceConfiguration/LenderServiceConfiguration.vue';
import UserService from '@/services/api/User.service';
import EventBus from '@/events/EventBus';

export default Vue.extend({
  data() {
    return {
      orgId: null as null | string,
      timeInterval: null as null | number,
      networkDisconnected: false as boolean,
      sessionLost: false,
    };
  },
  components: { LenderServiceConfiguration },
  name: 'SfLenderServiceConfig',
  async created() {
    this.orgId = (await UserService.getUserData())?.orgId || null;
    EventBus.$on('NetworkDisconnected', () => {
      this.networkDisconnected = true;
    });
    EventBus.$on('NetworkConnected', () => {
      if (this.networkDisconnected) {
        this.networkDisconnected = false;
        window.location.href = '/sf-lender-service-config';
      }
    });
    EventBus.$on('ForceRefreshPageInfo', () => {
      this.sessionLost = true;
    });
    // prevent session go to sleep
    this.timeInterval = setInterval(() => {
      UserService.getUserData(true);
    }, 30000);
  },
});
