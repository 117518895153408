










































































































































































import Vue from 'vue';
import LenderService, { LenderListI } from './services/api/Lender.service';
import UserService from './services/api/User.service';
import { UserI, LenderCustomFieldsI } from './interfaces';
import CustomDialog from './components/CustomDialog.vue';
import EventBus from './events/EventBus';

export interface MenuItemI {
  title: string;
  path: string;
  icon: string;
  mainLayout: string | null;
}
Vue.directive('visible', function(el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

export default Vue.extend({
  name: 'App',

  components: { CustomDialog },

  data: () => ({
    menuLayout: true as boolean,
    sidebar: null,
    lender: '',
    lenderList: [] as LenderListI[],
    userData: null as null | UserI,
    userService: UserService,
  }),
  watch: {
    $route: {
      handler: function(route) {
        const foundRoute = this.$store.getters.menuItems.find((menuItem: MenuItemI) => {
          const menuItemParts = menuItem.path.split('/');
          const routeParts = route.path.split('/');

          for (const indexItemPath in menuItemParts) {
            if (menuItemParts[indexItemPath].startsWith(':') && routeParts[indexItemPath]) {
              routeParts[indexItemPath] = menuItemParts[indexItemPath];
            }
          }

          return routeParts.join('/') === menuItem.path;
        });
        if (foundRoute?.mainLayout === 'SFComponent') {
          this.menuLayout = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    EventBus.$emit('SetLenderOrgId', this.$parent.$data.status.orgId);
    this.userData = await UserService.getUserData();
    this.getLenderData();
  },
  methods: {
    getLenderState(orgId: string) {
      let lenderState = false;
      if (this.lenderList.length > 1) {
        this.lenderList.forEach((lender: { orgId: string; active: boolean }) => {
          if (this.lender === lender.orgId) {
            lenderState = lender.active;
          }
        });
      }
      return lenderState;
    },
    prepareMnuItems(menuItems: { path: string }[]) {
      return menuItems.filter(menuItem => {
        return this.userService.checkUserViewAccess(menuItem.path);
      });
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    openUserDetails() {
      this.getRemoteComponent('UserDetails')
        ?.external()
        .open();
    },
    getUserIconColor() {
      if (!this.userData) {
        return '#eee';
      }
      if (this.userData) {
        switch (this.userData.userRole) {
          case 'Admin':
            return '#4CAF50';
          case 'Support':
            return '#CDDC39';
          default:
            return '#3d75b6';
        }
      }
      return '#eee';
    },
    clearLocalStorage() {
      localStorage.clear();
    },
    getLenderCustomFields(lenderList: LenderListI[]) {
      const output = {} as { key: string; value: LenderCustomFieldsI[] };
      lenderList.forEach((lender: LenderListI) => {
        // @ts-ignore: Unreachable code error
        output[lender.orgId] = lender.customFields;
      });
      return output;
    },
    setLender(): void {
      this.$store.commit('setLender', this.lender);
      this.$store.commit('setLenderState', this.getLenderState(this.lender));
      EventBus.$emit('event-set-lender', this.lender);
    },
    /**
     * Loading Lender List data from service
     */
    async getLenderData(): Promise<void> {
      this.lenderList = await LenderService.getLendersList();
      this.$store.commit('setLenderCustomFields', this.getLenderCustomFields(this.lenderList));
      this.lenderList.unshift({ orgId: '', lenderName: 'All', active: false });
      this.setLender();
    },
  },
  async mounted() {
    EventBus.$on('refresh-lender', () => {
      this.getLenderData();
    });
  },
  computed: {
    menuItems() {
      return this.$store.getters.menuItems.filter((i: MenuItemI) => !i.mainLayout);
    },
    lenderSelectionVisible() {
      if (
        ['Accounts', 'Products', 'Data Upload', 'Dealers', 'Lenders', 'IDP Details'].indexOf(this.$route.name || '') >
        -1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
});
