























































































































































































































import { Vue } from 'vue-property-decorator';
import DataGrid from '@/components/DataGrid.vue';

import axios from '../services/api/Api.service';
import ValidationService from '../services/Validations.service';
import FormatDisplayService from '../services/FormatDisplay.service';
import UserService from '../services/api/User.service';
import { AxiosResponse } from 'axios';
import { SerchResponse } from '../../../src/domain/grid/interfaces';

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();
/**
 *  Metadata service URL
 */
const metadataEndpointUrl = '/uiapi/grids/provider-alias';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/provider-alias/search';
/**
 *  Update record endpoint URL
 */
const recordUpdateEndpointUrl = '/uiapi/provider-alias/{id}';
/**
 *  Insert record endpoint URL
 */
const recordInsertEndpointUrl = '/uiapi/provider-alias';

/**
 *  Provider List call
 */
const providerDataEndpointEndpointUrl = '/uiapi/provider/search';

export interface ProviderAliasI {
  id: number | null;
  alias: string | null;
  verified: boolean;
  providerId: string | null;
  createdByName?: string | null;
  createdDate?: string | null;
  updatedByName?: string | null;
  dateTimeUpdated?: string | null;
  provider?: {};
}

export const emptyProviderAliasRecord = {
  id: null,
  alias: null,
  verified: false,
  providerId: null,
  createdByName: null,
  createdDate: null,
  updatedByName: null,
  dateTimeUpdated: null,
} as ProviderAliasI;

export interface FixedCondition {
  fieldName: string;
  value: string;
  fieldType: 'string' | 'boolean' | 'enum' | 'checkbox';
  conditionComparatorOption: string;
}

export interface CustomDataGridHeaderI {
  text: string;
  value: string;
  parse?: (v: string | null) => string | null;
  sortable?: boolean;
}

export interface ProviderAliasEditGridPageI {
  userService: {};
  valid: boolean;
  errors: string[];
  editActionType: 'INSERT' | 'UPDATE' | 'DELETE';
  processing: boolean;
  deleteDialogOpen: boolean;
  editDialogOpen: boolean;
  providerList: { text: string; value: string }[];
  customDataGrid: {
    actions: {
      icon: string;
      tooltip: string;
      callback: (rowData: ProviderAliasI, rowIndex?: number, gridData?: ProviderAliasI[]) => void;
    }[];
    ignoreResize: boolean;
    gridId: string;
    forceTableHeight: number | null;
    hideFilterSetup: boolean;
    apiMetadataUrl: string;
    apiDataUrl: string;
    dataIdField: string;
    fixedConditionsPreventCall: boolean;
    fixedConditions: FixedCondition[];
    ref: string;
    headersAutoParserMapping: boolean;
  };
  editedRecord: ProviderAliasI;
  validationService: {};
  snackBarSuccess: boolean;
  snackBarError: boolean;
}

export default Vue.extend({
  name: 'ProviderAliasEditGrid',
  components: {
    DataGrid,
  },
  data: (): ProviderAliasEditGridPageI => ({
    userService: UserService,
    valid: true,
    errors: [],
    editActionType: 'INSERT',
    processing: false,
    editDialogOpen: false,
    deleteDialogOpen: false,
    providerList: [],
    customDataGrid: {
      actions: [],
      ignoreResize: false,
      gridId: 'ProviderAliasEditGrid',
      forceTableHeight: null,
      hideFilterSetup: false,
      apiMetadataUrl: metadataEndpointUrl,
      apiDataUrl: dataEndpointUrl,
      dataIdField: 'alias',
      fixedConditionsPreventCall: false,
      fixedConditions: [],
      ref: 'providerAliasDataGrids',
      headersAutoParserMapping: true,
    },
    editedRecord: JSON.parse(JSON.stringify(emptyProviderAliasRecord)) as ProviderAliasI,
    validationService: ValidationService,
    snackBarSuccess: false,
    snackBarError: false,
  }),
  methods: {
    refreshGrid() {
      this.getRemoteComponent('providerAliasDataGrids').submitDataRequest();
    },
    openDeleteDialog(editedRecord: ProviderAliasI) {
      // Clear error box on enter edit
      this.errors.length = 0;
      // reset data in edited record if option "new provider"
      this.editActionType = 'DELETE';
      this.editedRecord = JSON.parse(JSON.stringify(editedRecord)) as ProviderAliasI;
      this.deleteDialogOpen = !this.deleteDialogOpen;
    },
    returnDateTimeRenderd(TimeDateString: string) {
      return FormatDisplayService.getRender('toDateTime')(TimeDateString);
    },
    openEditDialog(editedRecord?: ProviderAliasI) {
      // Clear error box on enter edit
      this.errors.length = 0;
      // Reset validation
      this.resetValidation();
      if (editedRecord === undefined) {
        // reset data in edited record if option "new provider"
        this.editActionType = 'INSERT';
        this.editedRecord = JSON.parse(JSON.stringify(emptyProviderAliasRecord)) as ProviderAliasI;
        // preselect provider based on parent component setup
        if (this.providerId) {
          this.editedRecord.providerId = this.providerId;
        }
      } else {
        // assign record from other source and edit
        this.editActionType = 'UPDATE';
        this.editedRecord = JSON.parse(JSON.stringify(editedRecord)) as ProviderAliasI;
        // change format of date to MM/DD/YYYY
        if (this.editedRecord.createdDate) {
          this.editedRecord.createdDate = FormatDisplayService.getRender('toDateTime')(this.editedRecord.createdDate);
        }
      }
      // Run validation
      this.validate();
      this.editDialogOpen = !this.editDialogOpen;
    },
    submitDeleteDataRequest() {
      // reset error box on send request
      this.errors.length = 0;
      this.processing = false;
      let url = '';
      const requestObject = Object.assign({}, this.editedRecord as ProviderAliasI) as ProviderAliasI;
      if (requestObject.id) {
        url = recordUpdateEndpointUrl.replace('{id}', requestObject.id.toString());
        axiosInstance
          .delete<
            any,
            AxiosResponse<{ success: boolean; errors?: string[]; messages?: string[]; data?: ProviderAliasI }>
          >(url)
          .then(result => {
            this.processing = false;
            if (result.data.success) {
              // Reload CustomDataGrid component
              this.snackBarSuccess = true;
              this.editDialogOpen = false;
              this.getRemoteComponent('providerAliasDataGrids').submitDataRequest();
              this.deleteDialogOpen = !this.deleteDialogOpen;
            } else {
              // Setting errors will show them on form
              this.setError(result.data.errors as string[]);
              this.snackBarError = true;
            }
          })
          .catch(error => {
            this.processing = false;
            this.setError([error]);
          });
      }
    },
    /**
     * Method for submitting Data
     */
    submitDataRequest(): void {
      // reset error box on send request
      this.errors.length = 0;
      this.processing = false;
      const requestObject = Object.assign({}, this.editedRecord as ProviderAliasI) as ProviderAliasI;
      // Remove unsued properties from request
      delete requestObject.createdByName;
      delete requestObject.createdDate;
      delete requestObject.updatedByName;
      delete requestObject.provider;
      let url = '';
      if (!requestObject.id) {
        url = recordInsertEndpointUrl;
      } else {
        url = recordUpdateEndpointUrl.replace('{id}', requestObject.id.toString());
      }
      //validation part, preparing data
      if (this.valid) {
        this.processing = true;
        axiosInstance
          .post<
            ProviderAliasI,
            AxiosResponse<{ success: boolean; errors?: string[]; messages?: string[]; data?: ProviderAliasI }>
          >(url, requestObject, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(result => {
            this.processing = false;
            if (result.data.success) {
              // Reload CustomDataGrid component
              this.snackBarSuccess = true;
              this.editDialogOpen = false;
              this.getRemoteComponent('providerAliasDataGrids').submitDataRequest();
            } else {
              // Setting errors will show them on form
              this.setError(result.data.errors as string[]);
              this.snackBarError = true;
            }
          })
          .catch(error => {
            this.processing = false;
            this.setError([error]);
          });
      } else {
        this.resetValidation();
        this.validate();
      }
    },
    /**
     * Set Errors in Edit Details Box
     */
    setError(errors: string[]) {
      this.errors.length = 0;
      Object.assign(this.errors, errors);
    },
    /**
     * Reste validation
     */
    resetValidation(): number {
      return setTimeout(() => {
        this.getRemoteComponent('aliasForm').resetValidation();
      }, 250);
    },
    /**
     * Do validation on form
     */
    validate(): number {
      return setTimeout(() => {
        this.getRemoteComponent('aliasForm').validate();
      }, 500);
    },
    // Return Access to ProviderAliasEditGrid Component ( all properties, all methods )
    // `ref="refComponentName"` need tobe added to related component!
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    async getEnums() {
      this.providerList = await this.getProviderList();
    },
    async getProviderList() {
      return axiosInstance
        .post<any, AxiosResponse<SerchResponse<{ id: string; name: string }[]>>>(
          providerDataEndpointEndpointUrl,
          {
            filterCode: 'All',
            page: 1,
            rowsPerPage: 999999,
            conditions: [],
            sort: [],
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(result => {
          return result.data.data.map(providerItem => {
            return {
              text: providerItem.name,
              value: providerItem.id,
            };
          });
        })
        .catch(error => {
          this.setError([error]);
          return [];
        });
    },
  },
  props: {
    providerId: {
      type: String,
      default: null, // url to metadata endpoint for current grid
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    defaultQueue: {
      type: String,
      default: '',
    },
    tableHeight: {
      type: [Number],
      default: null,
    },
    customHeaders: {
      type: Array,
      default: (): CustomDataGridHeaderI[] => {
        return [] as CustomDataGridHeaderI[];
      },
    },
  },
  /**
   * Method will run when comoponent is created
   */
  created() {
    this.getEnums();
    this.customDataGrid.hideFilterSetup = this.hideFilter;
    if (this.tableHeight) {
      this.customDataGrid.ignoreResize = true;
    }
    this.customDataGrid.forceTableHeight = this.tableHeight;
    if (parseInt(this.providerId) > 0) {
      this.customDataGrid.fixedConditions.length = 0;
      this.customDataGrid.fixedConditions.push({
        fieldName: 'providerId',
        value: this.providerId,
        fieldType: 'string',
        conditionComparatorOption: 'equal',
      });
    }
  },
  watch: {
    // Watch  for changes in providerId send to componet
    // Set Custom Grid Fixed Conditions and Reload grid on change
    providerId: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.customDataGrid.fixedConditions.length = 0;
          this.customDataGrid.fixedConditions.push({
            fieldName: 'providerId',
            value: newValue,
            fieldType: 'string',
            conditionComparatorOption: 'equal',
          });
          this.getRemoteComponent('providerAliasDataGrids').submitDataRequest();
        }
      },
      deep: true,
    },
  },
  computed: {
    buttonActions() {
      return [
        {
          name: 'New Alias',
          icon: 'mdi-plus',
          callback: () => {
            this.openEditDialog();
          },
        },
      ];
    },
    /**
     * Defined action for GridComponent
     */
    actions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit Alias',
          disabled: !UserService.checkUserActionAccess('PROVIDER_EDIT'),
          /**
           *  Callback function for action click
           *  params:
           *  - rowData - grid data for selected row (Object)
           *  - rowIndex - grid store index for selected row (in case of delete or other manipulation on data) (number)
           *  - gridData - grid store data (Array of Objects)
           */
          callback: (rowData: ProviderAliasI): void => {
            // Put data from grid row to editor
            this.openEditDialog(rowData);
          },
        },
        {
          icon: 'mdi-delete',
          tooltip: 'Delete Alias',
          disabled: !UserService.checkUserActionAccess('PROVIDER_EDIT'),
          /**
           *  Callback function for action click
           *  params:
           *  - rowData - grid data for selected row (Object)
           *  - rowIndex - grid store index for selected row (in case of delete or other manipulation on data) (number)
           *  - gridData - grid store data (Array of Objects)
           */
          callback: (rowData: ProviderAliasI): void => {
            // Put data from grid row to editor
            this.openDeleteDialog(rowData);
          },
        },
      ];
    },
  },
});
