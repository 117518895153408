var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { display: "inline-block", width: "100%" },
      on: { click: _vm.openPreview }
    },
    [
      _vm._t(
        "default",
        [
          _c(
            "div",
            {
              staticClass: "slds-button slds-button_neutral",
              attrs: { title: "Preview Letter", disabled: !_vm.enabled }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "slds-button__icon slds-button__icon_left",
                  attrs: { "aria-hidden": "true" }
                },
                [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                        "#preview"
                    }
                  })
                ]
              ),
              _vm._v(" Preview "),
              _c("span", { staticClass: "slds-assistive-text" }, [
                _vm._v("Preview")
              ])
            ]
          )
        ],
        { disabled: !_vm.enabled }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }