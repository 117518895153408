




















































import { Vue } from 'vue-property-decorator';
import axios from 'axios';
import S3FileService from '../../services/api/S3File.service';
import LenderService from '../../services/api/Lender.service';

const DUMMY_PASSWORD = 'DUMMY_PASSWORD';

export default Vue.extend({
  name: 'LogoTab',
  components: {
    // PageLayout,
  },
  data: () => ({
    logoSrc: '',
    errors: [],
    progress: false,
    selectedFile: null as File | null,
    selectedFileRules: [
      (v: string) => !!v || 'Please select a file',
      (value: File) => !value || value.size < 1048576 || 'File is too large',
    ],
    snackBarSuccess: false,
    snackBarError: false,
  }),
  created() {
    this.fetchLenderLogo();
  },
  methods: {
    async fetchLenderLogo() {
      const axiosCl = axios.create();
      const response: {
        data: {
          imageInBase64: string;
          imageFormat: string;
        };
      } = await axiosCl.get('/uiapi/s3/download-lender-logo/presigned', {
        params: {
          orgId: this.passEditedRecord.orgId,
        },
      });

      if (response.data.imageInBase64) {
        this.logoSrc = response.data.imageFormat + response.data.imageInBase64;
      }
    },
    async uploadSelectedFile() {
      try {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate() && this.selectedFile) {
          this.progress = true;
          const s3 = await S3FileService.getPresignedLenderLogoUrl(this.selectedFile.name, this.passEditedRecord.orgId);
          const s3logoUrl = s3.logoUrl;
          const axiosCl = axios.create();
          await axiosCl.put(s3.url, this.selectedFile, {
            headers: {
              'Content-Type': this.selectedFile.type,
            },
          });

          if (
            !this.passEditedRecord.integrationCommunicationSetup[0].email &&
            (!this.passEditedRecord.integrationCommunicationSetup[0].password ||
              this.passEditedRecord.integrationCommunicationSetup[0]?.password === DUMMY_PASSWORD) &&
            !this.passEditedRecord.integrationCommunicationSetup[0].username
          ) {
            this.passEditedRecord.integrationCommunicationSetup.length = 0;
          }

          const updateLenderResult = await LenderService.update(
            Object.assign(this.passEditedRecord, { lenderLogo: s3logoUrl }),
          );

          if (updateLenderResult.errors && updateLenderResult.errors.length > 0) {
            this.selectedFile = null;
            this.progress = false;
            this.setError([updateLenderResult.errors[0]]);
            this.snackBarError = true;
            return;
          }

          await this.fetchLenderLogo();

          this.snackBarSuccess = true;
          this.selectedFile = null;
          this.progress = false;
          this.resetValidation();
        }
      } catch (error) {
        this.selectedFile = null;
        this.progress = false;
        this.setError([error.response?.data?.message] || ['Save Failed']);
        this.snackBarError = true;
      }
    },
    async updateLender(s3LogoUrl: string) {
      const updateLenderResult = await LenderService.update(
        Object.assign(this.passEditedRecord, { lenderLogo: s3LogoUrl }),
      );

      if (updateLenderResult.errors && updateLenderResult.errors.length > 0) {
        throw new Error(updateLenderResult.errors[0]);
      }
    },
    setError(errors: string[]) {
      this.errors.length = 0;
      Object.assign(this.errors, errors);
    },
    resetValidation() {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).form.resetValidation();
      }, 100);
    },
  },
  props: {
    passEditedRecord: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
