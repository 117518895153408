var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page-scroll-layout", [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c(
          "v-row",
          { staticClass: "ml-1 mr-1" },
          [
            _c(
              "v-col",
              { attrs: { cols: "6" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticStyle: { "white-space": "nowrap" },
                        attrs: { cols: "3" }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              elevation: "2",
                              medium: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.goBackToGrid()
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v(" mdi-arrow-left")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              color: "primary",
                              elevation: "2",
                              medium: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.recordDataRefresh()
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v(" mdi-refresh")])],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            readonly: "",
                            autocomplete: "none",
                            label: "VIN"
                          },
                          model: {
                            value: _vm.dataPassed.VIN,
                            callback: function($$v) {
                              _vm.$set(_vm.dataPassed, "VIN", $$v)
                            },
                            expression: "dataPassed.VIN"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            readonly: "",
                            autocomplete: "none",
                            label: "Dealer Number"
                          },
                          model: {
                            value: _vm.dataPassed.dealerNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.dataPassed, "dealerNumber", $$v)
                            },
                            expression: "dataPassed.dealerNumber"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-col",
              { staticStyle: { "text-align": "right" }, attrs: { cols: "6" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      color: "primary",
                      disabled: _vm.processing || _vm.disabled
                    },
                    on: {
                      click: function($event) {
                        return _vm.submitDataRequest()
                      }
                    }
                  },
                  [_vm._v(" Save Account")]
                ),
                _vm.dataPassed.id &&
                _vm.dataPassed.accountProcessingStatus === "Open" &&
                _vm.productsCount === 0
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          color: "primary",
                          disabled: _vm.processing || _vm.disabled
                        },
                        on: {
                          click: function($event) {
                            return _vm.createNoCancellableProduct()
                          }
                        }
                      },
                      [_vm._v(" No Cancellable Product ")]
                    )
                  : _vm._e(),
                _vm.saveAndNextButtonVisible && _vm.dataPassed.id
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          disabled: _vm.processing || _vm.disabled
                        },
                        on: {
                          click: function($event) {
                            return _vm.submitDataRequest(true)
                          }
                        }
                      },
                      [_vm._v(" SAVE AND NEXT ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { attrs: { slot: "content" }, slot: "content" },
      [
        _c(
          "v-row",
          {
            staticClass:
              "bottom-row flex-grow-1 flex-shrink-1 account-details-layout-content-row",
            attrs: { "no-gutters": "" }
          },
          [
            _c(
              "v-col",
              { staticClass: "ma-0", attrs: { cols: "6" } },
              [
                _c("account-details-form", {
                  ref: "accountDetailsForm",
                  attrs: {
                    readonly: _vm.readonly,
                    disabled: _vm.disabled,
                    "account-data": _vm.dataPassed
                  },
                  on: { accountUpdated: _vm.onAccountUpdated }
                })
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "6" } },
              [
                _c(
                  "v-card",
                  { staticClass: "mt-8" },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-tabs",
                          [
                            _c("v-tab", { attrs: { href: "#related" } }, [
                              _vm._v("Related")
                            ]),
                            _c(
                              "v-tab-item",
                              {
                                staticClass: "mt-5",
                                attrs: { value: "related" }
                              },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticStyle: {
                                      "padding-left": "15px",
                                      "padding-top": "10px",
                                      "padding-bottom": "0px",
                                      background: "#EEE"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "Products (" +
                                        _vm._s(_vm.productsCount) +
                                        ")"
                                    )
                                  ]
                                ),
                                _c("data-grid", {
                                  ref: "ProductsGrid",
                                  attrs: {
                                    forceTableHeight: 200,
                                    listViewHide: "",
                                    filterButtonHide: "",
                                    apiDataUrl: "/uiapi/product/search",
                                    apiMetadataUrl: "/uiapi/grids/product",
                                    headersAutoParserMapping: "",
                                    defaultQueue: "All",
                                    actions: _vm.productActions,
                                    "button-actions": _vm.buttonProductActions,
                                    headerColumnsShow: [
                                      {
                                        text: "Actions",
                                        value: "Actions"
                                      },
                                      {
                                        text: "Product Id",
                                        value: "id"
                                      },
                                      {
                                        text: "Provider Name",
                                        value: "provider.name"
                                      },
                                      {
                                        text: "Product Processing Status",
                                        value: "productProcessingStatus"
                                      },
                                      {
                                        text: "Product Type",
                                        value: "productType"
                                      }
                                    ],
                                    "fixed-conditions":
                                      typeof _vm.dataPassed.orgId ===
                                        "string" &&
                                      _vm.dataPassed.orgId !== "" &&
                                      _vm.dataPassed.id
                                        ? [
                                            {
                                              fieldName: "orgId",
                                              value: _vm.dataPassed.orgId,
                                              fieldType: "string",
                                              conditionComparatorOption: "equal"
                                            },
                                            {
                                              fieldName: "accountNumber",
                                              value:
                                                _vm.dataPassed.accountNumber,
                                              fieldType: "string",
                                              conditionComparatorOption: "equal"
                                            }
                                          ]
                                        : [],
                                    "fixed-conditions-prevent-call": true
                                  },
                                  on: { onGridItemsCount: _vm.getProductsCount }
                                }),
                                _c(
                                  "v-card-text",
                                  {
                                    staticStyle: {
                                      "padding-left": "15px",
                                      "padding-top": "10px",
                                      "padding-bottom": "0px",
                                      background: "#EEE"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "Lender Receivables (" +
                                        _vm._s(_vm.productsPaymentsCount) +
                                        ")"
                                    )
                                  ]
                                ),
                                _c("data-grid", {
                                  ref: "ProductPaymentsGrid",
                                  attrs: {
                                    showFilterSets: false,
                                    filterButtonHide: "",
                                    listViewHide: "",
                                    forceTableHeight: 200,
                                    apiDataUrl: "/uiapi/product-payment/search",
                                    apiMetadataUrl:
                                      "/uiapi/grids/product-payment",
                                    defaultQueue: "Account",
                                    "button-actions":
                                      _vm.buttonProductPaymentActions,
                                    actions: _vm.productPaymentActions,
                                    "fixed-conditions":
                                      typeof _vm.dataPassed.orgId ===
                                        "string" &&
                                      _vm.dataPassed.orgId !== "" &&
                                      _vm.dataPassed.id
                                        ? [
                                            {
                                              fieldName: "orgId",
                                              value: _vm.dataPassed.orgId,
                                              fieldType: "string",
                                              conditionComparatorOption:
                                                "contain"
                                            },
                                            {
                                              fieldName: "accountNumber",
                                              value:
                                                _vm.dataPassed.accountNumber,
                                              fieldType: "string",
                                              conditionComparatorOption: "equal"
                                            }
                                          ]
                                        : [],
                                    "fixed-conditions-prevent-call": true
                                  },
                                  on: {
                                    onGridItemsCount:
                                      _vm.getProductPaymentsCount
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-tab", { attrs: { href: "#attachments" } }, [
                              _vm._v("Attachments")
                            ]),
                            _c(
                              "v-tab-item",
                              {
                                staticClass: "mt-5",
                                attrs: { value: "attachments", eager: "" }
                              },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticStyle: {
                                      "padding-left": "15px",
                                      "padding-top": "10px",
                                      "padding-bottom": "0px",
                                      background: "#EEE"
                                    }
                                  },
                                  [_vm._v("Attachments")]
                                ),
                                _c("data-grid", {
                                  ref: "AttachmentsGrid",
                                  attrs: {
                                    listViewHide: "",
                                    filterButtonHide: "",
                                    "button-actions":
                                      _vm.buttonAttachmentActions,
                                    apiDataUrl: "/uiapi/attachment/search",
                                    apiMetadataUrl: "/uiapi/grids/attachment",
                                    actions: _vm.attachmentActions,
                                    actionsToLastColumn: true,
                                    previews: _vm.attachmentDownload,
                                    headersAutoParserMapping: "",
                                    "fixed-conditions": [
                                      {
                                        fieldName: "orgId",
                                        value: _vm.dataPassed.orgId,
                                        fieldType: "string",
                                        conditionComparatorOption: "equal"
                                      },
                                      {
                                        fieldName: "accountNumber",
                                        value: _vm.dataPassed.accountNumber,
                                        fieldType: "string",
                                        conditionComparatorOption: "equal"
                                      }
                                    ],
                                    "fixed-conditions-prevent-call": true
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-tab", { attrs: { href: "#audit" } }, [
                              _vm._v("Audit History")
                            ]),
                            _c(
                              "v-tab-item",
                              {
                                staticClass: "mt-5",
                                attrs: { value: "audit" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "v-card v-sheet theme--light",
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                      position: "relative",
                                      "padding-bottom": "5px",
                                      height: "calc(100vh - 320px)"
                                    }
                                  },
                                  [
                                    _c("audit-history-component", {
                                      ref: "AuditHistoryComponent",
                                      attrs: {
                                        orgId: _vm.dataPassed.orgId,
                                        externalId:
                                          _vm.dataPassed.accountNumber,
                                        auditType: "account",
                                        footerHeight: 405
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-overlay", { attrs: { absolute: "", value: _vm.processing } })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { attrs: { slot: "hidden" }, slot: "hidden" },
      [
        _c(
          "custom-dialog",
          { ref: "CustomDialog", on: { close: _vm.onProductDetailClose } },
          [
            _c("div", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.createProductMode ? "Create Product" : "Edit Product"
                  ) +
                  " "
              )
            ]),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _vm.productDialogOpen
                  ? _c("product-details", {
                      ref: "productDetails",
                      attrs: {
                        enableInternalButtons: "",
                        prefilledAccount: _vm.dataPassed,
                        createMode: _vm.createProductMode,
                        disabled: _vm.disabled,
                        "pass-edited-record": _vm.productDetails,
                        showRefresh: !_vm.createProductMode,
                        refundDetailsShow: true
                      },
                      on: {
                        productUpdated: _vm.onProductUpdated,
                        "update:createMode": function($event) {
                          _vm.createProductMode = $event
                        },
                        "update:create-mode": function($event) {
                          _vm.createProductMode = $event
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "buttons" }, slot: "buttons" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    attrs: { elevation: "2" },
                    on: {
                      click: function($event) {
                        return _vm.setProductDialogOpen(false)
                      }
                    }
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.createProductMode,
                        expression: "!createProductMode"
                      }
                    ],
                    staticClass: "mr-3 primary",
                    attrs: { elevation: "2" },
                    on: {
                      click: function($event) {
                        return _vm.validateProduct()
                      }
                    }
                  },
                  [_vm._v(" Validate ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary",
                    attrs: { elevation: "2", disabled: _vm.disabled },
                    on: {
                      click: function($event) {
                        return _vm.updateProductDetails()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.createProductMode ? "Create" : "Update") +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "custom-dialog",
          {
            ref: "attachmentDialog",
            attrs: { maxWidth: "600px" },
            on: { close: _vm.onAttachmentDetailClose }
          },
          [
            _c("div", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("Attachment")
            ]),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _vm.attachmentDialogOpen
                  ? _c("account-attachment-form", {
                      ref: "accountAttachmentForm",
                      attrs: { account: _vm.dataPassed },
                      on: { uploadCompleted: _vm.onAttachmentUploadCompleted }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "buttons" }, slot: "buttons" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    attrs: { elevation: "2" },
                    on: {
                      click: function($event) {
                        return _vm.setAttachmentDialogOpen(false)
                      }
                    }
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary",
                    attrs: { elevation: "2" },
                    on: {
                      click: function($event) {
                        return _vm.addAttachment()
                      }
                    }
                  },
                  [_vm._v(" Add Attachment ")]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "custom-dialog",
          {
            ref: "productPaymentDialog",
            attrs: { maxWidth: "800px" },
            on: { close: _vm.onProductPaymentDialogClose }
          },
          [
            _c("div", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("Product Payment")
            ]),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _vm.productPaymentDialogOpen
                  ? _c("product-payment-details", {
                      ref: "productPaymentDetails",
                      attrs: {
                        account: _vm.dataPassed,
                        productPayment: _vm.productPaymentDetails,
                        createMode: _vm.createProductPaymentMode,
                        disabled: _vm.disabled
                      },
                      on: { productPaymentUpdated: _vm.onProductPaymentUpdated }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "buttons" }, slot: "buttons" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    attrs: { elevation: "2" },
                    on: {
                      click: function($event) {
                        return _vm.setProductPaymentDialogOpen(false)
                      }
                    }
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary",
                    attrs: { elevation: "2", disabled: _vm.disabled },
                    on: {
                      click: function($event) {
                        return _vm.updateProductPaymentDetails()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.createProductPaymentMode ? "Create" : "Update"
                        ) +
                        " Product Payment "
                    )
                  ]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "custom-dialog",
          { ref: "newProductNotification", attrs: { maxWidth: "400px" } },
          [
            _c("div", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("Save Account?")
            ]),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c("div", [
                  _vm._v(
                    " Account must be saved prior to adding product would you like to save account. "
                  )
                ]),
                !_vm.accountFormValid
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "ma-0 mt-2",
                        staticStyle: { "font-size": "13px" },
                        attrs: { type: "error", outlined: "", dense: "" }
                      },
                      [
                        _vm._v(
                          " Account data (Account #) is not valid. Please correct before save. "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "buttons" }, slot: "buttons" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    attrs: { elevation: "2" },
                    on: {
                      click: function($event) {
                        return _vm.setNewProductNotificationDialogOpen(false)
                      }
                    }
                  },
                  [_vm._v(" No ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-0 primary",
                    attrs: { elevation: "2", disabled: !_vm.accountFormValid },
                    on: {
                      click: function($event) {
                        return _vm.saveAccountAndOpenProduct()
                      }
                    }
                  },
                  [_vm._v(" Yes ")]
                )
              ],
              1
            )
          ]
        ),
        _c("remove-product-action", {
          ref: "RemoveProductAction",
          attrs: { eager: "" },
          on: {
            success: function($event) {
              _vm.productDeleteSuccess()
              _vm.onProductUpdated()
            }
          }
        }),
        _c("remove-attachment-action", {
          ref: "RemoveAttachmentAction",
          attrs: { eager: "" },
          on: {
            success: function($event) {
              return _vm.attachmentDeleteSuccess()
            }
          }
        }),
        _c("remove-product-payment-action", {
          ref: "RemoveProductPaymentAction",
          attrs: { eager: "" },
          on: {
            success: function($event) {
              return _vm.productPaymentDeleteSuccess()
            }
          }
        }),
        _c("create-no-cancellable-product-action", {
          ref: "CreateNoCancellableProductAction",
          attrs: { eager: "" },
          on: { success: _vm.noCalcellableProductCreateSuccess }
        }),
        _c(
          "v-snackbar",
          {
            attrs: { centered: "", color: "error", text: "", timeout: 4000 },
            model: {
              value: _vm.snackBarError,
              callback: function($$v) {
                _vm.snackBarError = $$v
              },
              expression: "snackBarError"
            }
          },
          [
            _c("v-icon", { attrs: { color: "error" } }, [
              _vm._v(" mdi-alert-circle-outline ")
            ]),
            _vm._v(" " + _vm._s(_vm.snackBarErrorMessage) + " ")
          ],
          1
        ),
        _c(
          "custom-dialog",
          {
            ref: "SaveWithReprocessPromptAccount",
            attrs: {
              closeDialogDisabled: true,
              maxWidth: "450px",
              messages: [
                "Product Type, VIN, Dealer, Cancel Type or Provider have been changed. To save your changes the cancellation must be Reprocessed."
              ]
            }
          },
          [
            _c("div", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("Reprocess Required")
            ]),
            _c(
              "div",
              { attrs: { slot: "buttons" }, slot: "buttons" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-1 primary",
                    on: {
                      click: function($event) {
                        return _vm.submitDataRequestAndRepreocess()
                      }
                    }
                  },
                  [_vm._v(" Reprocess ")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { eager: "" },
                    on: {
                      click: function($event) {
                        _vm.recordDataRefresh()
                        _vm
                          .getRemoteComponent("SaveWithReprocessPromptAccount")
                          .external()
                          .close()
                      }
                    }
                  },
                  [_vm._v(" Cancel ")]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "confirmation-dialog",
          _vm._b(
            {
              attrs: {
                buttons: _vm.confirmationButtons,
                cancelVisible: false,
                cancelDisabled: true,
                eager: ""
              }
            },
            "confirmation-dialog",
            _vm.confirmationDialogExit,
            false
          ),
          [
            _c("div", { attrs: { slot: "message" }, slot: "message" }, [
              _vm._v(" You have unsaved data, do you want to leave the page? ")
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }