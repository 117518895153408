

































import { Vue } from 'vue-property-decorator';
import { Product } from '../interfaces';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import SearchInputField from '@/components/SearchInputField.vue';
import axios from '../services/api/Api.service';
import { AxiosResponse } from 'axios';

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

const recordUpdateEndpointUrl = '/uiapi/product/user-assign';

export interface AssignUserRequestObjectI {
  operationType: 'ASSIGN' | 'UNASSIGN';
  productIds: number[];
  username: null | string | number;
  userId: null | string | number;
}

export interface UserI {
  id: null | number;
  username: null | string;
  name?: null | string; // fake coulmn prepared for searching
  firstName: null | string;
  lastName: null | string;
  role: null | string;
  phoneNumber: null | string;
}

export default Vue.extend({
  name: 'AssignUserToProductAction',
  components: { ConfirmationDialog, SearchInputField },
  data: () => ({
    selectedUser: null as UserI | null,
    submitButtonDisabled: false as boolean,
    rowSelections: [] as Product[],
    requestObject: {} as AssignUserRequestObjectI,
    openDialog: false,
    confirmationDialog: {
      processing: false,
      ref: 'ConfirmationDialog',
      title: 'Assign User' as string,
      message: '' as string,
      errors: [] as string[],
      messages: [] as string[],
    },
  }),
  methods: {
    selectUser(selectedUser: UserI) {
      this.selectedUser = selectedUser;
      this.requestObject.username = selectedUser.username;
      this.requestObject.userId = selectedUser.id;
    },
    setSubmitButtonDisabled(option: boolean) {
      this.submitButtonDisabled = option;
    },
    submitDataRequest(type: 'ASSIGN' | 'UNASSIGN'): boolean {
      // reset error box on send request
      this.error().clear();
      this.message().clear();

      this.requestObject.operationType = type;
      this.requestObject.productIds = this.rowSelections
        .filter((product: Product) => {
          return product.id !== undefined;
        })
        .map((product: Product) => {
          return product.id;
        }) as number[];

      if (this.requestObject.operationType === 'ASSIGN') {
        if (!this.requestObject.username) {
          this.error().add('User cannot be empty for Assign operation.');
          return false;
        }
      }

      if (this.requestObject.operationType === 'UNASSIGN') {
        this.requestObject.username = null;
        this.requestObject.userId = null;
      }

      this.confirmationDialog.processing = true;
      let requestObject = Object.assign({}, this.requestObject) as AssignUserRequestObjectI;

      axiosInstance
        .post<
          AssignUserRequestObjectI,
          AxiosResponse<{
            success: boolean;
            errors: string[];
            messages: string[];
          }>
        >(recordUpdateEndpointUrl, requestObject)
        .then(result => {
          if (result.data.success) {
            this.message().set(result.data.messages);
            this.$emit('success');
            setTimeout(() => {
              this.openDialog = false;
              this.confirmationDialog.processing = false;
            }, 2000);
          } else {
            this.confirmationDialog.processing = false;
            this.$emit('error');
            this.error().set(result.data.errors);
          }
        })
        .catch((error: string) => {
          this.confirmationDialog.processing = false;
          this.error().add(error);
        });
      return true;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    open(rowSelections: Product[]): void {
      this.selectedUser = null;
      this.confirmationDialog.message = 'Products selected (' + rowSelections.length + ')';
      this.rowSelections = rowSelections;
      this.confirmationDialog.processing = false;
      this.submitButtonDisabled = false;
      this.error().clear();
      this.message().clear();
      this.setSubmitButtonDisabled(false);

      if (rowSelections.length < 1) {
        this.submitButtonDisabled = true;
        this.error().add('No products were selected');
      }

      this.openDialog = true;

      setTimeout(() => {
        this.getRemoteComponent('ConfirmationDialog').open = true;
      }, 100);
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.confirmationDialog.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.confirmationDialog.errors.push(error);
          return this;
        },
        clear: () => {
          this.confirmationDialog.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.confirmationDialog.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.confirmationDialog.messages.push(error);
          return this;
        },
        clear: () => {
          this.confirmationDialog.messages.length = 0;
          return this;
        },
      };
    },
  },
  computed: {
    buttons() {
      return [
        {
          name: 'Unassign',
          color: 'error',
          disabled: this.submitButtonDisabled,
          callback: () => {
            this.submitDataRequest('UNASSIGN');
            this.$emit('completed');
          },
        },
        {
          name: 'Assign',
          color: 'primary',
          disabled: this.submitButtonDisabled || !this.selectedUser?.username,
          callback: () => {
            this.submitDataRequest('ASSIGN');
            this.$emit('completed');
          },
        },
      ];
    },
  },
});
