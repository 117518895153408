var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "sfl-base-container sfl-flex sfl-flex-column sfl-background-color"
    },
    [
      _c(
        "div",
        { staticStyle: { height: "48px", "border-bottom": "5px solid #ddd" } },
        [
          _c(
            "div",
            { staticClass: "slds-builder-toolbar", attrs: { role: "toolbar" } },
            [
              _c(
                "div",
                {
                  staticClass: "slds-builder-toolbar__item-group",
                  staticStyle: {
                    "padding-left": "190px",
                    "font-weight": "bold"
                  },
                  attrs: { "aria-label": "Canvas Actions" }
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "slds-builder-toolbar__actions",
                  staticStyle: { height: "38px" },
                  attrs: { "aria-label": "Document actions" }
                },
                [
                  false
                    ? _c(
                        "button",
                        {
                          staticClass: "slds-button slds-p-horizontal_small",
                          attrs: { "aria-live": "polite" }
                        },
                        [_vm._v("?")]
                      )
                    : _vm._e(),
                  false
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "slds-button slds-button_icon slds-button_icon-container slds-button_icon-warning",
                          attrs: { title: "warning" }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "slds-button__icon",
                              attrs: { "aria-hidden": "true" }
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                                    "#warning"
                                }
                              })
                            ]
                          ),
                          _c("span", { staticClass: "slds-assistive-text" }, [
                            _vm._v("warning")
                          ])
                        ]
                      )
                    : _vm._e(),
                  false
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "slds-button slds-button_icon slds-button_icon-container slds-button_icon-error",
                          attrs: { title: "error" }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "slds-button__icon",
                              attrs: { "aria-hidden": "true" }
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                                    "#error"
                                }
                              })
                            ]
                          ),
                          _c("span", { staticClass: "slds-assistive-text" }, [
                            _vm._v("error")
                          ])
                        ]
                      )
                    : _vm._e(),
                  ["Library"].indexOf(_vm.menuSelection.name) !== -1 &&
                  !_vm.readonly
                    ? _c("sf-letter-upload", { attrs: { orgId: _vm.orgId } })
                    : _vm._e(),
                  _vm.buttonText() !== false
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "visible",
                              rawName: "v-visible",
                              value: !_vm.readonly,
                              expression: "!readonly"
                            }
                          ],
                          staticClass: "slds-button slds-button_neutral",
                          attrs: { title: "Add New Rule" },
                          on: {
                            click: function($event) {
                              return _vm.addNewRule()
                            }
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "slds-button__icon slds-button__icon_left",
                              attrs: { "aria-hidden": "true" }
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                                    "#record_create"
                                }
                              })
                            ]
                          ),
                          _vm._v(" " + _vm._s(_vm.buttonText()) + " "),
                          _c("span", { staticClass: "slds-assistive-text" }, [
                            _vm._v(_vm._s(_vm.buttonText()))
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "sfl-flex sfl-flex-row",
          staticStyle: { flex: "1", height: "100%" }
        },
        [
          _c(
            "div",
            {
              staticClass: "sfl-flex sfl-flex-row sfl-background-color",
              staticStyle: { flex: "1" }
            },
            [
              _vm.showMenu
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "200px",
                        height: "100%",
                        display: "flex",
                        "flex-direction": "row"
                      }
                    },
                    [
                      _c("lender-service-configuration-menu", {
                        attrs: { menu: _vm.menu },
                        on: { input: _vm.menuSelect },
                        model: {
                          value: _vm.menuSelection,
                          callback: function($$v) {
                            _vm.menuSelection = $$v
                          },
                          expression: "menuSelection"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menuSelection.name === "Settings"
                ? _c(
                    "div",
                    { staticStyle: { flex: "1", height: "100%" } },
                    [
                      _c("lender-service-configuration-settings", {
                        ref: "LenderServiceConfigurationSettingsComponent",
                        attrs: {
                          orgId: _vm.orgId,
                          "footer-height": _vm.footerHeight
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menuSelection.name === "General"
                ? _c(
                    "div",
                    { staticStyle: { flex: "1", height: "100%" } },
                    [
                      _c("lender-service-configuration-settings-general", {
                        ref:
                          "LenderServiceConfigurationSettingsGeneralComponent",
                        attrs: {
                          orgId: _vm.orgId,
                          "footer-height": _vm.footerHeight
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menuSelection.name === "Dealer Invoice"
                ? _c(
                    "div",
                    { staticStyle: { flex: "1", height: "100%" } },
                    [
                      _c(
                        "lender-service-configuration-settings-dealer-invoice",
                        {
                          ref:
                            "LenderServiceConfigurationSettingsDealerInvoiceComponent",
                          attrs: {
                            orgId: _vm.orgId,
                            "footer-height": _vm.footerHeight
                          }
                        }
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menuSelection.name === "Fees"
                ? _c(
                    "div",
                    { staticStyle: { flex: "1", height: "100%" } },
                    [
                      _c("lender-service-configuration-fee", {
                        ref: "LenderServiceConfigurationFeeComponent",
                        attrs: {
                          orgId: _vm.orgId,
                          "footer-height": _vm.footerHeight
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menuSelection.name === "Paths"
                ? _c(
                    "div",
                    { staticStyle: { flex: "1", height: "100%" } },
                    [
                      _c("lender-service-configuration-paths", {
                        ref: "LenderServiceConfigurationPathsComponent",
                        attrs: {
                          orgId: _vm.orgId,
                          "footer-height": _vm.footerHeight
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menuSelection.name === "Days to Refund"
                ? _c(
                    "div",
                    { staticStyle: { flex: "1", height: "100%" } },
                    [
                      _c("lender-service-configuration-days-to-refund", {
                        ref: "LenderServiceConfigurationDaysToRefundComponent",
                        attrs: {
                          orgId: _vm.orgId,
                          "footer-height": _vm.footerHeight
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menuSelection.name === "Free Look"
                ? _c(
                    "div",
                    { staticStyle: { flex: "1", height: "100%" } },
                    [
                      _c("lender-service-configuration-free-look", {
                        ref: "LenderServiceConfigurationFreeLookComponent",
                        attrs: {
                          orgId: _vm.orgId,
                          "footer-height": _vm.footerHeight
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menuSelection.name === "Rules"
                ? _c(
                    "div",
                    { staticStyle: { flex: "1", height: "100%" } },
                    [
                      _c("lender-service-configuration-letters", {
                        ref: "LenderServiceConfigurationLettersComponent",
                        attrs: {
                          orgId: _vm.orgId,
                          "footer-height": _vm.footerHeight
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menuSelection.name === "Library"
                ? _c(
                    "div",
                    { staticStyle: { flex: "1", height: "100%" } },
                    [
                      _c("lender-service-configuration-letters-library", {
                        ref:
                          "LenderServiceConfigurationLettersLibraryComponent",
                        attrs: {
                          orgId: _vm.orgId,
                          "footer-height": _vm.footerHeight
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ]
      ),
      _vm._t("NetworkDisconnected"),
      _vm._t("SessionDisconnected"),
      _c(
        "v-snackbar",
        {
          attrs: { centered: "", color: "success", text: "" },
          model: {
            value: _vm.SavedSuccessfully,
            callback: function($$v) {
              _vm.SavedSuccessfully = $$v
            },
            expression: "SavedSuccessfully"
          }
        },
        [
          _c("v-progress-circular", {
            staticClass: "mr-5",
            attrs: { color: "success", value: "100" }
          }),
          _vm._v(" Saved Successfully... ")
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { centered: "", color: "default", text: "" },
          model: {
            value: _vm.processing,
            callback: function($$v) {
              _vm.processing = $$v
            },
            expression: "processing"
          }
        },
        [
          _c("v-progress-circular", {
            staticClass: "mr-5",
            attrs: { indeterminate: "", color: "primary" }
          }),
          _vm._v(" Processing... ")
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { centered: "", color: "error", text: "" },
          model: {
            value: _vm.processingError,
            callback: function($$v) {
              _vm.processingError = $$v
            },
            expression: "processingError"
          }
        },
        [
          _c("v-progress-circular", {
            staticClass: "mr-5",
            attrs: { indeterminate: "", color: "error" }
          }),
          _vm._v(" " + _vm._s(_vm.processingErrorMessage) + " ")
        ],
        1
      ),
      _vm.orgId
        ? _c("sf-letter-preview", {
            ref: "SfLetterPreviewDialog",
            attrs: { orgId: _vm.orgId }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }