var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        flex: "1",
        height: "100%",
        "flex-direction": "row",
        background: "white"
      }
    },
    [
      _c("v-container", { staticClass: "pa-2 pt-0 ma-0" }, [
        _c("div", { staticClass: "slds-section slds-is-open" }, [
          _c("h3", { staticClass: "slds-section__title" }, [
            _c(
              "div",
              {
                staticClass: "slds-button slds-section__title-action",
                attrs: {
                  "aria-controls": "expando-unique-id",
                  "aria-expanded": "true"
                }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "slds-truncate",
                    attrs: { title: "Section Title" }
                  },
                  [
                    _vm._v(
                      "Provider Cancel Date Before Cancellation Sent Date " +
                        _vm._s(_vm.readonly ? " (Read-Only)" : "")
                    )
                  ]
                )
              ]
            )
          ]),
          _c(
            "div",
            {
              staticClass: "slds-section__content",
              attrs: { "aria-hidden": "false", id: "expando-unique-id" }
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("error-message-info", {
                        ref: "InvoiceSettingsErrorMessageInfo",
                        attrs: { eager: "" }
                      })
                    ],
                    1
                  ),
                  _vm.editedRecord !== null
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _vm.editedRecord.dealerInvoicing !== "Disabled"
                                ? _c("v-checkbox", {
                                    attrs: {
                                      label: "Send to Lender Review",
                                      outlined: "",
                                      dense: "",
                                      readonly: _vm.readonly
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.saveButtonActive = true
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.editedRecord.sendToLenderReview,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedRecord,
                                          "sendToLenderReview",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedRecord.sendToLenderReview"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("v-col")
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c("v-col"),
                      _c(
                        "v-col",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary medium",
                              attrs: {
                                disabled: !_vm.saveButtonActive || _vm.readonly
                              },
                              on: {
                                click: function($event) {
                                  return _vm.updateData()
                                }
                              }
                            },
                            [_vm._v(" Save ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }