










































































import { Vue } from 'vue-property-decorator';
import SfCustomDialog from '@/components/SFComponents/SfCustomDialog.vue';
import SfDataGrid from '@/components/SFComponents/SfDataGrid.vue';
import LenderServiceConfigurationLetterEditForm from '@/components/SFComponents/LenderServiceConfiguration/Letters/LetterEditForm.vue';
import { LetterI } from '@/interfaces';
import UserService from '@/services/api/User.service';
import { LetterConfigurationRecordM } from '@/models';
import EventBus from '@/events/EventBus';

const emptyLetterRecord = JSON.parse(JSON.stringify(LetterConfigurationRecordM));

export default Vue.extend({
  name: 'LenderServiceConfigurationLetters',
  data() {
    return {
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      saveButtonActive: true,
      editedRecord: null as null | LetterI,
      gridApiUrls: {
        metadata: '/uiapi/grids/management-rule',
        data: '/uiapi/management-rule/search',
        lookups: {
          providers: {
            metadata: '/uiapi/grids/provider',
            data: '/uiapi/provider/search',
          },
          dealers: {
            metadata: '/uiapi/grids/dealer',
            data: '/uiapi/dealer/search',
            externalSearchConditions: [
              {
                comparatorOption: 'contain',
                fieldName: 'name',
                fixed: false,
                type: 'String',
                value: null,
              },
              {
                comparatorOption: 'equal',
                fieldName: 'dealerNumber',
                fixed: false,
                type: 'String',
                value: null,
              },
            ],
          },
        },
      },
    };
  },
  components: { SfDataGrid, SfCustomDialog, LenderServiceConfigurationLetterEditForm },
  methods: {
    actions() {
      const orgId = this.orgId;
      return [
        {
          icon: 'mdi-file-find',
          tooltip: 'Preview Letter',
          disabled: function(item: any) {
            return !item.letterFile;
          },
          callback: (rowData: LetterI): void => {
            EventBus.$emit('SF_OPEN_LETTER_FORM_PREVIEW', rowData.letterFile);
          },
        },
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit Rule',
          disabled: false,
          callback: (rowData: LetterI): void => {
            this.editRecord(rowData);
          },
        },
      ];
    },
    getRemoteComponent(ref: string) {
      return this.$getRemoteComponent(ref, this);
    },
    refreshGrid() {
      this.$getRemoteComponent('LenderServiceConfigurationLetterGrid', this).loadData();
    },
    async saveFormData() {
      this.saveButtonActive = false;

      this.$getRemoteComponent('LenderServiceConfigurationLetterEditForm', this).addCustomParameters();
      this.$getRemoteComponent('LenderServiceConfigurationLetterEditForm', this).prepareSaveDate();

      this.$getRemoteComponent('LenderServiceConfigurationLetterEditForm', this)
        .submitForm()
        .then(() => {
          this.saveButtonActive = true;
          this.$getRemoteComponent('LetterCustomDialogForm', this)
            .external()
            .close();
        })
        .catch(() => {
          this.saveButtonActive = true;
        });
    },
    addNewLettersRule() {
      this.editedRecord = this.$getClone(emptyLetterRecord);
      if (this.editedRecord) {
        this.editedRecord.orgId = this.orgId;
      }
    },
    editRecord(record: LetterI) {
      this.editedRecord = this.$getClone(record);
      this.openEditDialog();
    },
    openEditDialog() {
      this.$getRemoteComponent('LetterCustomDialogForm', this)
        .external()
        .open();
    },
  },
  props: ['footerHeight', 'orgId'],
});
