

































































































import { Vue } from 'vue-property-decorator';
import FormatDisplayService from '../services/FormatDisplay.service';
import CustomDatePicker from './CustomDatePicker.vue';
import ValidationService from './../services/Validations.service';
import moment from 'moment';

export default Vue.extend({
  name: 'CustomField',
  components: { CustomDatePicker },
  data() {
    return {
      moment: moment,
      FormatDisplayService: FormatDisplayService,
      ValidationService: ValidationService,
      datetimepicker: {
        open: () => {
          // @ts-ignore: Unreachable code error
          this.$refs.DateTimePicker?.open();
        },
      },
    };
  },
  created() {},
  computed: {
    getRules() {
      const rules = [];
      if (this.definition.required) {
        rules.push(ValidationService.getValidator('required', this.definition.label));
      }
      if (this.definition.type === 'Email') {
        rules.push(ValidationService.getValidator('email', this.definition.label));
      }
      if (this.definition.type === 'Phone') {
        rules.push(ValidationService.getValidator('phone', this.definition.label));
      }
      if (['Currency', 'Number', 'Double', 'Percent'].indexOf(this.definition.type) > -1) {
        rules.push(
          ValidationService.getValidator('customSFDouble', this.definition.label, {
            length: 1,
            sfRules: {
              maxLength: this.definition.maxLength ? this.definition.maxLength : 18,
              decimalPlaces: this.definition.decimalPlaces ? this.definition.decimalPlaces : 2,
              failMessage: 'value format is not valid for type: ' + this.definition.type,
            },
          }),
        );
      }

      return rules;
    },
  },
  methods: {
    makeid(length: number) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
  },
  props: ['definition'],
});
