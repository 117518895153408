





































import moment from 'moment';
import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  name: 'CustomTimePicker',
  components: {},
  data() {
    return {
      time: this.value,
      menu: false,
      content: this.value,
      menuId: null as null | string,
    };
  },
  computed: {},
  created() {
    this.menuId = 'TimePicker' + this.makeid(10);
  },
  methods: {
    timeFormat12(v: string | null | undefined) {
      if (!v) {
        return '';
      }
      return moment.utc(v, 'HH:mm').format('h:mm a');
    },
    makeid(length: number) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    handleInput() {
      this.$emit('input', this.content);
    },
  },
  props: ['value', 'readonly', 'disabled', 'label', 'rules', 'placeholder', 'disableclear', 'prependIcon'],
});
