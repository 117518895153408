import ApiService from '@/services/api/Api.service';
import { Lender, Response, LenderCustomFieldsI } from '../../interfaces';
import { AxiosResponse } from 'axios';

class LenderService extends ApiService {
  async getLendersList(): Promise<LenderListI[]> {
    return this.axios.get('/uiapi/lender/list').then(res => {
      return res.data as LenderListI[];
    });
  }

  async search(): Promise<Lender[]> {
    return this.axios.get('/uiapi/lender/search').then(res => {
      return res.data as Lender[];
    });
  }

  async create(data: Lender): Promise<Response<Lender>> {
    return (await this.axios.post<Lender, AxiosResponse<Response<Lender>>>('/uiapi/lender/', data)).data;
  }

  async update(data: Lender): Promise<Response<Lender>> {
    /**
     * Strip some characters form phone numbers before send
     */
    const phoneNumber = (data.phoneNumber || '').replace(/\D/g, '');
    const lenderFax = (data.lenderFax || '').replace(/\D/g, '');
    const cancelDepartmentPhone = (data.cancelDepartmentPhone || '').replace(/\D/g, '');

    data.phoneNumber = phoneNumber === '' ? null : phoneNumber;
    data.lenderFax = lenderFax === '' ? null : lenderFax;
    data.cancelDepartmentPhone = cancelDepartmentPhone === '' ? null : cancelDepartmentPhone;

    return (await this.axios.post<Lender, AxiosResponse<Response<Lender>>>('/uiapi/lender/' + data.id, data)).data;
  }
}

export interface LenderListI {
  lenderName: string;
  orgId: string;
  active: boolean;
  customFields?: LenderCustomFieldsI[];
}

export default new LenderService();
