var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      this.definition.type === "Text"
        ? _c(
            "v-text-field",
            _vm._g(
              _vm._b(
                { attrs: { rules: _vm.getRules } },
                "v-text-field",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      this.definition.type === "Number"
        ? _c(
            "v-text-field",
            _vm._g(
              _vm._b(
                { attrs: { rules: _vm.getRules } },
                "v-text-field",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      this.definition.type === "Percent"
        ? _c(
            "v-text-field",
            _vm._g(
              _vm._b(
                { attrs: { rules: _vm.getRules } },
                "v-text-field",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      this.definition.type === "Double"
        ? _c(
            "v-text-field",
            _vm._g(
              _vm._b(
                { attrs: { rules: _vm.getRules } },
                "v-text-field",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      this.definition.type === "Currency"
        ? _c(
            "v-text-field",
            _vm._g(
              _vm._b(
                { attrs: { rules: _vm.getRules } },
                "v-text-field",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      this.definition.type === "Geolocation"
        ? _c(
            "custom-geolocation-field",
            _vm._g(
              _vm._b(
                { attrs: { rules: _vm.getRules, definition: _vm.definition } },
                "custom-geolocation-field",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      this.definition.type === "Phone"
        ? _c(
            "v-text-field",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "(###) ###-####",
                      expression: "'(###) ###-####'"
                    }
                  ],
                  attrs: { rules: _vm.getRules }
                },
                "v-text-field",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      this.definition.type === "Email"
        ? _c(
            "v-text-field",
            _vm._g(
              _vm._b(
                { attrs: { rules: _vm.getRules } },
                "v-text-field",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      this.definition.type === "URL"
        ? _c(
            "v-text-field",
            _vm._g(
              _vm._b(
                { attrs: { rules: _vm.getRules } },
                "v-text-field",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      this.definition.type === "Date"
        ? _c(
            "custom-date-picker",
            _vm._g(
              _vm._b(
                { attrs: { rules: _vm.getRules } },
                "custom-date-picker",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      this.definition.type === "Time"
        ? _c(
            "custom-time-picker",
            _vm._g(
              _vm._b(
                { attrs: { rules: _vm.getRules } },
                "custom-time-picker",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      this.definition.type === "Date/time"
        ? _c(
            "div",
            [
              _c(
                "custom-datetime-picker",
                _vm._g(
                  _vm._b(
                    {
                      ref: "DateTimePicker",
                      staticClass: "ma-0 pa-0",
                      staticStyle: { display: "none !important" },
                      attrs: {
                        datetime: _vm.$attrs.value
                          ? _vm
                              .moment(_vm.$attrs.value, "YYYY-MM-DD HH:mm")
                              .format("YYYY-MM-DD HH:mm")
                          : null,
                        rules: _vm.getRules
                      }
                    },
                    "custom-datetime-picker",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              ),
              _c(
                "v-text-field",
                _vm._b(
                  {
                    attrs: {
                      value: _vm.$attrs.value
                        ? _vm
                            .moment(_vm.$attrs.value, "YYYY-MM-DD HH:mm")
                            .format("MM/DD/YYYY HH:mm a")
                        : "",
                      readonly: "",
                      rules: _vm.getRules
                    },
                    on: {
                      click: function($event) {
                        return _vm.datetimepicker.open()
                      }
                    }
                  },
                  "v-text-field",
                  _vm.$attrs,
                  false
                )
              )
            ],
            1
          )
        : _vm._e(),
      this.definition.type === "Text Area"
        ? _c(
            "v-textarea",
            _vm._g(
              _vm._b(
                { attrs: { rules: _vm.getRules } },
                "v-textarea",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }