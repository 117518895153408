























































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import DataGrid from '@/components/DataGrid.vue';
import CollectSearchInputField from '@/components/CollectSearchInputField.vue';
import CustomDialog from '@/components/CustomDialog.vue';
import PageLayout from '@/components/PageLayout.vue';
import { Lender, Provider, Dealer } from '../interfaces';
import FormatDisplayService from '../services/FormatDisplay.service';
import ValidationService from '../services/Validations.service';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

/**
 * Axios Instance for api comunication
 */
const axiosInstance = axios.create();

const apiTransmissionExportUrl = '/uiapi/transmission-export';

const apiDownloadJobUrl = '/uiapi/transmission-export/download/{id}';

/**
 *  Metadata service URL
 */
const metadataEndpointUrl = '/uiapi/grids/transmission-export';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/transmission-export/search';

/**
 * Date Range Configuration
 */
const dateRangeLength = 31;

export type ZipByOprionsI = 'DEALER' | 'PROVIDER' | 'LENDER' | 'DATE ';

export interface TransmissionExportJobRequestI {
  dealer?: {
    operator: 'NOT_IN_LIST' | 'IN_LIST';
    ids: string[];
  };
  provider?: {
    operator: 'NOT_IN_LIST' | 'IN_LIST';
    ids: string[];
  };
  lender?: {
    operator: 'NOT_IN_LIST' | 'IN_LIST';
    ids: string[];
  };
  zipBy: string[];
  date: {
    operator: ZipByOprionsI;
    arguments: string[];
  };
  recipient?: null | 'DEALER' | 'PROVUDER' | 'BOTH';
}

export default Vue.extend({
  name: 'ExportDocument',
  components: {
    DataGrid,
    PageLayout,
    CollectSearchInputField,
    CustomDialog,
  },
  /**
   * Page Component data object
   */
  data: () => ({
    zipByOptions: [
      {
        text: 'Lender',
        value: 'LENDER',
        disabled: false,
      },
      {
        text: 'Dealer',
        value: 'DEALER',
        disabled: false,
      },
      {
        text: 'Provider',
        value: 'PROVIDER',
        disabled: false,
      },
      {
        text: 'Date',
        value: 'DATE',
        disabled: false,
      },
    ],
    dateOptions: [
      { text: 'Between Dates (Max ' + dateRangeLength + ' Days)', value: 'BETWEEN', disabled: false },
      // { text: 'Before Date', value: 'BEFORE', disabled: false },
      // { text: 'After Date', value: 'AFTER', disabled: false },
      { text: 'Equal Date (One Day)', value: 'EQUAL', disabled: false },
      // { text: 'Other than Date', value: 'NOT_EQUAL', disabled: false },
    ],
    snackBarError: false,
    snackBarProgress: false,
    fileDownloadProgress: 0,
    reRenderGrid: 0,
    metadataEnumsMap: new Map(),
    ValidationService: ValidationService as Object,
    validExecute: false as boolean,
    valid: false as boolean,
    processing: false as boolean,
    errors: [] as string[],
    messages: [] as string[],
    datePicker: {
      menu: false,
    },
    selection: {
      lendersExclude: false as boolean,
      lenders: [] as Lender[],
      dealersExclude: false as boolean,
      dealers: [] as Dealer[],
      providersExclude: false as boolean,
      providers: [] as Provider[],
      dateOption: null as string | null,
      zipBy: [] as string[],
      date: null as null | string[],
      recipient: 'BOTH' as null | 'DEALER' | 'PROVUDER' | 'BOTH',
    },
    dataGrid: {
      apiMetadataUrl: metadataEndpointUrl,
      apiDataUrl: dataEndpointUrl,
      ref: 'dataGrid',
      hideFilterSetup: true,
      headersAutoParserMapping: true,
      listViewHide: true,
      buttonActionsHide: true,
    },
    requestObject: {
      dealer: {
        operator: null as null | 'IN_LIST' | 'NOT_IN_LIST',
        ids: [] as string[],
      },
      provider: {
        operator: null as null | 'IN_LIST' | 'NOT_IN_LIST',
        ids: [] as string[],
      },
      lender: {
        operator: null as null | 'IN_LIST' | 'NOT_IN_LIST',
        ids: [] as string[],
      },
      date: {
        operator: null as null | 'EQUAL' | 'NOT_EQUAL' | 'BETWEEN' | 'BEFORE' | 'AFTER',
        arguments: [] as string[],
      },
      zipBy: [] as ZipByOprionsI[],
      recipient: null as null | 'DEALER' | 'PROVUDER' | 'BOTH',
    },
    accountDetails: {
      accountData: {},
    },
    fixedConditions: [] as {}[],
    searchDelay: null as null | number,
  }),
  filters: {
    formatResults(dateArraySource: string[] | null) {
      let dateArray = JSON.parse(JSON.stringify(dateArraySource));
      if (dateArray) {
        let dateFrom = null as string | null;
        let dateTo = null as string | null;
        if (dateArray.length === 2) {
          dateArray.sort();
        }
        if (dateArray.length > 0) {
          dateFrom = FormatDisplayService.getRender('toDate')(dateArray[0]);
        }
        if (dateArray.length === 1) {
          return dateFrom;
        }
        if (dateArray.length === 2) {
          dateTo = FormatDisplayService.getRender('toDate')(dateArray[1]);
        }

        return dateFrom + ' - ' + dateTo;
      }
      return '';
    },
  },
  async created() {},
  computed: {
    actions() {
      return [
        {
          icon: 'mdi-download',
          tooltip: 'Download',
          callback: (rowData: { id: string }): void => {
            this.downloadFile(rowData.id);
          },
          disabled: this.snackBarProgress === true,
        },
      ];
    },
    dealerAdditionalConditions() {
      if (!this.selection.lenders) {
        return [];
      }
      if (this.selection.lenders.length === 0) {
        return [];
      }
      const ids = this.selection.lenders.map((dealer: Lender) => {
        return dealer.orgId;
      }) as string[];

      return [
        {
          fieldName: 'orgId',
          type: 'string',
          conditionComparatorOption: this.selection.lendersExclude ? 'not in' : 'in',
          value: ids,
        },
      ];
    },
    dateSummaryLabel() {
      let output = null;

      if (this.selection.date) {
        if (this.selection.date.length === 2) {
          const datesBetween = JSON.parse(JSON.stringify(this.selection.date)).sort();
          const datesDiffCalculation =
            moment(datesBetween[1], 'YYYY-MM-DD').diff(moment(datesBetween[0], 'YYYY-MM-DD'), 'days') + 1;
          output =
            moment(datesBetween[0], 'YYYY-MM-DD').format('MM/DD/YYYY') +
            ' - ' +
            moment(datesBetween[1], 'YYYY-MM-DD').format('MM/DD/YYYY') +
            ' (' +
            datesDiffCalculation +
            '  day' +
            (datesDiffCalculation === 1 ? '' : 's') +
            ')';
        }
        if (
          this.selection.date.length === 1 &&
          (this.selection.dateOption === 'BEFORE' || this.selection.dateOption === 'AFTER')
        ) {
          let datesBetween = JSON.parse(JSON.stringify(this.selection.date));
          datesBetween.push(
            moment(datesBetween[0], 'YYYY-MM-DD')
              .add(dateRangeLength * (this.selection.dateOption === 'BEFORE' ? -1 : 1), 'days')
              .format('YYYY-MM-DD'),
          );
          datesBetween = datesBetween.sort();

          output =
            moment(datesBetween[0], 'YYYY-MM-DD').format('MM/DD/YYYY') +
            ' - ' +
            moment(datesBetween[1], 'YYYY-MM-DD').format('MM/DD/YYYY') +
            ' (' +
            dateRangeLength +
            ' days)';
        }
        if (this.selection.date.length === 1 && this.selection.dateOption === 'EQUAL') {
          const datesBetween = JSON.parse(JSON.stringify(this.selection.date)).sort();
          output = moment(datesBetween[0], 'YYYY-MM-DD').format('MM/DD/YYYY') + ' (1 day)';
        }
        if (this.selection.date.length === 1 && this.selection.dateOption === 'NOT_EQUAL') {
          const datesbetween = [
            moment(this.selection.date[0], 'YYYY-MM-DD')
              .add(-15, 'days')
              .format('YYYY-MM-DD'),
            moment(this.selection.date[0], 'YYYY-MM-DD')
              .add(15, 'days')
              .format('YYYY-MM-DD'),
          ];
          output =
            moment(datesbetween[0], 'YYYY-MM-DD').format('MM/DD/YYYY') +
            ' - ' +
            moment(datesbetween[1], 'YYYY-MM-DD').format('MM/DD/YYYY') +
            ' (' +
            (dateRangeLength - 1) +
            ' days) ' +
            moment(this.selection.date[0], 'YYYY-MM-DD').format('MM/DD/YYYY') +
            ' Excluded';
        }
      }

      return output;
    },
    zipByOptionsComputed() {
      return this.zipByOptions.filter((option: { value: string; disabled: boolean }) => {
        if (this.selection.zipBy.indexOf(option.value) > -1) {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
        return option;
      });
    },
    computedDateOptions() {
      return this.dateOptions.map(dateOption => {
        if (!this.selection.date || this.selection.date.length === 0) {
          dateOption.disabled = true;
          return dateOption;
        }
        if (this.selection.date.length === 1) {
          if (dateOption.value === 'BETWEEN') {
            dateOption.disabled = true;
            return dateOption;
          }
        }
        if (this.selection.date.length === 2 && this.selection.date[0] === this.selection.date[1]) {
          if (dateOption.value === 'BETWEEN') {
            dateOption.disabled = true;
            return dateOption;
          }
        }
        if (this.selection.date.length === 2 && this.selection.date[0] !== this.selection.date[1]) {
          if (dateOption.value !== 'BETWEEN') {
            dateOption.disabled = true;
            return dateOption;
          }
        }
        dateOption.disabled = false;
        return dateOption;
      });
    },
    calculateMinDate() {
      if ((this.selection.date || []).length === 0) {
        return null;
      } else if (this.selection.date) {
        const newDate = moment(this.selection.date[0], 'YYYY-MM-DD')
          .add(-dateRangeLength, 'days')
          .format('YYYY-MM-DD');
        return newDate;
      }
      return null;
    },
    calculateMaxDate() {
      if ((this.selection.date || []).length === 0) {
        return null;
      } else if (this.selection.date) {
        const newDate = moment(this.selection.date[0], 'YYYY-MM-DD')
          .add(dateRangeLength, 'days')
          .format('YYYY-MM-DD');
        return newDate;
      }
      return null;
    },
  },
  watch: {
    'selection.date': {
      handler(newValue) {
        if (newValue) {
          if (newValue.length === 2 && newValue[0] !== newValue[1]) {
            this.selection.dateOption = 'BETWEEN';
          }
          if (newValue.length === 2 && newValue[0] === newValue[1]) {
            this.selection.dateOption = 'EQUAL';
          }
          if (newValue.length === 1) {
            this.selection.dateOption = 'EQUAL';
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    executeJob(): boolean {
      // reset error box on send request
      this.error().clear();
      this.message().clear();

      if (!this.getRemoteComponent('ExecuteDialogForm').validate()) {
        this.error().add('Please setup Zip By configuration');
        return false;
      }

      let url = apiTransmissionExportUrl;

      axiosInstance
        .post<any, AxiosResponse<{ success: boolean; errors: string[]; messages: string[] }>>(
          url,
          this.getRequestBody(),
        )
        .then(result => {
          if (result.data.success) {
            this.message().set(result.data.messages);
            if (result.data.errors?.length > 0) {
              this.error().set(result.data.errors);
            }
          } else {
            this.error().set(result.data.errors);
          }
          this.processing = false;
          setTimeout(() => {
            this.getRemoteComponent('ExecuteDialog').open = false;
          }, 600);
        })
        .catch((error: string) => {
          this.error().add(error);
          this.processing = false;
        })
        .finally(() => {
          this.reRenderGrid++;
        });
      return true;
    },
    prepareDateForRequest() {
      let dateArray = this.selection.date;
      let dateOperator = this.selection.dateOption;

      if (dateArray && dateArray?.length > 0 && dateArray?.length === 1) {
        dateArray = [dateArray[0], dateArray[0]];
        dateOperator = 'BETWEEN';
      }

      return {
        operator: dateOperator,
        arguments: dateArray,
      };
    },
    getRequestBody(): TransmissionExportJobRequestI {
      const requestObject = {
        dealer: {
          operator: this.selection.dealersExclude ? 'NOT_IN_LIST' : 'IN_LIST',
          ids: this.selection.dealers.map((dealer: Dealer) => {
            return dealer.id;
          }),
        },
        provider: {
          operator: this.selection.providersExclude ? 'NOT_IN_LIST' : 'IN_LIST',
          ids: this.selection.providers.map((provider: Provider) => {
            return provider.id;
          }),
        },
        lender: {
          operator: this.selection.lendersExclude ? 'NOT_IN_LIST' : 'IN_LIST',
          ids: this.selection.lenders.map((lender: Lender) => {
            return lender.orgId;
          }),
        },
        zipBy: this.selection.zipBy,
        date: this.prepareDateForRequest(),
        recipient: this.selection.recipient,
      } as TransmissionExportJobRequestI;

      if (requestObject.dealer?.ids.length === 0) {
        delete requestObject.dealer;
      }
      if (requestObject.provider?.ids.length === 0) {
        delete requestObject.provider;
      }
      if (requestObject.lender?.ids.length === 0) {
        delete requestObject.lender;
      }

      return requestObject;
    },
    uniq(array: string[]): string[] {
      return array.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    },
    openExecuteDialog() {
      this.error().clear();
      this.message().clear();
      if (this.getRemoteComponent('ExportDocumentForm').validate()) {
        this.getRemoteComponent('ExecuteDialog').open = true;
      }
    },
    dealerDisplay(lenders: { name: string }[]) {
      let output = '' as String | null;
      output =
        lenders
          .slice(0, 3)
          .map(item => {
            return item.name;
          })
          .join(', ') + (lenders.length > 1 ? '...' : '');

      return output + ' (selected: ' + (lenders.length === 0 ? 'none' : lenders.length) + ')';
    },
    lenderDisplay(lenders: { lenderName: string }[]) {
      let output = '' as String | null;
      output =
        lenders
          .slice(0, 3)
          .map(item => {
            return item.lenderName;
          })
          .join(', ') + (lenders.length > 3 ? '...' : '');

      return output + ' (selected: ' + (lenders.length === 0 ? 'none' : lenders.length) + ')';
    },
    providerDisplay(lenders: { name: string }[]) {
      let output = '' as String | null;
      output =
        lenders
          .slice(0, 3)
          .map(item => {
            return item.name;
          })
          .join(', ') + (lenders.length > 3 ? '...' : '');

      return output + ' (selected: ' + (lenders.length === 0 ? 'none' : lenders.length) + ')';
    },
    selectLenders(lenders: Lender[]) {
      this.selection.lenders = lenders;
    },
    selectDealers(dealers: Dealer[]) {
      this.selection.dealers = dealers;
    },
    selectProviders(providers: Provider[]) {
      this.selection.providers = providers;
    },
    async downloadFile(id: string): Promise<void> {
      const url = apiDownloadJobUrl.replace('{id}', id);
      return axiosInstance
        .get<Blob>(url, {
          responseType: 'blob',
          onDownloadProgress: progressEvent => {
            this.snackBarProgress = true;
            this.fileDownloadProgress = Math.floor((progressEvent.loaded / (progressEvent.total || 1)) * 100);
          },
        })
        .then(response => {
          if (response.data.size === 0) {
            this.snackBarError = true;
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'archive.zip');
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
            window.URL.revokeObjectURL(fileURL);
            this.fileDownloadProgress = 0;
            this.snackBarProgress = false;
          }
        })
        .catch(() => {
          this.snackBarError = true;
          this.snackBarProgress = false;
          this.fileDownloadProgress = 0;
        });
    },
    refreshForm() {
      this.getRemoteComponent('dataGrid').submitDataRequest();
    },
    clearForm() {
      this.selection = {
        lendersExclude: false as boolean,
        lenders: [] as Lender[],
        dealersExclude: false as boolean,
        dealers: [] as Dealer[],
        providersExclude: false as boolean,
        providers: [] as Provider[],
        dateOption: null,
        zipBy: [],
        date: null,
        recipient: 'BOTH',
      };
      this.selection.date = null;
      this.getRemoteComponent('LendersSelectionField').selectedItems = [];
      this.getRemoteComponent('DealersSelectionField').selectedItems = [];
      this.getRemoteComponent('ProvidersSelectionField').selectedItems = [];
    },
    getFixedConditions(): [] {
      return [];
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    error() {
      return {
        set: (errors: string[]) => {
          this.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      return {
        set: (errors: string[]) => {
          this.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.messages.push(error);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
  },
});
