






import { Vue } from 'vue-property-decorator';
import { Product } from '../interfaces';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import axios from '../services/api/Api.service';

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

const recordUpdateEndpointUrl = '/uiapi/product/{id}';

export default Vue.extend({
  name: 'RemoveProductAction',
  components: { ConfirmationDialog },
  data: () => ({
    submitButtonDisabled: false as boolean,
    rowData: {} as Product,
    openDialog: false,
    confirmationDialog: {
      processing: false,
      ref: 'ConfirmationDialog',
      title: 'Delete Product' as string,
      message: '' as string,
      errors: [] as string[],
      messages: [] as string[],
    },
  }),
  methods: {
    setSubmitButtonDisabled(option: boolean) {
      this.submitButtonDisabled = option;
    },
    submitDeleteDataRequest() {
      // reset error box on send request
      this.error().clear();
      this.message().clear();
      this.confirmationDialog.processing = true;
      let url = '';
      const requestObject = Object.assign({}, this.rowData as Product) as Product;
      if (requestObject.id) {
        url = recordUpdateEndpointUrl.replace('{id}', requestObject.id.toString());
        axiosInstance
          .delete(url)
          .then((result: { data: { success: boolean; errors: string[]; messages: string[] } }) => {
            if (result.data.success) {
              this.message().set(result.data.messages);
              this.$emit('success');
              setTimeout(() => {
                this.openDialog = false;
                this.confirmationDialog.processing = false;
              }, 2000);
            } else {
              this.confirmationDialog.processing = false;
              this.$emit('error');
              this.error().set(result.data.errors);
            }
          })
          .catch((error: string) => {
            this.confirmationDialog.processing = false;
            this.error().add(error);
          });
      }
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    open(rowData: Product): void {
      this.rowData = rowData;
      this.rowData.productType = rowData.productType || '';
      this.confirmationDialog.processing = false;
      this.error().clear();
      this.message().clear();
      this.setSubmitButtonDisabled(false);
      this.confirmationDialog.message = '';

      if (rowData.lastDealerTransmissionDate || rowData.lastProviderTransmissionDate) {
        this.error().add('Product was sent to Dealer or Provider. It is not allowed to delete this record.');
        this.setSubmitButtonDisabled(true);
        this.confirmationDialog.message =
          'Product: <b>' + this.rowData.productType + '  (Id: ' + this.rowData.id + ')</b>';
      } else {
        this.confirmationDialog.message =
          'Are you sure you want to delete product </br> <b>' +
          this.rowData.productType +
          ' Product Id: ' +
          this.rowData.id +
          '</b>?';
      }

      this.openDialog = true;

      setTimeout(() => {
        this.getRemoteComponent('ConfirmationDialog').open = true;
      }, 100);
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.confirmationDialog.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.confirmationDialog.errors.push(error);
          return this;
        },
        clear: () => {
          this.confirmationDialog.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.confirmationDialog.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.confirmationDialog.messages.push(error);
          return this;
        },
        clear: () => {
          this.confirmationDialog.messages.length = 0;
          return this;
        },
      };
    },
  },
  computed: {
    buttons() {
      return [
        {
          name: 'Delete',
          color: 'error',
          disabled: this.submitButtonDisabled,
          callback: () => {
            this.submitDeleteDataRequest();
            this.$emit('completed');
          },
        },
      ];
    },
  },
});
