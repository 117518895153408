






































































































































































































































































































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import axios from '../services/api/Api.service';
import FormatDisplayService from '../services/FormatDisplay.service';
import moment from 'moment';
import LocalStorageService from '../services/LocalStorage.service';
import ValidationService from '../services/Validations.service';
import { AxiosResponse } from 'axios';
import { SerchResponse } from '../../../src/domain/grid/interfaces';
import {
  SearchRequestBodyI,
  SearchConditionI,
  SubFilterConditionI,
  FilterSetI,
  SubFilterSetI,
  DataGridMetaDataI,
  DataTableHeaderI,
  ActionI,
  ButtonActionI,
} from '../interfaces';

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

interface DataGridI {
  queryLogicExpanded: boolean;
  queueConditionsExpanded: boolean;
  viewAllDialog: boolean;
  queryLogicValid: boolean;
  validationService: {};
  lastApiRequestBodyStore: {} | null;
  lastApiRequestBody: {} | null;
  filterFromStorage: boolean;
  diableAutoDataLoad: boolean;
  filterVisible: boolean;
  processing: boolean;
  errors: string[];
  selectedRows: [];
  selectedAction: ButtonActionI | null;
  selection: {
    filterSet: FilterSetI | null;
    subFilters: SubFilterSetI[];
    subFilter: SubFilterSetI | null;
    queryLogic: string | null;
  };
  gridData: {
    data: [];
    metaData: DataGridMetaDataI;
  };
  dataTable: {
    items: [];
    itemKey: string | null;
    serverItemsLength: number | null;
    height: null | number;
    headers?: DataTableHeaderI[];
    loading: boolean;
    itemsPerPage: number;
    footerProps: {
      itemsPerPageOptions: number[];
    };
    options: {
      page: number;
      itemsPerPage: number;
      sortBy: string[];
      sortDesc: boolean[];
      groupBy: string[];
      groupDesc: boolean[];
      multiSort: boolean;
      mustSort: boolean;
    };
    showSelect: boolean;
    singleSelect: boolean;
    fixedHeader: boolean;
    dense: boolean;
    calculateWidths?: boolean;
    value: [];
  };
}

export default Vue.extend({
  name: 'Datagrid',
  components: {},
  data: (): DataGridI =>
    ({
      queryLogicExpanded: false,
      queueConditionsExpanded: false,
      viewAllDialog: false,
      queryLogicValid: true,
      validationService: ValidationService,
      lastApiRequestBodyStore: {},
      lastApiRequestBody: null,
      filterFromStorage: false,
      diableAutoDataLoad: false,
      filterVisible: false,
      processing: false,
      errors: ['some error'],
      selectedRows: [],
      selectedAction: null,
      selection: {
        filterSet: null,
        subFilters: [],
        subFilter: null,
        queryLogic: null,
      },
      gridData: {
        data: [],
        metaData: {} as DataGridMetaDataI,
      },
      dataTable: {
        items: [],
        itemKey: null,
        serverItemsLength: 0,
        height: null,
        loading: false,
        itemsPerPage: 50,
        footerProps: {
          itemsPerPageOptions: [],
        },
        options: {
          page: 1,
          itemsPerPage: 50,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: false,
        },
        showSelect: false,
        singleSelect: false,
        fixedHeader: true,
        dense: true,
        value: [],
      },
    } as DataGridI),
  props: {
    gridId: {
      type: String,
      default: '', //default v-data-table footer height
    },
    footerHeight: {
      type: Number,
      default: 73, //default v-data-table footer height
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    forceTableHeight: {
      type: Number,
      default: 0, //default v-data-table footer height
    },
    apiDataUrl: {
      type: String,
      default: 'emptyDataUrl', // url to data endpoint for current grid
    },
    apiMetadataUrl: {
      type: String,
      default: 'emptyMetaDataUrl', // url to metadata endpoint for current grid
    },
    headerColumnsShow: {
      type: Array,
      default: () => {
        return []; // Definition of header columns ( if not set all columns from incoming data will be visible )
      },
    },
    previews: {
      type: Object,
      default: () => {
        return null;
      },
    },
    actions: {
      type: Array,
      default: () => {
        return []; // Array with Acions which will be added to grid as additional Action Column
      },
    },
    buttonActions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    buttonActionsHide: {
      type: Boolean,
      default: false,
    },
    actionsToLastColumn: {
      type: Boolean,
      default: false,
    },
    listViewHide: {
      type: Boolean,
      default: false,
    },
    listViewHideIfOne: {
      type: Boolean,
      default: false,
    },
    filterButtonHide: {
      type: Boolean,
      default: false,
    },
    dataIdField: {
      type: String,
      default: 'id', // filed name in column which identify unique id for data row
    },
    hideFilterSetup: {
      type: Boolean,
      default: false, // that will hide filter setup
    },
    fixedConditions: {
      type: Array,
      default: () => {
        return []; // Additional conditions added to all grid request from parent component or view
      },
    },
    fixedConditionsPreventCall: {
      type: Boolean,
      default: false,
    },
    initialSortOrder: {
      type: Array,
      default: () => {
        return []; // initial sorting for loadData
      },
    },
    itemsPerPageInitial: {
      type: Number,
      default: 50, //  Default ( initial ) setup for visible rows count
    },
    itemsPerPage: {
      type: Array,
      default: () => {
        return [10, 50, 100]; // rows per page array definition
      },
    },
    viewAll: {
      type: Boolean,
      default: false,
    },
    headersAutoParserMapping: {
      type: Boolean,
      default: false, // Experimental
    },
    showSelection: {
      type: Boolean,
      default: false, // Enable/Disable row selection feature
    },
    singleSelect: {
      type: Boolean,
      default: false, // Enable/Disable single row selection mode
    },
    // Row Click Function
    rowClickEvent: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      default: (rowData: {}, cell: {}) => {
        // console.log('rowclick', rowData, cell);
      },
    },
    // Cell Click Function
    cellClickEvent: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      default: (header: {}, item: {}) => {
        // console.log('cellclick', header, item);
      },
    },
    defaultQueue: {
      type: String,
      default: '', // Enable/Disable single row selection mode
    },
    showFilterSets: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.initGridDataLoading();
    this.updateDataTable();
    setTimeout(() => {
      this.onResize();
    }, 100);
    setTimeout(() => {
      this.onResize();
    }, 300);
  },
  watch: {
    $props: {
      handler() {
        setTimeout(() => {
          this.onResize();
        }, 100);
      },
      deep: true,
    },
    diableAutoDataLoad: {
      handler(newValue: boolean) {
        if (newValue) {
          setTimeout(() => {
            this.diableAutoDataLoad = false;
          }, 2000);
        }
      },
    },
    /**
     * Watch changes in grid options object to call api based on altered sort criteria
     */
    'dataTable.options': {
      async handler() {
        if (this.diableAutoDataLoad === false) {
          this.diableAutoDataLoad = true;
          await this.loadData();
          this.diableAutoDataLoad = false;
        }
      },
      deep: true,
    },
    /**
     *  Emit Event on row selection
     */
    selectedRows: {
      handler() {
        this.$emit('row-selection', this.selectedRows);
      },
      deep: true,
    },
    fixedConditions: {
      handler(oldValue, newValue) {
        // Reload if old and new values are different
        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
          this.loadData(true);
        }
      },
      deep: false,
    },
  },
  filters: {
    filterActions(actionItems: ActionI[]) {
      return actionItems.filter((actionItem: ActionI) => {
        return actionItem.show === undefined || actionItem.show === true ? true : false;
      });
    },
    actionDisabled(action: ActionI, item: {}): boolean {
      if (action.disabled === true) {
        return true;
      }
      if (!action.disabledCondition) {
        return false;
      } else {
        return action.disabledCondition(item);
      }
    },
    actionTooltip(action: ActionI, item: {}): string | undefined {
      if (!action.disabledCondition) {
        return action.tooltip;
      } else {
        return action.disabledCondition(item) ? action.tooltipDisabled : action.tooltip;
      }
    },
  },
  computed: {
    updateRefresh(): boolean {
      if (JSON.stringify(this.lastApiRequestBodyStore) !== JSON.stringify(this.getApiCallBody())) {
        return true;
      }
      return false;
    },
    getHeaders(): DataTableHeaderI[] {
      let headersToShow = [] as DataTableHeaderI[];
      if (this.headerColumnsShow.length > 0) {
        // @ts-ignore: Unreachable code error
        headersToShow = this.headerColumnsShow.map((column: DataTableHeaderI) => {
          if (column.parse && typeof column.parse === 'string') {
            // @ts-ignore: Unreachable code error
            column.parse = FormatDisplayService.getRender(column.parse);
          }
          return column;
        }) as DataTableHeaderI[];
      } else {
        if (this.gridData.metaData.columns) {
          if (this.selection.filterSet !== null) {
            this.selection.filterSet.columns.forEach((column: string) => {
              // @ts-ignore: Unreachable code error
              this.gridData.metaData.columns.forEach((header: DataTableHeaderI) => {
                // @ts-ignore: Unreachable code error
                if (header.value === column) {
                  headersToShow.push(header);
                }
              });
            });
          }
        }
      }

      if (this.headersAutoParserMapping) {
        //Add Mapping form subFilterSet (in progress not well tesetd )
        headersToShow.map((header: DataTableHeaderI) => {
          if (!this.gridData.metaData.subFilterSets) {
            return header;
          }
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          this.gridData.metaData.subFilterSets.forEach((subFilterSet, subFilterSetIndex) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            subFilterSet.conditions.forEach((condition: SubFilterConditionI, conditionIndex) => {
              if (['Boolean', 'Checkbox'].indexOf(condition.fieldType) > -1 && condition.fieldName === header.value) {
                // console.log('condition', condition, condition.fieldType, condition.fieldName);
                // @ts-ignore: Unreachable code error
                header.parse = FormatDisplayService.getRender('toYesNo');
              }
              // @ts-ignore: Unreachable code error
              if (
                ['Enum'].indexOf(condition.fieldType) > -1 &&
                condition.fieldName === header.value &&
                condition.fieldOptions &&
                condition.fieldOptions[0] &&
                // @ts-ignore: Unreachable code error
                condition.fieldOptions[0].value !== undefined
              ) {
                // console.log('condition', condition, condition.fieldType, condition.fieldName);
                header.parse = function(v: string | null | boolean): string {
                  let output: string | boolean = '';
                  if (condition.fieldOptions && condition.fieldOptions.length > 0) {
                    output = v ? v : '';
                    condition.fieldOptions.forEach(fieldOption => {
                      if (typeof fieldOption !== 'string') {
                        // console.log(v, fieldOption);
                        // @ts-ignore: Unreachable code error

                        if (fieldOption.value == v) {
                          // @ts-ignore: Unreachable code error
                          output = typeof fieldOption.text === 'string' ? fieldOption.text : v;
                        }
                      }
                    });
                  }
                  // @ts-ignore: Unreachable code error
                  return output;
                };
              }
            });
          });
          return header;
        });
      }
      if (this.previews !== null) {
        if (this.selection && this.selection.filterSet && !this.selection.filterSet.hideDocsColumn) {
          headersToShow = headersToShow.filter(header => {
            return header.text !== 'Previews';
          });

          headersToShow.unshift({ text: this.previews.columnName, value: 'Previews', sortable: false });
        }
      }
      if (this.actions.length > 0) {
        headersToShow = headersToShow.filter(header => {
          return header.text !== 'Actions';
        });
        if (this.actionsToLastColumn) {
          headersToShow.push({ text: 'Actions', value: 'Actions', sortable: false });
        } else {
          headersToShow.unshift({ text: 'Actions', value: 'Actions', sortable: false });
        }
      }
      return headersToShow;
    },
  },
  methods: {
    calculateDays(condition: any) {
      const days = moment(condition.valueEnd).diff(moment(condition.value), 'days') + 1;
      return 'Date Range: ' + days + ' Day' + (days === 1 ? '' : 's');
    },
    checkBetween(condition: any) {
      if (moment(condition.value) > moment(condition.valueEnd)) {
        return 'Start Date is after End Date';
      }
      return true;
    },
    fixCamelCaseToWords(input: string) {
      const spacedString = input.replace(/\./, ' ').replace(/([a-z])([A-Z])/g, '$1 $2');

      // Split the spaced string into words, capitalize the first letter of each word,
      // and then join them back together.
      const words = spacedString.split(' ');
      const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      return capitalizedWords.join(' ');
    },
    checkIfPassable(conditionComparatorOption: string, value: string | null | undefined) {
      return ['in', 'not in'].indexOf(conditionComparatorOption) > -1 && (value || '').toString().split(',').length > 1;
    },
    decimalOnly(e: any) {
      const key = e.key;
      if (
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'ArrowLeft', 'ArrowRight'].indexOf(key) ===
        -1
      ) {
        e.preventDefault();
      }
    },
    listViewChange() {
      // update sort from list view definitions after select
      if (this.selection.filterSet && this.selection.filterSet.sort) {
        this.diableAutoDataLoad = true;
        this.dataTable.options.sortBy = [];
        this.dataTable.options.sortDesc = [];
        if (this.selection.filterSet.sort.length > 0) {
          this.selection.filterSet.sort.forEach(sort => {
            this.dataTable.options.sortBy.push(sort.fieldName);
            this.dataTable.options.sortDesc.push(sort.desc ? true : false);
          });
        }
        this.diableAutoDataLoad = false;
      }

      this.loadData(true);
      this.storeCurrentGridState();
    },
    updateDataTable() {
      this.dataTable.options.itemsPerPage = this.itemsPerPageInitial;
      this.dataTable.footerProps.itemsPerPageOptions = this.itemsPerPage as number[];
      this.dataTable.singleSelect = this.singleSelect;
      this.dataTable.showSelect = this.showSelection;
      this.dataTable.itemKey = this.dataIdField;
    },
    submitDataRequest() {
      this.$emit('onDataGridLoad');
      this.external().refreshGrid(true);
    },
    buttonActionExecute(selectedAction?: ButtonActionI) {
      if (selectedAction) {
        this.selectedAction = selectedAction;
      }
      if (this.selectedAction) {
        this.selectedAction.callback(this.selectedRows);
      }
      setTimeout(() => {
        this.selectedAction = null;
      }, 500);
    },
    external() {
      const scope = this;
      return {
        getSelectedRows(): [] {
          return scope.selectedRows;
        },
        refreshGrid(resetPage: boolean) {
          scope.loadData(resetPage);
          return scope.gridData.data;
        },
        resetGridData() {
          scope.gridData.data = [];
          scope.dataTable.items = [];
        },
      };
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    /**
     * Return object for query api service based on current filters and subFilters selection
     */
    getApiCallBody(): SearchRequestBodyI | null {
      if (!this.selection.filterSet) {
        return null;
      }
      return {
        filterCode: this.selection.filterSet.filterCode,
        page: this.dataTable.options.page,
        rowsPerPage: this.dataTable.options.itemsPerPage,
        conditions: (() => {
          const allConditions = [] as SearchConditionI[];
          if (this.fixedConditions.length > 0) {
            this.fixedConditions.forEach(condition => {
              allConditions.push({
                // @ts-ignore: Unreachable code error
                fieldName: condition.fieldName,
                // @ts-ignore: Unreachable code error
                type: condition.fieldType,
                // @ts-ignore: Unreachable code error
                comparatorOption: condition.conditionComparatorOption,
                // @ts-ignore: Unreachable code error
                value: condition.value,
                fixed: true,
              });
            });
          }
          this.selection.subFilters.forEach(subFilter => {
            subFilter.conditions.forEach((condition: SubFilterConditionI) => {
              let value = this.extractValue(condition.value);
              if (condition.fieldType === 'Checkbox' || condition.fieldType === 'Boolean') {
                // @ts-ignore: Unreachable code error
                value = value === 'true';
              }
              if (condition.fieldType === 'Integer' || condition.fieldType === 'Float') {
                // @ts-ignore: Unreachable code error
                value = value === '' || value === null ? '0' : value;
              }

              if (this.extractValue(condition.conditionComparatorOption) === 'between') {
                allConditions.push({
                  fieldName: condition.fieldName,
                  type: condition.fieldType,
                  comparatorOption: this.extractValue(condition.conditionComparatorOption),
                  value: [value as string, condition.valueEnd as string],
                  fixed: false,
                });
              } else {
                allConditions.push({
                  fieldName: condition.fieldName,
                  type: condition.fieldType,
                  comparatorOption: this.extractValue(condition.conditionComparatorOption),
                  value: value,
                  fixed: false,
                });
              }
            });
          });
          return allConditions;
        })(),
        sort: this.dataTable.options.sortBy.map((sort, sortIndex) => {
          return {
            fieldName: sort,
            desc: this.dataTable.options.sortDesc[sortIndex],
          };
        }),
        queryLogic: this.selection.queryLogic,
      };
    },
    extractValue(
      itemObject:
        | string
        | boolean
        | {
            text: string;
            value: string | boolean | null;
          }
        | null
        | undefined
        | string[],
    ): string | string[] {
      if (Array.isArray(itemObject)) {
        return itemObject;
      }

      if (typeof itemObject === 'string') {
        return itemObject;
      }
      if (typeof itemObject === 'object' && itemObject !== null && itemObject !== undefined && itemObject.value) {
        return typeof itemObject.value === 'boolean' ? (itemObject.value ? 'true' : 'false') : itemObject.value;
      }
      if (typeof itemObject === 'boolean') {
        return itemObject ? 'true' : 'false';
      }
      if (itemObject === undefined) {
        return itemObject ? 'true' : 'false';
      }
      return '';
    },
    getHeaderText(fieldName: string): string {
      let fieldText = '';
      if (this.gridData.metaData.columns) {
        this.gridData.metaData.columns.forEach((column: DataTableHeaderI) => {
          if (column.value === fieldName) {
            fieldText = column.text;
          }
        });
      }
      return fieldText;
    },
    getHeader(fieldName: string): DataTableHeaderI | null {
      let headerObj = null;
      if (this.gridData.metaData.columns) {
        this.gridData.metaData.columns.forEach((column: DataTableHeaderI) => {
          if (column.value === fieldName) {
            headerObj = column;
          }
        });
      }

      return headerObj;
    },
    resolveDynamicValue(value: string) {
      if (value === 'CURRENT_USER') {
        return 'Current User';
      }
      return value;
    },
    removeSubFilter() {
      this.getRemoteComponent('QueryLogicTextArea')?.resetValidation();
      setTimeout(() => {
        this.getRemoteComponent('QueryLogicTextArea')?.validate();
      }, 250);
    },
    addSubFilter() {
      if (this.selection.subFilter) {
        const subFilter = JSON.parse(JSON.stringify(this.selection.subFilter));
        subFilter.conditions = this.prepareCondition(subFilter.conditions);
        this.selection.subFilters.push(subFilter as SubFilterSetI);
        this.selection.subFilter = null;
        this.getRemoteComponent('QueryLogicTextArea')?.resetValidation();
        setTimeout(() => {
          this.getRemoteComponent('QueryLogicTextArea')?.validate();
        }, 250);
      }
    },
    prepareCondition(conditions: SubFilterConditionI[]): SubFilterConditionI[] {
      conditions.forEach((condition: SubFilterConditionI) => {
        condition.value = null;
        if (condition.conditionComparatorOptions) {
          condition.conditionComparatorOption = condition.conditionComparatorOptions[0];
        } else {
          condition.conditionComparatorOption = 'equal';
        }
        // @ts-ignore: Unreachable code error
        if ((condition.conditionComparatorOptions || []).includes('contain')) {
          condition.conditionComparatorOption = 'contain';
        }

        if (condition.fieldType === 'Enum') {
          if (condition.fieldOptions) {
            condition.value = condition.fieldOptions[0];
          }
        }
        if (['Checkbox', 'Boolean'].indexOf(condition.fieldType) > -1) {
          condition.fieldOptions = [
            {
              text: 'Yes',
              value: true,
            },
            {
              text: 'No',
              value: false,
            },
          ];
          condition.value = this.extractValue(condition.fieldOptions[0]);
        }
        if (condition.defaultValue) {
          condition.value = condition.defaultValue;
        }
        if (condition.fieldType === 'Date') {
          condition.dateMenu = false;
          condition.value = '';

          if (!(typeof condition.defaultValue === 'string' && condition.defaultValue !== '')) {
            condition.value = moment().format('YYYY-MM-DD');
            condition.valueEnd = moment().format('YYYY-MM-DD');
          }
        }
      });
      return conditions;
    },
    /**
     * Calculate size of footer position based on parent element size
     */
    onResize() {
      if (this.forceTableHeight > 0) {
        this.dataTable.height = this.forceTableHeight;
      } else {
        this.dataTable.height =
          window.innerHeight - this.getRemoteComponent('resizableDiv')?.getBoundingClientRect().y - this.footerHeight;
      }
    },
    // Return Access to ProviderAliasEditGrid Component ( all properties, all methods )
    // `ref="refComponentName"` need tobe added to related component!
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    initGridDataLoading() {
      this.error().clear();
      this.loadMetaData().then(() => {
        this.loadData();
      });
    },
    /**
     * Function is loading data into grid store
     */
    async loadMetaData() {
      this.processing = true;
      this.error().clear();
      return axiosInstance
        .get<any, AxiosResponse<DataGridMetaDataI>>(this.apiMetadataUrl, {})
        .then(res => {
          if (typeof res.data === 'string') {
            throw new Error('Missing Metadata object');
          }
          if (!res.data.filterSets) {
            throw new Error('Missing Queue definition');
          }
          if (!res.data.subFilterSets) {
            throw new Error('Missing subFilterSets definition');
          }
          if (!res.data.columns) {
            // throw new Error('Missinng Columns definition');
          }

          this.gridData.metaData = res.data;

          try {
            this.gridData.metaData.subFilterSets = this.gridData.metaData.subFilterSets.sort((a, b) => {
              if (a.subFilterName < b.subFilterName) {
                return -1;
              }
              if (a.subFilterName > b.subFilterName) {
                return 1;
              }
              return 0;
            });
          } catch (e) {
            console.log('Issue Found with sorting filters');
          }

          this.$emit('onMetaDataLoaded', this.gridData.metaData.subFilterSets);
          this.$emit('on-meta-data-loaded', this.gridData.metaData.subFilterSets);
          // adding function instead parse string
          this.gridData.metaData = this.addColumnParsers(this.gridData.metaData) as DataGridMetaDataI;
          // Select first filterSet after metadata load
          if (this.gridData.metaData.filterSets.length > 0) {
            this.selection.filterSet = this.gridData.metaData.filterSets[0];
            if (this.defaultQueue !== '') {
              this.gridData.metaData.filterSets.forEach(filterSet => {
                if (filterSet.filterCode === this.defaultQueue) {
                  this.selection.filterSet = filterSet;
                }
              });
            }
            if (this.selection.filterSet && this.selection.filterSet.sort) {
              this.diableAutoDataLoad = true;
              if (this.selection.filterSet.sort.length > 0) {
                this.selection.filterSet.sort.forEach(sort => {
                  this.dataTable.options.sortBy.push(sort.fieldName);
                  this.dataTable.options.sortDesc.push(sort.desc ? true : false);
                });
              }
              this.diableAutoDataLoad = false;
            }
          }
          // force from parent sort setup
          if (this.initialSortOrder.length > 0) {
            this.dataTable.options.sortBy = [];
            this.dataTable.options.sortDesc = [];
            for (let sort of this.initialSortOrder as Array<{ fieldName: string; desc: boolean }>) {
              this.dataTable.options.sortBy.push(sort.fieldName);
              this.dataTable.options.sortDesc.push(sort.desc);
            }
          }

          // override with stored values if exists
          const storedSettings = LocalStorageService.get(this.gridId !== '' ? this.gridId : this.apiMetadataUrl, false);
          if (storedSettings) {
            this.diableAutoDataLoad = true;
            this.selection = storedSettings.selection;
            this.dataTable.options.sortBy = storedSettings.options.sortBy;
            this.dataTable.options.sortDesc = storedSettings.options.sortDesc;
            this.diableAutoDataLoad = false;
          }

          this.processing = false;
          return res;
        })
        .catch(error => {
          this.error().add(error + ' (Table Definition)');
        });
    },
    refreshData() {
      if (!this.queryLogicValid && this.selection.queryLogic !== '' && this.selection.queryLogic !== null) {
        setTimeout(() => {
          this.getRemoteComponent('QueryLogicTextArea').resetValidation();
        }, 100);
        setTimeout(() => {
          this.getRemoteComponent('QueryLogicTextArea').validate();
        }, 250);
        return false;
      }
      this.loadData(true);
      // safe current filter state during refresh data ( from refresh/submit buttton )
      this.storeCurrentGridState();
    },
    getClickedHeaderObject() {
      if (this.dataTable && this.dataTable.options && this.dataTable.options.sortBy) {
        return this.dataTable.options.sortBy.length ? this.getHeader(this.dataTable.options.sortBy[0]) : null;
      }
      return null;
    },
    storeCurrentGridState() {
      LocalStorageService.set(
        this.gridId !== '' ? this.gridId : this.apiMetadataUrl,
        { selection: this.selection, options: this.dataTable.options },
        false,
      );
    },
    overrideSortParams: function(requestBody: SearchRequestBodyI | null) {
      const headerClicked = this.getClickedHeaderObject();

      if (
        requestBody &&
        requestBody.sort &&
        requestBody.sort.length &&
        headerClicked &&
        headerClicked?.concatenatedDbColumns
      ) {
        const desc = requestBody.sort[0].desc;
        requestBody.sort = [];
        headerClicked.concatenatedDbColumns.forEach(dbColumnName => {
          requestBody.sort.push({
            desc: desc,
            fieldName: dbColumnName,
          });
        });
      }

      return requestBody;
    },
    async loadData(resetPage?: boolean) {
      this.error().clear();
      if (this.fixedConditionsPreventCall && this.fixedConditions.length === 0) {
        return false;
      }
      this.processing = true;
      let requestBody = this.getApiCallBody();
      if (resetPage) {
        this.dataTable.options.page = 1;
      }

      /**
       * Blocking cloned requests to API
       * Compare last query ( sent less thant 500 ms ago and block if new one is the same as last one )
       */
      if (JSON.stringify(requestBody) === JSON.stringify(this.lastApiRequestBody)) {
        this.processing = false;
        return null;
      }
      /**
       *  Store last query to server
       */
      this.lastApiRequestBody = requestBody;
      this.lastApiRequestBodyStore = this.lastApiRequestBody;
      /**
       * Clear last query data after 500 ms
       */
      setTimeout(() => {
        this.lastApiRequestBody = null;
      }, 500);

      requestBody = this.overrideSortParams(requestBody);

      if (requestBody) {
        return axiosInstance
          .post<any, AxiosResponse<SerchResponse<any>>>(this.apiDataUrl, requestBody, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(res => {
            if (typeof res.data === 'string') {
              throw new Error('Missing Metadata object');
            }
            if (!res.data.data) {
              throw new Error('Missing Data object');
            }
            if (!res.data.success) {
              this.queryLogicValid = false;
              this.dataTable.items = [];

              throw new Error(res.data.errors.join(','));
            }

            // Clear row selection after data load;
            this.selectedRows = [];
            this.dataTable.items = res.data.data;
            this.dataTable.serverItemsLength = res.data.totalRows;
            this.$emit('onGridItemsCount', this.dataTable.serverItemsLength);
            this.$emit('onListViewChange', this.selection.filterSet);
            this.$emit('onFilterRecords', this.dataTable.items);
            this.processing = false;
            return res;
          })
          .catch(error => {
            this.error().add(error + ' (Table Data)');
          });
      }
    },
    getHeaderFormatParser(value: string | string[], fieldName: string, fieldType: string, comparatorOption: string) {
      if (fieldType === 'DynamicValue' && typeof value === 'string') {
        return this.resolveDynamicValue(value);
      }
      if (comparatorOption === 'in' && typeof value === 'object') {
        try {
          return value.join(' OR ');
        } catch (e) {
          return value;
        }
      }
      if (comparatorOption === 'not in' && typeof value === 'object') {
        try {
          return value.join(', ');
        } catch (e) {
          return value;
        }
      }
      if (this.gridData.metaData.columns) {
        const headersFound = this.gridData.metaData.columns.filter(header => {
          return header.value === fieldName;
        });
        if (headersFound.length === 1) {
          if (typeof headersFound[0].parse === 'function') {
            return headersFound[0].parse(value as string);
          }
        }
        return value;
      }
    },
    addColumnParsers: (data: DataGridMetaDataI): DataGridMetaDataI => {
      if (data.columns) {
        data.columns = data.columns.map((column: DataTableHeaderI) => {
          if (column.parse && typeof column.parse === 'string') {
            column.parserCode = column.parse;
            // @ts-ignore: Unreachable code error
            column.parse = FormatDisplayService.getRender(column.parse);
          }
          return column;
        });
      }
      return data;
    },
    /**
     * Return Grid Array Index based on DataFieldID
     */
    getArrayIndexOfRow(cellValue: {}) {
      let rowIndex = null;
      this.dataTable.items.forEach((rowData, rowDataIndex) => {
        if (rowData[this.dataIdField] === cellValue) {
          rowIndex = rowDataIndex;
        }
      });
      return rowIndex;
    },
    printSelection() {
      // console.log(this.selection);
    },
    /**
     * Parse date from YYYY-MM-DD to MM/DD/YYYY
     */
    parseDate(dateString: string): string {
      if (typeof dateString === 'string') {
        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
      } else {
        return '';
      }
    },
    /**
     * Parse date from MM/DD/YYYY to  YYYY-MM-DD
     */
    reParseDate(dateString: string | boolean | undefined): string {
      if (typeof dateString === 'string') {
        const [month, day, year] = dateString.split('/');
        return `${year}-${month}-${day}`;
      } else {
        return '';
      }
    },
  },
});
