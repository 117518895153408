
























import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  name: 'CustomGeolocationField',
  components: {},
  data() {
    return {
      menu: false,
      content: this.value && typeof this.value === 'object' ? this.value : {},
      menuId: null as null | string,
    };
  },
  computed: {},
  created() {},
  watch: {
    '$props.value'(v) {
      this.content = v;
    },
  },
  methods: {
    makeid(length: number) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    handleInput() {
      this.$emit('input', this.content);
    },
  },
  props: [
    'value',
    'readonly',
    'disabled',
    'label',
    'rules',
    'placeholder',
    'disableclear',
    'prependIcon',
    'definition',
  ],
});
