import { render, staticRenderFns } from "./Product.vue?vue&type=template&id=35171425&scoped=true&"
import script from "./Product.vue?vue&type=script&lang=ts&"
export * from "./Product.vue?vue&type=script&lang=ts&"
import style0 from "./Product.vue?vue&type=style&index=0&id=35171425&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35171425",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VIcon,VTab,VTabItem,VTabs})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35171425')) {
      api.createRecord('35171425', component.options)
    } else {
      api.reload('35171425', component.options)
    }
    module.hot.accept("./Product.vue?vue&type=template&id=35171425&scoped=true&", function () {
      api.rerender('35171425', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Product.vue"
export default component.exports