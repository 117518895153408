






import { Vue } from 'vue-property-decorator';
import { ProductPayment } from '../interfaces';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import ProductPaymentService from './../services/api/ProductPayment.service';

export default Vue.extend({
  name: 'RemoveProductPaymentAction',
  components: { ConfirmationDialog },
  data: () => ({
    submitButtonDisabled: false as boolean,
    rowData: {} as ProductPayment,
    openDialog: false,
    confirmationDialog: {
      processing: false,
      ref: 'ConfirmationDialog',
      title: 'Delete Product Payment' as string,
      message: '' as string,
      errors: [] as string[],
      messages: [] as string[],
    },
  }),
  methods: {
    setSubmitButtonDisabled(option: boolean) {
      this.submitButtonDisabled = option;
    },
    submitDeleteDataRequest() {
      this.error().clear();
      this.message().clear();
      this.confirmationDialog.processing = true;
      ProductPaymentService.delete(this.rowData.id as number)
        .then(res => {
          if (res.success) {
            this.message().set(res.messages as string[]);
            this.$emit('success');
            setTimeout(() => {
              this.openDialog = false;
              this.confirmationDialog.processing = false;
            }, 2000);
          } else {
            this.confirmationDialog.processing = false;
            this.$emit('error');
            this.error().set(res.errors);
          }
        })
        .catch((error: string) => {
          this.confirmationDialog.processing = false;
          this.error().add(error);
        });
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    open(rowData: ProductPayment): void {
      this.rowData = rowData;
      this.confirmationDialog.processing = false;
      this.error().clear();
      this.message().clear();
      this.setSubmitButtonDisabled(false);

      this.confirmationDialog.message =
        'Are you sure you want to delete Product Payment<b> ' + this.rowData.id + '</b>?';
      this.openDialog = true;

      setTimeout(() => {
        this.getRemoteComponent('ConfirmationDialog').open = true;
      }, 100);
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.confirmationDialog.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.confirmationDialog.errors.push(error);
          return this;
        },
        clear: () => {
          this.confirmationDialog.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.confirmationDialog.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.confirmationDialog.messages.push(error);
          return this;
        },
        clear: () => {
          this.confirmationDialog.messages.length = 0;
          return this;
        },
      };
    },
  },
  computed: {
    buttons() {
      return [
        {
          name: 'Delete',
          color: 'error',
          disabled: this.submitButtonDisabled,
          callback: () => {
            this.submitDeleteDataRequest();
            this.$emit('completed');
          },
        },
      ];
    },
  },
});
