























































import { Vue } from 'vue-property-decorator';
import SfCustomDialog from '@/components/SFComponents/SfCustomDialog.vue';
import SfDataGrid from '@/components/SFComponents/SfDataGrid.vue';
import DaysToRefundEditForm from '@/components/SFComponents/LenderServiceConfiguration/DaysToRefund/DaysToRefundEditForm.vue';
import { FeeI, LetterI } from '@/interfaces';
import UserService from '@/services/api/User.service';
import { DaysToRefundConfigurationRecordM } from '@/models/DaysToRefundConfigurationRecord.model';
import EventBus from '@/events/EventBus';
import { DaysToRefundI } from '@/interfaces/days-to-refund.interface';

const emptyDaysToRefundRecord = JSON.parse(JSON.stringify(DaysToRefundConfigurationRecordM));

export default Vue.extend({
  name: 'DaysToRefundGrid',
  data() {
    return {
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      saveButtonActive: true,
      editedRecord: null as null | FeeI,
      dialogTitleText: 'Edit Days to Refund Rule',
      gridApiUrls: {
        metadata: '/uiapi/grids/config-days-to-refund',
        data: '/uiapi/config-days-to-refund/search',
      },
    };
  },
  components: { SfDataGrid, SfCustomDialog, DaysToRefundEditForm },
  methods: {
    actions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit Rule',
          disabled: false,
          callback: (rowData: DaysToRefundI): void => {
            this.editRecord(rowData);
          },
        },
      ];
    },
    refreshGrid() {
      this.getRemoteComponent('DaysToRefundGrid').loadData();
    },
    async saveFormData() {
      this.saveButtonActive = false;

      this.$getRemoteComponent('DaysToRefundEditForm', this).prepareSaveDate();

      this.getRemoteComponent('DaysToRefundEditForm')
        .submitForm()
        .then(() => {
          this.saveButtonActive = true;
          this.getRemoteComponent('DaysToRefundCustomDialogForm')
            .external()
            .close();
        })
        .catch(() => {
          this.saveButtonActive = true;
        });
    },
    addNewRule() {
      this.dialogTitleText = 'Add Days to Refund Rule';

      this.editedRecord = JSON.parse(JSON.stringify(emptyDaysToRefundRecord));
      if (this.editedRecord) {
        this.editedRecord.orgId = this.orgId;
      }
    },
    editRecord(record: DaysToRefundI) {
      this.dialogTitleText = 'Edit Days to Refund Rule';

      this.editedRecord = this.$getClone(record);
      this.openEditDialog();
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    openEditDialog() {
      this.getRemoteComponent('DaysToRefundCustomDialogForm')
        .external()
        .open();
    },
  },
  props: ['footerHeight', 'orgId'],
});
