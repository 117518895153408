var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      staticStyle: {
        display: "flex",
        flex: "1",
        height: "100%",
        "flex-direction": "row",
        position: "relative"
      }
    },
    [
      _c(
        "div",
        {
          ref: "resizableDiv",
          staticStyle: {
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            overflow: "hidden"
          }
        },
        [
          _c(
            "pre",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              staticStyle: {
                position: "absolute",
                bottom: "200px",
                left: "10px",
                right: "10px",
                height: "200px",
                border: "2px solid gold",
                "font-size": "12px"
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm
                    .getHeaders()
                    .filter(function(h) {
                      return (
                        h.subFilterSelection && h.subFilterSelection.length > 0
                      )
                    })
                    .map(function(h) {
                      return (
                        h.value +
                        ": " +
                        (h.subFilterSelection.map(function(v) {
                          return v.value
                        }) + "\n\r" || "")
                      )
                    })
                    .toString()
                    .replace(/\n\r,/gi, "\n\r")
                ) + "\n    "
              )
            ]
          ),
          _c(
            "v-data-table",
            _vm._b(
              {
                staticClass: "sfl-data-table",
                attrs: {
                  "hide-default-footer": _vm.hideFooter,
                  loading: _vm.loading,
                  "show-select": _vm.selection,
                  "single-select": _vm.singleSelect,
                  headers: _vm.getHeaders(),
                  options: _vm.dataTable.options,
                  "item-key": "id"
                },
                on: {
                  "click:row": _vm.rowClick,
                  "item-selected": _vm.itemSelected,
                  "update:options": function($event) {
                    return _vm.$set(_vm.dataTable, "options", $event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.getHeaders(), function(h, i) {
                      return {
                        key: "header." + h.value,
                        fn: function(ref) {
                          var header = ref.header
                          return [
                            _vm._v(" " + _vm._s(header.text) + " "),
                            (header.hideFilter
                            ? false
                            : true)
                              ? _c(
                                  "v-menu",
                                  {
                                    key: h + i,
                                    staticStyle: { "z-index": "9999" },
                                    attrs: {
                                      "offset-y": "",
                                      maxHeight: "350px",
                                      rounded: "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _vm.disableFilters === false
                                                ? _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticStyle: {
                                                            float: "right",
                                                            "margin-right":
                                                              "-14px",
                                                            "font-weight":
                                                              "100 !important"
                                                          },
                                                          attrs: {
                                                            color: header.subFilterSelection
                                                              ? header
                                                                  .subFilterSelection
                                                                  .length > 0
                                                                ? header.filterOpen
                                                                  ? "#1b96ff"
                                                                  : "#666"
                                                                : header.filterOpen
                                                                ? "#1b96ff"
                                                                : "#aaa"
                                                              : header.filterOpen
                                                              ? "#1b96ff"
                                                              : "#aaa",
                                                            dense: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.stopTheEvent(
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            header.subFilterSelection
                                                              ? header
                                                                  .subFilterSelection
                                                                  .length > 0
                                                                ? "mdi-filter"
                                                                : "mdi-filter-outline"
                                                              : "mdi-filter-outline"
                                                          )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: header.filterOpen,
                                      callback: function($$v) {
                                        _vm.$set(header, "filterOpen", $$v)
                                      },
                                      expression: "header.filterOpen"
                                    }
                                  },
                                  [
                                    _c("sf-data-grid-column-filter", {
                                      attrs: {
                                        orgId: _vm.orgId,
                                        "auto-refresh": _vm.autoRefresh,
                                        header: header,
                                        metadata: _vm.store.metadata
                                      },
                                      on: {
                                        input: function($event) {
                                          _vm.autoRefresh
                                            ? _vm.loadData()
                                            : (function() {})()
                                        },
                                        "apply-action": function($event) {
                                          header.filterOpen = false
                                          !_vm.autoRefresh
                                            ? _vm.loadData()
                                            : (function() {})()
                                        }
                                      },
                                      model: {
                                        value: header.subFilterSelection,
                                        callback: function($$v) {
                                          _vm.$set(
                                            header,
                                            "subFilterSelection",
                                            $$v
                                          )
                                        },
                                        expression: "header.subFilterSelection"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    }),
                    _vm._l(
                      _vm.getHeaders().filter(function(header) {
                        return (
                          header.hasOwnProperty("parse") ||
                          header.hasOwnProperty("parser")
                        )
                      }),
                      function(header) {
                        return {
                          key: "item." + header.value,
                          fn: function(ref) {
                            var value = ref.value
                            var item = ref.item
                            return [
                              !header.html
                                ? _c(
                                    "span",
                                    {
                                      key: header.value,
                                      staticClass: "text-regular"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.convertCellValue(
                                            value,
                                            header,
                                            item
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.html
                                ? _c("span", {
                                    key: header.value,
                                    staticClass: "html-object",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.convertCellValue(
                                          value,
                                          header,
                                          item
                                        )
                                      )
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        }
                      }
                    ),
                    {
                      key: "item.Previews",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticStyle: { "white-space": "nowrap" } },
                            _vm._l(_vm.previews.items, function(
                              previewItem,
                              previewItemIndex
                            ) {
                              return _c(
                                "div",
                                { key: previewItemIndex },
                                _vm._l(item.transmissions, function(
                                  transmission,
                                  transmissionIndex
                                ) {
                                  return _c(
                                    "v-tooltip",
                                    {
                                      key: transmissionIndex,
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "img",
                                                  _vm._g(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: previewItem.checkVisibility(
                                                            transmission
                                                          ),
                                                          expression:
                                                            "previewItem.checkVisibility(transmission)"
                                                        }
                                                      ],
                                                      staticStyle: {
                                                        width: "28px",
                                                        height: "28px",
                                                        cursor: "pointer"
                                                      },
                                                      attrs: {
                                                        src:
                                                          typeof previewItem.image ===
                                                          "string"
                                                            ? previewItem.image
                                                            : previewItem.image(
                                                                transmission
                                                              )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          previewItem.action(
                                                            transmission,
                                                            _vm.getArrayIndexOfRow(
                                                              item[
                                                                _vm.dataIdField
                                                              ]
                                                            )
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            typeof previewItem.tooltip ===
                                              "string"
                                              ? previewItem.tooltip
                                              : previewItem.tooltip(
                                                  transmission
                                                )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                }),
                                1
                              )
                            }),
                            0
                          )
                        ]
                      }
                    },
                    {
                      key: "item.Actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: { "white-space": "nowrap" },
                              on: { click: _vm.stopTheEvent }
                            },
                            _vm._l(_vm.actions, function(action, actionIndex) {
                              return _c(
                                "v-tooltip",
                                {
                                  key: actionIndex,
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        typeof action.hide ===
                                                        "function"
                                                          ? action.hide(item)
                                                          : action.hide === true
                                                          ? false
                                                          : true,
                                                      expression:
                                                        "typeof action.hide === 'function' ? action.hide(item) : action.hide === true ? false : true"
                                                    }
                                                  ],
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    small: "",
                                                    disabled: _vm._f(
                                                      "actionDisabled"
                                                    )(action, item),
                                                    color: action.color
                                                      ? action.color
                                                      : "#6691c5"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      action.callback(
                                                        item,
                                                        _vm.getArrayIndexOfRow(
                                                          item[_vm.dataIdField]
                                                        )
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(action.icon) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("actionTooltip")(action, item)
                                      )
                                    )
                                  ])
                                ]
                              )
                            }),
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectedRows,
                  callback: function($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows"
                }
              },
              "v-data-table",
              _vm.dataTable,
              false
            ),
            [
              _vm.errors.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "mt-5",
                      attrs: { slot: "no-data" },
                      slot: "no-data"
                    },
                    [
                      _c(
                        "v-alert",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "line-height": "15px",
                            width: "100%",
                            "text-align": "left"
                          },
                          attrs: {
                            type: "error",
                            border: "left",
                            text: "",
                            outlined: "",
                            dense: ""
                          }
                        },
                        [
                          _c("div", [_c("b", [_vm._v("Errors:")])]),
                          _vm._l(_vm.errors, function(error, errorIndex) {
                            return _c("div", { key: errorIndex }, [
                              _vm._v("* " + _vm._s(error))
                            ])
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              [_c("div", [_vm._v("error")])]
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }