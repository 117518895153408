








































































































































































































































































import { Vue } from 'vue-property-decorator';
import PageLayout from '../../components/PageLayout.vue';
import { ProductPreference } from '../../interfaces';
import ProductPreferenceService from '../../services/api/ProductPreference.service';
import ValidationService from '../../services/Validations.service';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import DataGrid from '@/components/DataGrid.vue';

/**
 *  Metadata service URL
 */
const metadataEndpointUrl = '/uiapi/grids/product-preference';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/product-preference/search';

const emptyProductPreferenceRecord: ProductPreference = {
  productType: '',
};

export default Vue.extend({
  name: 'ProductPreferencesTab',
  components: {
    PageLayout,
    DataGrid,
  },
  data: () => ({
    dialog: false,
    previewDialog: false,
    deleteDialogOpen: false,
    processing: false,
    valid: true,
    overlay: false,
    errors: [],
    editActionType: 'INSERT',
    editedRecord: Object.assign({}, emptyProductPreferenceRecord),
    selectedProductIdToDelete: 0 as number,
    ValidationService: ValidationService as Object,
    message: '',
    alertType: 'success',
    reRenderGrid: 0,
    snackBarSuccess: false,
    snackBarError: false,
    service: MetadataService,
    metadataEnumsMap: new Map(),
    hideFilters: false,
    endPoint: {
      metadataEndpointUrl: metadataEndpointUrl,
      dataEndpointUrl: dataEndpointUrl,
    },
  }),
  watch: {},
  methods: {
    openDialog(editedRecord?: ProductPreference): void {
      this.errors.length = 0;
      this.resetValidation();
      if (editedRecord === undefined) {
        this.editActionType = 'INSERT';
        Object.assign(this.editedRecord, emptyProductPreferenceRecord);
      } else {
        this.editActionType = 'UPDATE';
        Object.assign(this.editedRecord, editedRecord);
      }
      this.dialog = !this.dialog;
    },
    updateEditedRecord() {
      this.editedRecord.orgId = this.passEditedRecord.orgId;
    },
    validate() {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).productPreferenceForm.validate();
      }, 250);
    },
    resetValidation() {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).productPreferenceForm.resetValidation();
      }, 100);
    },
    formValueChange() {
      this.message = '';
    },
    setError(errors: string[]) {
      this.errors.length = 0;
      Object.assign(this.errors, errors);
    },
    submitDeleteDataRequest() {
      ProductPreferenceService.delete(this.selectedProductIdToDelete as number)
        .then(res => {
          this.processing = false;
          if (res?.data?.success) {
            this.snackBarSuccess = true;
            this.reRenderGrid++;
            this.deleteDialogOpen = !this.deleteDialogOpen;
          } else {
            // Setting errors will show them on form
            this.setError(res.data.errors);
            this.snackBarError = true;
          }
        })
        .catch(error => {
          this.processing = false;
          this.setError([error]);
        });
    },
    async submitDataRequest() {
      try {
        if ((this.$refs.productPreferenceForm as Vue & { validate: () => boolean }).validate()) {
          this.overlay = true;
          let res;
          if (this.editActionType === 'INSERT') {
            res = await ProductPreferenceService.create(this.editedRecord);
          }
          if (this.editActionType === 'UPDATE') {
            res = await ProductPreferenceService.update(this.editedRecord);
          }
          if (res.data['success']) {
            (this.$refs.productPreferenceForm as Vue & { reset: () => boolean }).reset();
            this.reRenderGrid++;
            this.alertType = 'success';
            this.message = 'Product Preference saved';
            setTimeout(() => {
              this.dialog = false;
              this.message = '';
              this.overlay = false;
            }, 1500);
          } else {
            this.alertType = 'error';
            this.message = res?.data['errors']?.join(', ') || 'Unknown error';
            this.overlay = false;
          }
        }
      } catch (err) {
        this.alertType = 'error';
        this.message = 'There were some errors during Product Preference saving';
        this.overlay = false;
      }
    },
  },
  async created() {
    this.metadataEnumsMap = await this.service.loadData(MetadataType.PRODUCT_PREFERENCE);
    this.updateEditedRecord();
  },
  props: {
    passEditedRecord: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit',
          callback: (rowData: ProductPreference): void => {
            this.openDialog(rowData);
          },
        },
        {
          icon: 'mdi-trash-can',
          tooltip: 'Delete',
          disabled: this.disabled,
          callback: (rowData: ProductPreference): void => {
            this.selectedProductIdToDelete = rowData.id as number;
            this.deleteDialogOpen = !this.deleteDialogOpen;
          },
        },
      ];
    },
    buttonActions() {
      return [
        {
          name: 'New Product Preference',
          icon: 'mdi-pencil',
          disabled: this.disabled,
          callback: () => {
            this.openDialog();
          },
        },
      ];
    },
  },
});
