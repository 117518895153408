

















































import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import ValidationService from '@/services/Validations.service';

@Component({
  components: {},
})
export default class SfCustomParamDate extends Vue {
  @PropSync('fieldValue') fieldValueSync!: any;
  @PropSync('fieldOperator') fieldOperatorSync!: any;
  @PropSync('fieldDefinition') fieldDefinitionSync!: any;
  @Prop([String]) readonly orgId: boolean | undefined;
  @Prop([Boolean]) readonly readonly: boolean | undefined;
  @Prop([Boolean]) readonly disabled: boolean | undefined;
  @Prop([Boolean]) readonly hideDetails: boolean | undefined;

  value: any;
  services: any;
  debug: any;
  data() {
    return {
      debug: false,
      ready: false,
      services: {
        validationService: ValidationService,
      },
    };
  }
  async mounted() {
    this.$data.ready = true;
  }
}
