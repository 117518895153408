var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        flex: "1",
        height: "100%",
        "flex-direction": "row"
      }
    },
    [
      _c("sf-data-grid", {
        ref: "LenderServiceConfigurationLetterGrid",
        attrs: {
          lookups: _vm.gridApiUrls.lookups,
          apiMetadataUrl: _vm.gridApiUrls.metadata,
          apiDataUrl: _vm.gridApiUrls.data,
          "footer-height": _vm.footerHeight,
          actions: _vm.actions(),
          orgId: _vm.orgId,
          "fixed-conditions": [
            {
              fieldName: "orgId",
              value: _vm.orgId,
              fieldType: "string",
              conditionComparatorOption: "equal"
            },
            {
              fieldName: "type",
              value: "Letter",
              fieldType: "string",
              conditionComparatorOption: "equal"
            }
          ]
        }
      }),
      _c(
        "sf-custom-dialog",
        {
          ref: "LetterCustomDialogForm",
          attrs: { "max-width": "99vw", fullscreen: "" },
          on: {
            close: function($event) {
              return _vm.refreshGrid()
            }
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(
              "Edit Letter Rule " + _vm._s(_vm.readonly ? " (Read-Only)" : "")
            )
          ]),
          _c("div", { attrs: { slot: "buttons" }, slot: "buttons" }, [
            _c(
              "button",
              {
                staticClass: "slds-button slds-button_neutral",
                on: {
                  click: function($event) {
                    _vm
                      .getRemoteComponent("LetterCustomDialogForm")
                      .external()
                      .close()
                  }
                }
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "button",
              {
                staticClass: "slds-button slds-button_neutral",
                attrs: {
                  disabled:
                    _vm.editedRecord &&
                    _vm.editedRecord.letterFileExternalId === null
                },
                on: {
                  click: function($event) {
                    _vm
                      .actions()
                      .find(function(a) {
                        return a.tooltip === "Preview Letter"
                      })
                      .callback(_vm.editedRecord)
                  }
                }
              },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "pr-1",
                    attrs: {
                      color: "#0176d3",
                      disabled:
                        _vm.editedRecord &&
                        _vm.editedRecord.letterFileExternalId === null
                    }
                  },
                  [_vm._v("mdi-file-find-outline")]
                ),
                _vm._v(" Preview Letter ")
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "slds-button slds-button_brand default",
                attrs: { disabled: !_vm.saveButtonActive || _vm.readonly },
                on: {
                  click: function($event) {
                    return _vm.saveFormData()
                  }
                }
              },
              [_vm._v(" Save ")]
            )
          ]),
          _c(
            "div",
            {
              staticStyle: { display: "flex", flex: "1" },
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _c("lender-service-configuration-letter-edit-form", {
                ref: "LenderServiceConfigurationLetterEditForm",
                attrs: { orgId: _vm.orgId },
                on: {
                  "row-updated": function($event) {
                    return _vm.refreshGrid()
                  },
                  "row-added": function($event) {
                    return _vm.refreshGrid()
                  }
                },
                model: {
                  value: _vm.editedRecord,
                  callback: function($$v) {
                    _vm.editedRecord = $$v
                  },
                  expression: "editedRecord"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }