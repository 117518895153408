var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              return [
                _c(
                  "div",
                  _vm._g({ staticStyle: { display: "inline-block" } }, on),
                  [
                    _vm._t("button", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "slds-button slds-button_neutral ml-2 mr-2",
                          attrs: { title: "Update Letter" }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "slds-button__icon slds-button__icon_left",
                              attrs: {
                                "aria-hidden": "true",
                                disabled: _vm.progress
                              }
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                                    "#upload"
                                }
                              })
                            ]
                          ),
                          _vm._v(" Edit Letter "),
                          _c("span", { staticClass: "slds-assistive-text" }, [
                            _vm._v("Edit Letter")
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                )
              ]
            }
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "section",
        {
          staticClass: "slds-modal slds-fade-in-open",
          attrs: {
            role: "dialog",
            tabindex: "-1",
            "aria-labelledby": "modal-heading-01",
            "aria-modal": "true",
            "aria-describedby": "modal-content-id-1"
          }
        },
        [
          _c("div", { staticClass: "slds-modal__container" }, [
            _c("header", { staticClass: "slds-modal__header" }, [
              _c(
                "button",
                {
                  staticClass:
                    "slds-button slds-button_icon slds-modal__close slds-button_icon-inverse",
                  attrs: { title: "Close" },
                  on: {
                    click: function($event) {
                      _vm.open = false
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "slds-button__icon slds-button__icon_large",
                      attrs: { "aria-hidden": "true" }
                    },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            require("slds/assets/icons/utility-sprite/svg/symbols.svg") +
                            "#close"
                        }
                      })
                    ]
                  ),
                  _c("span", { staticClass: "slds-assistive-text" }, [
                    _vm._v("Close")
                  ])
                ]
              ),
              _c(
                "h2",
                {
                  staticClass: "slds-modal__title slds-hyphenate",
                  attrs: { id: "modal-heading-01" }
                },
                [_vm._t("title", [_vm._v("Edit Letter")])],
                2
              )
            ]),
            _vm.open
              ? _c(
                  "div",
                  {
                    staticClass: "slds-modal__content slds-p-around_medium",
                    attrs: { id: "modal-content-id-1" }
                  },
                  [
                    _vm._t("content", [
                      _c(
                        "v-form",
                        {
                          ref: "LetterUploadForm",
                          on: {
                            submit: _vm.stopTheEvent,
                            click: _vm.stopTheEvent
                          },
                          model: {
                            value: _vm.formValid,
                            callback: function($$v) {
                              _vm.formValid = $$v
                            },
                            expression: "formValid"
                          }
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "input-shrink" },
                            [
                              _c("v-progress-linear", {
                                directives: [
                                  {
                                    name: "visible",
                                    rawName: "v-visible",
                                    value: _vm.progress,
                                    expression: "progress"
                                  }
                                ],
                                attrs: { indeterminate: "", color: "#0176d3" }
                              }),
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.messages.length > 0,
                                      expression: "messages.length > 0"
                                    }
                                  ],
                                  staticClass: "confirmation-message"
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                                "line-height": "15px"
                                              },
                                              attrs: {
                                                type: "info",
                                                border: "left",
                                                text: "",
                                                outlined: "",
                                                dense: "",
                                                autocomplete: "none"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mb-1" },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      "Message" +
                                                        _vm._s(
                                                          _vm.messages.length >
                                                            1
                                                            ? "s"
                                                            : ""
                                                        ) +
                                                        ":"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._l(_vm.messages, function(
                                                message,
                                                messageIndex
                                              ) {
                                                return _c(
                                                  "div",
                                                  { key: messageIndex },
                                                  [
                                                    _vm._v(
                                                      "* " + _vm._s(message)
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.length > 0,
                                      expression: "errors.length > 0"
                                    }
                                  ],
                                  staticClass: "error-message"
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              staticStyle: {
                                                "font-weight": "strong",
                                                "font-size": "14px",
                                                "line-height": "15px"
                                              },
                                              attrs: {
                                                type: "error",
                                                border: "left",
                                                text: "",
                                                outlined: "",
                                                dense: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mb-1" },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      "Error" +
                                                        _vm._s(
                                                          _vm.errors.length > 1
                                                            ? "s"
                                                            : ""
                                                        ) +
                                                        ":"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._l(_vm.errors, function(
                                                error,
                                                errorIndex
                                              ) {
                                                return _c("div", {
                                                  key: errorIndex,
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      "* " + error
                                                    )
                                                  }
                                                })
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "sfl-fix-label" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Letter Name",
                                          rules: [
                                            _vm.ValidationService.getValidator(
                                              "required",
                                              "Letter Name"
                                            )
                                          ],
                                          dense: "",
                                          "hide-details": "",
                                          outlined: ""
                                        },
                                        model: {
                                          value: _vm.uploadRecord.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.uploadRecord,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "uploadRecord.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "sfl-fix-label" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Letter File Selected",
                                          value: _vm.getFileName(),
                                          title: _vm.getFileName(),
                                          dense: "",
                                          "hide-details": "",
                                          outlined: "",
                                          disabled: "",
                                          readonly: ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: false,
                                          expression: "false"
                                        }
                                      ],
                                      ref: "FileInputField",
                                      attrs: {
                                        type: "file",
                                        label: "File input",
                                        accept: ".pdf"
                                      },
                                      on: { change: _vm.onFileChanged }
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "slds-button slds-button_neutral",
                                        staticStyle: {
                                          "margin-top": "-1px",
                                          height: "42px",
                                          width: "100%",
                                          "font-size": "19px",
                                          background: "#fdfdfd",
                                          "font-family": "Roboto, sans-serif",
                                          position: "relative",
                                          cursor: "pointer"
                                        },
                                        style: {
                                          borderColor: !_vm.checkLetterFileStatus()
                                            ? "red !important"
                                            : "",
                                          color: !_vm.checkLetterFileStatus()
                                            ? "red !important"
                                            : ""
                                        },
                                        attrs: { loading: _vm.isSelecting },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.handleFileImport($event)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "pr-3",
                                            style: {
                                              color: !_vm.checkLetterFileStatus()
                                                ? "red !important"
                                                : ""
                                            },
                                            attrs: { color: "#0176d3" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.checkLetterFileStatus()
                                                    ? "mdi-paperclip-check"
                                                    : "mdi-paperclip"
                                                )
                                            )
                                          ]
                                        ),
                                        _vm._v(" Select New Letter File "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.checkLetterFileStatus(),
                                                expression:
                                                  "!checkLetterFileStatus()"
                                              }
                                            ],
                                            staticStyle: {
                                              position: "absolute",
                                              top: "-7px",
                                              left: "7px",
                                              "font-size": "14px",
                                              background: "white",
                                              padding: "0px 8px",
                                              "line-height": "15px",
                                              color: "red"
                                            }
                                          },
                                          [_vm._v(" File is Required ")]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c(
                                    "v-col",
                                    { staticClass: "sfl-fix-label" },
                                    [
                                      _c(
                                        "sf-local-letter-preview",
                                        {
                                          staticStyle: { float: "left" },
                                          attrs: {
                                            uploadRecord: _vm.uploadRecord,
                                            orgId: _vm.orgId
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "slds-button slds-button_neutral",
                                              staticStyle: {
                                                "margin-top": "-1px",
                                                height: "42px",
                                                width: "100%",
                                                "font-size": "19px",
                                                background: "#fdfdfd",
                                                "font-family":
                                                  "Roboto, sans-serif",
                                                position: "relative",
                                                cursor: "pointer"
                                              },
                                              style: {
                                                borderColor: !_vm.checkLetterFileStatus()
                                                  ? "red !important"
                                                  : "",
                                                color: !_vm.checkLetterFileStatus()
                                                  ? "red !important"
                                                  : ""
                                              },
                                              attrs: {
                                                loading: _vm.isSelecting
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "pr-3",
                                                  style: {
                                                    color: !_vm.checkLetterFileStatus()
                                                      ? "red !important"
                                                      : ""
                                                  },
                                                  attrs: { color: "#0176d3" }
                                                },
                                                [_vm._v(" mdi-file-find")]
                                              ),
                                              _vm._v("Preview ")
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  2
                )
              : _vm._e(),
            _c("footer", { staticClass: "slds-modal__footer " }, [
              _c(
                "div",
                {
                  staticClass: "slds-button slds-button_neutral",
                  staticStyle: { cursor: "pointer" },
                  attrs: { title: "Update Letter" },
                  on: {
                    click: function($event) {
                      _vm.open = false
                    }
                  }
                },
                [
                  _vm._v(" " + _vm._s(_vm.cancelButtonText) + " "),
                  _c("span", { staticClass: "slds-assistive-text" }, [
                    _vm._v(_vm._s(_vm.cancelButtonText))
                  ])
                ]
              ),
              _vm.showUpdateButton
                ? _c(
                    "div",
                    {
                      staticClass: "slds-button slds-button_brand",
                      staticStyle: { cursor: "pointer" },
                      attrs: { title: "Save Letter", disabled: _vm.progress },
                      on: {
                        click: function($event) {
                          return _vm.update()
                        }
                      }
                    },
                    [
                      _vm._v(" Save "),
                      _c("span", { staticClass: "slds-assistive-text" }, [
                        _vm._v("Save")
                      ])
                    ]
                  )
                : _vm._e()
            ])
          ])
        ]
      ),
      _c("div", { staticClass: "slds-backdrop slds-backdrop_open" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }