import ApiService from '@/services/api/Api.service';
import { ProductTypeAlias, Response } from '../../interfaces';
import { AxiosResponse } from 'axios';

class ProductTypeAliasService extends ApiService {
  async create(data: ProductTypeAlias): Promise<Response<ProductTypeAlias>> {
    return (
      await this.axios.post<ProductTypeAlias, AxiosResponse<Response<ProductTypeAlias>>>(
        '/uiapi/product-type-alias',
        data,
      )
    ).data;
  }
  async update(data: ProductTypeAlias, id: number): Promise<Response<ProductTypeAlias>> {
    return (
      await this.axios.post<ProductTypeAlias, AxiosResponse<Response<ProductTypeAlias>>>(
        `/uiapi/product-type-alias/${id}`,
        data,
      )
    ).data;
  }
  async delete(id: number): Promise<Response<ProductTypeAlias>> {
    return (
      await this.axios.delete<ProductTypeAlias, AxiosResponse<Response<ProductTypeAlias>>>(
        `/uiapi/product-type-alias/${id}`,
      )
    ).data;
  }
}

export default new ProductTypeAliasService();
