import axios from '../../services/api/Api.service';
import { SerchResponse } from '../../../../src/domain/grid/interfaces';
import { AxiosResponse } from 'axios';

/**
 * Axios Instance for api communication
 */
const axiosInstance = new axios().getClient();
export interface EnumItemI {
  text: string;
  value: string | boolean;
}

export type FieldOptionI = { text: string; value: string | boolean | null } | string | null | boolean;
export type ConditionI = {
  fieldOptions: FieldOptionI[];
  fieldName: string;
  fieldType: 'Enum' | 'String' | 'Boolean' | 'Date' | 'Checkbox';
};

export interface SubFilterSetI {
  conditions: ConditionI[];
}

export enum MetadataType {
  ACCOUNT = 'account',
  PRODUCT = 'product',
  PRODUCT_PREFERENCE = 'product-preference',
  PRODUCT_TYPE_ALIAS = 'product-type-alias',
  API_LOGS = 'api-logs',
  API_PARTNER_LOGS = 'api-partner-logs',
  INFORMED_API_LOGS = 'informed-api-logs',
  PRODUCT_PAYMENT = 'product-payment',
  CONFIG_CANCELLATION_FEE = 'config-cancellation-fee',
  CONFIG_PATH = 'config-path',
  CONFIG_LETTER = 'config-letter',
  CONFIG_FREE_LOOK = 'config-free-look',
}

class MetadataService {
  protected store: Map<MetadataType, Map<string, FieldOptionI[]>>;

  protected metadataEndpointsMap: Map<MetadataType, string> = new Map([
    [MetadataType.ACCOUNT, '/uiapi/grids/account'],
    [MetadataType.PRODUCT, '/uiapi/grids/product'],
    [MetadataType.PRODUCT_PREFERENCE, '/uiapi/grids/product-preference'],
    [MetadataType.PRODUCT_TYPE_ALIAS, '/uiapi/grids/product-type-alias'],
    [MetadataType.API_LOGS, '/uiapi/grids/api-logs'],
    [MetadataType.API_PARTNER_LOGS, '/uiapi/grids/api-partner-logs'],
    [MetadataType.INFORMED_API_LOGS, '/uiapi/grids/informed-api-logs'],
    [MetadataType.PRODUCT_PAYMENT, '/uiapi/grids/product-payment'],
    [MetadataType.CONFIG_CANCELLATION_FEE, '/uiapi/grids/config-cancellation-fee'],
    [MetadataType.CONFIG_PATH, '/uiapi/grids/config-path'],
    [MetadataType.CONFIG_LETTER, '/uiapi/grids/config-letter'],
    [MetadataType.CONFIG_FREE_LOOK, '/uiapi/grids/config-free-look'],
  ]);

  constructor() {
    this.store = new Map();
  }
  private async getApiMetadata(metadataType: MetadataType): Promise<unknown> {
    const apiUrl = this.metadataEndpointsMap.get(metadataType);
    if (apiUrl) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get<{ subFilterSets: SubFilterSetI[] }>(apiUrl)
          .then(results => {
            const enumSets: Map<string, FieldOptionI[]> = new Map();
            results.data.subFilterSets.forEach((subFilterSet: SubFilterSetI) => {
              subFilterSet.conditions.forEach((condition: ConditionI) => {
                if (condition.fieldType === 'Enum') {
                  enumSets.set(condition.fieldName, condition.fieldOptions);
                }
              });
            });
            this.store.set(metadataType, enumSets);
            resolve(null);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }

  public async getRecord(searchFields: {}, filterCode: string, apiUrl: string) {
    const conditions = [] as {}[];
    for (const [key, value] of Object.entries(searchFields)) {
      conditions.push({
        fieldName: key,
        type: 'String',
        comparatorOption: 'equal',
        value: value,
      });
    }
    const request = {
      filterCode: filterCode,
      page: 1,
      rowsPerPage: 1,
      conditions: conditions,
      sort: [],
    };
    return new Promise((resolve, reject) => {
      axiosInstance
        .post<any, AxiosResponse<SerchResponse<any>>>(apiUrl, request)
        .then(results => {
          let data = null;
          data = results.data.data?.[0];
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public async getRecords(searchFields: {}, filterCode: string, apiUrl: string) {
    const conditions = [] as {}[];
    for (const [key, value] of Object.entries(searchFields)) {
      conditions.push({
        fieldName: key,
        type: 'String',
        comparatorOption: 'equal',
        value: value,
      });
    }
    const request = {
      filterCode: filterCode,
      page: 1,
      rowsPerPage: 9999,
      conditions: conditions,
      sort: [],
    };

    return new Promise((resolve, reject) => {
      axiosInstance
        .post<any, AxiosResponse<SerchResponse<any>>>(apiUrl, request)
        .then(results => {
          let data = null;
          data = results.data?.data;
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public async loadData(metadataType: MetadataType) {
    if (!this.store.has(metadataType)) {
      await this.getApiMetadata(metadataType);
    }
    return this.store.get(metadataType) || new Map();
  }
}

export default new MetadataService();
