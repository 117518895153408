

















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ErrorMessageInfo extends Vue {
  @Prop([String, Boolean]) readonly open: string | boolean | undefined;

  data() {
    return {
      messages: [] as string[],
      errors: [] as string[],
    };
  }
  message() {
    return {
      set: (messages: string[]) => {
        this.$data.messages.push(...messages);
        return this;
      },
      add: (message: string) => {
        this.$data.messages.push(message);
        return this;
      },
      clear: () => {
        this.$data.messages.length = 0;
        return this;
      },
    };
  }
  error() {
    return {
      set: (errors: string[]) => {
        this.$data.errors.push(...errors);
        return this;
      },
      add: (error: string) => {
        this.$data.errors.push(error);
        return this;
      },
      clear: () => {
        this.$data.errors.length = 0;
        return this;
      },
    };
  }
}
