














import { Vue } from 'vue-property-decorator';
import axios from '@/services/api/Api.service';
const axiosInstance = new axios().getClient();
const tempUploadUrl = '/uiapi/cancellation-letter/upload-temp-letter-file-preview';
import EventBus from '@/events/EventBus';

export default Vue.extend({
  name: 'SfLocalLetterPreview',
  data() {
    return {
      enabled: false,
    };
  },
  components: {},
  created() {},
  watch: {
    uploadRecord: {
      handler(n: any) {
        if (n) {
          this.enabled = true;
        } else {
          this.enabled = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    async convertBase64(file: File) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = error => {
          reject(error);
        };
      });
    },
    async uploadTempLetterFile() {
      const fileBase64Encoded = (await this.convertBase64(this.uploadRecord.files)) as string;
      return await axiosInstance.post(tempUploadUrl, {
        data: { fileBase64: fileBase64Encoded },
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
      });
    },
    async openPreview() {
      if (!this.enabled) {
        return true;
      }
      if (this.uploadRecord.files) {
        await this.openUploadPreview();
      } else {
        await this.openUploadedPreview();
      }
    },
    async openUploadedPreview() {
      EventBus.$emit('SF_OPEN_LETTER_FORM_PREVIEW', { id: this.uploadRecord.id });
    },
    async openUploadPreview() {
      const results = (await this.uploadTempLetterFile()) as any;
      if (results.data && results.data.previewFileName) {
        EventBus.$emit('SF_OPEN_LETTER_FORM_PREVIEW', { id: results.data.previewFileName, temp: true });
      }
    },
  },
  props: ['uploadRecord', 'orgId'],
});
