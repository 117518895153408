





























































import { Vue } from 'vue-property-decorator';

interface SlotConfigI {
  visible: boolean;
}

interface PageLayoutDataI {
  // Here some new layouts can be added but need refer to template modification
  state: boolean | null;
}

export default Vue.extend({
  name: 'PageLayout',
  components: {},
  /**
   * Page Layout Component data object
   */
  data: (): PageLayoutDataI => ({
    state: null,
  }),
  props: {
    // Page icon to left
    pageIcon: {
      type: String,
      default: 'mdi-icon',
    },
    // Page name top left next to page icon
    pageName: {
      type: String,
    },
    // page layout type ( for future use when some other layouts will be added example in template above: STANDARD_CONTENT_WITH_HEADER )
    pageType: {
      type: String,
      default: 'STANDARD_GRID_WITH_CONTROLS',
    },
    // Can add overlay on content space
    contentOverlay: {
      type: Boolean,
      default: false,
    },
  },
});
