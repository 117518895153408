import { render, staticRenderFns } from "./IDPAccountDetails.vue?vue&type=template&id=5fec0f20&scoped=true&"
import script from "./IDPAccountDetails.vue?vue&type=script&lang=ts&"
export * from "./IDPAccountDetails.vue?vue&type=script&lang=ts&"
import style0 from "./IDPAccountDetails.vue?vue&type=style&index=0&id=5fec0f20&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fec0f20",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VAlert,VBtn,VCheckbox,VCol,VContainer,VIcon,VList,VListItem,VListItemTitle,VMenu,VOverlay,VProgressCircular,VRow,VSelect,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fec0f20')) {
      api.createRecord('5fec0f20', component.options)
    } else {
      api.reload('5fec0f20', component.options)
    }
    module.hot.accept("./IDPAccountDetails.vue?vue&type=template&id=5fec0f20&scoped=true&", function () {
      api.rerender('5fec0f20', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/IDP/IDPAccountDetails.vue"
export default component.exports