











































































































































import { Vue } from 'vue-property-decorator';
import DataGrid from '@/components/DataGrid.vue';
import PageLayout from '@/components/PageLayout.vue';
import { LenderListI } from '../services/api/Lender.service';
import DealerPageService, { EnumItemI } from '../services/DealerPage.service';
import axios from '../services/api/Api.service';
import FormatDisplayService from '../services/FormatDisplay.service';
import { Lender } from '@/interfaces';
import FormGenerator from '@/components/FormGenerator.vue';
import ValidationService from '../services/Validations.service';
import { AxiosResponse } from 'axios';

Vue.directive('visible', function(el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

/**
 *  Metadata service URL
 */
const metadataEndpointUrl = '/uiapi/grids/dealer';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/dealer/search';

/**
 *  Update record endpoint URL
 */
const recordUpdateEndpointUrl = '/uiapi/dealer/{id}';
/**
 *  Insert record endpoint URL
 */
const recordInsertEndpointUrl = '/uiapi/dealer';

/**
 *  Dealer Data Record Definition
 */
interface DealerDataRecordI {
  id: number | null;
  orgId: string | null;
  sfid: string | null;
  communicationPreference: string | null;
  dealerCity: string | null;
  dealerContactName: string | null;
  dealerEIN: string | null;
  dealerFax: string | null;
  dealerNumber: string | null;
  dealerPhone: string | null;
  dealerReserve: boolean | null;
  dealerState: string | null;
  dealerStatus: string | null;
  dealerStreet: string | null;
  dealerZip: string | null;
  dealerCountry: string | null;
  name: string | null;
  emails: DealerEmailI[];
  orgPreference?: Lender;
  customFieldsValues?: object | null;
  dealerInvoice: boolean | null;
  doNotSendNotification: boolean | null;
  invoiceEmail: string | null;
}

/**
 *  Dealer Email Record Definition
 */
interface DealerEmailI {
  id: number | null;
  orgId: string | null;
  sfid: string | null;
  email: string | null;
  dealerNumber: string | null;
  externalId: string | null;
}

const emptyDealerDataRecord = {
  id: null,
  orgId: null,
  sfid: null,
  communicationPreference: 'Fax',
  dealerCity: null,
  dealerContactName: null,
  dealerEIN: null,
  dealerFax: null,
  dealerNumber: null,
  dealerPhone: null,
  dealerReserve: false,
  dealerState: null,
  dealerStatus: 'Inactive',
  dealerStreet: null,
  dealerZip: null,
  dealerCountry: 'US',
  name: null,
  emails: [],
  dealerInvoice: false,
  doNotSendNotification: false,
  invoiceEmail: null,
} as DealerDataRecordI;

/**
 * Dealer Page Component data object definition
 */
interface DealerPageDataI {
  snackMessage: boolean;
  phoneNumberRulesList: string[];
  overlay: boolean;
  errors: string[];
  editActionType: 'UPDATE' | 'INSERT';
  editFormDisabled: boolean;
  lender: string;
  dialog: boolean;
  valid: boolean;
  lenderList: LenderListI[];
  ContactEmail: string;
  editedRecordState: string[];
  editedRecord: DealerDataRecordI;
  editedRecordLists: {
    DealerStatus: EnumItemI[];
    CommunicationPreference: EnumItemI[];
    DealerReserve: EnumItemI[];
    DealerState: EnumItemI[];
    DealerCountry: EnumItemI[];
  };
  endPoint: {
    metadataEndpointUrl: string;
    dataEndpointUrl: string;
  };
  hideFilters: boolean;
  gridColumns: { text: string; value: string; sortable?: boolean }[];
}

export default Vue.extend({
  name: 'DealerPage',
  components: {
    DataGrid,
    PageLayout,
    FormGenerator,
  },
  /**
   * Dealer Page Component data object
   */
  data: (): DealerPageDataI =>
    ({
      snackMessage: false,
      phoneNumberRulesList: [
        'valid format (xxx) xxx-xxxx',
        'max length less than 16 characters',
        'cannot start with 1',
        'cannot contain sequential numbers (234) 567-890)',
        'cannot use the same digits (222) 222-2222)',
      ],
      overlay: false,
      errors: [],
      editActionType: 'INSERT',
      editFormDisabled: false,
      lender: '',
      dialog: false,
      valid: true,
      lenderList: [] as LenderListI[],
      ContactEmail: '',
      editedRecordState: [],
      editedRecord: Object.assign({}, emptyDealerDataRecord),
      /**
       * Dictionary Data for Edit Dealer
       * Will be replaced with Dictionary Service Source
       */
      editedRecordLists: {
        DealerStatus: [],
        CommunicationPreference: [],
        DealerReserve: [],
        DealerState: [],
        DealerCountry: [],
      },
      /**
       * Endpoint mapping for GridComponent
       */
      endPoint: {
        metadataEndpointUrl: metadataEndpointUrl,
        dataEndpointUrl: dataEndpointUrl,
      },
      /**
       * Optional hide filters in grid
       */
      hideFilters: false,
      /**
       * Definition of which coulm will be visible in GridComponent (value must match data property which GridComponent is feeded)
       */
      gridColumns: [
        { text: 'Dealer Number', value: 'dealerNumber' },
        { text: 'Dealer Name', value: 'name' },
        { text: 'Dealer Status', value: 'dealerStatus' },
        { text: 'Dealer Reserve', value: 'dealerReserve', parse: FormatDisplayService.getRender('toYesNo') },
        /**
         * Action colum will add actions to GridComponent
         */
        { text: 'Actions', value: 'Actions', sortable: false },
      ],
    } as DealerPageDataI),
  watch: {
    editedRecord: {
      handler() {
        this.validate();
      },
      deep: true,
    },
    '$store.getters.lender': {
      handler() {
        this.lender = this.$store.getters.lender as string;
      },
    },
    /**
     * Updates the dealer states list based on the selected dealer country.
     *
     * @param {string} newValue - The new country value.
     * @param {string} oldValue - The old country value.
     * @return {Promise<void>} - A promise that resolves when the dealer states list has been updated.
     */
    'editedRecord.dealerCountry': async function(newValue, oldValue) {
      if (newValue === 'CA') {
        this.editedRecordLists.DealerState = (await DealerPageService.getItems(
          'DealerStatesCanada',
          true,
        )) as EnumItemI[];
      } else {
        this.editedRecordLists.DealerState = (await DealerPageService.getItems('DealerStatesUS', true)) as EnumItemI[];
      }
    },
  },
  /**
   * Method will run when comoponent is created
   */
  created() {
    this.getEnums();
    this.lender = this.$store.getters.lender;
  },
  methods: {
    fixInvoiceEmails(emailList: string | null) {
      if (!emailList) {
        return emailList;
      } else {
        return emailList
          .toString()
          .toLowerCase()
          .replace(/\s/g, '')
          .replace(/;$/, '')
          .split(';')
          .join('; ');
      }
    },
    /**
     * Submit Request to API (Insert/Update)
     */
    submitDataRequest() {
      // reset error box on send request
      this.errors.length = 0;
      this.overlay = false;
      const requestObject = Object.assign({}, this.editedRecord as DealerDataRecordI);
      requestObject.invoiceEmail = this.fixInvoiceEmails(requestObject.invoiceEmail);
      let url = '';
      if (!requestObject.id) {
        url = recordInsertEndpointUrl;
      } else {
        url = recordUpdateEndpointUrl.replace('{id}', requestObject.id.toString());
      }
      //validation part, preparing data
      if (
        (this.$refs as HTMLFormElement).dealerFormGen
          .external()
          .getForm()
          .validate()
      ) {
        this.overlay = true;
        setTimeout(() => {
          axiosInstance
            .post<DealerDataRecordI, AxiosResponse<{ success: boolean; errors: string[] }>>(url, requestObject, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(result => {
              this.overlay = false;
              if (result.data.success) {
                // Reload CustomDataGrid component
                (this.$refs.dataGrid as HTMLFormElement).submitDataRequest();
                this.ContactEmail = '';
                this.snackMessage = true;
                this.dialog = false;
              } else {
                // Setting errors will show them on form
                this.setError(result.data.errors);
              }
            })
            .catch(error => {
              this.overlay = false;
              this.setError([error]);
            });
        }, 500);
      } else {
        this.resetValidation();
        this.validate();
      }
    },
    /**
     * Set Errors in Edit Details Box
     */
    setError(errors: string[]) {
      this.errors.length = 0;
      Object.assign(this.errors, errors);
    },
    /**
     * Validation Rules Object contain all validatioin methods
     */
    validationRules(FieldName?: string) {
      if (!FieldName) {
        FieldName = 'Field ';
      }
      const editedRecord = this.editedRecord as DealerDataRecordI;
      return {
        /**
         * Data length validation
         */
        maxLengthCheck10(v: string) {
          return (v || '').length <= 10 || FieldName + ' max 10 characters';
        },
        maxLengthCheck255(v: string) {
          return (v || '').length <= 255 || FieldName + ' max 255 characters';
        },
        maxLengthCheck100(v: string) {
          return (v || '').length <= 100 || FieldName + ' max 100 characters';
        },
        maxLengthCheck50(v: string) {
          return (v || '').length <= 50 || FieldName + ' max 50 characters';
        },
        /**
         * Phone number validation
         */
        phoneFormatIfEntered(v: string): boolean | string {
          if (typeof v === 'string' && v) {
            const digitOnly = v.replace(/\D/g, '');

            if ((v || '').length > 15) {
              return FieldName + ' max 15 characters';
            }
            if ((v || '')[0] === '1') {
              return FieldName + ' cannot start with 1';
            }

            if (digitOnly.indexOf('0123456789') > -1) {
              return FieldName + ' must be valid  ' + v + ' is wrong number';
            }

            if (!/^(?!(\d)\1+$|\d*(\d)\2{6}$)(?:\d{7,15})?$/.test(digitOnly)) {
              return FieldName + ' must be valid  cannot have more then 6 the same digits';
            }

            if (
              /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g.test(digitOnly) &&
              /^[2-9]\d{9}$/.test(digitOnly)
            ) {
              return true;
            } else {
              return FieldName + ' must be valid';
            }
          }
          return true;
        },
        emailFormatIfEntered(v: string): boolean | string {
          return !v || /.+@.+\..+/.test(v) || FieldName + ' must be valid';
        },
        notEmpty(v: string): boolean | string {
          return !!v || FieldName + ' is required';
        },
        dealerEINFormat(v: string): boolean | string {
          return /(\b\d{2})[-](\d{7}\b)/.test(v) || FieldName + ' (XX-XXXXXXX) must be valid';
        },
        checkFaxIfRequired(v?: string): boolean | string {
          if (!v && editedRecord.communicationPreference !== 'Fax') {
            return true;
          } else if (!v && editedRecord.communicationPreference === 'Fax') {
            return 'Valid fax number is required.';
          }
          return true;
        },
        /**
         * Validation of email field formt & require if Communication Preference switch to Email option
         */
        checkEmailIfRequired(v?: string): boolean | string {
          if (
            v &&
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            )
          ) {
            return 'Email must be valid';
          }
          if (!v) {
            if (editedRecord.communicationPreference === 'Email' && editedRecord.emails.length === 0) {
              return 'Valid Email is required.';
            }
          }
          return true;
        },
      };
    },
    /**
     * Loading Enums Lists ( replace with Enums Global Service in future )
     */
    async getEnums(): Promise<void> {
      this.editedRecordLists.DealerState = (await DealerPageService.getItems('DealerStatesUS', true)) as EnumItemI[];
      this.editedRecordLists.DealerStatus = (await DealerPageService.getItems('DealerStatus')) as EnumItemI[];
      this.editedRecordLists.DealerReserve = (await DealerPageService.getItems('DealerReserve')) as EnumItemI[];
      this.editedRecordLists.DealerCountry = (await DealerPageService.getItems('DealerCountry')) as EnumItemI[];
      this.editedRecordLists.CommunicationPreference = (await DealerPageService.getItems(
        'CommunicationPreference',
      )) as EnumItemI[];
    },
    /**
     * Add email to pool of emails for current edited record
     */
    addEmailToRecord(): void {
      if (this.ContactEmail && this.validationRules().emailFormatIfEntered(this.ContactEmail) === true) {
        this.editedRecord.emails.push({
          id: null,
          orgId: this.editedRecord.orgId,
          sfid: null,
          email: this.ContactEmail,
          dealerNumber: this.editedRecord.dealerNumber,
          externalId: null,
        } as DealerEmailI);
        this.ContactEmail = '';
      }
    },
    /**
     * Remove email from list
     */
    deleteEmail(emailIndex: number): void {
      this.editedRecord.emails.splice(emailIndex, 1);
    },
    /**
     * Open Dialog method ( this will open dialog for editing new if editedRecord is empty
     * or edit existing if editedRecord contain data from grid)
     */
    openEditDialog(editedRecord?: DealerDataRecordI | undefined): void {
      // Clear DealerEmail field
      this.ContactEmail = '';
      // Clear error box on enter edit
      this.errors.length = 0;
      // Reset validation
      this.resetValidation();
      if (editedRecord === undefined) {
        this.editedRecord = JSON.parse(JSON.stringify(emptyDealerDataRecord)) as DealerDataRecordI;
        // reset data in edited record if option "new dealer"
        this.editActionType = 'INSERT';
        // add current lender org id
        this.editedRecord.orgId = this.lender;
        this.editedRecord.customFieldsValues = {};
        this.editFormDisabled = false;
      } else {
        // assign record from other source and edit
        this.editActionType = 'UPDATE';
        // replace data in edditedRecord with what come in editRecord ( no reference )
        this.editedRecord = JSON.parse(JSON.stringify(editedRecord)) as DealerDataRecordI;
        this.editFormDisabled = !this.editedRecord.orgPreference?.active;
      }
      // Run validation
      this.validate();
      this.dialog = !this.dialog;
    },
    /**
     * Validate Edit Dealer Form
     */
    validate(): number {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).dealerFormGen
          .external()
          .getForm()
          .validate();
      }, 500);
    },
    /**
     * Reset validatioin in Edit Dealer Form
     */
    resetValidation(): number {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).dealerFormGen
          .external()
          .getForm()
          .resetValidation();
      }, 250);
    },
    /**
     * Function preapred  to manage states
     */
    formInputEvent(event: boolean) {
      return event;
    },
    formConfig() {
      return [
        {
          label: 'Dealer Details',
          columns: [
            {
              items: [
                {
                  label: 'Dealer Name',
                  fieldName: 'name',
                  rules: [
                    this.validationRules('Dealer Name').notEmpty,
                    this.validationRules('Dealer Name').maxLengthCheck255,
                  ],
                },
                {
                  label: 'Dealer Status',
                  fieldName: 'dealerStatus',
                  items: this.editedRecordLists.DealerStatus,
                  fieldType: 'select',
                },
                {
                  label: 'Dealer EIN',
                  fieldName: 'dealerEIN',
                  vMask: '##-#######',
                  rules: [
                    this.validationRules('Dealer EIN').notEmpty,
                    this.validationRules('Dealer EIN').dealerEINFormat,
                  ],
                },
                {
                  label: 'Dealer Phone',
                  fieldName: 'dealerPhone',
                  rules: [this.validationRules('Dealer Phone').phoneFormatIfEntered],
                  vMask: '(###) ###-####',
                },
              ],
            },
            {
              items: [
                {
                  label: 'Dealer Number',
                  fieldName: 'dealerNumber',
                  rules: [this.validationRules('Dealer Number').notEmpty],
                },
                {
                  label: 'Communication Preference',
                  fieldName: 'communicationPreference',
                  fieldType: 'select',
                  items: this.editedRecordLists.CommunicationPreference,
                  rules: [this.validationRules('Cancelation Communication Preference').notEmpty],
                },
                {
                  label: 'Dealer Reserve',
                  fieldName: 'dealerReserve',
                  items: this.editedRecordLists.DealerReserve,
                  fieldType: 'select',
                },
                {
                  label: 'Do Not Send Notification',
                  fieldName: 'doNotSendNotification',
                  fieldType: 'checkbox',
                  customTooltip: 'Will suppress the sending of a cancellation notice to the dealership.',
                  rules: [],
                },
                {
                  label: 'Dealer Fax',
                  fieldName: 'dealerFax',
                  vMask: '(###) ###-####',
                  rules: [
                    this.validationRules('Dealer Fax').checkFaxIfRequired,
                    this.validationRules('Dealer Fax').phoneFormatIfEntered,
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Address',
          columns: [
            {
              items: [
                {
                  label: 'Dealer Street',
                  fieldName: 'dealerStreet',
                  rules: [this.validationRules('Dealer Street').maxLengthCheck255],
                },
                {
                  label: 'Dealer State',
                  fieldName: 'dealerState',
                  rules: [this.validationRules('Dealer State').notEmpty],
                  fieldType: 'autocomplete',
                  items: this.editedRecordLists.DealerState,
                },
                {
                  label: 'Dealer Country',
                  fieldName: 'dealerCountry',
                  rules: [this.validationRules('Dealer Country').notEmpty],
                  items: this.editedRecordLists.DealerCountry,
                  fieldType: 'autocomplete',
                },
              ],
            },
            {
              items: [
                {
                  label: 'Dealer City',
                  fieldName: 'dealerCity',
                  rules: [this.validationRules('Dealer City').maxLengthCheck100],
                },
                {
                  label: 'Dealer ZIP',
                  fieldName: 'dealerZip',
                  rules: [this.validationRules('Dealer Zip').maxLengthCheck10],
                },
              ],
            },
          ],
        },
        {
          label: 'Contact Info',
          columns: [
            {
              items: [
                {
                  label: 'Dealer Contact Name',
                  fieldName: 'dealerContactName',
                  rules: [this.validationRules('Dealer Contact Name').maxLengthCheck255],
                },
                {
                  label: 'Dealer Contact Email',
                  fieldName: 'dealerContactEmail',
                  rules: [ValidationService.getValidator('email', 'Dealer Contact Email')],
                },
              ],
            },
            {
              items: [
                {
                  label: 'Dealer Contact Phone',
                  fieldName: 'dealerContactPhone',
                  vMask: '(###) ###-#### ##############################',
                },
                {
                  label: 'Email List',
                  fieldType: 'slot',
                  fieldName: 'emailList',
                },
              ],
            },
          ],
        },
        {
          label: 'Invoice Settings',
          columns: [
            {
              items: [
                {
                  label: 'Dealer Invoice',
                  fieldName: 'dealerInvoice',
                  fieldType: 'checkbox',
                  customTooltip:
                    'If checked the Invoice Email(s) will receive an itemized invoice monthly or twice-monthly for outstanding payments due.',
                  rules: [],
                },
              ],
            },
            {
              items: [
                {
                  label: 'Invoice Email',
                  fieldName: 'invoiceEmail',
                  fieldType: 'textTooltip',
                  customTooltip: 'Up to 3 valid email addresses allowed, separated by a semicolon.',
                  rules: [
                    this.editedRecord.dealerInvoice
                      ? this.validationRules('Invoice Email').notEmpty
                      : () => {
                          return true;
                        },
                    ValidationService.getValidator('emailSemiconList', 'Invoice Email'),
                    (value: string | null | undefined) => {
                      if (typeof value !== 'string') {
                        return true;
                      } else {
                        if (value.split(';').length > 3) {
                          return 'Invoice Email cannot contain more than 3 Email Addresses, please update and try again.';
                        }
                      }
                      return true;
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Custom Fields',
          hide: (() => {
            return this.$store.getters.getLenderCustomFields('dealer', this.editedRecord.orgId).length === 0
              ? true
              : false;
          })(),
          columns: [
            {
              items: [
                {
                  label: 'cuustomfields',
                  fieldType: 'customFormField',
                  fieldName: 'customFieldsValues',
                  objectType: 'dealer',
                },
              ],
            },
          ],
        },
        {
          label: 'System Info',
          columns: [
            {
              items: [
                {
                  label: 'Created By:',
                  fieldType: 'audit',
                  fieldNameDate: 'dateTimeCreated',
                  fieldNameString: 'createdByName',
                },
              ],
            },
            {
              items: [
                {
                  label: 'Updated By:',
                  fieldType: 'audit',
                  fieldNameDate: 'dateTimeUpdated',
                  fieldNameString: 'updatedByName',
                },
              ],
            },
          ],
        },
      ];
    },
  },
  computed: {
    /**
     * Shows separate error class if email is required but not added to the list
     */
    emailRestrictionClassError(): string {
      return this.editedRecord.emails.length === 0 && this.editedRecord.communicationPreference === 'Email'
        ? 'red lighten-5'
        : '';
    },
    buttonActions() {
      return [
        {
          name: 'New Dealer ' + (this.lender === '' || this.lender === null ? ' (Select Lender)' : ''),
          icon: 'mdi-plus',
          disabled: this.lender === '' || this.lender === null ? true : false,
          callback: () => {
            this.openEditDialog();
          },
        },
      ];
    },
    /**
     * Defined action for GridComponent
     */
    actions() {
      return [
        {
          icon: 'mdi-pencil',
          tooltip: 'Edit Dealer',
          /**
           *  Callback function for action click
           *  params:
           *  - rowData - grid data for selected row (Object)
           *  - rowIndex - grid store index for selected row (in case of delete or other manipulation on data) (number)
           *  - gridData - grid store data (Array of Objects)
           */
          callback: (rowData: DealerDataRecordI): void => {
            // Put data from grid row to editor
            if (!rowData.customFieldsValues) {
              rowData.customFieldsValues = {};
            }
            this.openEditDialog(rowData);
          },
        },
      ];
    },
  },
});
