import { render, staticRenderFns } from "./lenderPreferencesTab.vue?vue&type=template&id=24578e54&scoped=true&"
import script from "./lenderPreferencesTab.vue?vue&type=script&lang=ts&"
export * from "./lenderPreferencesTab.vue?vue&type=script&lang=ts&"
import style0 from "./lenderPreferencesTab.vue?vue&type=style&index=0&id=24578e54&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24578e54",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VAlert,VCard,VCardText,VCardTitle,VCheckbox,VCol,VContainer,VForm,VIcon,VMenu,VRow,VSelect,VSnackbar,VTextField,VTimePicker})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24578e54')) {
      api.createRecord('24578e54', component.options)
    } else {
      api.reload('24578e54', component.options)
    }
    module.hot.accept("./lenderPreferencesTab.vue?vue&type=template&id=24578e54&scoped=true&", function () {
      api.rerender('24578e54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/lenderDetailsTabs/lenderPreferencesTab.vue"
export default component.exports