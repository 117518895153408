var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-layout",
    {
      attrs: {
        "page-name": "API Partner Log Browser",
        "page-icon": "mdi-format-list-checks"
      }
    },
    [
      _c(
        "div",
        { attrs: { slot: "header_line" }, slot: "header_line" },
        [
          _c(
            "v-snackbar",
            {
              attrs: { centered: "", color: "error", text: "", timeout: 2000 },
              model: {
                value: _vm.snackBarError,
                callback: function($$v) {
                  _vm.snackBarError = $$v
                },
                expression: "snackBarError"
              }
            },
            [
              _c("v-icon", { attrs: { color: "error" } }, [
                _vm._v(" mdi-alert-circle-outline ")
              ]),
              _vm._v(" There was an error downloading the log file ")
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticStyle: { height: "95%" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c("v-progress-linear", {
                            directives: [
                              {
                                name: "visible",
                                rawName: "v-visible",
                                value: _vm.processing,
                                expression: "processing"
                              }
                            ],
                            attrs: {
                              indeterminate: "",
                              query: true,
                              color: "blue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.length > 0,
                          expression: "errors.length > 0"
                        }
                      ]
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "line-height": "15px"
                                  },
                                  attrs: {
                                    type: "error",
                                    border: "left",
                                    text: "",
                                    outlined: "",
                                    dense: "",
                                    autocomplete: "none"
                                  }
                                },
                                [
                                  _c("div", [_c("b", [_vm._v("Errors:")])]),
                                  _vm._l(_vm.errors, function(
                                    error,
                                    errorIndex
                                  ) {
                                    return _c("div", { key: errorIndex }, [
                                      _vm._v("* " + _vm._s(error))
                                    ])
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-form",
                {
                  ref: "aliasForm",
                  staticClass: "shrink-form",
                  attrs: { "lazy-validation": "", disabled: _vm.processing }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass: "details-header",
                          staticStyle: { "font-size": "15px" }
                        },
                        [
                          _vm._v("Search Helper "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticStyle: {
                                                position: "absolute",
                                                top: "15px",
                                                right: "55px",
                                                cursor: "pointer"
                                              },
                                              attrs: {
                                                center: "",
                                                medium: "",
                                                color: "default"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.clearForm()
                                                }
                                              }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-eraser ")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_c("span", [_vm._v("Clear search form")])]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticStyle: {
                                                position: "absolute",
                                                top: "15px",
                                                right: "15px",
                                                cursor: "pointer"
                                              },
                                              attrs: {
                                                center: "",
                                                medium: "",
                                                color: "default"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.refreshForm()
                                                }
                                              }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-refresh ")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_c("span", [_vm._v("Refresh results")])]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("search-input-field", {
                                    attrs: {
                                      fieldProps: {
                                        value: _vm.requestObject.lender
                                          ? _vm.requestObject.lender.name
                                          : "",
                                        readonly: true,
                                        label: "Lender Name"
                                      },
                                      apiMetadataUrl: "/uiapi/grids/lender",
                                      apiDataUrl: "/uiapi/lender/search",
                                      title: "Lender Provider",
                                      columns: [
                                        { text: "Actions", value: "Actions" },
                                        {
                                          text: "Lender Name",
                                          value: "lenderName"
                                        },
                                        { text: "Name", value: "name" }
                                      ],
                                      selectEvent: _vm.selectLender
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.metadataEnumsMap.get(
                                        "endpoint"
                                      ),
                                      label: "Endpoint"
                                    },
                                    model: {
                                      value: _vm.requestObject.endpoint,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.requestObject,
                                          "endpoint",
                                          $$v
                                        )
                                      },
                                      expression: "requestObject.endpoint"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "datePicker",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "return-value": _vm.requestObject.date,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto"
                                      },
                                      on: {
                                        "update:returnValue": function($event) {
                                          return _vm.$set(
                                            _vm.requestObject,
                                            "date",
                                            $event
                                          )
                                        },
                                        "update:return-value": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.requestObject,
                                            "date",
                                            $event
                                          )
                                        }
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        value: _vm._f(
                                                          "formatResults"
                                                        )(
                                                          _vm.requestObject.date
                                                        ),
                                                        label:
                                                          "Select Date Range",
                                                        "prepend-icon":
                                                          "mdi-calendar",
                                                        readonly: ""
                                                      }
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.datePicker.menu,
                                        callback: function($$v) {
                                          _vm.$set(_vm.datePicker, "menu", $$v)
                                        },
                                        expression: "datePicker.menu"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-date-picker",
                                        {
                                          attrs: {
                                            range: "",
                                            "no-title": "",
                                            scrollable: ""
                                          },
                                          model: {
                                            value: _vm.requestObject.date,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.requestObject,
                                                "date",
                                                $$v
                                              )
                                            },
                                            expression: "requestObject.date"
                                          }
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.datePicker.menu = false
                                                }
                                              }
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$refs.datePicker.save(
                                                    _vm.requestObject.date
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" OK ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.metadataEnumsMap.get("status"),
                                      label: "Status"
                                    },
                                    model: {
                                      value: _vm.requestObject.status,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.requestObject,
                                          "status",
                                          $$v
                                        )
                                      },
                                      expression: "requestObject.status"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "4" } }),
                              _c("v-col", { attrs: { cols: "4" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "data-grid",
        _vm._b(
          {
            attrs: {
              slot: "content",
              itemsPerPage: [50, 100, 200, 300, 400, 500],
              itemsPerPageInitial: 100,
              actions: _vm.actions,
              fixedConditions: _vm.fixedConditions
            },
            slot: "content"
          },
          "data-grid",
          _vm.dataGrid,
          false
        )
      ),
      _c(
        "custom-dialog",
        _vm._b(
          { attrs: { slot: "options", eager: "" }, slot: "options" },
          "custom-dialog",
          _vm.customDialogDetails,
          false
        ),
        [
          (_vm.detailRecord
          ? true
          : false)
            ? _c("api-partner-log-details", {
                ref: "apiLogDetails",
                attrs: { slot: "content", data: _vm.detailRecord, eager: "" },
                slot: "content"
              })
            : _vm._e(),
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("API Partner Log Details")
          ]),
          (_vm.detailRecord
          ? true
          : false)
            ? _c(
                "div",
                { attrs: { slot: "buttons" }, slot: "buttons" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3 primary",
                      attrs: { elevation: "2" },
                      on: {
                        click: function($event) {
                          return _vm.downloadFile(
                            _vm.detailRecord.id + "",
                            _vm.detailRecord.logFilePath
                          )
                        }
                      }
                    },
                    [_vm._v(" Download ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", elevation: "2" },
                      on: {
                        click: function($event) {
                          _vm
                            .getRemoteComponent("customDialogDetails")
                            .external()
                            .close()
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }