
















import { Vue } from 'vue-property-decorator';
import PageLayout from '@/components/PageScrollLayout.vue';
import IDPAccountDetails from '@/components/IDP/IDPAccountDetails.vue';
import eventBus from '@/events/EventBus';

export default Vue.extend({
  name: 'IDPPageDetails',
  components: {
    PageLayout,
    IDPAccountDetails,
  },
  /**
   * Page Component data object
   */
  data: () => ({
    id: null as string | null,
    pageTitle: 'IDP',
  }),
  created() {
    if (this.$route.params.id !== undefined) {
      this.id = this.$route.params.id;
    }
    eventBus.$on('IDP_PAGE_TITLE', (title: string) => {
      this.$data.pageTitle = title;
    });
  },
  destroyed() {
    eventBus.$off('IDP_PAGE_TITLE');
  },
});
