



























































































































































































































































import { Vue } from 'vue-property-decorator';
import axios from '../../services/api/Api.service';
import { AxiosResponse } from 'axios';
import { SerchResponse } from '../../../../src/domain/grid/interfaces';

const axiosInstance = new axios().getClient();

import { SearchRequestBodyI, SearchConditionI, DataGridMetaDataI, FieldTypeI } from '../../interfaces';

export default Vue.extend({
  name: 'SfLookupField',
  components: {},
  data() {
    return {
      selection: {
        left: null as null | object,
        right: null as null | object,
        leftMulti: [] as any[],
        rightMulti: [] as any[],
        leftAll: false as boolean,
        rightAll: false as boolean,
      },
      ids: {
        leftSelectAll: this.$getRandomId(),
        rightSelectAll: this.$getRandomId(),
      },
      menu: false as boolean,
      content: this.value || [],
      searchResults: [],
      searchMetadata: null as null | DataGridMetaDataI,
      searchResultsTotalLength: 0,
      searchResultsPage: 1,
      searchResultsPerPage: 50,
      searchResultsSort: [] as string[],
      searchResultsSortDesc: [] as boolean[],
      searchConditions: [] as SearchConditionI[],
      searchQueryLogic: null as null | string,
      search: '' as string,
      searchDelay: null as number | null,
      errors: [] as string[],
      dataTable: {},
    };
  },
  methods: {
    clearSelection() {
      this.content = [];
      this.input();
      this.$forceUpdate();
    },
    setPage(value: number) {
      this.searchResultsPage += value;
      this.loadData(this.getApiCallBody(), this.searchUrl);
    },
    searchInput() {
      setTimeout(() => {
        if (!this.search || this.search.length >= 3 || this.search.length === 0) {
          this.selection.leftMulti.length = 0;
          this.selection.leftAll = false;
          if (this.searchDelay) {
            clearTimeout(this.searchDelay);
          }
          this.searchDelay = setTimeout(() => {
            if ((this.search || []).length === 0) {
              this.searchConditions.length = 0;
              this.searchQueryLogic = null;
            } else {
              this.searchConditions.length = 0;

              if (!this.externalSearchConditions) {
                this.searchConditions.push({
                  comparatorOption: 'contain',
                  fieldName: this.searchField,
                  fixed: false,
                  type: 'String',
                  value: this.search + '',
                });
              } else {
                this.searchQueryLogic = '1 OR 2';
                this.searchConditions.push(
                  ...(this.externalSearchConditions.map((c: SearchConditionI) => {
                    c.value = this.search + '';
                    return c;
                  }) as SearchConditionI[]),
                );
              }
            }
            this.searchResultsPage = 1;
            this.loadData(this.getApiCallBody(), this.searchUrl);
          }, 500);
        }
      }, 1);
    },
    getDisplayName(record: any) {
      return (this.searchFieldPrefix ? record[this.searchFieldPrefix] + '-' : '') + record[this.searchField];
    },
    selectAllAvailableOptions() {
      if (this.selection.leftAll) {
        const allLeftItems = this.searchResults.filter((o: string) => !this.content.find((x: string) => x === o));
        this.selection.leftMulti.length = 0;
        this.selection.leftMulti = this.$getClone(allLeftItems);
      } else {
        this.selection.leftMulti.length = 0;
      }
    },
    selectAllSelectedOptions() {
      if (this.selection.rightAll) {
        this.selection.rightMulti.length = 0;
        this.selection.rightMulti = this.$getClone(this.content);
      } else {
        this.selection.rightMulti.length = 0;
      }
    },
    addSelectedOption(option: any) {
      if (!option) {
        return false;
      } else {
        this.content.push(JSON.parse(JSON.stringify(option)));
        this.selection.left = null;
      }
      this.input();
    },
    addSelectedOptionMulti() {
      if (this.selection.leftMulti.length === 0) {
        return false;
      } else {
        this.content.push(...JSON.parse(JSON.stringify(this.selection.leftMulti)));
        this.selection.leftMulti.length = 0;
        this.selection.leftAll = false;
      }
      this.input();
    },
    removeSelectedOption(option: any) {
      if (!option) {
        return false;
      } else {
        this.content.splice(
          // @ts-ignore: Unreachable code error
          this.content.findIndex((so: { id: number }) => so.id === option.id),
          1,
        );
        this.input();
      }
    },
    removeSelectedOptionMulti() {
      if (this.selection.rightMulti.length === 0) {
        return false;
      } else {
        this.selection.rightMulti.forEach(selectedOptioin => {
          this.content.splice(
            // @ts-ignore: Unreachable code error
            this.content.findIndex((so: { id: number }) => so.id === selectedOptioin.id),
            1,
          );
        });
        this.selection.rightAll = false;
        this.selection.rightMulti.length = 0;
        this.input();
      }
    },
    stopTheEvent: (event: Event) => event.stopPropagation(),
    input() {
      this.$emit('input', this.content);
    },
    getApiCallBody(): SearchRequestBodyI {
      this.searchResultsSort.length = 0;
      this.searchResultsSortDesc.length = 0;
      if (this.searchMetadata && this.searchMetadata.columns) {
        const findNameColumn = this.searchMetadata.columns.find(column => column.value === this.searchField);
        if (findNameColumn) {
          this.searchResultsSort.push(this.searchField);
          this.searchResultsSortDesc.push(false);
        }
      }
      return {
        filterCode: 'All',
        page: this.searchResultsPage,
        rowsPerPage: this.searchResultsPerPage,
        conditions: (() => {
          const allConditions = [] as SearchConditionI[];
          if (this.fixedConditions && this.fixedConditions.length > 0) {
            this.fixedConditions.forEach((condition: SearchConditionI) => {
              allConditions.push({
                // @ts-ignore: Unreachable code error
                fieldName: condition.fieldName,
                // @ts-ignore: Unreachable code error
                type: condition.fieldType,
                // @ts-ignore: Unreachable code error
                comparatorOption: condition.conditionComparatorOption,
                // @ts-ignore: Unreachable code error
                value: condition.value,
                fixed: true,
              });
            });
          }
          if (this.searchConditions.length > 0) {
            allConditions.push(...this.searchConditions);
          }

          return allConditions;
        })(),
        sort: this.searchResultsSort.map((sort, sortIndex) => {
          return {
            fieldName: sort,
            desc: this.searchResultsSortDesc[sortIndex],
          };
        }),
        queryLogic: this.searchQueryLogic,
      };
    },
    async loadMetaData(url?: string | undefined) {
      if (!url) {
        this.error().add(' Missing Search Url');
        return false;
      }
      if (!url) {
        console.error('METADATA: missing url');
      }
      return axiosInstance
        .get<any, AxiosResponse<DataGridMetaDataI>>(url, {})
        .then(res => {
          if (res.data.columns) {
            this.searchMetadata = res.data;
          }
        })
        .catch(error => {
          this.error().add(error + ' (Search Definition)');
        });
    },
    async loadData(requestBody: SearchRequestBodyI | null, url: string) {
      if (!requestBody) {
        return false;
      }
      this.error().clear();

      if (!url) {
        this.error().add(' Missing Search Url');
        return false;
      }

      return axiosInstance
        .post<any, AxiosResponse<SerchResponse<any>>>(url, requestBody, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(res => {
          this.searchResults = res.data.data;
          this.searchResultsTotalLength = res.data.totalRows;
        })
        .catch(error => {
          this.error().add(error + ' (Search Process)');
        });
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
  },
  async mounted() {
    if (this.fieldDataType === 'OBJECT_ARRAY') {
      await this.loadMetaData(this.metaDataUrl);
      await this.loadData(this.getApiCallBody(), this.searchUrl);
    }
  },
  computed: {
    getDisplayContent(): string {
      switch (this.fieldDataType) {
        case 'OBJECT_ARRAY':
          if (typeof this.content === 'object') {
            return this.content
              .map((i: object) => {
                // @ts-ignore: Unreachable code error
                return (this.searchFieldPrefix ? i[this.searchFieldPrefix] + '-' : '') + i[this.searchField];
              })
              .join(', ');
          }
          break;
      }
      return '';
    },
  },
  watch: {
    '$props.value'(v) {
      this.content = v;
    },
  },
  props: [
    'searchUrl',
    'metaDataUrl',
    'value',
    'keyField',
    'searchField',
    'searchFieldPrefix',
    'maxHeight',
    'minHeight',
    'minWidth',
    'maxWidth',
    'fieldDataType' as string,
    'multiselect',
    'fixedConditions',
    'readonly',
    'externalSearchConditions',
  ],
});
