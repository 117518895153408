



































































































































































import { Component, Prop, Vue, PropSync, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component({
  components: {},
})
export default class ComplianceTracker extends Vue {
  @PropSync('ProductRecord') productRecordSync!: any;
  @PropSync('ProductRecordBackup') productRecordBackupSync!: any;
  @Prop([String]) readonly orgId: boolean | undefined;
  @Prop([Boolean]) readonly readonly: boolean | undefined;
  @Prop([Boolean]) readonly disabled: boolean | undefined;
  statuses: any;
  productRecordSyncBackup: any;
  data() {
    return {
      ready: false,
      statuses: ['In-Progress', 'Non-Compliant', 'Compliant', null],
      paymentReceivedOverrideDateSwitch: false as boolean,
      refundedOverrideDateSwitch: false as boolean,
      processing: false,
    };
  }
  async mounted() {
    this.$data.ready = true;
  }
  created() {
    // Set locks in proper position based on data
    if (this.productRecordSync.refundedOverrideDate) {
      this.$data.refundedOverrideDateSwitch = true;
    }
    if (this.productRecordSync.paymentReceivedOverrideDate) {
      this.$data.paymentReceivedOverrideDateSwitch = true;
    }
  }
  formatDateIfNotNull(date: string | null) {
    return date;
  }
  save() {
    if (this.productRecordSync.id) {
      this.$emit('saveData');
    }
  }
  getSaveButtonClass(variable: 'refundedOverrideDate' | 'paymentReceivedOverrideDate') {
    return this.record()[variable] !== this.productRecordBackupSync[variable] ? 'show-save' : '';
  }
  getFormattedDate(date: string) {
    return date ? moment(date).format('MM/DD/YY') : date;
  }
  getStatusTranslation(id: number | string) {
    if (typeof id === 'number') {
      return this.statuses[id];
    } else {
      return this.statuses.findIndex((s: any) => s == id);
    }
  }
  getComplianceTrackerStatus() {
    let output = 4;
    /**
     *  IF Date Refunded is blank AND Days Remaining is positive,
     *  THEN Compliance Tracker bar will be grey and it will state “In-Progress”
     */
    if (!this.record().refundedDate && this.record().remainingDays > -1) {
      output = 0;
    }
    /**
     * IF Date Refunded is blank AND Days Remaining is negative,
     * THEN Compliance Tracker banner will be red and state “Non-Compliant”
     */
    if (!this.record().refundedDate && this.record().remainingDays < 0) {
      output = 1;
    }
    /**
     * IF Date Refunded is populated AND Days Remaining is negative,
     * THEN Compliance Tracker banner will be red and state “Non-Compliant”
     */
    if (this.record().refundedDate && this.record().remainingDays < 0) {
      output = 1;
    }
    /**
     * IF Date Refunded is populated AND Days Remaining is positive,
     *  THEN Compliance Tracker banner will be green and state “Compliant”.
     */
    if (this.record().refundedDate && this.record().remainingDays > -1) {
      output = 2;
    }
    /**
     *  IF there is no Days to Refund Rule,
     *  THEN Compliance Tracker will remain grey and there will be no status.
     */
    if (!this.record().daysToRefundRule) {
      output = 4;
    }
    return this.getStatusTranslation(output);
  }
  convertValue(value: any) {
    return [null, undefined, false, true].indexOf(value) === -1 ? value : '---';
  }
  datePaymentReceivedOverride() {
    this.$data.paymentReceivedOverrideDateSwitch = !this.$data.paymentReceivedOverrideDateSwitch;
    if (!this.$data.paymentReceivedOverrideDateSwitch) {
      this.productRecordSync.paymentReceivedOverrideDate = null;
    }
  }
  dateRefunded() {
    this.$data.refundedOverrideDateSwitch = !this.$data.refundedOverrideDateSwitch;
    if (!this.$data.refundedOverrideDateSwitch) {
      this.productRecordSync.refundedOverrideDate = null;
    }
  }
  getDaysToRefund() {
    if (this.productRecordSync.daysToRefund) return null;
    return this.productRecordSync.daysToRefund;
  }
  getMessageStatus(value: boolean | undefined | null) {
    return value;
  }
  getMessageClass(status: any) {
    if (['FAIL', 'PASS'].indexOf(status) > -1) {
      return status;
    }
    return '';
  }
  record(): any {
    return {
      // Rule
      daysToRefundRule: (() => {
        if (this.productRecordSync?.refundRuleData) {
          return this.productRecordSync.refundRuleData.name;
        }
        return null;
      })(),
      daysToRefund: this.productRecordSync.daysToRefund,
      daysSinceCancellation: this.productRecordSync.daysSinceCancellation,
      remainingDays: this.productRecordSync.remainingDays,
      totalDaysToRefund: this.productRecordSync.totalDaysToRefund,
      totalDaysToReceivedPayment: this.productRecordSync.totalDaysToReceivedPayment,
      // Dates
      refundedDate: this.productRecordSync.refundedDate,
      refundedOverrideDate: this.productRecordSync.refundedOverrideDate,
      paymentReceivedDate: this.productRecordSync.paymentReceivedDate,
      paymentReceivedOverrideDate: this.productRecordSync.paymentReceivedOverrideDate,
      // Messages
      feeComplianceStatus: this.productRecordSync.feeComplianceStatus,
      feeComplianceDetails: this.productRecordSync.feeComplianceDetails || 'N/A',
      freeLookStatus: this.productRecordSync.freeLookStatus,
      freeLookDetails: this.productRecordSync.freeLookDetails || 'N/A',
    };
  }
}
