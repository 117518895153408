import ApiService from '@/services/api/Api.service';
import { UserI } from '../../interfaces';
import EventBus from '@/events/EventBus';

/**
 *  Views Lists
 */
export const HOME_ACCESS = '/';
export const ABOUT_ACCESS = '/about';
export const ACCOUNTS_ACCESS = '/accounts';
export const PRODUCTS_ACCESS = '/products';
export const DATA_UPLOAD_ACCESS = '/data-upload';
export const DEALERS_ACCESS = '/dealers';
export const PROVIDERS_ACCESS = '/providers';
export const PROVIDER_FORM_ACCESS = '/provider-form';
export const PROVIDER_ALIAS_ACCESS = '/provider-alias';
export const PRODUCT_TYPE_ALIAS = '/product-type-alias';
export const LENDERS_ACCESS = '/lenders';
export const LOG_BROWSER_ACCESS = '/log-browser';
export const API_PARTNER_LOG_BROWSER_ACCESS = '/api-partner-log-browser';
export const INFORMED_LOG_BROWSER_ACCESS = '/informed-log-browser';
export const EXPORT_DOCUMENT_ACCESS = '/export-document';
export const IDP_ACCESS = '/idp';
export const IDP_DETAILS_ACCESS = '/idp-details';
export const SF_LENDER_SERVICE_CONFIG_ACCESS = '/sf-lender-service-config';

/**
 * Actions List
 */
export const PROVIDER_EDIT = 'PROVIDER_EDIT';
export const PROVIDER_FORM_EDIT = 'PROVIDER_FORM_EDIT';
export const ASSIGN_USER_EDIT = 'ASSIGN_USER_EDIT';
export const LENDER_EDIT_INTERFAX = 'LENDER_EDIT_INTERFAX';
export const SF_LENDER_SERVICE_CONFIG_EDIT = 'SF_LENDER_SERVICE_CONFIG_EDIT';

class UserService extends ApiService {
  userData = null as null | UserI;
  rolesFilterTab = {
    'Salesforce Admin': {
      views: [SF_LENDER_SERVICE_CONFIG_ACCESS],
      disabledActions: [] as string[],
    },
    'Salesforce Standard': {
      views: [SF_LENDER_SERVICE_CONFIG_ACCESS],
      disabledActions: [SF_LENDER_SERVICE_CONFIG_EDIT] as string[],
    },
    'Salesforce Employee': {
      views: [SF_LENDER_SERVICE_CONFIG_ACCESS],
      disabledActions: [SF_LENDER_SERVICE_CONFIG_EDIT] as string[],
    },
    Admin: {
      views: [
        HOME_ACCESS,
        ABOUT_ACCESS,
        ACCOUNTS_ACCESS,
        PRODUCTS_ACCESS,
        DEALERS_ACCESS,
        DATA_UPLOAD_ACCESS,
        PROVIDERS_ACCESS,
        PROVIDER_FORM_ACCESS,
        PRODUCT_TYPE_ALIAS,
        LENDERS_ACCESS,
        PROVIDER_ALIAS_ACCESS,
        LOG_BROWSER_ACCESS,
        INFORMED_LOG_BROWSER_ACCESS,
        EXPORT_DOCUMENT_ACCESS,
        SF_LENDER_SERVICE_CONFIG_ACCESS,
        IDP_ACCESS,
        IDP_DETAILS_ACCESS,
        API_PARTNER_LOG_BROWSER_ACCESS,
      ],
      disabledActions: [] as string[],
    },
    Support: {
      views: [
        HOME_ACCESS,
        ABOUT_ACCESS,
        ACCOUNTS_ACCESS,
        PRODUCTS_ACCESS,
        DEALERS_ACCESS,
        DATA_UPLOAD_ACCESS,
        PROVIDERS_ACCESS,
        PROVIDER_FORM_ACCESS,
        LENDERS_ACCESS,
        LOG_BROWSER_ACCESS,
        INFORMED_LOG_BROWSER_ACCESS,
        EXPORT_DOCUMENT_ACCESS,
        IDP_ACCESS,
        IDP_DETAILS_ACCESS,
        API_PARTNER_LOG_BROWSER_ACCESS,
      ],
      disabledActions: [PROVIDER_EDIT, PROVIDER_FORM_EDIT, LENDER_EDIT_INTERFAX] as string[],
    },
    Employee: {
      views: [
        HOME_ACCESS,
        ABOUT_ACCESS,
        ACCOUNTS_ACCESS,
        PRODUCTS_ACCESS,
        DEALERS_ACCESS,
        DATA_UPLOAD_ACCESS,
        PROVIDERS_ACCESS,
        PROVIDER_FORM_ACCESS,
        LOG_BROWSER_ACCESS,
        INFORMED_LOG_BROWSER_ACCESS,
        API_PARTNER_LOG_BROWSER_ACCESS,
        EXPORT_DOCUMENT_ACCESS,
      ],
      disabledActions: [PROVIDER_EDIT, PROVIDER_FORM_EDIT, ASSIGN_USER_EDIT, LENDER_EDIT_INTERFAX] as string[],
    },
  };

  setSfUser() {
    if (this.userData && this.userData.userRole.indexOf('Salesforce') > -1) {
      EventBus.$emit('SfUserLogged', true);
    }
  }

  async getUserData(force?: boolean): Promise<UserI> {
    if (this.userData && !force) {
      this.setSfUser();
      return this.userData;
    }
    return this.axios.get('/uiapi/aws-cognito/status').then(res => {
      this.userData = res.data as UserI;
      this.setSfUser();
      return this.userData;
    });
  }

  setUserData(userData: UserI) {
    this.userData = userData;
  }

  getUserRole(): string {
    if (this.userData) {
      return this.userData?.userRole;
    } else {
      return 'NOT DEFINED';
    }
  }
  checkUserViewAccess(viewName: string): boolean {
    // TEST SF
    if (this.userData?.userRole) {
      if (this.rolesFilterTab[this.userData.userRole].views.indexOf(viewName) > -1) {
        return true;
      }
    }
    return false;
  }
  checkUserActionAccess(actionName: string): boolean {
    if (this.userData?.userRole) {
      if (this.rolesFilterTab[this.userData.userRole].disabledActions.indexOf(actionName) === -1) {
        return true;
      }
    }
    return false;
  }
  getUserId(): string {
    if (this.userData && this.userData?.userId) {
      return this.userData?.userId;
    } else {
      return 'NOT SET';
    }
  }
}

export default new UserService();
