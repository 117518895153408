import ApiService from '@/services/api/Api.service';
import { ConsumerRefund, Response } from '../../interfaces';
import { AxiosResponse } from 'axios';

class ConsumerRefundService extends ApiService {
  async create(data: ConsumerRefund): Promise<Response<ConsumerRefund>> {
    return (
      await this.axios.post<ConsumerRefund, AxiosResponse<Response<ConsumerRefund>>>('/uiapi/consumer-refund', data)
    ).data;
  }
  async update(data: ConsumerRefund): Promise<Response<ConsumerRefund>> {
    return (
      await this.axios.post<ConsumerRefund, AxiosResponse<Response<ConsumerRefund>>>(
        '/uiapi/consumer-refund/' + data.id,
        data,
      )
    ).data;
  }
  async delete(id: number): Promise<Response<ConsumerRefund>> {
    return (
      await this.axios.delete<ConsumerRefund, AxiosResponse<Response<ConsumerRefund>>>('/uiapi/consumer-refund/' + id)
    ).data;
  }
}

export default new ConsumerRefundService();
