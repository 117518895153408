var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        flex: "1",
        height: "100%",
        "flex-direction": "row",
        background: "white"
      }
    },
    [_c("v-container", { staticClass: "pa-2 pt-0 ma-0" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }