








































































































































































import { Vue } from 'vue-property-decorator';
import SfDataGridColumnFilter from '@/components/SFComponents/SfDataGridColumFilter.vue';
import axios from '../../services/api/Api.service';
import { AxiosResponse } from 'axios';
import { SerchResponse } from '../../../../src/domain/grid/interfaces';
import FormatDisplayService from '../../services/FormatDisplay.service';
import {
  SearchRequestBodyI,
  SearchConditionI,
  SubFilterConditionI,
  DataGridMetaDataI,
  DataTableHeaderI,
  ActionI,
} from '../../interfaces';

const axiosInstance = new axios().getClient();

export default Vue.extend({
  name: 'SfDataGrid',
  data() {
    return {
      errors: [] as string[],
      messages: [] as string[],
      loading: false as boolean,
      selectedRows: [],
      store: {
        metadata: null as null | DataGridMetaDataI,
        data: [],
      },
      dataTable: {
        height: null as null | number,
        itemKey: 'id',
        loading: false,
        itemsPerPage: 50,
        footerProps: {
          itemsPerPageOptions: [5, 10, 50, 100],
        },
        options: {
          page: 1,
          itemsPerPage: 50,
          sortBy: [] as string[],
          sortDesc: [] as boolean[],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: false,
        },
        showSelect: false,
        singleSelect: false,
        fixedHeader: true,
        value: [],
        items: [],
        originalItems: [],
        serverItemsLength: 0,
      },
    };
  },
  components: { SfDataGridColumnFilter },
  created() {
    // force from parent sort setup
    if (this.initialSortOrder.length > 0) {
      this.dataTable.options.sortBy = [];
      this.dataTable.options.sortDesc = [];
      for (let sort of this.initialSortOrder as Array<{ fieldName: string; desc: boolean }>) {
        this.dataTable.options.sortBy.push(sort.fieldName);
        this.dataTable.options.sortDesc.push(sort.desc);
      }
    }
    this.initDataGrid();
  },
  async mounted() {
    await this.loadMetaData();
    this.dataTable.options.itemsPerPage = this.itemsPerPageInitial;
    this.dataTable.footerProps.itemsPerPageOptions = this.itemsPerPage as number[];
    await this.loadData();
  },
  filters: {
    filterActions(actionItems: ActionI[]) {
      return actionItems.filter((actionItem: ActionI) => {
        return actionItem.show === undefined || actionItem.show === true ? true : false;
      });
    },
    actionDisabled(action: ActionI, item: {}): boolean {
      if (action.disabled === true) {
        return true;
      }
      if (!action.disabledCondition) {
        return false;
      } else {
        return action.disabledCondition(item);
      }
    },
    actionTooltip(action: ActionI, item: {}): string | undefined {
      if (!action.disabledCondition) {
        return action.tooltip;
      } else {
        return action.disabledCondition(item) ? action.tooltipDisabled : action.tooltip;
      }
    },
  },
  methods: {
    localSortFunction(sortBy: string[], sortDesc: boolean[]) {
      const fieldName = sortBy[0] || null;
      const descSort = sortDesc[0] || null;
      this.$emit('local-sort-trigger', { name: fieldName, desc: descSort });
    },
    stopTheEvent: (event: Event) => event.stopPropagation(),
    getArrayIndexOfRow(cellValue: {}) {
      let rowIndex = null;
      this.dataTable.items.forEach((rowData, rowDataIndex) => {
        if (rowData[this.dataIdField] === cellValue) {
          rowIndex = rowDataIndex;
        }
      });
      return rowIndex;
    },
    convertCellValue(value: string | object | null, header: DataTableHeaderI, row?: { allowableFee: string }) {
      if (header.value === 'allowableFeeType') {
        if (row) {
          return value + ' ' + row.allowableFee;
        } else {
          return value;
        }
      }
      if (header.parse === 'toSimpleJsonToString' && Array.isArray(value)) {
        return value.join(', ');
      }
      if (header.parse === 'toStringFromArrayOfObjectsShowName' && Array.isArray(value)) {
        return value
          .map(object => {
            return object.name;
          })
          .join(', ');
      }
      if (header.parse === 'JsonObjectToString') {
        return JSON.stringify(value);
      }

      if (header.parse === 'JsonObjectToHtmlString') {
        let item: { [key: string]: any } = value as object;
        if (value === 'string') {
          return value;
        }

        let htmlVal = '';
        Object.keys(item).map((key: string) => {
          htmlVal += `<b>${key
            .split('_')
            .map(v => v.charAt(0).toUpperCase() + v.slice(1))
            .join(' ')}: </b> <i>${JSON.stringify(item[key])
            .replaceAll(/</g, '&lt;')
            .replaceAll(/>/g, '&gt;')
            .replace(/"/gi, '')}</i> <br/>`;
        });

        return htmlVal;
      }

      if (typeof header.parse === 'string' && typeof value !== 'object') {
        return FormatDisplayService.getRender(header.parse)(value);
      }

      if (header.parser) {
        // @ts-ignore: Unreachable code error
        return header.parser(value);
      }

      return value;
    },
    getHeaders() {
      const showColumns = this.showColumns as DataTableHeaderI[];
      if (this.store.metadata?.columns) {
        const headers = (showColumns.length === 0 ? this.store.metadata.columns : showColumns)
          .filter(column => {
            if (this.store.metadata?.filterSets.length === 1) {
              // SFGrid does not support multi queue!
              return this.store.metadata?.filterSets[0].columns.find(c => c === column.value) ? true : false;
            } else {
              return true;
            }
          })
          .map(header => {
            if (this.lookups[header.value]) {
              header.lookups = this.lookups[header.value];
            }
            this.store.metadata?.subFilterSets.forEach(subFilterSet => {
              subFilterSet.conditions.forEach((condition: SubFilterConditionI) => {
                // @ts-ignore: Unreachable code error
                if (
                  ['Enum'].indexOf(condition.fieldType) > -1 &&
                  condition.fieldName === header.value &&
                  condition.fieldOptions &&
                  condition.fieldOptions[0] &&
                  // @ts-ignore: Unreachable code error
                  condition.fieldOptions[0].value !== undefined
                ) {
                  header.parser = function(v: string | null | boolean): string {
                    let output: string | boolean = '';
                    if (condition.fieldOptions && condition.fieldOptions.length > 0) {
                      output = v ? v : '';
                      condition.fieldOptions.forEach(fieldOption => {
                        if (typeof fieldOption !== 'string') {
                          // @ts-ignore: Unreachable code error
                          if (fieldOption.value == v) {
                            // @ts-ignore: Unreachable code error
                            output = typeof fieldOption.text === 'string' ? fieldOption.text : v;
                          }
                        }
                      });
                    }
                    // @ts-ignore: Unreachable code error
                    return output;
                  };
                }
              });
            });
            return header;
          });
        if (this.previews !== null) {
          headers.push({
            value: 'Previews',
            text: this.previews.columnName,
            width: 25,
            sortable: false,
            hideFilter: true,
          });
        }
        if (this.actions.length > 0 && headers) {
          headers.unshift({
            value: 'Actions',
            text: 'Actions',
            width: 25,
            sortable: false,
            hideFilter: true,
          });
        }
        return headers;
      }
      return [];
    },
    async initDataGrid() {
      setTimeout(() => {
        this.onResize();
      }, 100);
    },
    getApiCallBody(): SearchRequestBodyI | null {
      if (!this.store.metadata) {
        return null;
      }
      return {
        filterCode: this.store.metadata?.filterSets[0].filterCode,
        returnSampleRecord: this.returnSampleRecord,
        page: this.dataTable.options.page,
        rowsPerPage: this.dataTable.options.itemsPerPage,
        conditions: (() => {
          let allConditions = [] as SearchConditionI[];
          if (this.fixedConditions.length > 0) {
            this.fixedConditions.forEach(condition => {
              allConditions.push({
                // @ts-ignore: Unreachable code error
                fieldName: condition.fieldName,
                // @ts-ignore: Unreachable code error
                type: condition.fieldType,
                // @ts-ignore: Unreachable code error
                comparatorOption: condition.conditionComparatorOption,
                // @ts-ignore: Unreachable code error
                value: condition.value,
                fixed: true,
              });
            });
          }
          // collect data from filter
          let userFiltersConditions = [] as SearchConditionI[];
          if (this.store.metadata.columns) {
            this.getHeaders().forEach(header => {
              if (header.subFilterSelection) {
                userFiltersConditions = userFiltersConditions.concat(
                  header.subFilterSelection.map(i => {
                    if (i.fieldNameSearchProperty) {
                      i.fieldName += '.' + i.fieldNameSearchProperty;
                      delete i.fieldNameSearchProperty;
                    }
                    return i;
                  }),
                );
              }
            });
          }

          return allConditions.concat(userFiltersConditions);
        })(),
        sort: this.dataTable.options.sortBy.map((sort, sortIndex) => {
          return {
            fieldName: sort,
            desc: this.dataTable.options.sortDesc[sortIndex],
          };
        }),
        queryLogic: null,
      };
    },
    async loadData() {
      const requestBody = this.getApiCallBody();
      if (!requestBody) {
        return false;
      }
      const url = this.apiDataUrl;
      this.loading = true;
      this.error().clear();
      if (!url) {
        console.error('DATA: missing url');
      }

      return axiosInstance
        .post<any, AxiosResponse<SerchResponse<any>>>(url, requestBody, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(res => {
          if (!res.data.success) {
            this.error().set(res.data.errors);
          }
          this.dataTable.items = res.data.data;
          this.dataTable.originalItems = res.data.data;
          this.$emit('sf-grid-data-loaded', this.dataTable.items);
          this.dataTable.serverItemsLength = res.data.totalRows;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.error().add(error + ' (Table Data)');
        });
    },
    async loadMetaData(url?: string | undefined) {
      if (!url) {
        url = this.apiMetadataUrl;
      }
      if (!url) {
        console.error('METADATA: missing url');
      }
      return axiosInstance
        .get<any, AxiosResponse<DataGridMetaDataI>>(url, {})
        .then(res => {
          if (res.data.columns) {
            this.store.metadata = res.data as DataGridMetaDataI;
          }
        })
        .catch(error => {
          this.error().add(error + ' (Table Definition)');
        });
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    onResize() {
      if (this.customTableSize === 0) {
        if (this.hideFooter === false) {
          this.dataTable.height =
            window.innerHeight - this.getRemoteComponent('resizableDiv')?.getBoundingClientRect().y - this.footerHeight;
        } else if (this.hideFooter === true && this.footerHeight > 0) {
          this.dataTable.height = window.innerHeight - this.footerHeight;
        } else {
          this.dataTable.height =
            window.innerHeight - this.getRemoteComponent('resizableDiv')?.getBoundingClientRect().y;
        }
      } else {
        this.dataTable.height = this.customTableSize;
      }
    },
    message() {
      const scope = this;
      return {
        set: (messages: string[]) => {
          scope.messages.push(...messages);
          return this;
        },
        add: (message: string) => {
          scope.errors.push(message);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
  },
  watch: {
    fixedConditions: {
      handler(n, o) {
        if (n && JSON.stringify(n) !== JSON.stringify(o)) {
          this.loadData();
        }
      },
      deep: true,
    },
    'dataTable.options': {
      async handler(n) {
        if (this.store.metadata) {
          if (!this.localSort) {
            await this.loadData();
          } else {
            this.localSortFunction(n.sortBy, n.sortDesc);
          }
        }
      },
      deep: true,
    },
  },
  props: {
    rowClick: {
      type: Function,
      default: () => {
        return true;
      },
    },
    showColumns: {
      type: Array,
      default: () => {
        return [];
      },
    },

    disableFilters: {
      type: Boolean,
      default: false,
    },
    itemSelected: {
      type: Function,
      default: () => {
        return true;
      },
    },
    actions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    previews: {
      type: Object,
      default: () => {
        return null;
      },
    },
    orgId: {
      type: String || null,
      default: null,
    },
    dataIdField: {
      type: String,
      default: 'Id',
    },
    autoRefresh: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Boolean,
      default: false,
    },
    returnSampleRecord: {
      type: Boolean,
      default: false,
    },
    localSort: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    customTableSize: {
      type: Number,
      default: 0,
    },
    footerHeight: {
      type: Number,
      default: 60,
    },
    lookups: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
    apiDataUrl: {
      type: String,
      default: 'emptyDataUrl',
    },
    apiMetadataUrl: {
      type: String,
      default: 'emptyMetaDataUrl',
    },
    singleSelect: {
      type: Boolean,
      default: true, // Enable/Disable single row selection mode
    },
    itemsPerPageInitial: {
      type: Number,
      default: 50, //  Default ( initial ) setup for visible rows count
    },
    itemsPerPage: {
      type: Array,
      default: () => {
        return [10, 50, 100]; // rows per page array definition
      },
    },
    fixedConditions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    initialSortOrder: {
      type: Array,
      default: () => {
        return []; // initial sorting for loadData
      },
    },
  },
});
