

















import { Vue } from 'vue-property-decorator';
import ProviderAliasEditGrid from '@/components/ProviderAliasEditGrid.vue';
import PageLayout from '@/components/PageLayout.vue';

export interface ProviderAliasPageI {
  buttonEnabled: boolean;
}

export default Vue.extend({
  name: 'ProviderAlias',
  components: {
    PageLayout,
    ProviderAliasEditGrid,
  },
  /**
   * Page Component data object
   */
  data: (): ProviderAliasPageI => ({
    buttonEnabled: true,
  }),
  methods: {
    // Open edit dialog from ProviderAliasEditGrid
    openEditDialog() {
      this.getRemoteComponent().openEditDialog();
    },
    // Return Access to ProviderAliasEditGrid Component ( all properties, all methods )
    getRemoteComponent(): HTMLFormElement {
      return this.$refs.providerAliasEditGrid as HTMLFormElement;
    },
  },
  computed: {},
  /**
   * Method will run when comoponent is created
   */
  created() {},
});
