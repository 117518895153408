var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    _vm.fieldOperatorSync !== "in" && _vm.fieldOperatorSync !== "not in"
      ? _c(
          "div",
          [
            _c("v-text-field", {
              attrs: {
                outlined: "",
                dense: "",
                "hide-details": _vm.hideDetails,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
                rules: [
                  _vm.services.validationService.getValidator(
                    "required",
                    _vm.fieldDefinitionSync.fieldLabel
                  )
                ]
              },
              on: { keypress: _vm.$keyPressIntegerValidation },
              model: {
                value: _vm.fieldValueSync.value,
                callback: function($$v) {
                  _vm.$set(_vm.fieldValueSync, "value", $$v)
                },
                expression: "fieldValueSync.value"
              }
            })
          ],
          1
        )
      : _vm._e(),
    (_vm.fieldOperatorSync === "in" || _vm.fieldOperatorSync === "not in") &&
    _vm.fieldDefinitionSync.lookupData
      ? _c(
          "div",
          [
            _c("sf-lookup-field", {
              staticClass: "sfl-fix-label",
              attrs: {
                dense: "",
                "hide-details": _vm.hideDetails,
                readonly: _vm.readonly,
                "field-data-type": "OBJECT_ARRAY",
                "key-field": "id",
                "search-field":
                  _vm.fieldDefinitionSync.lookupData.searchFieldName,
                "search-url": _vm.fieldDefinitionSync.lookupData.apiDataUrl,
                "meta-data-url":
                  _vm.fieldDefinitionSync.lookupData.apiMetadataUrl,
                orgId: _vm.orgId,
                fixedConditions: _vm.fieldDefinitionSync.fixedConditions,
                rules: [
                  _vm.services.validationService.getValidator(
                    "required",
                    _vm.fieldDefinitionSync.fieldLabel
                  )
                ]
              },
              model: {
                value: _vm.fieldValueSync.value,
                callback: function($$v) {
                  _vm.$set(_vm.fieldValueSync, "value", $$v)
                },
                expression: "fieldValueSync.value"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }