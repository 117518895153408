





























































































































































































































import { Vue } from 'vue-property-decorator';
import DataGrid from '@/components/DataGrid.vue';
import SearchInputField from '@/components/SearchInputField.vue';
import PageLayout from '@/components/PageLayout.vue';
import { Lender, Provider, ApiLogI, Product } from '../interfaces';
import FormatDisplayService from '../services/FormatDisplay.service';
import ValidationService from '../services/Validations.service';
import CustomDialog from '@/components/CustomDialog.vue';
import ApiLogDetails from '@/components/ApiLogDetails.vue';
import MetadataService, { MetadataType } from '../services/api/Metadata.service';
import axios from '../services/api/Api.service';

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

const apiDownloadLogUrl = '/uiapi/api-logs/download-log/{id}';

/**
 *  Metadata service URL
 */
const metadataEndpointUrl = '/uiapi/grids/api-logs';
/**
 *  Data Search service URL
 */
const dataEndpointUrl = '/uiapi/api-logs/search';

export default Vue.extend({
  name: 'ApiLogsPage',
  components: {
    DataGrid,
    PageLayout,
    SearchInputField,
    CustomDialog,
    ApiLogDetails,
  },
  /**
   * Page Component data object
   */
  data: () => ({
    lender: null as null | string,
    service: MetadataService,
    snackBarError: false,
    metadataEnumsMap: new Map(),
    ValidationService: ValidationService,
    detailRecord: null as {} | null,
    processing: false as boolean,
    errors: [] as string[],
    customDialogDetails: {
      ref: 'customDialogDetails',
    },
    datePicker: {
      menu: false,
    },
    dataGrid: {
      apiMetadataUrl: metadataEndpointUrl,
      apiDataUrl: dataEndpointUrl,
      ref: 'dataGrid',
      hideFilterSetup: true,
      headersAutoParserMapping: true,
      listViewHide: true,
      buttonActionsHide: true,
    },
    requestObject: {
      provider: null as Provider | null,
      product: {} as Product,
      lender: null as Lender | null,
      vin: null as string | null,
      date: null as string | null,
      productId: null as number | null,
      accountNumber: null as number | null,
      apiProcessName: null as string | null,
      productType: null as string | null,
      status: null as string | null,
      endpoint: null as string | null,
      dateTimeCreated: [] as string[],
      productExternalId: null as number | null,
    },
    accountDetails: {
      accountData: {},
    },
    fixedConditions: [] as {}[],
    searchDelay: null as null | number,
  }),
  filters: {
    formatResults(dateArray: string[] | null) {
      if (dateArray) {
        let dateFrom = null as string | null;
        let dateTo = null as string | null;
        if (dateArray.length > 0) {
          dateFrom = FormatDisplayService.getRender('toDate')(dateArray[0]);
        }
        if (dateArray.length === 1) {
          return dateFrom;
        }
        if (dateArray.length === 2) {
          dateTo = FormatDisplayService.getRender('toDate')(dateArray[1]);
        }

        return dateFrom + ' - ' + dateTo;
      }
      return '';
    },
  },
  async created() {
    // Refresh grid component in case of lost height
    setTimeout(() => {
      this.getRemoteComponent('dataGrid').onResize();
    }, 100);
    this.metadataEnumsMap = await this.service.loadData(MetadataType.API_LOGS);
  },
  watch: {
    '$store.getters.lender': {
      handler() {
        this.lender = this.$store.getters.lender as string;
        this.fixedConditions = this.getFixedConditions();
      },
    },
    requestObject: {
      handler() {
        if (this.searchDelay) {
          clearTimeout(this.searchDelay);
        }
        this.searchDelay = setTimeout(() => {
          this.fixedConditions = this.getFixedConditions();
        }, 500);
      },
      deep: true,
    },
  },
  computed: {
    actions() {
      return [
        {
          icon: 'mdi-table-cog',
          tooltip: 'Log Details',
          callback: (rowData: ApiLogI): void => {
            this.detailRecord = null;
            setTimeout(() => {
              this.detailRecord = rowData;
              this.getRemoteComponent('customDialogDetails')
                ?.external()
                .open();
            }, 100);
          },
        },
        {
          icon: 'mdi-download',
          tooltip: 'Download Logs',
          callback: (rowData: ApiLogI): void => {
            this.downloadFile(rowData.id + '', rowData.VIN + '_' + rowData.id);
          },
        },
      ];
    },
  },
  methods: {
    async downloadFile(id: string, fieldName?: string): Promise<void> {
      const url = apiDownloadLogUrl.replace('{id}', id);
      return axiosInstance
        .get<Blob>(url, { responseType: 'blob' })
        .then(response => {
          if (response.data.size === 0) {
            this.snackBarError = true;
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fieldName ? fieldName + '.json' : 'api_log.json');
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        })
        .catch(() => {
          this.snackBarError = true;
        });
    },
    refreshForm() {
      this.getRemoteComponent('dataGrid').submitDataRequest();
    },
    clearForm() {
      this.requestObject = {
        provider: null,
        product: {} as Product,
        lender: null,
        vin: null,
        date: null,
        productId: null,
        accountNumber: null,
        apiProcessName: null,
        productType: null,
        status: null,
        endpoint: null,
        dateTimeCreated: [],
        productExternalId: null,
      };
    },
    getFixedConditions() {
      let fixedConditions = [];

      if (typeof this.lender === 'string' && this.lender !== '') {
        fixedConditions.push({
          fieldName: 'orgId',
          value: this.lender,
          fieldType: 'string',
          conditionComparatorOption: 'equal',
        });
      }

      if (this.requestObject.vin) {
        fixedConditions.push({
          fieldName: 'VIN',
          value: this.requestObject.vin,
          fieldType: 'string',
          conditionComparatorOption: 'equal',
        });
      }
      if (this.requestObject.provider) {
        fixedConditions.push({
          fieldName: 'providerName',
          value: this.requestObject.provider.name,
          fieldType: 'string',
          conditionComparatorOption: 'equal',
        });
      }
      if (this.requestObject.lender) {
        fixedConditions.push({
          fieldName: 'orgId',
          value: this.requestObject.lender.orgId,
          fieldType: 'string',
          conditionComparatorOption: 'equal',
        });
      }
      if (this.requestObject.product.id) {
        fixedConditions.push({
          fieldName: 'product.id',
          value: this.requestObject.product.id,
          fieldType: 'string',
          conditionComparatorOption: 'equal',
        });
      }
      if (this.requestObject.product.accountNumber) {
        fixedConditions.push({
          fieldName: 'product.accountNumber',
          value: this.requestObject.product.accountNumber,
          fieldType: 'string',
          conditionComparatorOption: 'equal',
        });
      }
      if (this.requestObject.date?.length === 2) {
        fixedConditions.push({
          fieldName: 'dateTimeCreated',
          value: this.requestObject.date[0],
          fieldType: 'Date',
          conditionComparatorOption: 'greater than or equal',
        });
        fixedConditions.push({
          fieldName: 'dateTimeCreated',
          value: this.requestObject.date[1],
          fieldType: 'Date',
          conditionComparatorOption: 'less than or equal to',
        });
      }
      if (this.requestObject.productType) {
        fixedConditions.push({
          fieldName: 'productType',
          value: this.requestObject.productType,
          fieldType: 'string',
          conditionComparatorOption: 'equal',
        });
      }
      if (this.requestObject.status) {
        fixedConditions.push({
          fieldName: 'status',
          value: this.requestObject.status,
          fieldType: 'string',
          conditionComparatorOption: 'equal',
        });
      }
      if (this.requestObject.apiProcessName) {
        fixedConditions.push({
          fieldName: 'apiProcessName',
          value: this.requestObject.apiProcessName,
          fieldType: 'string',
          conditionComparatorOption: 'equal',
        });
      }

      return fixedConditions;
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    selectProvider(provider: {}) {
      this.requestObject.provider = provider as Provider;
    },
    selectLender(lender: {}) {
      this.requestObject.lender = lender as Lender;
    },
  },
});
