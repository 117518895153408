import { DaysToRefundI } from '@/interfaces/days-to-refund.interface';
const DaysToRefundConfigurationRecordM = {
  id: null,
  orgId: null,
  sfid: null,
  orgPreference: null,
  active: false,
  name: null,
  externalId: null,
  dealerStates: [],
  cancelTypes: [],
  productTypes: [],
  providers: [],
  dealers: [],
  sendTo: null,
  effectiveDateType: 'AllTime',
  effectiveDate: null,
  startDate: null,
  endDate: null,
  createdDate: null,
  createdBy: null,
  createdById: null,
  daysToRefund: null,
  dateTimeUpdated: null,
  updatedBy: null,
  updatedById: null,
  createdByName: null,
  updatedByName: null,
  managementRuleParameters: [
    {
      type: 'Dealer',
      fieldName: 'dealerState',
      fieldLabel: 'Dealer State(s)',
      customField: false,
      fieldType: 'Enum',
      fieldId: 'Dealer@dealerState',
      orgId: '00D010000008b7ZEAQ',
      fieldOptions: {
        Alabama: 'AL',
        Alaska: 'AK',
        AmericanForcesAmericas: 'AA',
        AmericanForcesEurope: 'AE',
        AmericanForcesPacific: 'AP',
        AmericanSamoa: 'AS',
        Arizona: 'AZ',
        Arkansas: 'AR',
        California: 'CA',
        Colorado: 'CO',
        Connecticut: 'CT',
        Delaware: 'DE',
        DistrictOfColumbia: 'DC',
        FederatedStatesOfMicronesia: 'FM',
        Florida: 'FL',
        Georgia: 'GA',
        Guam: 'GU',
        Hawaii: 'HI',
        Idaho: 'ID',
        Illinois: 'IL',
        Indiana: 'IN',
        Iowa: 'IA',
        Kansas: 'KS',
        Kentucky: 'KY',
        Louisiana: 'LA',
        Maine: 'ME',
        MarshallIslands: 'MH',
        Maryland: 'MD',
        Massachusetts: 'MA',
        Michigan: 'MI',
        Minnesota: 'MN',
        Mississippi: 'MS',
        Missouri: 'MO',
        Montana: 'MT',
        Nebraska: 'NE',
        Nevada: 'NV',
        NewHampshire: 'NH',
        NewJersey: 'NJ',
        NewMexico: 'NM',
        NewYork: 'NY',
        NorthCarolina: 'NC',
        NorthDakota: 'ND',
        NorthernMarianaIslands: 'MP',
        Ohio: 'OH',
        Oklahoma: 'OK',
        Oregon: 'OR',
        Palau: 'PW',
        Pennsylvania: 'PA',
        PuertoRico: 'PR',
        RhodeIsland: 'RI',
        SouthCarolina: 'SC',
        SouthDakota: 'SD',
        Tennessee: 'TN',
        Texas: 'TX',
        Utah: 'UT',
        Vermont: 'VT',
        VirginIslands: 'VI',
        Virginia: 'VA',
        Washington: 'WA',
        WestVirginia: 'WV',
        Wisconsin: 'WI',
        Wyoming: 'WY',
      },
      lookupData: '',
      operators: [
        {
          text: 'equal',
          value: 'equal',
        },
        {
          text: 'in',
          value: 'in',
        },
        {
          text: 'not in',
          value: 'not in',
        },
      ],
      data: {},
      operator: 'equal',
      value: {
        type: 'String',
        value: null,
      },
    },
    {
      type: 'Product',
      fieldName: 'productType',
      fieldLabel: 'Product Type(s)',
      customField: false,
      fieldType: 'Enum',
      fieldId: 'Product@productType',
      orgId: '00D010000008b7ZEAQ',
      fieldOptions: {
        ACCIDENT_AND_HEALTH: 'AH',
        CREDIT_LIFE: 'CL',
        CREDIT_LIFE_AND_DISABILITY: 'CLD',
        DISABILITY: 'DIS',
        ETCH: 'ETCH',
        GUARANTEED_ASSET_PROTECTION: 'GAP',
        KEY_REPLACEMENT: 'KEY',
        MAINTENANCE: 'MNT',
        OTHER: 'OTR',
        PAINTLESS_DENT: 'PD',
        PAINTLESS_DENT_REPAIR: 'PDR',
        THEFT_PROTECTION: 'THF',
        TIRE_AND_WHEEL: 'TW',
        VEHICLE_PROTECTION_PLAN: 'VPP',
        VEHICLE_SERVICE_CONTRACT: 'VSC',
        WARRANTY: 'WAR',
        APPEARANCE_PROTECTION: 'AP',
        EXCESS_WEAR_AND_TEAR: 'EWT',
        ROADSIDE_ASSISTANCE: 'RA',
        BATTERY: 'BAT',
        DEPOSIT_PROTECTION: 'DEP',
        PAINT_AND_FABRIC: 'PAF',
        BUNDLE: 'BUN',
        DEPRECIATION_ASSET_PROTECTION: 'DAP',
        WINDSHIELD: 'WDS',
      },
      lookupData: '',
      operators: [
        {
          text: 'equal',
          value: 'equal',
        },
        {
          text: 'in',
          value: 'in',
        },
        {
          text: 'not in',
          value: 'not in',
        },
      ],
      data: {},
      operator: 'equal',
      value: {
        type: 'String',
        value: null,
      },
    },
    {
      type: 'Product',
      fieldName: 'cancelType',
      fieldLabel: 'Cancel Type(s)',
      customField: false,
      fieldType: 'Enum',
      fieldOptions: {
        CHARGE_OFF: 'CO',
        CUSTOMER_REQUEST: 'CR',
        LIEN_LOSS: 'LL',
        OTHER: 'O',
        PAYOFF: 'P',
        REPOSSESSION: 'R',
        TOTAL_LOSS: 'TL',
      },
      lookupData: '',
      operators: [
        {
          text: 'equal',
          value: 'equal',
        },
        {
          text: 'in',
          value: 'in',
        },
        {
          text: 'not in',
          value: 'not in',
        },
      ],
      data: {},
      fieldId: 'Product@cancelType',
      orgId: '00D010000008b7ZEAQ',
      fieldOptionsConverted: [
        {
          text: 'Charge Off',
          value: 'CO',
        },
        {
          text: 'Customer Request',
          value: 'CR',
        },
        {
          text: 'Lien Loss',
          value: 'LL',
        },
        {
          text: 'Other',
          value: 'O',
        },
        {
          text: 'Payoff',
          value: 'P',
        },
        {
          text: 'Repossession',
          value: 'R',
        },
        {
          text: 'Total Loss',
          value: 'TL',
        },
      ],
      operator: 'equal',
      value: {
        type: 'String',
        value: null,
      },
    },
  ],
  type: 'Refund',
} as DaysToRefundI;

export { DaysToRefundConfigurationRecordM };
