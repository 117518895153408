var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        {
          staticClass: "fit-to-parent product-container",
          staticStyle: { overflow: "hidden", position: "relative" }
        },
        [
          _c(
            "v-form",
            {
              ref: "RefIDPProductForm",
              staticStyle: {
                position: "relative",
                flex: "1",
                display: "flex",
                "flex-direction": "column",
                padding: "0px",
                margin: "0px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "190px",
                    width: "100%",
                    "padding-top": "12px"
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-5", attrs: { cols: "5" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-1" },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          dense: "",
                                          disabled: _vm.disabled,
                                          readonly: _vm.readonly,
                                          items: _vm.productEnums.get(
                                            "productType"
                                          ),
                                          label: "Product Type"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.callParentUpdate()
                                          }
                                        },
                                        model: {
                                          value: _vm.productData.productType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productData,
                                              "productType",
                                              $$v
                                            )
                                          },
                                          expression: "productData.productType"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-1" },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          dense: "",
                                          disabled: _vm.disabled,
                                          readonly: _vm.readonly,
                                          items: _vm.productEnums.get(
                                            "cancelType"
                                          ),
                                          label: "Cancel Type",
                                          rules: [
                                            _vm.validationService.getValidator(
                                              "required",
                                              "Cancel Type"
                                            )
                                          ]
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.callParentUpdate()
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.productData.decisioned
                                              .cancelType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productData.decisioned,
                                              "cancelType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "productData.decisioned.cancelType"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-1" },
                                    [
                                      _c("custom-date-picker", {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          readonly: _vm.readonly,
                                          label: "Lender Cancel Date",
                                          rules: [
                                            _vm.validationService.getValidator(
                                              "required",
                                              "Cancel Type"
                                            )
                                          ]
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.callParentUpdate()
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.productData.decisioned
                                              .lenderCancelDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productData.decisioned,
                                              "lenderCancelDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "productData.decisioned.lenderCancelDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    "x-small": "",
                                    disabled: _vm.disabled
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.productData.notIncluded = !_vm
                                        .productData.notIncluded
                                        ? true
                                        : false
                                      _vm.productData.verified = false
                                      _vm.callParentUpdate()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        left: "",
                                        dark: "",
                                        color: _vm.productData.notIncluded
                                          ? "red"
                                          : "green"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.productData.notIncluded
                                              ? "mdi-checkbox-outline"
                                              : "mdi-checkbox-blank-outline"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm._v(" " + _vm._s("Do not Include") + " ")
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    disabled: _vm.disabled,
                                    "x-small": ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.switchProductToVerified()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        left: "",
                                        dark: "",
                                        color: _vm.productData.verified
                                          ? "green"
                                          : "#888"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.productData.verified
                                              ? "mdi-checkbox-outline"
                                              : "mdi-checkbox-blank-outline"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm._v(" Verified ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "1",
                    display: "flex",
                    "flex-direction": "column",
                    position: "relative"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pa-5 pt-2 pb-2" },
                        [
                          _c(
                            "v-row",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c("v-col", [_vm._v("Field Name")]),
                              _c("v-col", [_vm._v("Submitted")]),
                              _c("v-col", [_vm._v("Extracted")]),
                              _c("v-col", [_vm._v("Decisioned")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "1",
                        width: "100%",
                        position: "relative",
                        overflow: "hidden"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "fit-to-parent",
                          staticStyle: {
                            overflow: "hidden",
                            "overflow-y": "auto"
                          }
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "pa-5" },
                            _vm._l(_vm.getFields(), function(
                              fieldName,
                              fieldNameIndex
                            ) {
                              return _c(
                                "v-row",
                                { key: fieldNameIndex },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: { "white-space": "nowrap" },
                                      attrs: { cols: "3" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.camelCaseToTitleCase(fieldName)
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c(
                                        "v-text-field",
                                        {
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            disabled: "",
                                            value: _vm.formatReadOnlyData(
                                              _vm.productData.submitted[
                                                fieldName
                                              ],
                                              fieldName
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "cursor-pointer",
                                              attrs: { slot: "append" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.copyToDecisioned(
                                                    _vm.productData,
                                                    "submitted",
                                                    fieldName
                                                  )
                                                }
                                              },
                                              slot: "append"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: _vm.getColorIfSelected(
                                                      _vm.productData,
                                                      "submitted",
                                                      fieldName
                                                    )
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " mdi-arrow-right-bold-box "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c(
                                        "v-text-field",
                                        {
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            disabled: "",
                                            value: _vm.formatReadOnlyData(
                                              _vm.productData.extracted[
                                                fieldName
                                              ],
                                              fieldName
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "cursor-pointer",
                                              attrs: { slot: "append" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.copyToDecisioned(
                                                    _vm.productData,
                                                    "extracted",
                                                    fieldName
                                                  )
                                                }
                                              },
                                              slot: "append"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: _vm.getColorIfSelected(
                                                      _vm.productData,
                                                      "extracted",
                                                      fieldName
                                                    )
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " mdi-arrow-right-bold-box "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  !_vm.nonTextFields.includes(fieldName)
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          fieldName.indexOf("Date") === -1
                                            ? _c("v-text-field", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  readonly: _vm.readonly,
                                                  dense: "",
                                                  "hide-details": "",
                                                  value: "12",
                                                  clearable: "",
                                                  type: _vm.numericFields.includes(
                                                    fieldName
                                                  )
                                                    ? "number"
                                                    : "text"
                                                },
                                                model: {
                                                  value:
                                                    _vm.productData.decisioned[
                                                      fieldName
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.productData
                                                        .decisioned,
                                                      fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "productData.decisioned[fieldName]"
                                                }
                                              })
                                            : _vm._e(),
                                          fieldName.indexOf("Date") > -1
                                            ? _c("custom-date-picker", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  readonly: _vm.readonly,
                                                  dense: "",
                                                  "hide-details": "true"
                                                },
                                                model: {
                                                  value:
                                                    _vm.productData.decisioned[
                                                      fieldName
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.productData
                                                        .decisioned,
                                                      fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "productData.decisioned[fieldName]"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  fieldName === "providerName"
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c("search-input-field", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              readonly: _vm.readonly,
                                              fieldProps: {
                                                value:
                                                  _vm.productData.decisioned
                                                    .providerName,
                                                readonly: true,

                                                dense: true,
                                                hideDetails: true
                                              },
                                              actions: [],
                                              apiMetadataUrl:
                                                "/uiapi/grids/provider",
                                              apiDataUrl:
                                                "/uiapi/provider/search",
                                              title: "Search Provider",
                                              columns: [
                                                { text: "Name", value: "name" }
                                              ],
                                              selectEvent: _vm.selectProvider
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  fieldName === "cancelType"
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              readonly: _vm.readonly,
                                              dense: "",
                                              "hide-details": "",
                                              items: _vm.productEnums.get(
                                                "cancelType"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.callParentUpdate()
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.productData.decisioned
                                                  .cancelType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.productData.decisioned,
                                                  "cancelType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productData.decisioned.cancelType"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }