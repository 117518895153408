var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-shrink",
      staticStyle: {
        flex: "1",
        background: "white",
        position: "relative",
        padding: "5px",
        "margin-top": "-100px",
        "margin-bottom": "-100px"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "PathFormComponent",
          attrs: { readonly: _vm.readonly },
          model: {
            value: _vm.formValid,
            callback: function($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid"
          }
        },
        [
          _c(
            "div",
            {},
            [
              _c("v-progress-linear", {
                directives: [
                  {
                    name: "visible",
                    rawName: "v-visible",
                    value: _vm.progress,
                    expression: "progress"
                  }
                ],
                attrs: { indeterminate: "", color: "#0176d3" }
              }),
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.messages.length > 0,
                      expression: "messages.length > 0"
                    }
                  ],
                  staticClass: "confirmation-message"
                },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-alert",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "line-height": "15px"
                              },
                              attrs: {
                                type: "info",
                                border: "left",
                                text: "",
                                outlined: "",
                                dense: "",
                                autocomplete: "none"
                              }
                            },
                            [
                              _c("div", { staticClass: "mb-1" }, [
                                _c("b", [_vm._v("Mesagess:")])
                              ]),
                              _vm._l(_vm.messages, function(
                                message,
                                messageIndex
                              ) {
                                return _c("div", { key: messageIndex }, [
                                  _vm._v("* " + _vm._s(message))
                                ])
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.length > 0,
                      expression: "errors.length > 0"
                    }
                  ],
                  staticClass: "error-message"
                },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-alert",
                            {
                              staticStyle: {
                                "font-weight": "strong",
                                "font-size": "14px",
                                "line-height": "15px"
                              },
                              attrs: {
                                type: "error",
                                border: "left",
                                text: "",
                                outlined: "",
                                dense: ""
                              }
                            },
                            [
                              _c("div", { staticClass: "mb-1" }, [
                                _c("b", [_vm._v("Errors:")])
                              ]),
                              _vm._l(_vm.errors, function(error, errorIndex) {
                                return _c("div", {
                                  key: errorIndex,
                                  domProps: { innerHTML: _vm._s("* " + error) }
                                })
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-switch", {
                                staticClass: "mt-1",
                                attrs: { dense: "", label: "Active" },
                                model: {
                                  value: _vm.content.active,
                                  callback: function($$v) {
                                    _vm.$set(_vm.content, "active", $$v)
                                  },
                                  expression: "content.active"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "sfl-fix-label" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Path Name",
                                  outlined: "",
                                  dense: "",
                                  "persistent-placeholder": ""
                                },
                                model: {
                                  value: _vm.content.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.content, "name", $$v)
                                  },
                                  expression: "content.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "sfl-fix-label" },
                            [
                              _vm.metadataEnumsMap.get("dealerStates")
                                ? _c("sf-enum-field", {
                                    attrs: {
                                      readonly: _vm.readonly,
                                      label: "Dealer State",
                                      "field-data-type": "ARRAY",
                                      "key-field": "value",
                                      "search-field": "text",
                                      items: _vm.metadataEnumsMap
                                        .get("dealerStates")
                                        .map(function(o) {
                                          return o.value
                                        }),
                                      itemsObjects: _vm.metadataEnumsMap.get(
                                        "dealerStates"
                                      ),
                                      rules:
                                        _vm.content.cancelTypes === null ||
                                        _vm.content.cancelTypes.length === 0
                                          ? [
                                              _vm.ValidationService.getValidator(
                                                "required",
                                                "States"
                                              )
                                            ]
                                          : []
                                    },
                                    model: {
                                      value: _vm.content.dealerStates,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.content,
                                          "dealerStates",
                                          $$v
                                        )
                                      },
                                      expression: "content.dealerStates"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "sfl-fix-label" },
                            [
                              _vm.metadataEnumsMap.get("cancelTypes")
                                ? _c("sf-enum-field", {
                                    attrs: {
                                      readonly: _vm.readonly,
                                      label: "Cancel Type",
                                      "field-data-type": "ARRAY",
                                      "key-field": "value",
                                      "search-field": "text",
                                      items: _vm.metadataEnumsMap
                                        .get("cancelTypes")
                                        .map(function(o) {
                                          return o.value
                                        }),
                                      itemsObjects: _vm.metadataEnumsMap.get(
                                        "cancelTypes"
                                      ),
                                      rules:
                                        _vm.content.dealerStates === null ||
                                        _vm.content.dealerStates.length === 0
                                          ? [
                                              _vm.ValidationService.getValidator(
                                                "required",
                                                "Cancel Type"
                                              )
                                            ]
                                          : []
                                    },
                                    model: {
                                      value: _vm.content.cancelTypes,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.content,
                                          "cancelTypes",
                                          $$v
                                        )
                                      },
                                      expression: "content.cancelTypes"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "sfl-fix-label" },
                            [
                              _vm.metadataEnumsMap.get("productTypes")
                                ? _c("sf-enum-field", {
                                    attrs: {
                                      readonly: _vm.readonly,
                                      label: "Product Type",
                                      "field-data-type": "ARRAY",
                                      "key-field": "value",
                                      "search-field": "text",
                                      items: _vm.metadataEnumsMap
                                        .get("productTypes")
                                        .map(function(o) {
                                          return o.value
                                        }),
                                      itemsObjects: _vm.metadataEnumsMap.get(
                                        "productTypes"
                                      )
                                    },
                                    model: {
                                      value: _vm.content.productTypes,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.content,
                                          "productTypes",
                                          $$v
                                        )
                                      },
                                      expression: "content.productTypes"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "sfl-fix-label" },
                            [
                              _c("sf-lookup-field", {
                                staticClass: "sfl-fix-label",
                                attrs: {
                                  readonly: _vm.readonly,
                                  label: "Provider Name",
                                  "field-data-type": "OBJECT_ARRAY",
                                  "key-field": "id",
                                  "search-field": "name",
                                  "search-url": "/uiapi/provider/search",
                                  "meta-data-url": "/uiapi/grids/provider"
                                },
                                model: {
                                  value: _vm.content.providers,
                                  callback: function($$v) {
                                    _vm.$set(_vm.content, "providers", $$v)
                                  },
                                  expression: "content.providers"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "sfl-fix-label" },
                            [
                              _c("sf-lookup-field", {
                                staticClass: "sfl-fix-label",
                                attrs: {
                                  readonly: _vm.readonly,
                                  label: "Dealer Name",
                                  "field-data-type": "OBJECT_ARRAY",
                                  "key-field": "id",
                                  "search-field": "name",
                                  "search-field-prefix": "dealerNumber",
                                  "search-url": "/uiapi/dealer/search",
                                  "meta-data-url": "/uiapi/grids/dealer",
                                  fixedConditions: [
                                    {
                                      fieldName: "orgId",
                                      value: _vm.orgId,
                                      fieldType: "string",
                                      conditionComparatorOption: "equal"
                                    }
                                  ],
                                  externalSearchConditions: [
                                    {
                                      comparatorOption: "contain",
                                      fieldName: "name",
                                      fixed: false,
                                      type: "String",
                                      value: null
                                    },
                                    {
                                      comparatorOption: "equal",
                                      fieldName: "dealerNumber",
                                      fixed: false,
                                      type: "String",
                                      value: null
                                    }
                                  ]
                                },
                                model: {
                                  value: _vm.content.dealers,
                                  callback: function($$v) {
                                    _vm.$set(_vm.content, "dealers", $$v)
                                  },
                                  expression: "content.dealers"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "sfl-fix-label" },
                            [
                              _vm.metadataEnumsMap.get("businessChannelTypes")
                                ? _c("sf-enum-field", {
                                    attrs: {
                                      readonly: _vm.readonly,
                                      label: "Business Channel",
                                      "field-data-type": "ARRAY",
                                      "key-field": "value",
                                      "search-field": "text",
                                      items: _vm.metadataEnumsMap
                                        .get("businessChannelTypes")
                                        .map(function(o) {
                                          return o.value
                                        }),
                                      itemsObjects: _vm.metadataEnumsMap.get(
                                        "businessChannelTypes"
                                      )
                                    },
                                    model: {
                                      value: _vm.content.businessChannelTypes,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.content,
                                          "businessChannelTypes",
                                          $$v
                                        )
                                      },
                                      expression: "content.businessChannelTypes"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  border: "1px solid #ddd",
                                  height: "160px",
                                  padding: "3px",
                                  overflow: "hidden",
                                  "overflow-y": "auto",
                                  "border-radius": "3px",
                                  color: "999"
                                }
                              },
                              _vm._l(_vm.content.dealerStates, function(
                                dealerState,
                                dealerStateIndex
                              ) {
                                return _c("div", { key: dealerStateIndex }, [
                                  _vm._v(" " + _vm._s(dealerState) + " ")
                                ])
                              }),
                              0
                            )
                          ]),
                          _c("v-col", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  border: "1px solid #ddd",
                                  height: "160px",
                                  padding: "3px",
                                  overflow: "hidden",
                                  "overflow-y": "auto",
                                  "border-radius": "3px",
                                  color: "999"
                                }
                              },
                              _vm._l(_vm.content.cancelTypes, function(
                                cancelType,
                                cancelTypeIndex
                              ) {
                                return _c("div", { key: cancelTypeIndex }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("extendCancelType")(cancelType)
                                      ) +
                                      " "
                                  )
                                ])
                              }),
                              0
                            )
                          ]),
                          _c("v-col", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  border: "1px solid #ddd",
                                  height: "160px",
                                  padding: "3px",
                                  overflow: "hidden",
                                  "overflow-y": "auto",
                                  "border-radius": "3px",
                                  color: "999"
                                }
                              },
                              _vm._l(_vm.content.productTypes, function(
                                productType,
                                productTypeIndex
                              ) {
                                return _c("div", { key: productTypeIndex }, [
                                  _vm._v(" " + _vm._s(productType) + " ")
                                ])
                              }),
                              0
                            )
                          ]),
                          _c("v-col", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  border: "1px solid #ddd",
                                  height: "160px",
                                  padding: "3px",
                                  overflow: "hidden",
                                  "overflow-y": "auto",
                                  "border-radius": "3px",
                                  color: "999"
                                }
                              },
                              _vm._l(_vm.content.providers, function(
                                provider,
                                providerIndex
                              ) {
                                return _c("div", { key: providerIndex }, [
                                  _vm._v(" " + _vm._s(provider.name) + " ")
                                ])
                              }),
                              0
                            )
                          ]),
                          _c("v-col", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  border: "1px solid #ddd",
                                  height: "160px",
                                  padding: "3px",
                                  overflow: "hidden",
                                  "overflow-y": "auto",
                                  "border-radius": "3px",
                                  color: "999"
                                }
                              },
                              _vm._l(_vm.content.dealers, function(
                                dealer,
                                dealerIndex
                              ) {
                                return _c("div", { key: dealerIndex }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        dealer.dealerNumber + "-" + dealer.name
                                      ) +
                                      " "
                                  )
                                ])
                              }),
                              0
                            )
                          ]),
                          _c("v-col", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  border: "1px solid #ddd",
                                  height: "160px",
                                  padding: "3px",
                                  overflow: "hidden",
                                  "overflow-y": "auto",
                                  "border-radius": "3px",
                                  color: "999"
                                }
                              },
                              _vm._l(_vm.content.businessChannelTypes, function(
                                businessChannelType,
                                businessChannelTypeIndex
                              ) {
                                return _c(
                                  "div",
                                  { key: businessChannelTypeIndex },
                                  [
                                    _vm._v(
                                      " " + _vm._s(businessChannelType) + " "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "sfl-fix-label" },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.metadataEnumsMap.get(
                                    "effectiveTypeDate"
                                  ),
                                  label: "Effective Date Type",
                                  outlined: "",
                                  dense: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.effectiveDateTypeInput()
                                  }
                                },
                                model: {
                                  value: _vm.content.effectiveDateType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.content,
                                      "effectiveDateType",
                                      $$v
                                    )
                                  },
                                  expression: "content.effectiveDateType"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "sfl-fix-label" },
                            [
                              _c("custom-date-picker", {
                                attrs: {
                                  readonly: _vm.readonly,
                                  outlined: "",
                                  dense: "",
                                  label: "Effective Date",
                                  disabled:
                                    _vm.content.effectiveDateType === "AllTime",
                                  rules:
                                    _vm.content.effectiveDateType !== "AllTime"
                                      ? [
                                          _vm.ValidationService.getValidator(
                                            "required",
                                            "Effective Date"
                                          )
                                        ]
                                      : []
                                },
                                model: {
                                  value: _vm.content.effectiveDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.content, "effectiveDate", $$v)
                                  },
                                  expression: "content.effectiveDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "sfl-fix-label" },
                            [
                              _vm.content.effectiveDateType !== "AllTime"
                                ? _c("custom-date-picker", {
                                    attrs: {
                                      readonly: _vm.readonly,
                                      outlined: "",
                                      dense: "",
                                      disabled: "",
                                      label: "Start Date"
                                    },
                                    model: {
                                      value: _vm.content.effectiveDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.content,
                                          "effectiveDate",
                                          $$v
                                        )
                                      },
                                      expression: "content.effectiveDate"
                                    }
                                  })
                                : _vm._e(),
                              _vm.content.effectiveDateType === "AllTime"
                                ? _c("custom-date-picker", {
                                    attrs: {
                                      readonly: _vm.readonly,
                                      outlined: "",
                                      dense: "",
                                      rules: [
                                        _vm.ValidationService.getValidator(
                                          "required",
                                          "Start Date"
                                        )
                                      ],
                                      label: "Start Date"
                                    },
                                    model: {
                                      value: _vm.content.startDate,
                                      callback: function($$v) {
                                        _vm.$set(_vm.content, "startDate", $$v)
                                      },
                                      expression: "content.startDate"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "sfl-fix-label" },
                            [
                              _c("custom-date-picker", {
                                attrs: {
                                  readonly: _vm.readonly,
                                  outlined: "",
                                  dense: "",
                                  label: "End Date"
                                },
                                model: {
                                  value: _vm.content.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.content, "endDate", $$v)
                                  },
                                  expression: "content.endDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "pt-0 pb-0" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "380px",
                          padding: "3px",
                          overflow: "hidden",
                          "overflow-y": "auto",
                          "border-radius": "3px",
                          color: "999"
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("v-checkbox", {
                              class: _vm.partsReadOnly
                                .autoFinalizeAfterCreateProduct
                                ? "sfl-disabled-labels-row"
                                : "",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                label: "Auto Finalize (No Action Required)",
                                rules: _vm.selectPathOptions()
                              },
                              model: {
                                value:
                                  _vm.content.autoFinalizeAfterCreateProduct,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.content,
                                    "autoFinalizeAfterCreateProduct",
                                    $$v
                                  )
                                },
                                expression:
                                  "content.autoFinalizeAfterCreateProduct"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("v-checkbox", {
                              class: _vm.partsReadOnly.cancellationSendOnly
                                ? "sfl-disabled-labels-row"
                                : "",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                label: "Send Cancellation Only",
                                rules: _vm.selectPathOptions()
                              },
                              model: {
                                value: _vm.content.cancellationSendOnly,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.content,
                                    "cancellationSendOnly",
                                    $$v
                                  )
                                },
                                expression: "content.cancellationSendOnly"
                              }
                            }),
                            _c(
                              "div",
                              { attrs: { disabled: true } },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "ml-8 mt-2",
                                    class: _vm.partsReadOnly
                                      .autoSendCancellation
                                      ? "sfl-disabled-row"
                                      : ""
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _vm._v("Auto Send Cancellation")
                                    ]),
                                    _c(
                                      "v-col",
                                      { staticClass: "pt-1 pb-0" },
                                      [
                                        _c("sf-boolean-select-field", {
                                          attrs: {
                                            disabled:
                                              _vm.partsReadOnly
                                                .autoSendCancellation,
                                            outlined: "",
                                            dense: "",
                                            rules: _vm.selectRule(
                                              _vm.partsReadOnly
                                                .autoSendCancellation
                                            )
                                          },
                                          model: {
                                            value:
                                              _vm.content.autoSendCancellation,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.content,
                                                "autoSendCancellation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "content.autoSendCancellation"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "ml-8 mt-1",
                                    class: _vm.partsReadOnly
                                      .finalizeAfterSending
                                      ? "sfl-disabled-row"
                                      : ""
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _vm._v("Finalize After Sending")
                                    ]),
                                    _c(
                                      "v-col",
                                      { staticClass: "pt-1 pb-0" },
                                      [
                                        _c("sf-boolean-select-field", {
                                          attrs: {
                                            disabled:
                                              _vm.partsReadOnly
                                                .finalizeAfterSending,
                                            outlined: "",
                                            dense: "",
                                            rules: _vm.selectRule(
                                              _vm.partsReadOnly
                                                .finalizeAfterSending
                                            )
                                          },
                                          model: {
                                            value:
                                              _vm.content.finalizeAfterSending,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.content,
                                                "finalizeAfterSending",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "content.finalizeAfterSending"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("v-checkbox", {
                              class: _vm.partsReadOnly
                                .cancellationSendAndGetRefundQuote
                                ? "sfl-disabled-labels-row"
                                : "",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                label: "Send Cancellation and get Refund Quote",
                                rules: _vm.selectPathOptions()
                              },
                              model: {
                                value:
                                  _vm.content.cancellationSendAndGetRefundQuote,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.content,
                                    "cancellationSendAndGetRefundQuote",
                                    $$v
                                  )
                                },
                                expression:
                                  "content.cancellationSendAndGetRefundQuote"
                              }
                            }),
                            _c(
                              "div",
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "ml-8 mt-1",
                                    class: _vm.partsReadOnly
                                      .autoSendCancellationRefundQuote
                                      ? "sfl-disabled-row"
                                      : ""
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _vm._v("Auto Send Cancellation")
                                    ]),
                                    _c(
                                      "v-col",
                                      { staticClass: "pt-1 pb-0" },
                                      [
                                        _c("sf-boolean-select-field", {
                                          attrs: {
                                            disabled:
                                              _vm.partsReadOnly
                                                .autoSendCancellationRefundQuote,
                                            outlined: "",
                                            dense: "",
                                            rules: _vm.selectRule(
                                              _vm.partsReadOnly
                                                .autoSendCancellationRefundQuote
                                            )
                                          },
                                          model: {
                                            value:
                                              _vm.content
                                                .autoSendCancellationRefundQuote,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.content,
                                                "autoSendCancellationRefundQuote",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "content.autoSendCancellationRefundQuote"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("v-col", {
                    staticClass: "pt-0 pb-0",
                    attrs: { cols: "2" }
                  }),
                  _c("v-col", { staticClass: "pt-0 pb-0" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "340px",
                          padding: "3px",
                          overflow: "hidden",
                          "overflow-y": "auto",
                          "border-radius": "3px",
                          color: "999"
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("v-checkbox", {
                              class: _vm.partsReadOnly.refundCustomer
                                ? "sfl-disabled-labels-row"
                                : "",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                label: "Refund Consumer"
                              },
                              model: {
                                value: _vm.content.refundCustomer,
                                callback: function($$v) {
                                  _vm.$set(_vm.content, "refundCustomer", $$v)
                                },
                                expression: "content.refundCustomer"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("v-checkbox", {
                              class: _vm.partsReadOnly.lenderReceivables
                                ? "sfl-disabled-labels-row"
                                : "",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                label: "Lender Receivables"
                              },
                              model: {
                                value: _vm.content.lenderReceivables,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.content,
                                    "lenderReceivables",
                                    $$v
                                  )
                                },
                                expression: "content.lenderReceivables"
                              }
                            }),
                            _c(
                              "v-row",
                              {
                                staticClass: "ml-8 mt-1",
                                class: _vm.partsReadOnly.debitDealerReserve
                                  ? "sfl-disabled-row"
                                  : ""
                              },
                              [
                                _c("v-col", { attrs: { cols: "6" } }, [
                                  _vm._v("Debit Dealer Reserve")
                                ]),
                                _c(
                                  "v-col",
                                  { staticClass: "pt-1 pb-0" },
                                  [
                                    _c("sf-boolean-select-field", {
                                      attrs: {
                                        disabled:
                                          _vm.partsReadOnly.debitDealerReserve,
                                        outlined: "",
                                        dense: "",
                                        rules: _vm.selectRule(
                                          _vm.partsReadOnly.debitDealerReserve
                                        )
                                      },
                                      model: {
                                        value: _vm.content.debitDealerReserve,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.content,
                                            "debitDealerReserve",
                                            $$v
                                          )
                                        },
                                        expression: "content.debitDealerReserve"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass: "ml-8 mt-1",
                                class: _vm.partsReadOnly.perDealer
                                  ? "sfl-disabled-row"
                                  : ""
                              },
                              [
                                _c("v-col", { attrs: { cols: "6" } }, [
                                  _vm._v("Per Dealer")
                                ]),
                                _c(
                                  "v-col",
                                  { staticClass: "pt-1  pb-0" },
                                  [
                                    _c("sf-boolean-select-field", {
                                      attrs: {
                                        disabled: _vm.partsReadOnly.perDealer,
                                        outlined: "",
                                        dense: "",
                                        rules: _vm.selectRule(
                                          _vm.partsReadOnly.perDealer
                                        )
                                      },
                                      model: {
                                        value: _vm.content.perDealer,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.content,
                                            "perDealer",
                                            $$v
                                          )
                                        },
                                        expression: "content.perDealer"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }