






















































































import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  name: 'ConfirmationDialog',
  components: {},
  data: () => ({
    open: false,
  }),
  methods: {
    cancel() {
      this.open = false;
      this.$emit('cancel');
    },
    external() {
      const scope = this;
      return {
        open() {
          scope.open = true;
        },
        close() {
          scope.open = false;
        },
      };
    },
  },
  watch: {
    open: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue) {
            this.$emit('open');
          } else {
            this.$emit('close');
          }
        }
      },
    },
  },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
    cancelVisible: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
      default: 'Confirmation Dialog Message (prop: message)',
    },
    title: {
      type: String,
      default: 'Confirmation Dialog Title (prop: title)',
    },
    /**
     * Array of Objects (Buttons)
     *  - name: The name of the button
     *  - color: The color of the button
     *  - callback: callback function
     */
    buttons: {
      type: Array,
      default: () => {
        return []; // Definition of buttons ( if not set all columns from incoming data will be visible )
      },
    },
    errors: {
      type: Array,
      default: () => {
        return []; // Definition possible show errors on Confiem inside dialog window
      },
    },
    messages: {
      type: Array,
      default: () => {
        return []; // Definition possible show errors on Confiem inside dialog window
      },
    },
    maxWidth: {
      type: String,
      default: '550px',
    },
  },
});
