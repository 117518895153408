
























































import { Vue } from 'vue-property-decorator';
import UserService from '@/services/api/User.service';
import ErrorMessageInfo from '@/components/ErrorMessageInfo.vue';
import axios from '@/services/api/Api.service';
import { AxiosResponse } from 'axios';
import { Lender } from '@/interfaces';
import MetadataService from '@/services/api/Metadata.service';
import EventBus from '@/events/EventBus';

const axiosInstance = new axios().getClient();

const Url = 'uiapi/config-settings/general';

export default Vue.extend({
  name: 'LenderServiceConfigurationSettingsDealerInvoice',
  data() {
    return {
      messagesComponent: this.$getRemoteComponent('InvoiceSettingsErrorMessageInfo', this),
      readonly: !UserService.checkUserActionAccess('SF_LENDER_SERVICE_CONFIG_EDIT'),
      saveButtonActive: false,
      editedRecord: {
        sendToLenderReview: false,
        orgId: this.orgId,
      } as any,
      options: {
        optionEnum: [
          { text: 'Enabled', value: true },
          { text: 'Disabled', value: false },
        ],
      },
    };
  },
  async created() {},
  async mounted() {
    this.messagesComponent = this.$getRemoteComponent('InvoiceSettingsErrorMessageInfo', this);
    this.messagesComponent.error().clear();
    await this.getData();
  },
  components: { ErrorMessageInfo },
  methods: {
    setProgress(onOff: boolean) {
      if (onOff) {
        EventBus.$emit('SF_PROCESSING', true);
      } else {
        EventBus.$emit('SF_PROCESSING', false);
      }
      return onOff;
    },
    async getData() {
      this.messagesComponent.error().clear();
      this.setProgress(true);
      return axiosInstance
        .get<Lender, AxiosResponse<{ success: boolean; messages: string[]; errors: string[]; data: Lender }>>(Url, {
          headers: {
            'Content-Type': 'application/json',
            orgId: this.orgId,
          },
        })
        .then(result => {
          if (result.data.success) {
            this.editedRecord = result.data.data;
          } else {
            this.messagesComponent.error().add('Error Loading Lender Data');
          }
          if (result.data.success === false) {
            this.messagesComponent.error().add(result.data.errors);
          }

          this.setProgress(false);
        })
        .catch((error: string) => {
          this.setProgress(false);
          this.messagesComponent.error().add([error]);
        });
    },
    filterPayload(payload: any) {
      return {
        orgId: payload.orgId,
        sendToLenderReview: payload.sendToLenderReview,
        dateTimeUpdated: payload.dateTimeUpdated,
      };
    },
    updateData() {
      this.messagesComponent.error().clear();
      this.setProgress(true);
      return axiosInstance
        .post<Lender, AxiosResponse<{ success: boolean; messages: string[]; errors: string[]; data: Lender }>>(
          Url,
          this.editedRecord || undefined ? this.filterPayload(this.editedRecord) : undefined,
          {
            headers: {
              'Content-Type': 'application/json',
              orgId: this.orgId,
            },
          },
        )
        .then(result => {
          this.setProgress(false);
          if (result.data.success) {
            this.saveButtonActive = false;
            this.editedRecord = result.data.data;
            this.setProgress(false);
            EventBus.$emit('SF_SAVED_SUCCESSFULLY');
          } else {
            this.messagesComponent.error().set(result.data.errors);
          }
        })
        .catch((error: string) => {
          this.setProgress(false);
          this.messagesComponent.error().set([error]);
        });
    },
    async refreshData(): Promise<Lender> {
      return (await MetadataService.getRecord({ orgId: this.orgId }, 'All', '/uiapi/lender/search')) as Lender;
    },
  },
  props: ['footerHeight', 'orgId'],
});
