






























































































































































































































import { Vue } from 'vue-property-decorator';
import axios from '../../services/api/Api.service';
import ValidationService from '@/services/Validations.service';
import S3FileService from '@/services/api/S3File.service';
import EventBus from '@/events/EventBus';
import CancellationLetterService from '@/services/api/CancellationLetter.service';
import SfLocalLetterPreview from '@/components/SFComponents/SfLocalLetterPreview.vue';

const axiosInstance = new axios().getClient();

export default Vue.extend({
  name: 'SfLetterUpload',
  data() {
    return {
      showUpdateButton: true,
      cancelButtonText: 'Cancel',
      isSelecting: false,
      formValid: false,
      errors: [] as string[],
      messages: [] as string[],
      progress: false as boolean,
      open: false,
      ValidationService: ValidationService,
      uploadRecord: {
        type: null,
        party: null,
        name: null,
        files: null,
        filename: null,
        externalId: null,
      },
      lists: {
        party: [
          { text: 'Dealer', value: 'Dealer' },
          { text: 'Provider', value: 'Provider' },
          { text: 'Both', value: 'Both' },
        ],
        type: [
          { text: 'Payoff', value: 'payoff' },
          { text: 'Non-Payoff', value: 'non-payoff' },
          { text: 'Repossession', value: 'repossession' },
          { text: 'Total Loss', value: 'total_loss' },
          { text: 'Customer Request', value: 'customer_request' },
          { text: 'Lien Loss', value: 'lien_loss' },
          { text: 'Other', value: 'other' },
          { text: 'Default', value: 'default' },
        ],
      },
    };
  },
  components: { SfLocalLetterPreview },
  created() {
    if (!this.orgId) {
      this.error().clear();
      this.message().clear();
      this.error().add('Missing Organization ID');
    }
  },
  methods: {
    getFileName() {
      if (this.uploadRecord && this.uploadRecord.filename && typeof this.uploadRecord.filename === 'string') {
        if ((this.uploadRecord.filename + '').split('-').length > 1) {
          return (this.uploadRecord.filename + '').split('-')[1];
        } else {
          return this.uploadRecord.filename + '';
        }
      }
      return '';
    },
    async update() {
      try {
        this.message().clear();
        this.error().clear();
        if (
          (this.$refs.LetterUploadForm as Vue & { validate: () => boolean }).validate() &&
          this.uploadRecord.name &&
          this.uploadRecord.party &&
          this.orgId
        ) {
          if (this.uploadRecord.files && this.uploadRecord.filename) {
            this.progress = true;
            let url: string = '';
            //@todo Tomek this need to be updated for Letters Upload
            const response = (await S3FileService.getPresignedLetterEditUploadUrl(
              this.uploadRecord.party || '',
              this.uploadRecord.name || '',
              this.uploadRecord.filename || '',
              this.orgId,
              this.uploadRecord.externalId || '',
            )) as any;
            if (response.success === true && this.uploadRecord.files) {
              url = response.data?.url as string;
              const axiosCl = axiosInstance;
              const rs = await axiosCl.put(url, this.uploadRecord.files, {
                headers: {
                  'Content-Type': 'File',
                },
              });

              if (rs.status === 200) {
                this.message().add('Letter Upload successful.');
                this.$emit('file-saved-success', true);
                EventBus.$emit('sf-letter-upload-success', true);
                this.showUpdateButton = false;
                this.cancelButtonText = 'Close';
                setTimeout(() => {
                  this.clearForm();
                  this.open = false;
                }, 5000);
              } else {
                this.error().add('File upload failed due to client/server error, please try again');
                this.$emit('file-saved-failed', false);
                EventBus.$emit('sf-letter-upload-success', false);
              }
            } else {
              this.error().set(response.errors);
            }
            this.progress = false;
          } else {
            const response = (await CancellationLetterService.updateName({
              name: this.uploadRecord.name || '',
              externalId: this.uploadRecord.externalId || '',
              orgId: this.orgId || '',
            })) as any;
            if (response.success === true) {
              this.message().add('Letter Update successful.');
              this.$emit('file-saved-success', true);
              EventBus.$emit('sf-letter-upload-success', true);
              this.showUpdateButton = false;
              this.cancelButtonText = 'Close';
              setTimeout(() => {
                this.clearForm();
                this.open = false;
              }, 5000);
            } else {
              this.error().add('Letter update failed');
              this.error().add(response.errors.join());
              this.$emit('file-saved-failed', false);
              EventBus.$emit('sf-letter-upload-success', false);
            }
            this.progress = false;
          }
        }
      } catch (err) {
        this.error().add('File upload failed due to client/server error, please try again');
        EventBus.$emit('sf-letter-upload-success', false);
        this.progress = false;
      }
    },
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true },
      );

      // @ts-ignore: Unreachable code error
      // Trigger click on the FileInput
      setTimeout(() => {
        this.$getRemoteComponent('FileInputField', this).click();
      }, 400);
    },
    onFileChanged(e: any) {
      this.uploadRecord.files = e.target.files[0];
      if (e.target.files[0]) {
        this.uploadRecord.filename = e.target.files[0].name;
      }
      this.$forceUpdate();

      // Do whatever you need with the file, liek reading it with FileReader
    },
    checkLetterFileStatus() {
      return this.uploadRecord.files ? true : true;
    },
    stopTheEvent: (event: Event) => event.stopPropagation(),
    validateForm() {
      setTimeout(async () => {
        await this.$getRemoteComponent('LetterUploadForm', this)?.validate();
      }, 500);

      return this.formValid;
    },
    message() {
      const scope = this;
      return {
        set: (messages: string[]) => {
          scope.messages.push(...messages);
          return this;
        },
        add: (message: string) => {
          scope.messages.push(message);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    clearForm() {
      this.uploadRecord = {
        type: null,
        party: this.party ? this.party : null,
        name: null,
        files: null,
        filename: null,
        externalId: null,
      };
    },
  },
  watch: {
    open: {
      handler(n) {
        if (n) {
          this.showUpdateButton = true;
          this.cancelButtonText = 'Cancel';
          this.clearForm();
          this.validateForm();
          this.message().clear();
          this.error().clear();
        }
      },
    },
  },
  props: ['orgId', 'party', 'letterRecord'],
});
