import ApiService from '@/services/api/Api.service';
import { Attachment, Response } from '../../interfaces';
import { AxiosResponse } from 'axios';

class AttachmentService extends ApiService {
  async create(data: Attachment): Promise<Response<Attachment>> {
    return (await this.axios.post<Attachment, AxiosResponse<Response<Attachment>>>('/uiapi/attachment', data)).data;
  }

  async delete(id: string): Promise<Response<Attachment>> {
    return (await this.axios.delete<Attachment, AxiosResponse<Response<Attachment>>>(`/uiapi/attachment/${id}`)).data;
  }

  GetDownloadButton() {
    return require('@/assets/button/download.png');
  }
}

export default new AttachmentService();
