

































































import { Vue } from 'vue-property-decorator';
import ValidationService from './../services/Validations.service';
import S3FileService from './../services/api/S3File.service';
import AttachmentService from './../services/api/Attachment.service';
import axios from 'axios';

export default Vue.extend({
  name: 'AccountAttachmentForm',
  data: () => ({
    progress: false,
    selectedFile: null as File | null,
    selectedFileDescription: '',
    ValidationService: ValidationService as Object,
    snackBarSuccess: false,
    snackBarError: false,
    errors: [],
  }),
  methods: {
    async createAttachment() {
      try {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate() && this.selectedFile) {
          this.progress = true;
          const s3 = await S3FileService.getPresignedUploadUrlForAttachment(
            this.selectedFile.name,
            this.account.accountNumber,
            this.account.orgId,
          );
          const axiosCl = axios.create();
          await axiosCl.put(s3.url, this.selectedFile, {
            headers: {
              'Content-Type': this.selectedFile.type,
            },
          });
          const res = await AttachmentService.create({
            filename: this.selectedFile.name,
            fileURL: s3.fileName,
            orgId: this.account.orgId,
            description: this.selectedFileDescription,
            accountNumber: this.account.accountNumber || null,
          });
          if (res.success) {
            this.snackBarSuccess = true;
            this.selectedFile = null;
            this.selectedFileDescription = '';
            this.progress = false;
            this.resetValidation();
            setTimeout(() => {
              this.$emit('uploadCompleted');
            }, 500);
          } else {
            this.progress = false;
            this.snackBarError = true;
            this.setError(res.errors || ['Save Failed']);
          }
        }
      } catch (error) {
        this.selectedFile = null;
        this.progress = false;
        this.setError([error.response?.data?.message] || ['Unknown Error']);
        this.snackBarError = true;
      }
    },
    setError(errors: string[]) {
      this.errors.length = 0;
      Object.assign(this.errors, errors);
    },
    resetValidation() {
      return setTimeout(() => {
        (this.$refs as HTMLFormElement).form.resetValidation();
      }, 100);
    },
    formValueChange() {
      this.setError([]);
    },
  },
  props: {
    account: {
      type: Object,
      default: () => {},
    },
  },
});
