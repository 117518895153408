var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-scroll-layout",
    {
      attrs: {
        pageName: "Providers",
        pageIcon: "mdi-smart-card",
        contentList: _vm.contentList,
        contentSelected: _vm.contentSelected
      }
    },
    [
      _c(
        "div",
        { attrs: { slot: "buttons" }, slot: "buttons" },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.providerDetailsEnabled &&
                    _vm.userService.checkUserActionAccess("PROVIDER_EDIT"),
                  expression:
                    "providerDetailsEnabled && userService.checkUserActionAccess('PROVIDER_EDIT')"
                }
              ],
              attrs: { color: "primary", elevation: "2", medium: "" },
              on: {
                click: function($event) {
                  return _vm.submitDataRequestProviderDetails()
                }
              }
            },
            [_vm._v(" UPDATE PROVIDER ")]
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "1024px", scrollable: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            attrs: {
                              color: "primary",
                              elevation: "2",
                              medium: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.openEditDialog()
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" NEW PROVIDER ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "dialog-card-header" }, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Provider Data")
                    ])
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer"
                      },
                      attrs: { center: "", large: "", color: "white" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" mdi-close ")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticStyle: { height: "95%" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    directives: [
                                      {
                                        name: "visible",
                                        rawName: "v-visible",
                                        value: _vm.overlay,
                                        expression: "overlay"
                                      }
                                    ],
                                    attrs: {
                                      indeterminate: "",
                                      query: true,
                                      color: "blue"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.length > 0,
                                  expression: "errors.length > 0"
                                }
                              ]
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            "line-height": "15px"
                                          },
                                          attrs: {
                                            type: "error",
                                            border: "left",
                                            text: "",
                                            outlined: "",
                                            dense: ""
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c("b", [_vm._v("Errors:")])
                                          ]),
                                          _vm._l(_vm.errors, function(
                                            error,
                                            errorIndex
                                          ) {
                                            return _c(
                                              "div",
                                              { key: errorIndex },
                                              [_vm._v("* " + _vm._s(error))]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-form",
                            {
                              ref: "providerForm",
                              staticClass: "compact-form",
                              attrs: {
                                "lazy-validation": "",
                                disabled: _vm.overlay
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "7", sm: "7", md: "7" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c("v-card-subtitle", [
                                                    _vm._v("Provider Details")
                                                  ]),
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c(
                                                        "v-container",
                                                        {
                                                          staticClass:
                                                            "pa-0 ma-0"
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs: {
                                                                                dense:
                                                                                  "",
                                                                                autocomplete:
                                                                                  "none",
                                                                                label:
                                                                                  "LCT Provider ID",
                                                                                rules: [],
                                                                                required:
                                                                                  "",
                                                                                readonly:
                                                                                  "",
                                                                                disabled:
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .id,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.editedRecord,
                                                                                    "id",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "editedRecord.id"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs: {
                                                                                dense:
                                                                                  "",
                                                                                autocomplete:
                                                                                  "none",
                                                                                label:
                                                                                  "Provider Parent Name",
                                                                                rules: [
                                                                                  _vm.ValidationService.getValidator(
                                                                                    "maxLength",
                                                                                    "Provider Parent Name",
                                                                                    {
                                                                                      length: 255
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                required:
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .providerParentName,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.editedRecord,
                                                                                    "providerParentName",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "editedRecord.providerParentName"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  _vm
                                                                                    .editedRecordLists
                                                                                    .refundAPIproviders,
                                                                                dense:
                                                                                  "",
                                                                                autocomplete:
                                                                                  "none",
                                                                                label:
                                                                                  "Refund API"
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .refundAPI,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.editedRecord,
                                                                                    "refundAPI",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "editedRecord.refundAPI"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _c(
                                                                            "v-textarea",
                                                                            {
                                                                              attrs: {
                                                                                dense:
                                                                                  "",
                                                                                autocomplete:
                                                                                  "none",
                                                                                label:
                                                                                  "API Config Code",
                                                                                rules: [
                                                                                  _vm.ValidationService.getValidator(
                                                                                    "maxLength",
                                                                                    "API Config Code",
                                                                                    {
                                                                                      length: 255
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                required:
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .APIConfigCode,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.editedRecord,
                                                                                    "APIConfigCode",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "editedRecord.APIConfigCode"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs: {
                                                                                dense:
                                                                                  "",
                                                                                autocomplete:
                                                                                  "none",
                                                                                label:
                                                                                  "Provider Name",
                                                                                rules: [
                                                                                  _vm.ValidationService.getValidator(
                                                                                    "required",
                                                                                    "Provider Name"
                                                                                  ),
                                                                                  _vm.ValidationService.getValidator(
                                                                                    "maxLength",
                                                                                    "Provider Name",
                                                                                    {
                                                                                      length: 255
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                required:
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .name,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.editedRecord,
                                                                                    "name",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "editedRecord.name"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs: {
                                                                                dense:
                                                                                  "",
                                                                                autocomplete:
                                                                                  "none",
                                                                                label:
                                                                                  "Short Name",
                                                                                rules: [
                                                                                  _vm.ValidationService.getValidator(
                                                                                    "maxLength",
                                                                                    "Short Name",
                                                                                    {
                                                                                      length: 80
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                required:
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .shortName,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.editedRecord,
                                                                                    "shortName",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "editedRecord.shortName"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  _vm
                                                                                    .editedRecordLists
                                                                                    .providerRefundRequestMethod,
                                                                                dense:
                                                                                  "",
                                                                                autocomplete:
                                                                                  "none",
                                                                                label:
                                                                                  "Refund Request Method",
                                                                                rules: [
                                                                                  _vm.ValidationService.getValidator(
                                                                                    "required",
                                                                                    "Refund Request Method"
                                                                                  ),
                                                                                  _vm.ValidationService.getValidator(
                                                                                    "maxLength",
                                                                                    "Refund Request Method",
                                                                                    {
                                                                                      length: 100
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                required:
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .providerRefundRequestMethod,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.editedRecord,
                                                                                    "providerRefundRequestMethod",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "editedRecord.providerRefundRequestMethod"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm
                                                                    .editedRecord
                                                                    .providerRefundRequestMethod ===
                                                                  "Email"
                                                                    ? _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            [
                                                                              _c(
                                                                                "v-select",
                                                                                {
                                                                                  attrs: {
                                                                                    items:
                                                                                      _vm
                                                                                        .editedRecordLists
                                                                                        .emailFormat,
                                                                                    dense:
                                                                                      "",
                                                                                    label:
                                                                                      "Email Format"
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm
                                                                                        .editedRecord
                                                                                        .emailFormat,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.editedRecord,
                                                                                        "emailFormat",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                    expression:
                                                                                      "editedRecord.emailFormat"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items: [
                                                                                  "Email",
                                                                                  "Fax"
                                                                                ],
                                                                                dense:
                                                                                  "",
                                                                                label:
                                                                                  "Communication Preference"
                                                                              },
                                                                              on: {
                                                                                change:
                                                                                  _vm.refreshValidation
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .communicationPreference,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.editedRecord,
                                                                                    "communicationPreference",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "editedRecord.communicationPreference"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs: {
                                                                                dense:
                                                                                  "",
                                                                                autocomplete:
                                                                                  "none",
                                                                                label:
                                                                                  "Refund Return SLA",
                                                                                rules: [
                                                                                  _vm.ValidationService.getValidator(
                                                                                    "number",
                                                                                    "Refund Return SLA"
                                                                                  )
                                                                                ]
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .refundReturnSLA,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.editedRecord,
                                                                                    "refundReturnSLA",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "editedRecord.refundReturnSLA"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _c(
                                                                            "v-checkbox",
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  "Automate Call"
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .automateApiCall,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.editedRecord,
                                                                                    "automateApiCall",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "editedRecord.automateApiCall"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c("v-card-subtitle", [
                                                    _vm._v("Provider Address")
                                                  ]),
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c(
                                                        "v-container",
                                                        {
                                                          staticClass:
                                                            "pa-0 ma-0"
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        autocomplete:
                                                                          "none",
                                                                        label:
                                                                          "Street Address",
                                                                        rules: [
                                                                          _vm.ValidationService.getValidator(
                                                                            "maxLength",
                                                                            "Street Address",
                                                                            {
                                                                              length: 255
                                                                            }
                                                                          )
                                                                        ],
                                                                        required:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedRecord
                                                                            .providerStreetAddress,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.editedRecord,
                                                                            "providerStreetAddress",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "editedRecord.providerStreetAddress"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        autocomplete:
                                                                          "none",
                                                                        label:
                                                                          "City",
                                                                        rules: [
                                                                          _vm.ValidationService.getValidator(
                                                                            "maxLength",
                                                                            "City",
                                                                            {
                                                                              length: 100
                                                                            }
                                                                          )
                                                                        ],
                                                                        required:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedRecord
                                                                            .providerCity,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.editedRecord,
                                                                            "providerCity",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "editedRecord.providerCity"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "v-autocomplete",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        autocomplete:
                                                                          "none",
                                                                        label:
                                                                          "State",
                                                                        rules: [],
                                                                        items:
                                                                          _vm
                                                                            .editedRecordLists
                                                                            .providerState
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedRecord
                                                                            .providerState,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.editedRecord,
                                                                            "providerState",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "editedRecord.providerState"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        autocomplete:
                                                                          "none",
                                                                        label:
                                                                          "Zip",
                                                                        rules: [
                                                                          _vm.ValidationService.getValidator(
                                                                            "maxLength",
                                                                            "Zip",
                                                                            {
                                                                              length: 10
                                                                            }
                                                                          )
                                                                        ],
                                                                        required:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedRecord
                                                                            .providerZip,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.editedRecord,
                                                                            "providerZip",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "editedRecord.providerZip"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        autocomplete:
                                                                          "none",
                                                                        label:
                                                                          "Country",
                                                                        rules: [],
                                                                        items:
                                                                          _vm
                                                                            .editedRecordLists
                                                                            .providerCountry,
                                                                        required:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedRecord
                                                                            .providerCountry,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.editedRecord,
                                                                            "providerCountry",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "editedRecord.providerCountry"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        autocomplete:
                                                                          "none",
                                                                        label:
                                                                          "Web Address",
                                                                        rules: [
                                                                          _vm.ValidationService.getValidator(
                                                                            "maxLength",
                                                                            "Web Address",
                                                                            {
                                                                              length: 255
                                                                            }
                                                                          )
                                                                        ],
                                                                        required:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedRecord
                                                                            .providerWebAddress,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.editedRecord,
                                                                            "providerWebAddress",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "editedRecord.providerWebAddress"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "5", sm: "5", md: "5" } },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c("v-card-subtitle", [
                                            _vm._v("Contact Details")
                                          ]),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-container",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              autocomplete:
                                                                "none",
                                                              label:
                                                                "Cancellation Contact",
                                                              rules: [
                                                                _vm.ValidationService.getValidator(
                                                                  "maxLength",
                                                                  "Cancellation Contact",
                                                                  {
                                                                    length: 255
                                                                  }
                                                                )
                                                              ],
                                                              required: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .cancellationContact,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "cancellationContact",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.cancellationContact"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "v-text-field",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                                autocomplete:
                                                                  "none",
                                                                label:
                                                                  "Cancellation Contact Email",
                                                                rules: [
                                                                  _vm.ValidationService.getValidator(
                                                                    "maxLength",
                                                                    "Cancellation Contact Email",
                                                                    {
                                                                      length: 255
                                                                    }
                                                                  ),
                                                                  _vm.ValidationService.getValidator(
                                                                    "email",
                                                                    "Cancellation Contact Email"
                                                                  ),
                                                                  _vm.validateEmailFax(
                                                                    "Email"
                                                                  )
                                                                ],
                                                                required: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .editedRecord
                                                                    .cancellationEmail,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.editedRecord,
                                                                    "cancellationEmail",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "editedRecord.cancellationEmail"
                                                              }
                                                            },
                                                            [
                                                              _vm.editedRecord
                                                                .cancellationEmail !==
                                                              null
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "append"
                                                                      },
                                                                      slot:
                                                                        "append"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs: {
                                                                            href:
                                                                              "mailto:" +
                                                                              _vm
                                                                                .editedRecord
                                                                                .cancellationEmail
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticStyle: {
                                                                                cursor:
                                                                                  "pointer"
                                                                              },
                                                                              attrs: {
                                                                                title:
                                                                                  "Mail to: " +
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .cancellationEmail,
                                                                                color:
                                                                                  "green"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-email-arrow-right-outline"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-text-field", {
                                                            directives: [
                                                              {
                                                                name: "mask",
                                                                rawName:
                                                                  "v-mask",
                                                                value:
                                                                  "(###) ###-####",
                                                                expression:
                                                                  "'(###) ###-####'"
                                                              }
                                                            ],
                                                            attrs: {
                                                              dense: "",
                                                              autocomplete:
                                                                "none",
                                                              hint:
                                                                "Valid format: (xxx)xxx-xxxx",
                                                              phone:
                                                                _vm.editedRecord
                                                                  .providerCancellationFax,
                                                              label:
                                                                "Cancellation Fax",
                                                              rules: [
                                                                _vm.ValidationService.getValidator(
                                                                  "maxLength",
                                                                  "Cancellation Fax",
                                                                  {
                                                                    length: 15
                                                                  }
                                                                ),
                                                                _vm.ValidationService.getValidator(
                                                                  "phone",
                                                                  "Cancellation Fax"
                                                                ),
                                                                _vm.validateEmailFax(
                                                                  "Fax"
                                                                )
                                                              ],
                                                              required: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .providerCancellationFax,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "providerCancellationFax",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.providerCancellationFax"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dense: "",
                                                              autocomplete:
                                                                "none",
                                                              items:
                                                                _vm
                                                                  .editedRecordLists
                                                                  .CustomCancellationForm,
                                                              label:
                                                                "Cancellation Form",
                                                              rules: [],
                                                              required: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .customCancellationFormId,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "customCancellationFormId",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.customCancellationFormId"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "v-text-field",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    "(###) ###-####",
                                                                  expression:
                                                                    "'(###) ###-####'"
                                                                }
                                                              ],
                                                              attrs: {
                                                                dense: "",
                                                                autocomplete:
                                                                  "none",
                                                                hint:
                                                                  "Valid format: (xxx)xxx-xxxx",
                                                                label:
                                                                  "Cancellation Contact Phone",
                                                                rules: [
                                                                  _vm.ValidationService.getValidator(
                                                                    "maxLength",
                                                                    "Cancellation Contact Phone",
                                                                    {
                                                                      length: 15
                                                                    }
                                                                  ),
                                                                  _vm.ValidationService.getValidator(
                                                                    "phone",
                                                                    "Cancellation Contact Phone"
                                                                  )
                                                                ]
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .editedRecord
                                                                    .providerCancellationContactPhone,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.editedRecord,
                                                                    "providerCancellationContactPhone",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "editedRecord.providerCancellationContactPhone"
                                                              }
                                                            },
                                                            [
                                                              _vm.editedRecord
                                                                .providerCancellationContactPhone !==
                                                              null
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "append"
                                                                      },
                                                                      slot:
                                                                        "append"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs: {
                                                                            href:
                                                                              "tel: +1" +
                                                                              _vm
                                                                                .editedRecord
                                                                                .providerCancellationContactPhone
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticStyle: {
                                                                                cursor:
                                                                                  "pointer"
                                                                              },
                                                                              attrs: {
                                                                                title:
                                                                                  "Call +1 " +
                                                                                  _vm.formatDisplayService.getRender(
                                                                                    "toPhone"
                                                                                  )(
                                                                                    _vm
                                                                                      .editedRecord
                                                                                      .providerCancellationContactPhone
                                                                                  ),
                                                                                color:
                                                                                  "green"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-phone"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm
                                                                          .phoneNumberRulesList
                                                                          .length >
                                                                        0,
                                                                      expression:
                                                                        "phoneNumberRulesList.length > 0"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    slot:
                                                                      "append",
                                                                    bottom: ""
                                                                  },
                                                                  slot:
                                                                    "append",
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "blue"
                                                                                    }
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  " mdi-information-outline "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "13px",
                                                                        "line-height":
                                                                          "14px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Provider Cancellation Contact Phone: "
                                                                      ),
                                                                      _vm._l(
                                                                        _vm.phoneNumberRulesList,
                                                                        function(
                                                                          rule,
                                                                          ruleIndex
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: ruleIndex
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " - " +
                                                                                  _vm._s(
                                                                                    rule
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              autocomplete:
                                                                "none",
                                                              label:
                                                                "Refund Contact",
                                                              rules: [
                                                                _vm.ValidationService.getValidator(
                                                                  "maxLength",
                                                                  "Refund Contact",
                                                                  {
                                                                    length: 255
                                                                  }
                                                                )
                                                              ],
                                                              required: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .refundContact,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "refundContact",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.refundContact"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "v-text-field",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                                autocomplete:
                                                                  "none",
                                                                label:
                                                                  "Refund Contact Email",
                                                                rules: [
                                                                  _vm.ValidationService.getValidator(
                                                                    "email",
                                                                    "Refund Contact Email"
                                                                  ),
                                                                  _vm.ValidationService.getValidator(
                                                                    "maxLength",
                                                                    "Refund Contact Email",
                                                                    {
                                                                      length: 255
                                                                    }
                                                                  )
                                                                ],
                                                                required: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .editedRecord
                                                                    .refundContactEmail,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.editedRecord,
                                                                    "refundContactEmail",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "editedRecord.refundContactEmail"
                                                              }
                                                            },
                                                            [
                                                              _vm.editedRecord
                                                                .refundContactEmail !==
                                                              null
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "append"
                                                                      },
                                                                      slot:
                                                                        "append"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs: {
                                                                            href:
                                                                              "mailto:" +
                                                                              _vm
                                                                                .editedRecord
                                                                                .refundContactEmail
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticStyle: {
                                                                                cursor:
                                                                                  "pointer"
                                                                              },
                                                                              attrs: {
                                                                                title:
                                                                                  "Mail to: " +
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .refundContactEmail,
                                                                                color:
                                                                                  "green"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-email-arrow-right-outline"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "v-text-field",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    "(###) ###-####",
                                                                  expression:
                                                                    "'(###) ###-####'"
                                                                }
                                                              ],
                                                              attrs: {
                                                                dense: "",
                                                                autocomplete:
                                                                  "none",
                                                                hint:
                                                                  "Valid format: (xxx)xxx-xxxx",
                                                                label:
                                                                  "Refund Contact Phone",
                                                                rules: [
                                                                  _vm.ValidationService.getValidator(
                                                                    "maxLength",
                                                                    "Refund Contact Phone",
                                                                    {
                                                                      length: 15
                                                                    }
                                                                  ),
                                                                  _vm.ValidationService.getValidator(
                                                                    "phone",
                                                                    "Refund Contact Phone"
                                                                  )
                                                                ],
                                                                required: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .editedRecord
                                                                    .refundContactPhone,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.editedRecord,
                                                                    "refundContactPhone",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "editedRecord.refundContactPhone"
                                                              }
                                                            },
                                                            [
                                                              _vm.editedRecord
                                                                .refundContactPhone !==
                                                              null
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "append"
                                                                      },
                                                                      slot:
                                                                        "append"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs: {
                                                                            href:
                                                                              "tel: +1" +
                                                                              _vm
                                                                                .editedRecord
                                                                                .refundContactPhone
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticStyle: {
                                                                                cursor:
                                                                                  "pointer"
                                                                              },
                                                                              attrs: {
                                                                                title:
                                                                                  "Call +1 " +
                                                                                  _vm.formatDisplayService.getRender(
                                                                                    "toPhone"
                                                                                  )(
                                                                                    _vm
                                                                                      .editedRecord
                                                                                      .refundContactPhone
                                                                                  ),
                                                                                color:
                                                                                  "green"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-phone"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm
                                                                          .phoneNumberRulesList
                                                                          .length >
                                                                        0,
                                                                      expression:
                                                                        "phoneNumberRulesList.length > 0"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    slot:
                                                                      "append",
                                                                    bottom: ""
                                                                  },
                                                                  slot:
                                                                    "append",
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "blue"
                                                                                    }
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  " mdi-information-outline "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "13px",
                                                                        "line-height":
                                                                          "14px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Provider Refund Contact Phone: "
                                                                      ),
                                                                      _vm._l(
                                                                        _vm.phoneNumberRulesList,
                                                                        function(
                                                                          rule,
                                                                          ruleIndex
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: ruleIndex
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " - " +
                                                                                  _vm._s(
                                                                                    rule
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "v-textarea",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                                autocomplete:
                                                                  "none",
                                                                label:
                                                                  "Refund Email(s)",
                                                                rules:
                                                                  _vm.getRefundEmailsRules,
                                                                required: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .editedRecord
                                                                    .refundEmail,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.editedRecord,
                                                                    "refundEmail",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "editedRecord.refundEmail"
                                                              }
                                                            },
                                                            [
                                                              _vm.editedRecord
                                                                .refundEmail !==
                                                              null
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "append"
                                                                      },
                                                                      slot:
                                                                        "append"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs: {
                                                                            href:
                                                                              "mailto:" +
                                                                              _vm
                                                                                .editedRecord
                                                                                .refundEmail
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticStyle: {
                                                                                cursor:
                                                                                  "pointer"
                                                                              },
                                                                              attrs: {
                                                                                title:
                                                                                  "Mail to: " +
                                                                                  _vm
                                                                                    .editedRecord
                                                                                    .refundEmail,
                                                                                color:
                                                                                  "green"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-email-arrow-right-outline"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    slot:
                                                                      "append",
                                                                    bottom: ""
                                                                  },
                                                                  slot:
                                                                    "append",
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "blue"
                                                                                    }
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  " mdi-information-outline "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "13px",
                                                                        "line-height":
                                                                          "14px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Refund Email(s): - Up to 5 valid email addresses allowed, separated by a semicolon. "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "v-text-field",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    "(###) ###-####",
                                                                  expression:
                                                                    "'(###) ###-####'"
                                                                }
                                                              ],
                                                              attrs: {
                                                                hint:
                                                                  "Valid format: (xxx)xxx-xxxx",
                                                                dense: "",
                                                                autocomplete:
                                                                  "none",
                                                                label:
                                                                  "Refund Phone",
                                                                rules: [
                                                                  _vm.ValidationService.getValidator(
                                                                    "maxLength",
                                                                    "Refund Phone",
                                                                    {
                                                                      length: 15
                                                                    }
                                                                  ),
                                                                  _vm.ValidationService.getValidator(
                                                                    "phone",
                                                                    "Refund Phone"
                                                                  )
                                                                ],
                                                                required: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .editedRecord
                                                                    .refundPhone,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.editedRecord,
                                                                    "refundPhone",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "editedRecord.refundPhone"
                                                              }
                                                            },
                                                            [
                                                              _vm.editedRecord
                                                                .refundPhone !==
                                                              null
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "append"
                                                                      },
                                                                      slot:
                                                                        "append"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs: {
                                                                            href:
                                                                              "tel: +1" +
                                                                              _vm
                                                                                .editedRecord
                                                                                .refundPhone
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticStyle: {
                                                                                cursor:
                                                                                  "pointer"
                                                                              },
                                                                              attrs: {
                                                                                title:
                                                                                  "Call +1 " +
                                                                                  _vm.formatDisplayService.getRender(
                                                                                    "toPhone"
                                                                                  )(
                                                                                    _vm
                                                                                      .editedRecord
                                                                                      .refundPhone
                                                                                  ),
                                                                                color:
                                                                                  "green"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-phone"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm
                                                                          .phoneNumberRulesList
                                                                          .length >
                                                                        0,
                                                                      expression:
                                                                        "phoneNumberRulesList.length > 0"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    slot:
                                                                      "append",
                                                                    bottom: ""
                                                                  },
                                                                  slot:
                                                                    "append",
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "blue"
                                                                                    }
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  " mdi-information-outline "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "13px",
                                                                        "line-height":
                                                                          "14px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Provider Refund Phone: "
                                                                      ),
                                                                      _vm._l(
                                                                        _vm.phoneNumberRulesList,
                                                                        function(
                                                                          rule,
                                                                          ruleIndex
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: ruleIndex
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " - " +
                                                                                  _vm._s(
                                                                                    rule
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              autocomplete:
                                                                "none",
                                                              label:
                                                                "Refund Website",
                                                              rules: [
                                                                _vm.ValidationService.getValidator(
                                                                  "maxLength",
                                                                  "Refund Website",
                                                                  {
                                                                    length: 255
                                                                  }
                                                                )
                                                              ],
                                                              required: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editedRecord
                                                                  .providerRefundWebsite,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editedRecord,
                                                                  "providerRefundWebsite",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editedRecord.providerRefundWebsite"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-card-subtitle", [
                                _vm._v("System Information")
                              ]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "",
                                              dense: "",
                                              label: "Created By",
                                              rules: [],
                                              disabled: "",
                                              required: ""
                                            },
                                            model: {
                                              value:
                                                _vm.editedRecord.createdByName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editedRecord,
                                                  "createdByName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedRecord.createdByName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "",
                                              dense: "",
                                              label: "Created Date",
                                              rules: [],
                                              disabled: "",
                                              required: "",
                                              value: _vm.returnDateTimeRenderd(
                                                _vm.editedRecord.createdDate
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "",
                                              dense: "",
                                              label: "Changed By",
                                              rules: [],
                                              disabled: "",
                                              required: ""
                                            },
                                            model: {
                                              value:
                                                _vm.editedRecord.updatedByName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editedRecord,
                                                  "updatedByName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedRecord.updatedByName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "",
                                              dense: "",
                                              label: "Changed By Date",
                                              rules: [],
                                              disabled: "",
                                              required: "",
                                              value: _vm.returnDateTimeRenderd(
                                                _vm.editedRecord.dateTimeUpdated
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", elevation: "2" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: { elevation: "2", disabled: _vm.overlay },
                          on: {
                            click: function($event) {
                              return _vm.submitDataRequest()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.editActionType === "UPDATE"
                                  ? "Update Provider"
                                  : "Add New Provider"
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "toolbar" }, slot: "toolbar" },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.providerDetailsEnabled,
                  expression: "providerDetailsEnabled"
                }
              ],
              attrs: { color: "primary", elevation: "2", medium: "" },
              on: {
                click: function($event) {
                  return _vm.goBackToProvider()
                }
              }
            },
            [_c("v-icon", [_vm._v(" mdi-arrow-left")])],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.providerDetailsEnabled,
                  expression: "providerDetailsEnabled"
                }
              ],
              staticClass: "ml-1",
              attrs: { color: "primary", elevation: "2", medium: "" },
              on: {
                click: function($event) {
                  _vm.getRemoteComponent("providerDetails").recordDataRefresh()
                }
              }
            },
            [_c("v-icon", [_vm._v(" mdi-refresh")])],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "grid" }, slot: "grid" },
        [
          _c("data-grid", {
            ref: "dataGrid",
            attrs: {
              "xheader-columns-show": "gridColumns",
              itemsPerPage: [50, 100, 200, 300, 400, 500],
              itemsPerPageInitial: 100,
              "api-metadata-url": _vm.endPoint.metadataEndpointUrl,
              "api-data-url": _vm.endPoint.dataEndpointUrl,
              actions: _vm.actions,
              headersAutoParserMapping: "",
              buttonActions: _vm.buttonActions
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "details" }, slot: "details" },
        [
          _vm.contentSelected === "details"
            ? _c("provider-details", {
                ref: "providerDetails",
                attrs: {
                  "pass-edited-record": _vm.editedRecordProviderDetails
                },
                on: { "provider-data-refresh": _vm.refreshProviderGrid }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "hidden" }, slot: "hidden" },
        [
          _c(
            "v-snackbar",
            {
              attrs: {
                centered: "",
                color: "success",
                text: "",
                timeout: 2000
              },
              model: {
                value: _vm.snackMessage,
                callback: function($$v) {
                  _vm.snackMessage = $$v
                },
                expression: "snackMessage"
              }
            },
            [
              _c("v-icon", { attrs: { color: "success" } }, [
                _vm._v(" mdi-check-bold ")
              ]),
              _vm._v(" Saved successful ")
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }