






































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import FormatDisplayService from '../../services/FormatDisplay.service';
import ValidationService from '../../services/Validations.service';
import axios from '../../services/api/Api.service';
import { AxiosResponse } from 'axios';
import { SerchResponse } from '../../../../src/domain/grid/interfaces';
const axiosInstance = new axios().getClient();

import {
  FieldTypeI,
  FilterSetsConditionI,
  FieldOptionI,
  SearchRequestBodySortI,
  SearchRequestBodyI,
  SearchConditionI,
  SubFilterConditionI,
  FilterSetI,
  SubFilterSetI,
  DataGridMetaDataI,
  DataTableHeaderI,
  ActionI,
  ButtonActionI,
  PreviewColumnI,
} from '../../interfaces';

export interface FieldOptionSFI {
  text: string;
  value: string;
  selected: boolean;
}

export interface ResultOptionSFI {
  selected: boolean;
  name: string;
}

export default Vue.extend({
  name: 'SfDataGridColumnFilter',
  data() {
    return {
      search: '',
      content: this.value || ([] as SearchConditionI[]),
      checkbox: {
        booleanYes: false,
        booleanNo: false,
      },
      selection: [] as ResultOptionSFI[],
      lookupsResults: [],
      lookupsResultsItemsLength: 0 as number,
      delay: null as null | number,
      errors: [] as string[],
    };
  },
  methods: {
    keyPressValidation(evt: { which: boolean; keyCode: number; preventDefault: () => {} }): boolean {
      // @ts-ignore
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (['Float', 'Number'].indexOf(this.getSubFilterType()) > -1) {
        if (this.header.value1 && this.header.value1.indexOf('.') > -1 && charCode === 46) {
          evt.preventDefault();
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
      if (this.getSubFilterType() === 'Integer') {
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
      return true;
    },

    parseDate(dateString: string): string {
      if (typeof dateString === 'string') {
        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
      } else {
        return '';
      }
    },
    getSelectedCount(): string {
      if (this.getSubFilterType() === 'Enum') {
        const selectedItems = this.getEnumFieldOptions().filter((option: FieldOptionSFI) => option.selected === true)
          .length;

        return selectedItems > 0 ? '(' + selectedItems + ')' : '(All)';
      }
      if (this.getSubFilterType() === 'String' && this.header.lookups) {
        return this.selection.length > 0 ? '(' + this.selection.length + ')' : '(All)';
      }
      if (this.getSubFilterType() === 'Date') {
        return this.content.length > 0 ? '(' + this.content.length + ')' : '(All)';
      }
      if (this.getSubFilterType() === 'Checkbox' || this.getSubFilterType() === 'Boolean') {
        return this.content.length > 0 ? '(' + this.content.length + ')' : '(All)';
      }
      return '';
    },
    resetFilter() {
      switch (this.getSubFilterType()) {
        case 'Checkbox':
          this.checkbox = {
            booleanYes: false,
            booleanNo: false,
          };
          break;
        case 'String':
          this.search = '';
          this.inputLookupFieldCallSearch();
          this.selection = [];
          break;
        case 'Enum':
          this.getEnumFieldOptions().forEach((option: FieldOptionSFI) => {
            option.selected = false;
          });
          break;
        case 'Date':
          delete this.header.value1;
          delete this.header.value2;
          this.header.comparator1 = ' = ';
          delete this.header.comparator2;
          break;
      }
      this.search = '';
      this.$forceUpdate();
      this.content = [];
      this.input();
    },
    addEnumFilter() {
      setTimeout(() => {
        const selectedItems = this.getEnumFieldOptions().filter((option: FieldOptionSFI) => option.selected);
        if (selectedItems.length > 0) {
          this.content = [
            {
              comparatorOption: 'in',
              fieldName: this.header.value,
              fixed: false,
              type: 'Enum',
              value: selectedItems.map((option: FieldOptionSFI) => {
                return option.value;
              }),
            },
          ];
        } else {
          this.content = [];
        }
        this.$forceUpdate();
        this.input();
      }, 1);
    },
    addLookupFilter(fieldOption: ResultOptionSFI) {
      setTimeout(() => {
        const selectedItem = this.selection.findIndex((i: ResultOptionSFI) => i.name === fieldOption.name);

        if (selectedItem > -1) {
          fieldOption.selected = false;
          this.selection.splice(selectedItem, 1);
        } else {
          this.selection.push(JSON.parse(JSON.stringify(fieldOption)));
        }
        if (this.selection.length > 0) {
          this.content = [
            {
              comparatorOption: 'in',
              fieldName: this.header.value,
              fieldNameSearchProperty: 'name',
              fixed: false,
              type: 'Enum',
              value: this.selection.map(option => {
                return option.name;
              }),
            },
          ];
        } else {
          this.content = [];
        }
        this.$forceUpdate();
        this.input();
      }, 100);
    },
    addBoolFilter(filterValue: boolean, check: 'NO' | 'YES') {
      setTimeout(() => {
        if (filterValue && check === 'YES') {
          this.checkbox.booleanNo = false;
        }
        if (filterValue && check === 'NO') {
          this.checkbox.booleanYes = false;
        }
        let filter = null as null | boolean;
        if (this.checkbox.booleanYes && !this.checkbox.booleanNo) {
          filter = true;
        }
        if (!this.checkbox.booleanYes && this.checkbox.booleanNo) {
          filter = false;
        }
        if (filter !== null) {
          if (this.content.length === 1) {
            this.content[0].value = filter;
          } else {
            this.content.push({
              comparatorOption: 'equal',
              fieldName: this.header.value,
              fixed: false,
              type: 'Checkbox',
              value: filter,
            });
          }
        } else {
          this.content.length = 0;
          this.content = [];
        }
        this.input();
      }, 100);
    },
    stopTheEvent: (event: Event) => event.stopPropagation(),
    input() {
      setTimeout(() => {
        this.$emit('input', this.content);
      }, 1);
    },
    getSubFilterType() {
      const subFilter = this.getHeaderSubFilter();
      if (subFilter) {
        return subFilter.conditions.find(
          (condition: SubFilterConditionI, conditionIndex: number) => conditionIndex === 0,
        ).fieldType;
      }
      return null;
    },
    getSubFilterComparatorOptions() {
      const subFilter = this.getHeaderSubFilter();
      if (subFilter) {
        return subFilter.conditions.find(
          (condition: SubFilterConditionI, conditionIndex: number) => conditionIndex === 0,
        ).conditionComparatorOptions;
      }
      return null;
    },
    getEnumFieldOptions(): FieldOptionSFI[] {
      return this.getHeaderSubFilter().conditions.find(
        (condition: SubFilterConditionI, conditionIndex: number) => conditionIndex === 0,
      ).fieldOptions;
    },
    getHeaderSubFilter() {
      if (this.metadata) {
        return this.metadata.subFilterSets
          .filter((filterSet: FilterSetI) =>
            filterSet.conditions.find(condition => condition.fieldName === this.header.value),
          )
          .find((filter: FilterSetI, filterIndex: number) => filterIndex === 0);
      }
    },
    inputFieldSubFilter(): void {
      if (this.header.comparator1 && this.header.value1 && this.header.value1 !== '') {
        this.content.push({
          comparatorOption: this.header.comparator1,
          fieldName: this.header.value,
          fixed: false,
          type: this.getSubFilterType(),
          value: this.header.value1,
        });
        this.header.comparator1 = ' = ';
        delete this.header.value1;
      }
      this.input();
    },
    inputDateSubFilter() {
      if (this.header.comparator1 && this.header.value1 && this.header.value1 !== '') {
        this.content.length = 0;
        this.content.push({
          comparatorOption: this.header.comparator1,
          fieldName: this.header.value,
          fixed: false,
          type: 'Date',
          value: this.header.value1,
        });
        if (this.header.comparator2 && this.header.value2 && this.header.value2 !== '') {
          this.content.push({
            comparatorOption: this.header.comparator2,
            fieldName: this.header.value,
            fixed: false,
            type: 'Date',
            value: this.header.value2,
          });
        }
        this.input();
      } else {
        if (this.content.length > 0) {
          this.content.length = 0;
          this.input();
        }
      }
    },
    inputLookupField() {
      if (!this.search) {
        this.search = '';
      }
      if (this.search.length >= 3 || this.search.length === 0) {
        if (this.delay) {
          clearTimeout(this.delay);
        }
        this.delay = setTimeout(() => {
          this.inputLookupFieldCallSearch();
        }, 500);
      }
    },
    inputLookupFieldCallSearch() {
      let requestBody = {};
      if (!this.header.lookups.externalSearchConditions) {
        requestBody = {
          filterCode: 'All',
          page: 1,
          rowsPerPage: 20,
          sort: [{ fieldName: 'name', desc: false }],
          conditions: this.getFixedConditions([
            {
              comparatorOption: 'contain',
              fieldName: 'name',
              fixed: false,
              type: 'String',
              value: this.search + '',
            },
          ]),
          queryLogic: null,
        };
      } else {
        requestBody = {
          filterCode: 'All',
          page: 1,
          rowsPerPage: 20,
          sort: [{ fieldName: 'name', desc: false }],
          conditions: this.getFixedConditions(
            this.header.lookups.externalSearchConditions.map((c: any) => {
              c.value = this.search + '';
              return c;
            }),
          ),
          queryLogic: '1 OR 2',
        };
      }
      // @ts-ignore: Unreachable code error
      this.loadData(requestBody, this.header.lookups.data);
    },
    getFixedConditions(conditions: any) {
      if (this.orgId) {
        conditions.push({
          comparatorOption: 'equal',
          fieldName: 'orgId',
          fixed: true,
          type: 'String',
          value: this.orgId,
        });
      }
      return conditions;
    },
    error() {
      const scope = this;
      return {
        set: (errors: string[]) => {
          scope.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          scope.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    async loadData(requestBody: SearchRequestBodyI, url: string) {
      if (!url) {
        // console.error('DATA: missing url');
      }

      return axiosInstance
        .post<any, AxiosResponse<SerchResponse<any>>>(url, requestBody, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(res => {
          this.lookupsResults = res.data.data;
          this.lookupsResultsItemsLength = res.data.totalRows;
        })
        .catch(error => {
          this.error().add(error + ' (Table Data)');
        });
    },
  },
  computed: {
    getLookUpSelection() {
      let results = [] as ResultOptionSFI[];
      results = [
        ...this.selection,
        ...this.lookupsResults.filter((resultsItem: ResultOptionSFI) => {
          if (this.selection.find(i => i.name === resultsItem.name)) {
            return false;
          } else {
            return true;
          }
        }),
      ];

      return results.sort(function compare(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    getEnumFieldOptionsFiltered() {
      // @ts-ignore: Unreachable code error
      return this.getEnumFieldOptions()
        .map((option: any) => {
          if (typeof option === 'string') {
            return {
              text: option,
              value: option,
            };
          } else {
            return option;
          }
        })
        .filter((option: FieldOptionSFI) => {
          try {
            return option.text.toLowerCase().includes(this.search.toLowerCase());
          } catch (e) {
            return false;
          }
        });
    },
  },
  created() {
    if (this.header.lookups) {
      this.inputLookupFieldCallSearch();
    }
  },
  watch: {
    '$props.value'(v) {
      this.content = v;
    },
  },
  props: ['metadata', 'value', 'header', 'autoRefresh', 'orgId'],
});
