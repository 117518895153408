var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audit-history-component",
    { attrs: { viewAll: _vm.viewAll, orgId: _vm.orgId, auditType: "account" } },
    [
      _vm.networkDisconnected
        ? _c(
            "div",
            {
              staticStyle: {
                "z-index": "1000",
                position: "absolute",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                overflow: "hidden"
              },
              attrs: { slot: "NetworkDisconnected" },
              slot: "NetworkDisconnected"
            },
            [
              _c("div", {
                staticStyle: {
                  "z-index": "-1",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  overflow: "hidden",
                  opacity: ".5",
                  background: "#ccc"
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "table",
                    margin: "0px",
                    padding: "0px",
                    height: "100vh",
                    width: "100vw",
                    "z-index": "2000",
                    "border-radius": "5px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "table-cell",
                        "vertical-align": "middle",
                        width: "100vw",
                        "text-align": "center"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            background: "white",
                            display: "inline-block",
                            padding: "5%",
                            width: "50%"
                          }
                        },
                        [
                          _vm._v(" Reconnecting to LCT Services "),
                          _c("br"),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "border-top": "1px solid #aaa",
                                display: "inline-block",
                                width: "100%"
                              }
                            },
                            [
                              _c("v-progress-linear", {
                                attrs: { indeterminate: "", color: "#1b96ff" }
                              })
                            ],
                            1
                          ),
                          _c("div", {
                            staticStyle: {
                              "font-family": "roboto",
                              "font-size": "20px",
                              "margin-top": "10px"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm.sessionLost
        ? _c(
            "div",
            {
              staticStyle: {
                "z-index": "1000",
                position: "absolute",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                overflow: "hidden"
              },
              attrs: { slot: "SessionDisconnected" },
              slot: "SessionDisconnected"
            },
            [
              _c("div", {
                staticStyle: {
                  "z-index": "-1",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  overflow: "hidden",
                  opacity: ".5",
                  background: "#ccc"
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "table",
                    margin: "0px",
                    padding: "0px",
                    height: "100vh",
                    width: "100vw",
                    "z-index": "2000",
                    "border-radius": "5px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "table-cell",
                        "vertical-align": "middle",
                        width: "100vw",
                        "text-align": "center"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            background: "white",
                            display: "inline-block",
                            padding: "5%",
                            width: "50%"
                          }
                        },
                        [
                          _vm._v(
                            " Something went wrong. Please refresh page or try again "
                          ),
                          _c("br"),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "border-top": "1px solid #aaa",
                                display: "inline-block",
                                width: "100%"
                              }
                            },
                            [
                              _c("v-progress-linear", {
                                attrs: { value: "100", color: "#F44336" }
                              })
                            ],
                            1
                          ),
                          _c("div", {
                            staticStyle: {
                              "font-family": "roboto",
                              "font-size": "20px",
                              "margin-top": "10px"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }