var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "visible",
                        rawName: "v-visible",
                        value: _vm.progress,
                        expression: "progress"
                      }
                    ],
                    attrs: { indeterminate: "", query: true, color: "blue" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: {
                                "lazy-validation": "",
                                disabled: _vm.disabled
                              }
                            },
                            [
                              _c("v-file-input", {
                                attrs: {
                                  "show-size": "",
                                  label: "File input",
                                  messages:
                                    "Accepted formats: jpg,jpeg,png. Max. size: 1MB",
                                  accept: ".jpg,.jpeg,.png",
                                  rules: _vm.selectedFileRules,
                                  required: ""
                                },
                                model: {
                                  value: _vm.selectedFile,
                                  callback: function($$v) {
                                    _vm.selectedFile = $$v
                                  },
                                  expression: "selectedFile"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.progress || _vm.disabled,
                                color: "primary",
                                elevation: "2"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.uploadSelectedFile()
                                }
                              }
                            },
                            [_vm._v(" Upload ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-5" },
                    [
                      _c("v-col", [
                        _vm.logoSrc
                          ? _c("img", {
                              staticClass: "logo",
                              attrs: { src: _vm.logoSrc, alt: "logo" }
                            })
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-snackbar",
            {
              attrs: {
                centered: "",
                color: "success",
                text: "",
                timeout: 3000
              },
              model: {
                value: _vm.snackBarSuccess,
                callback: function($$v) {
                  _vm.snackBarSuccess = $$v
                },
                expression: "snackBarSuccess"
              }
            },
            [
              _c("v-icon", { attrs: { color: "success" } }, [
                _vm._v(" mdi-check-bold ")
              ]),
              _vm._v(" Saved successful ")
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { centered: "", color: "error", text: "", timeout: 4000 },
              model: {
                value: _vm.snackBarError,
                callback: function($$v) {
                  _vm.snackBarError = $$v
                },
                expression: "snackBarError"
              }
            },
            [
              _c("v-icon", { attrs: { color: "error" } }, [
                _vm._v(" mdi-alert-circle-outline ")
              ]),
              _vm._l(_vm.errors, function(error, errorIndex) {
                return _c("div", { key: errorIndex }, [
                  _vm._v("* " + _vm._s(error))
                ])
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }