


















































































































































































































































































































































































import { Account, Dealer } from '@/interfaces';
import { Component, Prop, Vue } from 'vue-property-decorator';
import MetadataService, { MetadataType } from '@/services/api/Metadata.service';
import ValidationService from '../../services/Validations.service';
import SearchInputField from '@/components/SearchInputField.vue';

@Component({
  components: { SearchInputField },
})
export default class IDPAccountForm extends Vue {
  @Prop([Object, Boolean]) readonly accountData: any | undefined;
  @Prop([Boolean]) readonly readonly: boolean | undefined;
  @Prop([Boolean]) readonly disabled: boolean | undefined;
  data() {
    return {
      accountEnums: null as null | any[],
      ValidationService: ValidationService,
      ready: false,
      processing: false,
      editedRecord: {},
      valid: true,
      accountForm: null,
    };
  }
  async mounted() {
    this.$data.accountEnums = await MetadataService.loadData(MetadataType.ACCOUNT);
    this.$data.ready = true;
    setTimeout(() => {
      this.$data.accountForm = this.$getRemoteComponent('idpAccountForm', this);
      this.$data.accountForm.validate();
    }, 500);
  }

  selectDealer(dealer: Dealer) {
    this.accountData.dealer = dealer as Dealer;
    this.accountData.dealerNumber = dealer.dealerNumber;
    this.accountData.decisioned.dealerNumber = dealer.dealerNumber;
    this.$forceUpdate();
  }
  validationFunctions() {
    return true;
  }

  getFields() {
    let fields = [] as string[];

    return fields;
  }
  camelCaseToTitleCase(str: string): string {
    // Insert space before uppercase letters and split the string
    let spacedStr = str.replace(/(?<=.)([A-Z])/g, ' $1');

    // Transform to title case
    return spacedStr
      .toLowerCase()
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
