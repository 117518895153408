var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-scroll-layout",
    { attrs: { pageName: "Products", pageIcon: "mdi-view-list-outline" } },
    [
      _c(
        "div",
        { attrs: { slot: "toolbar" }, slot: "toolbar" },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.productDetailsEnabled,
                  expression: "productDetailsEnabled"
                }
              ],
              attrs: { color: "primary", elevation: "2", medium: "" },
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [_c("v-icon", [_vm._v(" mdi-arrow-left")])],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.productDetailsEnabled,
                  expression: "productDetailsEnabled"
                }
              ],
              staticClass: "ml-1",
              attrs: { color: "primary", elevation: "2", medium: "" },
              on: {
                click: function($event) {
                  _vm.getRemoteComponent("productsDetails").recordDataRefresh()
                }
              }
            },
            [_c("v-icon", [_vm._v(" mdi-refresh")])],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            top: "-45%",
            left: "-100%",
            right: "-40%",
            "z-index": "100",
            padding: "5px"
          },
          attrs: { slot: "options" },
          slot: "options"
        },
        [_c("layout-floating-messages")],
        1
      ),
      _c(
        "div",
        {
          staticClass: "mb-3 mr-2",
          attrs: { slot: "buttons" },
          slot: "buttons"
        },
        [
          _vm.screen === "details" && !_vm.createProductMode
            ? _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.validateProduct()
                    }
                  }
                },
                [_vm._v(" VALIDATE ")]
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.editButtonsRepreocessOn() && _vm.productDetailsEnabled,
                  expression:
                    "editButtonsRepreocessOn() && productDetailsEnabled"
                }
              ],
              staticClass: "mr-1",
              attrs: {
                color: "primary",
                elevation: "2",
                medium: "",
                disabled:
                  !_vm.isEditedRecordLenderActive ||
                  (_vm.isFinalizeStateDisableCRUD && !_vm.createProductMode)
              },
              on: {
                click: function($event) {
                  return _vm.updateProductsDetails()
                }
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.createProductMode ? "CREATE" : "SAVE") + " "
              )
            ]
          ),
          _vm.screen === "details" &&
          !_vm.createProductMode &&
          _vm.saveAndNextButtonVisible
            ? _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editButtonsRepreocessOn(),
                      expression: "editButtonsRepreocessOn()"
                    }
                  ],
                  staticClass: "mr-1",
                  attrs: {
                    color: "primary",
                    disabled:
                      (!_vm.createProductMode &&
                        !_vm.isEditedRecordLenderActive) ||
                      _vm.isFinalizeStateDisableCRUD
                  },
                  on: {
                    click: function($event) {
                      return _vm.updateProductsDetails(true)
                    }
                  }
                },
                [_vm._v(" SAVE AND NEXT ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c(
            "v-tabs",
            {
              ref: "tabs",
              staticClass: "accounts-tabs",
              model: {
                value: _vm.screen,
                callback: function($$v) {
                  _vm.screen = $$v
                },
                expression: "screen"
              }
            },
            [
              _c("v-tab", { attrs: { href: "#grid" } }, [_vm._v("Grid")]),
              _c(
                "v-tab-item",
                { attrs: { value: "grid" } },
                [
                  _c("data-grid", {
                    ref: "dataGrid",
                    attrs: {
                      "api-metadata-url": _vm.endPoint.metadataEndpointUrl,
                      "api-data-url": _vm.endPoint.dataEndpointUrl,
                      showSelection: true,
                      actions: _vm.actions,
                      previews: _vm.previews,
                      itemsPerPage: [50, 100, 200, 300, 400, 500],
                      itemsPerPageInitial: 100,
                      "button-actions": _vm.buttonActions,
                      headersAutoParserMapping: "",
                      "fixed-conditions":
                        typeof _vm.lender === "string" && _vm.lender !== ""
                          ? [
                              {
                                fieldName: "orgId",
                                value: _vm.lender,
                                fieldType: "string",
                                conditionComparatorOption: "equal"
                              }
                            ]
                          : [],
                      "fixed-conditions-prevent-call": false
                    },
                    on: {
                      "row-selection": _vm.rowSelection,
                      onFilterRecords: _vm.setFilteredRecords,
                      onListViewChange: _vm.setCurrentListView
                    }
                  })
                ],
                1
              ),
              _c("v-tab", { attrs: { href: "#details" } }, [_vm._v("Details")]),
              _c(
                "v-tab-item",
                { attrs: { value: "details" } },
                [
                  _vm.screen === "details"
                    ? _c("product-details", {
                        ref: "productsDetails",
                        attrs: {
                          createMode: _vm.createProductMode,
                          "pass-edited-record": _vm.editedRecordProductDetails,
                          orgId: _vm.lender,
                          filteredProducts: _vm.filteredProducts,
                          selectedProductIndex: _vm.selectedProductIndex,
                          enableInternalButtons: "",
                          refundDetailsShow: false,
                          refundDetailsHideTopSection: true,
                          disabled: !_vm.isEditedRecordLenderActive
                        },
                        on: {
                          switchScreen: function($event) {
                            _vm.screen = "grid"
                          },
                          "update:createMode": function($event) {
                            _vm.createProductMode = $event
                          },
                          "update:create-mode": function($event) {
                            _vm.createProductMode = $event
                          },
                          productUpdated: _vm.syncRecordData,
                          saveAndNextButtonVisible:
                            _vm.onSaveAndNextButtonVisible,
                          setFinalizeStateDisableCRUD:
                            _vm.setFinalizeStateDisableCRUD
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("remove-product-action", {
            ref: "RemoveProductAction",
            attrs: { eager: "" },
            on: {
              success: function($event) {
                return _vm.productDeleteSuccess()
              }
            }
          }),
          _c("assign-user-to-product-action", {
            ref: "AssignUserToProductAction",
            on: {
              success: function($event) {
                return _vm.assignUserSuccess()
              }
            }
          }),
          _c(
            "confirmation-dialog",
            _vm._b(
              {
                attrs: {
                  buttons: [
                    {
                      name: "Confirm",
                      callback: _vm.sendProviderApiRequest,
                      disabled: _vm.sendProviderApiDialog.sendButtonDisabled
                    }
                  ],
                  eager: ""
                }
              },
              "confirmation-dialog",
              _vm.sendProviderApiDialog,
              false
            ),
            [
              _c("div", { attrs: { slot: "message" }, slot: "message" }, [
                _vm._v(_vm._s(_vm.sendProviderApiConfirmationMessage))
              ])
            ]
          ),
          _c("send-cancellation-action", { ref: "SendCancellationAction" }),
          _c("finalize-products-action", {
            ref: "FinalizeProductsAction",
            on: { "finalization-request-success": _vm.refreshMainGrid }
          }),
          _c("request-refund-action", {
            ref: "RquestRefundAction",
            on: { refreshGrid: _vm.refreshGrid }
          }),
          _c(
            "custom-dialog",
            {
              ref: "ProductApiCallDetails",
              attrs: { slot: "options", eager: "" },
              slot: "options"
            },
            [
              (_vm.apiLogRecordDetails
              ? true
              : false)
                ? _c("api-log-details", {
                    ref: "apiLogDetails",
                    attrs: {
                      slot: "content",
                      data: _vm.apiLogRecordDetails,
                      eager: ""
                    },
                    slot: "content"
                  })
                : _vm._e(),
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v("Api Log Details")
              ]),
              _c(
                "div",
                { attrs: { slot: "buttons" }, slot: "buttons" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3 primary",
                      attrs: { elevation: "2" },
                      on: {
                        click: function($event) {
                          return _vm.downloadFile(
                            _vm.apiLogRecordDetails.id,
                            _vm.apiLogRecordDetails.VIN +
                              "_" +
                              _vm.apiLogRecordDetails.id
                          )
                        }
                      }
                    },
                    [_vm._v(" Download ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", elevation: "2" },
                      on: {
                        click: function($event) {
                          _vm
                            .getRemoteComponent("ProductApiCallDetails")
                            .external()
                            .close()
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "confirmation-dialog",
            _vm._b(
              {
                attrs: {
                  buttons: _vm.confirmationButtons,
                  cancelVisible: false,
                  cancelDisabled: true,
                  eager: ""
                }
              },
              "confirmation-dialog",
              _vm.confirmationDialogExit,
              false
            ),
            [
              _c("div", { attrs: { slot: "message" }, slot: "message" }, [
                _vm._v(
                  " You have unsaved data, do you want to leave the page? "
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }