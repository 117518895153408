var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.menuLayout
        ? _c(
            "v-navigation-drawer",
            {
              attrs: { app: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "prepend",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "pa-0", attrs: { block: "" } },
                          [
                            _c("img", {
                              staticClass: "ml-2 mt-1",
                              staticStyle: { height: "52px", width: "150px" },
                              attrs: { src: require("./assets/logo.png") }
                            })
                          ]
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _vm.userData !== null
                          ? _c(
                              "div",
                              {
                                staticClass: "pa-2 pt-5",
                                staticStyle: {
                                  height: "60px",
                                  "font-size": "12px",
                                  background: "#eeeeee",
                                  color: "#666",
                                  cursor: "pointer"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openUserDetails()
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-3",
                                    attrs: { color: _vm.getUserIconColor() }
                                  },
                                  [_vm._v("mdi-account")]
                                ),
                                _vm._v(
                                  " " + _vm._s(_vm.userData.fullUserName) + " "
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3344819520
              ),
              model: {
                value: _vm.sidebar,
                callback: function($$v) {
                  _vm.sidebar = $$v
                },
                expression: "sidebar"
              }
            },
            [
              _c("v-divider"),
              _vm.userData !== null
                ? _c(
                    "v-list",
                    _vm._l(_vm.prepareMnuItems(_vm.menuItems), function(item) {
                      return _c(
                        "v-list-item",
                        {
                          key: item.title,
                          staticClass: "shrink-menu-item",
                          attrs: { to: item.path },
                          on: {
                            click: function($event) {
                              return _vm.clearLocalStorage()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-icon", { attrs: { color: "#3d75b6" } }, [
                                _vm._v(_vm._s(item.icon))
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-content", [
                            _vm._v(_vm._s(item.title))
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.menuLayout
        ? _c(
            "v-app-bar",
            {
              staticStyle: {
                "border-bottom": "3px solid black",
                position: "fixed"
              },
              attrs: {
                "elevate-on-scroll": "",
                "scroll-target": "#body",
                app: "",
                absolute: "",
                fixed: "",
                color: "white"
              }
            },
            [
              _c(
                "span",
                { staticClass: "hidden-lg-and-up" },
                [
                  _c("v-app-bar-nav-icon", {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.sidebar = !_vm.sidebar
                      }
                    }
                  })
                ],
                1
              ),
              _vm.lenderSelectionVisible
                ? _c("v-autocomplete", {
                    staticStyle: { "max-width": "300px" },
                    attrs: {
                      color: "#3a72b5",
                      label: "Select lender",
                      items: _vm.lenderList,
                      "item-text": "lenderName",
                      "item-value": "orgId",
                      placeholder: "Select Lender",
                      outlined: "",
                      dense: "",
                      "hide-details": ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.setLender()
                      }
                    },
                    model: {
                      value: _vm.lender,
                      callback: function($$v) {
                        _vm.lender = $$v
                      },
                      expression: "lender"
                    }
                  })
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    to: "/logout",
                    color: "#3a72b5",
                    title: "",
                    text: ""
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "", dark: "" } }, [
                    _vm._v("mdi-logout")
                  ]),
                  _vm._v("Sign Out")
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.menuLayout
        ? _c("v-main", { attrs: { id: "main" } }, [_c("router-view")], 1)
        : _vm._e(),
      _vm.menuLayout
        ? _c(
            "custom-dialog",
            { ref: "UserDetails", attrs: { maxWidth: "40%" } },
            [
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(" User Details ")
              ]),
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _vm.userData !== null
                    ? _c(
                        "v-card-text",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticStyle: { "text-align": "right" },
                                  attrs: { cols: "3" }
                                },
                                [_vm._v("Name:")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticStyle: { "font-weight": "bold" },
                                  attrs: { cols: "9" }
                                },
                                [_vm._v(_vm._s(_vm.userData.fullUserName))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticStyle: { "text-align": "right" },
                                  attrs: { cols: "3" }
                                },
                                [_vm._v("Username:")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticStyle: { "font-weight": "bold" },
                                  attrs: { cols: "9" }
                                },
                                [_vm._v(_vm._s(_vm.userData.username))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticStyle: { "text-align": "right" },
                                  attrs: { cols: "3" }
                                },
                                [_vm._v("Role:")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticStyle: { "font-weight": "bold" },
                                  attrs: { cols: "9" }
                                },
                                [_vm._v(_vm._s(_vm.userData.userRole))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "buttons" }, slot: "buttons" },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          _vm
                            .getRemoteComponent("UserDetails")
                            .external()
                            .close()
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      !_vm.menuLayout
        ? _c("v-main", { attrs: { id: "main" } }, [_c("router-view")], 1)
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }