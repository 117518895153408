























































































































































































import { Vue } from 'vue-property-decorator';
import FormatDisplayService from '../services/FormatDisplay.service';
import MetadataService, { MetadataType } from '../services/api/Metadata.service';
import axios from '../services/api/Api.service';

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

const apiGetLogUrl = '/uiapi/api-partner-logs/get-log/{id}';

export interface LogFileDataI {
  url: string;
  request: {
    header: string;
    body: string;
  };
  response: {
    statusCode: string;
    header: string;
    body: string;
  };
}

export default Vue.extend({
  name: 'ApiPartnerLogDetails',
  components: {},
  data: () => ({
    progress: true,
    service: MetadataService,
    metadataEnumsMap: new Map(),
    FormatDisplayService: FormatDisplayService,
    errors: [] as string[],
    messages: [] as string[],
    productData: {},
    logFileData: {} as LogFileDataI | null,
  }),
  async created() {
    this.metadataEnumsMap = await this.service.loadData(MetadataType.API_LOGS);
    await this.loadApiLogs();
  },
  computed: {
    logRequest() {
      if (this.progress) {
        return `
        Loading logs....`;
      }
      return `
Url:  ${this.logFileData?.url || ''}

Header:
${JSON.stringify(this.logFileData?.request?.header || '', null, 4)}

Body:
${JSON.stringify(this.logFileData?.request?.body || '', null, 4)}

      `;
    },
    logResponse() {
      if (this.progress) {
        return `
        Loading logs....`;
      }
      return `
Url:  ${this.logFileData?.url || ''}

StatusCode: ${this.logFileData?.response?.statusCode || ''}

Header:
${JSON.stringify(this.logFileData?.response?.header || '', null, 4)}
Body:
${JSON.stringify(this.logFileData?.response?.body || '', null, 4)}

      `;
    },
  },
  methods: {
    errorVisible() {
      if (this.data) {
        if (this.data.errorDetails) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async loadApiLogs(): Promise<void> {
      const url = apiGetLogUrl.replace('{id}', this.data.id);
      this.progress = true;
      return axiosInstance
        .get(url)
        .then((result: { data: { errors: string[]; messages: string[]; success: boolean; data: LogFileDataI } }) => {
          this.logFileData = result.data.data as LogFileDataI;
          if (result.data.success === false) {
            if (result.data.errors) {
              this.error().set(result.data.errors);
            }
          } else {
            if (result.data.messages) {
              this.message().set(result.data?.messages);
            }
          }
          this.progress = false;
        })
        .catch((error: string) => {
          this.error().add(error);
          this.progress = false;
        });
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    error() {
      return {
        set: (errors: string[]) => {
          this.errors.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.errors.push(error);
          return this;
        },
        clear: () => {
          this.errors.length = 0;
          return this;
        },
      };
    },
    message() {
      return {
        set: (errors: string[]) => {
          this.messages.push(...errors);
          return this;
        },
        add: (error: string) => {
          this.messages.push(error);
          return this;
        },
        clear: () => {
          this.messages.length = 0;
          return this;
        },
      };
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
});
