






























































































































import { Vue } from 'vue-property-decorator';
import DataGrid from '@/components/DataGrid.vue';
import { SubFilterConditionI } from '../interfaces';

export default Vue.extend({
  name: 'SearchInputField',
  components: { DataGrid },
  data: () => ({
    searchSelecteditem: '' as string | null,
    selectedItems: [] as { id: string }[],
    delay: null as null | number,
    processing: false,
    searchDialogOpen: false,
    search: '',
    conditions: [] as SubFilterConditionI[],
  }),
  filters: {
    filterSearchSelectedItem(selectedItems: {}[], searchField: string, searchSelecteditem: string): {}[] {
      return selectedItems.filter(item => {
        return (
          // @ts-ignore: Unreachable code error
          item[searchField].toLowerCase().includes(searchSelecteditem.toLowerCase()) || searchSelecteditem === null
        );
      });
    },
  },
  methods: {
    selectItems() {
      this.$emit('selected', this.selectedItems);
      this.searchDialogOpen = false;
    },
    cancelDialog() {
      this.searchDialogOpen = false;
    },
    clearDialog() {
      this.selectedItems = [];
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    openSearchDialog() {
      if (this.conditions.length > 0) {
        this.conditions.length = 0;
        this.getRemoteComponent('SearchResultsGrid').submitDataRequest();
      }
      this.searchDialogOpen = true;
      this.search = '';
    },
    prepareConditions() {
      this.conditions.length = 0;
      this.conditions.push({
        conditionComparatorOption: 'contain',
        fieldName: this.searchField,
        fieldType: 'String',
        value: this.search,
      } as SubFilterConditionI);
      if (this.additionalConditions.length > 0) {
        this.additionalConditions.forEach(condition => {
          this.conditions.push(condition as SubFilterConditionI);
        });
      }
    },
    inputSelectedItems() {},
    input() {
      if (!this.search) {
        this.search = '';
      }
      if (this.search.length >= 3 || this.search.length === 0) {
        if (this.delay) {
          clearTimeout(this.delay);
        }
        this.prepareConditions();
        this.delay = setTimeout(() => {
          this.getRemoteComponent('SearchResultsGrid').submitDataRequest();
        }, 500);
      }
    },
  },
  watch: {
    searchDialogOpen: {
      handler(newValue) {
        if (newValue) {
          if (this.delay) {
            clearTimeout(this.delay);
          }
          this.prepareConditions();
          this.delay = setTimeout(() => {
            this.getRemoteComponent('searchField').focus();
            this.getRemoteComponent('SearchResultsGrid').submitDataRequest();
          }, 100);
        }
      },
    },
  },
  computed: {
    leftActions() {
      return [
        {
          icon: 'mdi-plus',
          callback: (rowData: { id: string }) => {
            let found = false;
            this.selectedItems.forEach((item: { id: string }) => {
              if (rowData.id === item.id) {
                found = true;
              }
            });
            if (!found) {
              this.selectedItems.push(rowData);
            }
          },
          tooltip: 'Add item',
          tooltipDisabled: 'Item already added',
          disabledCondition: (item: { id: string }) => {
            let found = false;
            this.selectedItems.forEach((itemSelected: { id: string }) => {
              if (itemSelected.id === item.id) {
                found = true;
              }
            });
            return found;
          },
        },
      ];
    },
    rigthActions() {
      return [
        {
          icon: 'mdi-minus',
          callback: (rowData: { id: string }) => {
            this.selectedItems.forEach((item: { id: string }, index: number) => {
              if (item.id === rowData.id) {
                this.selectedItems.splice(index, 1);
              }
            });
          },
          tooltip: 'Remove item',
        },
      ];
    },
  },
  props: {
    additionalConditions: {
      type: Array,
      default: () => {
        return []; // Array with Additional Conditions
      },
    },
    searchDialogOpenForce: {
      type: Boolean,
      default: false,
    },
    searchField: {
      type: String,
      default: 'name',
    },
    maxWidth: {
      type: String,
      default: '550px',
    },
    title: {
      type: String,
      default: 'Search',
    },
    fieldProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    columns: {
      type: Array,
      default: () => {
        return {};
      },
    },
    apiDataUrl: {
      type: String,
      default: 'emptyDataUrl', // url to data endpoint for current grid
    },
    apiMetadataUrl: {
      type: String,
      default: 'emptyMetaDataUrl', // url to metadata endpoint for current grid
    },
    searchInputLabel: {
      type: String,
      default: 'Search',
    },
    parseValue: {
      type: Function,
      default: (items: {}[]): string | {}[] => {
        return items;
      },
    },
    selectEvent: {
      type: Function,
      default: (rowData: {}, cell: {}): void => {
        // console.log('rowclick', rowData, cell);
      },
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    initialSortOrder: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
});
