



























































import S3FileService from '../services/api/S3File.service';
import LenderService, { LenderListI } from '../services/api/Lender.service';
import { Vue } from 'vue-property-decorator';
import axios from 'axios';
import UserService from '@/services/api/User.service';

export interface DataI {
  freezeForm: boolean;
  fileType: string | null;
  fileTypes: string[];
  selectedFile: File | null;
  message: string;
  alertType: 'error' | 'success';
  lendersList: LenderListI[];
  lender: string | null;
  userData: null | any;
}

export default Vue.extend({
  name: 's3-file-upload',
  data: (): DataI => {
    return {
      freezeForm: true,
      fileType: null,
      fileTypes: ['Account', 'Dealer', 'Payment', 'Refund Quote', 'Historical Data Upload', 'Provider Alias'],
      fileTypeRules: [(v: string) => !!v || 'File type is required'],
      selectedFile: null,
      selectedFileRules: [(v: string) => !!v || 'Please select a file'],
      message: '',
      alertType: 'success',
      lender: null,
      lendersList: [],
      lenderRules: [(v: string) => !!v || 'Please select a Lender'],
      userData: null,
    } as DataI;
  },
  async created() {
    this.getLenderData();
    setTimeout(this.resetAndValidate, 250);
    this.userData = await UserService.getUserData();
  },
  methods: {
    returnFileTypes() {
      return this.fileTypes.filter(ft => {
        return !(this.userData.userRole.indexOf('Salesforce') > -1 && ft === 'Provider Alias');
      });
    },
    resetAndValidate() {
      return (this.$refs.form as Vue & { validate: () => boolean }).validate();
    },
    remoteAccess() {
      return {
        // Medthod for reseting form when reopen (from parent)
        resetForm: () => {
          this.fileType = null;
          this.selectedFile = null;
          this.lender = this.$store.getters.lender as string;
          this.message = '';
          this.freezeForm = false;
        },
      };
    },
    async getLenderData() {
      this.lendersList = await LenderService.getLendersList();
      this.freezeForm = false;
    },
    formValueChange() {
      this.message = '';
      this.resetAndValidate();
    },
    async upload() {
      try {
        this.message = '';
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate() && this.selectedFile) {
          this.freezeForm = true;
          let url: string = '';
          const response = await S3FileService.getPresignedUploadUrl(
            this.fileType as string,
            this.selectedFile.name,
            this.lender as string,
          );
          if (response.success === true) {
            url = response.data?.url as string;
            const axiosCl = axios.create();
            const rs = await axiosCl.put(url, this.selectedFile, {
              headers: {
                'Content-Type': this.selectedFile.type,
              },
            });

            if (rs.status === 200) {
              this.selectedFile = null;
              (this.$refs.form as Vue & { reset: () => boolean }).reset();
              this.alertType = 'success';
              this.message = 'File saved';
              // Event for parent reporting upload status
              this.$emit('file-saved-success', true);
            } else {
              this.alertType = 'error';
              this.message = 'There were some errors during file upload';
              // Event for parent reporting upload status
              this.$emit('file-saved-success', false);
            }
          } else {
            this.alertType = 'error';
            this.message = response?.errors?.join(', ') as string;
          }
          this.freezeForm = false;
        }
      } catch (err) {
        this.alertType = 'error';
        this.message = 'There were some errors during file upload';
        this.freezeForm = false;
      }
    },
  },
  props: {},
});
