import axios from '../../services/api/Api.service';
import { AxiosResponse } from 'axios';

/**
 * Axios Instance for api communication
 */
const axiosInstance = new axios().getClient();

class FieldDefinitionService {
  constructor() {}

  public async getCollection(collection: 'ALL' | 'LETTER_RULES_CUSTOM_PARAMETERS' | string, options: any) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post<any, AxiosResponse<any>>(
          '/uiapi/field-collection/',
          Object.assign({ collectionName: collection }, options),
        )
        .then(results => {
          let data = null;
          data = results.data;
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

export default new FieldDefinitionService();
