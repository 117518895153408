import SearchRequestBodyI from '../../components/DataGrid.vue';
import SearchConditionI from '../../components/DataGrid.vue';

export interface ValidatorOptionsI {
  customFailMessage?: string;
  length: number;
  minValue?: number;
  maxValue?: number;
  requestBody?: SearchRequestBodyI;
  maxEmailsAllowed?: number;
  sfRules?: {
    decimalPlaces: number;
    maxLength: number;
    failMessage: string;
  };
}

class ValidationsService {
  //   constructor() {}

  getValidator = (validatorName: string, fieldName: string, validatorOptions?: ValidatorOptionsI): Function => {
    if (validatorName === 'required') {
      return (v: string | null) => {
        if (validatorOptions && validatorOptions.customFailMessage) {
          return !!v || validatorOptions.customFailMessage;
        } else {
          return !!v || fieldName + ' is required';
        }
      };
    }
    if (validatorName === 'vinValidation') {
      return (v: string | number | null) => {
        const len = (v + '' || '').length;
        if (len < 5) {
          return fieldName + ' must be equal or greater then 5 characters';
        }
        if (len > 25) {
          return fieldName + ' must be equal or less then 25 characters';
        }
        return true;
      };
    }
    /**
     * Max field value string length
     * validator options need to care length value
     */
    if (validatorName === 'maxLength') {
      return (v: string | number | null) => {
        return (
          (v + '' || '').length <= (validatorOptions ? validatorOptions : { length: 0 }).length ||
          fieldName + ' max length ' + (validatorOptions ? validatorOptions : { length: 0 }).length + ' characters'
        );
      };
    }
    if (validatorName === 'minLength') {
      return (v: string | number | null) => {
        return (
          (v + '' || '').length >= (validatorOptions ? validatorOptions : { length: 0 }).length ||
          fieldName + ' min length ' + (validatorOptions ? validatorOptions : { length: 0 }).length + ' characters'
        );
      };
    }
    if (validatorName === 'maxValue') {
      return (v: string | number | null) => {
        if (!v) {
          return true;
        }
        let maxValue = 0;
        if (validatorOptions) {
          if (validatorOptions.maxValue) {
            maxValue = validatorOptions.maxValue;
          }
        }
        return (parseInt(v + '') || 0) <= maxValue || fieldName + ' max value ' + maxValue;
      };
    }
    if (validatorName === 'maxFloatValue') {
      return (v: string | number | null) => {
        if (!v) {
          return true;
        }
        let maxValue = 0;
        if (validatorOptions) {
          if (validatorOptions.maxValue) {
            maxValue = validatorOptions.maxValue;
          }
        }
        return (parseFloat(v + '') || 0) <= maxValue || fieldName + ' max value ' + maxValue;
      };
    }
    if (validatorName === 'minValue') {
      return (v: string | number | null) => {
        if (!v) {
          return true;
        }
        let minValue = 0;
        if (validatorOptions) {
          if (validatorOptions.minValue) {
            minValue = validatorOptions.minValue;
          }
        }
        return (parseInt(v + '') || 0) >= minValue || fieldName + ' min value ' + minValue;
      };
    }
    if (validatorName === 'customSFDouble') {
      return (v: string | null) => {
        if (!v) {
          return true;
        }
        v = v + '';
        let decimalPlaces = 2;
        let maxLength = 18;
        let failMessage = 'has wrong format';

        if (validatorOptions && validatorOptions.sfRules) {
          maxLength = validatorOptions?.sfRules?.maxLength;
          decimalPlaces = validatorOptions?.sfRules?.decimalPlaces;
          failMessage = validatorOptions?.sfRules?.failMessage;
        }

        const success =
          /^-?[0-9]{1,}\.?[0-9]*$/.test(v) &&
          (maxLength == undefined || maxLength >= (('' + v).replace('-', '').split('.')[0] || '').length) &&
          18 >= v.length;

        if (success) {
          return true;
        } else {
          return fieldName + ' ' + failMessage;
        }
      };
    }

    if (validatorName === 'number') {
      return (v: string | null) => {
        return !v || /^[0-9]+$/.test('' + v) || fieldName + ' allow numbers only';
      };
    }
    if (validatorName === 'double') {
      return (v: string | null) => {
        return !v || /^-?\d+\.?\d{0,}$/.test('' + v) || ('' + v).length > 18 || fieldName + ' allow double only';
      };
    }
    if (validatorName === 'currency') {
      return (v: string | null) => {
        return !v || /^-?\d+\.?\d{0,}$/.test('' + v) || ('' + v).length > 18 || fieldName + ' allow currency only';
      };
    }
    if (validatorName === 'percent') {
      return (v: string | null) => {
        return (
          !v || /^(-?\d+\.?\d{0,}){0,18}$/.test('' + v) || ('' + v).length > 18 || fieldName + ' allow percent only'
        );
      };
    }
    if (validatorName === 'queryLogicFiltersCheck') {
      return (v: string | null) => {
        if (v === null || v === '') {
          return true;
        }
        // @ts-ignore: Unreachable code error
        if (validatorOptions?.requestBody.conditions.length > 0) {
          const queryLogicNumbersArray = v.match(/\d+/g);
          const logicConditionsCount = (('' + v).match(/or|and/gi) || []).length;
          const filterCount = (queryLogicNumbersArray || []).length;
          let valid = true as boolean | string;
          // check if filter's indexes in query logic are not exceeds used filters count
          (queryLogicNumbersArray || []).forEach(filterNumber => {
            if (
              parseInt(filterNumber) >
                // @ts-ignore: Unreachable code error
                validatorOptions?.requestBody.conditions.filter((condition: SearchConditionI) => {
                  // @ts-ignore: Unreachable code error
                  return !condition.fixed;
                }).length ||
              parseInt(filterNumber) < 1
            ) {
              valid = 'Filter index used in Query Logic is not valid';
            }
          });
          if (filterCount > 0 && logicConditionsCount === 0) {
            valid = 'Filter index used in Query Logic is not valid (OR or AND need to be used between filters)';
          }
          if ((filterCount === 1 && logicConditionsCount === 0) || filterCount <= logicConditionsCount) {
            valid = 'Filter index used in Query Logic is not valid (OR or AND not match filters Quantity)';
          }
          return valid;
        } else {
          return 'Cannot use Query Logic without selecting any filters';
        }
      };
    }
    if (validatorName === 'queryLogicSyntaxEndTest') {
      return (v: string | null) => {
        if (!v || '') {
          return true;
        } else {
          if (('' + v).replace(/ /g, '').indexOf('()') > -1) {
            return 'Query Logic has wrong syntax (empty brackets found)';
          }
          if (('' + v).replace(/ /g, '').search(/(or|and)/i) === 0) {
            return 'Query Logic has wrong syntax (statement cannot start with AND or OR)';
          }
          return (
            !/.*(and|or)$/i.test(('' + v).replace(/ /g, '')) ||
            'Query Logic has wrong syntax (AND or OR cannot be at the end of the statement)'
          );
        }
      };
    }
    if (validatorName === 'queryLogicSyntax') {
      return (v: string | null) => {
        return !v || /^(\d|and|or|AND|OR|\(|\)|\s)*$/.test('' + v) || 'Query Logic has wrong syntax';
      };
    }
    if (validatorName === 'queryLogicBrackets') {
      return (v: string | null) => {
        if (v === null) {
          return true;
        }
        const holder = [];
        const openBrackets = ['('];
        const closedBrackets = [')'];
        for (const letter of v) {
          if (openBrackets.includes(letter)) {
            holder.push(letter);
          } else if (closedBrackets.includes(letter)) {
            const openPair = openBrackets[closedBrackets.indexOf(letter)];
            if (holder[holder.length - 1] === openPair) {
              holder.splice(-1, 1);
            } else {
              holder.push(letter);
              break;
            }
          }
        }
        return holder.length === 0 || 'Query Logic has wrong brackets balance';
      };
    }
    if (validatorName === 'phone') {
      return (v: string | null) => {
        if (typeof v === 'string' && v) {
          const digitOnly = v.replace(/\D/g, '');

          if ((v || '').length > 15) {
            return fieldName + ' must be valid';
          }
          if ((v || '')[0] === '1') {
            return fieldName + ' must be valid';
          }

          if (digitOnly.indexOf('0123456789') > -1) {
            return fieldName + ' must be valid';
          }

          if (!/^(?!(\d)\1+$|\d*(\d)\2{6}$)(?:\d{7,15})?$/.test(digitOnly)) {
            return fieldName + ' must be valid';
          }

          if (/(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g.test(digitOnly) && /^[2-9]\d{9}$/.test(digitOnly)) {
            return true;
          } else {
            return fieldName + ' must be valid';
          }
        }
        return true;
      };
    }

    if (validatorName === 'emailSemiconList') {
      return (v: string | null) => {
        if (!v) {
          return true;
        }
        const emailListArray = v.split(';');
        const errorList = [] as string[];

        emailListArray.forEach((email: string, index: number) => {
          if (
            email.length === 0 ||
            (email &&
              email !== '' &&
              !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                email.trim(),
              ))
          ) {
            if (email.length === 0) {
              errorList.push('Empty email after semicolon.');
            } else {
              errorList.push(email);
            }
          }
        });
        if (errorList.length > 0) {
          return fieldName + '(s)' + ' invalid (' + errorList.join(',') + '), please update and try again.';
        }
        return true;
      };
    }

    if (validatorName === 'email') {
      return (v: string | null) => {
        if (
          v &&
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          )
        ) {
          return fieldName + ' must be valid';
        }
        return true;
      };
    }
    if (validatorName === 'isValidEmailCount') {
      return (value: string | null | undefined) => {
        if (typeof value !== 'string') {
          return true;
        } else {
          let maxEmailsAllowed = 5;
          if (validatorOptions && validatorOptions?.maxEmailsAllowed) {
            maxEmailsAllowed = validatorOptions.maxEmailsAllowed;
          }

          if (value.split(';').length > maxEmailsAllowed) {
            return (
              fieldName +
              '(s)' +
              ` cannot contain more than ${maxEmailsAllowed} Email Addresses, please update and try again.`
            );
          }
        }
        return true;
      };
    }
    if (validatorName === 'required-any') {
      return (v: string | number | null) => {
        v += '';
        return !!v || fieldName + ' is required';
      };
    }
    if (validatorName === 'url') {
      return (v: string | null) => {
        const pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i',
        );
        if (v && !pattern.test(v)) {
          return fieldName + ' must be valid';
        }
        return true;
      };
    }
    return function(v: string) {
      return 'Validator ' + validatorOptions + '(' + v + ') not found ';
    };
  };
}

export default new ValidationsService();
