































































































import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import ValidationService from '@/services/Validations.service';
import SfLookupField from '../SfLookupField.vue';

@Component({
  components: { SfLookupField },
})
export default class SfCustomParamString extends Vue {
  @PropSync('fieldValue') fieldValueSync!: any;
  @PropSync('fieldOperator') fieldOperatorSync!: any;
  @PropSync('fieldDefinition') fieldDefinitionSync!: any;
  @Prop([String]) readonly orgId: boolean | undefined;
  @Prop([Boolean]) readonly readonly: boolean | undefined;
  @Prop([Boolean]) readonly disabled: boolean | undefined;
  @Prop([Boolean]) readonly hideDetails: boolean | undefined;
  debug: any;
  menu: any;
  services: any;
  data() {
    return {
      debug: false,
      in: '',
      ready: false,
      menu: false,
      services: {
        validationService: ValidationService,
      },
    };
  }
  async mounted() {
    this.$data.ready = true;
    try {
      this.$data.in = this.fieldValueSync.value.join(',');
    } catch (e) {
      this.$data.in = this.fieldValueSync.value;
    }
  }
  updateOptions() {
    this.fieldValueSync.value = this.$data.in.split(',').filter((o: any) => o.match(/^\s*$/g) === null);
  }
  removeOption(fieldValueSync: any, optionIndex: number) {
    this.fieldValueSync.value.splice(optionIndex, 1);
    this.$data.in = this.fieldValueSync.value.join(',');
  }
}
