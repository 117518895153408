


























































































































































































































































import { Lender } from '../../interfaces';
import { Vue } from 'vue-property-decorator';
import FormatDisplayService from '../../services/FormatDisplay.service';
import ValidationService from '../../services/Validations.service';
import axios from '../../services/api/Api.service';
import DataGrid from '@/components/DataGrid.vue';

/**
 * Axios Instance for api comunication
 */
const axiosInstance = new axios().getClient();

const emptyLenderDataRecord: Lender = {
  lenderID: '',
  orgId: '',
  name: '',
  mailingCountry: '',
  integrationCommunicationSetup: [
    {
      providerCode: 'INTERFAX',
      username: null,
      password: null,
      email: null,
      dateTimeCreated: null,
      createdBy: null,
      dateTimeUpdated: null,
      updatedBy: null,
      active: true,
      id: null,
    },
  ],
  isSalesforce: true,
};

export default Vue.extend({
  name: 'ApiConfigurationTab',
  computed: {
    emailRules: () => {},
  },
  components: {
    DataGrid,
  },
  data: () => ({
    snackBarSuccess: false,
    successMessage: 'Saved successful',
    snackBarError: false,
    errors: null as null | string[],
    ValidationService: ValidationService,
    editedRecord: Object.assign({}, emptyLenderDataRecord),
    displayDialogBox: true,
    processing: false,
    apiPartner: {
      orgId: '',
      apiKey: '',
      secret: '',
      dateTimeCreated: null,
      dateTimeUpdated: null,
      tokenCreatedDateTime: null,
      email: '',
      enabled: false,
      saveAndGenerateCredentials: false,
      createdByName: '',
      updatedByName: '',
      locked: false,
      verified: false,
      maxProductsInRequest: null,
      maxAccountsInRequest: null,
      maxRequestsPerMinute: null,
    },
    valid: false,
    overlay: false,
  }),
  created() {
    this.apiPartner.orgId = this.passEditedRecord.orgId;
    this.fetchApiPartnerData();
  },
  methods: {
    formatUserDateTime(user: string | null, dateTime: string | null) {
      const userDisplay = user === 'null' || user === '' || user === null || user === undefined ? '' : user + ', ';
      return userDisplay + (FormatDisplayService.getRender('toDateTime')(dateTime) || '');
    },
    async fetchApiPartnerData() {
      this.overlay = true;
      axiosInstance
        .get('/uiapi/api-partner/get-config', {
          params: {
            orgId: this.passEditedRecord.orgId,
          },
          validateStatus: (status: number) => status == 200 || status == 404,
        })
        .then((response: any) => {
          if (response.status == 200) {
            this.apiPartner = Object.assign(this.apiPartner, response.data);
            this.overlay = false;
          } else if (response.status == 404) {
            this.overlay = false;
          }
        })
        .catch((error: any) => {
          this.errors = [error.message];
          this.snackBarError = true;
          this.overlay = false;
        });
    },
    returnDateTimeRenderd(dateTimeString: string | null) {
      return FormatDisplayService.getRender('toDateTime')(dateTimeString || '');
    },

    async unlock() {
      const confirmResult = confirm(
        'Unlock require generate and send new credentials to API Partner. You will also need to verify this Partner once again. Do you still want unlock this API Partner?',
      );
      if (confirmResult) {
        this.apiPartner.locked = false;
        await this.save(true, 'API Partner was unlock successful');
      }
    },

    async verify() {
      this.apiPartner.verified = true;
      await this.save(false, 'API Partner was verified');
    },
    async save(saveAndGenerateCredentials: boolean, successMessage?: string) {
      if ((this.$refs.apiConfigurationForm as Vue & { validate: () => boolean }).validate()) {
        this.overlay = true;
        this.apiPartner.saveAndGenerateCredentials = saveAndGenerateCredentials;
        axiosInstance
          .post('/uiapi/api-partner/save', this.apiPartner, {
            headers: {
              'Content-Type': 'application/json',
            },
            validateStatus: (status: number) => status == 200 || status == 201 || status == 400 || status == 500,
          })
          .then((response: any) => {
            if (response.status == 201) {
              this.apiPartner = Object.assign(this.apiPartner, response.data);
              this.successMessage = successMessage || 'Saved successful';
              this.snackBarSuccess = true;
            } else {
              this.errors = Array.isArray(response.data.message) ? response.data.message : [response.data.message];
              this.snackBarError = true;
            }

            this.overlay = false;
          })
          .catch((error: any) => {
            this.errors = [error.message];
            this.snackBarError = true;
            this.overlay = false;
          });
      }
    },
  },
  props: {
    passEditedRecord: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
