







































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import FieldDefinitionService from '@/services/api/FieldDefinition.service';
import ValidationService from '@/services/Validations.service';
import SearchInputField from '@/components/SearchInputField.vue';
import SfCustomParamString from '@/components/SFComponents/CustomParameters/SfCustomParamString.vue';
import SfCustomParamInteger from '@/components/SFComponents/CustomParameters/SfCustomParamInteger.vue';
import SfCustomParamDate from '@/components/SFComponents/CustomParameters/SfCustomParamDate.vue';
import SfCustomParamBoolean from '@/components/SFComponents/CustomParameters/SfCustomParamBoolean.vue';
import SfCustomParamFloat from '@/components/SFComponents/CustomParameters/SfCustomParamFloat.vue';
import SfCustomParamEnum from '@/components/SFComponents/CustomParameters/SfCustomParamEnum.vue';

@Component({
  components: {
    SearchInputField,
    SfCustomParamString,
    SfCustomParamInteger,
    SfCustomParamDate,
    SfCustomParamBoolean,
    SfCustomParamFloat,
    SfCustomParamEnum,
  },
})
export default class SfCustomParameters extends Vue {
  @PropSync('customParams') customParameters!: any;
  @Prop([String]) readonly orgId: boolean | undefined;
  @Prop([Boolean]) readonly staticParameters: boolean | undefined;
  @Prop([Boolean]) readonly readonly: boolean | undefined;
  @Prop([Boolean]) readonly disabled: boolean | undefined;
  get isStaticParametersEnabled() {
    return this.staticParameters;
  }
  components: any;
  parameterSelected: any;
  parameterOperatorSelected: any;
  parameterValueSelected: any;
  parameters: any;
  data() {
    return {
      enums: null as null | any[],
      ready: false,
      services: {
        validationService: ValidationService,
      },
      parameterValueSelected: null,
      parameterOperatorSelected: null,
      parameterSelected: null as null | string,
      parameters: [] as any[],
      components: {
        SfCustomParamString,
        SfCustomParamInteger,
        SfCustomParamDate,
        SfCustomParamBoolean,
        SfCustomParamFloat,
        SfCustomParamEnum,
      } as any,
    };
  }
  async mounted() {
    const parameters = (await FieldDefinitionService.getCollection('LETTER_RULES_CUSTOM_PARAMETERS', {
      orgId: this.orgId,
    })) as any[];
    // Update/Prepare Parameters Collection (sort by type and add id)
    this.$data.parameters = this.prepareCustomeParametersList(parameters);

    // Add Fields Definition to Parameters which came from Record
    this.updateCustomParameters();
    this.$data.ready = true;
  }
  clearOperatorAndValue() {
    let findDefaultOperator = this.getDefinition().operators.find((o: any) => o.text == 'equal');
    findDefaultOperator = findDefaultOperator || this.getDefinition().operators.find((o: any) => o.text == 'in');
    this.$data.parameterOperatorSelected = findDefaultOperator.value;
    this.clearAndSetupValue();
  }
  clearAndSetupValue() {
    this.$data.parameterValueSelected = Object.assign(
      this.$data.parameterValueSelected || {},
      this.prepareValue(this.getDefinition(), this.$data.parameterOperatorSelected),
    );
    setTimeout(() => {
      this.$getRemoteComponent('REFInsertParameterForm', this)?.validate();
    }, 500);
  }
  clearAndSetupValueForStoredParameter(customParameter: any, operator: any) {
    customParameter.value = Object.assign(customParameter.value, this.prepareValue(customParameter, operator));
    this.$forceUpdate();
  }
  prepareValue(fieldDefinition: any, operator: any) {
    switch (fieldDefinition.fieldType) {
      case 'String':
        if (['in', 'not in'].includes(operator)) {
          return {
            type: 'Array',
            value: [],
          };
        } else {
          return {
            type: 'String',
            value: '',
          };
        }
      case 'Integer':
        if (['in', 'not in'].includes(operator)) {
          return {
            type: 'Array',
            value: [],
          };
        } else {
          return {
            type: 'Integer',
            value: '',
          };
        }
      case 'Date':
        if (['between'].includes(operator)) {
          return {
            type: 'Array',
            value: [null, null],
          };
        } else {
          return {
            type: 'String',
            value: '',
          };
        }
      case 'Boolean':
        return {
          type: 'Boolean',
          value: false,
        };
      case 'Float':
        return {
          type: 'Float',
          value: '',
        };
      case 'Enum':
        if (['in', 'not_in'].includes(operator)) {
          return {
            type: 'Array',
            value: [],
          };
        } else {
          return {
            type: 'String',
            value: '',
          };
        }
      default:
        return {
          type: 'string',
          value: '',
        };
    }
  }
  addParam() {
    if (!this.getDefinition()) {
      return undefined;
    }
    if (this.$getRemoteComponent('REFInsertParameterForm', this)?.validate()) {
      const newParam = this.$getClone(
        Object.assign(this.getDefinition(), {
          operator: this.$data.parameterOperatorSelected,
          value: this.$data.parameterValueSelected,
        }),
      );
      this.customParameters.unshift(newParam);

      this.$data.parameterValueSelected = null;
      this.$data.parameterOperatorSelected = null;
      this.$data.parameterSelected = null;
      (this.$findParentByName('LenderServiceConfigurationLetterEditForm', this) as any)?.validateForm();
      return true;
    } else {
      return false;
    }
  }
  removeParam(index: number) {
    this.customParameters.splice(index, 1);
  }
  filterWithGroups(item: any, queryText: string, itemText: string) {
    return (
      Object.prototype.hasOwnProperty.call(item, 'header') ||
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }
  getOperators() {
    const find = this.$data.parameters.find((p: any) => p.fieldId === this.$data.parameterSelected);
    return find ? find.operators : [];
  }
  getDefinition() {
    const find = this.$data.parameters.find((p: any) => p.fieldId === this.$data.parameterSelected);
    return find ? find : null;
  }
  updateCustomParameters() {
    this.customParameters.forEach((param: any, index: number) => {
      const find = this.$data.parameters.find((p: any) => p.type === param.type && p.fieldName === param.fieldName);
      if (find) {
        this.customParameters[index] = Object.assign(this.$getClone(find), param);
      }
    });
  }
  prepareCustomeParametersList(parameters: any[]) {
    const output = [] as any[];
    let header = null as any;
    parameters
      .sort((a: any, b: any) => {
        if (a.type < b.type) {
          return -1;
        } else if (a.type > b.type) {
          return 1;
        } else {
          if (a.fieldLabel < b.fieldLabel) {
            return -1;
          } else if (a.fieldLabel > b.fieldLabel) {
            return 1;
          } else {
            return 0;
          }
        }
      })
      .forEach((param: any) => {
        if (param.type !== header || header === null) {
          output.push({ header: param.type });
        }
        header = param.type;
        param.fieldId = param.type + '@' + param.fieldName;
        param.orgId = this.$props.orgId;
        param.fixedConditions = [
          {
            fieldName: 'orgId',
            value: param.orgId,
            fieldType: 'string',
            conditionComparatorOption: 'equal',
          },
        ];
        param.externalSearchConditions = [];

        if (param.lookupData !== '') {
          // Added for Lookup Field for Integer Type
          param.operators.push(
            {
              text: 'in',
              value: 'in',
            },
            {
              text: 'not in',
              value: 'not in',
            },
          );
          param.lookupData = Object.assign(param.lookupData, {
            searchFieldName: 'name',
          });
          // CUSTOM PARAMETER
          if (param.lookupData.apiMetadataUrl == '/uiapi/grids/user') {
            param.lookupData = Object.assign(param.lookupData, {
              searchFieldName: 'username',
            });
          }
          // CUSTOM DEALER PARAMETERS
          if (param.fieldName === 'dealerNumber') {
            param.searchFieldPrefix = 'dealerNumber';
            param.externalSearchConditions = [
              {
                comparatorOption: 'contain',
                fieldName: 'name',
                fixed: false,
                type: 'String',
                value: null,
              },
              {
                comparatorOption: 'equal',
                fieldName: 'dealerNumber',
                fixed: false,
                type: 'String',
                value: null,
              },
            ];
          }
        }

        if (param.fieldType === 'Enum') {
          param.fieldOptionsConverted = Object.keys(param.fieldOptions).map((k: any) => {
            let str = k.replace(/([a-z])([A-Z])/g, '$1 $2');
            str = str.toLowerCase().replace(/_/g, ' ');
            str = str.replace(/\b\w/g, (char: string) => char.toUpperCase());
            // exception for wrong code
            str = str === 'Out Of Bussiness' ? 'Out of Business' : str;

            return { text: str, value: param.fieldOptions[k] };
          });
        }

        output.push(param);
      });
    return output;
  }
}
